export type ColorsStepConfiguration = Record<
  string,
  {
    secondaryColorRequired: boolean;
    secondaryColorHidden: boolean;
  }
>;

export const colorsStepConfiguration: ColorsStepConfiguration = {
  armchairs: {
    secondaryColorRequired: false,
    secondaryColorHidden: false,
  },
  benches: {
    secondaryColorRequired: false,
    secondaryColorHidden: false,
  },
  conferenceChairs: {
    secondaryColorRequired: true,
    secondaryColorHidden: false,
  },
  foldingChairs: {
    secondaryColorRequired: true,
    secondaryColorHidden: false,
  },
  officeChairs: {
    secondaryColorRequired: true,
    secondaryColorHidden: false,
  },
  poufs: {
    secondaryColorRequired: false,
    secondaryColorHidden: true,
  },
  stools: {
    secondaryColorRequired: false,
    secondaryColorHidden: false,
  },
  standard: {
    secondaryColorRequired: true,
    secondaryColorHidden: false,
  },
  chaise: {
    secondaryColorRequired: true,
    secondaryColorHidden: false,
  },
  sectional: {
    secondaryColorRequired: true,
    secondaryColorHidden: false,
  },
  cabinet: {
    secondaryColorRequired: false,
    secondaryColorHidden: false,
  },
  fileCabinet: {
    secondaryColorRequired: false,
    secondaryColorHidden: false,
  },
  shelves: {
    secondaryColorRequired: false,
    secondaryColorHidden: false,
  },
  storageBox: {
    secondaryColorRequired: false,
    secondaryColorHidden: false,
  },
  workstationDesks: {
    secondaryColorRequired: true,
    secondaryColorHidden: false,
  },
  meetingRoomTables: {
    secondaryColorRequired: true,
    secondaryColorHidden: false,
  },
  foldingTables: {
    secondaryColorRequired: true,
    secondaryColorHidden: false,
  },
  diningTables: {
    secondaryColorRequired: true,
    secondaryColorHidden: false,
  },
  coffeeTables: {
    secondaryColorRequired: false,
    secondaryColorHidden: false,
  },
  sideTables: {
    secondaryColorRequired: false,
    secondaryColorHidden: false,
  },
  whiteboards: {
    secondaryColorRequired: false,
    secondaryColorHidden: false,
  },
};
