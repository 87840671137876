import React, { FC, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { useNavigation } from '../../../../common/navigation';
import { extractMutation } from '../../../../common/utils/extractQuery';
import { SummaryStep as SummaryStepComponent } from '../../../components/NewSkuView/SummaryStep';
import { useColorHelper } from '../../../context/useColorMapping';
import {
  CreateAdSkuMutation,
  AdSkuInput,
  useCreateAdSkuMutation,
} from '../../../graphql';
import { CreateAdSkuMutationPayload } from '../../../graphql/types';
import * as selectors from '../../../redux/newAdSku/selectors';
import { StepId } from '../types';
import { useNewAdSkuActions } from '../../../redux/newAdSku';

export type RedirectTarget = 'finish' | 'register';

const validateAdSkuInput = (
  adSkuInput: Partial<AdSkuInput>,
): [AdSkuInput | undefined, StepId | undefined] => {
  const {
    manufacturer,
    model,
    primaryColor,
    mainCategoryId,
    subcategoryId,
  } = adSkuInput;
  if (!mainCategoryId) {
    return [undefined, StepId.MainCategory];
  }
  if (!subcategoryId) {
    return [undefined, StepId.Subcategory];
  }
  if (!manufacturer) {
    return [undefined, StepId.Model];
  }
  if (!primaryColor) {
    return [undefined, StepId.Color];
  }

  return [
    {
      mainCategoryId,
      manufacturer,
      model,
      subcategoryId,
      ...adSkuInput,
    },
    undefined,
  ];
};

export const SummaryStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onGoBack,
  onGoToStep,
}) => {
  const partialAdSkuInput = useSelector(selectors.adSkuInput);
  const nav = useNavigation();
  const backLabel = useSelector(selectors.label);
  const { setDescription, reset } = useNewAdSkuActions();
  const isNewManufacturer = useSelector(selectors.isNewManufacturer);
  const images = useSelector(selectors.images);
  const colorHelper = useColorHelper();
  const [adSkuInput, stepId] = validateAdSkuInput(partialAdSkuInput);
  const [redirectTarget, setRedirectTarget] = useState<RedirectTarget>(
    'register',
  );
  const [createAdSku, rawMutationResult] = useCreateAdSkuMutation({
    refetchQueries: isNewManufacturer ? ['Context'] : undefined,
  });
  const mutationResult = extractMutation<
    CreateAdSkuMutation,
    CreateAdSkuMutationPayload
  >(rawMutationResult, 'CreateAdSkuPayload', data => data.createAdSku);

  useEffect(() => {
    if (!adSkuInput && stepId) {
      onGoToStep(stepId);
    }
  }, [onGoToStep, adSkuInput, stepId]);

  useEffect(() => {
    const adSku = mutationResult.data?.adSku;
    if (adSku) {
      reset();
      if (redirectTarget === 'finish') {
        return nav.adSku({ id: adSku.id }).go();
      }

      return nav.newAdSkuInstance({ adSkuId: adSku.id }).go();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationResult.data]);

  if (!adSkuInput) {
    return null;
  }

  const handleSubmit = (target: RedirectTarget) => {
    setRedirectTarget(target);
    createAdSku({
      variables: { input: { adSku: adSkuInput } },
    });
  };

  const handleDescriptionChange = (description: string) => {
    setDescription(description);
  };

  return (
    <SummaryStepComponent
      backLabel={backLabel}
      colorHelper={colorHelper}
      skuInput={adSkuInput}
      images={images}
      onGoBack={onGoBack}
      onGoToStep={onGoToStep}
      onSubmit={handleSubmit}
      onDescriptionChange={handleDescriptionChange}
      error={mutationResult.error}
      loading={mutationResult.loading}
      number={number}
      totalCount={totalCount}
    />
  );
};
