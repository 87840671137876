import { useState } from 'react';

import {
  CreateTenantMutation,
  TenantInput,
  useCreateTenantMutation,
} from '../../../inventory/graphql';
import { extractMutation } from '../../../common/utils/extractQuery';
import { CreateTenantMutationPayload } from '../../../inventory/graphql/types';

export const useCreateTenant = (onCompleteAction?: (id: string) => void) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const [createTenantMutation, rawMutationResult] = useCreateTenantMutation({
    onCompleted: data => {
      setIsProcessing(false);
      if (
        data.createTenant?.__typename === 'CreateTenantPayload' &&
        onCompleteAction
      ) {
        onCompleteAction(data.createTenant.tenant.name);
      }
    },
  });

  const mutationResult = extractMutation<
    CreateTenantMutation,
    CreateTenantMutationPayload
  >(rawMutationResult, 'CreateTenantPayload', data => data.createTenant);

  const createTenant = (tenant: TenantInput) => {
    setIsProcessing(true);
    createTenantMutation({
      variables: {
        input: {
          tenant,
        },
      },
    });
  };

  return {
    isProcessing,
    createTenant,
    mutationResult,
  };
};
