import Card from '@material-ui/core/Card';
import { createStyles, withStyles } from '@material-ui/core/styles';

export const WhiteCard = withStyles(theme =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      borderColor: theme.palette.secondary.veryLight,
    },
  }),
)(Card);
