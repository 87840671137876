import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, { useMemo } from 'react';

import { FieldProps } from '../../../common/components/Form/types';
import { ColorMapping } from '../../context/useColorMapping';

import { ControlledColorField } from './ControlledColorField';

export const useColorFieldComponent = (colorMapping: ColorMapping) => {
  return useMemo(
    () => ({ gridItemProps, ...props }: FieldProps) => (
      <Grid item {...gridItemProps}>
        <Box mb={1}>
          <ControlledColorField
            fullWidth
            colorMapping={colorMapping}
            id={`form-input-${props.name}`}
            {...props}
          />
        </Box>
      </Grid>
    ),
    [colorMapping],
  );
};
