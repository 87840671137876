import { State as LocalState } from './reducer';

export type State = {
  inventory: {
    newAdSku: LocalState;
  };
};

export const root = (state: State) => state.inventory.newAdSku;

export const adSkuInput = (state: State) => root(state).adSkuInput;

export const mainCategoryId = (state: State) =>
  adSkuInput(state).mainCategoryId;

export const subcategoryId = (state: State) => adSkuInput(state).subcategoryId;

export const mainCategoryKey = (state: State) => root(state).mainCategory?.key;

export const subcategoryKey = (state: State) => root(state).subcategory?.key;

export const subcategoryName = (state: State) => root(state).subcategory?.name;

export const primaryColor = (state: State) => adSkuInput(state).primaryColor;

export const secondaryColor = (state: State) =>
  adSkuInput(state).secondaryColor;

export const manufacturer = (state: State) => adSkuInput(state).manufacturer;

export const model = (state: State) => adSkuInput(state).model;

export const images = (state: State) => {
  return root(state).images;
};

export const label = (state: State) =>
  [manufacturer(state), model(state), subcategoryName(state)]
    .filter(Boolean)
    .join(' ');

export const isNewManufacturer = (state: State) =>
  root(state).isNewManufacturer;
