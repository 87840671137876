import React, { FC, useEffect } from 'react';
import { ApolloClient } from '@apollo/client';

import { LoadingIndicator } from '../../common/components/LoadingIndicator';
import { ErrorView } from '../../common/components/ErrorView';
import { extractQuery } from '../../common/utils/extractQuery';
import {
  Tenant,
  UserQuery,
  UserQueryResult,
  useUserLazyQuery,
} from '../graphql';
import { UserPayload } from '../graphql/types';

export type UserContextType = { __typename: 'User' } & {
  tenants: ({ __typename: 'Tenant' } & Pick<Tenant, 'id' | 'name'>)[];
};

type UserContextData = {
  data: UserPayload;
  fetchUserData: () => void;
};

export const UserContext = React.createContext<UserContextData>(
  {} as UserContextData,
);

type Props = {
  client: ApolloClient<object>;
};

export const UserProvider: FC<Props> = ({ client, children }) => {
  const [userQuery, result] = useUserLazyQuery({
    client,
  });

  useEffect(() => {
    userQuery();
  }, [userQuery]);

  const fetchUserData = () => {
    userQuery();
  };

  const { data, loading, error } = extractQuery<UserQuery, UserPayload>(
    result as UserQueryResult,
    'User',
    data => data.user,
  );

  if (error) {
    return <ErrorView error={error} />;
  }

  if (loading || !data) {
    return <LoadingIndicator />;
  }

  return (
    <UserContext.Provider value={{ data, fetchUserData }}>
      {children}
    </UserContext.Provider>
  );
};
