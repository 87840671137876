import React, { FC } from 'react';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { useNavigation } from '../../../../common/navigation';
import { WelcomeStep as WelcomeStepComponent } from '../../../components/RegistrationView/WelcomeStep';
import { useResolvedLastRegisteredSkus } from '../../../utils/useLastRegistered';
import { LocationDialog } from '../../NewSkuView/steps/LocationDialog';

export const WelcomeStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onStepCompleted,
}) => {
  const lastSkus = useResolvedLastRegisteredSkus();
  const nav = useNavigation();
  const handleRegisterInstance = (skuId: string) => {
    nav.newSkuInstance({ skuId }).go();
  };
  const handleRegisterSku = () => {
    nav.skuRegistration().go();
  };
  const handleRegisterAdSku = () => {
    onStepCompleted();
  };

  return (
    <WelcomeStepComponent
      onNewSku={handleRegisterSku}
      onNewAdSku={handleRegisterAdSku}
      number={number}
      totalCount={totalCount}
      lastSkus={lastSkus}
      onRegisterInstance={handleRegisterInstance}
      LocationDialog={LocationDialog}
    />
  );
};
