import React from 'react';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';

import { useTranslation } from '../../../translations';
import { Maybe, GraphQlError } from '../../types';

type Props = {
  error?: Maybe<GraphQlError>;
};

export const ErrorView: React.FC<Props> = props => {
  const { t, tk } = useTranslation('common');
  const { error } = props;
  return (
    <Container>
      <Alert color="error">{error?.message || t(tk.errors.generic)}</Alert>
    </Container>
  );
};
