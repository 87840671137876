import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';

import { CheckboxSwatch } from '../CheckboxSwatch';

const useStyles = makeStyles(theme =>
  createStyles({
    swatch: {
      margin: theme.spacing(0, 1, 0, 0),
    },
  }),
);

export type ColorMenuItemProps = MenuItemProps & {
  button?: true | undefined;
  color: string;
};

export const ColorMenuItem: React.FC<ColorMenuItemProps> = ({
  color,
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <MenuItem {...props}>
      <CheckboxSwatch className={classes.swatch} size="small" color={color} />
      {children}
    </MenuItem>
  );
};
