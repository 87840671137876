import React, { FC } from 'react';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { useCategories } from '../../../context/useCategories';
import { MainCategoryStep as MainCategoryStepComponent } from '../../../components/NewSkuView/MainCategoryStep';
import { ResolvedMainCategory } from '../../../context/types';
import { useNewAdSkuActions } from '../../../redux/newAdSku';

export const MainCategoryStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onGoBack,
  onStepCompleted,
}) => {
  const categories = useCategories();
  const { setMainCategory } = useNewAdSkuActions();

  const handleSubmit = (category: ResolvedMainCategory) => {
    setMainCategory(category);
    onStepCompleted();
  };

  return (
    <MainCategoryStepComponent
      categories={categories}
      onChange={handleSubmit}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
    />
  );
};
