export const fetchBase64Image = async (url: string): Promise<string> => {
  const response = await fetch(url, {
    method: 'GET',
    // mode: 'no-cors',
    headers: {},
  });

  return response.blob().then(
    blob =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }),
  );
};
