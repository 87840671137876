import React, { FC, useMemo } from 'react';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { Form } from '../../../../common/components/Form';
import { DimensionsData } from '../../../redux/newSku';
import { SkuInput } from '../../../graphql';
import { makeUiKit } from '../uiKit';
import { FormFields } from '../../../../common/components/Form/types';

import { useDimensionsStepFormLayout } from './useDimensionsStepForm';

export const fieldNames = [
  'minHeight',
  'maxHeight',
  'minWidth',
  'maxWidth',
  'minSeatHeight',
  'maxSeatHeight',
  'minDepth',
  'maxDepth',
] as const;

export type DimensionStepProps = {
  fields: FormFields;
  backLabel: string;
  initialValues?: Partial<Pick<SkuInput, typeof fieldNames[number]>>;
  number: number;
  totalCount: number;
  subcategoryKey: string;
  onSubmit: (data: DimensionsData) => void;
  onGoBack?: () => void;
};

export const DimensionsStep: FC<DimensionStepProps> = ({
  backLabel,
  fields,
  initialValues,
  number,
  totalCount,
  subcategoryKey,
  onSubmit,
  onGoBack,
}) => {
  const { t, tk } = useTranslation('inventory');
  const layout = useDimensionsStepFormLayout(subcategoryKey);

  const uiKit = useMemo(
    () => makeUiKit(t(tk.newSkuView.dimensionsStep.confirm)),
    [t, tk],
  );

  return (
    <WizardStep
      backLabel={backLabel}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      title={t(tk.newSkuView.dimensionsStep.title)}
      data-testid="DimensionsStep"
    >
      <Form
        defaultValues={initialValues}
        fields={fields}
        layout={layout}
        onSubmit={onSubmit}
        uiKit={uiKit}
      />
    </WizardStep>
  );
};
