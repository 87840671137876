import React, { FC, useMemo } from 'react';

import { AdSkuQuery, useAdSkuQuery } from '../../graphql';
import { AdSkuView as AdSkuViewComponent } from '../../components/AdSkuView';
import { ErrorView } from '../../../common/components/ErrorView';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { extractQuery } from '../../../common/utils/extractQuery';
import { AdSkuPayload } from '../../graphql/types';
import { useCategories } from '../../context/useCategories';
import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../translations';
import { SkuFormInput } from '../../forms';
import { useColorMapping } from '../../context/useColorMapping';
import { useResolvedAdSku } from '../../utils/adSku';
import { useAdSkuFormFields } from '../../forms/useAdSkuForm';
import { getBreadcrumbs } from '../SkuView/breadcrumbs';
import { AdSkuInstanceListView } from '../AdSkuInstanceListView';
import { useCurrentTenantId } from '../../context/TenantContext';

import { useUpdateAdSku } from './useUpdateAdSku';

type Props = {
  id: string;
};

export const AdSkuView: FC<Props> = ({ id }) => {
  const nav = useNavigation();

  const { t, tk } = useTranslation('inventory');
  const rawSkuQuery = useAdSkuQuery({
    variables: { id },
  });
  const adSkuQuery = extractQuery<AdSkuQuery, AdSkuPayload>(
    rawSkuQuery,
    'AdSku',
    data => data.adSku,
  );
  const adSku = useResolvedAdSku(adSkuQuery.data);
  const colorMapping = useColorMapping();
  const categories = useCategories();
  const { updateAdSku, mutationResult, updateCompleted } = useUpdateAdSku();

  const contextTenantId = useCurrentTenantId();
  const isTenantFromContext = adSku?.tenant?.name === contextTenantId;

  const fields = useAdSkuFormFields(undefined, !isTenantFromContext);

  const breadcrumbs = useMemo(
    () =>
      getBreadcrumbs(categories, t(tk.skuListView.title), adSku, nav.adSkus),
    [categories, nav, adSku, t, tk.skuListView.title],
  );

  if (adSkuQuery.error || !adSku || mutationResult.loading) {
    if (adSkuQuery.loading || mutationResult.loading) {
      return <LoadingIndicator />;
    }
    return <ErrorView error={adSkuQuery.error} />;
  }

  const handleUpdate = (skuInput: SkuFormInput) => {
    updateAdSku(adSku, skuInput);
  };

  return (
    <>
      <AdSkuViewComponent
        breadcrumbs={breadcrumbs}
        categories={categories}
        colorMapping={colorMapping}
        error={mutationResult.error}
        adSku={adSku}
        fields={fields}
        onUpdate={isTenantFromContext ? handleUpdate : undefined}
        updateCompleted={updateCompleted}
      />
      <AdSkuInstanceListView adSku={adSku} />
    </>
  );
};
