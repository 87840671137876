import { StyleRules } from '@material-ui/core/styles';
import { ListSubheaderClassKey } from '@material-ui/core/ListSubheader';

import { DsBlack } from '../colors';

export const MuiListSubheader: Partial<StyleRules<ListSubheaderClassKey>> = {
  root: {
    color: DsBlack.main,
  },
};
