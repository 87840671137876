import { ReactNode, useMemo, ComponentType } from 'react';
import { TFunction } from 'i18next';

import { useTranslation } from '../../translations';
import { ResolvedMainCategory } from '../context/types';
import { SkuInput, Subcategory } from '../graphql';
import {
  FieldDefinition,
  FieldProps,
  FieldValues,
  FormLayout,
} from '../../common/components/Form/types';
import ensure from '../../common/utils';
import { useCategories } from '../context/useCategories';
import { useSkuTemplate } from '../context/useSkuTemplate';
import { SkuTemplatePayload } from '../graphql/types';
import { dimensionsConfiguration } from '../models/dimensions';
import { resolveColorHidden } from '../components/NewSkuView/ColorStep/util';

import { gridItemStandard, gridItemLarge, gridItemSmall } from './common';
import { resolveFields } from './utils';

export const skuFormFields = [
  'manufacturer',
  'description',
  'maxHeight',
  'maxSeatHeight',
  'maxWidth',
  'minWidth',
  'minHeight',
  'minSeatHeight',
  'minDepth',
  'maxDepth',
  'model',
  'primaryColor',
  'secondaryColor',
  'mainCategoryId',
  'subcategoryId',
  'serialNumber',
] as const;

export type SupportedSkuField = typeof skuFormFields[number];

export type SkuFormInput = Pick<SkuInput, SupportedSkuField>;

const getOverrides = (
  categories: ResolvedMainCategory[],
): Record<string, Partial<FieldDefinition>> => ({
  mainCategoryId: {
    type: 'SelectFieldDefinition',
    options: categories.map(c => ({
      label: c.name,
      value: c.id,
    })),
  },
  subcategoryId: {
    type: 'SelectFieldDefinition',
    disabled: values => !values.mainCategoryId,
    strict: true,
    options: values =>
      values.mainCategoryId
        ? ensure(
            categories.find(c => c.id === values.mainCategoryId),
          ).subcategories.map(c => ({
            label: c.name,
            value: c.id,
          }))
        : [],
  },
  description: {
    rows: 3,
  },
  minHeight: {
    required: false,
  },
  maxHeight: {
    required: false,
  },
  minSeatHeight: {
    required: false,
  },
  maxSeatHeight: {
    required: false,
  },
  minWidth: {
    required: false,
  },
  maxWidth: {
    required: false,
  },
  minDepth: {
    required: false,
  },
  maxDepth: {
    required: false,
  },
});

const getSubcategory = (
  values: FieldValues,
  categories: ResolvedMainCategory[],
) => {
  let sub: Subcategory | undefined;
  categories.forEach(c => {
    const currentSubcategory = c.subcategories.find(
      c => c.id === values.subcategoryId,
    );
    if (currentSubcategory) {
      sub = currentSubcategory;
    }
  });

  return sub;
};

export const getLabel = (
  t: TFunction,
  collection: Record<string, string>,
  values: FieldValues,
  categories: ResolvedMainCategory[],
) => {
  const sub = getSubcategory(values, categories);

  if (!sub || !collection[sub.key]) {
    return t(collection['default']);
  }

  return t(collection[sub.key]);
};

export const getSkuFormFields = (
  templateFields: SkuTemplatePayload['fields'],
  categories: ResolvedMainCategory[],
  keys: readonly SupportedSkuField[] = skuFormFields,
) => {
  return resolveFields(templateFields, getOverrides(categories), keys);
};

export const useSkuFormFields = (
  keys: readonly SupportedSkuField[] = skuFormFields,
): Record<string, FieldDefinition> => {
  const categories = useCategories();
  const { fields } = useSkuTemplate();

  return useMemo(() => getSkuFormFields(fields, categories, keys), [
    fields,
    categories,
    keys,
  ]);
};

const getDimensionFieldConfig = (
  fieldName: string,
  categories: ResolvedMainCategory[],
  values: FieldValues,
) => {
  const sub = getSubcategory(values, categories);

  if (sub) {
    if (!dimensionsConfiguration[sub.key]) {
      return null;
    }
    return dimensionsConfiguration[sub.key][fieldName];
  }
};

export const isHiddenSecondaryColor = (
  categories: ResolvedMainCategory[],
  values: FieldValues,
) => {
  const sub = getSubcategory(values, categories);
  if (sub) {
    return resolveColorHidden(sub.key);
  }
  return false;
};

export const isHiddenInSubcategory = (
  fieldName: string,
  categories: ResolvedMainCategory[],
  values: FieldValues,
) => {
  const conf = getDimensionFieldConfig(fieldName, categories, values);

  return conf?.hidden || false;
};

export const getDimensionFieldLabel = (
  label: string,
  groupLabel: string,
  fieldName: string,
  categories: ResolvedMainCategory[],
  values: FieldValues,
) => {
  const config = getDimensionFieldConfig(fieldName, categories, values);

  return config?.showGroupLabel ? groupLabel : label;
};

/* eslint-disable-next-line max-lines-per-function */
export const useSkuFormLayout = (
  categories: ResolvedMainCategory[],
  ColorFieldComponent: ComponentType<FieldProps>,
  component: ReactNode,
): FormLayout => {
  const { t, tk } = useTranslation('inventory');
  return useMemo(
    /* eslint-disable-next-line max-lines-per-function */
    () => [
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemLarge,
            name: 'mainCategoryId',
            label: t(tk.fieldLabels.mainCategoryId),
          },
          {
            gridItemProps: gridItemLarge,
            name: 'subcategoryId',
            label: t(tk.fieldLabels.subcategoryId),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemLarge,
            name: 'manufacturer',
            label: t(tk.fieldLabels.manufacturer),
          },
          {
            gridItemProps: gridItemLarge,
            name: 'model',
            label: t(tk.fieldLabels.model),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'serialNumber',
            label: t(tk.fieldLabels.serialNumber),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'primaryColor',
            label: values =>
              getLabel(t, tk.fieldLabels.primaryColor, values, categories),
            Component: ColorFieldComponent,
          },
          {
            gridItemProps: gridItemStandard,
            name: 'secondaryColor',
            label: values =>
              getLabel(t, tk.fieldLabels.secondaryColor, values, categories),
            Component: ColorFieldComponent,
            hidden: values => isHiddenSecondaryColor(categories, values),
          },
        ],
      },
      {
        type: 'component',
        component,
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemSmall,
            name: 'minHeight',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.minHeight),
                t(tk.fieldLabels.height),
                'minHeight',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('minHeight', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'maxHeight',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.maxHeight),
                t(tk.fieldLabels.height),
                'minHeight',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('maxHeight', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'minSeatHeight',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.minSeatHeight),
                t(tk.fieldLabels.seatHeight),
                'minSeatHeight',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('minSeatHeight', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'maxSeatHeight',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.maxSeatHeight),
                t(tk.fieldLabels.seatHeight),
                'maxSeatHeight',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('maxSeatHeight', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'minWidth',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.minWidth),
                t(tk.fieldLabels.width),
                'minWidth',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('minWidth', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'maxWidth',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.maxWidth),
                t(tk.fieldLabels.width),
                'maxWidth',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('maxWidth', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'minDepth',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.minDepth),
                t(tk.fieldLabels.depth),
                'minDepth',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('minDepth', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'maxDepth',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.maxDepth),
                t(tk.fieldLabels.depth),
                'maxDepth',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('maxDepth', categories, values),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 12 },
            name: 'description',
            label: t(tk.fieldLabels.description),
          },
        ],
      },
    ],
    [t, tk, component, categories, ColorFieldComponent],
  );
};
