import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  FunctionalityValue,
  ResolvedOptionSetFieldConfig,
} from '../../../../models';

import { PromptLabel } from './PromptLabel';
import { SelectableButton } from './SelectableButton';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),
);

type Props = {
  field: ResolvedOptionSetFieldConfig;
  values: Record<string, FunctionalityValue>;
  onChange?: (changed: Record<string, FunctionalityValue>) => void;
  limitedTo?: string[];
};

export const FunctionalityOptionSet: FC<Props> = ({
  field,
  values,
  limitedTo,
  onChange,
}) => {
  const { key, options, promptLabel } = field;
  const classes = useStyles();

  const handleClick = (value: string) => () => {
    if (onChange) {
      onChange({ [key]: value });
    }
  };

  const displayedOptions = limitedTo
    ? options.filter(o => limitedTo.includes(o.value))
    : options;

  return (
    <Box mb={5}>
      <PromptLabel>{promptLabel}</PromptLabel>
      {displayedOptions.map(({ value, label }) => (
        <SelectableButton
          className={classes.button}
          key={value}
          onClick={handleClick(value)}
          selected={values[key] === value}
        >
          {label}
        </SelectableButton>
      ))}
    </Box>
  );
};
