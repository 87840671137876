/*
 *
 * ConfigDispatcher is preventing the component tree from render until config is loaded.
 * It loads the config.json file and puts its contests in the redux store.
 *
 */

import React, { FC, useState, useEffect } from 'react';
import { CacheLocation } from '@auth0/auth0-spa-js';

import { LoadingIndicator } from '../components/LoadingIndicator';
import { ErrorView } from '../components/ErrorView';

type Config = {
  apiUrl: string;
  authAudience: string;
  authClientId: string;
  authDomain: string;
  authCacheLocation: CacheLocation;
  mediaUrlBase: string;
  mediaTempUrlBase: string;
};

export const ConfigContext = React.createContext<Config>({} as Config);

type Status = 'loading' | 'loaded' | 'failed';

type Props = {
  configUrl: string;
};

export const ConfigProvider: FC<Props> = ({ children, configUrl }) => {
  const [status, setStatus] = useState<Status>('loading');
  const [config, setConfig] = useState<Config | null>(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setStatus('loading');
        const response = await fetch(configUrl);
        const json = (await response.json()) as Config;
        setConfig(json);
        setStatus('loaded');
      } catch {
        setStatus('failed');
      }
    };

    fetchConfig();
  }, [configUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  if (status === 'failed') {
    return <ErrorView />;
  }

  if (status === 'loading' || !config) {
    return <LoadingIndicator />;
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
