import { useMemo } from 'react';

import { Config, SelectedFilters, useFilters } from '../../utils/useFilters';
import { useAdSkuInstanceTemplate } from '../../context/useAdSkuInstanceTemplate';
import {
  getPriceRangeFilter,
  getSalesPeriodFilter,
} from '../common/customFilters';

const config: Config = [
  {
    field: 'status',
    template: 'adSkuInstance',
    singleSelect: false,
    type: 'items',
  },
  getPriceRangeFilter(),
  getSalesPeriodFilter(),
  {
    field: 'condition',
    template: 'adSkuInstance',
    singleSelect: false,
    type: 'items',
  },
  {
    field: 'skuAdProcessingStage',
    template: 'adSkuInstance',
    singleSelect: false,
    type: 'items',
  },
];

export const useAdSkuInstanceFilters = (selectedFilters: SelectedFilters) => {
  const adSkuInstanceTemplate = useAdSkuInstanceTemplate();
  const templates = useMemo(() => [adSkuInstanceTemplate], [
    adSkuInstanceTemplate,
  ]);

  return useFilters(config, templates, adSkuInstanceTemplate, selectedFilters);
};
