import { Backdrop, Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import { LoadingIndicator } from '../LoadingIndicator';
import { theme } from '../../../theme';
import { useTranslation } from '../../../translations';

type Props = {
  open: boolean;
};

const useStyles = makeStyles(() => ({
  typography: {
    marginTop: '32px',
  },
}));

export const BackdropLoading = ({ open }: Props) => {
  const { t, tk } = useTranslation('common');

  const classes = useStyles();

  return (
    <Backdrop
      open={open}
      style={{
        zIndex: theme.zIndex.drawer + 1,
        background: theme.palette.background.default,
      }}
    >
      <Box display="flex" flexDirection="column">
        <LoadingIndicator size="large" />

        <Typography className={classes.typography} variant="subtitle2">
          {t(tk.actions.download)}
        </Typography>
      </Box>
    </Backdrop>
  );
};
