import React, { FC, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { ResolvedSku } from '../../../types';
import { Maybe } from '../../../graphql';
import { Navigation } from '../../../../common/navigation';
import { StatusChip } from '../../SkuInstanceListView/SkuInstanceList/StatusChip';
import { SkuCard } from '../../common/SkuCard/SkuCard';
import { RegistrationCompleteDialog } from '../../common/RegistrationCompleteDialog';

const useStyles = makeStyles(theme =>
  createStyles({
    body: {
      background: theme.palette.success.veryLight,
    },
    description: {
      color: theme.palette.primary.light,
    },
  }),
);

type Props = {
  sku: Maybe<ResolvedSku>;
  count: number;
  nav: Navigation;
};

export const PreregistrationSuccessStep: FC<Props> = ({ sku, count, nav }) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  const skuLabel = sku ? `${sku.manufacturer} ${sku.model}` : '';

  const handleListingSku = () => {
    if (sku) {
      nav.sku({ id: sku.id }).go();
    }
  };

  const handleRegisterNew = () => {
    nav.registration().go();
  };

  const handleViewInventory = () => {
    nav.skus().go();
  };

  useEffect(() => {
    document.body.classList.add(classes.body);
    return () => {
      document.body.classList.remove(classes.body);
    };
  }, [classes.body]);

  return (
    <WizardStep
      showProgress={false}
      title={t(tk.newSkuInstanceView.preregistrationSuccessStep.title)}
      data-testid="PreregistrationSuccessStep"
    >
      <Typography variant="subtitle1"></Typography>
      <Box mt={2} mb={3}>
        {sku && (
          <SkuCard
            sku={sku}
            title={t(
              tk.newSkuInstanceView.preregistrationSuccessStep.skuCardTitle,
              {
                count,
              },
            )}
            btnLabel={t(
              tk.newSkuInstanceView.preregistrationSuccessStep
                .skuCardButtonLabel,
            )}
            onClick={handleListingSku}
          />
        )}
      </Box>
      <Box mb={1.5}>
        <Typography variant="subtitle1">
          <Trans
            i18nKey={tk.newSkuInstanceView.preregistrationSuccessStep.subtitle}
            values={{
              count,
              sku: skuLabel,
            }}
            ns="inventory"
          />
        </Typography>
      </Box>
      <Typography variant="body1" className={classes.description}>
        <Trans
          i18nKey={tk.newSkuInstanceView.preregistrationSuccessStep.description}
          components={[
            <StatusChip status="pre-registered" size="small" key="0" />,
          ]}
          ns="inventory"
        />
      </Typography>
      <RegistrationCompleteDialog
        handleRegisterNew={handleRegisterNew}
        handleViewInventory={handleViewInventory}
      />
    </WizardStep>
  );
};
