import React, { FC } from 'react';

import { useApolloClient } from '../setup/useApolloClient';
import { UserProvider } from '../../inventory/context/UserContext';

export const AppUserProvider: FC = ({ children }) => {
  const client = useApolloClient();

  return <UserProvider client={client}>{children}</UserProvider>;
};
