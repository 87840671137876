import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { useTranslation } from '../../translations';

export const Head: FC = () => {
  const { t, tk } = useTranslation('inventory');
  return (
    <Helmet>
      <title>{t(tk.head.title)}</title>
    </Helmet>
  );
};
