import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { SkuInstancesPayload } from '../../graphql/types';
import { useTranslation } from '../../../translations';
import { ResolvedSku } from '../../types';
import { SkuImage } from '../common/SkuImage';
import { formatOfficeAddress } from '../../utils/format';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'block',
      margin: theme.spacing(0, 'auto', 2),
      width: 700,
      '@media print': {
        pageBreakInside: 'avoid',
      },
      border: '1px solid #000',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
    },
    media: {
      display: 'block',
      maxWidth: '50%',
      maxHeight: 200,
      objectFit: 'contain',
      borderRadius: 8,
      marginRight: theme.spacing(3),
    },
    instance: {
      '@media print': {
        pageBreakInside: 'avoid',
      },
      padding: theme.spacing(1, 0),
    },
    divider: {
      backgroundColor: theme.palette.primary.light,
    },
  }),
);

type Props = {
  sku: ResolvedSku;
  skuInstances: SkuInstancesPayload['skuInstances'];
};

export const SkuInstanceReportView: FC<Props> = ({ sku, skuInstances }) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');

  const { images, primaryColor, secondaryColor } = sku;
  const image = images && images[0];

  return (
    <div className={classes.root}>
      <Box display="flex" mb={2}>
        {image && (
          <SkuImage image={image} className={classes.media} sizes="50%" />
        )}
        <div>
          <Typography variant="subtitle1" gutterBottom>
            {sku.manufacturer}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {sku.model} / {sku.serialNumber}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {primaryColor?.label} / {secondaryColor?.label}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {sku.description}
          </Typography>
        </div>
      </Box>
      <Typography variant="subtitle2" gutterBottom>
        {`${t(tk.skuReportView.instances)}: ${skuInstances.length}`}
      </Typography>
      <Divider className={classes.divider} />
      {skuInstances.map((instance, idx) => (
        <div key={instance.id} className={classes.instance}>
          <Typography variant="subtitle2" gutterBottom>
            {instance.id}
          </Typography>{' '}
          {instance.office?.__typename === 'Office' && (
            <Typography variant="body1" gutterBottom>
              {`${t(tk.fieldLabels.officeId)}: ${formatOfficeAddress(
                instance.office,
              )}`}
            </Typography>
          )}
          {instance.officeFloor?.__typename === 'OfficeFloor' && (
            <Typography variant="body1" gutterBottom>
              {`${t(tk.fieldLabels.officeFloorId)}: ${
                instance.officeFloor.floorLabel
              }`}
              {instance.room && `, ${t(tk.fieldLabels.room)}: ${instance.room}`}
            </Typography>
          )}
          <Typography variant="body1" gutterBottom>
            {t(tk.skuInstanceListView.item.status, { status: instance.status })}
          </Typography>
          {instance.condition && (
            <Typography variant="body1" gutterBottom>
              {t(tk.skuInstanceListView.item.condition, {
                condition: instance.condition,
              })}
            </Typography>
          )}
          {instance.processingStage && (
            <Typography variant="body1" gutterBottom>
              {`${t(tk.fieldLabels.processingStage)}: ${
                instance.processingStage
              }`}
              {instance.processingStageDateFrom &&
                ` ${t(tk.skuReportView.from)} ${
                  instance.processingStageDateFrom
                }`}
              {instance.processingStageDateTo &&
                ` ${t(tk.skuReportView.to)} ${instance.processingStageDateTo}`}
            </Typography>
          )}
          {instance.note && (
            <Typography variant="body1" gutterBottom>
              {`${t(tk.fieldLabels.note)}: ${instance.note}`}
            </Typography>
          )}
          {idx < skuInstances.length - 1 && (
            <Divider className={classes.divider} />
          )}
        </div>
      ))}
    </div>
  );
};
