import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { useTranslation } from '../../../translations';

const useStyles = makeStyles(theme =>
  createStyles({
    buttonContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
    },
    button: {
      flex: 1,
      backgroundColor: theme.palette.secondary.veryLight,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    buttonSuccess: {
      backgroundColor: theme.palette.success.veryLight,
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
    },
    description: {
      color: theme.palette.primary.light,
    },
    actionCard: {
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.only('xs')]: {
        position: 'fixed',
        width: '100%',
        zIndex: theme.zIndex.modal,
        bottom: 0,
        left: 0,
      },
      background: theme.palette.success.light,
    },
    actionCardContent: {
      padding: theme.spacing(2, 1.5),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2),
      },
    },
  }),
);

type Props = {
  handleRegisterNew: () => void;
  handleViewInventory: () => void;
};

export const RegistrationCompleteDialog: FC<Props> = ({
  handleRegisterNew,
  handleViewInventory,
}) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  return (
    <Card className={classes.actionCard}>
      <CardContent className={classes.actionCardContent}>
        <Typography variant="subtitle1" gutterBottom>
          <strong>
            {t(tk.newSkuInstanceView.preregistrationSuccessStep.actionsTitle)}
          </strong>
        </Typography>
        <div className={classes.buttonContainer}>
          <Button
            color="secondary"
            className={cn(classes.buttonSuccess, classes.button)}
            onClick={handleRegisterNew}
          >
            {t(tk.newSkuInstanceView.summaryStep.completedDialog.registerNew)}
          </Button>
          <Box ml={2} />
          <Button
            className={classes.button}
            variant="outlined"
            color="secondary"
            onClick={handleViewInventory}
          >
            {t(tk.newSkuInstanceView.summaryStep.completedDialog.viewInventory)}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
