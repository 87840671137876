import { useEffect, useState } from 'react';

import {
  FunctionalityValueMap,
  ResolvedFunctionalitySubcategoryConfig,
} from '../../../models';
import { needsConfiguration } from '../../common/SkuFunctionalityInput/utils';

export const useFunctionalityState = (
  config: ResolvedFunctionalitySubcategoryConfig,
  initialValues: FunctionalityValueMap = {},
) => {
  const [values, setValues] = useState(initialValues);
  const { groups } = config;
  useEffect(() => {
    setValues(initialValues);
  }, [config, initialValues]);

  const isReady = !groups.some(group => needsConfiguration(group, values));
  return {
    values,
    setValues,
    isReady,
  };
};
