import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import MenuItem from '@material-ui/core/MenuItem';

import { TextField } from '../../../common/components/TextField';
import { Tenant } from '../../../inventory/graphql';

const useStyles = makeStyles(theme =>
  createStyles({
    switch: {
      margin: theme.spacing(4),
    },
  }),
);

export type TenantSwitchProps = {
  current?: string;
  tenants?: Pick<Tenant, 'id' | 'name' | 'displayName'>[];
  onChange: (tenantId: string) => void;
};

export const TenantSwitch: FC<TenantSwitchProps> = ({
  current,
  tenants,
  onChange,
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.value);

  if (!tenants || tenants.length < 2) {
    return null;
  }

  return (
    <TextField
      name="tenantId"
      margin="normal"
      className={classes.switch}
      value={current}
      select
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
      onChange={handleChange}
    >
      {tenants.map(({ id, name, displayName }) => (
        <MenuItem key={id} value={name}>
          {displayName || name}
        </MenuItem>
      ))}
    </TextField>
  );
};
