import { StyleRules } from '@material-ui/core/styles';
import { CheckboxClassKey } from '@material-ui/core/Checkbox';

import { DsBlack } from '../colors';

export const MuiCheckbox: Partial<StyleRules<CheckboxClassKey>> = {
  root: {
    color: DsBlack.main,
  },
};
