import MuiLink from '@material-ui/core/Link';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(() =>
  createStyles({
    logout: {
      textAlign: 'right',
    },
  }),
);

type Props = {
  onLogout?: () => void;
};

export const LogoutBar: React.FC<Props> = ({ onLogout }) => {
  const classes = useStyles();

  return (
    <Container className={classes.logout}>
      <>
        {onLogout && (
          <MuiLink href="#" onClick={onLogout}>
            Logout
          </MuiLink>
        )}
      </>
    </Container>
  );
};
