import { camelCase } from 'lodash';

import {
  CreateSubcategoryMutation,
  MainCategory,
  Subcategory,
  useCreateSubcategoryMutation,
} from '../../graphql';

export function useSubcategoryCreate(
  handleSubmit: (subcategoryId: string) => void,
  mainCategoryId: string | undefined,
  mainCategory:
    | ({ __typename: 'MainCategory' } & Pick<
        MainCategory,
        'id' | 'name' | 'key'
      > & {
          subcategories: Array<
            { __typename: 'Subcategory' } & Pick<
              Subcategory,
              'id' | 'name' | 'key'
            >
          >;
        })
    | undefined
    | null,
) {
  const extractSubcategory = (
    subcategoryMutationData: CreateSubcategoryMutation,
  ): Subcategory | null | undefined => {
    if (
      subcategoryMutationData.createSubcategory &&
      subcategoryMutationData.createSubcategory.__typename ===
        'CreateSubcategoryPayload'
    ) {
      return subcategoryMutationData.createSubcategory.subcategory;
    }
  };

  const handleSubcategoryMutationComplete = (
    subcategoryMutationData: CreateSubcategoryMutation,
  ) => {
    const subcategory = extractSubcategory(subcategoryMutationData);

    if (subcategory) {
      handleSubmit(subcategory.id);
    }
  };

  const [createSubcategory, { error }] = useCreateSubcategoryMutation({
    refetchQueries: ['Context'],
    awaitRefetchQueries: true,
    onCompleted: handleSubcategoryMutationComplete,
  });

  const handleSubcategorySubmit = async (label: string | null) => {
    if (label && mainCategoryId && mainCategory) {
      if (
        !mainCategory.subcategories.some(
          subCat => subCat.name.toLowerCase() === label.toLowerCase(),
        )
      ) {
        return createSubcategory({
          variables: {
            input: {
              subcategory: {
                mainCategoryId,
                name: label,
              },
            },
          },
        });
      }

      const subcategory = mainCategory.subcategories.find(
        subcategory => subcategory.key === camelCase(label),
      );

      if (subcategory) {
        handleSubmit(subcategory.id);
      }
    }
  };
  return { error, handleSubcategorySubmit };
}
