import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { useTranslation } from '../../../translations';

export type CheckboxListType = 'items' | 'nodes';

const useStyles = makeStyles(theme =>
  createStyles({
    checkbox: {
      padding: 0,
    },
    listItemIcon: {
      minWidth: 24,
    },
    showMoreItem: {
      marginTop: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 0,
    },
    showMoreText: {
      fontWeight: 500,
    },
  }),
);

export type CheckboxListBaseProps = {
  className?: string;
  expanded?: boolean;
  title: string;
  onExpand: () => void;
};

export const CheckboxListBase: React.FC<CheckboxListBaseProps> = ({
  children,
  className,
  expanded = true,
  title,
  onExpand,
  ...props
}) => {
  const { t, tk } = useTranslation('common');
  const classes = useStyles();

  return (
    <List
      className={className}
      dense={true}
      subheader={
        <ListSubheader disableGutters disableSticky>
          {title}
        </ListSubheader>
      }
      {...props}
    >
      {children}
      {!expanded && (
        <ListItem
          className={classes.showMoreItem}
          button
          disableGutters
          dense
          onClick={onExpand}
        >
          <ListItemText
            data-testid="ShowMore"
            primary={t(tk.components.CheckboxList.showMore)}
            primaryTypographyProps={{
              variant: 'body1',
              className: classes.showMoreText,
            }}
          />
        </ListItem>
      )}
    </List>
  );
};
