import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { Controller } from 'react-hook-form';
import type { Control, FieldError } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';

import { TextField } from '../../TextField';

type Options = readonly { label: string; value: string }[];

type Props = Omit<TextFieldProps, 'onChange' | 'error'> & {
  control: Control;
  name: string;
  error?: FieldError;
  endAdornment?: string | React.ReactNode;
  options?: Options;
};

export const ControlledTextField: React.FC<Props> = props => {
  const { control, error, name, endAdornment, ...textFieldProps } = props;

  return (
    <Controller
      as={
        <TextField
          name={name}
          error={!!error}
          helperText={error?.message}
          {...textFieldProps}
          InputProps={
            {
              notched: false,
              endAdornment: endAdornment && (
                <InputAdornment position="end">
                  <EndAdormentTypography variant="subtitle2">
                    {endAdornment}
                  </EndAdormentTypography>
                </InputAdornment>
              ),
            } as Partial<OutlinedInputProps>
          }
        />
      }
      name={name}
      control={control}
    />
  );
};

export const EndAdormentTypography = withStyles(theme =>
  createStyles({
    root: {
      color: theme.palette.primary.light,
    },
  }),
)(Typography);
