import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { StepComponentProps } from '../../../common/components/Wizard/types';
import * as selectors from '../../redux/newAdSku/selectors';

import { StepId } from './types';

export const fieldNames = ['manufacturer', 'model'];

export const useAssertCategory = (
  onGoToStep: StepComponentProps['onGoToStep'],
) => {
  const subcategoryName = useSelector(selectors.subcategoryName);
  const mainCategoryKey = useSelector(selectors.mainCategoryKey);

  useEffect(() => {
    if (!mainCategoryKey) {
      return onGoToStep(StepId.MainCategory);
    }

    if (!subcategoryName) {
      return onGoToStep(StepId.Subcategory);
    }
  }, [mainCategoryKey, onGoToStep, subcategoryName]);
};
