import React from 'react';

import { Props } from '../types';

import { useStyles } from './styles';

export const ArrowLeft = (props: Props) => {
  const classes = useStyles(props);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      className={classes.root}
      {...props}
    >
      <path
        d="M15.41 6.66l-4.58 4.59 4.58 4.59L14 17.25l-6-6 6-6 1.41 1.41z"
        fill="#3A352D"
      />
    </svg>
  );
};
