import React, { FC } from 'react';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { ResolvedMainCategory } from '../../../context/types';
import { DynamicButtonSelect } from '../../../../common/components/DynamicButtonSelect';

type Props = {
  mainCategory: ResolvedMainCategory;
  number: number;
  totalCount: number;
  onChange: (categoryId: string) => void;
  onGoBack?: () => void;
  onSubcategorySubmit?: (label: string | null) => void;
};

export const SubcategoryStep: FC<Props> = ({
  mainCategory,
  number,
  totalCount,
  onChange,
  onGoBack,
  onSubcategorySubmit,
}) => {
  const { t, tk } = useTranslation('inventory');

  const items = mainCategory.subcategories.map(c => ({
    label: c.name,
    onClick: () => onChange(c.id),
  }));

  const allowInput = mainCategory.name.toLowerCase() === 'various';

  return (
    <WizardStep
      backLabel={mainCategory.name}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      title={t(tk.newSkuView.subcategoryStep.title)}
      data-testid="SubcategoryStep"
    >
      <DynamicButtonSelect
        items={items}
        onInputButtonSubmit={onSubcategorySubmit}
        allowInput={allowInput}
      ></DynamicButtonSelect>
    </WizardStep>
  );
};
