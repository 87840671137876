import { Maybe } from '../../common/types';

export enum FunctionalityFieldDisplayType {
  Bool = 'BOOL',
  String = 'STRING',
  Number = 'NUMBER',
}

export enum FunctionalityEditGroup {
  Boolean = 'Boolean',
  Group = 'Group',
}

export enum FunctionalityEditRow {
  BooleanGroup = 'BooleanGroup',
  OptionSet = 'OptionSet',
}

export enum FunctionalityFilterRow {
  Boolean = 'Boolean',
  OptionSet = 'OptionSet',
}

export type FunctionalityValue = Maybe<string | number | boolean>;

export type FunctionalityValueMap = Record<string, FunctionalityValue>;

export type Field = {
  key: string;
  label: string;
  type: FunctionalityFieldDisplayType;
  groupBy?: string;
};

export type AttributeGroupField = BooleanFieldConfig | OptionSetFieldConfig;

export type FunctionalityGroupField = Field & {
  groupBy?: string;
};

export type BooleanFieldConfig = Field & {
  type: FunctionalityFieldDisplayType.Bool;
  editLabel?: string;
};

export type BooleanFunctionalityFieldConfig = BooleanFieldConfig &
  FunctionalityGroupField;

export type OptionSetFieldConfig = Field & {
  type:
    | FunctionalityFieldDisplayType.String
    | FunctionalityFieldDisplayType.Number;
  promptLabel: string;
  valueEditLabels?: Record<string, string>;
  valueViewLabels?: Record<string, string>;
};

export type ResolvedOptionSetFieldConfig = Omit<
  OptionSetFieldConfig,
  'valueEditLabels' | 'valueViewLabels'
> & {
  options: {
    value: string;
    label: string;
  }[];
};

export type NumberEditableField = Field & {
  type: FunctionalityFieldDisplayType.Number;
  promptLabel: string;
};

type OptionSetEditRow = {
  type: FunctionalityEditRow.OptionSet;
  field: OptionSetFieldConfig;
  limitedTo?: string[];
};

type ResolvedOptionSetEditRow = Omit<OptionSetEditRow, 'field'> & {
  field: ResolvedOptionSetFieldConfig;
};

type BooleanGroupEditRow = {
  type: FunctionalityEditRow.BooleanGroup;
  promptLabel: string;
  exclusiveFields?: string[];
  fields: BooleanFieldConfig[];
};

export type FunctionalityEditGroupRow = OptionSetEditRow | BooleanGroupEditRow;

type ResolvedFunctionalityEditGroupRow =
  | ResolvedOptionSetEditRow
  | BooleanGroupEditRow;

export type BooleanFunctionalityEditGroup = {
  type: FunctionalityEditGroup.Boolean;
  noValueLabel?: string;
  field: BooleanFieldConfig;
};

export type ComplexFunctionalityEditGroup = {
  type: FunctionalityEditGroup.Group;
  noValueLabel?: string;
  requiredFieldKeys?: string[];
  rows: Array<FunctionalityEditGroupRow>;
};

export type ResolvedComplexFunctionalityEditGroup = Omit<
  ComplexFunctionalityEditGroup,
  'rows' | 'requiredFieldKeys'
> & {
  editableFieldKeys: string[];
  requiredFieldKeys: string[];
  rows: ResolvedFunctionalityEditGroupRow[];
};

export type FunctionalityEditConfig =
  | BooleanFunctionalityEditGroup
  | ComplexFunctionalityEditGroup;

export type ResolvedFunctionalityEditConfig =
  | BooleanFunctionalityEditGroup
  | ResolvedComplexFunctionalityEditGroup;

export type HideWhenRule = {
  field: string;
  value: string | boolean;
};

export type FunctionalityGroup = {
  name: string;
  label: string;
  attributes: AttributeGroupField[];
  functionalities: FunctionalityGroupField[];
  editConfig?: FunctionalityEditConfig;
  hideRule?: (functionalities: Record<string, FunctionalityValue>) => boolean;
};

export type ResolvedFunctionalityGroup = Omit<
  FunctionalityGroup,
  'editConfig'
> & {
  editConfig?: ResolvedFunctionalityEditConfig;
};

export type FunctionalitySubcategoryConfig = {
  defaultValues?: FunctionalityValueMap;
  fields: Record<string, AttributeGroupField | FunctionalityGroupField>;
  groups: FunctionalityGroup[];
  processValues?: (values: FunctionalityValueMap) => FunctionalityValueMap;
};

export type ResolvedFunctionalitySubcategoryConfig = Omit<
  FunctionalitySubcategoryConfig,
  'groups' | 'defaultValues'
> & {
  defaultValues: FunctionalityValueMap;
  groups: ResolvedFunctionalityGroup[];
};

export type BooleanFilterRow = {
  type: FunctionalityFilterRow.Boolean;
  field: BooleanFieldConfig;
  inverse?: boolean;
  label?: string;
};

export type OptionSetFilterRow = {
  type: FunctionalityFilterRow.OptionSet;
  field: OptionSetFieldConfig;
  variant: 'inline' | 'select';
};

export type FunctionalityFilterGroup = {
  label: string;
  singleSelect?: boolean;
  rows: (BooleanFilterRow | OptionSetFilterRow)[];
};

export type FunctionalityFilters = {
  title: string;
  groups: FunctionalityFilterGroup[];
};

export type ResolvedOptionSetFilterRow = Omit<OptionSetFilterRow, 'field'> & {
  field: ResolvedOptionSetFieldConfig;
};

export type ResolvedFunctionalityFilterGroup = Omit<
  FunctionalityFilterGroup,
  'rows'
> & {
  rows: (BooleanFilterRow | ResolvedOptionSetFilterRow)[];
};

export type ResolvedFunctionalityFilters = Omit<
  FunctionalityFilters,
  'groups'
> & {
  groups: ResolvedFunctionalityFilterGroup[];
};
