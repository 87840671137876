import React from 'react';
import MuiDivider, { DividerProps } from '@material-ui/core/Divider';
import { makeStyles, createStyles } from '@material-ui/core/styles';

type Props = DividerProps & {
  thickness?: number;
  color?: 'primary' | 'secondary';
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: ({ color = 'primary' }: Props) =>
        theme.palette[color].main,
      height: ({ thickness }: Partial<Props>) => thickness,
    },
    vertical: {
      width: ({ thickness }: Partial<Props>) => thickness,
    },
  }),
);

export const Divider: React.FC<Props> = props => {
  const { thickness = 2, color = 'primary', ...dividerProps } = props;
  const classes = useStyles({ color, thickness });
  return <MuiDivider classes={classes} {...dividerProps} />;
};
