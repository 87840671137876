import { State as LocalState } from './reducer';

export type State = {
  inventory: {
    newSkuInstance: LocalState;
  };
};

export const root = (state: State) => state.inventory.newSkuInstance;

export const count = (state: State) => root(state).count;

export const preregistrationSuccess = (state: State) =>
  root(state).preregistrationSuccess;

export const inputs = (state: State) => root(state).inputs;
