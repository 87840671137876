import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';

import {
  sofaChaiseLounge,
  sofaFeet,
  sofaSeats,
  sofaShape,
  sofaWidth,
  sofaType,
} from './fields';

export const getFields = (t: TFunction, tk: TKeys<'functionality'>) => ({
  sofaChaiseLounge: sofaChaiseLounge(t, tk),
  sofaFeet: sofaFeet(t, tk),
  sofaSeats: sofaSeats(t, tk),
  sofaShape: sofaShape(t, tk),
  sofaWidth: sofaWidth(t, tk),
  sofaType: sofaType(t, tk),
});

export type SofaFields = ReturnType<typeof getFields>;
