import React, { FC } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useTranslation } from '../../../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityValue,
  ResolvedFunctionalityGroup,
} from '../../../../models';
import { needsConfiguration } from '../utils';
import { RegistrationDialog } from '../../RegistrationDialog';

import { FunctionalityOptionSet } from './FunctionalityOptionSet';
import { FunctionalityBooleanGroup } from './FunctionalityBooleanGroup';

const useStyles = makeStyles(theme =>
  createStyles({
    reset: {
      color: theme.palette.error.main,
      display: 'block',
      margin: theme.spacing(0, 'auto'),
    },
  }),
);

type Props = {
  open: boolean;
  onClose?: () => void;
  group: ResolvedFunctionalityGroup & {
    editConfig: Extract<
      ResolvedFunctionalityGroup['editConfig'],
      { type: FunctionalityEditGroup.Group }
    >;
  };
  values: Record<string, FunctionalityValue>;
  initialValues: Record<string, FunctionalityValue>;
  onChange?: (changed: Record<string, FunctionalityValue>) => void;
};

export const FunctionalityGroupDialog: FC<Props> = ({
  group,
  values,
  initialValues,
  onChange,
  open,
  onClose,
}) => {
  const { t: tc, tk: tkc } = useTranslation('common');
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  const { editConfig, label, name } = group;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onReset = () => {
    onChange &&
      onChange({
        ...group.attributes.reduce(
          (values: Record<string, FunctionalityValue>, a) => {
            values[a.key] = initialValues[a.key];
            return values;
          },
          {},
        ),
        ...group.functionalities.reduce(
          (values: Record<string, FunctionalityValue>, a) => {
            values[a.key] = initialValues[a.key];
            return values;
          },
          {},
        ),
      });
  };

  const submitDisabled = needsConfiguration(group, values);
  return (
    <RegistrationDialog
      DialogProps={{ fullScreen }}
      title={label}
      open={open}
      titleId={`functionality-group-dialog-${name}`}
      onClose={onClose}
      actions={
        <Button
          disabled={submitDisabled}
          onClick={onClose}
          fullWidth
          size="large"
          color="secondary"
        >
          {tc(tkc.actions.confirm)}
        </Button>
      }
      withPadding
    >
      {editConfig.rows.map(row => {
        switch (row.type) {
          case FunctionalityEditRow.BooleanGroup:
            return (
              <FunctionalityBooleanGroup
                {...row}
                key={row.promptLabel}
                values={values}
                onChange={onChange}
              />
            );
          case FunctionalityEditRow.OptionSet:
            return (
              <FunctionalityOptionSet
                {...row}
                key={row.field.key}
                values={values}
                onChange={onChange}
              />
            );
        }
      })}
      <Link className={classes.reset} component="button" onClick={onReset}>
        {t(tk.newSkuView.functionalityStep.reset)}
      </Link>
    </RegistrationDialog>
  );
};
