import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useTranslation } from '../../../translations';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import {
  DeleteAdSkuInstanceMutationPayload,
  DeleteSkuInstanceMutationPayload,
} from '../../graphql/types';
import { MutationReturnType } from '../../../common/utils/extractQuery';
import { FormFeedback } from '../../../common/components/FormFeedback';

export type DeleteSkuInstanceDialogProps = {
  open: boolean;
  onDelete: () => void;
  mutationResult?: MutationReturnType<
    DeleteSkuInstanceMutationPayload | DeleteAdSkuInstanceMutationPayload
  >;
  onClose?: () => void;
};

const useStyles = makeStyles(theme =>
  createStyles({
    button: () => ({
      background: theme.palette.error.light,
      '&:hover': {
        background: theme.palette.error.main,
      },
    }),
    paper: {
      background: theme.palette.primary.main,
    },
    text: {
      color: theme.palette.background.default,
    },
  }),
);

export const DeleteSkuInstanceDialog: FC<DeleteSkuInstanceDialogProps> = props => {
  const { open, onClose, onDelete, mutationResult } = props;
  const classes = useStyles(props);
  const { t, tk } = useTranslation('inventory');

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      data-testid="DeleteSkuInstanceDialog"
      PaperProps={{ className: classes.paper }}
    >
      {mutationResult?.loading ? (
        <DialogContent>
          <LoadingIndicator />
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <FormFeedback error={mutationResult?.error} />
            <Typography
              align="center"
              variant="subtitle1"
              className={classes.text}
            >
              {t(tk.skuInstanceView.deleteDialog.prompt)}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={onClose} className={classes.text}>
              {t(tk.skuInstanceView.deleteDialog.cancel)}
            </Button>
            <Button
              variant="contained"
              onClick={onDelete}
              color="secondary"
              className={classes.button}
            >
              {t(tk.skuInstanceView.deleteDialog.confirm)}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
