import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { useMediaQuery, Theme } from '@material-ui/core';

import { ResolvedAdSku, ResolvedSku } from '../../../types';
import { SkuImage } from '../SkuImage';

type Props = {
  sku: ResolvedSku | ResolvedAdSku;
  title: string;
  btnLabel: string;
  onClick: () => void;
  chips?: React.ReactNode;
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxHeight: 'min-content',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 'fit-content',
      },
    },
    modelFont: {
      color: theme.palette.secondary.main,
      fontSize: 24,
      lineHeight: '32px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
    media: {
      borderRadius: 8,
      maxWidth: 200,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 140,
      },
    },
    imageSection: {
      [theme.breakpoints.between('md', 'xl')]: {
        display: 'flex',
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'grid',
        alignContent: 'center',
      },
    },
    infoSection: {
      margin: theme.spacing(0, 2),
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
    },
    bold: { fontWeight: 700 },
    manufacturerModel: {
      display: 'flex',
      alignItems: 'center',
      '& > :first-child': {
        paddingRight: theme.spacing(1),
      },
    },
    colorSubcategory: {
      textTransform: 'capitalize',
      padding: theme.spacing(2, 0),
    },
    content: {
      display: 'flex',
      flexFlow: 'row nowrap',
      padding: theme.spacing(2),
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        '&:last-child': {
          paddingBottom: theme.spacing(1),
        },
      },
    },
  }),
);

export const SkuCard: FC<Props> = ({
  sku,
  title,
  btnLabel,
  onClick,
  chips,
}) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <section className={classes.imageSection}>
          {sku.images.length ? (
            <SkuImage className={classes.media} image={sku.images[0]} />
          ) : null}
        </section>

        <section className={classes.infoSection}>
          <Typography variant="body2" className={classes.bold}>
            {title}
          </Typography>
          <div className={classes.manufacturerModel}>
            <Typography
              variant={isSmall ? 'body2' : 'h5'}
              className={classes.bold}
            >
              {sku.manufacturer}
            </Typography>
            <Typography variant="body1" className={classes.modelFont}>
              {sku.model}
            </Typography>
          </div>
          <div>{chips}</div>
          <Typography variant="body1" className={classes.colorSubcategory}>
            {`${sku.primaryColor?.label} ${
              sku.subcategory?.__typename === 'Subcategory' &&
              sku.subcategory.name
            }
            `}
          </Typography>
          <Button color="primary" onClick={onClick}>
            <Typography variant={isSmall ? 'caption' : 'button'}>
              {btnLabel}
            </Typography>
          </Button>
        </section>
      </CardContent>
    </Card>
  );
};
