import * as yup from 'yup';

import { FormFields } from '../../../common/components/Form/types';

export const officeFormFields: FormFields = {
  city: {
    type: 'TextFieldDefinition',
    validation: yup.string().required(),
  },
  zipCode: {
    type: 'TextFieldDefinition',
    validation: yup.string().required(),
  },
  street: {
    type: 'TextFieldDefinition',
    validation: yup.string().required(),
  },
  streetNumber: {
    type: 'TextFieldDefinition',
    validation: yup.string().required(),
  },
  officeFloors: {
    type: 'ArrayFieldDefinition',
    validation: yup.array().required(),
  },
};
