import React, { useEffect, useState } from 'react';

import { Maybe } from '../../types';

import { Step } from './types';

type Props<T = {}> = {
  steps: Step<T>[];
  onStepMount?: (stepId: string) => void;
  onGoBack?: (stepId: string) => void;
  onStepCompleted?: (stepId: string) => void;
  extraProps: T;
  useStepState?: (initialStep: string) => [string, (step: string) => void];
};

export function Wizard<T>({
  steps,
  extraProps,
  onStepCompleted,
  onGoBack,
  onStepMount,
  useStepState = useState,
}: Props<T>) {
  const [activeStep, setActiveStep] = useStepState(steps[0]?.id);
  const [returnTo, setReturnTo] = useState<Maybe<string>>(null);

  useEffect(() => {
    if (onStepMount) {
      onStepMount(activeStep);
    }
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [activeStep, onStepMount]);

  if (!activeStep) {
    return null;
  }

  const stepIdx = steps.findIndex(s => s.id === activeStep);
  if (stepIdx === -1) {
    return null;
  }
  const step = steps[stepIdx];

  const prevStep = steps[stepIdx - 1];
  const nextStep = steps[stepIdx + 1];

  const handleBack = prevStep
    ? () => {
        if (onGoBack) {
          onGoBack(activeStep);
        }
        setReturnTo(null);
        setActiveStep(prevStep.id);
      }
    : undefined;

  const handleCompleted = () => {
    if (onStepCompleted) {
      onStepCompleted(activeStep);
    }
    if (returnTo) {
      setReturnTo(null);
      setActiveStep(returnTo);
    } else if (nextStep) {
      setActiveStep(nextStep.id);
    }
  };

  const handleGoToStep = (stepId: string, returnTo?: Maybe<string>) => {
    setReturnTo(returnTo);
    setActiveStep(stepId);
  };

  const Component = step.component;
  return (
    <Component
      onGoBack={handleBack}
      onGoToStep={handleGoToStep}
      onStepCompleted={handleCompleted}
      number={stepIdx + 1}
      totalCount={steps.length}
      extraProps={extraProps}
    />
  );
}
