import React, { FC } from 'react';
import List from '@material-ui/core/List';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from '../../../../translations';
import {
  FunctionalityValue,
  ResolvedFunctionalitySubcategoryConfig,
} from '../../../models';

import { FunctionalityGroup } from './FunctionalityGroup';

const useStyles = makeStyles(theme =>
  createStyles({
    alert: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    icon: {
      fontSize: 16,
      marginRight: theme.spacing(1),
    },
  }),
);

type Props = {
  values: Record<string, FunctionalityValue>;
  initialValues: Record<string, FunctionalityValue>;
  config: ResolvedFunctionalitySubcategoryConfig;
  onChange: (data: Record<string, FunctionalityValue>) => void;
};

export const SkuFunctionalityInput: FC<Props> = ({
  config,
  values,
  initialValues,
  onChange,
}) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();
  const { groups, processValues } = config;

  const handleChange = (changed: Record<string, FunctionalityValue>) => {
    let newValues = { ...values, ...changed };
    if (processValues) {
      newValues = processValues(newValues);
    }
    onChange(newValues);
  };

  return (
    <div>
      <div className={classes.alert}>
        <InfoOutlinedIcon className={classes.icon} />
        <Typography>{t(tk.newSkuView.functionalityStep.helperText)}</Typography>
      </div>
      <List data-testid="FunctionalityStepGroups">
        {groups.map(group => (
          <FunctionalityGroup
            key={group.name}
            data-testid={`FunctionalityGroup-${group.name}`}
            group={group}
            values={values}
            initialValues={initialValues}
            onChange={handleChange}
          />
        ))}
      </List>
    </div>
  );
};
