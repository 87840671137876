import React, { ChangeEvent, FC } from 'react';

import { useTranslation } from '../../../../translations';
import { ResolvedOffice } from '../../../context/types';
import { formatOfficeAddress } from '../../../utils/format';
import { TextField } from '../../../../common/components/TextField';

type Props = {
  offices: ResolvedOffice[];
  selected?: string;
  onChange: (officeId: string) => void;
};

export const LocationSelect: FC<Props> = ({ offices, selected, onChange }) => {
  const { t, tk } = useTranslation('inventory');

  const options = [
    {
      label: t(tk.newSkuView.locationStep.placeholder),
      value: '',
      disabled: true,
    },
    ...offices.map(office => ({
      label: formatOfficeAddress(office),
      value: office.id,
    })),
  ];

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    onChange(event.target.value);
  };

  return (
    <TextField
      fullWidth
      select={true}
      SelectProps={{
        displayEmpty: true,
      }}
      id="form-input-officeId"
      name="officeId"
      options={options}
      value={selected}
      onChange={handleChange}
    />
  );
};
