import React from 'react';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';

type Options = readonly {
  label: string;
  value: string;
  disabled?: boolean;
}[];

type Props = TextFieldProps & {
  options?: Options;
};

export const TextField: React.FC<Props> = props => {
  const { children, options, ...textFieldProps } = props;

  return (
    <MuiTextField
      InputProps={
        {
          notched: false,
        } as Partial<OutlinedInputProps>
      }
      InputLabelProps={{
        shrink: true,
      }}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
        ...(textFieldProps.SelectProps ? textFieldProps.SelectProps : {}),
      }}
      margin="normal"
      variant="outlined"
      {...textFieldProps}
    >
      {options &&
        options.map(({ label, value, disabled }) => (
          <MenuItem key={value} value={value} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      {children}
    </MuiTextField>
  );
};
