import { SliderClassKey } from '@material-ui/core/Slider';
import { StyleRules } from '@material-ui/core/styles';

import { DsBlack, DsYellow } from '../colors';

export const MuiSlider: Partial<StyleRules<SliderClassKey>> = {
  rail: {
    color: DsBlack.veryLight,
    opacity: 1,
  },
  track: {
    color: DsYellow.main,
    opacity: 1,
  },
};
