import React, { FC, useState } from 'react';
import Box from '@material-ui/core/Box';

import { useTranslation } from '../../../translations';
import { ContextMenu } from '../common/ContextMenu';
import { BreadcrumbItem } from '../../../common/components/Breadcrumbs';
import { ArrowLeft } from '../../../common/components/icons/12px/ArrowLeft';

import {
  DeleteSkuInstanceDialog,
  DeleteSkuInstanceDialogProps,
} from './DeleteSkuInstanceDialog';

type Props = {
  breadcrumbs: BreadcrumbItem[];
  deleteMutation: Pick<
    DeleteSkuInstanceDialogProps,
    'onDelete' | 'mutationResult'
  >;
};

export const SkuInstanceTopMenu: FC<Props> = ({
  breadcrumbs,
  deleteMutation,
}) => {
  const { t, tk } = useTranslation('inventory');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const handleInitDelete = () => setShowDeleteDialog(true);
  const handleCloseDialog = () => setShowDeleteDialog(false);

  return (
    <Box display="flex" flexDirection="row">
      <DeleteSkuInstanceDialog
        open={showDeleteDialog}
        onClose={handleCloseDialog}
        {...deleteMutation}
      />
      <ContextMenu
        title={<ArrowLeft />}
        items={breadcrumbs.map(b => ({
          label: b.label,
          onSelect: b.route ? () => b.route?.go() : undefined,
        }))}
      />
      <Box ml={2} />
      <ContextMenu
        title={t(tk.skuInstanceView.topMenu.title)}
        items={[
          {
            label: t(tk.skuInstanceView.topMenu.delete),
            onSelect: handleInitDelete,
            id: 'delete-instance-option',
          },
        ]}
        showExpandIcon
      />
    </Box>
  );
};
