export enum StepId {
  Form = 'Form',
  Location = 'Location',
  PreregistrationSuccess = 'PreregistrationSuccess',
  Summary = 'Summary',
}

export type NewInstanceStepProps = {
  skuId: string;
};
