import React, { ChangeEvent, FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type Props = {
  expandTitle: string;
  hideTitle: string;
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      background: 'none',
      '&:before': {
        display: 'none',
      },
    },
    details: {
      padding: 0,
    },
    summary: {
      '&&': {
        minHeight: `auto`,
      },
    },
    summaryContent: {
      '&&': {
        margin: 0,
      },
    },
    summaryText: {
      color: theme.palette.primary.veryLight,
    },
  }),
);

export const AccordionWrapper: FC<Props> = ({
  children,
  expandTitle,
  hideTitle,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();

  const handleChange = (_: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? true : false);
  };

  return (
    <Accordion
      className={classes.root}
      expanded={expanded}
      elevation={0}
      onChange={handleChange}
    >
      <AccordionSummary
        classes={{ root: classes.summary, content: classes.summaryContent }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.summaryText} variant="subtitle1">
          {expanded ? hideTitle : expandTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
