import React, { FC, useCallback } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import { useConfig } from '../../common/config';

type AppState = {
  returnTo?: string;
};

export const AuthProvider: FC = ({ children }) => {
  const config = useConfig();
  const history = useHistory();
  const onRedirectCallback = useCallback(
    (appState: AppState) => {
      history.replace(
        (appState && appState.returnTo) || window.location.pathname,
      );
    },
    [history],
  );

  return (
    <Auth0Provider
      domain={config.authDomain}
      clientId={config.authClientId}
      audience={config.authAudience}
      redirectUri={`${window.location.origin}/callback`}
      cacheLocation={config.authCacheLocation}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
