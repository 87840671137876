import { useRouteMatch } from 'react-router-dom';

import { routes, useNavigation } from '../../common/navigation';

type Params = {
  lang: string;
  tenantId: string;
  id?: string;
  filters?: string;
};

export const useAdRedirect = () => {
  const adSkuFilterMatch = useRouteMatch<Params>(routes.adSkusFilter.path);
  const adSkuMatch = useRouteMatch<Params>(routes.adSku.path);
  const adSkuInstanceMatch = useRouteMatch<Params>(routes.adSkuInstance.path);
  const adSkusMatch = useRouteMatch<Params>(routes.adSkus.path);
  const adSkusFilterMatch = useRouteMatch<Params>(routes.adSkusFilter.path);

  const nav = useNavigation();

  const adSkuRedirect = adSkuMatch
    ? (tenantId: string) =>
        nav.adSku(
          {
            id: adSkuMatch?.params.id || '',
          },
          { tenantId },
        )
    : undefined;

  const adSkuFilterRedirect = adSkuFilterMatch
    ? (tenantId: string) =>
        nav.adSkuEncodedFilters(
          {
            id: adSkuFilterMatch?.params.id || '',
            filters: adSkuFilterMatch?.params.filters,
          },
          { tenantId },
        )
    : undefined;

  const adSkuInstanceRedirect = adSkuInstanceMatch
    ? (tenantId: string) =>
        nav.adSkuInstance(
          { id: adSkuInstanceMatch?.params.id || '' },
          { tenantId },
        )
    : undefined;

  const adSkusFiltersRedirect = adSkusMatch
    ? (tenantId: string) =>
        nav.adSkusEncodedFilters(adSkusFilterMatch?.params.filters, {
          tenantId,
        })
    : undefined;

  const adSkusRedirect = adSkusMatch
    ? (tenantId: string) => nav.adSkus({}, { tenantId })
    : undefined;

  return (
    adSkuFilterRedirect ||
    adSkuRedirect ||
    adSkuInstanceRedirect ||
    adSkusFiltersRedirect ||
    adSkusRedirect
  );
};
