import React from 'react';

import { Props } from '../types';

import { useStyles } from './styles';

export const CheckboxSelected = (props: Props) => {
  const classes = useStyles(props);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="8 8 24 24"
      fill="none"
      className={classes.root}
      {...props}
    >
      <path
        d="M29.333 8H10.667C9.187 8 8 9.2 8 10.667v18.666A2.666 2.666 0 0010.667 32h18.666C30.813 32 32 30.8 32 29.333V10.667A2.666 2.666 0 0029.333 8z"
        fill="#FFCC49"
      />
      <path
        d="M18.286 25L14 20.681l1.209-1.218 3.077 3.093L24.79 16 26 17.227 18.286 25z"
        fill="#3A352D"
      />
    </svg>
  );
};
