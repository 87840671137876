import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
} from '../types';
import { mapFieldKeys } from '../utils';

import { getFields, TableFields } from './fields';
import { TableFunctionalities } from './types';

export const FOLDING_TABLES_SUBCATEGORY_KEY = 'foldingTables';
export const FOLDING_TABLES_SUBCATEGORY_LABEL = 'Folding Tables';

const defaultValues: TableFunctionalities = {
  tableAdjustableWidth: false,
  tableFoldable: true,
  tableDrawer: false,
  tableStackable: false,
};

const groups = (
  { tableFeet, tableStackable, tableFoldable, tableTopShape }: TableFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'feet',
    label: tableFeet.label,
    attributes: [tableFeet],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [tableFeet.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tableFeet,
        },
      ],
    },
  },
  {
    name: 'tableStackable',
    label: tableStackable.label,
    attributes: [tableStackable],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.tables.groups.tableStackable.noValueLabel),
      field: tableStackable,
    },
  },
  {
    name: 'tableFoldable',
    label: tableFoldable.label,
    attributes: [tableFoldable],
    functionalities: [],
  },
  {
    name: 'tableTopShape',
    label: tableTopShape.label,
    attributes: [tableTopShape],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [tableTopShape.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tableTopShape,
        },
      ],
    },
  },
];

export const foldingTables = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields, t, tk),
  };
};
