import omit from 'lodash/omit';

import { AdSkuInstanceInput } from '../../graphql';
import { AdSkuInstancePayload } from '../../graphql/types';

export const getInitialValues = (
  adSkuInstance: AdSkuInstancePayload,
): Omit<AdSkuInstanceInput, 'adSkuId'> & { tenant: string } => {
  return {
    ...(omit(adSkuInstance, ['id', 'adSku', '__typename']) as Omit<
      AdSkuInstancePayload,
      'id' | 'adSku'
    >),
    tenant: adSkuInstance.tenant?.name || '',
  };
};
