/* eslint-disable @typescript-eslint/camelcase */
export default {
  actions: {
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    continue: 'Continue',
    reset: 'Reset',
  },
  errors: {
    userEmptyTenants: 'Page unavailable due to insufficient permissions',
  },
  head: {
    title: 'Good Work - office furniture',
  },
  createSkuInstanceView: {
    title: 'Create instance',
    createSuccess: 'SKU instance created successfully',
  },
  createSkuView: {
    title: 'Create SKU',
    createSuccess: 'SKU created successfully',
  },
  newAdSkuInstanceView: {
    formStep: {
      title: 'Configure Ad Details',
      subtitle: 'Please confirm the quantity, price and sales period.',
      confirm: 'Confirm',
      location: 'Location',
      optional: '[Optional]',
      price: 'Price per instance',
      salePeriod: 'Sale period',
      from: 'From',
      to: 'To',
      status: 'Status {{count}} Instances',
      condition: 'Condition {{watchCount}} Instances',
    },
    summaryStep: {
      title: 'Advert Complete!',
      perInstance: 'per instance',
    },
  },
  newSkuInstanceView: {
    formStep: {
      title: 'Register Instances',
      subtitle:
        'Please confirm the quantity, location, condition and status of the instances.',
      countFieldLabel: 'Number of Instances',
      isPreregistration: 'Instances will be scanned at a later time.',
      isSameLocation: 'Instances are at the same location.',
      isSameStatus: 'Instances have the same status & condition.',
      conditionMultipleInstances: 'Condition {{count}} Instances',
      statusMultipleInstances: 'Status {{count}} Instances',
      officeFloorMultipleInstances: 'Floor {{count}} Instances',
      procurementTitle: 'Procurement Information',
      procurementTitleSuffix: '(Optional)',
      instancesHeader: '',
      instancesHeader_location:
        'Select <strong>Location</strong> of 4 Instances',
      instancesHeader_status:
        'Select <strong>Status</strong> & <strong>Condition</strong> of 4 Instances',
      instancesHeader_statuslocation:
        'Select <strong>Status</strong>, <strong>Condition</strong> & <strong>Location</strong> of 4 Instances',
      confirm: 'Confirm & Proceed to Scanning',
      confirmAndSave: 'Confirm & Save for later',
    },
    summaryStep: {
      title: 'Scan Instances',
      titleCompleted: 'Registration Complete!',
      subtitle:
        'Please scan the QR code on the physical furniture(s) to complete the registration.',
      subtitleCompleted:
        'The Following furniture has been added to your inventory.',
      location: 'Location',
      goBackAndEdit: 'Go back and edit instance information',
      confirm: 'Scan',
      completed: 'Complete!',
      scanner: {
        enterCode: 'Enter Code',
        enterCodePlaceholder: 'Input text',
        successText: 'Scanned!',
        title: 'Scan QR codes',
      },
      completedDialog: {
        registerNew: 'Register New',
        viewInventory: 'View Inventory',
      },
    },
    preregistrationSuccessStep: {
      title: 'Registration Complete!',
      subtitle:
        '<strong>{{count}} Instances</strong> of {{sku}} have been added to your inventory and saved for scanning at a later time.',
      description:
        'You can find the pre-registered instances in the registry by filtering the status to <0></0>.',
      actionsTitle: 'What would you like to do now?',
      skuCardTitle: '{{count}} Instances of',
      skuCardButtonLabel: 'View Listing',
    },
  },
  registrationView: {
    adPickStep: {
      title: 'What kind of ad would you like to register?',
      newAdSku: {
        title: 'Ad SKU',
        subtitle: 'Register a new furniture ad type.',
      },
      newAdSkuInstance: {
        title: 'Ad Instance',
        subtitle: 'Register new ad instance.',
      },
    },
  },
  newSkuView: {
    locationDialog: {
      confirm: 'Confirm Location',
    },
    locationStep: {
      title_morning: 'Good morning!',
      title_afternoon: 'Good afternoon!',
      title_evening: 'Good evening!',
      description: 'What office are you in today?',
      placeholder: 'Select office',
      submit: 'Start Registering',
    },
    welcomeStep: {
      title: 'What would you like to register?',
      newSku: {
        title: 'New SKU',
        subtitle: 'Register a new furniture type.',
      },
      newAdSku: {
        title: 'New Ad',
        subtitle: 'Register a ad SKU or instance.',
      },
      changeLocation: 'Change Location',
      lastSkus: {
        title: 'Register more of the same',
        action: 'Register new instance',
      },
    },
    mainCategoryStep: {
      backLabel: 'Registration',
      title: 'Pick furniture category',
    },
    subcategoryStep: {
      title: 'Pick furniture category',
    },
    modelStep: {
      title: 'Configure Brand & Model',
      brandPlaceholder: 'Select a brand',
      createBrand: {
        link: '+ Create New Brand',
        title: 'Creating New Brand',
      },
    },
    searchStep: {
      title: 'We found similar products',
      subtitle:
        'There are currently {{count}} {{manufacturer}} {{model}} {{subcategory}} in our system. Please check the list below and make sure you’re not registering a duplicate.',
      actions: {
        newInstance: 'New Instance',
        newSimilarSku: 'New Similar',
      },
      newSku: {
        description:
          'Does none of these fit the furniture you’re registrating?',
        action: 'Continue creating a new SKU',
      },
      abandon: {
        description: 'Does the SKU already exist in our system?',
        action: 'Abandon and start over',
      },
    },
    imageStep: {
      title: 'Upload image',
      uploadDescription: 'Upload image from your device',
      changeDescription: 'Upload a new image',
      confirm: 'Confirm Image',
    },
    functionalityStep: {
      title: 'Configure Functionality',
      helperText:
        'Functionality marked in red is mandatory to configure for this furniture.',
      notConfigured: 'Not configured',
      reset: 'Reset Selections',
      confirm: 'Confirm Functionality',
    },
    colorStep: {
      title: 'Select Color',
      showMore: '+ More color options',
      confirm: 'Confirm Color',
    },
    dimensionsStep: {
      title: 'Configure Dimensions',
      confirm: 'Confirm Dimensions',
    },
    summaryStep: {
      title: 'Summary',
      saveAndRegister: 'Save & Register Instances',
      saveAndFinish: 'Save & Finish',
      descriptionLabel: 'Add a Product Description (optional)',
      descriptionPlaceholder: 'Description...',
      hideFunctionality: 'Hide Functionality',
      showFunctionality: 'Show Functionality',
      goBackAndEdit: {
        link: 'Go back and edit',
        title: 'What would you like to edit?',
        action: 'edit',
        items: {
          brand: 'Brand',
          model: 'Model',
          image: 'Image',
          functionality: 'Functionality',
          colors: 'Colors',
          dimensions: 'Dimensions',
        },
        functionality: '{{count}} Configured Functionalities',
        image: '{{count}} Registered Image',
      },
    },
  },
  skuInstanceListView: {
    title: 'Instances of {{manufacturer}} {{model}}',
    metadata:
      '<strong>{{skuInstanceCount}}</strong> instances match your filtering options.',
    item: {
      primary: 'Instance: {{id}}',
      status: 'Status: {{status}}',
      condition: 'Condition: {{condition}}',
      address: 'Address: ',
      processingStage: 'Processing stage: ',
      callToAction: 'See this instance',
    },
    changeStatusForInstancesDialog: {
      loading: 'Loading...',
      prompt: 'Are you sure you’d like to update this instances?',
      confirm: 'Yes, I’m sure',
      cancel: 'No, take me back',
    },
    registerInstance: 'Register New Instance',
    changeStatusForInstancesButton: 'Update selected instances',
    switchItemLabel: 'Select / Unselect all instances in page',
  },
  scanSkuInstanceView: {
    title: 'Complete registration',
    subtitle:
      'This furniture has been pre-registered. Please fill out the details below and scan the QR code on the physical furniture to complete the registration.',
    confirm: 'Confirm & Scan',
    successMessage: 'The furniture has been registered successfully.',
    viewInstance: 'View listing',
  },
  adSkuInstanceView: {
    instanceCard: {
      button: 'View AD Information',
    },
  },
  skuInstanceView: {
    deleteDialog: {
      prompt: 'Are you sure you’d like to delete this instance?',
      confirm: 'Yes, I’m sure',
      cancel: 'No, take me back',
    },

    instanceCard: {
      example: 'Example {{skuInstanceId}}',
      button: 'View SKU Information',
    },
    updateSuccess: 'SKU instance updated successfully',
    qrCode: 'Instans-ID',
    validation: {
      costCenterLength: 'Must be exactly 6 digits',
      invalidDate: 'Invalid date (dd.mm.yyyy)',
      invalidDecimal: 'Must be a decimal number',
    },
    topMenu: {
      currentItem: 'Instance {{id}}',
      title: 'Options',
      delete: 'Delete instance',
    },
  },
  skuListView: {
    title: 'All furniture',
    skuGenerateSpreedSheetForPageTooltip: 'Generate spreadsheet from page ',
    skuGenerateSpreedSheetForAllTooltip:
      'Generate spreadsheet with all furnitures',
    skuGeneratePrintForAllTooltip: 'Generate print file with all furnitures',
    metadata: {
      counts:
        '<strong>{{skuCount}}</strong> different models and <strong>{{skuInstanceCount}}</strong> instances match your filtering options.',
    },
    item: {
      counts: '{{matches}} of total {{total}} instances',
    },
    filterLabels: {
      category: 'Category',
      location: 'Location',
      primaryColor: 'Colors',
      minimumInstances: 'Minimum Instances Count',
    },
    noModelLabel: '-',
    instancesForSale: '{{matches}} instances for sale',
    registerNewAdInstance: 'Register New Ad Instance ',
  },
  skuView: {
    updateSuccess: 'SKU updated successfully',
    topMenu: {
      title: 'Options',
      editFunctionality: 'Edit Functionality',
      registerNewInstance: 'Register New Instance',
    },
    editFunctionality: {
      successTitle: 'Functionality updated!',
      successDescription:
        'The furniture functionality has been successfully updated.',
      returnAction: 'Return to product',
      saveAction: 'Save Changes',
    },
  },
  skuReportView: {
    instances: 'Instances',
    from: 'from',
    to: 'to',
  },
  addTenantView: {
    newOfficeInfo: 'Office information:',
    tenantInfo: 'Tenant information:',
  },
  fieldLabels: {
    skuId: 'Product ID',
    mainCategoryId: 'Main category',
    subcategoryId: 'Subcategory',
    manufacturer: 'Brand',
    model: 'Model',
    serialNumber: 'Variant #',
    serialNumber_registration: 'Model Variant-NR / Serial Number',
    skuAdProcessingStage: 'Processing stage',
    primaryColor: {
      default: 'Primary color',
      armchairs: 'Seat and back',
      benches: 'Seat and back',
      conferenceChairs: 'Seat and back',
      foldingChairs: 'Seat and back',
      officeChairs: 'Seat and back',
      stools: 'Seat',
      poufs: 'Seat',
      standard: 'Seat and back',
      chaise: 'Seat and back',
      sectional: 'Seat and back',
      cabinet: 'Frame',
      fileCabinet: 'Frame',
      shelves: 'Frame',
      storageBox: 'Frame',
      workstationDesks: 'Table top',
      meetingRoomTables: 'Table top',
      foldingTables: 'Table top',
      diningTables: 'Table top',
      coffeeTables: 'Table top',
      sideTables: 'Table top',
      whiteboards: 'Board',
    },
    secondaryColor: {
      default: 'Secondary color',
      armchairs: 'Base',
      benches: 'Base',
      conferenceChairs: 'Base',
      foldingChairs: 'Base',
      officeChairs: 'Base',
      stools: 'Base',
      poufs: '',
      standard: 'Legs',
      chaise: 'Legs',
      sectional: 'Legs',
      cabinet: 'Doors',
      fileCabinet: 'Doors',
      shelves: 'Doors',
      storageBox: 'Doors',
      workstationDesks: 'Legs',
      meetingRoomTables: 'Legs',
      foldingTables: 'Legs',
      diningTables: 'Legs',
      coffeeTables: 'Legs',
      sideTables: 'Legs',
      whiteboards: 'Frame',
    },
    height: 'Height',
    minHeight: 'Min. height',
    maxHeight: 'Max. height',
    seatHeight: 'Seat height',
    minSeatHeight: 'Min. seat height',
    maxSeatHeight: 'Max. seat height',
    depth: 'Depth',
    minDepth: 'Min. depth',
    maxDepth: 'Max. depth',
    length: 'Length',
    minLength: 'Min. length',
    maxLength: 'Max. length',
    width: 'Width',
    minWidth: 'Min. width',
    maxWidth: 'Max. width',
    description: 'Description',
    status: 'Status',
    condition: 'Condition',
    officeId: 'Address',
    officeFloorId: 'Floor',
    officeFloorList: 'Office floors list',
    room: 'Room',
    qrCode: 'QR Code',
    costCenter: 'Cost Center',
    procurementDate: 'Procurement Date',
    procurementCost: 'Procurement Cost',
    procurementNumber: 'Procurement Number',
    processingStage: 'Processing stage',
    processingStageDateFrom: 'Processing stage date from',
    processingStageDateTo: 'Processing stage date to',
    note: 'Note',
    currency: 'Kr.',
    saleStart: 'Sale Start',
    saleEnd: 'Sale End',
    price: 'Price',
    tenant: 'Tenant',
    name: 'Name',
    companyUserEmail: 'Company admin user [email]',
    city: 'City',
    zipCode: 'Zip code',
    street: 'Street',
    streetNumber: 'Street number',
    companyName: 'Company name',
  },
} as const;
