import MuiLink from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useTranslation } from '../../../../translations';
import { useNavigation } from '../../../../common/navigation';
import { Link } from '../../../../common/components/Link';

import goodLogoLight from './good-work.logo.png';
import goodLogoDark from './good-work.logo.svg';

const gogoodLinkProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
  href: 'https://gogood.no',
} as const;

const useStyles = makeStyles(theme =>
  createStyles({
    goodLogoWrapper: {
      marginBottom: theme.spacing(4),
    },
    logo: {
      width: 48,
    },
  }),
);

type Props = {
  withText?: boolean;
  mode?: 'light' | 'dark';
};

export const GoodWorkLogo: React.FC<Props> = ({ withText, mode = 'light' }) => {
  const classes = useStyles();
  const nav = useNavigation();
  const { t: tc, tk: tkc } = useTranslation('common');

  return (
    <div className={classes.goodLogoWrapper}>
      <Link route={nav.skus()}>
        <img
          src={mode === 'light' ? goodLogoLight : goodLogoDark}
          alt="GoodWork"
          className={classes.logo}
        />
      </Link>

      {withText && (
        <div>
          - {tc(tkc.logo.goodWorkLabel)}
          <MuiLink
            color={mode === 'light' ? 'primary' : 'inherit'}
            {...gogoodLinkProps}
          >
            GoGood
          </MuiLink>
        </div>
      )}
    </div>
  );
};
