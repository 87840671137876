import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Trans } from 'react-i18next';

import { ListMetadataMatch } from '../../graphql';
import { skuInstanceTemplateName } from '../../consts';
import { useTranslation } from '../../../translations';
import { Row } from '../../../common/components/Row';
import { OmitTypename } from '../../../common/types';

type Props = {
  matches?: OmitTypename<ListMetadataMatch>[];
};

export const SkuInstanceListMetadata: FC<Props> = ({ matches }) => {
  const { t, tk } = useTranslation('inventory');

  const skuInstanceCount = matches?.find(
    m => m.template === skuInstanceTemplateName,
  )?.count;

  if (skuInstanceCount === undefined) {
    return null;
  }

  return (
    <Row>
      <Typography variant="subtitle1">
        <Trans
          i18nKey={tk.skuInstanceListView.metadata}
          t={t}
          values={{ skuInstanceCount }}
        />
      </Typography>
    </Row>
  );
};
