import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
  FunctionalityValue,
} from '../types';
import { mapFieldKeys, someTrue } from '../utils';

import { ChairFunctionalities } from './types';
import { getFields, ChairFields } from './fields';

const defaultValues: ChairFunctionalities = {
  chairAdjustableHeight: 'fixed',
  chairBack: true,
  chairBackLength: 'standard',
  chairBackFixed: true,
  chairBackAdjustableHeight: false,
  chairBackAdjustableAngle: false,
  chairLeaningBack: false,
  chairSeat: true,
  chairSeatFixed: true,
  chairSeatAdjustablePosition: false,
  chairSeatAdjustableAngle: false,
  chairArmrests: false,
  chairArmrestsFixed: false,
  chairArmrestsAdjustablePosition: false,
  chairArmrestsAdjustableHeight: false,
  chairArmrestsAdjustableWidth: false,
  chairArmrestsAdjustableLength: false,
  chairFeetCount: '4',
  chairFeetType: 'feet',
  chairNeckrest: 'none',
  chairFootring: false,
  chairStackable: true,
  chairFoldable: true,
};

const processValues = (values: Record<string, FunctionalityValue>) => {
  return {
    ...values,
    chairArmrestsFixed: someTrue(values, ['chairArmrests']),
  };
};

const groups = (
  { chairArmrests, chairArmrestsFixed, chairStackable }: ChairFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'armrests',
    label: t(tk.chairs.groups.armrests.label),
    attributes: [chairArmrestsFixed],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      field: chairArmrests,
    },
  },
  {
    name: 'stackable',
    label: t(tk.chairs.groups.stackable.label),
    attributes: [chairStackable],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.chairs.groups.stackable.noValueLabel),
      field: chairStackable,
    },
  },
];

export const foldingChairs = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields, t, tk),
    processValues,
  };
};
