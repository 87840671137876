import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 8,
      marginBottom: theme.spacing(1),
    },
    primaryText: {
      fontSize: 24,
      fontWeight: 700,
    },
  }),
);

export type TwoLineButtonProps = {
  color?: 'primary' | 'secondary' | 'default';
  primaryText: string;
  secondaryText?: string;
  onClick?: () => void;
};

export const TwoLineButton: React.FC<TwoLineButtonProps> = props => {
  const classes = useStyles(props);
  const {
    color = 'default',
    primaryText,
    secondaryText,
    ...otherProps
  } = props;

  return (
    <Button className={classes.root} color={color} fullWidth {...otherProps}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="flex-start"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography className={classes.primaryText} variant="subtitle1">
            {primaryText}
          </Typography>
          <ChevronRightIcon />
        </Box>
        <Typography variant="subtitle1">{secondaryText}</Typography>
      </Box>
    </Button>
  );
};
