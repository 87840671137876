import React, { useState } from 'react';

import { CheckboxListBase } from '../../../../common/components/CheckboxList/CheckboxListBase';
import { ResolvedFunctionalityFilterGroup } from '../../../models/types';

import { FilterGroup } from './FilterGroup';

export type CheckboxListProps = {
  className?: string;
  title: string;
  groups: ResolvedFunctionalityFilterGroup[];
  defaultExpanded?: boolean;
  selected: Record<string, string[]>;
  onChange: (selected: Record<string, string[]>) => void;
};

export const AggregatedFilters: React.FC<CheckboxListProps> = ({
  className,
  defaultExpanded,
  groups,
  title,
  selected,
  onChange,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded || true);
  const handleExpand = () => {
    setExpanded(true);
  };

  return (
    <CheckboxListBase
      data-testid={`AggregatedFilters-${title}`}
      title={title}
      className={className}
      expanded={expanded}
      onExpand={handleExpand}
    >
      {groups.map(group => (
        <FilterGroup
          key={group.label}
          onChange={onChange}
          group={group}
          selected={selected}
        />
      ))}
    </CheckboxListBase>
  );
};
