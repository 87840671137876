import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
} from '../types';
import { mapFieldKeys } from '../utils';

import { getFields, SofaFields } from './fields';
import { SofasFunctionalities } from './types';

export const CHAISE_SUBCATEGORY_KEY = 'chaise';
export const CHAISE_SUBCATEGORY_LABEL = 'Chaise';

const defaultValues: SofasFunctionalities = {
  sofaWidth: undefined,
  sofaFeet: undefined,
  sofaSeats: undefined,
  sofaChaiseLounge: undefined,
  sofaShape: undefined,
  sofaType: undefined,
};

const groups = (
  {
    sofaChaiseLounge,
    sofaFeet,
    sofaSeats,
    sofaShape,
    sofaWidth,
    sofaType,
  }: SofaFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'width',
    label: t(tk.sofas.groups.width.label),
    attributes: [sofaWidth],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [sofaWidth.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: sofaWidth,
        },
      ],
    },
  },
  {
    name: 'feet',
    label: t(tk.sofas.groups.feet.label),
    attributes: [sofaFeet],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [sofaFeet.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: sofaFeet,
        },
      ],
    },
  },
  {
    name: 'seats',
    label: t(tk.sofas.groups.seats.label),
    attributes: [sofaSeats],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [sofaSeats.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: sofaSeats,
        },
      ],
    },
  },
  {
    name: 'chaiseLounge',
    label: t(tk.sofas.groups.chaiseLounge.label),
    attributes: [sofaChaiseLounge],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [sofaChaiseLounge.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: sofaChaiseLounge,
        },
      ],
    },
  },
  {
    name: 'shape',
    label: t(tk.sofas.groups.shape.label),
    attributes: [sofaShape],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [sofaShape.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: sofaShape,
          limitedTo: ['lShape', 'cornerShape', 'uShape'],
        },
      ],
    },
  },
  {
    name: 'type',
    label: t(tk.sofas.groups.type.label),
    attributes: [sofaType],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: sofaType,
        },
      ],
    },
  },
];

export const chaise = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields, t, tk),
  };
};
