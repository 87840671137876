import React, { ChangeEvent, FC } from 'react';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import { useTranslation } from '../../../../translations';
import { RegistrationDialog } from '../../common/RegistrationDialog';

type Props = {
  open?: boolean;
  onClose: () => void;
  onSubmit: (code: string) => void;
};

export const CodeFormDialog: FC<Props> = ({
  open = false,
  onSubmit,
  onClose,
}) => {
  const { t, tk } = useTranslation('inventory');

  const [code, setCode] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handleSubmit = () => {
    if (code) {
      onSubmit(code);
    }
  };

  return (
    <RegistrationDialog
      title={t(tk.newSkuInstanceView.summaryStep.scanner.enterCode)}
      open={open}
      titleId="enter-code-dialog"
      onClose={onClose}
      actions={
        <Button
          disabled={!code}
          onClick={handleSubmit}
          size="small"
          variant="outlined"
        >
          {t(tk.actions.confirm)}
        </Button>
      }
    >
      <Box display="flex" justifyContent="center">
        <TextField
          variant="outlined"
          value={code}
          onChange={handleChange}
          name="code"
          placeholder={t(
            tk.newSkuInstanceView.summaryStep.scanner.enterCodePlaceholder,
          )}
        />
      </Box>
    </RegistrationDialog>
  );
};
