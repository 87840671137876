import { SkuInstanceInput } from '../../graphql';

import { actionTypes } from './actionTypes';

export const setCount = (count: number) => ({
  type: actionTypes.SET_COUNT,
  count,
});

export const setInputs = (
  inputs: Pick<
    SkuInstanceInput,
    'condition' | 'status' | 'officeFloorId' | 'officeId'
  >[],
) => ({
  type: actionTypes.SET_INPUTS,
  inputs,
});

export const setPreregistrationSuccess = () => ({
  type: actionTypes.PREREGISTRATION_SUCCESS,
});

export const reset = () => ({
  type: actionTypes.RESET,
});

export type Actions = ReturnType<
  | typeof setPreregistrationSuccess
  | typeof setCount
  | typeof setInputs
  | typeof reset
>;
