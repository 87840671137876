import omit from 'lodash/omit';

import { logError } from '../../../common/utils/logError';
import { SkuFormInput } from '../../forms';
import { ResolvedSku } from '../../types';

export const getInitialValues = (sku: ResolvedSku): Partial<SkuFormInput> => {
  let mainCategoryId = '';
  let subcategoryId = '';
  if (sku.mainCategory?.__typename === 'MainCategory') {
    mainCategoryId = sku.mainCategory.id;
  } else {
    const error =
      // ts being weird, either complains that condition !== MainCategory is unnecessary,
      // or that message doesn't exist when typename is MainCategory
      // @ts-expect-error
      sku.mainCategory && sku.mainCategory.__typename !== 'MainCategory'
        ? sku.mainCategory?.message
        : null;
    logError({
      key: 'sku-missing-category',
      message: error || `Missing category id for SKU ${sku.id}`,
      skuId: sku.id,
    });
  }
  if (sku.subcategory?.__typename === 'Subcategory') {
    subcategoryId = sku.subcategory.id;
  } else {
    const error =
      // ts being weird, either complains that condition !== Subcategory is unnecessary,
      // or that message doesn't exist when typename is Subcategory
      // @ts-expect-error
      sku.subcategory && sku.subcategory.__typename !== 'Subcategory'
        ? sku.subcategory.message
        : null;
    logError({
      key: 'sku-missing-subcategory',
      message: error || `Missing subcategory id for SKU ${sku.id}`,
      skuId: sku.id,
    });
  }
  return {
    ...omit(sku, [
      'mainCategory',
      'subcategory',
      'images',
      'functionalities',
      '__typename',
    ]),
    mainCategoryId,
    subcategoryId,
    primaryColor: sku.primaryColor?.key,
    secondaryColor: sku.secondaryColor?.key,
  };
};
