import { StyleRules } from '@material-ui/core/styles';
import { AppBarClassKey } from '@material-ui/core/AppBar';

import { DsBlack } from '../colors';

export const MuiAppBar: Partial<StyleRules<AppBarClassKey>> = {
  colorDefault: {
    backgroundColor: DsBlack.ultraLight,
  },
};
