import { produce } from 'immer';

import { MainCategory, SkuInput, Subcategory } from '../../graphql';
import { FunctionalityGroup, FunctionalityValue } from '../../models';
import { ResolvedImage } from '../../types';

import { Actions } from './actions';
import { actionTypes } from './actionTypes';

export type DimensionsData = Pick<
  SkuInput,
  | 'minHeight'
  | 'maxHeight'
  | 'minWidth'
  | 'maxWidth'
  | 'minLength'
  | 'maxLength'
  | 'minSeatHeight'
  | 'maxSeatHeight'
  | 'minDepth'
  | 'maxDepth'
>;

export type State = {
  skuInput: Partial<
    DimensionsData &
      Pick<
        SkuInput,
        | 'mainCategoryId'
        | 'subcategoryId'
        | 'manufacturer'
        | 'model'
        | 'serialNumber'
        | 'description'
        | 'primaryColor'
        | 'secondaryColor'
        | 'images'
      >
  >;
  functionalityGroups?: FunctionalityGroup[];
  functionalityValues: Record<string, FunctionalityValue>;
  images: ResolvedImage[];
  mainCategory?: Pick<MainCategory, 'id' | 'key' | 'name'>;
  subcategory?: Pick<Subcategory, 'id' | 'key' | 'name'>;
  isNewManufacturer?: boolean;
};

export const initialData: State = {
  skuInput: {},
  functionalityValues: {},
  images: [],
};

// eslint-disable-next-line complexity
export const reducer = produce((state: State, action: Actions) => {
  switch (action.type) {
    case actionTypes.RESET: {
      state.skuInput = {};
      state.images = [];
      state.functionalityValues = {};
      state.isNewManufacturer = false;
      delete state.functionalityGroups;
      delete state.mainCategory;
      delete state.subcategory;
      return;
    }

    case actionTypes.INITIALIZE_FROM_EXISTING_SKU: {
      state.skuInput = {
        ...action.skuInput,
        // keep values from previous steps
        mainCategoryId: state.skuInput.mainCategoryId,
        subcategoryId: state.skuInput.subcategoryId,
        manufacturer: state.skuInput.manufacturer,
        model: state.skuInput.model,
        serialNumber: state.skuInput.serialNumber,
      };
      state.functionalityValues = action.functionalityValues;
      return;
    }

    case actionTypes.SET_COLORS: {
      state.skuInput.primaryColor = action.primaryColor;
      state.skuInput.secondaryColor = action.secondaryColor;
      return;
    }

    case actionTypes.SET_DESCRIPTION: {
      state.skuInput.description = action.description;
      return;
    }

    case actionTypes.SET_DIMENSIONS: {
      state.skuInput.minHeight = action.minHeight;
      state.skuInput.maxHeight = action.maxHeight
        ? action.maxHeight
        : action.minHeight;
      state.skuInput.minWidth = action.minWidth;
      state.skuInput.maxWidth = action.maxWidth
        ? action.maxWidth
        : action.minWidth;
      state.skuInput.minLength = action.minLength;
      state.skuInput.maxLength = action.maxLength
        ? action.maxLength
        : action.minLength;
      state.skuInput.minSeatHeight = action.minSeatHeight;
      state.skuInput.maxSeatHeight = action.maxSeatHeight
        ? action.maxSeatHeight
        : action.minSeatHeight;
      state.skuInput.minDepth = action.minDepth;
      state.skuInput.maxDepth = action.maxDepth
        ? action.maxDepth
        : action.minDepth;
      return;
    }

    case actionTypes.SET_FUNCTIONALITY_VALUES: {
      state.functionalityValues = action.functionalityValues;
      return;
    }

    case actionTypes.SET_IMAGES: {
      state.images = action.images;
      state.skuInput.images = action.images.map(({ filename }) => ({
        filename,
      }));
      return;
    }

    case actionTypes.SET_MAIN_CATEGORY: {
      if (state.skuInput.mainCategoryId !== action.mainCategory.id) {
        state.skuInput.mainCategoryId = action.mainCategory.id;
        state.subcategory = undefined;
        state.functionalityGroups = undefined;
        state.functionalityValues = {};
        state.mainCategory = action.mainCategory;
      }
      return;
    }
    case actionTypes.SET_MODEL_DATA: {
      state.skuInput.manufacturer = action.manufacturer;
      state.skuInput.model = action.model;
      state.isNewManufacturer = action.isNewManufacturer;
      state.skuInput.serialNumber = action.serialNumber;
      return;
    }
    case actionTypes.SET_SUBCATEGORY: {
      state.skuInput.subcategoryId = action.subcategory.id;
      state.subcategory = action.subcategory;
      state.functionalityValues = action.functionalityValues;
      return;
    }
  }
}, initialData);
