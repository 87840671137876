import { StyleRules } from '@material-ui/core/styles';
import { TabClassKey } from '@material-ui/core/Tab';
import { TabsClassKey } from '@material-ui/core/Tabs';

import { DsBlack } from '../colors';

export const MuiTabs: Partial<StyleRules<TabsClassKey>> = {
  flexContainer: {
    borderBottom: `1px solid ${DsBlack.veryLight}`,
    marginBottom: 1,
  },
  indicator: {
    height: 3,
  },
};

export const MuiTab: Partial<StyleRules<TabClassKey>> = {
  wrapper: {
    flexDirection: 'row',
  },
  labelIcon: {
    minHeight: 48,
    paddingTop: 0,
    '& $wrapper > *:first-child': {
      marginBottom: 4,
    },
  },
};
