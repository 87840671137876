import React, { FC, ReactNode } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1.5),
      minHeight: 32,
    },
    title: ({ icon }: Props) => ({
      display: 'flex',
      flex: 1,
      marginRight: icon ? theme.spacing(1.5) : 0,
      fontSize: 13,
    }),
  }),
);

type Props = {
  title?: ReactNode | string;
  icon?: ReactNode;
};

export const ContextMenuTitle: FC<Props> = props => {
  const { title, icon } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      {icon}
    </div>
  );
};
