import { Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useTranslation } from '../../../../translations';
import { ListItemMetadata, Maybe } from '../../../graphql';
import { OmitTypename } from '../../../../common/types';

const useStyles = makeStyles(theme =>
  createStyles({
    counts: {
      marginTop: theme.spacing(3),
    },
  }),
);

type Props = {
  metadata?: Maybe<OmitTypename<ListItemMetadata>>;
};

export const SkuItemMetadata: React.FC<Props> = ({ metadata }) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  return metadata ? (
    <Typography display="block" className={classes.counts} variant="caption">
      <Trans
        i18nKey={tk.skuListView.item.counts}
        t={t}
        values={{
          matches: metadata.matches,
          total: metadata.related,
        }}
      />
    </Typography>
  ) : (
    <></>
  );
};
