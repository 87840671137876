import { State as LocalState } from './reducer';

export type State = {
  inventory: {
    office: LocalState;
  };
};

export const root = (state: State) => state.inventory.office;

export const officeId = (state: State) => root(state).officeId;
