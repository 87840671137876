import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';
import { FunctionalityFieldDisplayType } from '../../types';
import { genBooleanField, genOptionSetField } from '../../configUtils';

export const storageDoorsMechanism = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genOptionSetField({
    key: 'storageDoorsMechanism',
    t,
    tk: tk.storage.fields.storageDoorsMechanism,
    type: FunctionalityFieldDisplayType.String,
  });

export const storageFeetType = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'storageFeetType',
    t,
    tk: tk.storage.fields.storageFeetType,
    type: FunctionalityFieldDisplayType.String,
  });

export const storageLockType = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'storageLockType',
    t,
    tk: tk.storage.fields.storageLockType,
    type: FunctionalityFieldDisplayType.String,
  });

export const storageBuiltInLighting = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genBooleanField({
    key: 'storageBuiltInLighting',
    t,
    tk: tk.storage.fields.storageBuiltInLighting,
  });

export const storageShelvesType = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'storageShelvesType',
    t,
    tk: tk.storage.fields.storageShelvesType,
    type: FunctionalityFieldDisplayType.String,
  });
