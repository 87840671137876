import {
  ResolvedAdSku,
  ResolvedAdSkuWithInstances,
  ResolvedImage,
} from '../inventory/types';
import placeholderUrl from '../inventory/images/placeholder.jpg';

import { adSkuInstanceMock } from './adSkuInstance';

const imageMock: ResolvedImage = {
  url: placeholderUrl,
  filename: 'placeholder.jpg',
  optimizedImages: [],
};

export const adSkuMock: ResolvedAdSku = {
  id: '7dac2453-39b7-4dea-9764-5a862910f5c6',
  manufacturer: 'Ikea',
  model: 'Markus',
  primaryColor: {
    key: 'red',
    hex: '#D43535',
    label: 'Red',
    thumb: '',
  },
  secondaryColor: {
    key: 'darkGreen',
    hex: '#24430F',
    label: 'Dark Green',
    thumb: '',
  },
  maxHeight: 100,
  maxLength: 90,
  maxWidth: 80,
  minHeight: 70,
  minLength: 60,
  minWidth: 50,
  mainCategory: {
    id: '7d608851-8c28-4d07-99ff-d89b27138adb',
    name: 'Chairs',
    key: 'chairs',
    __typename: 'MainCategory',
  },
  subcategory: {
    id: 'c7e01fff-1fca-4a5d-b50c-286fc1d6b58c',
    name: 'Office chairs',
    key: 'officeChairs',
    __typename: 'Subcategory',
  },
  images: [imageMock],
};

export const adSkuMockKinnarps: ResolvedAdSku = {
  id: 'af34eeac-a741-4a01-acc8-88938e528d00',
  manufacturer: 'Kinnarps',
  model: '6000',
  primaryColor: {
    key: 'darkBlue',
    hex: '#183E5B',
    label: 'Dark Blue',
    thumb: '',
  },
  secondaryColor: {
    key: 'black',
    hex: '#000000',
    label: 'Black',
    thumb: '',
  },
  maxHeight: 105,
  maxLength: 95,
  maxWidth: 85,
  minHeight: 75,
  minLength: 65,
  minWidth: 55,
  mainCategory: {
    id: '7d608851-8c28-4d07-99ff-d89b27138adb',
    name: 'Chairs',
    key: 'chairs',
    __typename: 'MainCategory',
  },
  subcategory: {
    id: 'c7e01fff-1fca-4a5d-b50c-286fc1d6b58c',
    name: 'Office chairs',
    key: 'officeChairs',
    __typename: 'Subcategory',
  },
  images: [],
};

export const adSkuMockWithInstances: ResolvedAdSkuWithInstances = {
  ...adSkuMock,
  adSkuInstances: [
    adSkuInstanceMock,
    { ...adSkuInstanceMock, salesPrice: '400' },
  ],
};
