import cloneDeep from 'lodash/cloneDeep';

import { logError } from '../../common/utils/logError';
import {
  MainCategoriesPayload,
  MappingsPayload,
  OfficesPayload,
  SkuTemplatePayload,
} from '../graphql/types';
import { OptionSet } from '../graphql';

export const COLOR_TO_HEX_MAPPING = 'colorToHex';

export const getColorMapping = (payload?: MappingsPayload) => {
  if (!payload || !payload.mappings) {
    return null;
  }

  const mapping = payload.mappings.find(m => m.name === COLOR_TO_HEX_MAPPING);
  if (!mapping) {
    return null;
  }
  return JSON.parse(mapping.mappings);
};

export const formatCategories = (payload?: MainCategoriesPayload) => {
  if (!payload || !payload.mainCategories) {
    return null;
  }

  try {
    const categories = payload.mainCategories.map(c => {
      if (c.subcategories?.__typename !== 'SubcategoryList') {
        throw new Error(`Missing subcategories of ${c.name}`);
      }
      return {
        ...c,
        subcategories: c.subcategories.subcategories,
      };
    });
    return categories;
  } catch (e) {
    logError(e);
    return null;
  }
};

export const formatOffices = (payload?: OfficesPayload) => {
  if (!payload || !payload.items) {
    return null;
  }

  try {
    const floors = payload.items.map(c => {
      if (c.officeFloors?.__typename !== 'OfficeFloorList') {
        throw new Error(`Missing floors of ${c.id}`);
      }
      return {
        ...c,
        officeFloors: c.officeFloors.items,
      };
    });
    return floors;
  } catch (e) {
    logError(e);
    return null;
  }
};

export const formatSkuTemplates = (payload?: SkuTemplatePayload) => {
  if (!payload || !payload.fields) {
    return null;
  }

  const fieldsCopy = cloneDeep(payload.fields);

  const manufacturer = fieldsCopy.find(field => field.name === 'manufacturer');

  if (
    manufacturer &&
    manufacturer.type === 'OptionSetFieldDefinition' &&
    manufacturer.optionSet
  ) {
    manufacturer.optionSet.values.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  }

  return { ...payload, fields: fieldsCopy };
};

export const getStatusFromFieldTamplate = (
  payload?: SkuTemplatePayload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (!payload || !payload.fields) {
    return null;
  }
  const fieldsCopy = cloneDeep(payload?.fields);

  const findStatusTemplate = fieldsCopy?.find(e => e.name === 'status');

  if (findStatusTemplate?.__typename === 'OptionSetFieldDefinition') {
    return findStatusTemplate?.optionSet as OptionSet;
  }

  return { ...payload, fields: fieldsCopy };
};
