import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import React, { FC, useCallback } from 'react';
import { useEffect, useState } from 'react';

import { ErrorView } from '../../../common/components/ErrorView';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { GraphQlError } from '../../../common/types';
import { ResolvedSkuWithMetadata } from '../../types';

import { SkuReportView } from './SkuReportView';

type Props = {
  queryFilters: {
    field: string;
    values: string[];
    includeRelated: boolean;
  }[];
  skus: ResolvedSkuWithMetadata[];
};

export const SkuListReportView: FC<Props> = ({ queryFilters, skus }) => {
  const [error, setError] = useState<GraphQlError | null>(null);
  const [done, setDone] = useState<Record<string, boolean>>({});
  const skuInstanceFilters = queryFilters
    .filter(f => f.includeRelated)
    .map(f => ({
      ...f,
      includeRelated: false,
    }));
  const handleError = useCallback(error => {
    setError(error);
  }, []);
  const handleSuccess = useCallback((id: string) => {
    setDone(done => ({
      ...done,
      [id]: true,
    }));
  }, []);
  const isLoading = !!skus.find(sku => !done[sku.sku.id]);

  useEffect(() => {
    if (!isLoading) {
      window.print();
    }
  }, [isLoading]);

  if (error) {
    return <ErrorView error={error} />;
  }

  return (
    <>
      {isLoading && (
        <Modal open={true} disableAutoFocus disableEnforceFocus>
          <Box display="flex" width="100vw" height="100vh" alignItems="center">
            <LoadingIndicator />
          </Box>
        </Modal>
      )}
      {skus.map(sku => (
        <SkuReportView
          key={sku.sku.id}
          sku={sku}
          queryFilters={skuInstanceFilters}
          onError={handleError}
          onCompleted={() => handleSuccess(sku.sku.id)}
        />
      ))}
    </>
  );
};
