import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';

import { EndAdormentTypography } from '../Form/uiKit/ControlledTextField';
import { TextField } from '../TextField';

type NumberInputProps = {
  name: string;
  label: string;
  handleChange: (x: any) => void;
  adornment: string;
  value: any;
};
export const NumberInput = (props: NumberInputProps) => {
  return (
    <TextField
      value={props.value}
      name={props.name}
      label={props.label}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        props.handleChange(e.target.value);
      }}
      InputProps={
        {
          notched: false,
          endAdornment: props.adornment && (
            <InputAdornment position="end">
              <EndAdormentTypography variant="subtitle2">
                {props.adornment}
              </EndAdormentTypography>
            </InputAdornment>
          ),
        } as Partial<OutlinedInputProps>
      }
    />
  );
};
