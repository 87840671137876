import React, { FC, useMemo } from 'react';
import { useUnmount } from 'react-use';

import { Wizard } from '../../../common/components/Wizard';
import { StepComponent } from '../../../common/components/Wizard/types';
import {
  RegistrationParams,
  RouteActions,
  useNavigation,
} from '../../../common/navigation';
import { useNewSkuInstanceActions } from '../../redux/newSkuInstance';
import { makeUseStepState } from '../../utils/wizard';
import { LocationStep } from '../RegistrationView/steps/LocationStep';

import { FormStep } from './steps/FormStep';
import { PreregistrationSuccessStep } from './steps/PreregistrationSuccessStep';
import { SummaryStep } from './steps/SummaryStep';
import { NewInstanceStepProps, StepId } from './types';

type Props = {
  skuId: string;
};

export const steps = [
  {
    id: StepId.Location,
    component: LocationStep as StepComponent<NewInstanceStepProps>,
  },
  {
    id: StepId.Form,
    component: FormStep,
  },
  {
    id: StepId.Summary,
    component: SummaryStep,
  },
  {
    id: StepId.PreregistrationSuccess,
    component: PreregistrationSuccessStep,
  },
];

export const NewSkuInstanceView: FC<Props> = ({ skuId }) => {
  const nav = useNavigation();
  const { reset } = useNewSkuInstanceActions();

  const useStepState = useMemo(
    () =>
      makeUseStepState(
        nav.newSkuInstance as (params: RegistrationParams) => RouteActions,
        { skuId },
      ),
    [nav, skuId],
  );

  useUnmount(() => reset());

  return (
    <Wizard steps={steps} extraProps={{ skuId }} useStepState={useStepState} />
  );
};
