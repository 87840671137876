import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
} from '../../types';
import { hideRuleByFieldValue } from '../../rules';
import { getFields, StorageFields } from '../fields';
import { mapFieldKeys } from '../../utils';
import { StorageFunctionalities } from '../types';

const defaultValues: StorageFunctionalities = {
  storageBuiltInLighting: false,
};

const groups = (
  {
    storageDoorsMechanism,
    storageFeetType,
    storageLockType,
    storageShelvesType,
  }: StorageFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'doors',
    label: t(tk.storage.groups.doors.label),
    attributes: [storageDoorsMechanism],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [storageDoorsMechanism.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: storageDoorsMechanism,
        },
      ],
    },
  },
  {
    name: 'shelves',
    label: t(tk.storage.groups.shelves.label),
    attributes: [storageShelvesType],
    functionalities: [],
    hideRule: hideRuleByFieldValue(storageShelvesType.key, 'none'),
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [storageShelvesType.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: storageShelvesType,
          limitedTo: ['none', 'fixed', 'adjustable'],
        },
      ],
    },
  },
  {
    name: 'lock',
    label: t(tk.storage.groups.lock.label),
    attributes: [storageLockType],
    functionalities: [],
    hideRule: hideRuleByFieldValue(storageLockType.key, 'none'),
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [storageLockType.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: storageLockType,
        },
      ],
    },
  },
  {
    name: 'feet',
    label: t(tk.storage.groups.feet.label),
    attributes: [storageFeetType],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [storageFeetType.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: storageFeetType,
        },
      ],
    },
  },
];

export const storageBox = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields, t, tk),
  };
};
