import {
  applyMiddleware,
  createStore,
  Reducer,
  AnyAction,
  Middleware,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';

import { logError } from './logError';

const crashReporter: Middleware = () => next => action => {
  if (action.error) {
    logError({
      message: 'Action error',
      error: action.error,
    });
  }
  try {
    next(action);
  } catch (error) {
    logError({
      message: 'Action error',
      error: action.error,
    });
    // TODO add UI/UX error hanling, send action that will show show error message something went wrong
  }
};

export function configureStore<S extends {}, A extends AnyAction>(
  reducer: Reducer<S, A>,
  history: History,
) {
  let middlewares = applyMiddleware(
    ...[routerMiddleware(history), crashReporter],
  );

  if (process.env.NODE_ENV !== 'production') {
    middlewares = composeWithDevTools(middlewares);
  }

  return createStore(reducer, middlewares);
}
