import React from 'react';
import Container from '@material-ui/core/Container';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { FormFeedback } from '../../../common/components/FormFeedback';
import { Form } from '../../../common/components/Form';
import { GraphQlError } from '../../../common/types';
import { FormFields, FormLayout } from '../../../common/components/Form/types';
import { CancelButton } from '../Common/CancelButton';
import { useNavigation } from '../../../common/navigation';

import { FormHeading } from './FormHeading';

export type TenantFormFields = {
  displayName: string;
  user?: string;
};

type OfficeFormFields = {
  city: string;
  zipCode: string;
  street: string;
  streetNumber: string;
};

export type TenantWithOfficeFormFields = TenantFormFields & OfficeFormFields;

const useStyles = makeStyles(theme =>
  createStyles({
    cancelButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      float: 'right',
    },
  }),
);

type Props = {
  error?: GraphQlError;
  fields: FormFields;
  onSubmit: (data: TenantWithOfficeFormFields) => void;
  layout: FormLayout;
  headingText: string;
};

export const AddTenantView: React.FC<Props> = ({
  error,
  fields,
  onSubmit,
  layout,
  headingText,
}) => {
  const classes = useStyles();
  const nav = useNavigation();

  return (
    <Container>
      <FormHeading variant="h4" text={headingText}></FormHeading>
      <FormFeedback error={error} />
      <Form fields={fields} layout={layout} onSubmit={onSubmit} />
      <Box className={classes.cancelButton}>
        <CancelButton onCancel={() => nav.adminPanel().go()} />
      </Box>
    </Container>
  );
};
