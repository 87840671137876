import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Link } from '../../../../common/components/Link';
import { GoOptions } from '../../../../common/navigation';
import { useTranslation } from '../../../../translations';
import { ResolvedAdSkuWithInstances, ResolvedSku } from '../../../types';
import { getMainImageSrcSet, getMainImageUrl } from '../../../utils/sku';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    action: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    content: {
      /* This trick is to make text-overflow: ellipses work in a flex container */
      width: 'calc(100%)',
    },
    media: {
      objectFit: 'cover',
    },
    manufacturer: {
      textTransform: 'uppercase',
      marginBottom: theme.spacing(1),
    },
    primaryColor: {
      textTransform: 'capitalize',
      marginTop: theme.spacing(2),
    },
    counts: {
      marginTop: theme.spacing(3),
    },
  }),
);

type Props = {
  sku: ResolvedSku | ResolvedAdSkuWithInstances;
  route: { url: string; go(options?: GoOptions): void };
  metadataView?: React.ReactNode;
};

export const SkuListItem: FC<Props> = ({ sku, route, metadataView }) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');

  const subcategoryName =
    sku.subcategory &&
    sku.subcategory.__typename === 'Subcategory' &&
    sku.subcategory.name;

  return (
    <Card data-testid={`SkuListItem-${sku.id}`} className={classes.root}>
      <CardActionArea component={Link} route={route} className={classes.action}>
        <CardMedia
          className={classes.media}
          image={getMainImageUrl(sku)}
          srcSet={getMainImageSrcSet(sku)}
          sizes="50%"
          title={`${sku.manufacturer} ${sku.model}`}
          component="img"
        />
        <CardContent className={classes.content}>
          <Typography
            className={classes.manufacturer}
            variant="body1"
            noWrap={true}
          >
            {sku.manufacturer}
          </Typography>
          <Typography variant="h5">
            {sku.model || t(tk.skuListView.noModelLabel)}
          </Typography>
          <Typography className={classes.primaryColor} variant="body1">
            {sku.primaryColor?.label}
          </Typography>
          {subcategoryName && (
            <Typography variant="body1">{subcategoryName}</Typography>
          )}
          {metadataView}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
