import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export type StyleProps = Pick<CSSProperties, 'fontSize'>;

export const styles = () =>
  createStyles({
    root: (props: StyleProps) => ({
      fontSize: props.fontSize || 12,
    }),
  });

export const useStyles = makeStyles(styles);
