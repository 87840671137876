import React from 'react';

import { Props } from '../types';

import { useStyles } from './styles';

export const ArrowDown = (props: Props) => {
  const classes = useStyles(props);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      className={classes.root}
      {...props}
    >
      <path
        d="M2.94 4L6 7.09 9.06 4l.94.951L6 9 2 4.951 2.94 4z"
        fill="#3A352D"
      />
    </svg>
  );
};
