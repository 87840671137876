import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';

import {
  tableAdjustableHeight,
  tableAdjustableWidth,
  tableDrawer,
  tableFeet,
  tableFoldable,
  tableStackable,
  tableCableSupport,
  tableCableManagement,
  tablePrivacyScreen,
  tableTopShape,
} from './fields';

export const getFields = (t: TFunction, tk: TKeys<'functionality'>) => ({
  tableAdjustableHeight: tableAdjustableHeight(t, tk),
  tableAdjustableWidth: tableAdjustableWidth(t, tk),
  tableDrawer: tableDrawer(t, tk),
  tableFeet: tableFeet(t, tk),
  tableFoldable: tableFoldable(t, tk),
  tableStackable: tableStackable(t, tk),
  tableCableSupport: tableCableSupport(t, tk),
  tableCableManagement: tableCableManagement(t, tk),
  tablePrivacyScreen: tablePrivacyScreen(t, tk),
  tableTopShape: tableTopShape(t, tk),
});

export type TableFields = ReturnType<typeof getFields>;
