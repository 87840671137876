export const actionTypes = {
  INITIALIZE_FROM_EXISTING_SKU: 'inventory/newSku/INITIALIZE_FROM_EXISTING_SKU',
  SET_COLORS: 'inventory/newSku/SET_COLORS',
  SET_DESCRIPTION: 'inventory/newSku/SET_DESCRIPTION',
  SET_DIMENSIONS: 'inventory/newSku/SET_DIMENSIONS',
  SET_FUNCTIONALITY_VALUES: 'inventory/newSku/SET_FUNCTIONALITY_VALUES',
  SET_IMAGES: 'inventory/newSku/SET_IMAGES',
  SET_MAIN_CATEGORY: 'inventory/newSku/SET_MAIN_CATEGORY',
  SET_MODEL_DATA: 'inventory/newSku/SET_MODEL_DATA',
  SET_SUBCATEGORY: 'inventory/newSku/SET_SUBCATEGORY',
  RESET: 'inventory/newSku/RESET',
} as const;

export type ActionTypes = typeof actionTypes;
