import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      borderRadius: 16,
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),
);

export type ButtonSelectProps = {
  items: {
    label: string;
    onClick: () => void;
  }[];
};

export const ButtonSelect: React.FC<ButtonSelectProps> = ({ items }) => {
  const classes = useStyles();
  return (
    <>
      {items.map(item => (
        <Button
          key={item.label}
          className={classes.button}
          color="secondary"
          onClick={item.onClick}
          size="small"
          fullWidth
        >
          {item.label}
        </Button>
      ))}
    </>
  );
};
