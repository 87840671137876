import React from 'react';
import Alert from '@material-ui/lab/Alert';

import { useTranslation } from '../../../translations';
import { Row } from '../Row';
import { GraphQlError, Maybe } from '../../types';

type Props = {
  error?: Maybe<GraphQlError>;
  success?: boolean;
  sucessMessage?: string;
};

export const FormFeedback: React.FC<Props> = ({
  error,
  success,
  sucessMessage,
}) => {
  const { t, tk } = useTranslation('common');
  if (error) {
    return (
      <Row>
        <Alert severity="error" data-testid="error">
          {error?.message || t(tk.errors.generic)}
        </Alert>
      </Row>
    );
  }
  if (success) {
    return (
      <Row>
        <Alert severity="success" data-testid="success">
          {sucessMessage || t(tk.success)}
        </Alert>
      </Row>
    );
  }
  return null;
};
