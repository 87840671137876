import { useState } from 'react';

import {
  UpdateTenantInput,
  UpdateTenantMutation,
  useUpdateTenantMutation,
} from '../../../inventory/graphql';
import { extractMutation } from '../../../common/utils/extractQuery';
import { UpdateTenantMutationPayload } from '../../../inventory/graphql/types';

export const useUpdateTenant = (onCompleted?: () => void) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const [updateTenantMutation, rawMutationResult] = useUpdateTenantMutation({
    onCompleted: () => {
      setIsProcessing(false);
      if (onCompleted) {
        onCompleted();
      }
    },
  });

  const mutationResult = extractMutation<
    UpdateTenantMutation,
    UpdateTenantMutationPayload
  >(rawMutationResult, 'CreateTenantPayload', data => data.updateTenant);

  const updateTenant = ({ tenant, id }: UpdateTenantInput) => {
    setIsProcessing(true);
    updateTenantMutation({
      variables: {
        input: {
          id,
          tenant,
        },
      },
    });
  };

  return {
    isProcessing,
    updateTenant,
    mutationResult,
  };
};
