import { produce } from 'immer';

import { SkuInstanceInput } from '../../graphql';

import { Actions } from './actions';
import { actionTypes } from './actionTypes';

export type State = {
  inputs: Partial<
    Pick<
      SkuInstanceInput,
      | 'condition'
      | 'status'
      | 'officeFloorId'
      | 'officeId'
      | 'costCenter'
      | 'procurementCost'
      | 'procurementDate'
      | 'procurementNumber'
    >
  >[];
  count?: number;
  preregistrationSuccess?: boolean;
};

export const initialData: State = {
  inputs: [],
};

export const reducer = produce((state: State, action: Actions) => {
  switch (action.type) {
    case actionTypes.SET_COUNT: {
      state.count = action.count;
      return;
    }

    case actionTypes.PREREGISTRATION_SUCCESS: {
      state.preregistrationSuccess = true;
      return;
    }

    case actionTypes.SET_INPUTS: {
      state.inputs = action.inputs;
      return;
    }

    case actionTypes.RESET: {
      return { ...initialData };
    }
  }
}, initialData);
