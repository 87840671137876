import { ReactNode, useMemo, ComponentType } from 'react';

import { useTranslation } from '../../translations';
import { ResolvedMainCategory } from '../context/types';
import { AdSkuInput } from '../graphql';
import {
  FieldDefinition,
  FieldProps,
  FormLayout,
} from '../../common/components/Form/types';
import ensure from '../../common/utils';
import { SkuTemplatePayload } from '../graphql/types';
import { useCategories } from '../context/useCategories';
import { useAdSkuTemplate } from '../context/useAdSkuTemplate';

import {
  gridItemStandard,
  gridItemLarge,
  gridItemSmall,
  disableFields,
} from './common';
import { resolveFields } from './utils';
import {
  getLabel,
  isHiddenSecondaryColor,
  getDimensionFieldLabel,
  isHiddenInSubcategory,
} from './useSkuForm';

export const adSkuFormFields = [
  'manufacturer',
  'description',
  'maxHeight',
  'maxWidth',
  'minWidth',
  'maxLength',
  'minLength',
  'minHeight',
  'model',
  'primaryColor',
  'secondaryColor',
  'mainCategoryId',
  'subcategoryId',
] as const;

export type SupportedAdSkuField = typeof adSkuFormFields[number];

export type AdSkuFormInput = Pick<AdSkuInput, SupportedAdSkuField>;

const getOverrides = (
  categories: ResolvedMainCategory[],
): Record<string, Partial<FieldDefinition>> => ({
  mainCategoryId: {
    type: 'SelectFieldDefinition',
    options: categories.map(c => ({
      label: c.name,
      value: c.id,
    })),
  },
  subcategoryId: {
    type: 'SelectFieldDefinition',
    disabled: values => !values.mainCategoryId,
    strict: true,
    options: values =>
      values.mainCategoryId
        ? ensure(
            categories.find(c => c.id === values.mainCategoryId),
          ).subcategories.map(c => ({
            label: c.name,
            value: c.id,
          }))
        : [],
  },
  description: {
    rows: 3,
    required: false,
  },
  minHeight: {
    required: false,
  },
  maxHeight: {
    required: false,
  },
  minWidth: {
    required: false,
  },
  maxWidth: {
    required: false,
  },
  minLength: {
    required: false,
  },
  maxLength: {
    required: false,
  },
});

export const getAdSkuFormFields = (
  templateFields: SkuTemplatePayload['fields'],
  categories: ResolvedMainCategory[],
  keys: readonly SupportedAdSkuField[] = adSkuFormFields,
) => {
  return resolveFields(templateFields, getOverrides(categories), keys);
};

export const useAdSkuFormFields = (
  keys: readonly SupportedAdSkuField[] = adSkuFormFields,
  disabledFields = false,
): Record<string, FieldDefinition> => {
  const categories = useCategories();
  const { fields } = useAdSkuTemplate();
  return useMemo(() => {
    const formFields = getAdSkuFormFields(fields, categories, keys);

    return disabledFields ? disableFields(formFields) : formFields;
  }, [fields, categories, keys, disabledFields]);
};

/* eslint-disable-next-line max-lines-per-function */
export const useAdSkuFormLayout = (
  categories: ResolvedMainCategory[],
  ColorFieldComponent: ComponentType<FieldProps>,
  component?: ReactNode,
): FormLayout => {
  const { t, tk } = useTranslation('inventory');
  return useMemo(
    /* eslint-disable-next-line max-lines-per-function */
    () => [
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemLarge,
            name: 'mainCategoryId',
            label: t(tk.fieldLabels.mainCategoryId),
          },
          {
            gridItemProps: gridItemLarge,
            name: 'subcategoryId',
            label: t(tk.fieldLabels.subcategoryId),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemLarge,
            name: 'manufacturer',
            label: t(tk.fieldLabels.manufacturer),
          },
          {
            gridItemProps: gridItemLarge,
            name: 'model',
            label: t(tk.fieldLabels.model),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'primaryColor',
            label: values =>
              getLabel(t, tk.fieldLabels.primaryColor, values, categories),
            Component: ColorFieldComponent,
          },
          {
            gridItemProps: gridItemStandard,
            name: 'secondaryColor',
            label: values =>
              getLabel(t, tk.fieldLabels.secondaryColor, values, categories),
            Component: ColorFieldComponent,
            hidden: values => isHiddenSecondaryColor(categories, values),
          },
        ],
      },
      {
        type: 'component',
        component,
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemSmall,
            name: 'minHeight',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.minHeight),
                t(tk.fieldLabels.height),
                'minHeight',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('minHeight', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'maxHeight',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.maxHeight),
                t(tk.fieldLabels.height),
                'minHeight',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('maxHeight', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'minWidth',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.minWidth),
                t(tk.fieldLabels.width),
                'minWidth',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('minWidth', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'maxWidth',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.maxWidth),
                t(tk.fieldLabels.width),
                'maxWidth',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('maxWidth', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'maxLength',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.maxLength),
                t(tk.fieldLabels.length),
                'maxLength',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('maxLength', categories, values),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'minLength',
            label: values =>
              getDimensionFieldLabel(
                t(tk.fieldLabels.minLength),
                t(tk.fieldLabels.length),
                'minLength',
                categories,
                values,
              ),
            hidden: values =>
              isHiddenInSubcategory('maxWidth', categories, values),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 12 },
            name: 'description',
            label: t(tk.fieldLabels.description),
          },
        ],
      },
    ],
    [t, tk, component, categories, ColorFieldComponent],
  );
};
