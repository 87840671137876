import React, { useState } from 'react';

import { CheckboxListBase } from './CheckboxListBase';
import { CheckboxListColorItem } from './CheckboxListColorItem';
import { CheckboxListItem } from './CheckboxListItem';
import { CheckboxListNode } from './CheckboxListNode';
import { TextLessFilterDefinition } from './types';

export type CheckboxListProps = {
  className?: string;
  defaultExpanded?: boolean;
  selected: string[];
  onChange: (selected: string[]) => void;
} & TextLessFilterDefinition;

const getDefaultExpanded = (props: CheckboxListProps) => {
  switch (props.type) {
    case 'nodes':
      return props.nodes.length <= 6;
    case 'range':
      return props.nodes.length <= 6;
    case 'colors':
      return props.items.length <= 12;
    default:
      return props.items.length <= 6;
  }
};

export const CheckboxList: React.FC<CheckboxListProps> = props => {
  const [expanded, setExpanded] = useState(getDefaultExpanded(props) || false);
  const handleExpand = () => {
    setExpanded(true);
  };

  const handleToggle = (value: string) => () => {
    const currentIndex = props.selected.indexOf(value);
    let newSelected = [...props.selected];

    if (currentIndex === -1) {
      props.singleSelect ? (newSelected = [value]) : newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    props.onChange(newSelected);
  };

  let CheckboxListComponent: React.ReactNode;

  switch (props.type) {
    case 'nodes': {
      const displayedNodes = expanded
        ? props.nodes
        : props.nodes
            .slice(0, 2)
            .concat(
              props.nodes
                .slice(2)
                .filter(n =>
                  n.items.some(i => props.selected.includes(i.value)),
                ),
            );
      CheckboxListComponent = displayedNodes.map(node => (
        <CheckboxListNode
          key={node.name}
          handleToggle={handleToggle}
          onChange={props.onChange}
          items={node.items}
          selected={props.selected}
          name={props.name}
          groupName={node.name}
          singleSelect={props.singleSelect}
        ></CheckboxListNode>
      ));
      break;
    }
    case 'range': {
      const displayedNodes = expanded
        ? props.nodes
        : props.nodes
            .slice(0, 2)
            .concat(
              props.nodes
                .slice(2)
                .filter(n =>
                  n.items.some(i => props.selected.includes(i.value)),
                ),
            );
      CheckboxListComponent = displayedNodes.map(node => (
        <CheckboxListNode
          key={node.name}
          handleToggle={handleToggle}
          onChange={props.onChange}
          items={node.items}
          selected={props.selected}
          name={props.name}
          groupName={node.name}
          singleSelect={props.singleSelect}
        ></CheckboxListNode>
      ));
      break;
    }
    case 'colors': {
      const displayedItems = expanded
        ? props.items
        : props.items
            .slice(0, 8)
            .concat(
              props.items
                .slice(8)
                .filter(i => props.selected.includes(i.value)),
            );
      CheckboxListComponent = displayedItems.map(item => (
        <CheckboxListColorItem
          key={item.value}
          handleToggle={handleToggle}
          colorHelper={props.colorHelper}
          item={item}
          selected={props.selected.indexOf(item.value) !== -1}
          name={props.name}
          singleSelect={props.singleSelect}
        ></CheckboxListColorItem>
      ));
      break;
    }
    default: {
      const displayedItems = expanded
        ? props.items
        : props.items
            .slice(0, 4)
            .concat(
              props.items
                .slice(4)
                .filter(i => props.selected.includes(i.value)),
            );
      CheckboxListComponent = displayedItems.map(item => (
        <CheckboxListItem
          key={item.value}
          handleToggle={handleToggle(item.value)}
          item={item}
          selected={props.selected.indexOf(item.value) !== -1}
          name={props.name}
          singleSelect={props.singleSelect}
        ></CheckboxListItem>
      ));
    }
  }

  return (
    <CheckboxListBase
      data-testid={`CheckboxList-${props.name}`}
      title={props.title}
      className={props.className}
      expanded={expanded}
      onExpand={handleExpand}
    >
      {CheckboxListComponent}
    </CheckboxListBase>
  );
};
