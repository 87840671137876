import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
  FunctionalityValue,
} from '../types';
import { mapFieldKeys, someTrue } from '../utils';

import { ChairFunctionalities } from './types';
import { getFields, ChairFields } from './fields';

const defaultValues: ChairFunctionalities = {
  chairAdjustableHeight: 'fixed',
  chairBack: true,
  chairBackLength: 'standard',
  chairBackFixed: true,
  chairBackAdjustableHeight: false,
  chairBackAdjustableAngle: false,
  chairLeaningBack: false,
  chairSeatFixed: true,
  chairSeatAdjustablePosition: false,
  chairSeatAdjustableAngle: false,
  chairArmrests: false,
  chairArmrestsFixed: false,
  chairArmrestsAdjustablePosition: false,
  chairArmrestsAdjustableHeight: false,
  chairArmrestsAdjustableWidth: false,
  chairArmrestsAdjustableLength: false,
  chairFeetCount: '4',
  chairNeckrest: 'none',
  chairFootring: false,
  chairStackable: false,
  chairFoldable: false,
  chairSeat: true,
};

const processValues = (values: Record<string, FunctionalityValue>) => {
  return {
    ...values,
    chairBack: someTrue(values, [
      'chairBackFixed',
      'chairBackAdjustableHeight',
      'chairBackAdjustableAngle',
    ]),
    chairArmrests: someTrue(values, [
      'chairArmrestsFixed',
      'chairArmrestsAdjustablePosition',
      'chairArmrestsAdjustableHeight',
      'chairArmrestsAdjustableWidth',
      'chairArmrestsAdjustableLength',
    ]),
  };
};

const groups = (
  {
    chairBackFixed,
    chairBackAdjustableHeight,
    chairBackAdjustableAngle,
    chairArmrestsFixed,
    chairArmrestsAdjustablePosition,
    chairArmrestsAdjustableHeight,
    chairArmrestsAdjustableWidth,
    chairArmrestsAdjustableLength,
    chairFeetCount,
    chairFeetType,
    chairStackable,
    chairFoldable,
  }: ChairFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'back',
    label: t(tk.chairs.groups.back.label),
    attributes: [chairBackFixed],
    functionalities: [chairBackAdjustableHeight, chairBackAdjustableAngle],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      rows: [
        {
          type: FunctionalityEditRow.BooleanGroup,
          promptLabel: t(tk.chairs.groups.back.promptLabel),
          exclusiveFields: [chairBackFixed.key],
          fields: [
            chairBackFixed,
            chairBackAdjustableHeight,
            chairBackAdjustableAngle,
          ],
        },
      ],
    },
  },
  {
    name: 'armrests',
    label: t(tk.chairs.groups.armrests.label),
    attributes: [chairArmrestsFixed],
    functionalities: [
      chairArmrestsAdjustablePosition,
      chairArmrestsAdjustableHeight,
      chairArmrestsAdjustableWidth,
      chairArmrestsAdjustableLength,
    ],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      rows: [
        {
          type: FunctionalityEditRow.BooleanGroup,
          promptLabel: t(tk.chairs.groups.armrests.promptLabel),
          exclusiveFields: [chairArmrestsFixed.key],
          fields: [
            chairArmrestsFixed,
            chairArmrestsAdjustablePosition,
            chairArmrestsAdjustableHeight,
            chairArmrestsAdjustableWidth,
            chairArmrestsAdjustableLength,
          ],
        },
      ],
    },
  },
  {
    name: 'feet',
    label: t(tk.chairs.groups.feet.label),
    attributes: [chairFeetCount, chairFeetType],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [chairFeetCount.key, chairFeetType.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairFeetCount,
        },
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairFeetType,
        },
      ],
    },
  },
  {
    name: 'stackable',
    label: t(tk.chairs.groups.stackable.label),
    attributes: [chairStackable],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.chairs.groups.stackable.noValueLabel),
      field: chairStackable,
    },
  },
  {
    name: 'foldable',
    label: t(tk.chairs.groups.foldable.label),
    attributes: [chairFoldable],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.chairs.groups.foldable.noValueLabel),
      field: chairFoldable,
    },
  },
];

export const conferenceChairs = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields, t, tk),
    processValues,
  };
};
