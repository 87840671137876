export const skuTemplateName = 'sku';
export const skuInstanceTemplateName = 'skuInstance';
export const adSkuTemplateName = 'adSku';
export const adSkuInstanceTemplateName = 'adSkuInstance';

export const imageSizes = [400, 800, 1200];
export const defaultImageSize = 800;

export const acceptedImages = [
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/tiff',
  'image/gif',
];

export const fallbackColor = '#000000';

export const defaultGtin = '7090097645904';
