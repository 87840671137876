import React, { FC } from 'react';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { ResolvedMainCategory } from '../../../context/types';
import { ButtonSelect } from '../../../../common/components/ButtonSelect';

type Props = {
  categories: ResolvedMainCategory[];
  number: number;
  totalCount: number;
  onChange: (category: ResolvedMainCategory) => void;
  onGoBack?: () => void;
};

export const MainCategoryStep: FC<Props> = ({
  categories,
  number,
  totalCount,
  onChange,
  onGoBack,
}) => {
  const { t, tk } = useTranslation('inventory');

  const items = categories.map(c => ({
    label: c.name,
    onClick: () => onChange(c),
  }));

  return (
    <WizardStep
      backLabel={t(tk.newSkuView.mainCategoryStep.backLabel)}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      title={t(tk.newSkuView.mainCategoryStep.title)}
      data-testid="MainCategoryStep"
    >
      <ButtonSelect items={items} />
    </WizardStep>
  );
};
