import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import pick from 'lodash/pick';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { DimensionsData, useNewSkuActions } from '../../../redux/newSku';
import {
  DimensionsStep as DimensionsStepComponent,
  fieldNames,
} from '../../../components/NewSkuView/DimensionsStep';
import * as selectors from '../../../redux/newSku/selectors';
import { useAssertCategory } from '../useAssertCategory';
import { useSkuFormFields } from '../../../forms';
import { filterDimensionFieldNames } from '../../../models/utils';

export const DimensionsStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onGoBack,
  onGoToStep,
  onStepCompleted,
}) => {
  const backLabel = useSelector(selectors.label);
  const skuInput = useSelector(selectors.skuInput);
  const mainCategoryKey = useSelector(selectors.mainCategoryKey);
  const subcategoryKey = useSelector(selectors.subcategoryKey);
  const { setDimensions } = useNewSkuActions();
  const fields = useSkuFormFields(fieldNames);

  useAssertCategory(onGoToStep);

  const handleSubmit = (data: DimensionsData) => {
    setDimensions(data);
    onStepCompleted();
  };

  if (!subcategoryKey) {
    return null;
  }

  const key = mainCategoryKey === 'various' ? mainCategoryKey : subcategoryKey;

  const fieldNamesBySubcategory = filterDimensionFieldNames(key, fieldNames);

  const initialValues = pick(skuInput, fieldNamesBySubcategory);
  const dimensionFields = pick(fields, fieldNamesBySubcategory);

  return (
    <DimensionsStepComponent
      backLabel={backLabel}
      fields={dimensionFields}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      subcategoryKey={key}
    />
  );
};
