import {
  Box,
  Checkbox,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { ChangeEvent } from 'react';

import { IndicatorChip } from '../../../../common/components/IndicatorChip/IndicatorChip';
import { SkuInstanceFieldsFragment } from '../../../graphql';
import { useTranslation } from '../../../../translations';

import { ConditionChip } from './ConditionChip';
import { StatusChip } from './StatusChip';

type Props = {
  skuInstance?: { __typename: 'SkuInstance' } & SkuInstanceFieldsFragment;
  handleSelectedSkuItems?: (
    e: ChangeEvent<HTMLInputElement>,
    chekced: boolean,
  ) => void;
  selectedSkuItems?: string[];
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
      padding: theme.spacing(4, 3, 3),
    },
    mainBlock: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    chip: {
      cursor: 'inherit',
    },

    chipStatus: {
      cursor: 'inherit',
      backgroundColor: theme.palette.info.veryLight,
    },
    chipOffice: {
      cursor: 'inherit',
      backgroundColor: theme.palette.grey['300'],
    },

    divider: {
      backgroundColor: theme.palette.primary.veryLight,
      '&:last-child': {
        display: 'none',
      },
    },
  }),
);

export const SkuInstanceItemMainBlock = ({
  skuInstance,
  selectedSkuItems,
  handleSelectedSkuItems,
}: Props) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');

  const isDisabled = skuInstance?.status === 'pre-registered';

  const officeInfo =
    skuInstance?.office.__typename === 'Office' &&
    `${skuInstance?.office.locationNumber} [${skuInstance.office.zipCode} ${skuInstance.office.city}]`;

  return (
    <div>
      <Box display={'flex'} gridColumnGap={'8px'} alignItems={'center'}>
        {handleSelectedSkuItems && (
          <Checkbox
            disabled={isDisabled}
            color="primary"
            value={skuInstance?.id}
            onChange={handleSelectedSkuItems}
            checked={selectedSkuItems?.includes(skuInstance?.id || '')}
          />
        )}
        <Typography>
          <strong>
            {t(tk.skuInstanceListView.item.primary, {
              id: skuInstance?.id,
            })}
          </strong>
        </Typography>
      </Box>
      <Box display={'flex'} flexWrap={'wrap'} gridGap={'8px'} mt={2}>
        {skuInstance?.status && (
          <StatusChip
            size="small"
            className={classes.chip}
            status={skuInstance.status}
          />
        )}

        {skuInstance?.condition && (
          <ConditionChip
            size="small"
            className={classes.chip}
            condition={skuInstance.condition}
          />
        )}

        {skuInstance?.processingStage && (
          <IndicatorChip
            size="small"
            color="secondary"
            className={classes.chipStatus}
            label={
              t(tk.skuInstanceListView.item.processingStage) +
              skuInstance.processingStage
            }
          />
        )}
        {officeInfo && (
          <IndicatorChip
            size="small"
            color="secondary"
            className={classes.chipOffice}
            label={t(tk.skuInstanceListView.item.address) + officeInfo}
          />
        )}
      </Box>
    </div>
  );
};
