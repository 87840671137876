import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { ArrowDown } from '../../../../common/components/icons/12px/ArrowDown';

import { ContextMenuTitle } from './ContextMenuTitle';
import { ContextMenuButton } from './ContextMenuButton';

const useStyles = makeStyles(theme =>
  createStyles({
    menuButton: {
      borderRadius: theme.spacing(1),
      borderWidth: 1,
      padding: theme.spacing(0),
      minHeight: 0,
      minWidth: 0,
    },
    menuPaper: {
      borderRadius: theme.spacing(1),
      marginTop: 0,
      marginLeft: 0,
    },
    menuItem: {
      fontSize: 13,
    },
    menuTitle: {
      '&:focus': {
        outline: 'none',
      },
      opacity: 0.5,
    },
  }),
);

export type ContextMenuProps = {
  title: ReactNode | string;
  items: { label: string; onSelect?: () => void; id?: string }[];
  showExpandIcon?: boolean;
};

export const ContextMenu: FC<ContextMenuProps> = ({
  title,
  items,
  showExpandIcon,
}) => {
  const classes = useStyles();
  const anchorEl = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(
    event => {
      event.target.scrollIntoViewIfNeeded();
      setOpen(!open);
    },
    [setOpen, open],
  );

  const handleClose = (action?: () => void) => {
    setOpen(false);
    if (action) {
      action();
    }
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl.current}
        keepMounted
        getContentAnchorEl={null}
        elevation={2}
        marginThreshold={0}
        anchorOrigin={{ vertical: 0, horizontal: 0 }}
        transformOrigin={{ vertical: 0, horizontal: 0 }}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          disablePadding: true,
        }}
        PaperProps={{
          className: classes.menuPaper,
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          'data-testid': 'ContextMenu',
        }}
      >
        <div className={classes.menuTitle} onClick={() => handleClose()}>
          <ContextMenuTitle
            title={title}
            icon={showExpandIcon && <ArrowDown />}
          />
        </div>
        {items.map((item, idx) => (
          <MenuItem
            dense
            className={classes.menuItem}
            key={idx}
            disabled={!item.onSelect}
            id={item.id}
            onClick={
              item.onSelect ? () => handleClose(item.onSelect) : undefined
            }
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <ContextMenuButton ref={anchorEl} onClick={handleClick}>
        <ContextMenuTitle
          title={title}
          icon={showExpandIcon && <ArrowDown />}
        />
      </ContextMenuButton>
    </>
  );
};
