import React, { FC } from 'react';

import { ButtonListItem } from '../../../../common/components/ButtonListItem';
import {
  FunctionalityEditGroup,
  FunctionalityValue,
  ResolvedFunctionalityGroup,
} from '../../../models';
import { getBooleanGroupLabel } from '../../../models/getGroupLabel';

type Props = {
  group: ResolvedFunctionalityGroup & {
    editConfig: Extract<
      ResolvedFunctionalityGroup['editConfig'],
      { type: FunctionalityEditGroup.Boolean }
    >;
  };
  values: Record<string, FunctionalityValue>;
  onChange?: (changed: Record<string, FunctionalityValue>) => void;
};

export const BooleanFunctionalityGroup: FC<Props> = ({
  group,
  values,
  onChange,
  ...props
}) => {
  const { editConfig, label } = group;

  const key = editConfig.field.key;
  const value = values[key];

  const handleClick = () => {
    if (onChange) {
      onChange({ [key]: !value });
    }
  };

  return (
    <ButtonListItem
      variant={value ? 'success' : 'standard'}
      primaryText={label}
      secondaryText={getBooleanGroupLabel(editConfig, value)}
      onClick={handleClick}
      {...props}
    />
  );
};
