import React, { FC, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { ResolvedAdSku } from '../../../types';
import { AdSkuInstanceInput, Maybe } from '../../../graphql';
import { Navigation } from '../../../../common/navigation';
import { SkuCard } from '../../common/SkuCard/SkuCard';
import { RegistrationCompleteDialog } from '../../common/RegistrationCompleteDialog';

import { Chips } from './Chips';

const useStyles = makeStyles(theme =>
  createStyles({
    body: {
      background: theme.palette.success.veryLight,
    },
    description: {
      color: theme.palette.primary.light,
    },
  }),
);

type Props = {
  adSku: Maybe<ResolvedAdSku>;
  adSkuInstance?: Partial<
    Pick<
      AdSkuInstanceInput,
      | 'condition'
      | 'status'
      | 'officeId'
      | 'processingStage'
      | 'salesPeriodDateFrom'
      | 'salesPeriodDateTo'
      | 'salesPrice'
    >
  >;
  count: number;
  nav: Navigation;
  onGoBack?: () => void;
};

export const SummaryStep: FC<Props> = ({
  adSku,
  adSkuInstance,
  count,
  nav,
  onGoBack,
}) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  const handleListingSku = () => {
    if (adSku) {
      nav.adSku({ id: adSku.id }).go();
    }
  };

  useEffect(() => {
    document.body.classList.add(classes.body);
    return () => {
      document.body.classList.remove(classes.body);
    };
  }, [classes.body]);

  const handleRegisterNew = () => {
    nav.registration().go();
  };

  const handleViewInventory = () => null;

  return (
    <WizardStep
      showProgress={false}
      title={t(tk.newAdSkuInstanceView.summaryStep.title)}
      data-testid="SummaryStep"
      onGoBack={onGoBack}
    >
      <Typography variant="subtitle1"></Typography>
      <Box mt={2} mb={3}>
        {adSku && (
          <SkuCard
            sku={adSku}
            title={t(
              tk.newSkuInstanceView.preregistrationSuccessStep.skuCardTitle,
              {
                count,
              },
            )}
            btnLabel={t(
              tk.newSkuInstanceView.preregistrationSuccessStep
                .skuCardButtonLabel,
            )}
            onClick={handleListingSku}
            chips={
              <Chips
                from={adSkuInstance?.salesPeriodDateFrom}
                to={adSkuInstance?.salesPeriodDateTo}
                price={adSkuInstance?.salesPrice}
              />
            }
          />
        )}
      </Box>
      <RegistrationCompleteDialog
        handleRegisterNew={handleRegisterNew}
        handleViewInventory={handleViewInventory}
      />
    </WizardStep>
  );
};
