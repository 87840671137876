import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';
import { FunctionalityFieldDisplayType } from '../../types';
import { genBooleanField, genOptionSetField } from '../../configUtils';

export const tableAdjustableWidth = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genBooleanField({
    key: 'tableAdjustableWidth',
    t,
    tk: tk.tables.fields.tableAdjustableWidth,
  });

export const tableAdjustableHeight = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genOptionSetField({
    key: 'tableAdjustableHeight',
    t,
    tk: tk.tables.fields.tableAdjustableHeight,
    type: FunctionalityFieldDisplayType.String,
  });

export const tableFeet = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'tableFeet',
    t,
    tk: tk.tables.fields.tableFeet,
    type: FunctionalityFieldDisplayType.String,
  });

export const tableCableSupport = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'tableCableSupport',
    t,
    tk: tk.tables.fields.tableCableSupport,
    type: FunctionalityFieldDisplayType.String,
  });

export const tableCableManagement = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genOptionSetField({
    key: 'tableCableManagement',
    t,
    tk: tk.tables.fields.tableCableManagement,
    type: FunctionalityFieldDisplayType.String,
  });

export const tablePrivacyScreen = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'tablePrivacyScreen',
    t,
    tk: tk.tables.fields.tablePrivacyScreen,
    type: FunctionalityFieldDisplayType.String,
  });

export const tableTopShape = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'tableTopShape',
    t,
    tk: tk.tables.fields.tableTopShape,
    type: FunctionalityFieldDisplayType.String,
  });

export const tableDrawer = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'tableDrawer',
    t,
    tk: tk.tables.fields.tableDrawer,
  });

export const tableStackable = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'tableStackable',
    t,
    tk: tk.tables.fields.tableStackable,
  });

export const tableFoldable = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'tableFoldable',
    t,
    tk: tk.tables.fields.tableFoldable,
  });
