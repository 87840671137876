import React, { FC, useMemo } from 'react';

import { LoadingIndicator } from '../../common/components/LoadingIndicator';
import { ErrorView } from '../../common/components/ErrorView';
import { ContextQuery, OptionSet, useContextQuery } from '../graphql';
import {
  AdSkuInstanceTemplatePayload,
  AdSkuTemplatePayload,
  MainCategoriesPayload,
  MappingsPayload,
  OfficesPayload,
  SkuInstanceTemplatePayload,
  SkuTemplatePayload,
} from '../graphql/types';
import { extractQuery } from '../../common/utils/extractQuery';

import { ResolvedMainCategory, ResolvedOffice } from './types';
import {
  COLOR_TO_HEX_MAPPING,
  formatCategories,
  formatOffices,
  formatSkuTemplates,
  getColorMapping,
  getStatusFromFieldTamplate,
} from './utils';

export type InitialData = {
  categories: ResolvedMainCategory[];
  colorMapping: Record<string, string>;
  offices: ResolvedOffice[];
  skuTemplate: SkuTemplatePayload;
  adSkuTemplate: AdSkuTemplatePayload;
  skuInstanceTemplate: SkuInstanceTemplatePayload;
  adSkuInstanceTemplate: AdSkuInstanceTemplatePayload;
  status: OptionSet;
};

export const InventoryContext = React.createContext<InitialData>(
  {} as InitialData,
);

type Props = {};

export const InventoryProvider: FC<Props> = ({ children }) => {
  const contextQuery = useContextQuery({
    variables: {
      mappings: [COLOR_TO_HEX_MAPPING],
    },
  });
  const query = extractQuery<ContextQuery, MainCategoriesPayload>(
    contextQuery,
    'MainCategoryList',
    data => data.mainCategories,
  );
  const skuTemplateQuery = extractQuery<ContextQuery, SkuTemplatePayload>(
    contextQuery,
    'Template',
    data => data.skuTemplate,
  );
  const adSkuTemplateQuery = extractQuery<ContextQuery, SkuTemplatePayload>(
    contextQuery,
    'Template',
    data => data.adSkuTemplate,
  );
  const skuInstanceTemplateQuery = extractQuery<
    ContextQuery,
    SkuTemplatePayload
  >(contextQuery, 'Template', data => data.skuInstanceTemplate);
  const adSkuInstanceTemplateQuery = extractQuery<
    ContextQuery,
    SkuTemplatePayload
  >(contextQuery, 'Template', data => data.adSkuInstanceTemplate);
  const mappingsQuery = extractQuery<ContextQuery, MappingsPayload>(
    contextQuery,
    'MappingList',
    data => data.mappings,
  );
  const officesQuery = extractQuery<ContextQuery, OfficesPayload>(
    contextQuery,
    'OfficeList',
    data => data.offices,
  );

  const categories = useMemo(() => formatCategories(query.data), [query.data]);
  const colorMapping = useMemo(() => getColorMapping(mappingsQuery.data), [
    mappingsQuery,
  ]);
  const offices = useMemo(() => formatOffices(officesQuery.data), [
    officesQuery.data,
  ]);

  const status = useMemo(
    () => getStatusFromFieldTamplate(skuInstanceTemplateQuery.data),
    [skuInstanceTemplateQuery.data],
  );

  const skuTemplate = useMemo(() => formatSkuTemplates(skuTemplateQuery.data), [
    skuTemplateQuery.data,
  ]);
  const adSkuTemplate = useMemo(
    () => formatSkuTemplates(adSkuTemplateQuery.data),
    [adSkuTemplateQuery.data],
  );

  const value = useMemo(() => {
    if (
      categories &&
      colorMapping &&
      offices &&
      status &&
      skuTemplate &&
      adSkuTemplate &&
      skuInstanceTemplateQuery.data &&
      adSkuInstanceTemplateQuery.data
    ) {
      return {
        categories,
        colorMapping,
        offices,
        status,
        skuTemplate,
        adSkuTemplate,
        skuInstanceTemplate: skuInstanceTemplateQuery.data,
        adSkuInstanceTemplate: adSkuInstanceTemplateQuery.data,
      };
    }
    return null;
  }, [
    categories,
    colorMapping,
    offices,
    skuTemplate,
    status,
    adSkuTemplate,
    skuInstanceTemplateQuery.data,
    adSkuInstanceTemplateQuery.data,
  ]);

  if (!value) {
    if (query.loading) {
      return <LoadingIndicator />;
    }
    return (
      <ErrorView
        error={
          query.error ||
          skuTemplateQuery.error ||
          adSkuTemplateQuery.error ||
          skuInstanceTemplateQuery.error ||
          adSkuInstanceTemplateQuery.error ||
          mappingsQuery.error ||
          officesQuery.error
        }
      />
    );
  }
  return (
    <InventoryContext.Provider value={value}>
      {children}
    </InventoryContext.Provider>
  );
};
