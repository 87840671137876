import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';

import { workstationDesks } from './workstationDesks';
import { meetingRoomTables } from './meetingRoomTables';
import { foldingTables } from './foldingTables';
import { diningTables } from './diningTables';
import { coffeeTables } from './coffeeTables';
import { sideTables } from './sideTables';

export const tables = (t: TFunction, tk: TKeys<'functionality'>) => ({
  workstationDesks: workstationDesks(t, tk),
  meetingRoomTables: meetingRoomTables(t, tk),
  foldingTables: foldingTables(t, tk),
  diningTables: diningTables(t, tk),
  coffeeTables: coffeeTables(t, tk),
  sideTables: sideTables(t, tk),
});
