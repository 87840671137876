import React, { FC, useContext, useEffect } from 'react';

import { ErrorView } from '../../common/components/ErrorView';
import { LoadingIndicator } from '../../common/components/LoadingIndicator';
import { useTranslation } from '../../translations';
import { useNavigation } from '../../common/navigation';

import { useUserContext } from './useUserContext';
import { useAdRedirect } from './useAdRedirect';

export const TenantContext = React.createContext<string>('');

export type TenantProviderProps = {
  tenantId: string;
  onTenantChange: (tenantId: string) => void;
};

export const TenantProvider: FC<TenantProviderProps> = ({
  tenantId,
  onTenantChange,
  children,
}) => {
  const { data } = useUserContext();
  const { t, tk } = useTranslation('inventory');
  const adRedirect = useAdRedirect();
  const nav = useNavigation();

  useEffect(() => {
    if (!tenantId) {
      const tenant = data.tenants[0];
      if (tenant) {
        onTenantChange(tenant.name);
      }
    }
  }, [tenantId, onTenantChange, data.tenants]);

  useEffect(() => {
    if (!tenantId || !data.tenants.find(t => t.name === tenantId)) {
      if (adRedirect) {
        const tenant = data.tenants[0];
        if (tenant) {
          adRedirect(tenant.name).go();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId, data.tenants]);

  if (!tenantId || !data.tenants.find(t => t.name === tenantId)) {
    if (tenantId === 'partner') {
      nav.adminPanel({ tenantId: data.tenants[0].name }).go();
    } else if (
      tenantId !== 'partner' &&
      data.tenants.length === 1 &&
      data.tenants[0].name === 'partner' &&
      data.isPartnerAdmin
    ) {
      nav.adminPanel({ tenantId: data.tenants[0].name }).go();
    }
    if (data) {
      return <ErrorView error={{ message: t(tk.errors.userEmptyTenants) }} />;
    }
    return <LoadingIndicator />;
  }

  return (
    <TenantContext.Provider value={tenantId}>{children}</TenantContext.Provider>
  );
};

export const useCurrentTenantId = () => {
  return useContext(TenantContext);
};
