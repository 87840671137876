import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';

import {
  storageDoorsMechanism,
  storageFeetType,
  storageLockType,
  storageBuiltInLighting,
  storageShelvesType,
} from './attributeFields';

export const getFields = (t: TFunction, tk: TKeys<'functionality'>) => ({
  storageDoorsMechanism: storageDoorsMechanism(t, tk),
  storageFeetType: storageFeetType(t, tk),
  storageLockType: storageLockType(t, tk),
  storageBuiltInLighting: storageBuiltInLighting(t, tk),
  storageShelvesType: storageShelvesType(t, tk),
});

export type StorageFields = ReturnType<typeof getFields>;
