import React, { ChangeEvent, FC } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { GoOptions } from '../../../../../common/navigation';
import { SkuInstanceFieldsFragment } from '../../../../graphql';
import { SkuInstanceItemMainBlock } from '../SkuInstanceItemMainBlock';

import { CallToActionSkuInstancesItem } from './CallToActionSkuInstancesItem';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
      padding: theme.spacing(4, 3, 3),
    },
    mainBlock: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    chip: {
      cursor: 'inherit',
      marginRight: theme.spacing(1),
    },
    divider: {
      backgroundColor: theme.palette.primary.veryLight,
      '&:last-child': {
        display: 'none',
      },
    },
  }),
);

type Props = {
  skuInstance: SkuInstanceFieldsFragment;
  route: { url: string; go(options?: GoOptions): void };
  handleSelectedSkuItems: (
    e: ChangeEvent<HTMLInputElement>,
    chekced: boolean,
  ) => void;
  selectedSkuItems: string[];
};

export const SkuInstanceListItem: FC<Props> = ({
  skuInstance,
  route,
  selectedSkuItems,
  handleSelectedSkuItems,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      className={classes.root}
      data-testid={`SkuInstanceListItem-${skuInstance.id}`}
    >
      <Box className={classes.mainBlock}>
        <SkuInstanceItemMainBlock
          selectedSkuItems={selectedSkuItems}
          handleSelectedSkuItems={handleSelectedSkuItems}
          skuInstance={skuInstance}
        />
        <CallToActionSkuInstancesItem route={route} />
      </Box>
    </ListItem>
  );
};
