import React, { FC, useEffect } from 'react';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { NewAdInstanceStepProps, StepId } from '../types';
import { FormStep as FormStepComponent } from '../../../components/NewAdSkuInstanceView/FormStep';
import {
  FormStepData,
  useNewAdSkuInstanceFormFields,
} from '../../../components/NewAdSkuInstanceView/FormStep/useNewAdSkuInstanceForm';
import { useOffices } from '../../../context/useOffices';
import {
  CreateAdSkuInstancesMutation,
  useCreateAdSkuInstancesMutation,
} from '../../../graphql';
import { extractMutation } from '../../../../common/utils/extractQuery';
import { CreateAdSkuInstancesMutationPayload } from '../../../graphql/types';
import {
  LastRegisteredTypes,
  useLastRegistered,
} from '../../../utils/useLastRegistered';
import { useNavigation } from '../../../../common/navigation';
import { useNewAdSkuInstanceActions } from '../../../redux/newAdSkuInstance';

export const FormStep: FC<StepComponentProps<NewAdInstanceStepProps>> = ({
  extraProps: { adSkuId },
  number,
  totalCount,
  onGoToStep,
}) => {
  const offices = useOffices();
  const nav = useNavigation();
  const { add } = useLastRegistered(LastRegisteredTypes.adSku);
  const { setCount, setInstance } = useNewAdSkuInstanceActions();

  const fields = useNewAdSkuInstanceFormFields(offices);

  const [
    createAdSkuInstances,
    rawMutationResult,
  ] = useCreateAdSkuInstancesMutation({
    refetchQueries: ['AdSkuInstances'],
  });
  const mutationResult = extractMutation<
    CreateAdSkuInstancesMutation,
    CreateAdSkuInstancesMutationPayload
  >(
    rawMutationResult,
    'CreateAdSkuInstancesPayload',
    data => data.createAdSkuInstances,
  );

  useEffect(() => {
    if (mutationResult.data) {
      add(adSkuId);
      onGoToStep(StepId.Summary);
    }
  }, [add, mutationResult.data, onGoToStep, adSkuId]);

  const handleBack = () => {
    nav.adSku({ id: adSkuId }).go();
  };

  const handleSubmit = (data: FormStepData) => {
    const { count, adSkuInstance } = data;
    setCount(count);
    setInstance(adSkuInstance);
    return createAdSkuInstances({
      variables: {
        input: {
          count,
          adSkuInstance: {
            ...adSkuInstance,
            adSkuId,
          },
        },
      },
    });
  };

  return (
    <FormStepComponent
      error={mutationResult.error}
      fields={fields}
      number={number}
      totalCount={totalCount}
      onSubmit={handleSubmit}
      onGoBack={handleBack}
    />
  );
};
