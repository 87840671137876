import { makeStyles, createStyles } from '@material-ui/core/styles';
import React from 'react';

import { Link } from '../../../../../common/components/Link';
import { useNavigation } from '../../../../../common/navigation';

import logo from './good-work.logo.png';

const useStyles = makeStyles(theme =>
  createStyles({
    logo: {
      width: 80,
      [theme.breakpoints.down('sm')]: {
        width: 64,
        margin: 0,
      },
    },
  }),
);

export const UniversalTenantLogo: React.FC = () => {
  const nav = useNavigation();
  const classes = useStyles();

  return (
    <Link route={nav.skus()}>
      <img src={logo} alt="GoGood" className={classes.logo} />
    </Link>
  );
};
