import React from 'react';

import { UpdateTenantInput } from '../../../inventory/graphql';
import { EditTenantView as EditTenantViewComponent } from '../../components/EditTenantView/EditTenantView';
import { updateTenantFormFields } from '../../components/AddTenantView/tenantFormFields';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { ErrorView } from '../../../common/components/ErrorView';
import { useUpdateTenantFormLayout } from '../../forms/useTenantForm';
import { useTranslation } from '../../../translations';
import { useNavigation } from '../../../common/navigation';
import { useUserContext } from '../../../inventory/context/useUserContext';
import { useOffices } from '../../../inventory/context/useOffices';

import { useUpdateTenant } from './useUpdateTenant';

type Props = {
  id: string;
};

// eslint-disable-next-line complexity
export const EditTenantView: React.FC<Props> = ({ id }) => {
  const nav = useNavigation();
  const { t, tk } = useTranslation('admin');
  const offices = useOffices();

  const { data: userData } = useUserContext();

  const tenantData = userData.tenants.find(tenant => tenant.id === id);

  const layout = useUpdateTenantFormLayout();
  const { fetchUserData } = useUserContext();

  const onUpdateTenantCompleted = () => {
    fetchUserData();
    nav.adminPanel().go();
  };

  const { updateTenant, isProcessing, mutationResult } = useUpdateTenant(
    onUpdateTenantCompleted,
  );

  const handleUpdate = (tenant: UpdateTenantInput['tenant']) => {
    updateTenant({
      tenant: {
        name: tenantData?.name || '',
        displayName: tenant?.displayName || '',
        users: [],
      },
      id,
    });
  };

  const handleOfficeEdit = (officeId: string) => {
    return nav.adminPanelEditOffice({ id: tenantData!.name, officeId }).go();
  };

  if (isProcessing) {
    return <LoadingIndicator />;
  }

  if (mutationResult.error) {
    return <ErrorView error={mutationResult.error} />;
  }

  const tenant = mutationResult.data ? mutationResult.data.tenant : tenantData;

  return (
    <>
      <EditTenantViewComponent
        fields={updateTenantFormFields}
        onSubmit={handleUpdate}
        tenant={{
          displayName:
            mutationResult.data?.tenant.displayName ||
            tenantData?.displayName ||
            '',
          name: tenantData?.name || '',
          users: [],
        }}
        layout={layout}
        headingText={t(tk.adminPanel.editTenant, {
          name: tenant?.displayName || tenant?.name,
        })}
        offices={offices || []}
        handleOfficeEdit={handleOfficeEdit}
        onCancel={() => nav.adminPanel().go()}
      />
    </>
  );
};
