import { ThemeOptions } from '@material-ui/core';

export const props: ThemeOptions['props'] = {
  MuiButton: {
    disableElevation: true,
    variant: 'contained',
  },
  MuiTextField: {
    variant: 'outlined',
  },
  MuiTypography: {
    variant: 'body2',
  },
};
