import React, { FC } from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme =>
  createStyles({
    chip: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    chipDefault: {
      backgroundColor: theme.palette.background.default,
    },
    iconDefault: {
      color: theme.palette.primary.light,
    },
    iconPrimary: {
      color: theme.palette.secondary.veryLight,
    },
  }),
);

export type CurrentFilterProps = {
  color?: ChipProps['color'];
  filters: {
    name: string;
    value: string;
    label: string;
    selected: string[];
  }[];
  onFilterChange: (field: string, values: string[]) => void;
};

export const CurrentFilters: FC<CurrentFilterProps> = props => {
  const { color = 'default', filters, onFilterChange } = props;
  const classes = useStyles(props);

  const onDelete = (name: string, value: string, selected: string[]) => () => {
    if (name === 'salesPrice' || name === 'salesPeriod') {
      onFilterChange(name, []);
    } else {
      onFilterChange(
        name,
        selected.filter(v => v !== value),
      );
    }
  };

  return (
    <Box mb={3}>
      {filters.map(({ name, value, label, selected }) => (
        <Chip
          className={cn({
            [classes.chip]: true,
            [classes.chipDefault]: color === 'default',
          })}
          key={`${name}_${value}`}
          label={label}
          onDelete={onDelete(name, value, selected)}
          deleteIcon={
            <CloseIcon
              className={cn({
                [classes.iconDefault]: color === 'default',
                [classes.iconPrimary]: color === 'primary',
              })}
            />
          }
          color={color}
          size="small"
        />
      ))}
    </Box>
  );
};
