import React, { ComponentType, FC, ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useTranslation } from '../../../../translations';
import { FieldConfig } from '../../../../common/components/Form/types';
import { ExpansionCard } from '../../../../common/components/ExpansionCard';

type Props = {
  Row: ComponentType<{}>;
  renderField: (config: FieldConfig) => ReactNode;
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
    },
    summary: {},
    details: {
      flexDirection: 'column',
      paddingRight: theme.spacing(8),
    },
    title: {
      display: 'flex',
      alignItems: 'baseline',
    },
    titleSuffix: {
      paddingLeft: theme.spacing(0.5),
    },
  }),
);

export const ProcurementFields: FC<Props> = ({ Row, renderField }) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();
  return (
    <ExpansionCard
      color="info"
      classes={{
        root: classes.root,
        summary: classes.summary,
        details: classes.details,
      }}
      title={
        <div className={classes.title} data-testid="ProcurementFields">
          <Typography variant="body1">
            <strong>
              {t(tk.newSkuInstanceView.formStep.procurementTitle)}
            </strong>
          </Typography>
          <Typography className={classes.titleSuffix} variant="body2">
            {t(tk.newSkuInstanceView.formStep.procurementTitleSuffix)}
          </Typography>
        </div>
      }
    >
      <Row>
        {renderField({
          name: 'costCenter',
          gridItemProps: { xs: 6 },
          label: t(tk.fieldLabels.costCenter),
        })}
      </Row>
      <Row>
        {renderField({
          name: 'procurementDate',
          gridItemProps: { xs: 6 },
          label: t(tk.fieldLabels.procurementDate),
        })}
        {renderField({
          name: 'procurementNumber',
          gridItemProps: { xs: 6 },
          label: t(tk.fieldLabels.procurementNumber),
        })}
      </Row>
      <Row>
        {renderField({
          name: 'procurementCost',
          gridItemProps: { xs: 6 },
          label: t(tk.fieldLabels.procurementCost),
        })}
      </Row>
    </ExpansionCard>
  );
};
