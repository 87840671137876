import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { InputButton } from '../InputButton';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      borderRadius: 16,
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),
);

export type Item = {
  label: string;
  onClick: () => void;
};

export type DynamicButtonSelectProps = {
  items: Item[];
  allowInput?: boolean;
  onInputButtonSubmit?: (label: string | null) => void;
};

export const DynamicButtonSelect: React.FC<DynamicButtonSelectProps> = ({
  items,
  allowInput = false,
  onInputButtonSubmit,
}) => {
  const classes = useStyles();

  return (
    <>
      {items.map(item => (
        <Button
          key={item.label}
          className={classes.button}
          color="secondary"
          size="small"
          fullWidth
          onClick={item.onClick}
        >
          {item.label}
        </Button>
      ))}
      {onInputButtonSubmit && allowInput && (
        <InputButton options={items} onSubmit={onInputButtonSubmit} />
      )}
    </>
  );
};
