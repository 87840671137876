import i18next from 'i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as H from 'history';
import pickBy from 'lodash/pickBy';

import { useCurrentTenantId } from '../inventory/context/TenantContext';

import { logError } from './utils/logError';

export type GoOptions = { replace?: boolean; state?: H.LocationState };

const actions = (history: H.History, url: string) => ({
  url,
  go(options: GoOptions = {}) {
    if (options && options.replace) {
      history.replace(url, options.state);
    } else {
      history.push(url, options.state);
    }
  },
});

export type Filters = { [key: string]: string[] };
type RangeFilters = { [key: string]: { from: string; to: string } };

export type RouteActions = ReturnType<typeof actions>;

export const routes = {
  createSku: {
    exact: true,
    path: '/:lang/:tenantId/create-sku',
  },
  registrationStep: {
    path: '/:lang/:tenantId/registration/:step',
  },
  registration: {
    exact: true,
    path: '/:lang/:tenantId/registration',
  },
  skuRegistration: {
    exact: true,
    path: '/:lang/:tenantId/skuRegistration',
  },
  skuRegistrationStep: {
    path: '/:lang/:tenantId/skuRegistration/:step',
  },
  adSkuRegistration: {
    exact: true,
    path: '/:lang/:tenantId/adSkuRegistration',
  },
  adSkuRegistrationStep: {
    path: '/:lang/:tenantId/adSkuRegistration/:step',
  },
  scanSkuInstance: {
    exact: true,
    path: '/:lang/:tenantId/instance/:id/scan',
  },
  skus: {
    exact: true,
    path: '/:lang/:tenantId',
  },
  skusFilter: {
    exact: true,
    path: '/:lang/:tenantId/filter/:filters',
  },
  sku: {
    exact: true,
    path: '/:lang/:tenantId/sku/:id',
  },
  adSku: {
    exact: true,
    path: '/:lang/:tenantId/adSku/:id',
  },
  adSkuFilter: {
    exact: true,
    path: '/:lang/:tenantId/adSku/:id/filter/:filters',
  },
  adSkusFilter: {
    exact: true,
    path: '/:lang/:tenantId/adSkus/filter/:filters',
  },
  adSkus: {
    exact: true,
    path: '/:lang/:tenantId/adSkus',
  },
  adSkusAddInstance: {
    exact: true,
    path: '/:lang/:tenantId/adSkus/add-instance',
  },
  adSkusAddInstanceFilter: {
    exact: true,
    path: '/:lang/:tenantId/adSkus/add-instance/filter/:filters',
  },
  newSkuInstanceStep: {
    path: '/:lang/:tenantId/sku/:skuId/new-instance/:step',
  },
  newSkuInstance: {
    exact: true,
    path: '/:lang/:tenantId/sku/:skuId/new-instance',
  },
  newAdSkuInstanceStep: {
    path: '/:lang/:tenantId/adSku/:adSkuId/new-instance/:step',
  },
  newAdSkuInstance: {
    exact: true,
    path: '/:lang/:tenantId/adSku/:adSkuId/new-instance',
  },
  skuFilter: {
    exact: true,
    path: '/:lang/:tenantId/sku/:id/filter/:filters',
  },
  skuInstance: {
    exact: true,
    path: '/:lang/:tenantId/instance/:id',
  },
  adSkuInstance: {
    exact: true,
    path: '/:lang/:tenantId/adInstance/:id',
  },
  adminPanel: {
    exact: true,
    path: '/:lang/:tenantId/admin-panel',
  },
  adminPanelTenant: {
    exact: true,
    path: '/:lang/:tenantId/admin-panel/tenant/:id',
  },
  adminPanelAddTenant: {
    exact: true,
    path: '/:lang/:tenantId/admin-panel/tenant',
  },
  adminPanelOffice: {
    exact: true,
    path: '/:lang/:tenantId/admin-panel/office/tenant/:id',
  },
  adminPanelEditOffice: {
    exact: true,
    path: '/:lang/:tenantId/admin-panel/office/tenant/:id/:officeId',
  },
} as const;

type Params = { id: string };
type EditOfficeParams = Params & { officeId: string };

type SkuParams = Params & {
  filters?: Filters;
};

export type SkusParams = {
  filters?: Filters;
};

type AdSkusParams = {
  filters?: Filters;
};

export type RegistrationParams = {
  stepId?: string;
};

const addFilters = (
  url: string,
  filters?: Filters,
  rangeFilters?: RangeFilters,
) => {
  const cleanedUpFilters = pickBy(filters || {}, v => v && v.length);

  if (Object.keys(cleanedUpFilters).length) {
    const mergedFilters = { ...cleanedUpFilters, ...rangeFilters };
    url = url + `/filter/${btoa(JSON.stringify(mergedFilters))}`;
  }
  return url;
};

const addStep = (url: string, stepId?: string) => {
  let result = url;
  if (stepId) {
    result = result + '/' + stepId;
  }
  return result;
};

const baseUrl = (
  baseConfig: NavigationConfig,
  overrides?: NavigationConfig,
) => {
  const { lang, tenantId } = { ...baseConfig, ...(overrides || {}) };
  return `/${lang}/${tenantId}`;
};

export type NavigationConfig = {
  lang?: string;
  tenantId?: string;
};

export const useNavigation = () => {
  const history = useHistory();
  const currentTenantId = useCurrentTenantId();

  const baseConfig: NavigationConfig = {
    lang: i18next.language,
    tenantId: currentTenantId,
  };

  return {
    /**
     * @deprecated Please use `registration`
     */
    createSku: (config?: NavigationConfig) =>
      actions(history, `${baseUrl(baseConfig, config)}/create-sku`),
    registration: (
      { stepId }: RegistrationParams = {},
      config?: NavigationConfig,
    ) =>
      actions(
        history,
        addStep(`${baseUrl(baseConfig, config)}/registration`, stepId),
      ),
    skuRegistration: (
      { stepId }: RegistrationParams = {},
      config?: NavigationConfig,
    ) =>
      actions(
        history,
        addStep(`${baseUrl(baseConfig, config)}/skuRegistration`, stepId),
      ),
    adSkuRegistration: (
      { stepId }: RegistrationParams = {},
      config?: NavigationConfig,
    ) =>
      actions(
        history,
        addStep(`${baseUrl(baseConfig, config)}/adSkuRegistration`, stepId),
      ),
    newAdSkuInstance: (
      { adSkuId, stepId }: { adSkuId: string } & RegistrationParams,
      config?: NavigationConfig,
    ) =>
      actions(
        history,
        addStep(
          `${baseUrl(baseConfig, config)}/adSku/${adSkuId}/new-instance`,
          stepId,
        ),
      ),
    skus: ({ filters }: SkusParams = {}, config?: NavigationConfig) =>
      actions(history, addFilters(baseUrl(baseConfig, config), filters)),
    sku: ({ id, filters }: SkuParams, config?: NavigationConfig) =>
      actions(
        history,
        addFilters(`${baseUrl(baseConfig, config)}/sku/${id}`, filters),
      ),
    adSku: ({ id, filters }: SkuParams, config?: NavigationConfig) =>
      actions(
        history,
        addFilters(`${baseUrl(baseConfig, config)}/adSku/${id}`, filters),
      ),
    adSkuEncodedFilters: (
      { id, filters }: Params & { filters?: string },
      config?: NavigationConfig,
    ) =>
      actions(
        history,
        `${baseUrl(baseConfig, config)}/adSku/${id}/filter/${filters || ''}`,
      ),
    adSkus: ({ filters }: AdSkusParams = {}, config?: NavigationConfig) =>
      actions(
        history,
        addFilters(`${baseUrl(baseConfig, config)}/adSkus`, filters),
      ),
    adSkusAddInstance: (
      { filters }: AdSkusParams = {},
      config?: NavigationConfig,
    ) =>
      actions(
        history,
        addFilters(
          `${baseUrl(baseConfig, config)}/adSkus/add-instance`,
          filters,
        ),
      ),
    adSkusEncodedFilters: (filters = '', config?: NavigationConfig) =>
      actions(
        history,
        `${baseUrl(baseConfig, config)}/adSkus/filter/${filters}`,
      ),
    newSkuInstance: (
      { skuId, stepId }: { skuId: string } & RegistrationParams,
      config?: NavigationConfig,
    ) =>
      actions(
        history,
        addStep(
          `${baseUrl(baseConfig, config)}/sku/${skuId}/new-instance`,
          stepId,
        ),
      ),
    skuInstance: ({ id }: Params, config?: NavigationConfig) =>
      actions(history, `${baseUrl(baseConfig, config)}/instance/${id}`),
    adSkuInstance: ({ id }: Params, config?: NavigationConfig) =>
      actions(history, `${baseUrl(baseConfig, config)}/adInstance/${id}`),
    scanSkuInstance: ({ id }: Params, config?: NavigationConfig) =>
      actions(history, `${baseUrl(baseConfig, config)}/instance/${id}/scan`),
    adminPanel: (config?: NavigationConfig) =>
      actions(history, `${baseUrl(baseConfig, config)}/admin-panel`),
    adminPanelTenant: ({ id }: Params, config?: NavigationConfig) =>
      actions(
        history,
        `${baseUrl(baseConfig, config)}/admin-panel/tenant/${id}`,
      ),
    adminPanelAddTenant: (config?: NavigationConfig) =>
      actions(history, `${baseUrl(baseConfig, config)}/admin-panel/tenant`),
    adminPanelAddOffice: ({ id }: Params, config?: NavigationConfig) =>
      actions(
        history,
        `${baseUrl(baseConfig, config)}/admin-panel/office/tenant/${id}`,
      ),
    adminPanelEditOffice: (
      { id, officeId }: EditOfficeParams,
      config?: NavigationConfig,
    ) =>
      actions(
        history,
        `${baseUrl(
          baseConfig,
          config,
        )}/admin-panel/office/tenant/${id}/${officeId}`,
      ),
  };
};

export type Navigation = ReturnType<typeof useNavigation>;

export const useSelectedFilters = () => {
  const { filters } = useParams<{ filters: string }>();
  try {
    return filters ? JSON.parse(atob(filters)) : [];
  } catch (e) {
    logError(e);
    return [];
  }
};
