import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';

import {
  chairAdjustableHeight,
  chairBack,
  chairBackLength,
  chairBackFixed,
  chairBackAdjustableHeight,
  chairBackAdjustableAngle,
  chairArmrests,
  chairArmrestsFixed,
  chairArmrestsAdjustablePosition,
  chairArmrestsAdjustableHeight,
  chairArmrestsAdjustableWidth,
  chairArmrestsAdjustableLength,
  chairFeetCount,
  chairFeetType,
  chairNeckrest,
  chairFootring,
  chairStackable,
  chairFoldable,
  chairLeaningBack,
  chairSeats,
  chairSeat,
  chairSeatFixed,
  chairSeatAdjustablePosition,
  chairSeatAdjustableAngle,
} from './fields';

export const getFields = (t: TFunction, tk: TKeys<'functionality'>) => ({
  chairAdjustableHeight: chairAdjustableHeight(t, tk),
  chairBack: chairBack(t, tk),
  chairBackLength: chairBackLength(t, tk),
  chairBackFixed: chairBackFixed(t, tk),
  chairBackAdjustableHeight: chairBackAdjustableHeight(t, tk),
  chairBackAdjustableAngle: chairBackAdjustableAngle(t, tk),
  chairArmrests: chairArmrests(t, tk),
  chairArmrestsFixed: chairArmrestsFixed(t, tk),
  chairArmrestsAdjustablePosition: chairArmrestsAdjustablePosition(t, tk),
  chairArmrestsAdjustableHeight: chairArmrestsAdjustableHeight(t, tk),
  chairArmrestsAdjustableWidth: chairArmrestsAdjustableWidth(t, tk),
  chairArmrestsAdjustableLength: chairArmrestsAdjustableLength(t, tk),
  chairFeetCount: chairFeetCount(t, tk),
  chairFeetType: chairFeetType(t, tk),
  chairNeckrest: chairNeckrest(t, tk),
  chairFootring: chairFootring(t, tk),
  chairStackable: chairStackable(t, tk),
  chairFoldable: chairFoldable(t, tk),
  chairLeaningBack: chairLeaningBack(t, tk),
  chairSeats: chairSeats(t, tk),
  chairSeat: chairSeat(t, tk),
  chairSeatFixed: chairSeatFixed(t, tk),
  chairSeatAdjustablePosition: chairSeatAdjustablePosition(t, tk),
  chairSeatAdjustableAngle: chairSeatAdjustableAngle(t, tk),
});

export type ChairFields = ReturnType<typeof getFields>;
