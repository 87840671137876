import { State as LocalState } from './reducer';

export type State = {
  inventory: {
    newAdSkuInstance: LocalState;
  };
};

export const root = (state: State) => state.inventory.newAdSkuInstance;

export const count = (state: State) => root(state).count;

export const instance = (state: State) => root(state).instance;
