type SubcategoryDimensionFieldConfiguration = {
  showGroupLabel?: boolean;
  hidden: boolean;
};

export const dimensionsConfiguration: Record<
  string,
  Record<string, SubcategoryDimensionFieldConfiguration>
> = {
  armchairs: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  officeChairs: {
    minHeight: {
      hidden: false,
    },
    maxHeight: {
      hidden: false,
    },
    minSeatHeight: {
      hidden: false,
    },
    maxSeatHeight: {
      hidden: false,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      hidden: false,
    },
    maxDepth: {
      hidden: false,
    },
  },
  benches: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  conferenceChairs: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  foldingChairs: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  poufs: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  stools: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: false,
    },
    maxSeatHeight: {
      hidden: false,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  standard: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  chaise: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  sectional: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  cabinet: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  fileCabinet: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  shelves: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  storageBox: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  workstationDesks: {
    minHeight: {
      hidden: false,
    },
    maxHeight: {
      hidden: false,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  meetingRoomTables: {
    minHeight: {
      hidden: false,
    },
    maxHeight: {
      hidden: false,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  foldingTables: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  diningTables: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      hidden: false,
    },
    maxWidth: {
      hidden: false,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  coffeeTables: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  sideTables: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  whiteboards: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
  various: {
    minHeight: {
      showGroupLabel: true,
      hidden: false,
    },
    maxHeight: {
      hidden: true,
    },
    minSeatHeight: {
      hidden: true,
    },
    maxSeatHeight: {
      hidden: true,
    },
    minWidth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxWidth: {
      hidden: true,
    },
    minLength: {
      hidden: true,
    },
    maxLength: {
      hidden: true,
    },
    minDepth: {
      showGroupLabel: true,
      hidden: false,
    },
    maxDepth: {
      hidden: true,
    },
  },
};
