import React, { FC, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import QRCode from 'qrcode.react';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container';

import { SkuInstanceInput } from '../../graphql';
import { Form } from '../../../common/components/Form';
import { useTranslation } from '../../../translations';
import { FormFeedback } from '../../../common/components/FormFeedback';
import { SkuInstancePayload } from '../../graphql/types';
import { GraphQlError } from '../../../common/types';
import { useSkuInstanceFormLayout } from '../../forms/useSkuInstanceForm';
import { ResolvedSku } from '../../types';
import { FormFields } from '../../../common/components/Form/types';
import { SkuCard } from '../common/SkuCard/SkuCard';
import { BreadcrumbItem } from '../../../common/components/Breadcrumbs';

import { SkuInstanceTopMenu } from './SkuInstanceTopMenu';
import { getInitialValues } from './getInitialValues';
import { DeleteSkuInstanceDialogProps } from './DeleteSkuInstanceDialog';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: '45% 55%',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '100%',
        paddingBottom: theme.spacing(3),
        maxWidth: theme.breakpoints.values.md,
      },
    },
    cardSection: {
      width: '100%',
      display: 'flex',
      height: 'min-content',
      paddingRight: theme.spacing(3),
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        paddingBottom: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    formSection: {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(12),
      },
    },
    menuSection: {
      width: '100%',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.only('xs')]: {
        marginBottom: theme.spacing(3),
      },
    },
  }),
);

type Props = {
  error?: GraphQlError;
  sku: ResolvedSku;
  skuInstance: SkuInstancePayload;
  fields: FormFields;
  breadcrumbs: BreadcrumbItem[];
  onUpdate: (data: Omit<SkuInstanceInput, 'skuId'>) => void;
  onViewSku: () => void;
  updateCompleted: boolean;
  deleteMutation: Pick<
    DeleteSkuInstanceDialogProps,
    'onDelete' | 'mutationResult'
  >;
};

export const SkuInstanceView: FC<Props> = ({
  error,
  fields,
  sku,
  skuInstance,
  breadcrumbs,
  deleteMutation,
  onUpdate,
  onViewSku,
  updateCompleted,
}) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');
  const layout = useSkuInstanceFormLayout(
    skuInstance.qrCode && <QRComponent value={skuInstance.qrCode} />,
  );
  const initialValues = useMemo(() => getInitialValues(skuInstance), [
    skuInstance,
  ]);

  if (skuInstance.sku.__typename !== 'Sku') {
    return null;
  }

  return (
    <Container className={classes.root}>
      <section className={classes.menuSection}>
        <SkuInstanceTopMenu
          breadcrumbs={breadcrumbs}
          deleteMutation={deleteMutation}
        />
      </section>
      <section />
      <section className={classes.cardSection}>
        <SkuCard
          onClick={onViewSku}
          sku={sku}
          title={t(tk.skuInstanceView.instanceCard.example, {
            skuInstanceId: skuInstance.id,
          })}
          btnLabel={t(tk.skuInstanceView.instanceCard.button)}
        />
      </section>
      <section data-testid="SkuInstanceView" className={classes.formSection}>
        <FormFeedback
          error={error}
          success={updateCompleted}
          sucessMessage={t(tk.skuInstanceView.updateSuccess)}
        />
        <Form
          fields={fields}
          layout={layout}
          defaultValues={initialValues}
          onSubmit={onUpdate}
        />
      </section>
    </Container>
  );
};

const useQRStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      padding: theme.spacing(1),
    },
    qr: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      padding: theme.spacing(1),
    },
    label: {
      padding: theme.spacing(1, 2),
    },
  }),
);

const QRComponent: React.FC<{ value: string }> = ({ value }) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useQRStyles();
  return (
    <div className={classes.root}>
      <InputLabel className={classes.label}>
        {t(tk.skuInstanceView.qrCode)}
      </InputLabel>
      <QRCode className={classes.qr} size={130} value={value} />
    </div>
  );
};
