import { useCallback } from 'react';

import { useNavigation } from '../../../common/navigation';
import {
  useDeleteAdSkuInstanceMutation,
  DeleteAdSkuInstanceMutation,
} from '../../graphql';
import { extractMutation } from '../../../common/utils/extractQuery';
import { DeleteAdSkuInstanceMutationPayload } from '../../graphql/types';

export const useDeleteAdSkuInstance = (id: string, adSkuId?: string) => {
  const nav = useNavigation();
  const [
    deleteAdSkuInstance,
    rawDeleteMutationResult,
  ] = useDeleteAdSkuInstanceMutation({
    onCompleted: data => {
      if (
        data.deleteAdSkuInstance?.__typename !== 'DeleteAdSkuInstancePayload'
      ) {
        return;
      }
      if (adSkuId) {
        nav.adSku({ id: adSkuId }).go();
      } else {
        nav.adSkus().go();
      }
    },
  });

  const mutationResult = extractMutation<
    DeleteAdSkuInstanceMutation,
    DeleteAdSkuInstanceMutationPayload
  >(
    rawDeleteMutationResult,
    'DeleteAdSkuInstancePayload',
    data => data.deleteAdSkuInstance,
  );

  const onDelete = useCallback(() => {
    deleteAdSkuInstance({
      variables: {
        input: {
          id,
        },
      },
    });
  }, [deleteAdSkuInstance, id]);

  return {
    onDelete,
    mutationResult,
  };
};
