import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import {
  FunctionalityValue,
  ResolvedFunctionalitySubcategoryConfig,
} from '../../../models';
import { SkuFunctionalityInput } from '../../common/SkuFunctionalityInput/SkuFunctionalityInput';
import { useFunctionalityState } from '../../common/SkuFunctionalityInput/useFunctionalityState';

type Props = {
  initialValues?: Record<string, FunctionalityValue>;
  config: ResolvedFunctionalitySubcategoryConfig;
  backLabel: string;
  number: number;
  totalCount: number;
  onSubmit: (data: Record<string, FunctionalityValue>) => void;
  onGoBack?: () => void;
};

export const FunctionalityStep: FC<Props> = ({
  backLabel,
  config,
  initialValues = {},
  number,
  totalCount,
  onSubmit,
  onGoBack,
}) => {
  const { values, setValues, isReady } = useFunctionalityState(
    config,
    initialValues,
  );
  const { t, tk } = useTranslation('inventory');

  const handleSubmit = () => {
    onSubmit(values);
  };

  return (
    <WizardStep
      backLabel={backLabel}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      title={t(tk.newSkuView.functionalityStep.title)}
      data-testid="FunctionalityStep"
    >
      <SkuFunctionalityInput
        config={config}
        values={values}
        initialValues={initialValues}
        onChange={setValues}
      />
      <Box mt={8}>
        <Button
          disabled={!isReady}
          onClick={handleSubmit}
          fullWidth
          size="large"
          color="secondary"
        >
          {t(tk.newSkuView.functionalityStep.confirm)}
        </Button>
      </Box>
    </WizardStep>
  );
};
