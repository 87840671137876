/* eslint-disable @typescript-eslint/camelcase */
export default {
  adminPanel: {
    editPartner: 'Edit partner',
    partner: 'Partner',
    selectPartner: 'Select partner',
    addTenant: 'Add tenant',
    addOfficeFloor: 'Add next floor',
    removeOfficeFloor: '-',
    addOffice: 'Add office',
    edit: 'Edit',
    addOfficeForTenant: 'Add office to {{tenant}} tenant',
    editTenant: 'Edit {{name}} tenant',
    cancel: 'Cancel',
    editOffice: 'Edit office',
    offices: 'Offices:',
  },
};
