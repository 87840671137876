import pick from 'lodash/pick';

import { SkuInstanceInput } from '../../graphql';
import { SkuInstancePayload } from '../../graphql/types';

export const getInitialValues = (
  skuInstance: SkuInstancePayload,
): Omit<SkuInstanceInput, 'skuId'> => {
  let officeId;
  let officeFloorId;
  if (skuInstance.office?.__typename === 'Office') {
    officeId = skuInstance.office.id;
  }
  if (skuInstance.officeFloor?.__typename === 'OfficeFloor') {
    officeFloorId = skuInstance.officeFloor.id;
  }
  return {
    ...(pick(skuInstance, ['condition', 'note']) as Omit<
      SkuInstancePayload,
      'id' | 'office' | 'officeFloor' | 'sku'
    >),
    officeId,
    officeFloorId,
  };
};
