import React, { FC, useMemo, useState } from 'react';

import { ErrorView } from '../../../common/components/ErrorView';
import { useNavigation, useSelectedFilters } from '../../../common/navigation';
import { useCategories } from '../../context/useCategories';
import { useTranslation } from '../../../translations';
import { getPageCount } from '../../utils/getPageCount';
import { adSkuTemplateName } from '../../consts';
import { SelectedFilters } from '../../utils/useFilters';
import {
  filterSingleValueQueryFilters,
  useResolvedAdSkusWithInstancesQuery,
} from '../../utils/adSku';
import { AdSkuList } from '../../components/SkuListView/AdSkuList';
import { getHeaderData, SkuType } from '../SkuListView/utils';
import { SkuListView } from '../../components/SkuListView';

import { useAdSkusAddInstanceFilters } from './useAdSkusAddInstanceFilters';

const PAGE_LIMIT = 16;

export const AdSkusAddInstanceView: FC = () => {
  const nav = useNavigation();
  const selectedFilters = useSelectedFilters();

  const setSelectedFilters = (newFilters: SelectedFilters) => {
    nav.adSkusAddInstance({ filters: newFilters }).go();
  };

  const { filters, queryFilters } = useAdSkusAddInstanceFilters(
    selectedFilters,
  );

  const [page, setPage] = useState(1);
  const categories = useCategories();
  const { t, tk } = useTranslation('inventory');
  const { breadcrumbs, title } = useMemo(
    () =>
      getHeaderData(
        queryFilters,
        categories,
        nav,
        t(tk.skuListView.title),
        SkuType.adSkus,
      ),
    [categories, queryFilters, t, nav, tk.skuListView.title],
  );

  const singleValueFilters = queryFilters.filter(filterSingleValueQueryFilters);

  const result = useResolvedAdSkusWithInstancesQuery({
    variables: {
      filters: singleValueFilters,
      slice: {
        offset: (page - 1) * PAGE_LIMIT,
        limit: PAGE_LIMIT,
      },
    },
  });

  const adSkus = result.data?.adSkus || [];

  if (result.error) {
    return <ErrorView error={result.error} />;
  }

  const handleFilterChange = (field: string, values: string[]) => {
    setPage(1);
    if (field === 'subcategoryId') {
      setSelectedFilters({
        [field]: values,
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        [field]: values,
      });
    }
  };

  const pageCount = getPageCount(
    result.data?.metadata.matches,
    adSkuTemplateName,
    PAGE_LIMIT,
  );

  const onAddInstanceClick = (id: string) =>
    nav.newAdSkuInstance({ adSkuId: id }).go();

  return (
    <SkuListView
      breadcrumbs={breadcrumbs}
      filters={filters}
      loading={result.loading}
      onFilterChange={handleFilterChange}
      onPageChange={setPage}
      page={page}
      pageCount={pageCount}
      matches={result.data?.metadata.matches}
      title={title}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      skuList={
        <AdSkuList
          adSkus={adSkus}
          nav={nav}
          onAddInstanceClick={onAddInstanceClick}
          isAddInstanceView={true}
          createRoute={id => nav.newAdSkuInstance({ adSkuId: id })}
        />
      }
    />
  );
};
