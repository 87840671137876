import { ButtonClassKey } from '@material-ui/core/Button';
import { StyleRules } from '@material-ui/core/styles';

import { DsBlack } from '../colors';

export const MuiButton: Partial<StyleRules<ButtonClassKey>> = {
  outlined: {
    border: `2px solid #252525`,
  },
  outlinedSizeSmall: {
    border: `1px solid #252525`,
  },
  outlinedSecondary: {
    color: DsBlack.main,
  },
};
