import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import pick from 'lodash/pick';

import { StepComponentProps } from '../../../../../common/components/Wizard/types';
import { useNewSkuActions } from '../../../../redux/newSku';
import { ModelStep as ModelStepComponent } from '../../../../components/NewSkuView/ModelStep';
import * as selectors from '../../../../redux/newSku/selectors';
import { StepId } from '../../types';
import { SkuInput } from '../../../../graphql';
import { FormFields } from '../../../../../common/components/Form/types';
import { useSkuFormFields } from '../../../../forms';

export const fieldNames = ['manufacturer', 'model', 'serialNumber'] as const;

const isNewManufacturer = (brand: string, fields: FormFields) => {
  const field = fields.manufacturer;
  if (!field || field.type !== 'SelectFieldDefinition') {
    return true;
  }
  return (
    typeof field.options === 'function' ||
    !field.options.find(o => o.value === brand)
  );
};

export const ModelStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onGoBack,
  onGoToStep,
  onStepCompleted,
}) => {
  const { setModelData } = useNewSkuActions();
  const subcategoryName = useSelector(selectors.subcategoryName);
  const skuInput = useSelector(selectors.skuInput);

  const fields = useSkuFormFields(fieldNames);

  const handleSubmit = (
    data: Pick<SkuInput, 'manufacturer' | 'model' | 'serialNumber'>,
  ) => {
    const isNew = isNewManufacturer(data.manufacturer, fields);
    setModelData(data.manufacturer, data.model, isNew, data.serialNumber);
    onStepCompleted();
  };

  const initialValues = pick(skuInput, fieldNames);

  useEffect(() => {
    if (!subcategoryName) {
      onGoToStep(StepId.Subcategory);
    }
  }, [onGoToStep, subcategoryName]);

  if (!subcategoryName) {
    return null;
  }
  return (
    <ModelStepComponent
      backLabel={subcategoryName}
      fields={fields}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      withSerialNumber
    />
  );
};
