import React from 'react';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { DsPurple } from '../../../../theme/colors';
import { AdSkuInstanceFieldsFragment } from '../../../graphql';

const useStyles = makeStyles(theme =>
  createStyles({
    dateChip: {
      marginTop: theme.spacing(1),
      borderRadius: '10px',
      backgroundColor: DsPurple.ultraLight,
    },
  }),
);

type Range = {
  from?: number;
  to?: number;
};

type Props = {
  instances: Array<AdSkuInstanceFieldsFragment>;
};

const calculateFrom = (previousValue?: number, currentValue?: number) =>
  previousValue && currentValue
    ? currentValue < previousValue && currentValue !== 0
      ? currentValue
      : previousValue
    : previousValue || currentValue;

const calculateTo = (previousValue?: number, currentValue?: number) =>
  previousValue && currentValue
    ? currentValue > previousValue
      ? currentValue
      : previousValue
    : previousValue || currentValue;

export const SalesPeriod: React.FC<Props> = ({ instances }) => {
  const classes = useStyles();

  const { from, to } = instances.reduce<Range>(({ from, to }, instance) => {
    const { salesPeriodTimestampFrom, salesPeriodTimestampTo } = instance;
    const parsedSalesPeriodDateFrom = salesPeriodTimestampFrom
      ? parseFloat(salesPeriodTimestampFrom)
      : undefined;

    const parsedSalesPeriodDateTo = salesPeriodTimestampTo
      ? parseFloat(salesPeriodTimestampTo)
      : undefined;

    return {
      from: calculateFrom(from, parsedSalesPeriodDateFrom),
      to: calculateTo(to, parsedSalesPeriodDateTo),
    };
  }, {});

  const toDate = (value: number) => new Date(value * 1000).toLocaleDateString();

  return (
    <div>
      {from && to && (
        <Chip
          className={classes.dateChip}
          size="small"
          label={
            <Typography variant="caption">
              {' '}
              <DateRangeIcon fontSize="inherit" />
              {` ${toDate(from)} - ${toDate(to)}`}
            </Typography>
          }
        />
      )}
    </div>
  );
};
