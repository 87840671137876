import React, { FC, SetStateAction, useCallback, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ListAlt from '@material-ui/icons/ListAlt';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { ApolloQueryResult } from '@apollo/client';
import { Row, Workbook } from 'exceljs';
import FileSaver from 'file-saver';
import { Tooltip } from '@material-ui/core';

import { Sku, SkuInstance, SkuInstancesDocument } from '../../graphql';
import { useApolloClient } from '../../../app/setup/useApolloClient';
import { ResolvedSkuWithMetadata } from '../../types';
import { useTranslation } from '../../../translations';
import { useCurrentTenantId } from '../../context/TenantContext';
import { BackdropLoading } from '../../../common/components/BackdropLoading';

import {
  createSpreadsheetGenerator,
  splitSkusArrHelper,
} from './SpreadsheetGenerator';

interface Props {
  pageSkus: ResolvedSkuWithMetadata[];
  allSkus: ResolvedSkuWithMetadata[];
  page: number;
  setError: React.Dispatch<SetStateAction<Error | undefined>>;
  queryFilters: {
    field: string;
    values: string[];
    includeRelated: boolean;
  }[];
}

export const SpreadsheetGeneratorView: FC<Props> = ({
  pageSkus,
  allSkus,
  queryFilters,
  page,
  setError,
}) => {
  const [loading, setLoading] = useState(false);
  const client = useApolloClient(useCurrentTenantId());

  const { t, tk } = useTranslation('inventory');

  const skuInstanceFilters = queryFilters
    .filter(f => f.includeRelated)
    .map(f => ({
      ...f,
      includeRelated: false,
    }));

  const getSkuInstances = useCallback(
    (
      skuIds: string[],
    ): Promise<
      ApolloQueryResult<{
        skuInstances: {
          metadata: Record<string, unknown>;
          skuInstances: SkuInstance[];
        };
      }>
    > => {
      const baseOptions = {
        query: SkuInstancesDocument,
        variables: {
          filters: [
            ...skuInstanceFilters,
            { field: 'skuId', values: skuIds, includeRelated: false },
          ],
          slice: {
            offset: 0,
            limit: 0,
          },
        },
      };

      return client.query(baseOptions);
    },
    [client, skuInstanceFilters],
  );

  const handleSheetGenerate = useCallback(
    async (
      skus: ResolvedSkuWithMetadata[],
      skusIdsArr: string[],
    ): Promise<void> => {
      const workbook = new Workbook();
      setLoading(true);

      const splitSkusArr = splitSkusArrHelper(skusIdsArr, skus);

      const skusData = skus.map(({ sku }) => sku);

      const arrRespnse: SkuInstance[][] = [];

      try {
        for (let i = 0; i < splitSkusArr.length; i++) {
          const data = await getSkuInstances(splitSkusArr[i]);

          arrRespnse.push(data?.data?.skuInstances.skuInstances);
          continue;
        }

        const skuInstances = arrRespnse.flat().sort((a, b) => {
          if (a.sku.__typename === 'Sku' && b.sku.__typename === 'Sku') {
            return a.sku.id.localeCompare(b.sku.id);
          }
          return 1;
        });

        const worksheet = workbook.addWorksheet('Skus');
        const spreadSheetGenerator = createSpreadsheetGenerator(
          workbook,
          worksheet,
        );

        spreadSheetGenerator.addSkuInstanceLabelsRow(t, tk);

        await Promise.all(
          skuInstances.map(async (skuInstance, index) => {
            const rows: Row[] = [];

            const skuInstancesId =
              skuInstance.sku.__typename === 'Sku' ? skuInstance.sku.id : '';

            const findSkusById = skusData.find(
              elm => elm.id === skuInstancesId,
            );

            if (!findSkusById) {
              return;
            }

            rows.push(
              spreadSheetGenerator.addSkuInstanceRow(findSkusById, skuInstance),
            );

            if (
              !index ||
              (skuInstances[index].sku.__typename === 'Sku' &&
                skuInstances[index - 1]?.sku.__typename === 'Sku' &&
                (skuInstances[index].sku as Sku).id !==
                  (skuInstances[index - 1].sku as Sku).id)
            ) {
              await spreadSheetGenerator.addImage(findSkusById, rows);
            }
          }),
        );
        workbook.xlsx.writeBuffer().then(buffer => {
          const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          const file = new Blob([buffer], { type: fileType });
          FileSaver.saveAs(file, 'export');
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err as Error);
      }
    },
    [getSkuInstances, setError, t, tk],
  );

  return (
    <>
      <BackdropLoading open={loading} />
      <Tooltip
        title={t(tk.skuListView.skuGenerateSpreedSheetForPageTooltip) + page}
      >
        <IconButton
          disabled={loading || pageSkus.length === 0}
          onClick={() =>
            handleSheetGenerate(
              pageSkus,
              pageSkus.map(({ sku }) => sku.id),
            )
          }
        >
          <ListAlt fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip
        title={t(tk.skuListView.skuGenerateSpreedSheetForAllTooltip) as string}
      >
        <IconButton
          disabled={loading || allSkus.length === 0}
          onClick={() =>
            handleSheetGenerate(
              allSkus,
              allSkus.map(({ sku }) => sku.id),
            )
          }
        >
          <FileCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};
