import Typography from '@material-ui/core/Typography';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Variant } from '@material-ui/core/styles/createTypography';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
);

type Props = {
  text: string;
  variant: Variant;
};

export const FormHeading: React.FC<Props> = ({ text, variant }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} variant={variant}>
      {text}
    </Typography>
  );
};
