import { useMemo } from 'react';

import { useNavigation } from '../../common/navigation';
import { Maybe, AdSkuInstanceQuery, useAdSkuInstanceQuery } from '../graphql';
import { extractQuery } from '../../common/utils/extractQuery';
import { AdSkuInstancePayload } from '../graphql/types';
import { useCategories } from '../context/useCategories';
import { useTranslation } from '../../translations';
import { getBreadcrumbs } from '../containers/SkuView/breadcrumbs';
import { ResolvedAdSku } from '../types';

import { useResolvedAdSku } from './adSku';

export const useResolvedAdSkuInstance = (id: string) => {
  const adSkuInstanceQuery = extractQuery<
    AdSkuInstanceQuery,
    AdSkuInstancePayload
  >(
    useAdSkuInstanceQuery({
      variables: { id },
    }),
    'AdSkuInstance',
    data => data.adSkuInstance,
  );

  const checkAdSku =
    adSkuInstanceQuery.data?.adSku.__typename === 'AdSku'
      ? adSkuInstanceQuery.data.adSku
      : null;
  const adSku = useResolvedAdSku(checkAdSku);

  const adSkuInstance = adSkuInstanceQuery.data;

  const error =
    adSkuInstanceQuery.error ||
    (adSkuInstance?.adSku.__typename !== 'AdSku' ? adSkuInstance?.adSku : null);

  return {
    adSkuInstance,
    adSku,
    loading: adSkuInstanceQuery.loading,
    error,
  };
};

export const useAdSkuInstanceBreadcrumbs = (
  adSku: Maybe<ResolvedAdSku>,
  id: string,
) => {
  const nav = useNavigation();
  const categories = useCategories();
  const { t, tk } = useTranslation('inventory');

  return useMemo(() => {
    if (!adSku) {
      return [];
    }

    let breadcrumbs = getBreadcrumbs(
      categories,
      t(tk.skuListView.title),
      adSku,
      nav.adSkus,
    );
    if (breadcrumbs.length > 0) {
      breadcrumbs = breadcrumbs.slice(0, -1);
    }
    if (adSku) {
      breadcrumbs.push({
        label: `${adSku.manufacturer} ${adSku.model}`,
        route: nav.adSku({ id: adSku.id }),
      });
    }
    breadcrumbs.push({
      label: t(tk.skuInstanceView.topMenu.currentItem, {
        id: id.substring(0, 8),
      }),
    });
    return breadcrumbs;
  }, [
    nav,
    categories,
    t,
    tk.skuListView.title,
    tk.skuInstanceView.topMenu.currentItem,
    adSku,
    id,
  ]);
};
