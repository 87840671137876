import { setContext } from '@apollo/link-context';

export const createAuthLink = (getTokenSilently: () => Promise<string>) =>
  setContext(async (_, { headers }) => {
    const token = await getTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
