/* eslint-disable @typescript-eslint/camelcase */
export default {
  head: {
    title: 'Good Work - kontorinnredning',
  },
  newAdSkuInstanceView: {
    formStep: {
      title: 'Configure Ad Details',
      subtitle: 'Please confirm the quantity, price and sales period.',
      confirm: 'Confirm',
      location: 'Location',
      optional: '[Optional]',
      price: 'Price per instance',
      salePeriod: 'Sale period',
      from: 'From',
      to: 'To',
      status: 'Status {{count}} Instances',
      condition: 'Condition {{watchCount}} Instances',
    },
  },
} as const;
