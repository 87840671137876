import React, { ChangeEvent, Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { SkuInstancesPayload } from '../../../../graphql/types';
import { Navigation } from '../../../../../common/navigation';
import { SkuInstanceListItem } from '../SkuInstanceListItemVariant/SkuInstanceListItem';

const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      margin: theme.spacing(0, 3),
      backgroundColor: theme.palette.background.default,
      border: 'none',
      borderTop: `2px dashed ${theme.palette.primary.veryLight}`,
      color: theme.palette.background.default,
      height: 1,
      '&:last-child': {
        display: 'none',
      },
    },
  }),
);

type Props = {
  skuInstances: SkuInstancesPayload['skuInstances'];
  nav: Navigation;
  handleSelectedSkuItems: (
    e: ChangeEvent<HTMLInputElement>,
    chekced: boolean,
  ) => void;
  selectedSkuItems: string[];
};

export const SkuItems: React.FC<Props> = ({
  skuInstances,
  nav,
  handleSelectedSkuItems,
  selectedSkuItems,
}) => {
  const classes = useStyles();

  return (
    <>
      {skuInstances.map(instance => {
        const route = nav.skuInstance({ id: instance.id });

        return (
          <Fragment key={instance.id}>
            <SkuInstanceListItem
              selectedSkuItems={selectedSkuItems}
              handleSelectedSkuItems={handleSelectedSkuItems}
              skuInstance={instance}
              route={route}
            />
            <Divider
              key={`${instance.id}-divider`}
              className={classes.divider}
            />
          </Fragment>
        );
      })}
    </>
  );
};
