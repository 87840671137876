import React, { FC, useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { useNavigation } from '../../../../common/navigation';
import { SearchStep as SearchStepComponent } from '../../../components/NewSkuView/SearchStep';
import { Filter, Maybe, SkuInput } from '../../../graphql';
import { useNewAdSkuActions } from '../../../redux/newAdSku';
import * as selectors from '../../../redux/newAdSku/selectors';
import { ResolvedAdSku } from '../../../types';
import { useResolvedAdSkusQuery } from '../../../utils/adSku';
import { StepId } from '../types';

export type RedirectTarget = 'finish' | 'register';

const getSearchParams = ({
  manufacturer,
  model,
  subcategoryId,
}: Partial<Pick<SkuInput, 'manufacturer' | 'model' | 'subcategoryId'>>): [
  Maybe<Array<Filter>>,
  StepId | undefined,
] => {
  if (!subcategoryId) {
    return [undefined, StepId.Subcategory];
  }
  if (!manufacturer) {
    return [undefined, StepId.Model];
  }

  return [
    Object.entries({ manufacturer, model, subcategoryId })
      .filter(([, value]) => value)
      .map(([field, value]) => ({
        field,
        values: [value],
      })) as Array<Filter>,
    undefined,
  ];
};

export const SearchStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onGoBack,
  onGoToStep,
  onStepCompleted,
}) => {
  const manufacturer = useSelector(selectors.manufacturer);
  const model = useSelector(selectors.model);
  const subcategoryName = useSelector(selectors.subcategoryName);
  const subcategoryId = useSelector(selectors.subcategoryId);
  const nav = useNavigation();
  const backLabel = useSelector(selectors.label);
  const { initializeFromExistingAdSku } = useNewAdSkuActions();

  const [searchParams, stepId] = useMemo(
    () => getSearchParams({ manufacturer, model, subcategoryId }),
    [manufacturer, model, subcategoryId],
  );

  const { data, loading, error } = useResolvedAdSkusQuery({
    variables: {
      filters: searchParams,
      slice: {
        offset: 0,
        limit: 20,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!searchParams && stepId) {
      onGoToStep(stepId);
    }
  }, [onGoToStep, searchParams, stepId]);

  useEffect(() => {
    if (!data?.adSkus.length && !loading && !error) {
      onStepCompleted();
    }
  }, [data, error, loading, onStepCompleted]);

  const adSkus = useMemo(() => (data?.adSkus || []).map(item => item.adSku), [
    data,
  ]);

  const handleNewSimilarAdSku = useCallback(
    (adSku: ResolvedAdSku) => {
      initializeFromExistingAdSku(adSku);
      onStepCompleted();
    },
    [onStepCompleted, initializeFromExistingAdSku],
  );

  const handleNewSkuInstance = useCallback(
    (adSku: ResolvedAdSku) => {
      nav.newAdSkuInstance({ adSkuId: adSku.id }).go();
    },
    [nav],
  );

  if (
    !manufacturer ||
    !subcategoryName ||
    (!data?.adSkus.length && !loading && !error)
  ) {
    return null;
  }

  return (
    <SearchStepComponent
      backLabel={backLabel}
      error={error}
      skus={adSkus}
      loading={loading}
      number={number}
      totalCount={totalCount}
      onGoBack={onGoBack}
      onNewSkuInstance={handleNewSkuInstance}
      onNewSimilarSku={handleNewSimilarAdSku}
      onNewSku={onStepCompleted}
      manufacturer={manufacturer}
      model={model}
      subcategoryLabel={subcategoryName}
    />
  );
};
