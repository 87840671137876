import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { createUploadLink } from 'apollo-upload-client';

import { skuMock, skuTemplateMock } from '../../mocks';

import { createAuthLink } from './authLink';
import { errorLink } from './errorLink';
import { createTenantLink } from './tenantLink';

const resolvers = {
  Query: {
    skuTemplate: () => skuTemplateMock,
    sku: () => skuMock,
  },
};

export const getClient = (
  uri: string,
  getToken: () => Promise<string>,
  tenantId?: string,
) => {
  const timeoutLink = new ApolloLinkTimeout(30000);
  const httpLink = createUploadLink({ uri });
  const authLink = createAuthLink(getToken);
  const tenantLink = createTenantLink(tenantId);
  const link = ApolloLink.from([
    errorLink,
    // Error in @types/apollo-upload-client
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    authLink,
    // Error in @types/apollo-upload-client
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    tenantLink,
    timeoutLink,
    // Error in @types/apollo-upload-client
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    httpLink,
  ]);

  return new ApolloClient({
    cache: new InMemoryCache(),
    resolvers,
    link,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
      query: {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};
