import { TFunction } from 'i18next';
import { mapValues } from 'lodash';

import {
  BooleanFieldConfig,
  FunctionalityFieldDisplayType,
  OptionSetFieldConfig,
} from './types';

type OptionSetFieldKeys = {
  label: string;
  promptLabel: string;
  valueEditLabels?: Record<string, string>;
  valueViewLabels?: Record<string, string>;
};

type OptionSetFieldParams = {
  key: string;
  t: TFunction;
  tk: OptionSetFieldKeys;
  type:
    | FunctionalityFieldDisplayType.String
    | FunctionalityFieldDisplayType.Number;
};

export const genOptionSetField = ({
  key,
  t,
  tk,
  type,
}: OptionSetFieldParams): OptionSetFieldConfig => {
  return {
    key,
    type,
    label: t(tk.label),
    promptLabel: t(tk.promptLabel),
    valueEditLabels: tk.valueEditLabels
      ? mapValues(tk.valueEditLabels, value => t(value))
      : undefined,
    valueViewLabels: tk.valueViewLabels
      ? mapValues(tk.valueViewLabels, value => t(value))
      : undefined,
  };
};

type BooleanFieldKeys = {
  label: string;
  editLabel?: string;
  groupBy?: string;
};

type BooleanFieldParams = {
  key: string;
  t: TFunction;
  tk: BooleanFieldKeys;
};

export const genBooleanField = ({
  key,
  t,
  tk,
}: BooleanFieldParams): BooleanFieldConfig => {
  return {
    key,
    type: FunctionalityFieldDisplayType.Bool,
    label: t(tk.label),
    editLabel: tk.editLabel ? t(tk.editLabel) : undefined,
    groupBy: tk.groupBy ? t(tk.groupBy) : undefined,
  };
};
