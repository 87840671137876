import { useContext } from 'react';

import { assert } from '../utils';

import { ConfigContext } from './ConfigContext';

export const useConfig = () => {
  const config = useContext(ConfigContext);
  assert(config.apiUrl);
  return config;
};
