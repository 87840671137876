import { useMemo } from 'react';
import * as yup from 'yup';
import { parse, isValid } from 'date-fns';
import { TFunction } from 'i18next';

import { useTranslation } from '../../translations';
import { ResolvedOffice } from '../context/types';
import {
  DateFieldDefinition,
  FieldDefinition,
  FormLayout,
} from '../../common/components/Form/types';
import { useOffices } from '../context/useOffices';
import { AdSkuTemplatePayload } from '../graphql/types';
import { dateFormat } from '../utils/format';
import { useAdSkuInstanceTemplate } from '../context/useAdSkuInstanceTemplate';

import { FieldOverrides, resolveFields } from './utils';
import { procurementCost } from './useSkuInstanceForm';
import { disableFields, gridItemSmall, gridItemStandard } from './common';

export const adSkuInstanceFormFields = [
  'officeId',
  'status',
  'condition',
  'processingStage',
  'salesPeriodDateFrom',
  'salesPeriodDateTo',
  'salesPrice',
  'locationNumber',
  'tenant',
] as const;

export type SupportedAdSkuInstanceField = typeof adSkuInstanceFormFields[number];

export const dateField = (
  dateFieldName: string,
  t: TFunction,
): Partial<DateFieldDefinition> => ({
  type: 'DateFieldDefinition',
  validation: yup
    .string()
    .nullable()
    .test(
      dateFieldName,
      t('skuInstanceView.validation.invalidDate'),
      (value?: string | null) => {
        if (!value) {
          return true;
        }

        return (
          isValid(parse(value, dateFormat, new Date())) &&
          /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/.test(value)
        );
      },
    ),
});

const getOverrides = (
  offices: ResolvedOffice[],
  t: TFunction,
): FieldOverrides => ({
  locationNumber: {
    type: 'SelectFieldDefinition',
    options: offices.map(office => ({
      label: `${office.locationNumber}`,
      value: office.locationNumber,
    })),
  },
  salesPrice: procurementCost(t),
  salesPeriodDateFrom: dateField('salesPeriodDateFrom', t),
  salesPeriodDateTo: dateField('salesPeriodDateTo', t),
});

export const getAdSkuInstanceFormFields = (
  templateFields: AdSkuTemplatePayload['fields'],
  offices: ResolvedOffice[],
  t: TFunction,
  keys: readonly SupportedAdSkuInstanceField[] = adSkuInstanceFormFields,
): Record<string, FieldDefinition> => {
  return {
    ...resolveFields(templateFields, getOverrides(offices, t), keys),
    tenant: {
      disabled: true,
      type: 'TextFieldDefinition',
    } as FieldDefinition,
  };
};

export const useAdSkuInstanceFormFields = (
  keys = adSkuInstanceFormFields,
  disabledFields = false,
) => {
  const offices = useOffices();
  const { fields } = useAdSkuInstanceTemplate();
  const { t } = useTranslation('inventory');

  return useMemo(() => {
    const formFields = getAdSkuInstanceFormFields(fields, offices, t, keys);

    return disabledFields ? disableFields(formFields) : formFields;
  }, [fields, offices, t, keys, disabledFields]);
};

export const useAdSkuInstanceFormLayout = (): FormLayout => {
  const { t, tk } = useTranslation('inventory');
  return useMemo(
    () => [
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'status',
            label: t(tk.fieldLabels.status),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'condition',
            label: t(tk.fieldLabels.condition),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemSmall,
            name: 'salesPrice',
            label: t(tk.fieldLabels.price),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'salesPeriodDateFrom',
            label: t(tk.fieldLabels.saleStart),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'salesPeriodDateTo',
            label: t(tk.fieldLabels.saleEnd),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'tenant',
            label: t(tk.fieldLabels.tenant),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'locationNumber',
            label: t(tk.fieldLabels.officeId),
          },
        ],
      },
    ],
    [t, tk],
  );
};
