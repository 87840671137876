import { BreadcrumbItem } from '../../../common/components/Breadcrumbs';
import { Navigation } from '../../../common/navigation';
import { ResolvedMainCategory } from '../../context/types';
import { Filter } from '../../graphql';

type HeaderData = {
  title: string;
  breadcrumbs: BreadcrumbItem[];
};

export enum SkuType {
  skus = 'skus',
  adSkus = 'adSkus',
}

export const getHeaderData = (
  filters: Filter[],
  categories: ResolvedMainCategory[],
  nav: Navigation,
  title: string,
  skuType: SkuType,
) => {
  const result: HeaderData = {
    breadcrumbs: [
      {
        label: title,
        route: nav[skuType](),
      },
    ],
    title,
  };

  const subcategoryFilter = filters.find(f => f.field === 'subcategoryId');
  if (!subcategoryFilter || !subcategoryFilter.values.length) {
    return result;
  }
  const mainCategory = categories.find(c =>
    c.subcategories.some(s => s.id === subcategoryFilter.values[0]),
  );
  if (!mainCategory) {
    return result;
  }
  result.breadcrumbs.push({
    label: mainCategory.name,
    route: nav.skus({
      filters: { subcategoryId: mainCategory.subcategories.map(s => s.id) },
    }),
  });
  result.title = mainCategory.name;

  // more than one subcategory means we selected whole main category
  if (subcategoryFilter.values.length > 1) {
    return result;
  }

  const subcategory = mainCategory.subcategories.find(
    c => c.id === subcategoryFilter.values[0],
  );
  if (!subcategory) {
    return result;
  }
  result.breadcrumbs.push({
    label: subcategory.name,
    route: nav.skus({
      filters: { subcategoryId: [subcategory.id] },
    }),
  });
  result.title = subcategory.name;

  return result;
};
