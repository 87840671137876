import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { GraphQlError } from '../../../../common/types';
import { FormFeedback } from '../../../../common/components/FormFeedback';
import { SkuPreview } from '../SkuPreview/SkuPreview';
import { ResolvedAdSku, ResolvedSku } from '../../../types';
import { getFunctionalityPreviewProps } from '../../../utils/sku';
import { BackgroundContainer } from '../../../../common/components/BackgroundContainer';
import { LoadingIndicator } from '../../../../common/components/LoadingIndicator';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      marginRight: theme.spacing(1),
    },
  }),
);

type Props<T = ResolvedSku | ResolvedAdSku> = {
  skus: Array<T>;
  backLabel: string;
  number: number;
  totalCount: number;
  loading?: boolean;
  error?: GraphQlError;
  onNewSkuInstance: (sku: T) => void;
  onNewSimilarSku: (sku: T) => void;
  onNewSku: () => void;
  onGoBack?: () => void;
  manufacturer: string;
  model?: string | null;
  subcategoryLabel: string;
};

const isSkuWithFunctionalities = (
  sku: ResolvedSku | ResolvedAdSku,
): sku is ResolvedSku => {
  return (sku as ResolvedSku).functionalities !== undefined;
};

export const SearchStep = <T extends ResolvedSku | ResolvedAdSku>({
  backLabel,
  error,
  skus,
  loading,
  number,
  totalCount,
  onGoBack,
  onNewSkuInstance,
  onNewSimilarSku,
  onNewSku,
  manufacturer,
  model,
  subcategoryLabel,
}: Props<T>) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  const handleNewSkuInstance = (sku: T) => () => {
    onNewSkuInstance(sku);
  };

  const handleNewSimilarSku = (sku: T) => () => {
    onNewSimilarSku(sku);
  };

  if (loading) {
    return <LoadingIndicator size="small" />;
  }

  return (
    <>
      <WizardStep
        backLabel={backLabel}
        onGoBack={onGoBack}
        number={number}
        totalCount={totalCount}
        showProgress={false}
        title={t(tk.newSkuView.searchStep.title)}
        data-testid="SearchStep"
      >
        <Box mb={3}>
          <Typography variant="subtitle1">
            {t(tk.newSkuView.searchStep.subtitle, {
              count: skus.length,
              manufacturer,
              model,
              subcategory: subcategoryLabel,
            })}
          </Typography>
        </Box>
        <FormFeedback error={error} />
        {skus.map(sku => (
          <Box key={sku.id} mb={2}>
            <SkuPreview
              sku={sku}
              images={sku.images}
              functionality={
                isSkuWithFunctionalities(sku)
                  ? getFunctionalityPreviewProps(sku)
                  : undefined
              }
              actions={
                <>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    color="secondary"
                    onClick={handleNewSkuInstance(sku)}
                  >
                    {t(tk.newSkuView.searchStep.actions.newInstance)}
                  </Button>
                  <Button color="secondary" onClick={handleNewSimilarSku(sku)}>
                    {t(tk.newSkuView.searchStep.actions.newSimilarSku)}
                  </Button>
                </>
              }
            />
          </Box>
        ))}
      </WizardStep>
      <BackgroundContainer color="secondary" maxWidth="sm">
        <Box pt={5} mb={2}>
          <Typography variant="subtitle1">
            {t(tk.newSkuView.searchStep.newSku.description)}
          </Typography>
        </Box>
        <Typography variant="subtitle1">
          <strong>
            <Link href="#" onClick={onNewSku}>
              {t(tk.newSkuView.searchStep.newSku.action)}
            </Link>
          </strong>
        </Typography>
        <Box mt={3} mb={2}>
          <Typography variant="subtitle1">
            {t(tk.newSkuView.searchStep.abandon.description)}
          </Typography>
        </Box>
        <Box pb={5}>
          <Typography variant="subtitle1">
            <strong>
              <Link href="#" onClick={onGoBack}>
                {t(tk.newSkuView.searchStep.abandon.action)}
              </Link>
            </strong>
          </Typography>
        </Box>
      </BackgroundContainer>
    </>
  );
};
