import React, { useMemo, useLayoutEffect } from 'react';
import { useRouteMatch } from 'react-router';

import { initTranslations } from '../../translations';

export const LanguageProvider: React.FC = ({ children }) => {
  const match = useRouteMatch<{ lang: string }>();
  const i18n = useMemo(() => initTranslations(match.params.lang), [
    match.params.lang,
  ]);
  useLayoutEffect(() => {
    if (i18n.language !== match.params.lang) {
      i18n.changeLanguage(match.params.lang);
    }
  }, [match.params.lang, i18n]);

  return <>{children}</>;
};
