import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { mapValues } from 'lodash';

import { FieldDefinition, FieldValues, FormFields } from './types';
import { getValidationSchema } from './validation';

const getDefaultValue = (field: FieldDefinition) => {
  switch (field.type) {
    case 'BooleanFieldDefinition':
      return field.defaultValue || false;
    case 'DateFieldDefinition':
      return field.defaultValue || null;
    default:
      return field.defaultValue || '';
  }
};

type Options<T extends FieldValues = FieldValues> = {
  defaultValues?: Partial<T>;
  fields: FormFields;
};

export const useConfiguredForm = <T extends FieldValues = FieldValues>({
  defaultValues: existingValues,
  fields,
}: Options<T>) => {
  const defaultValues = useMemo(
    () =>
      mapValues(fields, (field, name) => {
        const existingValue = existingValues ? existingValues[name] : undefined;
        return existingValue || getDefaultValue(field);
      }),
    [existingValues, fields],
  );

  const schema = useMemo(() => getValidationSchema(fields), [fields]);

  return useForm<T>({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: defaultValues as any,
    resolver: yupResolver(schema),
  });
};
