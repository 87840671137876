import { useCallback } from 'react';

import { useNavigation } from '../../../common/navigation';
import {
  useDeleteSkuInstanceMutation,
  DeleteSkuInstanceMutation,
} from '../../graphql';
import { extractMutation } from '../../../common/utils/extractQuery';
import { DeleteSkuInstanceMutationPayload } from '../../graphql/types';

export const useDeleteSkuInstance = (id: string, skuId?: string) => {
  const nav = useNavigation();
  const [
    deleteSkuInstance,
    rawDeleteMutationResult,
  ] = useDeleteSkuInstanceMutation({
    onCompleted: data => {
      if (data.deleteSkuInstance?.__typename !== 'DeleteSkuInstancePayload') {
        return;
      }
      if (skuId) {
        nav.sku({ id: skuId }).go();
      } else {
        nav.skus().go();
      }
    },
  });

  const mutationResult = extractMutation<
    DeleteSkuInstanceMutation,
    DeleteSkuInstanceMutationPayload
  >(
    rawDeleteMutationResult,
    'DeleteSkuInstancePayload',
    data => data.deleteSkuInstance,
  );

  const onDelete = useCallback(() => {
    deleteSkuInstance({
      variables: {
        input: {
          id,
        },
      },
    });
  }, [deleteSkuInstance, id]);

  return {
    onDelete,
    mutationResult,
  };
};
