import omit from 'lodash/omit';

import { logError } from '../../../common/utils/logError';
import { ResolvedAdSku } from '../../types';
import { AdSkuFormInput } from '../../forms/useAdSkuForm';

export const getInitialValues = (
  adSku: ResolvedAdSku,
): Partial<AdSkuFormInput> => {
  let mainCategoryId = '';
  let subcategoryId = '';
  if (adSku.mainCategory?.__typename === 'MainCategory') {
    mainCategoryId = adSku.mainCategory.id;
  } else {
    const error =
      // ts being weird, either complains that condition !== MainCategory is unnecessary,
      // or that message doesn't exist when typename is MainCategory
      // @ts-expect-error
      adSku.mainCategory && adSku.mainCategory.__typename !== 'MainCategory'
        ? adSku.mainCategory?.message
        : null;
    logError({
      key: 'ad-sku-missing-category',
      message: error || `Missing category id for AD SKU ${adSku.id}`,
      skuId: adSku.id,
    });
  }
  if (adSku.subcategory?.__typename === 'Subcategory') {
    subcategoryId = adSku.subcategory.id;
  } else {
    const error =
      // ts being weird, either complains that condition !== Subcategory is unnecessary,
      // or that message doesn't exist when typename is Subcategory
      // @ts-expect-error
      adSku.subcategory && adSku.subcategory.__typename !== 'Subcategory'
        ? adSku.subcategory.message
        : null;
    logError({
      key: 'ad-sku-missing-subcategory',
      message: error || `Missing subcategory id for AD SKU ${adSku.id}`,
      skuId: adSku.id,
    });
  }
  return {
    ...omit(adSku, ['mainCategory', 'subcategory', 'images', '__typename']),
    mainCategoryId,
    subcategoryId,
    primaryColor: adSku.primaryColor?.key,
    secondaryColor: adSku.secondaryColor?.key,
  };
};
