import React, { FC } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useState } from 'react';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { ResolvedOffice } from '../../../context/types';
import { WhiteCard } from '../../../../common/components/WhiteCard';

import { LocationSelect } from './LocationSelect';

const getTimeOfDay = () => {
  const hours = new Date().getHours();
  if (hours < 12) {
    return 'morning';
  }
  if (hours < 18) {
    return 'afternoon';
  }
  return 'evening';
};

type Props = {
  offices: ResolvedOffice[];
  initialValue?: string;
  number: number;
  totalCount: number;
  onSubmit: (officeId: string) => void;
};

export const LocationStep: FC<Props> = ({
  offices,
  initialValue,
  number,
  totalCount,
  onSubmit,
}) => {
  const { t, tk } = useTranslation('inventory');

  const [selected, setSelected] = useState<string>(initialValue || '');

  const handleSubmit = () => {
    if (selected) {
      onSubmit(selected);
    }
  };

  return (
    <WizardStep
      number={number}
      totalCount={totalCount}
      showProgress={false}
      data-testid="LocationStep"
    >
      <WhiteCard variant="outlined">
        <CardContent>
          <Box mb={1}>
            <Typography variant="h3">
              {t('newSkuView.locationStep.title', {
                context: getTimeOfDay(),
              })}
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="subtitle1">
              {t(tk.newSkuView.locationStep.description)}
            </Typography>
          </Box>
          <LocationSelect
            offices={offices}
            onChange={setSelected}
            selected={selected}
          />
          <Box mt={2}>
            <Button
              disabled={!selected}
              onClick={handleSubmit}
              fullWidth
              size="large"
              color="secondary"
            >
              {t(tk.newSkuView.locationStep.submit)}
            </Button>
          </Box>
        </CardContent>
      </WhiteCard>
    </WizardStep>
  );
};
