import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import QrReader from 'react-qr-reader';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
    },
    reader: {
      width: '100%',
      '& video': {
        position: 'fixed !important',
        width: '100vw !important',
        height: '100vh !important',
      },
    },
    button: {
      position: 'absolute',
      bottom: 20,
      margin: '0 20px',
    },
  }),
);

type Props = {
  open: boolean;
  onError?: (message: string) => void;
  onChange?: (code: string) => void;
};

export const Scanner: FC<Props> = ({ children, onChange, open, onError }) => {
  const classes = useStyles();

  const handleScan = (result: string | null) => {
    if (result && onChange) {
      onChange(result);
    }
  };

  const handleError = (error: Error) => {
    if (onError) {
      onError(error.message);
    }
  };

  return (
    <Modal open={open}>
      <div className={classes.container}>
        <QrReader
          delay={300}
          facingMode="environment"
          showViewFinder={true}
          onError={handleError}
          onScan={handleScan}
          resolution={1200}
          className={classes.reader}
        />
        {children}
      </div>
    </Modal>
  );
};
