import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
} from '../types';
import { mapFieldKeys } from '../utils';

import { getFields, ChairFields } from './fields';
import { ChairFunctionalities } from './types';

const defaultValues: ChairFunctionalities = {
  chairAdjustableHeight: 'fixed',
  chairBack: false,
  chairBackFixed: false,
  chairBackAdjustableHeight: false,
  chairBackAdjustableAngle: false,
  chairLeaningBack: false,
  chairSeat: true,
  chairSeatFixed: true,
  chairSeatAdjustablePosition: false,
  chairSeatAdjustableAngle: false,
  chairArmrests: false,
  chairArmrestsFixed: false,
  chairArmrestsAdjustablePosition: false,
  chairArmrestsAdjustableHeight: false,
  chairArmrestsAdjustableWidth: false,
  chairArmrestsAdjustableLength: false,
  chairNeckrest: 'none',
  chairFootring: false,
  chairStackable: false,
  chairFoldable: false,
};

const groups = (
  { chairFeetCount, chairFeetType, chairStackable, chairFoldable }: ChairFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'feet',
    label: t(tk.chairs.groups.feet.label),
    attributes: [chairFeetCount, chairFeetType],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairFeetCount,
        },
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairFeetType,
        },
      ],
    },
  },
  {
    name: 'stackable',
    label: t(tk.chairs.groups.stackable.label),
    attributes: [chairStackable],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.chairs.groups.stackable.noValueLabel),
      field: chairStackable,
    },
  },
  {
    name: 'foldable',
    label: t(tk.chairs.groups.foldable.label),
    attributes: [chairFoldable],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.chairs.groups.foldable.noValueLabel),
      field: chairFoldable,
    },
  },
];

export const poufs = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields, t, tk),
  };
};
