import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';

import { standard } from './standard';
import { chaise } from './chaise';
import { sectional } from './sectional';

export const sofas = (t: TFunction, tk: TKeys<'functionality'>) => ({
  standard: standard(t, tk),
  chaise: chaise(t, tk),
  sectional: sectional(t, tk),
});
