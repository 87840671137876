import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { useOffices } from '../../../context/useOffices';
import { useOfficeActions } from '../../../redux/office';
import { LocationStep as LocationStepComponent } from '../../../components/RegistrationView/LocationStep';
import * as selectors from '../../../redux/office/selectors';

export const LocationStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onStepCompleted,
}) => {
  const offices = useOffices();
  const { setOfficeId } = useOfficeActions();
  const initialValue = useSelector(selectors.officeId);

  useMount(() => {
    if (initialValue) {
      onStepCompleted();
    }
  });

  const handleSubmit = (officeId: string) => {
    setOfficeId(officeId);
    onStepCompleted();
  };

  return (
    <LocationStepComponent
      initialValue={initialValue}
      offices={offices}
      onSubmit={handleSubmit}
      number={number}
      totalCount={totalCount}
    />
  );
};
