import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useConfig } from '../../common/config';
import { getClient } from '../setup/apolloClient';

export const useApolloClient = (tenantId?: string) => {
  const config = useConfig();
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(
    () => getClient(config.apiUrl, getAccessTokenSilently, tenantId),
    [config.apiUrl, getAccessTokenSilently, tenantId],
  );
};
