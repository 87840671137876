import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import dateFnsFormat from 'date-fns/format';
import dateFnsNoLocale from 'date-fns/locale/nb';

import { resources } from './resources';

type DateFormats = {
  date: string;
  dateTime: string;
  dateExtended: string;
};

type AvailableDateFormats = 'date' | 'dateTime' | 'dateExtended';

const dateLocales: { [lng: string]: typeof dateFnsNoLocale } = {
  no: dateFnsNoLocale,
};

const dateFormat: { [lng: string]: DateFormats } = {
  no: {
    date: 'dd.MM.yyyy',
    dateTime: 'dd.MM.yyyy HH:mm',
    dateExtended: 'dd. MMMM yyyy',
  },
};

const defaultDateFormat = dateFormat.no.date;

export const initTranslations = (lng?: string) => {
  i18next.use(initReactI18next).init({
    debug: process.env.NODE_ENV === 'development',
    lng,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: (value, format, language?: string) => {
        if (value instanceof Date) {
          const locale = (language && dateLocales[language]) || dateLocales.no;
          const dateFormats =
            (language && dateFormat[language]) || dateFormat.no;
          const options = { locale };
          const isDateFormat =
            format === 'dateTime' ||
            format === 'dateExtended' ||
            format === 'date';

          return dateFnsFormat(
            value,
            isDateFormat
              ? dateFormats[format as AvailableDateFormats]
              : format || defaultDateFormat,
            options,
          );
        }
        return value;
      },
    },
    resources,
  });

  return i18next;
};
