import { ReactNode, useMemo } from 'react';
import * as yup from 'yup';
import { parse, isValid } from 'date-fns';
import { TFunction } from 'i18next';

import { useTranslation } from '../../translations';
import { ResolvedOffice } from '../context/types';
import {
  DateFieldDefinition,
  FieldDefinition,
  FormLayout,
  TextFieldDefinition,
} from '../../common/components/Form/types';
import { SkuInstanceInput } from '../graphql';
import ensure from '../../common/utils';
import { useOffices } from '../context/useOffices';
import { useSkuInstanceTemplate } from '../context/useSkuInstanceTemplate';
import { SkuTemplatePayload } from '../graphql/types';
import { dateFormat } from '../utils/format';

import { gridItemStandard, gridItemSmall } from './common';
import { FieldOverrides, resolveFields } from './utils';

export const skuInstanceFormFields = [
  'officeId',
  'officeFloorId',
  'room',
  'status',
  'condition',
  'costCenter',
  'procurementDate',
  'procurementNumber',
  'procurementCost',
  'processingStage',
  'processingStageDateFrom',
  'processingStageDateTo',
  'note',
] as const;

export type SupportedSkuInstanceField = typeof skuInstanceFormFields[number];

export type SkuInstanceFormInput = Pick<
  SkuInstanceInput,
  SupportedSkuInstanceField
>;

export const procurementCost = (
  t: TFunction,
): Partial<TextFieldDefinition> => ({
  endAdornment: 'kr',
  validation: yup
    .string()
    .test(
      'is-decimal',
      t('skuInstanceView.validation.invalidDecimal'),
      (value?: string | null) => {
        if (!value) {
          return true;
        }
        return !!value.match(/^\d+(?:\.\d{1,4})?$/);
      },
    ),
});

export const dateField = (
  dateFieldName: string,
  t: TFunction,
): Partial<DateFieldDefinition> => ({
  type: 'DateFieldDefinition',
  validation: yup
    .string()
    .nullable()
    .test(
      dateFieldName,
      t('skuInstanceView.validation.invalidDate'),
      (value?: string | null) => {
        if (!value) {
          return true;
        }

        return (
          isValid(parse(value, dateFormat, new Date())) &&
          /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/.test(value)
        );
      },
    ),
});

export const costCenter = (t: TFunction): Partial<TextFieldDefinition> => ({
  validation: yup
    .string()
    .matches(
      /(^$)|(^[0-9]{6}$)/,
      t('skuInstanceView.validation.costCenterLength'),
    ),
});

const getOverrides = (
  offices: ResolvedOffice[],
  t: TFunction,
): FieldOverrides => ({
  officeId: {
    type: 'SelectFieldDefinition',
    options: offices.map(office => ({
      label: `${office.street} ${office.streetNumber}${
        office.building ? office.building : ''
      } [${office.zipCode} ${office.city}]`,
      value: office.id,
    })),
  },
  officeFloorId: {
    type: 'SelectFieldDefinition',
    disabled: values => !values.officeId,
    strict: true,
    options: values =>
      values.officeId
        ? ensure(offices.find(c => c.id === values.officeId)).officeFloors.map(
            floor => ({
              label: floor.floorLabel,
              value: floor.id,
            }),
          )
        : [],
  },
  procurementCost: procurementCost(t),
  procurementDate: dateField('procurementDate', t),
  processingStageDateFrom: dateField('processingStageDateFrom', t),
  processingStageDateTo: dateField('processingStageDateTo', t),
  note: {
    rows: 3,
  },
  costCenter: costCenter(t),
});

export const getSkuInstanceFormFields = (
  templateFields: SkuTemplatePayload['fields'],
  offices: ResolvedOffice[],
  t: TFunction,
  keys: readonly SupportedSkuInstanceField[] = skuInstanceFormFields,
) => {
  return resolveFields(templateFields, getOverrides(offices, t), keys);
};

export const useSkuInstanceFormFields = (
  keys = skuInstanceFormFields,
): Record<string, FieldDefinition> => {
  const offices = useOffices();
  const { fields } = useSkuInstanceTemplate();
  const { t } = useTranslation('inventory');

  return useMemo(() => getSkuInstanceFormFields(fields, offices, t, keys), [
    fields,
    offices,
    keys,
    t,
  ]);
};

export const useSkuInstanceFormLayout = (
  qrCodeElement: ReactNode,
): FormLayout => {
  const { t, tk } = useTranslation('inventory');
  return useMemo(
    () => [
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 12, md: 4 },
            name: 'officeId',
            label: t(tk.fieldLabels.officeId),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'officeFloorId',
            label: t(tk.fieldLabels.officeFloorId),
          },
          {
            gridItemProps: gridItemSmall,
            name: 'room',
            label: t(tk.fieldLabels.room),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'status',
            label: t(tk.fieldLabels.status),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'condition',
            label: t(tk.fieldLabels.condition),
          },
        ],
      },
      { type: 'component', component: qrCodeElement ?? null },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'costCenter',
            label: t(tk.fieldLabels.costCenter),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'procurementDate',
            label: t(tk.fieldLabels.procurementDate),
            fieldOverrides: {},
          },
          {
            gridItemProps: gridItemStandard,
            name: 'procurementNumber',
            label: t(tk.fieldLabels.procurementNumber),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'procurementCost',
            label: t(tk.fieldLabels.procurementCost),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'processingStage',
            label: t(tk.fieldLabels.processingStage),
            fieldOverrides: {},
          },
          {
            gridItemProps: gridItemStandard,
            name: 'processingStageDateFrom',
            label: t(tk.fieldLabels.processingStageDateFrom),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'processingStageDateTo',
            label: t(tk.fieldLabels.processingStageDateTo),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 12 },
            name: 'note',
            label: t(tk.fieldLabels.note),
            fieldOverrides: {},
          },
        ],
      },
    ],
    [qrCodeElement, t, tk],
  );
};
