import { produce } from 'immer';

import { Actions } from './actions';
import { actionTypes } from './actionTypes';

export type State = {
  officeId?: string;
};

export const initialData: State = {};

export const reducer = produce((state: State, action: Actions) => {
  switch (action.type) {
    case actionTypes.RESET: {
      state.officeId = undefined;
      return;
    }

    case actionTypes.SET_OFFICE_ID: {
      state.officeId = action.officeId;
      return;
    }
  }
}, initialData);
