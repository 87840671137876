export enum StepId {
  Color = 'Color',
  Dimensions = 'Dimensions',
  Image = 'Image',
  MainCategory = 'MainCategory',
  Model = 'Model',
  Search = 'Search',
  Subcategory = 'Subcategory',
  Summary = 'Summary',
}
