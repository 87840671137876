import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import { LoadingIndicator } from '../../common/components/LoadingIndicator';
import { QrCodeView } from '../../inventory/containers/QrCodeView';
import { LanguageProvider } from '../translations/LanguageProvider';

export const RootRouter: React.FC = ({ children }) => {
  return (
    <Switch>
      <Route
        path="/:lang(en|no)"
        component={() => <LanguageProvider>{children}</LanguageProvider>}
      />
      <Route path="/callback" exact component={LoadingIndicator} />
      <Route
        path="/qr/:gtin/:serialNumber"
        render={props => (
          <QrCodeView
            gtin={props.match.params.gtin}
            serialNumber={props.match.params.serialNumber}
          />
        )}
      />
      <Route component={() => <Redirect to="/en" />} />
    </Switch>
  );
};
