import omit from 'lodash/omit';

import { SkuInstanceInput } from '../../graphql';
import { SkuInstancePayload } from '../../graphql/types';

export const getInitialValues = (
  skuInstance: SkuInstancePayload,
): Omit<SkuInstanceInput, 'skuId'> => {
  let officeId;
  let officeFloorId;
  if (skuInstance.office?.__typename === 'Office') {
    officeId = skuInstance.office.id;
  }
  if (skuInstance.officeFloor?.__typename === 'OfficeFloor') {
    officeFloorId = skuInstance.officeFloor.id;
  }
  return {
    ...(omit(skuInstance, [
      'id',
      'office',
      'officeFloor',
      'sku',
      '__typename',
    ]) as Omit<SkuInstancePayload, 'id' | 'office' | 'officeFloor' | 'sku'>),
    officeId,
    officeFloorId,
  };
};
