import React, { FC, ReactNode, useMemo } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import cn from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';

import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import {
  Breadcrumbs,
  BreadcrumbsProps,
} from '../../../common/components/Breadcrumbs';
import { ListProps, useList } from '../../../common/components/List/useList';
import { SelectedFilters } from '../../utils/useFilters';
import { ResolvedFunctionalityFilters } from '../../models/types';
import { Maybe } from '../../graphql';
import { useTranslation } from '../../../translations';

import { SkuListMetadata } from './SkuListMetadata';
import { AggregatedFilters } from './AggregatedFilters/AggregatedFilters';
import { getCurrentFilterProps } from './AggregatedFilters/currentFilters';

const EXCLUDE_CURRENT_FILTERS = ['subcategoryId'];

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(12),
      },
    },
    left: {
      paddingRight: theme.spacing(2),
    },
    right: {
      position: 'relative',
    },
    filterCard: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1, 2, 1),
    },
    functionalityCard: {
      backgroundColor: theme.palette.success.veryLight,
      border: `1px solid ${theme.palette.success.light}`,
    },
    categoryFilter: {
      marginBottom: theme.spacing(3),
    },
    grid: {
      marginBottom: theme.spacing(5),
    },
    pagination: {
      justifyContent: 'center',
    },
    printIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    cardTitle: {
      paddingBottom: theme.spacing(1),
    },
  }),
);

type Props = ListProps & {
  breadcrumbs: BreadcrumbsProps['items'];
  title: string;
  selectedFilters: SelectedFilters;
  setSelectedFilters: (filters: SelectedFilters) => void;
  functionalityFilters?: Maybe<ResolvedFunctionalityFilters>;
  generateSheet?: ReactNode;
  onPrint?: () => void;
  skuList: JSX.Element;
  onTextInputChange?: (field: string, value: string) => void;
};

export const SkuListView: FC<Props> = ({
  breadcrumbs,
  filters,
  functionalityFilters,
  loading,
  matches,
  title,
  page,
  pageCount,
  onPageChange,
  onFilterChange,
  onRangeFilterChange,
  selectedFilters,
  setSelectedFilters,
  onPrint,
  generateSheet,
  skuList,
  onTextInputChange,
}) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');
  const { breakpoints } = useTheme();
  const twoColumn = useMediaQuery(breakpoints.up('md'));
  const extraCurrentFilters = useMemo(
    () =>
      functionalityFilters
        ? getCurrentFilterProps(functionalityFilters.groups, selectedFilters)
        : undefined,
    [functionalityFilters, selectedFilters],
  );

  const { CurrentFilters, Pagination, Filters } = useList({
    filters,
    page,
    pageCount,
    onPageChange,
    onFilterChange,
    onRangeFilterChange,
    onTextInputChange,
    selectedFilters,
    excludeCurrentFilters: EXCLUDE_CURRENT_FILTERS,
    extraCurrentFilters,
  });

  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        {twoColumn && (
          <Grid item md={3} xs={12} className={classes.left}></Grid>
        )}
        <Grid item md={9} xs={12} className={classes.right}>
          <Grid container direction={'row'} justify="flex-end">
            {onPrint && (
              <Tooltip
                title={
                  t(tk.skuListView.skuGeneratePrintForAllTooltip) as string
                }
              >
                <IconButton onClick={onPrint}>
                  <PrintIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {generateSheet}
          </Grid>
          <Breadcrumbs items={breadcrumbs} />
          <Box mb={3}>
            <Typography variant="h3">{title}</Typography>
          </Box>
          <SkuListMetadata matches={matches} loading={loading} />
          <CurrentFilters color="primary" />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={3} xs={12} className={classes.left}>
          <Filters />
          {functionalityFilters && (
            <Card className={cn(classes.filterCard, classes.functionalityCard)}>
              <AggregatedFilters
                {...functionalityFilters}
                selected={selectedFilters}
                onChange={setSelectedFilters}
              />
            </Card>
          )}
        </Grid>
        <Grid item md={9} xs={12}>
          {loading ? (
            <Box m={8}>
              <LoadingIndicator size="medium" />
            </Box>
          ) : (
            <>
              <Grid
                container
                className={classes.grid}
                spacing={2}
                data-testid="SkuList"
              >
                {skuList}
              </Grid>
              <Pagination data-testid="SkuListPagination" />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
