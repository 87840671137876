import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { FormFeedback } from '../../../common/components/FormFeedback';
import { Form } from '../../../common/components/Form';
import { GraphQlError } from '../../../common/types';
import { FormFields } from '../../../common/components/Form/types';
import {
  useTenantFormBasicLayout,
  useTenantFormWithOfficeFloorLayout,
} from '../../forms/useOfficeForm';
import { CancelButton } from '../Common/CancelButton';
import { CreateOfficeInput } from '../../../inventory/graphql';
import { useOfficeFloorsFieldComponent } from '../../../inventory/components/SkuView/useOfficeFloorsFieldComponent';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    cancelButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      float: 'right',
    },
  }),
);

export type CreateOfficeFields = Pick<
  CreateOfficeInput['office'],
  'street' | 'streetNumber' | 'zipCode' | 'officeFloors' | 'city'
>;

export type OfficeInput = {
  city: string;
  zipCode: string;
  street: string;
  streetNumber: string;
};

type Props = {
  error?: GraphQlError;
  fields: FormFields;
  onSubmit: (data: OfficeInput) => void;
  onCancel: () => void;
  tenantId: string;
  defaultValues?: OfficeInput;
  heading: string;
  isOfficeFloorField?: boolean;
};

export const OfficeView: React.FC<Props> = ({
  error,
  fields,
  onSubmit,
  defaultValues,
  onCancel,
  heading,
  isOfficeFloorField,
}) => {
  const ComponentOfficeFloorField = useOfficeFloorsFieldComponent();
  const layoutWithOfficeFloor = useTenantFormWithOfficeFloorLayout(
    isOfficeFloorField ? ComponentOfficeFloorField : undefined,
  );
  const layoutBasic = useTenantFormBasicLayout();
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography variant="h4">{heading}</Typography>
      <FormFeedback error={error} />
      <Form
        fields={fields}
        layout={isOfficeFloorField ? layoutWithOfficeFloor : layoutBasic}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      />
      <Box className={classes.cancelButton}>
        <CancelButton onCancel={onCancel} />
      </Box>
    </Container>
  );
};
