import React, { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { NewInstanceStepProps, StepId } from '../types';
import { FormStep as FormStepComponent } from '../../../components/NewSkuInstanceView/FormStep';
import * as officeSelectors from '../../../redux/office/selectors';
import { useNewSkuInstanceActions } from '../../../redux/newSkuInstance/useActions';
import * as selectors from '../../../redux/newSkuInstance/selectors';
import {
  FormStepData,
  preregistrationStatus,
  ProcurementFormValues,
  useNewSkuInstanceFormFields,
} from '../../../components/NewSkuInstanceView/FormStep/useNewSkuInstanceForm';
import { useOffices } from '../../../context/useOffices';
import {
  CreateSkuInstancesMutation,
  useCreateSkuInstancesMutation,
} from '../../../graphql';
import { extractMutation } from '../../../../common/utils/extractQuery';
import { CreateSkuInstancesMutationPayload } from '../../../graphql/types';
import {
  LastRegisteredTypes,
  useLastRegistered,
} from '../../../utils/useLastRegistered';
import { useResolvedSkuQuery } from '../../../utils/sku';
import { LoadingIndicator } from '../../../../common/components/LoadingIndicator';
import { ErrorView } from '../../../../common/components/ErrorView';
import { useNavigation } from '../../../../common/navigation';

export const FormStep: FC<StepComponentProps<NewInstanceStepProps>> = ({
  extraProps: { skuId },
  number,
  totalCount,
  onGoToStep,
  onStepCompleted,
}) => {
  const count = useSelector(selectors.count);
  const officeId = useSelector(officeSelectors.officeId);
  const inputs = useSelector(selectors.inputs);
  const offices = useOffices();
  const nav = useNavigation();
  const { add } = useLastRegistered(LastRegisteredTypes.sku);
  const {
    setCount,
    setInputs,
    setPreregistrationSuccess,
  } = useNewSkuInstanceActions();
  const skuQuery = useResolvedSkuQuery({
    variables: { id: skuId },
  });

  const office = officeId ? offices.find(o => o.id === officeId) : null;

  const fields = useNewSkuInstanceFormFields(office);

  const [createSkuInstances, rawMutationResult] = useCreateSkuInstancesMutation(
    {
      refetchQueries: ['SkuInstances'],
    },
  );
  const mutationResult = extractMutation<
    CreateSkuInstancesMutation,
    CreateSkuInstancesMutationPayload
  >(
    rawMutationResult,
    'CreateSkuInstancesPayload',
    data => data.createSkuInstances,
  );

  const initialValues = useMemo(
    () => ({
      ...(inputs[0] || {}),
      count,
      skuId,
    }),
    [count, skuId, inputs],
  );

  useEffect(() => {
    if (!office) {
      onGoToStep(StepId.Location);
    }
  }, [onGoToStep, office]);

  useEffect(() => {
    if (mutationResult.data) {
      setPreregistrationSuccess();
      add(skuId);
      onGoToStep(StepId.PreregistrationSuccess);
    }
  }, [add, mutationResult.data, onGoToStep, setPreregistrationSuccess, skuId]);

  if (skuQuery.error || !skuQuery.data) {
    if (skuQuery.loading) {
      return <LoadingIndicator />;
    }
    return <ErrorView error={skuQuery.error} />;
  }

  if (!office) {
    return null;
  }

  const handlePreregistration = (
    count: number,
    data: ProcurementFormValues,
  ) => {
    setCount(count);
    return createSkuInstances({
      variables: {
        input: {
          count,
          skuInstance: {
            ...data,
            status: preregistrationStatus,
            skuId,
            officeId,
          },
        },
      },
    });
  };

  const handleBack = () => {
    nav.sku({ id: skuId }).go();
  };

  const handleSubmit = (data: FormStepData) => {
    const { count, inputs } = data;
    setCount(count);
    setInputs(inputs);
    onStepCompleted();
  };

  return (
    <FormStepComponent
      error={mutationResult.error}
      fields={fields}
      sku={skuQuery.data}
      initialValues={initialValues}
      number={number}
      totalCount={totalCount}
      onSubmit={handleSubmit}
      onPreregistration={handlePreregistration}
      onGoBack={handleBack}
    />
  );
};
