import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import omit from 'lodash/omit';
import React from 'react';

import { CheckboxListItem } from '../../../../common/components/CheckboxList/CheckboxListItem';
import {
  BooleanFilterRow,
  ResolvedFunctionalityFilterGroup,
  FunctionalityFilterRow,
  ResolvedOptionSetFilterRow,
} from '../../../models/types';

const useStyles = makeStyles(theme =>
  createStyles({
    titleItem: {
      marginTop: theme.spacing(2),
    },
    titleText: {
      fontWeight: 700,
    },
  }),
);

export type CheckboxListProps = {
  group: ResolvedFunctionalityFilterGroup;
  selected: Record<string, string[]>;
  onChange: (selected: Record<string, string[]>) => void;
};

type BooleanRowProps = {
  row: BooleanFilterRow;
  onToggle: () => void;
  selected: string[];
};

const BooleanRow: React.FC<BooleanRowProps> = ({ row, onToggle, selected }) => {
  const { field, label, inverse } = row;

  const showSelected = inverse
    ? selected.includes('false')
    : selected.includes('true');

  return (
    <CheckboxListItem
      handleToggle={onToggle}
      item={{
        label: label || field.label,
        value: field.key,
      }}
      selected={showSelected || false}
      name={field.key}
    ></CheckboxListItem>
  );
};

type OptionSetRowProps = {
  row: ResolvedOptionSetFilterRow;
  onToggle: (key: string, value: string) => () => void;
  selected: string[];
};

const OptionSetRow: React.FC<OptionSetRowProps> = ({
  row,
  onToggle,
  selected,
}) => {
  const { field } = row;

  return (
    <>
      {field.options.map(item => (
        <CheckboxListItem
          key={item.value}
          handleToggle={onToggle(field.key, item.value)}
          item={item}
          selected={selected.indexOf(item.value) !== -1}
          name={field.key}
        ></CheckboxListItem>
      ))}
    </>
  );
};

export const FilterGroup: React.FC<CheckboxListProps> = ({
  group,
  selected,
  onChange,
}) => {
  const { label, rows, singleSelect } = group;
  const classes = useStyles();
  const keys = rows.map(row => row.field.key);

  const handleBooleanToggle = (key: string, inverse: boolean) => () => {
    const currentValue = selected[key] ? selected[key][0] : undefined;
    if (currentValue !== undefined) {
      onChange(omit(selected, key));
      return;
    }

    onChange({
      ...(singleSelect ? omit(selected, keys) : selected),
      [key]: inverse ? ['false'] : ['true'],
    });
  };

  const handleOptionSetToggle = (key: string, value: string) => () => {
    const fieldSelected = selected[key] || [];
    const currentIndex = fieldSelected.indexOf(value);
    let newFieldSelected = [...fieldSelected];

    if (currentIndex !== -1) {
      newFieldSelected.splice(currentIndex, 1);
      onChange({
        ...selected,
        [key]: newFieldSelected,
      });
      return;
    }
    singleSelect ? (newFieldSelected = [value]) : newFieldSelected.push(value);

    onChange({
      ...(singleSelect ? omit(selected, keys) : selected),
      [key]: newFieldSelected,
    });
  };

  return (
    <>
      <ListItem className={classes.titleItem} disableGutters dense>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            variant: 'body1',
            className: classes.titleText,
          }}
        />
      </ListItem>
      {rows.map(row => {
        switch (row.type) {
          case FunctionalityFilterRow.Boolean:
            return (
              <BooleanRow
                onToggle={handleBooleanToggle(
                  row.field.key,
                  row.inverse || false,
                )}
                selected={selected[row.field.key] || []}
                row={row}
                key={row.field.key}
              />
            );
          case FunctionalityFilterRow.OptionSet:
            return (
              <OptionSetRow
                onToggle={handleOptionSetToggle}
                selected={selected[row.field.key] || []}
                row={row}
                key={row.field.key}
              />
            );
        }
      })}
    </>
  );
};
