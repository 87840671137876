import * as en from './en';
import * as no from './no';

export const schema = en;
export type TranslationSchema = typeof en;

export const resources = {
  en,
  no,
} as const;
