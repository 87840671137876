import { ZIndex } from '@material-ui/core/styles/zIndex';

declare module '@material-ui/core/styles/zIndex' {
  interface ZIndex {
    menuBar: number;
  }
}

export const zIndex: ZIndex = {
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
  menuBar: 1600,
};
