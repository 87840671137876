import React, { FC } from 'react';
import { ApolloProvider as BaseApolloProvider } from '@apollo/client/react';

import { useApolloClient } from '../setup/useApolloClient';
import { useCurrentTenantId } from '../../inventory/context/TenantContext';

export const ApolloProvider: FC = ({ children }) => {
  const tenantId = useCurrentTenantId();
  const client = useApolloClient(tenantId);

  return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>;
};
