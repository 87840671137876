import { FieldDefinition } from '../../common/components/Form/types';
import { AdSkuTemplatePayload, SkuTemplatePayload } from '../graphql/types';

export type FieldOverrides = Record<string, Partial<FieldDefinition>>;

const resolveField = (
  field:
    | SkuTemplatePayload['fields'][number]
    | AdSkuTemplatePayload['fields'][number],
): FieldDefinition => {
  switch (field.type) {
    case 'OptionSetFieldDefinition': {
      const { description, defaultValue, required } = field;
      return {
        type: 'SelectFieldDefinition',
        options:
          field.optionSet?.values.map(value => ({
            label: value,
            value,
          })) || [],
        description,
        defaultValue,
        required,
      };
    }
    case 'BooleanFieldDefinition': {
      const { description, boolDefaultValue, required, type } = field;
      return {
        type,
        description,
        defaultValue: boolDefaultValue,
        required,
      };
    }
    case 'NumberFieldDefinition': {
      const { description, numberDefaultValue, required, type } = field;
      return {
        type,
        description,
        defaultValue: numberDefaultValue,
        required,
      };
    }
    default: {
      const { description, defaultValue, required, type } = field;
      return {
        type,
        description,
        defaultValue,
        required,
      };
    }
  }
};

export const resolveFieldWithOverrides = (
  field:
    | SkuTemplatePayload['fields'][number]
    | AdSkuTemplatePayload['fields'][number],
  overrides: Partial<FieldDefinition>,
): FieldDefinition => {
  const resolved = resolveField(field);
  return {
    ...resolved,
    ...overrides,
  } as FieldDefinition;
};

export const resolveFields = (
  fields:
    | SkuTemplatePayload['fields']
    | (AdSkuTemplatePayload['fields'] & { tenant: FieldDefinition }),
  overrides: Record<string, Partial<FieldDefinition>>,
  filterKeys?: readonly string[],
): Record<string, FieldDefinition> => {
  return fields.reduce((result: Record<string, FieldDefinition>, field) => {
    if (filterKeys && !filterKeys.includes(field.name)) {
      return result;
    }
    result[field.name] = resolveFieldWithOverrides(
      field,
      overrides[field.name],
    );
    return result;
  }, {});
};
