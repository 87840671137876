import React, { FC, useMemo } from 'react';
import { useUnmount } from 'react-use';

import { StepId } from '../RegistrationView/types';
import { useNavigation } from '../../../common/navigation';
import { useNewSkuActions } from '../../redux/newSku';
import { makeUseStepState } from '../../utils/wizard';
import { Wizard } from '../../../common/components/Wizard';

import { WelcomeStep } from './steps/WelcomeStep';
import { LocationStep } from './steps/LocationStep';
import { AdPickStep } from './steps/AdPickView';

export const steps = [
  {
    id: StepId.Location,
    component: LocationStep,
  },
  {
    id: StepId.Welcome,
    component: WelcomeStep,
  },
  {
    id: StepId.AdPick,
    component: AdPickStep,
  },
];

export const RegistrationView: FC = () => {
  const nav = useNavigation();
  const { reset } = useNewSkuActions();

  const useStepState = useMemo(() => makeUseStepState(nav.registration), [nav]);

  useUnmount(() => reset());

  return <Wizard steps={steps} extraProps={{}} useStepState={useStepState} />;
};
