const error = (
  typename: string,
  message: string,
  props?: Record<string, unknown>,
) => ({
  message,
  ...props,
  __typename: typename,
});

export const clientError = (message: string) => error('ClientError', message);

export const networkError = (message: string) => error('NetworkError', message);

export const emptyDataError = (message: string) =>
  error('EmptyDataError', message);

export const mutationError = (message: string) =>
  error('MutationError', message);
