import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      padding: 0,
    },
    selectedCheckboxLabel: {
      fontWeight: 700,
    },
    listItemIcon: {
      minWidth: 24,
    },
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
);

export type CheckboxListItemProps = {
  selected: boolean;
  item: {
    label: string;
    value: string;
  };
  name: string;
  singleSelect?: boolean;
  handleToggle: () => void;
};

export const CheckboxListItem: React.FC<CheckboxListItemProps> = ({
  item,
  name,
  selected,
  singleSelect = false,
  handleToggle,
}) => {
  const classes = useStyles();
  const labelId = `checkbox-list-${name}-label-${item.value}`;
  return (
    <ListItem
      className={classes.root}
      key={item.value}
      role={undefined}
      dense
      button
      onClick={handleToggle}
    >
      {!singleSelect && (
        <ListItemIcon className={classes.listItemIcon}>
          <Checkbox
            className={classes.checkbox}
            size="small"
            edge="start"
            checked={selected}
            tabIndex={-1}
            disableRipple
            color="primary"
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
      )}
      <ListItemText
        id={labelId}
        primary={item.label}
        primaryTypographyProps={{
          className: selected ? classes.selectedCheckboxLabel : undefined,
          variant: 'body1',
        }}
      />
    </ListItem>
  );
};
