import React, { ChangeEvent, MouseEvent, FC, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { SkuInput } from '../../../graphql';
import { RedirectTarget } from '../../../containers/NewSkuView/steps/SummaryStep';
import { GraphQlError } from '../../../../common/types';
import { FormFeedback } from '../../../../common/components/FormFeedback';
import { SkuPreview } from '../SkuPreview/SkuPreview';
import { WhiteCard } from '../../../../common/components/WhiteCard';
import { FunctionalityPreviewProps } from '../SkuPreview/FunctionalityPreview';
import { ResolvedImage } from '../../../types';
import { ColorHelper } from '../../../context/useColorMapping';

import { EditDialog } from './EditDialog';

type Props = {
  skuInput: SkuInput;
  backLabel: string;
  colorHelper: ColorHelper;
  images: ResolvedImage[];
  functionality?: FunctionalityPreviewProps;
  number: number;
  totalCount: number;
  loading?: boolean;
  error?: GraphQlError;
  onGoToStep: (stepId: string) => void;
  onSubmit: (target: RedirectTarget) => void;
  onGoBack?: () => void;
  onDescriptionChange: (description: string) => void;
};

export const SummaryStep: FC<Props> = ({
  backLabel,
  colorHelper,
  error,
  images,
  skuInput,
  functionality,
  loading,
  number,
  totalCount,
  onGoToStep,
  onGoBack,
  onSubmit,
  onDescriptionChange,
}) => {
  const { t, tk } = useTranslation('inventory');
  const [showEditDialog, setShowEditDialog] = useState(false);

  const skuPreviewData = useMemo(
    () => ({
      ...skuInput,
      primaryColor: skuInput.primaryColor
        ? colorHelper(skuInput.primaryColor)
        : null,
      secondaryColor: skuInput.secondaryColor
        ? colorHelper(skuInput.secondaryColor)
        : null,
    }),
    [skuInput, colorHelper],
  );

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onDescriptionChange(event.target.value);
  };

  const handleEditClick = (event: MouseEvent) => {
    event.preventDefault();
    setShowEditDialog(true);
  };

  const handleHideEditDialog = () => {
    setShowEditDialog(false);
  };

  return (
    <WizardStep
      backLabel={backLabel}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      showProgress={false}
      title={t(tk.newSkuView.summaryStep.title)}
      data-testid="SummaryStep"
    >
      {showEditDialog && (
        <EditDialog
          open={true}
          onClose={handleHideEditDialog}
          skuInput={skuInput}
          functionality={functionality}
          onGoToStep={onGoToStep}
        />
      )}
      <FormFeedback error={error} />
      <Box mb={2}>
        <SkuPreview
          sku={skuPreviewData}
          images={images}
          functionality={functionality}
        />
      </Box>
      <WhiteCard variant="outlined">
        <CardContent>
          <Typography variant="body1" gutterBottom>
            {t(tk.newSkuView.summaryStep.descriptionLabel)}
          </Typography>
          <TextField
            variant="outlined"
            value={skuInput.description || ''}
            onChange={handleDescriptionChange}
            rows={3}
            multiline
            fullWidth
            name="description"
            placeholder={t(tk.newSkuView.summaryStep.descriptionPlaceholder)}
          />
        </CardContent>
      </WhiteCard>
      <Box mt={3} display="flex" justifyContent="center">
        <Link href="#" onClick={handleEditClick}>
          {t(tk.newSkuView.summaryStep.goBackAndEdit.link)}
        </Link>
      </Box>
      <Box mt={4}>
        <Button
          disabled={loading}
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={() => onSubmit('register')}
        >
          {t(tk.newSkuView.summaryStep.saveAndRegister)}
        </Button>
      </Box>
      <Box mt={2}>
        <Button
          disabled={loading}
          fullWidth
          size="large"
          color="secondary"
          variant="outlined"
          onClick={() => onSubmit('finish')}
        >
          {t(tk.newSkuView.summaryStep.saveAndFinish)}
        </Button>
      </Box>
    </WizardStep>
  );
};
