import React, { useMemo, useState } from 'react';
import Box from '@material-ui/core/Box/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link/Link';

import { Maybe } from '../types';
import { ColorMapping } from '../../inventory/context/useColorMapping';

import { Swatch } from './Swatch';
import { SwatchProps } from './Swatch/common';

type Props = {
  selected?: Maybe<string>;
  colorMapping: ColorMapping;
  showMoreLabel: string;
  onChange: (colorKey: string) => void;
  SwatchProps?: Partial<SwatchProps>;
};

const DEFAULT_COUNT = 5;

export const SwatchSelect: React.FC<Props> = ({
  selected,
  colorMapping,
  showMoreLabel,
  onChange,
  SwatchProps,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);

  const items = useMemo(() => {
    const items = Object.entries(colorMapping);
    if (expanded) {
      return items;
    }
    const selectedIndex = items.findIndex(([key]) => key === selected);
    if (selectedIndex >= DEFAULT_COUNT) {
      return items.slice(0, DEFAULT_COUNT - 1).concat([items[selectedIndex]]);
    }
    return items.slice(0, DEFAULT_COUNT);
  }, [colorMapping, selected, expanded]);

  return (
    <div {...props}>
      <Grid container>
        {items.map(([key, { hex, label }]) => (
          <Grid item key={key}>
            <Swatch
              selected={key === selected}
              color={hex}
              size="medium"
              key={key}
              label={label}
              onClick={() => onChange(key)}
              withBorder
              {...(SwatchProps || {})}
            />
          </Grid>
        ))}
      </Grid>
      {!expanded && (
        <Box mt={2} ml={1.5}>
          <Link href="#" onClick={() => setExpanded(true)}>
            {showMoreLabel}
          </Link>
        </Box>
      )}
    </div>
  );
};
