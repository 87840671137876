import { makeStyles } from '@material-ui/core/styles';

export const useFlexRoot = makeStyles({
  '@global': {
    // The line below is done like this to avoid conflict in typescript with
    // new private fields that are accessed with # and still selecting element
    // by id
    // eslint-disable-next-line no-useless-concat
    ['#' + 'root']: {
      display: 'flex',
      flexFlow: 'column nowrap',
      position: 'relative',
      minHeight: '100vh',
      '@media print': {
        display: 'block',
      },
    },
  },
});
