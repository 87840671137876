import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { useNewSkuActions } from '../../../redux/newSku';
import { FunctionalityStep as FunctionalityStepComponent } from '../../../components/NewSkuView/FunctionalityStep';
import * as selectors from '../../../redux/newSku/selectors';
import {
  FunctionalityValue,
  useResolvedFunctionalityConfig,
} from '../../../models';
import { useSkuTemplate } from '../../../context/useSkuTemplate';
import { StepId } from '../types';

export const FunctionalityStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onGoBack,
  onGoToStep,
  onStepCompleted,
}) => {
  const backLabel = useSelector(selectors.label);
  const skuTemplate = useSkuTemplate();
  const initialValues = useSelector(selectors.functionalityValues);
  const subcategoryKey = useSelector(selectors.subcategoryKey);
  const { setFunctionalityValues } = useNewSkuActions();
  const config = useResolvedFunctionalityConfig(
    subcategoryKey || '',
    skuTemplate.fields,
  );

  useEffect(() => {
    if (!subcategoryKey) {
      onGoToStep(StepId.Subcategory);
    }
  }, [onGoToStep, subcategoryKey]);

  const handleSubmit = (values: Record<string, FunctionalityValue>) => {
    setFunctionalityValues(values);
    onStepCompleted();
  };

  if (isEmpty(initialValues)) {
    onStepCompleted();
  }

  if (!config) {
    return null;
  }

  return (
    <FunctionalityStepComponent
      config={config}
      backLabel={backLabel}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
    />
  );
};
