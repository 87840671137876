import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import pick from 'lodash/pick';

import { StepComponentProps } from '../../../../../common/components/Wizard/types';
import { ModelStep as ModelStepComponent } from '../../../../components/NewSkuView/ModelStep';
import * as selectors from '../../../../redux/newAdSku/selectors';
import { AdSkuInput } from '../../../../graphql';
import { FormFields } from '../../../../../common/components/Form/types';
import { useNewAdSkuActions } from '../../../../redux/newAdSku';
import { useAdSkuFormFields } from '../../../../forms/useAdSkuForm';
import { useAssertCategory } from '../../useAssertCategory';

export const fieldNames = ['manufacturer', 'model'] as const;

const isNewManufacturer = (brand: string, fields: FormFields) => {
  const field = fields.manufacturer;
  if (!field || field.type !== 'SelectFieldDefinition') {
    return true;
  }
  return (
    typeof field.options === 'function' ||
    !field.options.find(o => o.value === brand)
  );
};

export const ModelStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onGoBack,
  onGoToStep,
  onStepCompleted,
}) => {
  const { setModelData } = useNewAdSkuActions();
  const subcategoryName = useSelector(selectors.subcategoryName);
  const adSkuInput = useSelector(selectors.adSkuInput);

  const fields = useAdSkuFormFields(fieldNames);

  const handleSubmit = (data: Pick<AdSkuInput, 'manufacturer' | 'model'>) => {
    const isNew = isNewManufacturer(data.manufacturer, fields);
    setModelData(data.manufacturer, data.model, isNew);
    onStepCompleted();
  };

  const initialValues = pick(adSkuInput, fieldNames);

  useAssertCategory(onGoToStep);

  if (!subcategoryName) {
    return null;
  }
  return (
    <ModelStepComponent
      backLabel={subcategoryName}
      fields={fields}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
    />
  );
};
