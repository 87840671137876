import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useOffices } from '../../../context/useOffices';
import { useOfficeActions } from '../../../redux/office';
import { LocationDialog as LocationDialogComponent } from '../../../components/NewSkuView/LocationDialog';
import * as selectors from '../../../redux/office/selectors';
import { LocationDialogProps } from '../../../components/RegistrationView/WelcomeStep';

export const LocationDialog: FC<LocationDialogProps> = ({ onClose, open }) => {
  const offices = useOffices();
  const { setOfficeId } = useOfficeActions();
  const initialValue = useSelector(selectors.officeId);

  const handleSubmit = (officeId: string) => {
    setOfficeId(officeId);
    onClose();
  };

  return (
    <LocationDialogComponent
      initialValue={initialValue}
      open={open}
      offices={offices}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
