import React, { FC, useState } from 'react';

import {
  AdSkuInstancesQuery,
  RangeFilter,
  UnionFilter,
  useAdSkuInstancesQuery,
} from '../../graphql';
import { ErrorView } from '../../../common/components/ErrorView';
import { useNavigation, useSelectedFilters } from '../../../common/navigation';
import { extractQuery } from '../../../common/utils/extractQuery';
import { AdSkuInstancesPayload } from '../../graphql/types';
import { getPageCount } from '../../utils/getPageCount';
import { adSkuInstanceTemplateName } from '../../consts';
import { SkuInstanceListView as SkuInstanceListViewComponent } from '../../components/SkuInstanceListView';
import { SelectedFilters } from '../../utils/useFilters';
import { ResolvedAdSku } from '../../types';
import { AdSkuItems } from '../../components/SkuInstanceListView/SkuInstanceList/SkuItemsVariant/AdSkuItems';
import {
  filterRangeValueQueryFilters,
  filterSingleValueQueryFilters,
  getRangeFiltersQuery,
  getUnionFiltersQuery,
} from '../../utils/adSku';

import { useAdSkuInstanceFilters } from './useAdSkuInstanceFilters';

type Props = {
  adSku: ResolvedAdSku;
};

const PAGE_LIMIT = 16;

export const AdSkuInstanceListView: FC<Props> = ({ adSku }) => {
  const nav = useNavigation();
  const selectedFilters = useSelectedFilters();

  const setSelectedFilters = (newFilters: SelectedFilters) => {
    return nav.adSku({ id: adSku.id, filters: newFilters }).go();
  };

  const { filters, queryFilters } = useAdSkuInstanceFilters(selectedFilters);
  const [page, setPage] = useState(1);

  const singleValueFilters = queryFilters.filter(filterSingleValueQueryFilters);
  const rangeFilters = queryFilters.filter(filterRangeValueQueryFilters);
  let rangeFiltersQuery: RangeFilter[] = [];
  let unionFiltersQuery: UnionFilter[] = [];

  if (Array.isArray(rangeFilters) && rangeFilters.length > 0) {
    rangeFiltersQuery = getRangeFiltersQuery({
      filters: rangeFilters,
      name: 'salesPrice',
      min: '0',
      max: '9999999999',
    });

    unionFiltersQuery = getUnionFiltersQuery({
      filters: rangeFilters,
      name: 'salesPeriod',
      fromField: 'salesPeriodTimestampFrom',
      toField: 'salesPeriodTimestampTo',
      defaultFromValue: '946714748',
      defaultToValue: '2147483647',
    });
  }

  const result = extractQuery<AdSkuInstancesQuery, AdSkuInstancesPayload>(
    useAdSkuInstancesQuery({
      variables: {
        filters: [
          ...singleValueFilters,
          { field: 'adSkuId', values: [adSku.id], includeRelated: false },
        ],
        rangeFilters: rangeFiltersQuery,
        unionFilters: unionFiltersQuery,
        slice: {
          offset: (page - 1) * PAGE_LIMIT,
          limit: PAGE_LIMIT,
        },
      },
    }),
    'AdSkuInstanceListWithMetadata',
    data => data.adSkuInstances,
  );

  if (result.error) {
    return <ErrorView error={result.error} />;
  }

  const handleFilterChange = (field: string, values: string[]) => {
    setPage(1);
    setSelectedFilters({
      ...selectedFilters,
      [field]: values,
    });
  };

  const handleRangeFilterChange = (
    field: string,
    from: number | string | undefined,
    to: number | string | undefined,
  ) => {
    if (from === '' && to === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [field]: removed, ...selectedFiltersRest } = selectedFilters;
      return setSelectedFilters(selectedFiltersRest);
    }
    return setSelectedFilters({
      ...selectedFilters,
      [field]: [`from:${from}`, `to:${to}`],
    });
  };

  const pageCount = getPageCount(
    result.data?.metadata.matches,
    adSkuInstanceTemplateName,
    PAGE_LIMIT,
  );

  return (
    <SkuInstanceListViewComponent
      filters={filters}
      loading={result.loading}
      onFilterChange={handleFilterChange}
      onRangeFilterChange={handleRangeFilterChange}
      selectedFilters={selectedFilters}
      onPageChange={setPage}
      page={page}
      pageCount={pageCount}
      matches={result.data?.metadata.matches}
      sku={adSku}
      onRegister={() => nav.newAdSkuInstance({ adSkuId: adSku.id }).go()}
      items={<AdSkuItems skuInstances={result.data?.items || []} nav={nav} />}
    />
  );
};
