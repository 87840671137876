import { useContext, useMemo } from 'react';
import { mapValues } from 'lodash';

import { fallbackColor } from '../consts';
import { useTranslation } from '../../translations';

import { InventoryContext } from './InventoryContext';

export type ColorDefinition = {
  key: string;
  hex: string;
  thumb: string | null;
  label: string;
};

export type ColorMapping = Record<string, ColorDefinition>;

export type ColorHelper = (key: string) => ColorDefinition;

export const useColorHelper = (): ColorHelper => {
  const context = useContext(InventoryContext);
  const { t, tk } = useTranslation('optionSets');
  const mapping = context.colorMapping;

  return useMemo(
    () => (key: string) => ({
      key,
      hex: mapping[key] || fallbackColor,
      label: tk.colors[key as keyof typeof tk.colors]
        ? t(tk.colors[key as keyof typeof tk.colors])
        : key,
      thumb: null,
    }),
    [mapping, t, tk],
  );
};

export const useColorMapping = (): ColorMapping => {
  const colorHelper = useColorHelper();
  const context = useContext(InventoryContext);
  const mapping = context.colorMapping;
  return useMemo(() => mapValues(mapping, (_, key) => colorHelper(key)), [
    mapping,
    colorHelper,
  ]);
};
