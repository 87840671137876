import React, { FC, Fragment } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem/ListItem';
import List from '@material-ui/core/List/List';
import ListItemText from '@material-ui/core/ListItemText';

import { getGroupLabel, useFunctionalityConfig } from '../../../models';
import { FunctionalityValue } from '../../../models/types';
import { useTranslation } from '../../../../translations';

import { AccordionWrapper } from './AccordionWrapper';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: 0,
      width: '100%',
    },
    secondary: {
      color: theme.palette.primary.veryLight,
    },
    divider: {
      backgroundColor: theme.palette.background.alternative,
      '&:last-child': {
        display: 'none',
      },
    },
  }),
);

export type FunctionalityPreviewProps = {
  expandable?: boolean;
  subcategoryKey: string;
  values: Record<string, FunctionalityValue>;
};

export const FunctionalityPreview: FC<FunctionalityPreviewProps> = ({
  expandable,
  subcategoryKey,
  values,
}) => {
  const { t, tk } = useTranslation('inventory');
  const config = useFunctionalityConfig(subcategoryKey);
  const classes = useStyles();

  if (!config) {
    return null;
  }

  const content = (
    <List className={classes.root} dense>
      {config.groups.map(group => {
        const { label, name, hideRule } = group;
        if (hideRule && hideRule(values)) {
          return null;
        }
        const chipLabel = getGroupLabel(group, values);
        if (!chipLabel || !chipLabel.length) {
          return null;
        }
        return (
          <Fragment key={name}>
            <ListItem>
              <ListItemText
                primary={label}
                secondary={chipLabel}
                secondaryTypographyProps={{ className: classes.secondary }}
              />
            </ListItem>
            <Divider className={classes.divider} component="li" />
          </Fragment>
        );
      })}
    </List>
  );
  if (!expandable) {
    return content;
  }

  return (
    <AccordionWrapper
      expandTitle={t(tk.newSkuView.summaryStep.showFunctionality)}
      hideTitle={t(tk.newSkuView.summaryStep.hideFunctionality)}
    >
      {content}
    </AccordionWrapper>
  );
};
