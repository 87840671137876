import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDropzone } from 'react-dropzone';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RootRef from '@material-ui/core/RootRef';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { ResolvedImage } from '../../../types';
import { SkuImage } from '../../common/SkuImage';
import { LoadingIndicator } from '../../../../common/components/LoadingIndicator';
import { acceptedImages } from '../../../consts';

const useStyles = makeStyles(theme =>
  createStyles({
    dropzone: {
      borderRadius: 8,
      width: 180,
      height: 240,
      margin: theme.spacing(0, 'auto', 4),
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.secondary.veryLight,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    image: {
      borderRadius: 8,
      display: 'block',
      height: 240,
      margin: theme.spacing(0, 'auto', 4),
    },
    button: {
      borderRadius: 20,
    },
  }),
);

type Props = {
  backLabel?: string;
  images: ResolvedImage[];
  loading?: boolean;
  required?: boolean;
  number: number;
  totalCount: number;
  onChange: (file: File) => void;
  onSubmit: () => void;
  onGoBack?: () => void;
};

export const ImageStep: FC<Props> = ({
  backLabel,
  images,
  loading = false,
  required,
  number,
  totalCount,
  onChange,
  onSubmit,
  onGoBack,
}) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedImages,
    maxFiles: 1,
    onDrop: files => onChange(files[0]),
  });

  const submitDisabled = loading || (!images.length && required);
  const image = images[0];

  const { ref, ...rootProps } = getRootProps();

  return (
    <WizardStep
      backLabel={backLabel}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      title={t(tk.newSkuView.imageStep.title)}
      data-testid="ImageStep"
    >
      {image && !loading ? (
        <>
          <SkuImage image={image} className={classes.image} />
          <RootRef rootRef={ref}>
            <Button
              {...rootProps}
              className={classes.button}
              color="secondary"
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
            >
              <input {...getInputProps()} />
              <Typography variant="body1">
                {t(tk.newSkuView.imageStep.changeDescription)}
              </Typography>
            </Button>
          </RootRef>
        </>
      ) : (
        <div ref={ref} {...rootProps} className={classes.dropzone}>
          <div>
            <input {...getInputProps()} data-testid="ImageInput" />
          </div>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <AddIcon color="secondary" />
              <Typography variant="body1">
                {t(tk.newSkuView.imageStep.uploadDescription)}
              </Typography>
            </>
          )}
        </div>
      )}
      <Box mt={8}>
        <Button
          disabled={submitDisabled}
          onClick={onSubmit}
          fullWidth
          size="large"
          color="secondary"
        >
          {t(tk.newSkuView.imageStep.confirm)}
        </Button>
      </Box>
    </WizardStep>
  );
};
