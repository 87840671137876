import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { routes } from '../../common/navigation';
import { SkuInstanceView } from '../../inventory/containers/SkuInstanceView';
import { SkuView } from '../../inventory/containers/SkuView';
import { SkuListView } from '../../inventory/containers/SkuListView';
import { NewSkuView } from '../../inventory/containers/NewSkuView/NewSkuView';
import { NewSkuInstanceView } from '../../inventory/containers/NewSkuInstanceView';
import { ScanSkuInstanceView } from '../../inventory/containers/ScanSkuInstanceView/ScanSkuInstanceView';
import { RegistrationView } from '../../inventory/containers/RegistrationView/RegistrationView';
import { NewAdSkuView } from '../../inventory/containers/NewAdSkuView/NewAdSkuView';
import { AdSkuView } from '../../inventory/containers/AdSkuView';
import { NewAdSkuInstanceView } from '../../inventory/containers/NewAdSkuInstanceView';
import { AdSkuListView } from '../../inventory/containers/AdSkuListView';
import { AdSkuInstanceView } from '../../inventory/containers/AdSkuInstanceView';
import { AdminPanelView } from '../../admin/containers/AdminPanelView/AdminPanelView';
import { AddTenantView } from '../../admin/containers/TenantView/AddTenantView';
import { AddOfficeView } from '../../admin/containers/AddOfficeView/AddOfficeView';
import { EditTenantView } from '../../admin/containers/TenantView/EditTenantView';
import { AdSkusAddInstanceView } from '../../inventory/containers/AdSkusAddInstanceView';
import { EditOfficeView } from '../../admin/containers/EditOfficeView/EditOfficeView';

export const Routes: FC = () => {
  return (
    <Switch>
      <Route {...routes.registrationStep} component={RegistrationView} />
      <Route {...routes.registration} component={RegistrationView} />
      <Route {...routes.skuRegistrationStep} component={NewSkuView} />
      <Route {...routes.skuRegistration} component={NewSkuView} />
      <Route {...routes.adSkuRegistrationStep} component={NewAdSkuView} />
      <Route {...routes.adSkuRegistration} component={NewAdSkuView} />
      <Route {...routes.skus} component={SkuListView} />
      <Route {...routes.skusFilter} component={SkuListView} />
      <Route {...routes.adSkus} component={AdSkuListView} />
      <Route
        {...routes.adSkusAddInstance}
        render={() => <AdSkusAddInstanceView />}
      />
      <Route {...routes.adSkusFilter} component={AdSkuListView} />
      <Route
        {...routes.adSkusAddInstanceFilter}
        render={() => <AdSkusAddInstanceView />}
      />
      <Route
        {...routes.newSkuInstanceStep}
        render={props => (
          <NewSkuInstanceView skuId={props.match.params.skuId} />
        )}
      />
      <Route
        {...routes.newSkuInstance}
        render={props => (
          <NewSkuInstanceView skuId={props.match.params.skuId} />
        )}
      />
      <Route
        {...routes.newAdSkuInstanceStep}
        render={props => (
          <NewAdSkuInstanceView adSkuId={props.match.params.adSkuId} />
        )}
      />
      <Route
        {...routes.newAdSkuInstance}
        render={props => (
          <NewAdSkuInstanceView adSkuId={props.match.params.adSkuId} />
        )}
      />
      <Route
        {...routes.scanSkuInstance}
        render={props => <ScanSkuInstanceView id={props.match.params.id} />}
      />
      <Route
        {...routes.skuInstance}
        render={props => <SkuInstanceView id={props.match.params.id} />}
      />
      <Route
        {...routes.adSkuInstance}
        render={props => <AdSkuInstanceView id={props.match.params.id} />}
      />
      <Route
        {...routes.sku}
        render={props => <SkuView id={props.match.params.id} />}
      />
      <Route
        {...routes.adSku}
        render={props => <AdSkuView id={props.match.params.id} />}
      />
      <Route
        {...routes.adSkuFilter}
        render={props => <AdSkuView id={props.match.params.id} />}
      />
      <Route
        {...routes.skuFilter}
        render={props => <SkuView id={props.match.params.id} />}
      />
      <Route {...routes.adminPanel} render={() => <AdminPanelView />} />
      <Route
        {...routes.adminPanelTenant}
        render={props => <EditTenantView id={props.match.params.id} />}
      />
      <Route {...routes.adminPanelAddTenant} render={() => <AddTenantView />} />
      <Route
        {...routes.adminPanelOffice}
        render={props => <AddOfficeView tenantId={props.match.params.id} />}
      />
      <Route
        {...routes.adminPanelEditOffice}
        render={props => (
          <EditOfficeView
            tenantKey={props.match.params.id}
            id={props.match.params.officeId}
          />
        )}
      />
    </Switch>
  );
};
