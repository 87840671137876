import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { FunctionalityValue, BooleanFieldConfig } from '../../../../models';

import { PromptLabel } from './PromptLabel';
import { SelectableButton } from './SelectableButton';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),
);

type Props = {
  fields: BooleanFieldConfig[];
  promptLabel: string;
  exclusiveFields?: string[];
  values: Record<string, FunctionalityValue>;
  onChange?: (changed: Record<string, FunctionalityValue>) => void;
};

export const FunctionalityBooleanGroup: FC<Props> = ({
  fields,
  promptLabel,
  exclusiveFields,
  values,
  onChange,
}) => {
  const classes = useStyles();
  const handleClick = (key: string) => () => {
    const newValue = !values[key];
    const changed = { [key]: newValue };
    if (exclusiveFields && newValue) {
      if (exclusiveFields.includes(key)) {
        fields
          .filter(f => f.key !== key)
          .forEach(f => {
            if (values[f.key]) {
              changed[f.key] = false;
            }
          });
      } else {
        exclusiveFields.forEach(name => {
          if (values[name]) {
            changed[name] = false;
          }
        });
      }
    }
    if (onChange) {
      onChange(changed);
    }
  };

  return (
    <Box mb={5}>
      <PromptLabel>{promptLabel}</PromptLabel>
      {fields.map(({ key, editLabel }) => (
        <SelectableButton
          className={classes.button}
          key={key}
          onClick={handleClick(key)}
          selected={!!values[key]}
        >
          {editLabel}
        </SelectableButton>
      ))}
    </Box>
  );
};
