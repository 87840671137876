import omit from 'lodash/omit';

import { getInitialValues } from '../../components/AdSkuView/getInitialValues';
import { MainCategory, Maybe, Subcategory } from '../../graphql';
import { ResolvedAdSku, ResolvedImage } from '../../types';

import { actionTypes } from './actionTypes';
import { DimensionsData } from './reducer';

export const initializeFromExistingAdSku = (adSku: ResolvedAdSku) => {
  const adSkuInput = omit(getInitialValues(adSku), 'id');
  return {
    type: actionTypes.INITIALIZE_FROM_EXISTING_AD_SKU,
    adSkuInput,
  };
};

export const setColors = (
  primaryColor: string,
  secondaryColor: Maybe<string>,
) => ({
  type: actionTypes.SET_COLORS,
  primaryColor,
  secondaryColor,
});

export const setDescription = (description: string) => ({
  type: actionTypes.SET_DESCRIPTION,
  description,
});

export const setDimensions = (dimensions: DimensionsData) => ({
  type: actionTypes.SET_DIMENSIONS,
  ...dimensions,
});

export const setImages = (images: ResolvedImage[]) => ({
  type: actionTypes.SET_IMAGES,
  images,
});

export const setMainCategory = (
  mainCategory: Pick<MainCategory, 'id' | 'key' | 'name'>,
) => ({
  type: actionTypes.SET_MAIN_CATEGORY,
  mainCategory,
});

export const setModelData = (
  manufacturer: string,
  model?: string | null,
  isNewManufacturer?: boolean,
  serialNumber?: string | null,
) => ({
  type: actionTypes.SET_MODEL_DATA,
  manufacturer,
  model,
  isNewManufacturer,
  serialNumber,
});

export const setSubcategory = (
  subcategory: Pick<Subcategory, 'id' | 'key' | 'name'>,
) => ({
  type: actionTypes.SET_SUBCATEGORY,
  subcategory,
});

export const reset = () => ({
  type: actionTypes.RESET,
});

export type Actions = ReturnType<
  | typeof initializeFromExistingAdSku
  | typeof setColors
  | typeof setDescription
  | typeof setDimensions
  | typeof setImages
  | typeof setMainCategory
  | typeof setModelData
  | typeof setSubcategory
  | typeof reset
>;
