import React, { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { LogoutBar as LogoutBarComponent } from '../components/Header/LogoutBar';

type Logout = {
  showLogout: boolean;
  setShowLogout: (showLogout: boolean) => void;
};

export const LogoutContext = React.createContext<Logout>({} as Logout);

export const LogoutProvider: React.FC = ({ children }) => {
  const [showLogout, setShowLogout] = useState(true);
  const { logout } = useAuth0();
  const handleLogout = useCallback(() => {
    window.localStorage.clear();
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const handleSetShowLogoutChange = (showLogout: boolean) =>
    setShowLogout(showLogout);

  return (
    <LogoutContext.Provider
      value={{ showLogout, setShowLogout: handleSetShowLogoutChange }}
    >
      {showLogout && <LogoutBarComponent onLogout={handleLogout} />}
      {children}
    </LogoutContext.Provider>
  );
};
