import { GetOfficeQuery, useGetOfficeQuery } from '../../../inventory/graphql';
import { extractQuery } from '../../../common/utils/extractQuery';
import { OfficeQueryPayload } from '../../../inventory/graphql/types';

export const useGetOffice = (id: string, tenantKey: string) => {
  const rawGetOfficeQuery = useGetOfficeQuery({
    context: { headers: { 'x-tenant-id': tenantKey } },
    variables: { id },
  });

  const { loading, data, error } = extractQuery<
    GetOfficeQuery,
    OfficeQueryPayload
  >(rawGetOfficeQuery, 'Office', data => data.office);

  return {
    loading,
    data,
    error,
  };
};
