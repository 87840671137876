import { useMemo } from 'react';
import * as yup from 'yup';
import { TFunction } from 'i18next';

import {
  FieldDefinition,
  Options,
  SelectFieldDefinition,
} from '../../../../common/components/Form/types';
import { ResolvedOffice } from '../../../context/types';
import { Maybe, SkuInstanceInput } from '../../../graphql';
import { useSkuInstanceTemplate } from '../../../context/useSkuInstanceTemplate';
import { FieldOverrides, resolveFields } from '../../../forms/utils';
import {
  costCenter,
  procurementCost,
  dateField,
} from '../../../forms/useSkuInstanceForm';
import { useTranslation } from '../../../../translations';

const instanceFieldNames = [
  'officeFloorId',
  'condition',
  'status',
  'costCenter',
  'procurementDate',
  'procurementNumber',
  'procurementCost',
] as const;

export const preregistrationStatus = 'pre-registered';

export type FormStepData = {
  count: number;
  inputs: Pick<SkuInstanceInput, typeof instanceFieldNames[number]>[];
};

export type ProcurementFormValues = Pick<
  SkuInstanceInput,
  'costCenter' | 'procurementDate' | 'procurementNumber' | 'procurementCost'
>;

export type FormValues = ProcurementFormValues & {
  count: number;
  isPreregistration: boolean;
  isSameStatus: boolean;
  isSameLocation: boolean;
  status: Maybe<string>;
  condition: Maybe<string>;
  officeFloorId: Maybe<string>;
  inputs: Partial<Pick<SkuInstanceInput, typeof instanceFieldNames[number]>>[];
};

const getOverrides = (
  office: Maybe<ResolvedOffice>,
  t: TFunction,
): FieldOverrides => ({
  officeFloorId: {
    type: 'SelectFieldDefinition',
    required: true,
    options: office
      ? office.officeFloors.map(floor => ({
          label: floor.floorLabel,
          value: floor.id,
        }))
      : [],
    validation: yup.string().when(['isPreregistration', 'isSameFloorId'], {
      is: (isPreregistration, isSameFloorId) =>
        !isPreregistration && isSameFloorId,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  },
  condition: {
    validation: yup.string().when(['isPreregistration', 'isSameStatus'], {
      is: (isPreregistration, isSameStatus) =>
        !isPreregistration && isSameStatus,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  },
  status: {
    validation: yup.string().when(['isPreregistration', 'isSameStatus'], {
      is: (isPreregistration, isSameStatus) =>
        !isPreregistration && isSameStatus,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  },
  procurementCost: procurementCost(t),
  procurementDate: dateField('procurementDate', t),
  costCenter: costCenter(t),
});

export const useNewSkuInstanceFormFields = (
  office: Maybe<ResolvedOffice>,
  keys = instanceFieldNames,
): Record<string, FieldDefinition> => {
  const { fields } = useSkuInstanceTemplate();
  const { t } = useTranslation('inventory');

  return useMemo(() => {
    const resolvedFields = resolveFields(fields, getOverrides(office, t), keys);
    return {
      ...resolvedFields,
      status: {
        ...resolvedFields.status,
        options: ((resolvedFields.status as SelectFieldDefinition)
          .options as Options).filter(o => o.value !== preregistrationStatus),
      },
      count: {
        type: 'NumberFieldDefinition',
        required: true,
      },
      isPreregistration: {
        type: 'BooleanFieldDefinition',
      },
      isSameLocation: {
        type: 'BooleanFieldDefinition',
        disabled: values => !!values.isPreregistration,
      },
      isSameStatus: {
        type: 'BooleanFieldDefinition',
        disabled: values => !!values.isPreregistration,
      },
      inputs: {
        type: 'ArrayFieldDefinition',
        validation: yup.array().when('isSameLocation', {
          is: true,
          then: yup.array().when('isSameStatus', {
            is: true,
            then: yup.array().of(
              yup.object().shape({
                officeFloorId: yup.string(),
                condition: yup.string(),
                status: yup.string(),
              }),
            ),
            otherwise: yup.array().of(
              yup.object().shape({
                officeFloorId: yup.string(),
                condition: yup.string().required(),
                status: yup.string().required(),
              }),
            ),
          }),
          otherwise: yup.array().when('isSameStatus', {
            is: true,
            then: yup.array().of(
              yup.object().shape({
                officeFloorId: yup.string().required(),
                condition: yup.string(),
                status: yup.string(),
              }),
            ),
            otherwise: yup.array().of(
              yup.object().shape({
                officeFloorId: yup.string().required(),
                condition: yup.string().required(),
                status: yup.string().required(),
              }),
            ),
          }),
        }),
      },
    };
  }, [fields, office, t, keys]);
};
