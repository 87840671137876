import React, { FC } from 'react';

import {
  FunctionalityEditGroup,
  FunctionalityValue,
  ResolvedFunctionalityGroup,
} from '../../../models';

import { BooleanFunctionalityGroup } from './BooleanFunctionalityGroup';
import { ComplexFunctionalityGroup } from './ComplexFunctionalityGroup';

type Props = {
  group: ResolvedFunctionalityGroup;
  values: Record<string, FunctionalityValue>;
  initialValues: Record<string, FunctionalityValue>;
  onChange?: (changed: Record<string, FunctionalityValue>) => void;
};

export const FunctionalityGroup: FC<Props> = ({
  group,
  values,
  initialValues,
  onChange,
  ...props
}) => {
  const { editConfig } = group;
  if (!editConfig) {
    return null;
  }
  if (editConfig.type === FunctionalityEditGroup.Boolean) {
    return (
      <BooleanFunctionalityGroup
        group={{ ...group, editConfig }}
        values={values}
        onChange={onChange}
        {...props}
      />
    );
  }
  return (
    <ComplexFunctionalityGroup
      group={{ ...group, editConfig }}
      values={values}
      initialValues={initialValues}
      onChange={onChange}
      {...props}
    />
  );
};
