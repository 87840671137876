import { StyleRules } from '@material-ui/core/styles';
import { BackdropClassKey } from '@material-ui/core/Backdrop';
import { DialogClassKey } from '@material-ui/core/Dialog';
import { DialogActionsClassKey } from '@material-ui/core/DialogActions';
import { DialogContentClassKey } from '@material-ui/core/DialogContent';

import { breakpoints } from '../breakpoints';
import { DsModalBackground } from '../colors';

export const MuiBackdrop: Partial<StyleRules<BackdropClassKey>> = {
  root: {
    backgroundColor: DsModalBackground,
  },
};

const xsPaperMaxWidth = {
  [breakpoints.down('xs')]: {
    maxWidth: 'calc(100% - 16px)',
  },
};

export const MuiDialog: Partial<StyleRules<DialogClassKey>> = {
  paper: {
    [breakpoints.down('xs')]: {
      margin: 8,
    },
  },
  paperScrollPaper: {
    [breakpoints.down('xs')]: {
      maxHeight: 'calc(100% - 16px)',
    },
  },
  paperWidthFalse: xsPaperMaxWidth,
  paperWidthXs: xsPaperMaxWidth,
  paperWidthSm: xsPaperMaxWidth,
  paperWidthMd: xsPaperMaxWidth,
  paperWidthLg: xsPaperMaxWidth,
  paperWidthXl: xsPaperMaxWidth,
  paperFullWidth: {
    [breakpoints.down('xs')]: {
      width: 'calc(100% - 16px)',
    },
  },
};

export const MuiDialogActions: Partial<StyleRules<DialogActionsClassKey>> = {
  root: {
    justifyContent: 'center',
    padding: 24,
    [breakpoints.down('xs')]: {
      padding: 24,
    },
  },
};

export const MuiDialogContent: Partial<StyleRules<DialogContentClassKey>> = {
  root: {
    padding: '24px 40px',
    [breakpoints.down('xs')]: {
      padding: 24,
    },
  },
};
