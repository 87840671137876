import * as yup from 'yup';

import { FieldDefinition, FormFields } from './types';

const getPrimitive = (field: FieldDefinition) => {
  switch (field.type) {
    case 'DateFieldDefinition':
    case 'TextFieldDefinition':
    case 'SelectFieldDefinition':
      return yup.string();
    case 'NumberFieldDefinition':
      return yup
        .number()
        .transform((v, o) =>
          typeof o === 'string' && o.trim() === '' ? null : v,
        );
    case 'BooleanFieldDefinition':
      return yup.bool();
    case 'ArrayFieldDefinition':
      return yup.array();
  }
};

const getFieldValidation = (field: FieldDefinition) => {
  if (field.validation) {
    return field.validation;
  }
  let schema: FieldDefinition['validation'] = getPrimitive(field);
  if (field.required) {
    schema = schema.required();
  } else {
    schema = schema.nullable();
  }

  return schema;
};

export const getValidationSchema = (fields: FormFields) => {
  const schema = Object.entries(fields).reduce((schema, [name, field]) => {
    schema[name] = getFieldValidation(field);
    return schema;
  }, {} as { [key: string]: yup.Schema<unknown> });
  return yup.object().shape(schema);
};
