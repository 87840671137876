import React, { FC, ImgHTMLAttributes } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import { ResolvedSku } from '../../types';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 8,
      maxWidth: '100%',
    },
  }),
);

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  className?: string;
  image: ResolvedSku['images'][number];
};

export const SkuImage: FC<Props> = ({ image, className, ...props }) => {
  const classes = useStyles();

  return (
    <img
      className={cn(classes.root, className)}
      src={image.url}
      srcSet={image.srcSet}
      {...props}
    />
  );
};
