import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export const DsYellow = {
  main: '#FFCC49',
  light: '#FBDC87',
  veryLight: '#FBEBB8',
} as const;

export const DsBlue = {
  main: '#2C7DC9',
  light: '#A2C1E6',
  veryLight: '#D4E2EA',
} as const;

export const DsRed = {
  main: '#EA673E',
  light: '#FF9C86',
  veryLight: '#FCDFCC',
} as const;

export const DsGreen = {
  main: '#73A054',
  light: '#C5DCB0',
  veryLight: '#DDE9CA',
} as const;

export const DsBlack = {
  main: '#3A352D',
  light: '#8E8B84',
  veryLight: '#C8C4B7',
  ultraLight: '#EEEBDF',
} as const;

export const DsPurple = {
  main: '#7954A0',
  light: '#A083BE',
  veryLight: '#C4ACDE',
  ultraLight: '#DDC9F3',
} as const;

export const DsBaige = {
  main: '#FFFBEF',
} as const;

export const DsModalBackground = 'rgba(37, 37, 37, 0.9)' as const;

export const palette: PaletteOptions = {
  primary: {
    main: DsBlack.main,
    light: DsBlack.light,
    veryLight: DsBlack.veryLight,
    contrastText: DsBaige.main,
  },
  secondary: {
    main: DsYellow.main,
    light: DsYellow.light,
    veryLight: DsYellow.veryLight,
  },
  ternary: {
    main: DsGreen.light,
    light: DsGreen.veryLight,
    veryLight: DsGreen.veryLight,
    contrastText: DsBlack.main,
  },
  quaternary: {
    main: DsPurple.main,
    light: DsPurple.light,
    veryLight: DsPurple.ultraLight,
  },
  error: {
    main: DsRed.main,
    light: DsRed.light,
    veryLight: DsRed.veryLight,
  },
  info: {
    main: DsBlue.main,
    light: DsBlue.light,
    veryLight: DsBlue.veryLight,
  },
  success: {
    main: DsGreen.main,
    light: DsGreen.light,
    veryLight: DsGreen.veryLight,
  },
  background: {
    default: DsBaige.main,
    paper: DsBaige.main,
    alternative: DsBlack.ultraLight,
  },
  text: {
    primary: DsBlack.main,
    secondary: DsYellow.main,
  },
  action: {
    disabled: DsBlack.light,
  },
  divider: DsBlack.main,
};
