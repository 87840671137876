import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import { Maybe } from '../../../../common/types';
import { DsGreen, DsPurple } from '../../../../theme/colors';
import { formatPrice } from '../../../../common/utils/formatPrice';
import { useTranslation } from '../../../../translations';

const useStyles = makeStyles(theme =>
  createStyles({
    chip: {
      marginTop: theme.spacing(1),
      borderRadius: '10px',
    },
    dateChip: {
      backgroundColor: DsPurple.ultraLight,
    },
    priceChip: {
      backgroundColor: DsGreen.veryLight,
    },
  }),
);

type Props = {
  from?: Maybe<string>;
  to?: Maybe<string>;
  price?: Maybe<string>;
};
export const Chips: React.FC<Props> = ({ from, to, price }) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');

  return (
    <Box display="flex" flexDirection="column">
      {price && (
        <Chip
          className={`${classes.chip} ${classes.priceChip}`}
          size="small"
          label={
            <Typography variant="caption">
              {' '}
              <LocalOfferIcon fontSize="inherit" />
              {` ${formatPrice(price)} ${t(
                tk.newAdSkuInstanceView.summaryStep.perInstance,
              )}`}
            </Typography>
          }
        />
      )}
      {(from || to) && (
        <Chip
          className={`${classes.chip} ${classes.dateChip}`}
          size="small"
          label={
            <Typography variant="caption">
              <CalendarTodayIcon fontSize="inherit" />
              {` ${from} - ${to}`}
            </Typography>
          }
        />
      )}
    </Box>
  );
};
