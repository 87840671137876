import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
} from '../types';
import { mapFieldKeys } from '../utils';

import { getFields, TableFields } from './fields';
import { TableFunctionalities } from './types';

export const COFFEE_TABLES_SUBCATEGORY_KEY = 'coffeeTables';
export const COFFEE_TABLES_SUBCATEGORY_LABEL = 'Coffee Tables';

const defaultValues: TableFunctionalities = {
  tableAdjustableWidth: false,
  tableDrawer: false,
  tableStackable: false,
  tableFoldable: false,
};

const groups = ({
  tableDrawer,
  tableFeet,
  tableTopShape,
}: TableFields): FunctionalityGroup[] => [
  {
    name: 'feet',
    label: tableFeet.label,
    attributes: [tableFeet],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [tableFeet.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tableFeet,
        },
      ],
    },
  },
  {
    name: 'tableDrawer',
    label: tableDrawer.label,
    attributes: [tableDrawer],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      field: tableDrawer,
    },
  },
  {
    name: 'tableTopShape',
    label: tableTopShape.label,
    attributes: [tableTopShape],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [tableTopShape.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tableTopShape,
        },
      ],
    },
  },
];

export const coffeeTables = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields),
  };
};
