import React, { useEffect, FC } from 'react';

import { ErrorView } from '../../../common/components/ErrorView';
import { GraphQlError } from '../../../common/types';
import { extractQuery } from '../../../common/utils/extractQuery';
import { SkuInstanceReportView } from '../../components/SkuInstanceReportView';
import { SkuInstancesQuery, useSkuInstancesQuery } from '../../graphql';
import { SkuInstancesPayload } from '../../graphql/types';
import { ResolvedSkuWithMetadata } from '../../types';

type Props = {
  queryFilters: {
    field: string;
    values: string[];
    includeRelated: boolean;
  }[];
  sku: ResolvedSkuWithMetadata;
  onCompleted: (id: string) => void;
  onError: (error: GraphQlError) => void;
};

const PAGE_LIMIT = 10000;

export const SkuReportView: FC<Props> = ({
  queryFilters,
  sku,
  onCompleted,
  onError,
}) => {
  const result = extractQuery<SkuInstancesQuery, SkuInstancesPayload>(
    useSkuInstancesQuery({
      variables: {
        filters: [
          ...queryFilters,
          { field: 'skuId', values: [sku.sku.id], includeRelated: false },
        ],
        slice: {
          offset: 0,
          limit: PAGE_LIMIT,
        },
      },
      onCompleted: () => onCompleted(sku.sku.id),
      onError,
    }),
    'SkuInstanceListWithMetadata',
    data => data.skuInstances,
  );

  useEffect(() => {
    if (result.error) {
      onError(result.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.error]);

  if (result.error) {
    return <ErrorView error={result.error} />;
  }

  if (!result.data) {
    return null;
  }

  return (
    <SkuInstanceReportView
      sku={sku.sku}
      skuInstances={result.data.skuInstances}
    />
  );
};
