import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TFunction } from 'i18next';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { WizardStep } from '../../../../common/components/WizardStep';
import { useForm } from '../../../../common/components/Form';
import { TKeys } from '../../../../translations/keys';
import { materialUiKit } from '../../../../common/components/Form/uiKit';
import { useTranslation } from '../../../../translations';
import { Row } from '../../../../common/components/Row';
import { FormFields } from '../../../../common/components/Form/types';
import { DsPurple } from '../../../../theme/colors';
import { FormFeedback } from '../../../../common/components/FormFeedback';
import { GraphQlError } from '../../../../common/types';

import { FormStepData, FormValues } from './useNewAdSkuInstanceForm';

const useStyles = makeStyles(theme =>
  createStyles({
    greenCard: {
      backgroundColor: DsPurple.ultraLight,
      borderRadius: 16,
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3, 2),
    },
    cardTitle: {
      paddingBottom: theme.spacing(1),
    },
  }),
);

type Props = {
  onGoBack?: () => void;
  totalCount: number;
  number: number;
  fields: FormFields;
  onSubmit: (data: FormStepData) => void;
  error?: GraphQlError;
};

const makeUiKit = (t: TFunction, tk: TKeys<'inventory'>) => ({
  ...materialUiKit,
  Submit: (disabled: boolean) => {
    return (
      <Box mt={8}>
        <Button
          disabled={disabled}
          fullWidth
          size="large"
          type="submit"
          color="secondary"
          variant="contained"
        >
          {t(tk.newAdSkuInstanceView.formStep.confirm)}
        </Button>
      </Box>
    );
  },
});

export const FormStep: React.FC<Props> = ({
  fields,
  totalCount,
  onGoBack,
  number,
  onSubmit,
  error,
}) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');
  const uiKit = useMemo(() => makeUiKit(t, tk), [t, tk]);
  const { handleSubmit, renderField, renderSubmit, watch } = useForm<
    FormValues
  >({
    fields,
    defaultValues: {
      count: 1,
    },
    uiKit,
  });

  const values: FormValues = watch() as FormValues;
  const count = Number.isNaN(Number(values?.count)) ? 0 : Number(values?.count);

  const handleSubmitCallback = ({
    count,
    locationNumber,
    ...adSkuInstance
  }: FormValues) => {
    return onSubmit({
      count,
      adSkuInstance: {
        ...adSkuInstance,
        salesPeriodTimestampFrom: adSkuInstance.salesPeriodDateFrom
          ? `${new Date(adSkuInstance.salesPeriodDateFrom).getTime() / 1000}`
          : null,
        salesPeriodTimestampTo: adSkuInstance.salesPeriodDateTo
          ? `${new Date(adSkuInstance.salesPeriodDateTo).getTime() / 1000}`
          : null,
        officeId: locationNumber,
      },
    });
  };

  return (
    <WizardStep
      backLabel=""
      onGoBack={onGoBack}
      showProgress={false}
      number={number}
      totalCount={totalCount}
      title={t(tk.newAdSkuInstanceView.formStep.title)}
      data-testid="FormStep"
    >
      <Box mb={3}>
        <Typography variant="subtitle1">
          {t(tk.newAdSkuInstanceView.formStep.subtitle)}
        </Typography>
      </Box>
      <FormFeedback error={error} />
      <form onSubmit={handleSubmit(handleSubmitCallback)}>
        <Row>
          {renderField({
            name: 'locationNumber',
            gridItemProps: { xs: 12 },
            label: t(tk.newAdSkuInstanceView.formStep.location),
          })}
        </Row>
        <Row>
          {renderField({
            name: 'count',
            gridItemProps: { xs: 12 },
            label: t(tk.newSkuInstanceView.formStep.countFieldLabel),
          })}
        </Row>
        <Row>
          {renderField({
            name: 'salesPrice',
            gridItemProps: { xs: 6 },
            label: `${t(tk.newAdSkuInstanceView.formStep.price)} ${t(
              tk.newAdSkuInstanceView.formStep.optional,
            )}`,
          })}
        </Row>
        <Grid className={classes.greenCard} container>
          <Grid item>
            <Typography variant="body1" className={classes.cardTitle}>
              <strong>{`${t(tk.newAdSkuInstanceView.formStep.salePeriod)} ${t(
                tk.newAdSkuInstanceView.formStep.optional,
              )}`}</strong>
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {renderField({
                name: 'salesPeriodDateFrom',
                gridItemProps: { xs: 12 },
                label: t(tk.newAdSkuInstanceView.formStep.from),
              })}
            </Grid>
            <Grid item xs={6}>
              {renderField({
                name: 'salesPeriodDateTo',
                gridItemProps: { xs: 12 },
                label: t(tk.newAdSkuInstanceView.formStep.to),
              })}
            </Grid>
          </Grid>
        </Grid>
        <Row>
          {renderField({
            name: 'status',
            gridItemProps: { xs: 12 },
            label: t(tk.newAdSkuInstanceView.formStep.status, {
              count,
            }),
          })}
        </Row>
        <Row>
          {renderField({
            name: 'condition',
            gridItemProps: { xs: 12 },
            label: `${t(tk.newAdSkuInstanceView.formStep.condition, {
              count,
            })} ${t(tk.newAdSkuInstanceView.formStep.optional)}`,
          })}
        </Row>
        {renderSubmit()}
      </form>
    </WizardStep>
  );
};
