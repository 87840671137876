import React, { useMemo, useEffect } from 'react';
import { get } from 'react-hook-form';
import { mapValues } from 'lodash';

import { useTranslation } from '../../../translations/useTranslation';

import {
  FieldDefinition,
  FieldValues,
  UnpackNestedValue,
  UiKit,
  ResolvedFieldDefinition,
  FormFields,
  FormLayout,
  FieldConfig,
} from './types';
import { useConfiguredForm } from './useConfiguredForm';
import { materialUiKit } from './uiKit';
import { callIfCallable } from './utils';

type Props<T extends FieldValues = FieldValues> = {
  fields: FormFields;
  layout: FormLayout;
  defaultValues?: Partial<T>;
  uiKit?: UiKit<T>;
  onSubmit?: (data: UnpackNestedValue<T>) => void;
};

const prepareField = (
  field: FieldDefinition,
  values: FieldValues,
): ResolvedFieldDefinition => {
  if (field.type === 'SelectFieldDefinition') {
    return {
      ...field,
      disabled: callIfCallable(field.disabled, values),
      options: callIfCallable(field.options, values),
    };
  }
  return {
    ...field,
    disabled: callIfCallable(field.disabled, values),
  };
};

export function useForm<T extends FieldValues = FieldValues>({
  defaultValues,
  fields: rawFields,
  uiKit = materialUiKit,
}: Pick<Props<T>, 'defaultValues' | 'fields' | 'uiKit'>) {
  const form = useConfiguredForm<FieldValues>({
    defaultValues,
    fields: rawFields,
  });
  const { control, errors, formState, watch, setValue } = form;
  const values: T = watch() as T;
  const { t, tk } = useTranslation('common');
  const fields = useMemo(
    () => mapValues(rawFields, field => prepareField(field, values)),
    [rawFields, values],
  );

  useEffect(() => {
    Object.entries(fields).forEach(([name, field]) => {
      if (
        field.type === 'SelectFieldDefinition' &&
        field.strict &&
        values[name] &&
        !field.options.find(o => o.value === values[name])
      ) {
        setValue(name, '');
      }
    });
  }, [fields, setValue, values]);

  const { Field } = uiKit;
  return {
    ...form,
    renderField: ({
      name,
      inputName,
      label,
      Component,
      hidden,
      ...props
    }: FieldConfig) => {
      if (callIfCallable(hidden, values)) {
        return null;
      }
      const FieldComponent = Component || Field;
      return (
        <FieldComponent
          noValueLabel={t(tk.noValueLabel)}
          {...props}
          label={callIfCallable(label, values)}
          control={control}
          name={inputName || name}
          field={fields[name]}
          error={get(errors, inputName || name)}
          key={name}
        />
      );
    },
    renderSubmit: () => uiKit.Submit(formState.isSubmitting, values),
  };
}

export function Form<T extends FieldValues = FieldValues>({
  defaultValues,
  fields: rawFields,
  layout,
  uiKit = materialUiKit,
  onSubmit,
}: Props<T>) {
  const { renderField, handleSubmit, renderSubmit } = useForm({
    defaultValues,
    fields: rawFields,
    uiKit,
  });

  const { Row } = uiKit;
  return (
    <form onSubmit={handleSubmit(onSubmit ? onSubmit : () => undefined)}>
      {layout.map((row, idx) => {
        if (row.type === 'component') {
          return <Row key={idx}>{row.component}</Row>;
        }
        return (
          <Row key={idx}>{row.fields.map(field => renderField(field))}</Row>
        );
      })}
      {onSubmit && renderSubmit()}
    </form>
  );
}
