import React, { FC } from 'react';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { LoadingIndicator } from '../../../../common/components/LoadingIndicator';

const useStyles = makeStyles(theme =>
  createStyles({
    chip: {
      cursor: 'inherit',
      marginRight: theme.spacing(1),
    },
    list: {
      marginBottom: theme.spacing(2),
    },
  }),
);

type Props = {
  loading?: boolean;
  items: JSX.Element;
};

export const SkuInstanceList: FC<Props> = ({ items, loading }) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Box m={8}>
        <LoadingIndicator size="medium" />
      </Box>
    );
  }

  return (
    <Card elevation={0}>
      <List
        disablePadding
        className={classes.list}
        data-testid="SkuInstanceList"
      >
        {items}
      </List>
    </Card>
  );
};
