import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import cn from 'classnames';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CheckIcon from '@material-ui/icons/Check';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 8,
      marginBottom: theme.spacing(1),
    },
    error: {
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.light}`,
    },
    standard: {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    success: {
      backgroundColor: theme.palette.success.veryLight,
      border: `1px solid ${theme.palette.success.main}`,
      color: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
    },
    errorSecondary: {
      color: theme.palette.error.light,
    },
    standardSecondary: {
      color: theme.palette.primary.light,
    },
    successSecondary: {
      color: theme.palette.success.main,
    },
    text: {
      margin: 0,
    },
  }),
);

export type ButtonListItemProps = {
  variant: 'standard' | 'error' | 'success';
  primaryText: string;
  secondaryText?: string;
  withIcon?: boolean;
  onClick?: () => void;
};

export const ButtonListItem: React.FC<ButtonListItemProps> = props => {
  const classes = useStyles(props);
  const {
    variant,
    primaryText,
    secondaryText,
    withIcon,
    ...otherProps
  } = props;

  const Icon =
    variant === 'success' ? CheckIcon : withIcon ? ChevronRightIcon : null;

  const rootClassName = cn({
    [classes.root]: true,
    [classes.standard]: variant === 'standard',
    [classes.success]: variant === 'success',
    [classes.error]: variant === 'error',
  });

  const secondaryClassName = cn({
    [classes.standardSecondary]: variant === 'standard',
    [classes.successSecondary]: variant === 'success',
    [classes.errorSecondary]: variant === 'error',
  });

  return (
    <ListItem button dense={true} className={rootClassName} {...otherProps}>
      <ListItemText
        className={classes.text}
        primary={primaryText}
        primaryTypographyProps={{ variant: 'subtitle1' }}
        secondary={secondaryText}
        secondaryTypographyProps={{
          className: secondaryClassName,
          color: 'inherit',
        }}
      />
      {Icon && (
        <ListItemSecondaryAction>
          <Icon className={secondaryClassName} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
