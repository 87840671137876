import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { extractMutation } from '../../../../common/utils/extractQuery';
import { SummaryStep as SummaryStepComponent } from '../../../components/NewSkuInstanceView/SummaryStep';
import {
  CreateSkuInstanceMutation,
  Maybe,
  SkuInstanceInput,
  useCreateSkuInstanceMutation,
} from '../../../graphql';
import { CreateSkuInstanceMutationPayload } from '../../../graphql/types';
import * as officeSelectors from '../../../redux/office/selectors';
import * as selectors from '../../../redux/newSkuInstance/selectors';
import { NewInstanceStepProps, StepId } from '../types';
import { ResolvedOffice } from '../../../context/types';
import { useOffices } from '../../../context/useOffices';
import { useResolvedSkuQuery } from '../../../utils/sku';
import { LoadingIndicator } from '../../../../common/components/LoadingIndicator';
import { ErrorView } from '../../../../common/components/ErrorView';
import { useNavigation } from '../../../../common/navigation';
import {
  LastRegisteredTypes,
  useLastRegistered,
} from '../../../utils/useLastRegistered';
import { QrCodeData } from '../../../components/common/SkuInstanceScanner';

const validateSkuInstanceInput = (
  inputs: Maybe<Partial<SkuInstanceInput>>[],
  skuId: string,
  office: Maybe<ResolvedOffice>,
): Maybe<SkuInstanceInput>[] => {
  return inputs.map(input => {
    if (!input) {
      return null;
    }
    const { condition, status, officeFloorId } = input;

    if (
      !status ||
      !condition ||
      !officeFloorId ||
      !office ||
      !office.officeFloors.find(f => f.id === officeFloorId)
    ) {
      return null;
    }

    return {
      ...input,
      status,
      condition,
      officeId: office.id,
      skuId,
    };
  });
};

export const SummaryStep: FC<StepComponentProps<NewInstanceStepProps>> = ({
  extraProps: { skuId },
  number,
  totalCount,
  onGoBack,
  onGoToStep,
}) => {
  const count = useSelector(selectors.count);
  const officeId = useSelector(officeSelectors.officeId);
  const rawInputs = useSelector(selectors.inputs);
  const [completed, setCompleted] = useState<number[]>([]);
  const [loading, setLoading] = useState<number[]>([]);
  const offices = useOffices();
  const nav = useNavigation();
  const { add } = useLastRegistered(LastRegisteredTypes.sku);
  const skuQuery = useResolvedSkuQuery({
    variables: { id: skuId },
  });
  const office = officeId ? offices.find(o => o.id === officeId) : null;

  const [createSkuInstance, rawMutationResult] = useCreateSkuInstanceMutation({
    refetchQueries: ['SkuInstances'],
  });
  const mutationResult = extractMutation<
    CreateSkuInstanceMutation,
    CreateSkuInstanceMutationPayload
  >(
    rawMutationResult,
    'CreateSkuInstancePayload',
    data => data.createSkuInstance,
  );

  const inputs = validateSkuInstanceInput(rawInputs, skuId, office)
    .filter(Boolean)
    .map(input => input as SkuInstanceInput);

  useEffect(() => {
    if (!office) {
      onGoToStep(StepId.Location);
    }
    if (!count || !inputs.length) {
      onGoToStep(StepId.Form);
    }
  }, [count, office, onGoBack, onGoToStep, inputs]);

  if (skuQuery.error || !skuQuery.data) {
    if (skuQuery.loading) {
      return <LoadingIndicator />;
    }
    return <ErrorView error={skuQuery.error} />;
  }

  if (!inputs.length || !office) {
    return null;
  }

  const handleSubmit = async (idx: number, qrCodeData: QrCodeData) => {
    setLoading([...loading, idx]);
    const result = await createSkuInstance({
      variables: { input: { skuInstance: { ...inputs[idx], ...qrCodeData } } },
    });
    if (
      result.data?.createSkuInstance?.__typename === 'CreateSkuInstancePayload'
    ) {
      setCompleted([...completed, idx]);
      add(skuId);
    }
    setLoading(loading.filter(x => x !== idx));
  };

  return (
    <SummaryStepComponent
      office={office}
      sku={skuQuery.data}
      inputs={inputs}
      onGoBack={onGoBack}
      onSubmit={handleSubmit}
      error={mutationResult.error}
      loading={loading}
      completed={completed}
      number={number}
      totalCount={totalCount}
      nav={nav}
    />
  );
};
