import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';

import {
  wBoardSize,
  wBoardMaterial,
  wBoardFrame,
  wBoardColor,
  wBoardFeet,
  wBoardSided,
  wBoardFoldable,
  wBoardFlippable,
  wBoardHanger,
} from './attributeFields';

export const getFields = (t: TFunction, tk: TKeys<'functionality'>) => ({
  wBoardSize: wBoardSize(t, tk),
  wBoardMaterial: wBoardMaterial(t, tk),
  wBoardFrame: wBoardFrame(t, tk),
  wBoardColor: wBoardColor(t, tk),
  wBoardFeet: wBoardFeet(t, tk),
  wBoardSided: wBoardSided(t, tk),
  wBoardFoldable: wBoardFoldable(t, tk),
  wBoardFlippable: wBoardFlippable(t, tk),
  wBoardHanger: wBoardHanger(t, tk),
});

export type WhiteboardFields = ReturnType<typeof getFields>;
