import { AdSkuInstanceInput } from '../../graphql';

import { actionTypes } from './actionTypes';

export const setCount = (count: number) => ({
  type: actionTypes.SET_COUNT,
  count,
});

export const setInstance = (
  instance: Pick<
    AdSkuInstanceInput,
    | 'condition'
    | 'status'
    | 'officeId'
    | 'processingStage'
    | 'salesPeriodDateFrom'
    | 'salesPeriodDateTo'
    | 'salesPrice'
  >,
) => ({
  type: actionTypes.SET_INSTANCE,
  instance,
});

export const reset = () => ({
  type: actionTypes.RESET,
});

export type Actions = ReturnType<
  typeof setCount | typeof setInstance | typeof reset
>;
