import {
  FieldDefinition,
  GridItemProps,
} from '../../common/components/Form/types';

export const gridItemTwoColumn: GridItemProps = { xs: 6, md: 6 };

export const gridItemLarge: GridItemProps = { xs: 12, md: 4 };

export const gridItemStandard: GridItemProps = { xs: 6, md: 4 };

export const gridItemSmall: GridItemProps = { xs: 4, md: 2 };

export const disableFields = (fields: Record<string, FieldDefinition>) => {
  return Object.keys(fields).reduce((acc, key) => {
    const field = { ...fields[key], disabled: true };
    return { ...acc, [key]: field };
  }, {});
};
