const allowedDomains = ['reuse.gogood.no', 'reuse.dev.gogood.no'];

const domain = 'reuse.gogood.no';

export const parseQrCode = (qrCode: string) => {
  const regexp = new RegExp(
    `^https:\\/\\/(${allowedDomains.join(
      '|',
    )})\\/qr\\/([a-zA-z0-9]+)\\/([a-zA-z0-9]+)$`,
  );
  const match = qrCode.match(regexp);

  if (!match) {
    return null;
  }
  return {
    qrCode,
    gtin: match[2],
    serialNumber: match[3],
  };
};

export const generateQrCode = (gtin: string, serialNumber: string) => {
  return `https://${domain}/qr/${gtin}/${serialNumber}`;
};
