import { ResolvedOffice } from '../../context/types';
import { formatOfficeAddress } from '../../utils/format';
import { CustomFilter } from '../../utils/useFilters';

export const getLocationFilter = (
  offices: ResolvedOffice[],
  title: string,
): CustomFilter => ({
  type: 'custom',
  getFilter: () => {
    return {
      nodes: offices.map(office => ({
        name: formatOfficeAddress(office),
        items: office.officeFloors.map(floor => ({
          label: floor.floorLabel,
          value: floor.id,
        })),
      })),
      type: 'nodes',
      name: 'officeFloorId',
      title,
    };
  },
});

export const getPriceRangeFilter = (): CustomFilter => ({
  type: 'custom',
  getFilter: () => {
    return {
      nodes: [],
      type: 'range',
      name: 'salesPrice',
      title: 'Price Range',
    };
  },
});

export const getSalesPeriodFilter = (): CustomFilter => ({
  type: 'custom',
  getFilter: () => {
    return {
      nodes: [],
      type: 'range',
      name: 'salesPeriod',
      title: 'Sales Period',
    };
  },
});

export const getInstancesFilter = (title: string): CustomFilter => ({
  type: 'custom',
  getFilter: () => {
    return {
      type: 'text',
      name: 'minRelatedCount',
      title,
    };
  },
});
