import React from 'react';
import List from '@material-ui/core/List';
import { ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import Box from '@material-ui/core/Box';

import { Tenant } from '../../../inventory/graphql';
import { useTranslation } from '../../../translations';

const useStyles = makeStyles(theme =>
  createStyles({
    add: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    tenantButtons: {
      '& button': {
        marginLeft: 2,
        marginRight: 2,
      },
    },
  }),
);

type Props = {
  tenants: Pick<Tenant, 'name' | 'id' | 'displayName'>[];
  handleAddTenant: () => void;
  handleAddOffice: (tenantId: string) => void;
  handleEdit: (tenantName: string, tenantId: string) => void;
};

export const TenantsManagementView: React.FC<Props> = ({
  tenants,
  handleAddTenant,
  handleAddOffice,
  handleEdit,
}) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('admin');

  return (
    <>
      <List subheader={<ListSubheader>Tenants:</ListSubheader>}>
        {tenants
          .filter(t => t.name !== 'partner')
          .map(tenant => (
            <ListItem key={tenant.name} divider>
              <ListItemText primary={tenant.displayName || tenant.name} />
              <Box className={classes.tenantButtons}>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => handleEdit(tenant.name, tenant.id)}
                >
                  <EditIcon fontSize="small" /> {t(tk.adminPanel.edit)}
                </Button>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => handleAddOffice(tenant.name)}
                >
                  <AddLocationIcon fontSize="small" />{' '}
                  {t(tk.adminPanel.addOffice)}
                </Button>
              </Box>
            </ListItem>
          ))}
      </List>
      <>
        <Button
          color="primary"
          aria-label="add"
          className={classes.add}
          onClick={handleAddTenant}
        >
          <AddIcon /> {t(tk.adminPanel.addTenant)}
        </Button>
      </>
    </>
  );
};
