import { CurrentFilterProps } from '../../../../common/components/List/CurrentFilters';
import {
  FunctionalityFilterRow,
  ResolvedFunctionalityFilterGroup,
} from '../../../models/types';

export const getCurrentFilterProps = (
  groups: ResolvedFunctionalityFilterGroup[],
  selected: Record<string, string[]>,
): CurrentFilterProps['filters'] => {
  const result: CurrentFilterProps['filters'] = [];
  groups.forEach(group =>
    group.rows.forEach(row => {
      const key = row.field.key;
      if (!selected[key] || !selected[key].length) {
        return;
      }
      if (row.type === FunctionalityFilterRow.Boolean) {
        result.push({
          name: key,
          value: selected[key][0],
          label: `${group.label}: ${row.label || row.field.label}`,
          selected: selected[key],
        });
        return;
      }
      selected[key].forEach(val =>
        result.push({
          name: key,
          value: val,
          label: `${group.label}: ${
            row.field.options.find(o => o.value === val)?.label
          }`,
          selected: selected[key],
        }),
      );
    }),
  );
  return result;
};
