import { StyleRules } from '@material-ui/core/styles';
import { ExpansionPanelClassKey } from '@material-ui/core/ExpansionPanel';
import { ExpansionPanelSummaryClassKey } from '@material-ui/core/ExpansionPanelSummary';
import { ExpansionPanelDetailsClassKey } from '@material-ui/core';

import { DsBlack, DsYellow } from '../colors';

export const MuiExpansionPanel: Partial<StyleRules<ExpansionPanelClassKey>> = {
  root: {
    border: 0,
    margin: '16px 0',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      border: `2px solid ${DsYellow.main}`,
    },
  },
  rounded: {
    borderRadius: 4,
  },
};

export const MuiExpansionPanelSummary: Partial<StyleRules<
  ExpansionPanelSummaryClassKey
>> = {
  root: {
    '&$expanded': {
      '&:after': {
        position: 'absolute',
        bottom: -2,
        left: 24,
        right: 24,
        height: 2,
        content: '""',
        opacity: 1,
        backgroundColor: DsBlack.veryLight,
      },
    },
  },
  content: {
    margin: '24px 0',
    '&$expanded': {
      margin: '24px 0',
    },
  },
};

export const MuiExpansionPanelDetails: Partial<StyleRules<
  ExpansionPanelDetailsClassKey
>> = {
  root: {
    padding: 32,
  },
};
