import { SkuTemplatePayload } from '../inventory/graphql/types';

export const skuTemplateMock: SkuTemplatePayload = {
  id: '7dac2453-39b7-4dea-9764-5a862910f5c6',
  name: 'sku',
  fields: [
    {
      id: '6ed07b2b-89eb-4d71-a17e-5c75e95ae8c9',
      name: 'secondaryColor',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'c58acbc5-78c0-4756-8e17-7a7ba2efc1e4',
        name: 'colors',
        description: null,
        values: [
          'black',
          'darkGray',
          'lightGray',
          'white',
          'darkBlue',
          'darkGreen',
          'transparent',
          'steel',
          'lightWood',
          'darkWood',
          'redWood',
          'beige',
          'red',
          'burgundy',
          'brass',
          'bronze',
          'brushedSteel',
          'brown',
          'lightBlue',
          'orange',
          'yellow',
          'salmon',
          'turquoise',
          'mint',
          'lightGreen',
          'pink',
          'purple',
        ],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'e5f4f61e-18c9-4e88-8cfc-cd8b3b799b43',
      name: 'primaryColor',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: true,
      defaultValue: null,
      optionSet: {
        id: 'c58acbc5-78c0-4756-8e17-7a7ba2efc1e4',
        name: 'colors',
        description: null,
        values: [
          'black',
          'darkGray',
          'lightGray',
          'white',
          'darkBlue',
          'darkGreen',
          'transparent',
          'steel',
          'lightWood',
          'darkWood',
          'redWood',
          'beige',
          'red',
          'burgundy',
          'brass',
          'bronze',
          'brushedSteel',
          'brown',
          'lightBlue',
          'orange',
          'yellow',
          'salmon',
          'turquoise',
          'mint',
          'lightGreen',
          'pink',
          'purple',
        ],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '1cb27951-be06-4b3e-86cc-e61425ebfac0',
      name: 'manufacturer',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: true,
      defaultValue: null,
      optionSet: {
        id: '585558e2-c4f0-4ee2-ae9f-95256fa04190',
        name: 'manufacturers',
        description: null,
        values: [
          'Kinnarps',
          'Ikea',
          'Steelcase',
          'Herman Miller',
          'Urban Office',
          'Haworth',
          'HNI',
          'LA-Z-Boy',
          'Knoll Inc',
          'Global Furniture Group',
          'Kimball',
          'Humanscale',
        ],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '952bdfc6-6894-4e6f-a7b2-8b75568dc707',
      name: 'maxWidth',
      type: 'NumberFieldDefinition',
      description: null,
      required: false,
      numberDefaultValue: null,
      __typename: 'NumberFieldDefinition',
    },
    {
      id: '9930bbe6-584d-463b-b1da-63d67076c06a',
      name: 'maxLength',
      type: 'NumberFieldDefinition',
      description: null,
      required: false,
      numberDefaultValue: null,
      __typename: 'NumberFieldDefinition',
    },
    {
      id: 'a2ebb5ac-d801-4dca-9b83-ba4fea8b4754',
      name: 'maxHeight',
      type: 'NumberFieldDefinition',
      description: null,
      required: false,
      numberDefaultValue: null,
      __typename: 'NumberFieldDefinition',
    },
    {
      id: 'a2ebb5ac-d801-4dca-9b83-bv4fea8b4754',
      name: 'minSeatHeight',
      type: 'NumberFieldDefinition',
      description: null,
      required: false,
      numberDefaultValue: null,
      __typename: 'NumberFieldDefinition',
    },
    {
      id: 'a2ebb5ac-d801-4dca-9b83-br4fea8b4754',
      name: 'maxSeatHeight',
      type: 'NumberFieldDefinition',
      description: null,
      required: false,
      numberDefaultValue: null,
      __typename: 'NumberFieldDefinition',
    },
    {
      id: 'a2ebb5ac-d801-4bca-9b83-bv4fea8b4754',
      name: 'minDepth',
      type: 'NumberFieldDefinition',
      description: null,
      required: false,
      numberDefaultValue: null,
      __typename: 'NumberFieldDefinition',
    },
    {
      id: 'a2ebb5ac-d801-4d4a-9b83-bv4fea8b4754',
      name: 'maxDepth',
      type: 'NumberFieldDefinition',
      description: null,
      required: false,
      numberDefaultValue: null,
      __typename: 'NumberFieldDefinition',
    },
    {
      id: '2e4b9048-4ef2-470f-867c-16300fa46969',
      name: 'minWidth',
      type: 'NumberFieldDefinition',
      description: null,
      required: false,
      numberDefaultValue: null,
      __typename: 'NumberFieldDefinition',
    },
    {
      id: 'd151136f-27c4-4fe0-a3cf-38a069f1660a',
      name: 'minLength',
      type: 'NumberFieldDefinition',
      description: null,
      required: false,
      numberDefaultValue: null,
      __typename: 'NumberFieldDefinition',
    },
    {
      id: '58a27073-6058-4357-b544-a0df3375649c',
      name: 'minHeight',
      type: 'NumberFieldDefinition',
      description: null,
      required: false,
      numberDefaultValue: null,
      __typename: 'NumberFieldDefinition',
    },
    {
      id: 'cdbd4f88-2105-4080-b6a3-a7e35cd59a71',
      name: 'model',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'f4222e25-1966-4f81-8a63-a925a9b7b179',
      name: 'subcategoryId',
      type: 'TextFieldDefinition',
      description: null,
      required: true,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '0a78a0b8-7b80-44da-947a-26a1e5c142b4',
      name: 'serialNumber',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '9d65b714-a1db-49cc-b819-85c5792485b2',
      name: 'mainCategoryId',
      type: 'TextFieldDefinition',
      description: null,
      required: true,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '86810168-94b7-485b-b197-846a52d8bf77',
      name: 'description',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '2f3c90bc-37ca-4ea8-b894-04135a20a618',
      name: 'images',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'bceccf68-352f-45ce-ae7d-1d956756b28f',
      name: 'chairBack',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '4ca82fa0-3486-4d5f-9d16-36cb880da464',
      name: 'chairBackFixed',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '738749c2-e993-474d-8ff7-788ae5479300',
      name: 'chairBackAdjustableHeight',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'a1e44436-e41b-4d5c-8492-c19ae82ea140',
      name: 'chairBackAdjustableAngle',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'f59e4105-36db-4f86-869c-24406dc28585',
      name: 'chairLeaningBack',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'fca09a9f-e008-4545-a450-eb28469e6dc1',
      name: 'chairSeat',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'c1dc8c82-c514-4efd-b433-7759d750a49a',
      name: 'chairSeatFixed',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '1a2e5726-5afe-4868-a438-e03c04b65065',
      name: 'chairSeatAdjustablePosition',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '446af42b-0cc2-4ecb-b686-bf7d2b455544',
      name: 'chairSeatAdjustableAngle',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '24b0ade5-e74e-415a-bc17-369e4e6c8237',
      name: 'chairArmrests',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '692da5c2-c9ee-42b3-9ff1-fc9df0e16f69',
      name: 'chairArmrestsFixed',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '40033058-225b-4f79-80e6-f9e80889fd4e',
      name: 'chairArmrestsAdjustablePosition',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '0caa28b8-b76c-4bdd-94c6-ba03a47e84bb',
      name: 'chairArmrestsAdjustableHeight',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '3673263c-cfbc-4d5d-a94e-146b67e0c6b9',
      name: 'chairArmrestsAdjustableWidth',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'd791f8c2-2ac1-4132-9c64-84537d7d6a66',
      name: 'chairArmrestsAdjustableLength',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'b58eee63-5762-4b7a-ab4a-0993caa1261a',
      name: 'chairFootring',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'b986d72d-27d6-47d6-8c56-81ff9b85b8fa',
      name: 'chairFoldable',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '6757a26d-93b9-4460-acae-7f0d66b53823',
      name: 'chairStackable',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'de6b060b-77cd-4bc6-96fc-971d753f8c78',
      name: 'chairAdjustableHeight',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '1c95d931-3e65-4269-9542-7d53f14989ab',
        name: 'chairAdjustableHeights',
        description: null,
        values: ['fixed', 'standard', 'long'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '610ac874-4246-4c02-a11f-c3aab0e9b4cf',
      name: 'chairBackLength',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '4502cbf6-3aeb-49d6-8f60-02a2282711f3',
        name: 'chairBackLengths',
        description: null,
        values: ['standard', 'long'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '630bcbe7-6869-4971-af16-978bb6682569',
      name: 'chairFeetCount',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'a69502b5-2e1a-42d4-8ab3-ea203e82a4f5',
        name: 'chairFeetCounts',
        description: null,
        values: ['4', '5'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '6a1eb669-1a14-4943-a94e-4f78d6397184',
      name: 'chairFeetType',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '13b448c6-0160-4700-a29c-e49a73c6a3d4',
        name: 'chairFeetTypes',
        description: null,
        values: ['feet', 'hardWheels', 'softWheels', 'hybridWheels'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '3931c639-6d73-4cca-be8e-5856cbadefc9',
      name: 'storageDoorsMechanism',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '33023b7e-dac7-40a5-9361-f22fceb9f991',
        name: 'storageDoorsMechanisms',
        description: null,
        values: ['sliding', 'hinged', 'pullOut'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '71c6ca6b-7a52-4ff7-ab96-60be1650b762',
      name: 'storageShelvesType',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '24ee2740-f304-46c9-aca6-31666eb118a0',
        name: 'storageShelvesTypes',
        description: null,
        values: ['none', 'fixed', 'adjustable', 'drawer'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '2180020a-d8d6-42bc-b531-fe468ae60121',
      name: 'storageFeetType',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'b6b8becf-064a-4af9-8e2e-14dcb53935b2',
        name: 'storageFeetTypes',
        description: null,
        values: ['fixed', 'fixedAdjustable', 'lockableWheels'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'eae50111-eb3a-47c2-b3f9-648b65d3af70',
      name: 'storageLockType',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '51861072-a130-4908-b122-c1e06fd41bf4',
        name: 'storageLockTypes',
        description: null,
        values: ['none', 'combination', 'key', 'padlock'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '61a64e93-27ca-4795-a384-ee9598acd6c2',
      name: 'storageBuiltInLighting',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'a8881ce3-6e38-4a6a-9771-3caf967f0262',
      name: 'tableAdjustableHeight',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'c1a17650-501a-4c8f-87cd-7bad27aaba3b',
        name: 'tableAdjustableHeightOs',
        description: null,
        values: ['electric', 'manual'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'bec06e09-9142-4b58-ac12-be8b503904c4',
      name: 'tableAdjustableWidth',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'a9f2301f-05a9-4310-8b51-c03769513a95',
      name: 'tableFeet',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'c96e35e8-4c75-4536-9420-70b1a488647e',
        name: 'tableFeetOs',
        description: null,
        values: ['fixed', 'adjustable', 'wheelsLockable'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '975f1719-ca36-4c15-93ca-71ca49ce2295',
      name: 'tableCableSupport',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'ba7515be-f0c2-41c8-b028-c3c944792052',
        name: 'tableCableSupportOs',
        description: null,
        values: ['cablePort', 'cablePortPower', 'powerBox'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'c48ff48a-a8eb-4f57-913d-8222f4e1efd1',
      name: 'tableCableManagement',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '8ef88c1e-aae3-4ccd-af79-73eb4ebcfa83',
        name: 'tableCableManagementOs',
        description: null,
        values: ['cableTray', 'fillUpCover'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'f02afa47-7e16-491b-9bf9-4ed1a0f17d11',
      name: 'tableStackable',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'eb2bf89c-b55d-41d8-b5fb-f19b48f827c2',
      name: 'tableFoldable',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'b31e0ddf-f03e-4fa4-9af9-d00e7a3e6a7b',
      name: 'tablePrivacyScreen',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'a99d65ad-b4fa-446e-820d-cab3f7dd227e',
        name: 'tablePrivacyScreenOs',
        description: null,
        values: [
          'topFront',
          'topSide',
          'topAll',
          'bottomFront',
          'bottomSide',
          'bottomAll',
          'topBottomFront',
          'topBottomSide',
          'all',
        ],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'b6f1ba05-09ef-4357-8832-a63cda37b331',
      name: 'tableDrawer',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'ffb75cb1-d1d3-467f-ab7f-b7cfcc21b324',
      name: 'tableTopShape',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '5b6d8e07-1e86-439c-aeb3-5f9b18e80913',
        name: 'tableTopShapeOs',
        description: null,
        values: [
          'square',
          'oval',
          'rectangle',
          'circle',
          'rectangleWithCutout',
        ],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'd459c392-9a09-41b0-928a-e63f75928fdd',
      name: 'sofaWidth',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'e040f436-a4e1-4da9-8a19-96f25614423d',
        name: 'sofaWidthOs',
        description: null,
        values: ['fixed', 'sectional'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'd1d91f4b-2103-4dee-891a-a197ece2364c',
      name: 'sofaFeet',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'a4d77390-81d8-42cb-b4e5-00fd08fa2a73',
        name: 'sofaFeetOs',
        description: null,
        values: ['feetFixed', 'feetFixedAdjustable', 'wheels'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'db6db0f3-961c-4a44-addb-2c3f065ad884',
      name: 'sofaSeats',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '4f9c33fc-bde2-4f40-bcda-11e2b5b38540',
        name: 'sofaSeatsOs',
        description: null,
        values: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'e7f99f93-8157-499d-a746-51bd9db5ffb4',
      name: 'sofaChaiseLounge',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '31ed6895-b66e-4ef4-baf7-0473a1dba3a5',
        name: 'sofaChaiseLoungeOs',
        description: null,
        values: ['left', 'right'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '7ce2ecb4-20e5-4bf5-9204-a052e959789b',
      name: 'sofaShape',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'fea875cf-4fae-46cb-8733-49e63399f6be',
        name: 'sofaShapeOs',
        description: null,
        values: ['standard', 'lShape', 'cornerShape', 'uShape'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '02d6ef49-464e-48a6-8382-f6c87da7828a',
      name: 'sofaType',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'd83db599-29be-4f9b-ab56-31b60105bc11',
        name: 'sofaTypeOs',
        description: null,
        values: [
          'chesterfield',
          'settee',
          'midCentry',
          'daybed',
          'futon',
          'tuxedo',
          'lowSeated',
        ],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'b730d8b0-9219-4f1c-8217-b08d04f31582',
      name: 'wBoardSize',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '20fa77da-7ad2-44b9-b912-3e3672c8ced5',
        name: 'wBoardSizes',
        description: null,
        values: ['small', 'medium', 'large'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'fc219ee3-626d-4758-969b-7a73fcd516d9',
      name: 'wBoardMaterial',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '29c61086-e2ff-4044-9cc4-44835da10304',
        name: 'wBoardMaterials',
        description: null,
        values: ['plastic', 'glass'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'ff7f42a5-dcde-4b2a-9cb1-24b8a48c836a',
      name: 'wBoardColor',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '8a9b7ac4-9014-4a96-8b44-7046d5ccd5e6',
        name: 'wBoardColors',
        description: null,
        values: ['white', 'black', 'gray'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '7942d453-3480-4818-a75f-00610a46ec3f',
      name: 'wBoardFeet',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'b4791991-3a4d-4d47-a231-0512c9532593',
        name: 'wBoardFeet',
        description: null,
        values: ['none', 'fixed', 'wheels'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '9a53c8c8-b866-432e-a930-e99d2ef7205f',
      name: 'wBoardSided',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '412f3a88-9107-4df3-9334-f7307a6d3678',
        name: 'wBoardSides',
        description: null,
        values: ['oneSided', 'twoSided'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: '5fcfd264-00db-4820-a55c-95a137cc6666',
      name: 'wBoardHanger',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'cd232011-199b-4d88-8571-96c3e284ad49',
        name: 'wBoardHangers',
        description: null,
        values: ['none', 'hook', 'rails'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'faa4a414-2f4e-43a0-bce4-104dae682f88',
      name: 'wBoardFrame',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: 'bd83f9fe-bfb3-4409-8fee-4f35edf82bfd',
      name: 'wBoardFlippable',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '9a64201b-4076-4906-ac1c-12a95d7ee4de',
      name: 'wBoardFoldable',
      type: 'TextFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      __typename: 'TextFieldDefinition',
    },
    {
      id: '89eb5eea-3041-4d7d-8728-c3cba8d1559b',
      name: 'chairNeckrest',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: 'bf84921f-b468-4d9b-8c1e-a265d6879af7',
        name: 'chairNeckrests',
        description: null,
        values: ['none', 'fixed', 'heightAdjustable'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
    {
      id: 'a0040065-f3b4-459c-a162-954662197967',
      name: 'chairSeats',
      type: 'OptionSetFieldDefinition',
      description: null,
      required: false,
      defaultValue: null,
      optionSet: {
        id: '017f314f-01fd-47d7-87ac-298a17dd940f',
        name: 'chairSeatsOs',
        description: null,
        values: ['1', '2', '3', '4', '5', '6', '7', '8', 'eightPlus'],
        __typename: 'OptionSet',
      },
      __typename: 'OptionSetFieldDefinition',
    },
  ],
  __typename: 'Template',
};
