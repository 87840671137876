import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import cn from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block',
    color: theme.palette.success.light,
  },
  alignCenter: {
    margin: 'auto',
  },
}));

type Props = CircularProgressProps & {
  alignCenter?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const sizes = {
  small: 24,
  medium: 40,
  large: 80,
} as const;

export const LoadingIndicator: React.FC<Props> = props => {
  const { alignCenter = true, size = 'large', ...indicatorProps } = props;
  const classes = useStyles();
  return (
    <CircularProgress
      {...indicatorProps}
      size={sizes[size]}
      className={cn(classes.root, { [classes.alignCenter]: alignCenter })}
    />
  );
};
