import { useMemo } from 'react';

import { useTranslation } from '../../../../translations';
import { FormLayout } from '../../../../common/components/Form/types';
import { processDimensionRows } from '../../../models/utils';

export const useDimensionsStepFormLayout = (
  subcategoryKey: string,
): FormLayout => {
  const { t, tk } = useTranslation('inventory');

  return useMemo(
    () => [
      processDimensionRows(subcategoryKey, {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 6 },
            name: 'minHeight',
            label: t(tk.fieldLabels.minHeight),
            groupLabel: t(tk.fieldLabels.height),
          },
          {
            gridItemProps: { xs: 6 },
            name: 'maxHeight',
            label: t(tk.fieldLabels.maxHeight),
            groupLabel: t(tk.fieldLabels.height),
          },
        ],
      }),
      processDimensionRows(subcategoryKey, {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 6 },
            name: 'minSeatHeight',
            label: t(tk.fieldLabels.minSeatHeight),
            groupLabel: t(tk.fieldLabels.seatHeight),
          },
          {
            gridItemProps: { xs: 6 },
            name: 'maxSeatHeight',
            label: t(tk.fieldLabels.maxSeatHeight),
            groupLabel: t(tk.fieldLabels.seatHeight),
          },
        ],
      }),
      processDimensionRows(subcategoryKey, {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 6 },
            name: 'minWidth',
            label: t(tk.fieldLabels.minWidth),
            groupLabel: t(tk.fieldLabels.width),
          },
          {
            gridItemProps: { xs: 6 },
            name: 'maxWidth',
            label: t(tk.fieldLabels.maxWidth),
            groupLabel: t(tk.fieldLabels.width),
          },
        ],
      }),
      processDimensionRows(subcategoryKey, {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 6 },
            name: 'minLength',
            label: t(tk.fieldLabels.minLength),
            groupLabel: t(tk.fieldLabels.length),
          },
          {
            gridItemProps: { xs: 6 },
            name: 'maxLength',
            label: t(tk.fieldLabels.maxLength),
            groupLabel: t(tk.fieldLabels.length),
          },
        ],
      }),
      processDimensionRows(subcategoryKey, {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 6 },
            name: 'minDepth',
            label: t(tk.fieldLabels.minDepth),
            groupLabel: t(tk.fieldLabels.depth),
          },
          {
            gridItemProps: { xs: 6 },
            name: 'maxDepth',
            label: t(tk.fieldLabels.maxDepth),
            groupLabel: t(tk.fieldLabels.depth),
          },
        ],
      }),
    ],
    [t, tk, subcategoryKey],
  );
};
