/* eslint-disable @typescript-eslint/camelcase */
export default {
  actions: {
    back: 'Back',
    confirm: 'Confirm',
    save: 'Save Changes',
    download: 'File generation in progress it may take a while..',
  },
  errors: {
    generic: 'Something went wrong!',
  },
  components: {
    CheckboxList: {
      showMore: 'Show more +',
    },
  },
  logo: {
    goodWorkLabel: 'a service from ',
  },
  header: {
    menu: {
      createSku: 'Register New Furniture',
      skus: 'Furniture Registry',
      adSkus: 'Market Place',
      adminPanel: 'Admin Panel',
    },
    logout: 'Logout',
    closeMenuButton: 'Close Menu',
  },
  externalLinks: {
    aboutUs: 'https://www.gogood.no/om-oss/',
    contactUs: 'https://www.gogood.no/kontakt-oss/',
    news: 'https://www.gogood.no/nyheter/',
    privacyPolicy: 'https://www.gogood.no/privacy-policy/',
    facebook: 'https://www.facebook.com/gogood.no/',
    instagram: 'https://instagram.com/gogood.no',
    pinterest: 'https://no.pinterest.com/gogoodforreal/',
  },

  footer: {
    linkLabels: {
      aboutUs: 'About us',
      contactUs: 'Contact us',
      privacyPolicy: 'Privacy',
      cookiePolicy: 'Cookie Policy',
      scrollTop: 'Scroll to top',
    },
    linkHrefs: {
      aboutUs: 'https://www.gogood.no/om-oss/',
      contactUs: 'https://www.gogood.no/kontakt-oss/',
      privacyPolicy: 'https://www.gogood.no/privacy-policy/',
      cookiePolicy: '',
    },
  },
  success: 'Success!',
  noValueLabel: '-- not set --',
} as const;
