import React from 'react';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { Controller } from 'react-hook-form';
import type { Control, FieldError } from 'react-hook-form';

import { CheckboxField } from '../../CheckboxField';

type Props = Omit<CheckboxProps, 'onChange' | 'value'> & {
  control: Control;
  name: string;
  label: string;
  error?: FieldError;
};

export const ControlledCheckbox: React.FC<Props> = props => {
  const { control, name, ...checkboxProps } = props;

  return (
    <Controller
      render={({ onChange, value }) => (
        <CheckboxField
          name={name}
          {...checkboxProps}
          value={!!value}
          onChange={onChange}
        />
      )}
      name={name}
      control={control}
    />
  );
};
