import React, { FC } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { useNavigation } from '../../../../common/navigation';
import { extractMutation } from '../../../../common/utils/extractQuery';
import { SummaryStep as SummaryStepComponent } from '../../../components/NewSkuView/SummaryStep';
import { useColorHelper } from '../../../context/useColorMapping';
import {
  CreateSkuMutation,
  FunctionalityInput,
  SkuInput,
  useCreateSkuMutation,
} from '../../../graphql';
import { CreateSkuMutationPayload } from '../../../graphql/types';
import {
  FunctionalitySubcategoryConfig,
  FunctionalityValue,
  useFunctionalityConfig,
} from '../../../models';
import { getFunctionalityInput } from '../../../models/utils';
import { useNewSkuActions } from '../../../redux/newSku';
import * as selectors from '../../../redux/newSku/selectors';
import { StepId } from '../types';
import { StepId as RegistrationStepId } from '../../RegistrationView/types';

export type RedirectTarget = 'finish' | 'register';

const validateSkuInput = (
  skuInput: Partial<SkuInput>,
  functionalityValues: Record<string, FunctionalityValue>,
  functionalityConfig: FunctionalitySubcategoryConfig | null,
): [SkuInput | undefined, StepId | RegistrationStepId | undefined] => {
  const {
    manufacturer,
    model,
    primaryColor,
    mainCategoryId,
    subcategoryId,
  } = skuInput;
  if (!mainCategoryId) {
    return [undefined, RegistrationStepId.Welcome];
  }
  if (!subcategoryId) {
    return [undefined, RegistrationStepId.Welcome];
  }
  if (!manufacturer) {
    return [undefined, StepId.Model];
  }
  if (!primaryColor) {
    return [undefined, StepId.Color];
  }

  let functionalities: FunctionalityInput[] = [];
  if (functionalityConfig) {
    functionalities = getFunctionalityInput(
      functionalityConfig.fields,
      functionalityValues,
    );
  }

  return [
    {
      mainCategoryId,
      manufacturer,
      model,
      subcategoryId,
      functionalities,
      ...skuInput,
    },
    undefined,
  ];
};

export const SummaryStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onGoBack,
  onGoToStep,
}) => {
  const parialSkuInput = useSelector(selectors.skuInput);
  const [redirectTarget, setRedirectTarget] = useState<RedirectTarget>(
    'register',
  );
  const nav = useNavigation();
  const backLabel = useSelector(selectors.label);
  const { setDescription, reset } = useNewSkuActions();
  const functionalityValues = useSelector(selectors.functionalityValues);
  const subcategoryKey = useSelector(selectors.subcategoryKey);
  const isNewManufacturer = useSelector(selectors.isNewManufacturer);
  const images = useSelector(selectors.images);
  const colorHelper = useColorHelper();
  const functionalityConfig = useFunctionalityConfig(subcategoryKey);
  const [skuInput, stepId] = validateSkuInput(
    parialSkuInput,
    functionalityValues,
    functionalityConfig,
  );

  const [createSku, rawMutationResult] = useCreateSkuMutation({
    refetchQueries: isNewManufacturer ? ['Context'] : undefined,
  });
  const mutationResult = extractMutation<
    CreateSkuMutation,
    CreateSkuMutationPayload
  >(rawMutationResult, 'CreateSkuPayload', data => data.createSku);

  useEffect(() => {
    if (!skuInput && stepId) {
      onGoToStep(stepId);
    }
  }, [onGoToStep, skuInput, stepId]);

  useEffect(() => {
    const sku = mutationResult.data?.sku;
    if (sku) {
      reset();
      if (redirectTarget === 'finish') {
        return nav.sku({ id: sku.id }).go();
      }
      return nav.newSkuInstance({ skuId: sku.id }).go();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationResult.data]);

  if (!skuInput) {
    return null;
  }

  const handleSubmit = (target: RedirectTarget) => {
    setRedirectTarget(target);
    createSku({
      variables: { input: { sku: skuInput } },
    });
  };

  const handleDescriptionChange = (description: string) => {
    setDescription(description);
  };

  return (
    <SummaryStepComponent
      backLabel={backLabel}
      colorHelper={colorHelper}
      skuInput={skuInput}
      functionality={
        subcategoryKey
          ? {
              subcategoryKey,
              values: functionalityValues,
            }
          : undefined
      }
      images={images}
      onGoBack={onGoBack}
      onGoToStep={onGoToStep}
      onSubmit={handleSubmit}
      onDescriptionChange={handleDescriptionChange}
      error={mutationResult.error}
      loading={mutationResult.loading}
      number={number}
      totalCount={totalCount}
    />
  );
};
