import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityFilterGroup,
  FunctionalityFilters,
  FunctionalityFilterRow,
} from '../types';

import { getFields, StorageFields } from './fields';

const groups = (
  {
    storageBuiltInLighting,
    storageDoorsMechanism,
    storageFeetType,
    storageLockType,
    storageShelvesType,
  }: StorageFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityFilterGroup[] => [
  {
    label: t(tk.storage.filters.doors),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: storageDoorsMechanism,
      },
    ],
  },
  {
    label: t(tk.storage.filters.shelves),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: storageShelvesType,
      },
    ],
  },
  {
    label: t(tk.storage.filters.lock),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: storageLockType,
      },
    ],
  },
  {
    label: t(tk.storage.filters.feet),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: storageFeetType,
      },
    ],
  },
  {
    label: t(tk.storage.filters.lighting),
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: storageBuiltInLighting,
        label: t(tk.storage.filters.builtInLighting),
      },
    ],
  },
];

export const storage = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityFilters => ({
  groups: groups(getFields(t, tk), t, tk),
  title: t(tk.common.filters.attributes),
});
