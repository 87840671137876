/* eslint-disable @typescript-eslint/camelcase */
export default {
  common: {
    filters: {
      attributes: 'Attributes',
      none: 'None',
      yes: 'Yes',
    },
  },
  chairs: {
    fields: {
      chairBack: {
        label: 'Back',
        editLabel: 'Chair has back',
      },
      chairBackAdjustableHeight: {
        label: 'Height',
        groupBy: 'Adjustable',
        editLabel: 'I can adjust the height of the back',
      },
      chairBackAdjustableAngle: {
        label: 'Angle',
        groupBy: 'Adjustable',
        editLabel: 'I can adjust the angle of the back',
      },
      chairLeaningBack: {
        label: 'Leaning Back',
        editLabel: 'The back is leaning',
      },
      chairSeatAdjustablePosition: {
        label: 'Position',
        groupBy: 'Adjustable',
        editLabel: 'I can adjust the position of the seat',
      },
      chairSeatAdjustableAngle: {
        label: 'Angle',
        groupBy: 'Adjustable',
        editLabel: 'I can adjust the angle of the seat',
      },
      chairArmrestsAdjustablePosition: {
        label: 'Position',
        groupBy: 'Adjustable',
        editLabel: 'I can adjust the position of the armrests',
      },
      chairArmrestsAdjustableHeight: {
        label: 'Height',
        groupBy: 'Adjustable',
        editLabel: 'I can adjust the height of the armrests',
      },
      chairArmrestsAdjustableWidth: {
        label: 'Width',
        groupBy: 'Adjustable',
        editLabel: 'I can adjust the width of the armrests',
      },
      chairArmrestsAdjustableLength: {
        label: 'Length',
        groupBy: 'Adjustable',
        editLabel: 'I can adjust the length of the armrests',
      },
      chairAdjustableHeight: {
        label: 'Adjustable Seat Height',
        promptLabel: 'Maximum height of the seat is:',
        valueEditLabels: {
          fixed: 'Fixed',
          standard: 'Standard (below 57 cm)',
          long: 'High (above 57 cm)',
        },
        valueViewLabels: {
          fixed: 'Fixed',
          standard: 'Standard',
          long: 'High',
        },
      },
      chairBackFixed: {
        label: 'Fixed',
        editLabel: 'The back is fixed (Non-adjustable)',
      },
      chairBackLength: {
        label: 'Height',
        promptLabel: 'Select length of the back measured from the front.',
        valueEditLabels: {
          standard: 'Standard (below 50 cm)',
          long: 'Long (above 50 cm)',
        },
        valueViewLabels: {
          standard: 'Standard',
          long: 'Long',
        },
      },
      chairSeat: {
        label: 'Seat',
      },
      chairSeatFixed: {
        label: 'Fixed',
        editLabel: 'The seat is fixed (Non-adjustable)',
      },
      chairArmrests: {
        label: 'Armrests',
        editLabel: 'Chair has armrests',
      },
      chairArmrestsFixed: {
        label: 'Fixed',
        editLabel: 'Armrests are fixed (Non-adjustable)',
      },
      chairFeetCount: {
        label: '',
        promptLabel: 'How many feet/ wheels does the chair have?',
        valueEditLabels: {
          '4': '4',
          '5': '5',
        },
        valueViewLabels: {
          '4': '4',
          '5': '5',
        },
      },
      chairFeetType: {
        label: '',
        promptLabel: 'Select the type of feet/wheels.',
        valueEditLabels: {
          feet: 'The chair has feet',
          hardWheels: 'The chair has hard wheels',
          softWheels: 'The chair has soft wheels',
          hybridWheels: 'The chair has hybrid wheels',
        },
        valueViewLabels: {
          feet: 'Feet',
          hardWheels: 'Hard Wheels',
          softWheels: 'Soft Wheels',
          hybridWheels: 'Hybrid Wheels',
        },
      },
      chairNeckrest: {
        label: '',
        promptLabel: 'Select the functionality of the neckrest.',
        valueEditLabels: {
          none: 'No neckrest',
          fixed: 'The neckrest is fixed',
          heightAdjustable: 'I can adjust the height of the neckrest',
        },
        valueViewLabels: {
          none: 'None',
          fixed: 'Fixed',
          heightAdjustable: 'Height Adjustable',
        },
      },
      chairFootring: {
        label: 'Footring',
        editLabel: 'Has footring',
      },
      chairStackable: {
        label: 'Stackable',
        editLabel: 'The chair is stackable',
      },
      chairFoldable: {
        label: 'Foldable',
        editLabel: 'The chair is foldable',
      },
      chairSeats: {
        label: 'Seats',
        promptLabel: 'Select number of seats.',
        valueEditLabels: {
          '1': '1',
          '2': '2',
          '3': '3',
          '4': '4',
          '5': '5',
          '6': '6',
          '7': '7',
          '8': '8',
          eightPlus: 'More than 8',
        },
        valueViewLabels: {
          '1': '1',
          '2': '2',
          '3': '3',
          '4': '4',
          '5': '5',
          '6': '6',
          '7': '7',
          '8': '8',
          eightPlus: '8+',
        },
      },
    },
    filters: {
      height: 'Height',
      backLength: 'Back length',
      back: 'Back',
      armrests: 'Armrests',
      neckrest: 'Neckrest',
      feetType: 'Feet & Wheels',
      feetCount: 'Number of feet or wheels',
      footring: 'Footring',
      stackable: 'Stackable',
      foldable: 'Foldable',
    },
    groups: {
      adjustableHeight: {
        label: 'Adjustable Height',
        noValueLabel: 'Fixed height',
      },
      back: {
        label: 'Back',
        promptLabel: 'Select the functionality of the back.',
      },
      seat: {
        label: 'Seat',
        promptLabel: 'Select the functionality of the seat.',
      },
      armrests: {
        label: 'Armrests',
        promptLabel: 'Select the functionality of the armrests.',
      },
      feet: {
        label: 'Feet & Wheels',
      },
      neckrest: {
        label: 'Neckrest',
      },
      footring: {
        label: 'Footring',
      },
      stackable: {
        label: 'Stackable',
        noValueLabel: 'Not stackable',
      },
      foldable: {
        label: 'Foldable',
        noValueLabel: 'Not foldable',
      },
      seats: {
        label: 'Seats',
      },
    },
  },
  sofas: {
    fields: {
      sofaWidth: {
        label: 'Width',
        promptLabel: 'Select type of width adjustability.',
        valueEditLabels: {
          fixed: 'The sofa has fixed width',
          sectional: 'The sofa is composed of sectional modules',
        },
        valueViewLabels: {
          fixed: 'Fixed',
          sectional: 'Sectional',
        },
      },
      sofaFeet: {
        label: 'Feet',
        promptLabel: 'Select type of feet.',
        valueEditLabels: {
          feetFixed: 'The sofa has fixed feet',
          feetFixedAdjustable: 'The sofa has fixed feet and adjustable height',
          wheels: 'The sofa has lockable wheels',
        },
        valueViewLabels: {
          feetFixed: 'Fixed feet',
          feetFixedAdjustable: 'Fixed feet, adjustable height',
          wheels: 'Wheels, lockable',
        },
      },
      sofaSeats: {
        label: 'Seats',
        promptLabel: 'Select number of seats.',
        valueEditLabels: {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
          7: '7',
          8: '8',
          9: '9',
        },
        valueViewLabels: {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
          7: '7',
          8: '8',
          9: '9',
        },
      },
      sofaChaiseLounge: {
        label: 'Chaise lounge',
        promptLabel: 'Select chase lounge side.',
        valueEditLabels: {
          left: 'The sofa has left chaise',
          right: 'The sofa has right chaise',
        },
        valueViewLabels: {
          left: 'Left chaise',
          right: 'Right chaise',
        },
      },
      sofaShape: {
        label: 'Shape',
        promptLabel: 'Select shape.',
        valueEditLabels: {
          standard: 'The sofa has standard shape',
          lShape: 'The sofa has L-shape',
          cornerShape: 'The sofa has Corner-shape',
          uShape: 'The sofa has U-shape',
        },
        valueViewLabels: {
          standard: 'Standard',
          lShape: 'L-shape',
          cornerShape: 'Corner-shape',
          uShape: 'U-shape',
        },
      },
      sofaType: {
        label: 'Type',
        promptLabel: 'Select type.',
        valueEditLabels: {
          chesterfield: 'Chesterfield',
          settee: 'Settee',
          midCentry: 'Mid-centry',
          daybed: 'Daybed',
          futon: 'Futon',
          tuxedo: 'Tuxedo',
          lowSeated: 'Low seated',
        },
        valueViewLabels: {
          chesterfield: 'Chesterfield',
          settee: 'Settee',
          midCentry: 'Mid-centry',
          daybed: 'Daybed',
          futon: 'Futon',
          tuxedo: 'Tuxedo',
          lowSeated: 'Low seated',
        },
      },
    },
    filters: {
      seats: 'Seats',
      size: 'Size',
      shape: 'Shape',
      chaiseLounge: 'Chaise Lounge',
      feet: 'Feet',
      style: 'Style',
    },
    groups: {
      width: {
        label: 'Width',
      },
      feet: {
        label: 'Feet',
      },
      seats: {
        label: 'Seats',
      },
      chaiseLounge: {
        label: 'Chaise Lounge',
      },
      shape: {
        label: 'Shape',
      },
      type: {
        label: 'Type',
      },
    },
  },
  storage: {
    fields: {
      storageDoorsMechanism: {
        label: 'Doors',
        promptLabel: 'Select door mechanism.',
        valueViewLabels: {
          sliding: 'Sliding',
          hinged: 'Hinged',
          pullOut: 'Pull Out',
        },
      },
      storageFeetType: {
        label: 'Feet type',
        promptLabel: 'Select feet type.',
        valueEditLabels: {
          fixed: 'Fixed feet, non-adjustable height',
          fixedAdjustable: 'Fixed feet, adjustable height',
          lockableWheels: 'Lockable wheels',
        },
        valueViewLabels: {
          fixed: 'Fixed feet, non-adjustable height',
          fixedAdjustable: 'Fixed feet, adjustable height',
          lockableWheels: 'Lockable wheels',
        },
      },
      storageLockType: {
        label: 'Lock type',
        promptLabel: 'Select lock type feet type.',
        valueViewLabels: {
          none: 'None',
          combination: 'Combination',
          key: 'Key',
          padlock: 'Padlock',
        },
      },
      storageBuiltInLighting: {
        label: 'Built it',
        editLabel: 'Has built in lighting',
      },
      storageShelvesType: {
        label: 'Shelves type',
        promptLabel: 'Select shelves type.',
        valueViewLabels: {
          none: 'None',
          fixed: 'Fixed',
          adjustable: 'Adjustable',
          drawers: 'Drawers',
        },
      },
    },
    filters: {
      doors: 'Doors',
      shelves: 'Shelves',
      lock: 'Lock',
      feet: 'Feet',
      lighting: 'Lighting',
      builtInLighting: 'Built In',
    },
    groups: {
      doors: {
        label: 'Doors',
      },
      shelves: {
        label: 'Shelves',
      },
      lock: {
        label: 'Lock',
      },
      feet: {
        label: 'Feet',
      },
      builtInLighting: {
        label: 'Built in lightining',
      },
    },
  },
  tables: {
    fields: {
      tableAdjustableWidth: {
        label: 'Adjustable Width',
        editLabel: 'I can adjust width of the table',
      },
      tableAdjustableHeight: {
        label: 'Adjustable Height',
        promptLabel: 'Select the type of height adjustment.',
        valueEditLabels: {
          electric: 'The table has electric height adjustment',
          manual: 'The table has manual height adjustment',
        },
        valueViewLabels: {
          electric: 'Electric',
          manual: 'Manual',
        },
      },
      tableFeet: {
        label: 'Feet',
        promptLabel: 'Select the type of feet/wheels.',
        valueEditLabels: {
          fixed: 'The table has fixed feet',
          adjustable: 'The table has adjustable feet',
          wheelsLockable: 'The table has locable wheels',
        },
        valueViewLabels: {
          fixed: 'Fixed',
          adjustable: 'Adjustable',
          wheelsLockable: 'Wheels, lockable',
        },
      },
      tableCableSupport: {
        label: 'Cable/power support',
        promptLabel: 'Select cable/power support functionality.',
        valueEditLabels: {
          cablePort: 'The table has cable port',
          cablePortPower: 'The table has cable port with power',
          powerBox: 'The table has power box',
        },
        valueViewLabels: {
          cablePort: 'Cable port',
          cablePortPower: 'Cable port, power',
          powerBox: 'Power box',
        },
      },
      tableCableManagement: {
        label: 'Cable management',
        promptLabel: 'Select cable management type.',
        valueEditLabels: {
          cableTray: 'The table has cable tray',
          fillUpCover: 'The table has fill-up cover',
        },
        valueViewLabels: {
          cableTray: 'Cable tray ',
          fillUpCover: 'Flip-Up Cover',
        },
      },
      tablePrivacyScreen: {
        label: 'Privacy screen',
        promptLabel: 'Select privacy screen placement.',
        valueEditLabels: {
          topFront: 'The table has top front privacy screen',
          topSide: 'The table has top side privacy screen',
          topAll: 'The table has top and side front privacy screens',
          bottomFront: 'The table has bottom front privacy screen',
          bottomSide: 'The table has bottom side privacy screen',
          bottomAll: 'The table has bottom front and side privacy screens',
          topBottomFront: 'The table has top and bottom front privacy screens',
          topBottomSide: 'The table has top and bottom side privacy screens',
          all: 'The table has top and bottom front and side privacy screens',
        },
        valueViewLabels: {
          topFront: 'Top, front',
          topSide: 'Top, side',
          topAll: 'Top, front and side',
          bottomFront: 'Bottom, front',
          bottomSide: 'Bottom, side',
          bottomAll: 'Bottom, front and side',
          topBottomFront: 'Top & bottom, front',
          topBottomSide: 'Top & bottom, side',
          all: 'Top & bottom, front and side',
        },
      },
      tableTopShape: {
        label: 'Table top shape',
        promptLabel: 'Select table top shape.',
        valueEditLabels: {
          square: 'The table has square table top',
          oval: 'The table has oval table top',
          rectangle: 'The table has rectangle table top',
          circle: 'The table has circle table top',
          rectangleWithCutout: 'The table has rectangle table top with cutout',
        },
        valueViewLabels: {
          square: 'Square',
          oval: 'Oval',
          rectangle: 'Rectangle',
          circle: 'Circle',
          rectangleWithCutout: 'Rectangle with cutout',
        },
      },
      tableDrawer: {
        label: 'Drawer',
        editLabel: 'The table has drawer',
      },
      tableStackable: {
        label: 'Stackable',
        editLabel: 'The table is stackable',
      },
      tableFoldable: {
        label: 'Foldable',
        editLabel: 'The table is foldable',
      },
    },
    filters: {
      adjustableHeight: 'Adjustable Height',
      adjustableWidth: 'Adjustable Width',
      feet: 'Feet',
      cableSupport: 'Cable/Power Support',
      privacyScreen: 'Privacy Screen',
      topShape: 'Table Top Shape',
      stackable: 'Stackable',
      foldable: 'Foldable',
    },
    groups: {
      adjustableHeight: {
        noValueLabel: 'Fixed height',
      },
      adjustableWidth: {
        noValueLabel: 'Fixed width',
      },
      tableStackable: {
        noValueLabel: 'Not stackable',
      },
      tableFoldable: {
        noValueLabel: 'Not foldable',
      },
    },
  },
  whiteboards: {
    fields: {
      wBoardSize: {
        label: 'Size',
        promptLabel: 'Select white board size.',
        valueEditLabels: {
          small: 'Small whiteboard',
          medium: 'Medium whiteboard',
          large: 'Large whiteboard',
        },
        valueViewLabels: {
          small: 'Small',
          medium: 'Medium',
          large: 'Large',
        },
      },
      wBoardMaterial: {
        label: 'Material',
        promptLabel: 'Select material.',
        valueEditLabels: {
          plastic: 'The whiteboard is made of plastic',
          glass: 'The whiteboard is made of glass',
        },
        valueViewLabels: {
          plastic: 'Plastic',
          glass: 'Glass',
        },
      },
      wBoardFrame: {
        label: 'Has frame',
        editLabel: 'Has frame',
      },
      wBoardColor: {
        label: 'Color',
        promptLabel: 'What color is the drawing surface?',
        valueViewLabels: {
          white: 'White',
          black: 'Black',
          gray: 'Gray',
        },
      },
      wBoardFeet: {
        label: 'Feet',
        promptLabel: 'Feet or wheels?',
        valueEditLabels: {
          none: 'Whiteboard without feet',
          fixed: 'The whiteboard has feet',
          wheels: 'The whiteboard has wheels',
        },
        valueViewLabels: {
          none: 'Without feet',
          fixed: 'Has feet',
          wheels: 'Has wheels',
        },
      },
      wBoardSided: {
        label: 'Sided',
        promptLabel: 'One or two sided whiteboard?',
        valueViewLabels: {
          oneSided: 'The whiteboard is one-sided',
          twoSided: 'The whiteboard is two-sided',
        },
      },
      wBoardFoldable: {
        label: 'Is foldable',
        editLabel: 'Is foldable',
      },
      wBoardFlippable: {
        label: 'Is flippable',
        editLabel: 'Is flippable',
      },
      wBoardHanger: {
        label: 'Hanger',
        promptLabel: 'Specify hanging method',
        valueEditLabels: {
          none: 'No hanger',
          hook: 'The whiteboard has hook',
          rails: 'The whiteboard has rails',
        },
        valueViewLabels: {
          none: 'No hanger',
          hook: 'Hook',
          rails: 'Rails',
        },
      },
    },
    filters: {
      size: 'Size',
      material: 'Material',
      sides: 'Sides',
      frame: 'Frame',
      feet: 'Feet',
      hanger: 'Hanging Method',
      other: 'Other',
    },
    groups: {
      sizes: {
        label: 'Whiteboard size',
      },
      materials: {
        label: 'Whiteboard material',
      },
      colors: {
        label: 'Colors',
      },
      feet: {
        label: 'Feet',
      },
      sided: {
        label: 'One- or two-sided',
      },
      hangers: {
        label: 'Hanging method',
      },
      frames: {
        label: 'Frame',
      },
      foldability: {
        label: 'Foldability',
        noValueLabel: 'Not foldable',
      },
      fippability: {
        label: 'Flippability',
        noValueLabel: 'Non-flippable',
      },
    },
  },
} as const;
