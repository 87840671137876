import React, { FC, useEffect } from 'react';

import { useNavigation } from '../../../common/navigation';
import {
  SkuInstanceByGtinSerialQuery,
  useSkuInstanceByGtinSerialQuery,
} from '../../graphql';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { ErrorView } from '../../../common/components/ErrorView';
import { extractQuery } from '../../../common/utils/extractQuery';
import { SkuInstanceByGtinSerialPayload } from '../../graphql/types';
import { useApolloClient } from '../../../app/setup/useApolloClient';

type Props = {
  gtin: string;
  serialNumber: string;
};

export const QrCodeView: FC<Props> = ({ gtin, serialNumber }) => {
  const nav = useNavigation();
  const client = useApolloClient();

  const { data, loading, error } = extractQuery<
    SkuInstanceByGtinSerialQuery,
    SkuInstanceByGtinSerialPayload
  >(
    useSkuInstanceByGtinSerialQuery({
      client,
      variables: {
        gtin,
        serialNumber,
      },
    }),
    'SkuInstanceTenantId',
    data => data.skuInstancesCrossTenantsByGtinSerial,
  );

  useEffect(() => {
    const id = data?.skuInstance.id;
    console.warn(id, data?.tenantId);
    if (id) {
      nav.skuInstance({ id }, { tenantId: data?.tenantId, lang: 'no' }).go();
    }
  }, [data, nav]);

  if (!data) {
    if (loading) {
      return <LoadingIndicator />;
    }

    return <ErrorView error={error} />;
  }

  return <LoadingIndicator />;
};
