import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { Fragment, useCallback, useState } from 'react';

import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { useTranslation } from '../../../translations';
import { SkuInstancePayload } from '../../graphql/types';
import { Form } from '../../../common/components/Form';
import { SkuInstanceInput, useUpdateSkuInstanceMutation } from '../../graphql';
import { getInitialValues } from '../SkuInstanceView/getInitialValues';
import {
  useSkuInstanceFormUpdateDialogFields,
  useSkuInstanceFormUpdateDialogLayout,
} from '../../forms/useSkuInstanceFormUpdateDialogFields';

import { SkuInstanceItemMainBlock } from './SkuInstanceList/SkuInstanceItemMainBlock';

type Props = {
  open: boolean;
  onClose: () => void;
  selectedSkuItems?: SkuInstancePayload[];
};

type InputBodyType = {
  officeId: SkuInstanceInput['officeId'];
  status: SkuInstanceInput['status'];
  condition: SkuInstanceInput['condition'];
  processingStage: SkuInstanceInput['processingStage'];
};

const useStyles = makeStyles(theme =>
  createStyles({
    button: () => ({
      background: theme.palette.error.light,
      '&:hover': {
        background: theme.palette.error.main,
      },
    }),
    paper: {
      background: theme.palette.primary.contrastText,
    },
    text: {
      marginBottom: theme.spacing(3),
      color: theme.palette.primary.main,
    },
    divider: {
      margin: theme.spacing(0, 3),
      backgroundColor: theme.palette.background.default,
      border: 'none',
      borderTop: `2px dashed ${theme.palette.primary.veryLight}`,
      color: theme.palette.background.default,
      height: 1,
      '&:last-child': {
        display: 'none',
      },
    },
  }),
);

export const DialogFormUpdateSkus = ({
  open,
  onClose,
  selectedSkuItems,
}: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [, setError] = useState<unknown | undefined>(undefined);

  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');

  const fields = useSkuInstanceFormUpdateDialogFields();

  const layout = useSkuInstanceFormUpdateDialogLayout();

  const [updateSkuInstance] = useUpdateSkuInstanceMutation();

  const handleUpdate = useCallback(
    (input: InputBodyType, skuInstance: SkuInstancePayload) => {
      const initValue = getInitialValues(skuInstance);

      return updateSkuInstance({
        variables: {
          input: {
            id: skuInstance.id,
            skuInstance: {
              ...initValue,
              status: input.status || initValue.status,
              officeId: input.officeId || initValue.officeId,
              condition: input.condition || initValue.condition,
              processingStage:
                input.processingStage || initValue.processingStage,
              skuId:
                skuInstance.sku.__typename === 'Sku' ? skuInstance.sku.id : '',
            },
          },
        },
      });
    },
    [updateSkuInstance],
  );

  const handleSheetGenerate = useCallback(
    async (input: InputBodyType) => {
      if (!selectedSkuItems) {
        return;
      }
      setLoading(true);
      try {
        await Promise.all(
          selectedSkuItems.map(async skuInstance => {
            await handleUpdate(input, skuInstance);
          }),
        );

        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    },
    [handleUpdate, selectedSkuItems],
  );

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      data-testid="UpdateSkuInstanceDialog"
      PaperProps={{ className: classes.paper }}
    >
      {isLoading ? (
        <DialogContent>
          <Typography align="center" variant="h5" className={classes.text}>
            {t(tk.skuInstanceListView.changeStatusForInstancesDialog.loading)}
          </Typography>
          <LoadingIndicator size="large" />
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <Typography
              align="center"
              variant="subtitle1"
              className={classes.text}
            >
              {t(tk.skuInstanceListView.changeStatusForInstancesDialog.prompt)}
            </Typography>
            <Box
              display={'flex'}
              flexDirection={'column'}
              gridColumnGap={'8px'}
              gridRowGap={'16px'}
            >
              {selectedSkuItems?.map((skuInstance, idx) => (
                <Fragment key={skuInstance?.id}>
                  <SkuInstanceItemMainBlock
                    key={idx}
                    skuInstance={skuInstance}
                  />
                  <Divider
                    key={`${skuInstance?.id}-divider`}
                    className={classes.divider}
                  />
                </Fragment>
              ))}
            </Box>
            <Box marginTop={'16px'} margin={'auto'} width={'100%'}>
              <Form
                fields={fields}
                layout={layout}
                onSubmit={(data: InputBodyType) => {
                  return handleSheetGenerate(data);
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={onClose} className={classes.text}>
              {t(tk.skuInstanceView.deleteDialog.cancel)}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
