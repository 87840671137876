import React, { FC, ReactNode } from 'react';
import Box from '@material-ui/core/Box';

import { Maybe } from '../../../graphql';
import { WhiteCard } from '../../../../common/components/WhiteCard';
import { Divider } from '../../../../common/components/Divider';
import { ResolvedImage, ResolvedSku } from '../../../types';

import { SummaryHeader } from './SummaryHeader';
import {
  FunctionalityPreview,
  FunctionalityPreviewProps,
} from './FunctionalityPreview';

type Props = {
  sku: Pick<
    ResolvedSku,
    'manufacturer' | 'model' | 'primaryColor' | 'secondaryColor'
  >;
  images?: ResolvedImage[];
  actions?: ReactNode;
  functionality?: Maybe<FunctionalityPreviewProps>;
};

export const SkuPreview: FC<Props> = ({
  actions,
  images,
  sku,
  functionality,
}) => {
  return (
    <WhiteCard variant="outlined">
      <Box p={2}>
        <SummaryHeader sku={sku} image={images && images[0]} />
        {actions && (
          <Box my={2}>
            {actions}
            <Box mt={2}>
              <Divider color="secondary" thickness={1} />
            </Box>
          </Box>
        )}
        {functionality && <FunctionalityPreview {...functionality} />}
      </Box>
    </WhiteCard>
  );
};
