import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
} from '../types';
import { mapFieldKeys } from '../utils';

import { getFields, TableFields } from './fields';
import { TableFunctionalities } from './types';

export const WORKSTATION_DESKS_SUBCATEGORY_KEY = 'workstationDesks';
export const WORKSTATION_DESKS_SUBCATEGORY_LABEL = 'Workstation Desks';

const defaultValues: TableFunctionalities = {
  tableAdjustableWidth: false,
  tableFoldable: false,
  tableDrawer: false,
  tableStackable: false,
};

const groups = (
  {
    tableAdjustableHeight,
    tableAdjustableWidth,
    tableDrawer,
    tableFeet,
    tableStackable,
    tableCableSupport,
    tableCableManagement,
    tablePrivacyScreen,
    tableTopShape,
  }: TableFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'adjustableHeight',
    label: tableAdjustableHeight.label,
    attributes: [tableAdjustableHeight],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [],
      noValueLabel: t(tk.tables.groups.adjustableHeight.noValueLabel),
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tableAdjustableHeight,
        },
      ],
    },
  },
  {
    name: 'feet',
    label: tableFeet.label,
    attributes: [tableFeet],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [tableFeet.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tableFeet,
        },
      ],
    },
  },
  {
    name: 'adjustableWidth',
    label: tableAdjustableWidth.label,
    attributes: [tableAdjustableWidth],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.tables.groups.adjustableWidth.noValueLabel),
      field: tableAdjustableWidth,
    },
  },
  {
    name: 'tableDrawer',
    label: tableDrawer.label,
    attributes: [tableDrawer],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      field: tableDrawer,
    },
  },
  {
    name: 'tableStackable',
    label: tableStackable.label,
    attributes: [tableStackable],
    functionalities: [],
  },
  {
    name: 'tableCableSupport',
    label: tableCableSupport.label,
    attributes: [tableCableSupport],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tableCableSupport,
        },
      ],
    },
  },
  {
    name: 'tableCableManagement',
    label: tableCableManagement.label,
    attributes: [tableCableManagement],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tableCableManagement,
        },
      ],
    },
  },
  {
    name: 'tablePrivacyScreen',
    label: tablePrivacyScreen.label,
    attributes: [tablePrivacyScreen],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tablePrivacyScreen,
        },
      ],
    },
  },
  {
    name: 'tableTopShape',
    label: tableTopShape.label,
    attributes: [tableTopShape],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [tableTopShape.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tableTopShape,
        },
      ],
    },
  },
];

export const workstationDesks = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields, t, tk),
  };
};
