import React from 'react';
import Typography from '@material-ui/core/Typography';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { RouteActions } from '../../navigation';
import { Link as RouterLink } from '../Link';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    separator: {
      fontSize: 20,
    },
  }),
);

export type BreadcrumbItem = {
  label: string;
  route?: RouteActions;
};

export type BreadcrumbsProps = {
  items: BreadcrumbItem[];
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  const classes = useStyles();
  return (
    <MuiBreadcrumbs
      className={classes.root}
      separator={<NavigateNextIcon className={classes.separator} />}
      aria-label="breadcrumb"
      color="primary"
    >
      {items.map(({ label, route }, idx) =>
        route ? (
          <Link component={RouterLink} route={route} key={idx}>
            {label}
          </Link>
        ) : (
          <Typography key={idx}>{label}</Typography>
        ),
      )}
    </MuiBreadcrumbs>
  );
};
