import { State as LocalState } from './reducer';

export type State = {
  inventory: {
    newSku: LocalState;
  };
};

export const root = (state: State) => state.inventory.newSku;

export const skuInput = (state: State) => root(state).skuInput;

export const mainCategoryId = (state: State) => skuInput(state).mainCategoryId;

export const subcategoryId = (state: State) => skuInput(state).subcategoryId;

export const mainCategoryKey = (state: State) => root(state).mainCategory?.key;

export const subcategoryKey = (state: State) => root(state).subcategory?.key;

export const subcategoryName = (state: State) => root(state).subcategory?.name;

export const primaryColor = (state: State) => skuInput(state).primaryColor;

export const secondaryColor = (state: State) => skuInput(state).secondaryColor;

export const manufacturer = (state: State) => skuInput(state).manufacturer;

export const model = (state: State) => skuInput(state).model;

export const functionalityGroups = (state: State) =>
  root(state).functionalityGroups;

export const functionalityValues = (state: State) =>
  root(state).functionalityValues;

export const images = (state: State) => {
  return root(state).images;
};

export const label = (state: State) =>
  [manufacturer(state), model(state), subcategoryName(state)]
    .filter(Boolean)
    .join(' ');

export const isNewManufacturer = (state: State) =>
  root(state).isNewManufacturer;
