import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityFilterGroup,
  FunctionalityFilterRow,
  FunctionalityFilters,
} from '../types';

import { getFields, TableFields } from './fields';

const groups = (
  {
    tableAdjustableHeight,
    tableAdjustableWidth,
    tableFeet,
    tableStackable,
    tableFoldable,
    tableCableSupport,
    tableCableManagement,
    tablePrivacyScreen,
    tableTopShape,
  }: TableFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityFilterGroup[] => [
  {
    label: t(tk.tables.filters.adjustableHeight),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: tableAdjustableHeight,
      },
    ],
  },
  {
    label: t(tk.tables.filters.adjustableWidth),
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: tableAdjustableWidth,
      },
    ],
  },

  {
    label: t(tk.tables.filters.feet),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: tableFeet,
      },
    ],
  },
  {
    label: t(tk.tables.filters.cableSupport),
    singleSelect: true,
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: tableCableSupport,
      },
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: tableCableManagement,
      },
    ],
  },
  {
    label: t(tk.tables.filters.privacyScreen),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: tablePrivacyScreen,
      },
    ],
  },
  {
    label: t(tk.tables.filters.topShape),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: tableTopShape,
      },
    ],
  },
  {
    label: t(tk.tables.filters.stackable),
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: tableStackable,
        label: t(tk.common.filters.yes),
      },
    ],
  },
  {
    label: t(tk.tables.filters.foldable),
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: tableFoldable,
        label: t(tk.common.filters.yes),
      },
    ],
  },
];

export const tables = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityFilters => ({
  groups: groups(getFields(t, tk), t, tk),
  title: t(tk.common.filters.attributes),
});
