import React from 'react';

import { NavLogo } from './NavLogo/NavLogo';
import { UniversalTenantLogo } from './UniversalTenantLogo/UniversalTenantLogo';

type Props = {
  menuOpen?: boolean;
  tenantId?: string;
};

export const TenantLogo: React.FC<Props> = ({ menuOpen = false, tenantId }) => {
  if (tenantId === 'nav') {
    return <NavLogo />;
  }

  return <>{!menuOpen && <UniversalTenantLogo />}</>;
};
