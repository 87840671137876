import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      fontSize: 13,
      width: '100%',
      paddingBottom: 8,
      color: '#586069',
      fontWeight: 600,
      '&:hover,&:focus': {
        color: '#0366d6',
      },
      '& span': {
        width: '100%',
      },
      '& svg': {
        width: 16,
        height: 16,
      },
    },
    tag: {
      marginTop: 3,
      height: 20,
      padding: '.15em 4px',
      fontWeight: 600,
      lineHeight: '15px',
      borderRadius: 2,
    },
    popper: {
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 3,
      width: 300,
      zIndex: 1,
      fontSize: 13,
      color: '#586069',
      backgroundColor: '#f6f8fa',
    },
    header: {
      borderBottom: '1px solid #e1e4e8',
      padding: '8px 10px',
      fontWeight: 600,
    },
    inputBase: {
      width: '100%',
      textAlign: 'center',
      '& input': {
        textAlign: 'center',
        borderRadius: 16,
        backgroundColor: '#FFCC49',
        padding: 7,
        paddingLeft: 15,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #FFCC49',
        fontSize: '0.9285714285714285rem',
        '&:focus': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: '#586069',
      fontSize: 13,
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
    },
    iconSelected: {
      width: 17,
      height: 17,
      marginRight: 5,
      marginLeft: -2,
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    text: {
      flexGrow: 1,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
  }),
);

type Options = readonly {
  label: string;
  disabled?: boolean;
}[];

type Props = {
  options: Options;
  onSubmit: (label: string | null) => void;
};

export const InputButton: React.FC<Props> = props => {
  const classes = useStyles();
  const [value, setValue] = React.useState<string>('');
  const { onSubmit, options } = props;

  const handleSubmit = (
    event: React.ChangeEvent<unknown>,
    newInputValue: string | null,
  ): void => {
    if (newInputValue) {
      onSubmit(newInputValue);
    }

    setValue('');
  };

  return (
    <Autocomplete
      freeSolo
      classes={{
        paper: classes.paper,
        option: classes.option,
        popperDisablePortal: classes.popperDisablePortal,
      }}
      options={options.map(option => option.label)}
      onChange={handleSubmit}
      renderInput={params => (
        <InputBase
          value={value}
          placeholder="Add new ..."
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus
          className={classes.inputBase}
        />
      )}
    />
  );
};
