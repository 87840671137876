import React from 'react';

import { Props } from '../types';

import { useStyles } from './styles';

export const ArrowLeft = (props: Props) => {
  const classes = useStyles(props);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      className={classes.root}
      {...props}
    >
      <path
        d="M8 2.94L4.91 6 8 9.06l-.951.94L3 6l4.049-4L8 2.94z"
        fill="#3A352D"
      />
    </svg>
  );
};
