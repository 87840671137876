import React, { FC, ReactNode } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Divider } from '../../../common/components/Divider';
import { useTranslation } from '../../../translations';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { ArrowLeft } from '../../../common/components/icons/24px/ArrowLeft';
import { Close } from '../../../common/components/icons/12px/Close';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: ({ withPadding }: Props) => ({
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        padding: withPadding ? theme.spacing(3, 12, 0) : undefined,
      },
    }),
    iconWrapper: {
      width: 32,
    },
    closeIcon: {
      fontSize: 12,
      '& path': {
        fill: theme.palette.primary.light,
      },
    },
    absoluteCloseButton: {
      position: 'absolute',
      right: 16,
      top: 16,
      color: theme.palette.primary.light,
    },
    divider: {
      backgroundColor: theme.palette.primary.veryLight,
      margin: theme.spacing(0, 3),
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: theme.spacing(1.5),
    },
    loading: {
      display: 'flex',
      position: 'absolute',
      background: theme.palette.background.default,
      top: 64,
      bottom: 0,
      left: 0,
      right: 0,
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: theme.zIndex.modal,
    },
  }),
);

type Props = {
  DialogProps?: Partial<DialogProps>;
  open: boolean;
  onBack?: () => void;
  onClose?: () => void;
  title: string;
  titleId?: string;
  actions?: ReactNode;
  loading?: boolean;
  withPadding?: boolean;
};

export const RegistrationDialog: FC<Props> = props => {
  const {
    children,
    open,
    onClose,
    onBack,
    actions,
    loading,
    title,
    titleId,
    DialogProps,
    withPadding,
  } = props;
  const classes = useStyles(props);
  const { t, tk } = useTranslation('inventory');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const absoluteCloseButton = !isMobile && withPadding;

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      data-testid={titleId}
      aria-labelledby={titleId}
      PaperProps={{
        className: classes.paper,
        ...DialogProps?.PaperProps,
      }}
      {...DialogProps}
    >
      {onClose && absoluteCloseButton && (
        <div className={classes.absoluteCloseButton}>
          <Link component="button" color="inherit" onClick={onClose}>
            {t(tk.actions.close)}
          </Link>
          <IconButton aria-label="close" onClick={onClose} size="small">
            <Close className={classes.closeIcon} />
          </IconButton>
        </div>
      )}
      {loading && (
        <div className={classes.loading}>
          <LoadingIndicator />
        </div>
      )}
      <DialogTitle className={classes.title} disableTypography id={titleId}>
        <div className={classes.iconWrapper}>
          {onBack && (
            <IconButton onClick={onBack} size="small">
              <ArrowLeft />
            </IconButton>
          )}
        </div>
        <Typography variant={isMobile ? 'body1' : 'h5'}>{title}</Typography>
        <div className={classes.iconWrapper}>
          {onClose && !absoluteCloseButton && (
            <IconButton aria-label="close" onClick={onClose} size="small">
              <Close />
            </IconButton>
          )}
        </div>
      </DialogTitle>
      <Divider className={classes.divider} thickness={2} />
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
