import { AdSkuInstancePayload } from '../inventory/graphql/types';

export const adSkuInstanceMock: AdSkuInstancePayload = {
  adSku: {
    id: '7dac2453-39b7-4dea-9764-5a862910f5c6',
    manufacturer: 'Ikea',
    model: 'Markus',
    maxHeight: 100,
    maxLength: 90,
    maxWidth: 80,
    minHeight: 70,
    minLength: 60,
    minWidth: 50,
    mainCategory: {
      id: '7d608851-8c28-4d07-99ff-d89b27138adb',
      name: 'Chairs',
      key: 'chairs',
      __typename: 'MainCategory',
    },
    subcategory: {
      id: 'c7e01fff-1fca-4a5d-b50c-286fc1d6b58c',
      name: 'Office chairs',
      key: 'officeChairs',
      __typename: 'Subcategory',
    },
    images: [],
    __typename: 'AdSku',
  },
  id: '78492154-2d4f-4b89-bbbd-203c2dd307bb',
  status: 'in storage',
  condition: 'worn',
  processingStage: null,
  locationNumber: 'L02',
  salesPrice: '300',
  salesPeriodDateFrom: '2021-09-05',
  salesPeriodDateTo: '2022-03-21',
  __typename: 'AdSkuInstance',
};
