import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useTranslation } from '../../../../translations';
import { ResolvedSku } from '../../../types';

const useStyles = makeStyles(theme =>
  createStyles({
    topBar: {
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
    },
    title: {
      marginTop: theme.spacing(4),
      color: theme.palette.background.default,
    },
    bottomBar: ({ isReady }: Props) => ({
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      bottom: 0,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      backgroundColor: isReady ? theme.palette.background.default : 'none',
      zIndex: theme.zIndex.drawer, // have to be on top of view finder of react-qr-reader
    }),
    button: {
      borderColor: theme.palette.background.default,
      color: theme.palette.background.default,
    },
    successText: {
      color: theme.palette.success.main,
      fontWeight: 700,
      margin: theme.spacing(3, 0, 1),
    },
    skuText: ({ isReady }: Props) => ({
      marginBottom: theme.spacing(3),
      textAlign: 'center',
      color: isReady
        ? theme.palette.primary.main
        : theme.palette.background.default,
    }),
  }),
);

type Props = {
  sku: Pick<
    ResolvedSku,
    'manufacturer' | 'model' | 'primaryColor' | 'secondaryColor' | 'images'
  >;
  isReady: boolean;
  onEnterCode?: () => void;
  onConfirm: () => void;
};

export const ScannerBar: FC<Props> = props => {
  const { sku, isReady, onEnterCode, onConfirm } = props;
  const classes = useStyles(props);
  const { t, tk } = useTranslation('inventory');

  return (
    <>
      <div className={classes.topBar}>
        <Typography variant="h6" className={classes.title}>
          {t(tk.newSkuInstanceView.summaryStep.scanner.title)}
        </Typography>
      </div>
      <div className={classes.bottomBar}>
        {isReady && (
          <Typography className={classes.successText}>
            {t(tk.newSkuInstanceView.summaryStep.scanner.successText)}
          </Typography>
        )}
        <div className={classes.skuText}>
          <Typography variant="body1">{sku.manufacturer}</Typography>
          <Typography variant="caption">{sku.model}</Typography>
        </div>
        <Box pb={6}>
          {isReady && (
            <Button onClick={onConfirm} size="small" color="secondary">
              {t(tk.actions.continue)}
            </Button>
          )}
          {!isReady && (
            <Button
              onClick={onEnterCode}
              variant="outlined"
              size="small"
              className={classes.button}
            >
              {t(tk.newSkuInstanceView.summaryStep.scanner.enterCode)}
            </Button>
          )}
        </Box>
      </div>
    </>
  );
};
