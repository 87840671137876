import React from 'react';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { DsGreen } from '../../../../theme/colors';
import { AdSkuInstanceFieldsFragment } from '../../../graphql';

const useStyles = makeStyles(theme =>
  createStyles({
    priceChip: {
      marginTop: theme.spacing(1),
      borderRadius: '10px',
      backgroundColor: DsGreen.veryLight,
    },
  }),
);

type Range = {
  from?: number;
  to?: number;
};

type Props = {
  instances: Array<AdSkuInstanceFieldsFragment>;
};

const calculateFrom = (previousValue?: number, currentValue?: number) =>
  previousValue && currentValue
    ? currentValue < previousValue && currentValue !== 0
      ? currentValue
      : previousValue
    : previousValue || currentValue;

const calculateTo = (previousValue?: number, currentValue?: number) =>
  previousValue && currentValue
    ? currentValue > previousValue
      ? currentValue
      : previousValue
    : previousValue || currentValue;

export const PriceRange: React.FC<Props> = ({ instances }) => {
  const classes = useStyles();

  const { from, to } = instances.reduce<Range>(({ from, to }, instance) => {
    const { salesPrice } = instance;
    const parsedSalesPrice = salesPrice ? parseFloat(salesPrice) : undefined;
    return {
      from: calculateFrom(from, parsedSalesPrice),
      to: calculateTo(to, parsedSalesPrice),
    };
  }, {});

  return (
    <div>
      {from && to && (
        <Chip
          className={classes.priceChip}
          size="small"
          label={
            <Typography variant="caption">
              {' '}
              <LocalOfferIcon fontSize="inherit" />
              {` ${from} - ${to} kr`}
            </Typography>
          }
        />
      )}
    </div>
  );
};
