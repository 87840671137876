import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { TenantsManagementView } from '../TenantsManagementView/TenantsManagementView';
import { Tenant } from '../../../inventory/graphql';
import { useTranslation } from '../../../translations';

type Props = {
  tenants: Pick<Tenant, 'name' | 'id'>[];
  handleAddTenant: () => void;
  handleAddOffice: (tenantId: string) => void;
  handleEdit: (tenantName: string, tenantId: string) => void;
};

export const AdminPanelView: React.FC<Props> = ({
  tenants,
  handleAddTenant,
  handleAddOffice,
  handleEdit,
}) => {
  const { t, tk } = useTranslation('admin');

  return (
    <Container>
      <Typography variant="h4">{t(tk.adminPanel.editPartner)}</Typography>
      <TenantsManagementView
        tenants={tenants}
        handleAddTenant={handleAddTenant}
        handleAddOffice={handleAddOffice}
        handleEdit={handleEdit}
      />
    </Container>
  );
};
