import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

type DateInputProps = {
  name: string;
  handleChange: (x: any) => void;
  value: any;
  label: string;
};

export const DateInput = (props: DateInputProps) => (
  <KeyboardDatePicker
    label={props.label}
    autoOk
    clearable
    name={props.name}
    margin="normal"
    value={props.value || null}
    inputVariant="outlined"
    format={'dd.MM.yyyy'}
    onChange={date => {
      props.handleChange(date);
    }}
    placeholder="dd.mm.yyyy"
    InputProps={{ notched: false }}
    InputLabelProps={{
      shrink: true,
    }}
  />
);
