import { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import mapValues from 'lodash/mapValues';

import { forceType } from './forceType';

type ActionsInner<A, K extends keyof A> = {
  [key in K]: A[key] extends (...args: infer T) => unknown
    ? (...args: T) => void
    : never;
};
type Actions<A> = ActionsInner<A, keyof A>;

export const createActions = <T extends object>(actions: T) => (): Actions<
  T
> => {
  const dispatch = useDispatch();

  const mapping = useCallback(
    function <A extends (...args: unknown[]) => unknown>(action: A) {
      return (...args: Parameters<A>): void => {
        dispatch(action(...args));
      };
    },
    [dispatch],
  );

  return forceType<Actions<typeof actions>>(
    useMemo(() => mapValues(actions, mapping), [mapping]),
  );
};
