import { actionTypes } from './actionTypes';

export const setOfficeId = (officeId: string) => ({
  type: actionTypes.SET_OFFICE_ID,
  officeId,
});

export const reset = () => ({
  type: actionTypes.RESET,
});

export type Actions = ReturnType<typeof setOfficeId | typeof reset>;
