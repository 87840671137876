import { useMemo } from 'react';

import { Maybe } from '../../../common/types';
import { useSkuTemplate } from '../../context/useSkuTemplate';
import {
  getResolvedFunctionalityConfig,
  useFunctionalityConfiguration,
} from '../../models';
import { ResolvedSku } from '../../types';

export const useFunctionalityConfig = (sku: Maybe<ResolvedSku>) => {
  const skuTemplate = useSkuTemplate();
  const configuration = useFunctionalityConfiguration();
  return useMemo(() => {
    if (
      !sku ||
      !sku.subcategory ||
      sku.subcategory.__typename !== 'Subcategory'
    ) {
      return null;
    }
    return getResolvedFunctionalityConfig(
      configuration,
      sku.subcategory.key,
      skuTemplate.fields,
    );
  }, [configuration, sku, skuTemplate.fields]);
};
