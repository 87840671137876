export default {
  colors: {
    black: 'Black',
    darkGray: 'Dark Gray',
    lightGray: 'Light Gray',
    white: 'White',
    darkBlue: 'Dark Blue',
    darkGreen: 'Dark Green',
    transparent: 'Transparent',
    steel: 'Steel',
    lightWood: 'Light Wood',
    darkWood: 'Dark Wood',
    redWood: 'Red Wood',
    beige: 'Beige',
    red: 'Red',
    burgundy: 'Burgundy',
    brass: 'Brass',
    bronze: 'Bronze',
    brushedSteel: 'Brushed Steel',
    brown: 'Brown',
    lightBlue: 'Light Blue',
    orange: 'Orange',
    yellow: 'Yellow',
    salmon: 'Salmon',
    turquoise: 'Turquoise',
    mint: 'Mint',
    lightGreen: 'Light Green',
    pink: 'Pink',
    purple: 'Purple',
  },
} as const;
