import React, { FC } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { extractQuery } from '../../../../common/utils/extractQuery';
import { PreregistrationSuccessStep as PreregistrationSuccessStepComponent } from '../../../components/NewSkuInstanceView/PreregistrationSuccessStep';
import { SkuQuery, useSkuQuery } from '../../../graphql';
import { SkuPayload } from '../../../graphql/types';
import * as officeSelectors from '../../../redux/office/selectors';
import * as selectors from '../../../redux/newSkuInstance/selectors';
import { NewInstanceStepProps, StepId } from '../types';
import { useOffices } from '../../../context/useOffices';
import { useResolvedSku } from '../../../utils/sku';
import { LoadingIndicator } from '../../../../common/components/LoadingIndicator';
import { useNavigation } from '../../../../common/navigation';
import { useNewSkuInstanceActions } from '../../../redux/newSkuInstance';

export const PreregistrationSuccessStep: FC<StepComponentProps<
  NewInstanceStepProps
>> = ({ extraProps: { skuId }, onGoToStep }) => {
  const count = useSelector(selectors.count);
  const success = useSelector(selectors.preregistrationSuccess);
  const officeId = useSelector(officeSelectors.officeId);
  const { reset } = useNewSkuInstanceActions();
  const offices = useOffices();
  const nav = useNavigation();
  const office = officeId ? offices.find(o => o.id === officeId) : null;

  const rawSkuQuery = useSkuQuery({
    variables: { id: skuId },
  });
  const skuQuery = extractQuery<SkuQuery, SkuPayload>(
    rawSkuQuery,
    'Sku',
    data => data.sku,
  );
  const sku = useResolvedSku(skuQuery.data);

  useEffect(() => {
    if (!office) {
      onGoToStep(StepId.Location);
    }
    if (!success) {
      onGoToStep(StepId.Form);
    }
  }, [success, office, onGoToStep]);

  useUnmount(() => reset());

  if (skuQuery.loading || !success) {
    return <LoadingIndicator />;
  }

  return (
    <PreregistrationSuccessStepComponent
      sku={sku}
      count={count || 0}
      nav={nav}
    />
  );
};
