import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { StepComponentProps } from '../../../common/components/Wizard/types';
import * as selectors from '../../redux/newSku/selectors';
import { StepId } from '../RegistrationView/types';

export const fieldNames = ['manufacturer', 'model'];

export const useAssertCategory = (
  onGoToStep: StepComponentProps['onGoToStep'],
) => {
  const subcategoryName = useSelector(selectors.subcategoryName);

  useEffect(() => {
    if (!subcategoryName) {
      onGoToStep(StepId.Welcome);
    }
  }, [onGoToStep, subcategoryName]);
};
