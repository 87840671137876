import { useMemo } from 'react';

import { useTranslation } from '../../translations';
import { ResolvedOffice } from '../context/types';
import {
  FieldDefinition,
  FormLayout,
} from '../../common/components/Form/types';
import { SkuInstanceInput } from '../graphql';
import ensure from '../../common/utils';
import { useOffices } from '../context/useOffices';
import { useSkuInstanceTemplate } from '../context/useSkuInstanceTemplate';
import { SkuTemplatePayload } from '../graphql/types';

import { FieldOverrides, resolveFields } from './utils';

export const scanSkuInstanceFormFields = [
  'officeId',
  'officeFloorId',
  'status',
  'condition',
  'note',
] as const;

export type SupportedSkuInstanceField = typeof scanSkuInstanceFormFields[number];

export type SkuInstanceFormInput = Pick<
  SkuInstanceInput,
  SupportedSkuInstanceField
>;

const getOverrides = (offices: ResolvedOffice[]): FieldOverrides => ({
  officeId: {
    type: 'SelectFieldDefinition',
    options: offices.map(office => ({
      label: `${office.street} ${office.streetNumber}${
        office.building ? office.building : ''
      } [${office.zipCode} ${office.city}]`,
      value: office.id,
    })),
  },
  officeFloorId: {
    type: 'SelectFieldDefinition',
    disabled: values => !values.officeId,
    strict: true,
    options: values =>
      values.officeId
        ? ensure(offices.find(c => c.id === values.officeId)).officeFloors.map(
            floor => ({
              label: floor.floorLabel,
              value: floor.id,
            }),
          )
        : [],
  },
  note: {
    rows: 3,
  },
});

export const getScanSkuInstanceFormFields = (
  templateFields: SkuTemplatePayload['fields'],
  offices: ResolvedOffice[],
  keys: readonly SupportedSkuInstanceField[] = scanSkuInstanceFormFields,
) => {
  return resolveFields(templateFields, getOverrides(offices), keys);
};

export const useScanSkuInstanceFormFields = (
  keys = scanSkuInstanceFormFields,
): Record<string, FieldDefinition> => {
  const offices = useOffices();
  const { fields } = useSkuInstanceTemplate();

  return useMemo(() => getScanSkuInstanceFormFields(fields, offices, keys), [
    fields,
    offices,
    keys,
  ]);
};

export const useScanSkuInstanceFormLayout = (): FormLayout => {
  const { t, tk } = useTranslation('inventory');
  return useMemo(
    () => [
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 12 },
            name: 'officeId',
            label: t(tk.fieldLabels.officeId),
          },
          {
            gridItemProps: { xs: 12 },
            name: 'officeFloorId',
            label: t(tk.fieldLabels.officeFloorId),
          },
          {
            gridItemProps: { xs: 12 },
            name: 'status',
            label: t(tk.fieldLabels.status),
          },
          {
            gridItemProps: { xs: 12 },
            name: 'condition',
            label: t(tk.fieldLabels.condition),
          },
          {
            gridItemProps: { xs: 12 },
            name: 'note',
            label: t(tk.fieldLabels.note),
            fieldOverrides: {},
          },
        ],
      },
    ],
    [t, tk],
  );
};
