import { useMemo } from 'react';
import * as yup from 'yup';
import { TFunction } from 'i18next';

import {
  FieldDefinition,
  Options,
  SelectFieldDefinition,
} from '../../../../common/components/Form/types';
import { ResolvedOffice } from '../../../context/types';
import { AdSkuInstanceInput } from '../../../graphql';
import { FieldOverrides, resolveFields } from '../../../forms/utils';
import { procurementCost, dateField } from '../../../forms/useSkuInstanceForm';
import { useTranslation } from '../../../../translations';
import { useAdSkuInstanceTemplate } from '../../../context/useAdSkuInstanceTemplate';

const instanceFieldNames = [
  'officeId',
  'condition',
  'status',
  'processingStage',
  'salesPeriodDateFrom',
  'salesPeriodDateTo',
  'salesPeriodTimestampFrom',
  'salesPeriodTimestampTo',
  'salesPrice',
] as const;

export const preregistrationStatus = 'pre-registered';

export type FormDataStepInputs = Pick<
  AdSkuInstanceInput,
  typeof instanceFieldNames[number]
>[];

export type FormStepData = {
  count: number;
  adSkuInstance: Pick<AdSkuInstanceInput, typeof instanceFieldNames[number]>;
};

export type FormValues = Pick<
  AdSkuInstanceInput,
  typeof instanceFieldNames[number]
> & {
  count: number;
  locationNumber: string;
};

const getOverrides = (
  offices: ResolvedOffice[],
  t: TFunction,
): FieldOverrides => ({
  locationNumber: {
    type: 'SelectFieldDefinition',
    options: offices.map(office => ({
      label: `${office.locationNumber}`,
      value: office.id,
    })),
  },
  salesPrice: procurementCost(t),
  salesPeriodDateFrom: dateField('salesPeriodDateFrom', t),
  salesPeriodDateTo: dateField('salesPeriodDateTo', t),
  condition: {
    validation: yup.string().when(['isPreregistration', 'isSameStatus'], {
      is: (isPreregistration, isSameStatus) =>
        !isPreregistration && isSameStatus,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  },
  status: {
    validation: yup.string().when(['isPreregistration', 'isSameStatus'], {
      is: (isPreregistration, isSameStatus) =>
        !isPreregistration && isSameStatus,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  },
});

export const useNewAdSkuInstanceFormFields = (
  offices: ResolvedOffice[],
  keys = [...instanceFieldNames, 'locationNumber'],
): Record<string, FieldDefinition> => {
  const { fields } = useAdSkuInstanceTemplate();
  const { t } = useTranslation('inventory');

  return useMemo(() => {
    const resolvedFields = resolveFields(
      fields,
      getOverrides(offices, t),
      keys,
    );

    return {
      ...resolvedFields,
      status: {
        ...resolvedFields.status,
        options: ((resolvedFields.status as SelectFieldDefinition)
          .options as Options).filter(o => o.value !== preregistrationStatus),
      },
      count: {
        type: 'NumberFieldDefinition',
        required: true,
      },
    };
  }, [fields, offices, t, keys]);
};
