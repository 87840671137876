import React, { FC, useMemo } from 'react';
import { useUnmount } from 'react-use';

import { Wizard } from '../../../common/components/Wizard';
import { StepComponent } from '../../../common/components/Wizard/types';
import {
  RegistrationParams,
  RouteActions,
  useNavigation,
} from '../../../common/navigation';
import { useNewSkuInstanceActions } from '../../redux/newSkuInstance';
import { makeUseStepState } from '../../utils/wizard';

import { FormStep } from './steps/FormStep';
import { SummaryStep } from './steps/SummaryStep';
import { NewAdInstanceStepProps, StepId } from './types';

type Props = {
  adSkuId: string;
};

export const steps = [
  {
    id: StepId.Form,
    component: FormStep as StepComponent<NewAdInstanceStepProps>,
  },
  {
    id: StepId.Summary,
    component: SummaryStep,
  },
];

export const NewAdSkuInstanceView: FC<Props> = ({ adSkuId }) => {
  const nav = useNavigation();
  const { reset } = useNewSkuInstanceActions();

  const useStepState = useMemo(
    () =>
      makeUseStepState(
        nav.newAdSkuInstance as (params: RegistrationParams) => RouteActions,
        { adSkuId },
      ),
    [nav, adSkuId],
  );

  useUnmount(() => reset());

  return (
    <Wizard
      steps={steps}
      extraProps={{ adSkuId }}
      useStepState={useStepState}
    />
  );
};
