import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { useTranslation } from '../../../../translations';
import {
  ResolvedFieldDefinition,
  FieldProps,
  FormFieldProps,
  UiKit,
} from '../types';

import { ControlledTextField } from './ControlledTextField';
import { ControlledDateField } from './ControlledDateField';
import { ControlledCheckbox } from './ControlledCheckbox';

const adornment = (field: ResolvedFieldDefinition) =>
  field.type === 'TextFieldDefinition' || field.type === 'NumberFieldDefinition'
    ? field.endAdornment
    : undefined;

export const materialUiKit: UiKit = {
  Row: ({ children }) => (
    <Grid container spacing={2}>
      {children}
    </Grid>
  ),
  Field: ({
    field,
    gridItemProps,
    control,
    error,
    name,
    label,
    noValueLabel,
  }: FieldProps & { noValueLabel?: string }) => {
    let component = null;

    const fieldProps: FormFieldProps = {
      fullWidth: true,
      label: label || name,
      id: `form-input-${name}`,
      name,
      required: field.required,
      control,
      endAdornment: adornment(field),
      disabled: field.disabled,
      error,
    };
    switch (field.type) {
      case 'SelectFieldDefinition': {
        const availableOptions = field.required
          ? field.options
          : [
              {
                label: noValueLabel || '-',
                value: '',
              },
              ...field.options,
            ];

        component = (
          <ControlledTextField
            {...fieldProps}
            select={true}
            options={availableOptions}
          />
        );
        break;
      }
      case 'NumberFieldDefinition': {
        component = <ControlledTextField {...fieldProps} type="number" />;
        break;
      }
      case 'DateFieldDefinition': {
        component = <ControlledDateField {...fieldProps} />;
        break;
      }
      case 'TextFieldDefinition': {
        const rows = field.rows;
        component = (
          <ControlledTextField
            {...fieldProps}
            {...(rows ? { multiline: true, rows } : {})}
          />
        );
        break;
      }
      case 'BooleanFieldDefinition': {
        component = <ControlledCheckbox {...fieldProps} />;
        break;
      }
      default:
        component = <ControlledTextField {...fieldProps} />;
    }
    return (
      <Grid item {...gridItemProps}>
        <Box mb={1}>{component}</Box>
      </Grid>
    );
  },
  Submit: (disabled: boolean) => {
    const { t, tk } = useTranslation('common');
    return (
      <Box mt={3} textAlign={{ xs: 'left', md: 'right' }}>
        <Button
          disabled={disabled}
          type="submit"
          color="secondary"
          variant="contained"
        >
          {t(tk.actions.save)}
        </Button>
      </Box>
    );
  },
};
