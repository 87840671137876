import React, { useCallback } from 'react';

import { AdminPanelView as AdminPanelViewComponent } from '../../components/AdminPanelView/AdminPanelView';
import { useNavigation } from '../../../common/navigation';
import { useUserContext } from '../../../inventory/context/useUserContext';
import { useCurrentTenantId } from '../../../inventory/context/TenantContext';

export const AdminPanelView: React.FC = () => {
  const nav = useNavigation();
  const { data } = useUserContext();
  const contextTenantId = useCurrentTenantId();

  const handleTenantChange = useCallback(
    (tenantName: string, tenantId: string) => {
      nav.adminPanelTenant({ id: tenantId }, { tenantId: tenantName }).go();
    },
    [nav],
  );

  return (
    <AdminPanelViewComponent
      tenants={data.tenants}
      handleAddTenant={() => nav.adminPanelAddTenant().go()}
      handleAddOffice={tenantId =>
        nav.adminPanelAddOffice({ id: tenantId || contextTenantId }).go()
      }
      handleEdit={(tenantName: string, tenantId: string) =>
        handleTenantChange(tenantName, tenantId)
      }
    />
  );
};
