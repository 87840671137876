import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';

import { cabinet } from './groups/cabinet';
import { fileCabinet } from './groups/fileCabinet';
import { shelves } from './groups/shelves';
import { storageBox } from './groups/storageBox';
import { storageDrawer } from './groups/storageDrawer';

export const storage = (t: TFunction, tk: TKeys<'functionality'>) => ({
  cabinet: cabinet(t, tk),
  fileCabinet: fileCabinet(t, tk),
  shelves: shelves(t, tk),
  storageBox: storageBox(t, tk),
  storageDrawer: storageDrawer(t, tk),
});
