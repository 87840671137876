import { ValuesFunc } from './types';

type CallIfCallable = <A, R, C extends R | ValuesFunc<R>>(
  check: C,
  values: A,
) => R;

export const callIfCallable: CallIfCallable = (check, values) => {
  return typeof check === 'function' ? check(values) : check;
};
