import React from 'react';
import Container from '@material-ui/core/Container';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { FormFeedback } from '../../../common/components/FormFeedback';
import { Form } from '../../../common/components/Form';
import { GraphQlError } from '../../../common/types';
import { FormFields, FormLayout } from '../../../common/components/Form/types';
import { FormHeading } from '../AddTenantView/FormHeading';
import { CancelButton } from '../Common/CancelButton';
import { OfficeList } from '../OfficeList/OfficeList';
import { Office, UpdateTenantInput } from '../../../inventory/graphql';

const useStyles = makeStyles(theme =>
  createStyles({
    cancelButton: {
      marginTop: theme.spacing(1),
      float: 'right',
    },
    offices: {
      textAlign: 'left',
    },
  }),
);

type Props = {
  error?: GraphQlError;
  fields: FormFields;
  onSubmit: (data: UpdateTenantInput['tenant']) => void;
  tenant?: UpdateTenantInput['tenant'];
  layout: FormLayout;
  headingText: string;
  handleOfficeEdit: (id: string) => void;
  offices: Array<Omit<Office, 'officeFloors'>>;
  onCancel: () => void;
};

export const EditTenantView: React.FC<Props> = ({
  error,
  fields,
  onSubmit,
  tenant,
  layout,
  headingText,
  handleOfficeEdit,
  offices,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <>
      <Container>
        <FormHeading variant="h4" text={headingText}></FormHeading>
        <FormFeedback error={error} />
        <Form
          fields={fields}
          layout={layout}
          onSubmit={onSubmit}
          defaultValues={tenant}
        />
        <Box className={classes.cancelButton}>
          <CancelButton onCancel={onCancel} />
        </Box>
        <Grid container>
          <Grid item md={6} sm={12}>
            <OfficeList
              offices={offices}
              handleEdit={handleOfficeEdit}
            ></OfficeList>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
