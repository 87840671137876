import React, { FC, useState } from 'react';

import { ButtonListItem } from '../../../../common/components/ButtonListItem';
import {
  FunctionalityEditGroup,
  FunctionalityValue,
  ResolvedFunctionalityGroup,
} from '../../../models';
import { getGroupEditLabel } from '../../../models/getGroupLabel';

import { FunctionalityGroupDialog } from './FunctionalityGroupDialog';
import { needsConfiguration } from './utils';

type Props = {
  group: ResolvedFunctionalityGroup & {
    editConfig: Extract<
      ResolvedFunctionalityGroup['editConfig'],
      { type: FunctionalityEditGroup.Group }
    >;
  };
  values: Record<string, FunctionalityValue>;
  initialValues: Record<string, FunctionalityValue>;
  onChange?: (changed: Record<string, FunctionalityValue>) => void;
};

const getVariant = (group: Props['group'], values: Props['values']) => {
  if (needsConfiguration(group, values)) {
    return 'error';
  }
  if (
    group.editConfig.editableFieldKeys.some(key => !!values[key]) &&
    !(group.hideRule && group.hideRule(values))
  ) {
    return 'success';
  }
  return 'standard';
};

export const ComplexFunctionalityGroup: FC<Props> = ({
  group,
  values,
  initialValues,
  onChange,
  ...props
}) => {
  const { label } = group;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const variant = getVariant(group, values);

  const secondaryText =
    getGroupEditLabel(group, values, variant !== 'error') || '';
  return (
    <>
      <ButtonListItem
        variant={variant}
        primaryText={label}
        secondaryText={secondaryText}
        onClick={handleOpen}
        withIcon
        {...props}
      />
      <FunctionalityGroupDialog
        group={group}
        values={values}
        initialValues={initialValues}
        onChange={onChange}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};
