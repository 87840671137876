import React, { FC, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SkuListView as SkuListViewComponent } from '../../components/SkuListView';
import { ErrorView } from '../../../common/components/ErrorView';
import { useNavigation, useSelectedFilters } from '../../../common/navigation';
import { useCategories } from '../../context/useCategories';
import { useTranslation } from '../../../translations';
import { getPageCount } from '../../utils/getPageCount';
import { skuTemplateName } from '../../consts';
import { SelectedFilters } from '../../utils/useFilters';
import { useResolvedSkusQuery } from '../../utils/sku';
import { SkuListReportView } from '../SkuListReportView/SkuListReportView';
import { SpreadsheetGeneratorView } from '../../components/SkuInstanceListSpreadsheetGenerator/SpreadsheetGeneratorView';
import { SkuList } from '../../components/SkuListView/SkuList';

import { useSkuFilters } from './useSkuFilters';
import { getHeaderData, SkuType } from './utils';

const useIsReport = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  return searchParams.has('print');
};

type Props = {
  id: string;
};

const PAGE_LIMIT = 16;

export const SkuListView: FC<Props> = () => {
  const nav = useNavigation();
  const selectedFilters = useSelectedFilters();
  const setSelectedFilters = (newFilters: SelectedFilters) => {
    nav.skus({ filters: newFilters }).go();
  };
  const isReport = useIsReport();

  const { filters, queryFilters, functionalityFilters } = useSkuFilters(
    selectedFilters,
  );
  const [page, setPage] = useState(1);
  const [error, setError] = useState<Error>();

  const categories = useCategories();
  const { t, tk } = useTranslation('inventory');
  const { breadcrumbs, title } = useMemo(
    () =>
      getHeaderData(
        queryFilters,
        categories,
        nav,
        t(tk.skuListView.title),
        SkuType.skus,
      ),
    [categories, queryFilters, t, nav, tk.skuListView.title],
  );

  const result = useResolvedSkusQuery({
    variables: {
      filters: queryFilters,
      slice: {
        offset: (page - 1) * PAGE_LIMIT,
        limit: PAGE_LIMIT,
      },
    },
  });

  const getAllResult = useResolvedSkusQuery({
    variables: {
      filters: queryFilters,
      slice: {
        offset: 0,
        limit: 0,
      },
    },
  });

  const handlePrint = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('print', 'true');
    window.open(url.toString(), '_blank');
  };

  const skus = result.data?.skus || [];

  const allSkus = getAllResult.data?.skus || [];

  if (result.error || error) {
    return <ErrorView error={result.error} />;
  }

  const handleFilterChange = (field: string, values: string[]) => {
    setPage(1);
    if (field === 'subcategoryId') {
      setSelectedFilters({
        [field]: values,
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        [field]: values,
      });
    }
  };

  const pageCount = getPageCount(
    result.data?.metadata.matches,
    skuTemplateName,
    PAGE_LIMIT,
  );

  if (isReport && !result.loading) {
    return <SkuListReportView queryFilters={queryFilters} skus={skus} />;
  }

  return (
    <SkuListViewComponent
      breadcrumbs={breadcrumbs}
      filters={filters}
      loading={result.loading}
      onFilterChange={handleFilterChange}
      onPageChange={setPage}
      page={page}
      pageCount={pageCount}
      matches={result.data?.metadata.matches}
      title={title}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      functionalityFilters={functionalityFilters}
      onPrint={handlePrint}
      skuList={<SkuList skus={skus} nav={nav} />}
      generateSheet={
        <SpreadsheetGeneratorView
          page={page}
          allSkus={allSkus}
          pageSkus={skus}
          setError={setError}
          queryFilters={queryFilters}
        />
      }
    />
  );
};
