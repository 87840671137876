import Button from '@material-ui/core/Button';
import React from 'react';

import { useTranslation } from '../../../translations';

type Props = {
  onCancel: () => void;
};

export const CancelButton: React.FC<Props> = ({ onCancel }) => {
  const { t, tk } = useTranslation('admin');

  return <Button onClick={onCancel}>{t(tk.adminPanel.cancel)}</Button>;
};
