import React, { FC, Fragment, HTMLAttributes, MouseEvent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem/ListItem';
import List from '@material-ui/core/List/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Link from '@material-ui/core/Link';

import { useTranslation } from '../../../../translations';
import { RegistrationDialog } from '../../common/RegistrationDialog';
import { FunctionalityPreviewProps } from '../SkuPreview/FunctionalityPreview';
import { SkuInput } from '../../../graphql';
import { StepId } from '../../../containers/NewSkuView/types';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: 0,
    },
    secondary: {
      color: theme.palette.primary.veryLight,
    },
    divider: {
      backgroundColor: theme.palette.background.alternative,
      '&:last-child': {
        display: 'none',
      },
    },
  }),
);

type Props = {
  skuInput: SkuInput;
  functionality?: FunctionalityPreviewProps;
  open?: boolean;
  onClose: () => void;
  onGoToStep: (stepId: string, returnTo?: string) => void;
};

export const EditDialog: FC<Props> = ({
  skuInput,
  functionality,
  open = false,
  onGoToStep,
  onClose,
}) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  const {
    manufacturer,
    model,
    images,
    primaryColor,
    secondaryColor,
    minLength,
    maxLength,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  } = skuInput;

  const items = [
    {
      primary: t(tk.newSkuView.summaryStep.goBackAndEdit.items.brand),
      secondary: manufacturer,
      step: StepId.Model,
    },
    {
      primary: t(tk.newSkuView.summaryStep.goBackAndEdit.items.model),
      secondary: model,
      step: StepId.Model,
    },
    {
      primary: t(tk.newSkuView.summaryStep.goBackAndEdit.items.image),
      secondary: t(tk.newSkuView.summaryStep.goBackAndEdit.image, {
        count: images?.length || 0,
      }),
      step: StepId.Image,
    },
    {
      primary: t(tk.newSkuView.summaryStep.goBackAndEdit.items.functionality),
      secondary: t(tk.newSkuView.summaryStep.goBackAndEdit.functionality, {
        count: functionality
          ? Object.values(functionality.values).filter(Boolean).length
          : 0,
      }),
      step: StepId.Functionality,
    },
    {
      primary: t(tk.newSkuView.summaryStep.goBackAndEdit.items.colors),
      secondary: primaryColor?.concat(
        secondaryColor ? ` & ${secondaryColor}` : '',
      ),
      step: StepId.Color,
    },
    {
      primary: t(tk.newSkuView.summaryStep.goBackAndEdit.items.dimensions),
      secondary: [
        minLength,
        maxLength,
        minWidth,
        maxWidth,
        minHeight,
        maxHeight,
      ].join(', '),
      step: StepId.Dimensions,
    },
  ];

  const handleClick = (step: string) => (event: MouseEvent) => {
    event.preventDefault();
    onGoToStep(step, StepId.Summary);
  };

  return (
    <RegistrationDialog
      title={t(tk.newSkuView.summaryStep.goBackAndEdit.title)}
      open={open}
      titleId="summary-step-edit-dialog"
      onClose={onClose}
    >
      <List className={classes.root} dense>
        {items.map(({ primary, secondary, step }) => (
          <Fragment key={primary}>
            <ListItem
              ContainerProps={
                {
                  'data-testid': `EditDialogItem-${primary}`,
                } as HTMLAttributes<HTMLDivElement>
              }
            >
              <ListItemText
                primary={primary}
                secondary={secondary}
                secondaryTypographyProps={{ className: classes.secondary }}
              />
              <ListItemSecondaryAction>
                <Link href="#" onClick={handleClick(step)}>
                  {t(tk.newSkuView.summaryStep.goBackAndEdit.action)}
                </Link>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider className={classes.divider} component="li" />
          </Fragment>
        ))}
      </List>
    </RegistrationDialog>
  );
};
