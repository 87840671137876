import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
  FunctionalityValue,
} from '../types';
import { mapFieldKeys, someTrue } from '../utils';
import { hideRuleByFieldValue } from '../rules';

import { ChairFunctionalities } from './types';
import { getFields, ChairFields } from './fields';

const defaultValues: ChairFunctionalities = {
  chairBack: false,
  chairBackFixed: false,
  chairBackAdjustableHeight: false,
  chairBackAdjustableAngle: false,
  chairLeaningBack: false,
  chairSeat: false,
  chairSeatFixed: false,
  chairSeatAdjustablePosition: false,
  chairSeatAdjustableAngle: false,
  chairArmrests: false,
  chairArmrestsFixed: false,
  chairArmrestsAdjustablePosition: false,
  chairArmrestsAdjustableHeight: false,
  chairArmrestsAdjustableWidth: false,
  chairArmrestsAdjustableLength: false,
  chairFeetCount: '5',
  chairNeckrest: 'none',
  chairFootring: false,
  chairStackable: false,
  chairFoldable: false,
};

const processValues = (values: Record<string, FunctionalityValue>) => {
  return {
    ...values,
    chairBack: someTrue(values, [
      'chairBackFixed',
      'chairBackAdjustableHeight',
      'chairBackAdjustableAngle',
    ]),
    chairSeat: someTrue(values, [
      'chairSeatFixed',
      'chairSeatAdjustablePosition',
      'chairSeatAdjustableAngle',
    ]),
    chairArmrests: someTrue(values, [
      'chairArmrestsFixed',
      'chairArmrestsAdjustablePosition',
      'chairArmrestsAdjustableHeight',
      'chairArmrestsAdjustableWidth',
      'chairArmrestsAdjustableLength',
    ]),
  };
};

// eslint-disable-next-line max-lines-per-function
const groups = (
  {
    chairAdjustableHeight,
    chairBack,
    chairBackLength,
    chairBackFixed,
    chairBackAdjustableHeight,
    chairBackAdjustableAngle,
    chairSeat,
    chairSeatFixed,
    chairSeatAdjustablePosition,
    chairSeatAdjustableAngle,
    chairArmrestsFixed,
    chairArmrestsAdjustablePosition,
    chairArmrestsAdjustableHeight,
    chairArmrestsAdjustableWidth,
    chairArmrestsAdjustableLength,
    chairFeetCount,
    chairFeetType,
    chairNeckrest,
    chairFootring,
  }: ChairFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'adjustableHeight',
    label: t(tk.chairs.groups.adjustableHeight.label),
    attributes: [chairAdjustableHeight],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      noValueLabel: t(tk.chairs.groups.adjustableHeight.noValueLabel),
      requiredFieldKeys: [chairAdjustableHeight.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairAdjustableHeight,
          limitedTo: ['standard', 'long'],
        },
      ],
    },
  },
  {
    name: 'back',
    label: t(tk.chairs.groups.back.label),
    attributes: [chairBackFixed, chairBackLength],
    functionalities: [chairBackAdjustableHeight, chairBackAdjustableAngle],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [chairBack.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairBackLength,
        },
        {
          type: FunctionalityEditRow.BooleanGroup,
          promptLabel: t(tk.chairs.groups.back.promptLabel),
          exclusiveFields: [chairBackFixed.key],
          fields: [
            chairBackFixed,
            chairBackAdjustableHeight,
            chairBackAdjustableAngle,
          ],
        },
      ],
    },
  },
  {
    name: 'seat',
    label: t(tk.chairs.groups.seat.label),
    attributes: [chairSeatFixed],
    functionalities: [chairSeatAdjustablePosition, chairSeatAdjustableAngle],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [chairSeat.key],
      rows: [
        {
          type: FunctionalityEditRow.BooleanGroup,
          promptLabel: t(tk.chairs.groups.seat.promptLabel),
          exclusiveFields: [chairSeatFixed.key],
          fields: [
            chairSeatFixed,
            chairSeatAdjustablePosition,
            chairSeatAdjustableAngle,
          ],
        },
      ],
    },
  },
  {
    name: 'armrests',
    label: t(tk.chairs.groups.armrests.label),
    attributes: [chairArmrestsFixed],
    functionalities: [
      chairArmrestsAdjustablePosition,
      chairArmrestsAdjustableHeight,
      chairArmrestsAdjustableWidth,
      chairArmrestsAdjustableLength,
    ],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      rows: [
        {
          type: FunctionalityEditRow.BooleanGroup,
          promptLabel: t(tk.chairs.groups.armrests.promptLabel),
          exclusiveFields: [chairArmrestsFixed.key],
          fields: [
            chairArmrestsFixed,
            chairArmrestsAdjustablePosition,
            chairArmrestsAdjustableHeight,
            chairArmrestsAdjustableWidth,
            chairArmrestsAdjustableLength,
          ],
        },
      ],
    },
  },
  {
    name: 'feet',
    label: t(tk.chairs.groups.feet.label),
    attributes: [chairFeetCount, chairFeetType],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [chairFeetCount.key, chairFeetType.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairFeetCount,
        },
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairFeetType,
        },
      ],
    },
  },
  {
    name: 'neckrest',
    label: t(tk.chairs.groups.neckrest.label),
    attributes: [chairNeckrest],
    functionalities: [],
    hideRule: hideRuleByFieldValue(chairNeckrest.key, 'none'),
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [chairNeckrest.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairNeckrest,
        },
      ],
    },
  },
  {
    name: 'footring',
    label: t(tk.chairs.groups.footring.label),
    attributes: [chairFootring],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      field: chairFootring,
    },
  },
];

export const officeChairs = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields, t, tk),
    processValues,
  };
};
