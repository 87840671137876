import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useMemo } from 'react';
import Container from '@material-ui/core/Container';

import { GraphQlError } from '../../../common/types';
import { ResolvedAdSku } from '../../types';
import { AdSkuInstancePayload } from '../../graphql/types';
import { FormFields } from '../../../common/components/Form/types';
import { BreadcrumbItem } from '../../../common/components/Breadcrumbs';
import { SkuInstanceInput } from '../../graphql';
import { DeleteSkuInstanceDialogProps } from '../SkuInstanceView/DeleteSkuInstanceDialog';
import { useTranslation } from '../../../translations';
import { SkuInstanceTopMenu } from '../SkuInstanceView/SkuInstanceTopMenu';
import { SkuCard } from '../common/SkuCard/SkuCard';
import { FormFeedback } from '../../../common/components/FormFeedback';
import { Form } from '../../../common/components/Form';
import { useAdSkuInstanceFormLayout } from '../../forms/useAdSkuInstanceForm';

import { getInitialValues } from './getInitialValues';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: '45% 55%',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '100%',
        paddingBottom: theme.spacing(3),
        maxWidth: theme.breakpoints.values.md,
      },
    },
    cardSection: {
      width: '100%',
      display: 'flex',
      height: 'min-content',
      paddingRight: theme.spacing(3),
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        paddingBottom: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    formSection: {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(12),
      },
    },
    menuSection: {
      width: '100%',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.only('xs')]: {
        marginBottom: theme.spacing(3),
      },
    },
  }),
);

type Props = {
  error?: GraphQlError;
  adSku: ResolvedAdSku;
  adSkuInstance: AdSkuInstancePayload;
  fields: FormFields;
  breadcrumbs: BreadcrumbItem[];
  onUpdate?: (data: Omit<SkuInstanceInput, 'skuId'>) => void;
  onViewSku: () => void;
  updateCompleted: boolean;
  deleteMutation: Pick<
    DeleteSkuInstanceDialogProps,
    'onDelete' | 'mutationResult'
  >;
};

export const AdSkuInstanceView: FC<Props> = ({
  error,
  fields,
  adSku,
  adSkuInstance,
  breadcrumbs,
  deleteMutation,
  onUpdate,
  onViewSku,
  updateCompleted,
}) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');
  const layout = useAdSkuInstanceFormLayout();
  const initialValues = useMemo(() => getInitialValues(adSkuInstance), [
    adSkuInstance,
  ]);

  if (adSkuInstance.adSku.__typename !== 'AdSku') {
    return null;
  }

  return (
    <Container className={classes.root}>
      <section className={classes.menuSection}>
        <SkuInstanceTopMenu
          breadcrumbs={breadcrumbs}
          deleteMutation={deleteMutation}
        />
      </section>
      <section />
      <section className={classes.cardSection}>
        <SkuCard
          onClick={onViewSku}
          sku={adSku}
          title={t(tk.skuInstanceView.instanceCard.example, {
            skuInstanceId: adSkuInstance.id,
          })}
          btnLabel={t(tk.adSkuInstanceView.instanceCard.button)}
        />
      </section>
      <section data-testid="SkuInstanceView" className={classes.formSection}>
        <FormFeedback
          error={error}
          success={updateCompleted}
          sucessMessage={t(tk.skuInstanceView.updateSuccess)}
        />
        <Form
          fields={fields}
          layout={layout}
          defaultValues={initialValues}
          onSubmit={onUpdate}
        />
      </section>
    </Container>
  );
};
