import React, { FC, useMemo } from 'react';

import { useSkuQuery, SkuQuery } from '../../graphql';
import { SkuView as SkuViewComponent } from '../../components/SkuView';
import { ErrorView } from '../../../common/components/ErrorView';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { extractQuery } from '../../../common/utils/extractQuery';
import { SkuPayload } from '../../graphql/types';
import { useCategories } from '../../context/useCategories';
import { SkuInstanceListView } from '../SkuInstanceListView';
import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../translations';
import { SkuFormInput, useSkuFormFields } from '../../forms';
import { useResolvedSku } from '../../utils/sku';
import { FunctionalityValueMap } from '../../models';
import { useColorMapping } from '../../context/useColorMapping';

import { getBreadcrumbs } from './breadcrumbs';
import { useFunctionalityConfig } from './useFunctionalityConfig';
import { useUpdateSku } from './useUpdateSku';
import { useUpdateSkuFunctionalities } from './useUpdateSkuFunctionalities';

type Props = {
  id: string;
};

export const SkuView: FC<Props> = ({ id }) => {
  const nav = useNavigation();
  const { t, tk } = useTranslation('inventory');
  const rawSkuQuery = useSkuQuery({
    variables: { id },
  });
  const skuQuery = extractQuery<SkuQuery, SkuPayload>(
    rawSkuQuery,
    'Sku',
    data => data.sku,
  );
  const sku = useResolvedSku(skuQuery.data);
  const fields = useSkuFormFields();
  const colorMapping = useColorMapping();
  const functionalityConfig = useFunctionalityConfig(sku);
  const categories = useCategories();
  const { updateSku, mutationResult, updateCompleted } = useUpdateSku();
  const {
    updateSkuFunctionalities,
    ...updateFunctionalitiesMutation
  } = useUpdateSkuFunctionalities();

  const breadcrumbs = useMemo(
    () => getBreadcrumbs(categories, t(tk.skuListView.title), sku, nav.skus),
    [categories, nav, sku, t, tk.skuListView.title],
  );

  if (skuQuery.error || !sku) {
    if (skuQuery.loading) {
      return <LoadingIndicator />;
    }
    return <ErrorView error={skuQuery.error} />;
  }

  if (
    skuQuery.data?.functionalities.__typename !== 'AvailableFunctionalityList'
  ) {
    return <ErrorView error={skuQuery.data?.functionalities} />;
  }

  const handleUpdate = (skuInput: SkuFormInput) => {
    updateSku(sku, skuInput);
  };

  const handleFunctionalityUpdate = (values: FunctionalityValueMap) => {
    if (!functionalityConfig) {
      return;
    }
    updateSkuFunctionalities(functionalityConfig, sku, values);
  };

  const editFunctionality = functionalityConfig
    ? {
        config: functionalityConfig,
        onUpdate: handleFunctionalityUpdate,
        ...updateFunctionalitiesMutation,
      }
    : undefined;
  return (
    <>
      <SkuViewComponent
        breadcrumbs={breadcrumbs}
        categories={categories}
        colorMapping={colorMapping}
        error={mutationResult.error}
        sku={sku}
        fields={fields}
        onUpdate={handleUpdate}
        updateCompleted={updateCompleted}
        editFunctionality={editFunctionality}
      />
      <SkuInstanceListView sku={sku} />
    </>
  );
};
