import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import cn from 'classnames';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 8,
      border: `1px solid ${theme.palette.primary.light}`,
    },
    selected: {
      color: theme.palette.success.main,
      border: `1px solid ${theme.palette.success.main}`,
      backgroundColor: theme.palette.success.veryLight,
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
    },
  }),
);

export type Props = {
  className?: string;
  onClick: () => void;
  selected: boolean;
};

export const SelectableButton: React.FC<Props> = ({
  children,
  className,
  onClick,
  selected,
}) => {
  const classes = useStyles();
  return (
    <Button
      className={cn([
        classes.root,
        { [classes.selected]: selected },
        className,
      ])}
      variant="outlined"
      onClick={onClick}
      size="small"
      fullWidth
    >
      {children}
    </Button>
  );
};
