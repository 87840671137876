import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { ImageStep as ImageStepComponent } from '../../../components/NewSkuView/ImageStep';
import * as selectors from '../../../redux/newSku/selectors';
import { useAssertCategory } from '../useAssertCategory';
import { useNewSkuActions } from '../../../redux/newSku';
import { useUploadImages } from '../../common/useUploadImages';

const IMAGE_REQUIRED = true;

export const ImageStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onGoBack,
  onGoToStep,
  onStepCompleted,
}) => {
  const backLabel = useSelector(selectors.label);
  const images = useSelector(selectors.images);
  const { setImages } = useNewSkuActions();
  const { uploadImage, mutationResult } = useUploadImages(setImages);

  useAssertCategory(onGoToStep);

  const handleSubmit = () => {
    if (mutationResult.loading || (!images.length && IMAGE_REQUIRED)) {
      return;
    }
    onStepCompleted();
  };

  const handleChange = (file: File) => {
    uploadImage({
      variables: { file },
    });
  };

  return (
    <ImageStepComponent
      backLabel={backLabel}
      images={images}
      loading={mutationResult.loading}
      onGoBack={onGoBack}
      onChange={handleChange}
      onSubmit={handleSubmit}
      required={IMAGE_REQUIRED}
      number={number}
      totalCount={totalCount}
    />
  );
};
