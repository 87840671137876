import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { extractQuery } from '../../../../common/utils/extractQuery';
import { AdSkuQuery, useAdSkuQuery } from '../../../graphql';
import { AdSkuPayload } from '../../../graphql/types';
import * as selectors from '../../../redux/newAdSkuInstance/selectors';
import { NewAdInstanceStepProps } from '../types';
import { LoadingIndicator } from '../../../../common/components/LoadingIndicator';
import { useNavigation } from '../../../../common/navigation';
import { SummaryStep as SummaryStepComponent } from '../../../components/NewAdSkuInstanceView/SummaryStep';
import { useNewAdSkuInstanceActions } from '../../../redux/newAdSkuInstance';
import { useResolvedAdSku } from '../../../utils/adSku';

export const SummaryStep: FC<StepComponentProps<NewAdInstanceStepProps>> = ({
  extraProps: { adSkuId },
}) => {
  const count = useSelector(selectors.count);
  const instance = useSelector(selectors.instance);
  const { reset } = useNewAdSkuInstanceActions();
  const nav = useNavigation();

  const rawAdSkuQuery = useAdSkuQuery({
    variables: { id: adSkuId },
  });
  const adSkuQuery = extractQuery<AdSkuQuery, AdSkuPayload>(
    rawAdSkuQuery,
    'AdSku',
    data => data.adSku,
  );
  const adSku = useResolvedAdSku(adSkuQuery.data);

  useUnmount(() => reset());

  if (adSkuQuery.loading) {
    return <LoadingIndicator />;
  }

  return (
    <SummaryStepComponent
      adSku={adSku}
      adSkuInstance={instance}
      count={count || 0}
      nav={nav}
    />
  );
};
