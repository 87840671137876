import React, { FC } from 'react';

import {
  IndicatorChip,
  IndicatorChipProps,
} from '../../../../common/components/IndicatorChip/IndicatorChip';
import { useTranslation } from '../../../../translations';

type Props = Omit<IndicatorChipProps, 'color'> & {
  status: string;
};

const colorMapping: Record<string, 'error' | 'secondary' | 'quaternary'> = {
  discarded: 'error',
  'under maintenance': 'secondary',
  'pre-registered': 'quaternary',
};

const getColor = (status: string) => {
  if (colorMapping[status]) {
    return colorMapping[status];
  }
  return 'success';
};

export const StatusChip: FC<Props> = ({ status, label, ...props }) => {
  const { t, tk } = useTranslation('inventory');

  return (
    <IndicatorChip
      label={label || t(tk.skuInstanceListView.item.status, { status })}
      color={getColor(status)}
      {...props}
    />
  );
};
