import { BreadcrumbItem } from '../../../common/components/Breadcrumbs';
import {
  GoOptions,
  NavigationConfig,
  SkusParams,
} from '../../../common/navigation';
import { Maybe } from '../../../common/types';
import { ResolvedMainCategory } from '../../context/types';
import { ResolvedAdSku, ResolvedSku } from '../../types';

export const getBreadcrumbs = (
  categories: ResolvedMainCategory[],
  title: string,
  sku: Maybe<ResolvedSku | ResolvedAdSku>,
  route: (
    skusParams?: SkusParams,
    config?: NavigationConfig,
  ) => { go(options?: GoOptions): void; url: string },
) => {
  const items: BreadcrumbItem[] = [
    {
      label: title,
      route: route(),
    },
  ];
  if (!sku) {
    return items;
  }
  const { mainCategory, subcategory, manufacturer, model } = sku;
  if (mainCategory?.__typename === 'MainCategory') {
    const resolvedMainCategory = categories.find(c => c.id === mainCategory.id);
    if (resolvedMainCategory) {
      items.push({
        label: resolvedMainCategory.name,
        route: route({
          filters: {
            subcategoryId: resolvedMainCategory.subcategories.map(s => s.id),
          },
        }),
      });
    }
  }
  if (subcategory?.__typename === 'Subcategory') {
    items.push({
      label: subcategory.name,
      route: route({
        filters: { subcategoryId: [subcategory.id] },
      }),
    });
  }
  items.push(
    {
      label: manufacturer,
      route: route({
        filters: { manufacturer: [manufacturer] },
      }),
    },
    {
      label: `${manufacturer} ${model}`,
    },
  );
  return items;
};
