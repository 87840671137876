import React, { FC } from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';

import { ResolvedSku } from '../../types';
import { SkuImage } from '../common/SkuImage';

const useStyles = makeStyles(theme =>
  createStyles({
    media: {
      borderRadius: 8,
      maxWidth: '100%',
    },
    root: {
      [theme.breakpoints.up('md')]: {
        textAlign: 'right',
        // height of one form row
        marginTop: 103,
      },
    },
  }),
);

type Props = {
  sku: ResolvedSku;
};

export const PhotoGallery: FC<Props> = ({ sku }) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();

  if (!sku.images.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      <SkuImage
        image={sku.images[0]}
        sizes={`(max-width: ${breakpoints.values.md}px) 100%, 50%`}
      />
    </div>
  );
};
