import { Button, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { GoOptions } from '../../../../../common/navigation';
import { Link } from '../../../../../common/components/Link';
import { useTranslation } from '../../../../../translations';

type Props = {
  route: { url: string; go(options?: GoOptions): void };
};

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: 'none',
      whiteSpace: 'normal',
    },
  }),
);

export const CallToActionSkuInstancesItem = ({ route }: Props) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');

  const reuseCallToActionProps = {
    component: Link,
    route: route,
    className: classes.button,
  };

  return (
    <>
      <Tooltip title={t(tk.skuInstanceListView.item.callToAction) as string}>
        <Button {...reuseCallToActionProps}>
          <MoreHorizIcon />
        </Button>
      </Tooltip>
    </>
  );
};
