import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination, { PaginationProps } from '@material-ui/lab/Pagination';

import { CheckboxList } from '../CheckboxList';
import { Maybe, OmitTypename } from '../../types';
import { ListMetadataMatch } from '../../../inventory/graphql';
import { RangeFilter } from '../RangeFilter';
import { SelectedFilters } from '../../../inventory/utils/useFilters';
import { TextInputFilter } from '../TextInputFilter/TextInputFilter';

import { CurrentFilterProps, CurrentFilters } from './CurrentFilters';
import { ListFilter } from './types';
import { mapCurrentFilters } from './utils';

const useStyles = makeStyles(theme =>
  createStyles({
    filter: {
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    pagination: {
      justifyContent: 'center',
    },
  }),
);

export type ListProps = {
  filters: ListFilter[];
  loading?: boolean;
  matches?: OmitTypename<ListMetadataMatch>[];
  page?: Maybe<number>;
  pageCount?: Maybe<number>;
  excludeCurrentFilters?: string[];
  extraCurrentFilters?: CurrentFilterProps['filters'];
  onFilterChange: (field: string, values: string[]) => void;
  onRangeFilterChange?: (
    field: string,
    from: number | string | undefined,
    to: number | string | undefined,
  ) => void;
  selectedFilters: SelectedFilters;
  onTextInputChange?: (field: string, value: string) => void;
  onPageChange: (page: number) => void;
};

export const useList = ({
  filters,
  page,
  pageCount,
  onPageChange,
  onFilterChange,
  onRangeFilterChange,
  excludeCurrentFilters,
  onTextInputChange,
  selectedFilters,
  extraCurrentFilters = [],
}: ListProps) => {
  const classes = useStyles();

  return {
    Filters: () => (
      <>
        {filters.map(option => {
          switch (option.type) {
            case 'range': {
              return (
                <RangeFilter
                  name={option.name}
                  labelFrom={'From'}
                  labelTo={'To'}
                  adornment={'kr'}
                  handleChange={onRangeFilterChange}
                  selected={selectedFilters}
                  title={option.title}
                  type={option.name === 'salesPeriod' ? 'date' : 'number'}
                />
              );
            }
            case 'text': {
              return (
                <TextInputFilter
                  title={option.title}
                  name={option.name}
                  handleChange={onTextInputChange}
                  value={selectedFilters[option.name]}
                />
              );
            }
            default: {
              return (
                <CheckboxList
                  key={option.name}
                  {...option}
                  className={classes.filter}
                  onChange={(values: string[]) =>
                    onFilterChange(option.name, values)
                  }
                />
              );
            }
          }
        })}
      </>
    ),
    CurrentFilters: (
      props: Omit<CurrentFilterProps, 'filters' | 'onFilterChange'>,
    ) => (
      <CurrentFilters
        {...props}
        filters={mapCurrentFilters(
          filters.filter(f => !excludeCurrentFilters?.includes(f.name)),
        ).concat(extraCurrentFilters)}
        onFilterChange={onFilterChange}
      />
    ),
    Pagination: (props: PaginationProps) =>
      page && pageCount && pageCount > 1 ? (
        <Pagination
          classes={{ ul: classes.pagination }}
          page={page}
          count={pageCount}
          onChange={(_, page) => onPageChange(page)}
          color="primary"
          size="large"
          {...props}
        />
      ) : null,
  };
};
