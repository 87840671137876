import React from 'react';
import { useMemo } from 'react';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { Form } from '../../../../common/components/Form';
import { makeUiKit } from '../uiKit';
import {
  FieldValues,
  FormFields,
  UnpackNestedValue,
} from '../../../../common/components/Form/types';

import { useModelStepFormLayout } from './useModelStepForm';

type Props<T extends FieldValues> = {
  fields: FormFields;
  backLabel: string;
  initialValues?: Partial<T>;
  number: number;
  totalCount: number;
  onSubmit: (data: UnpackNestedValue<T>) => void;
  onGoBack?: () => void;
  withSerialNumber?: boolean;
};

export const ModelStep = <T extends FieldValues>({
  backLabel,
  fields,
  initialValues,
  number,
  totalCount,
  onSubmit,
  onGoBack,
  withSerialNumber,
}: Props<T>) => {
  const { t, tk } = useTranslation('inventory');
  const { t: tc, tk: tkc } = useTranslation('common');
  const layout = useModelStepFormLayout(withSerialNumber);
  const uiKit = useMemo(() => makeUiKit(tc(tkc.actions.confirm)), [tc, tkc]);

  return (
    <WizardStep
      backLabel={backLabel}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      title={t(tk.newSkuView.modelStep.title)}
      data-testid="ModelStep"
    >
      <Form
        defaultValues={initialValues}
        fields={fields}
        layout={layout}
        onSubmit={onSubmit}
        uiKit={uiKit}
      />
    </WizardStep>
  );
};
