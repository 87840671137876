import { ComponentType, useMemo } from 'react';

import { FieldProps, FormLayout } from '../../common/components/Form/types';
import { useTranslation } from '../../translations';
import {
  gridItemStandard,
  gridItemTwoColumn,
} from '../../inventory/forms/common';

export const useTenantFormBasicLayout = (): FormLayout => {
  const { t, tk } = useTranslation('inventory');
  return useMemo(
    () => [
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'city',
            label: t(tk.fieldLabels.city),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'zipCode',
            label: t(tk.fieldLabels.zipCode),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'street',
            label: t(tk.fieldLabels.street),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'streetNumber',
            label: t(tk.fieldLabels.streetNumber),
          },
        ],
      },
    ],
    [t, tk],
  );
};

export const useTenantFormWithOfficeFloorLayout = (
  ComponentOfficeFloors: ComponentType<FieldProps> | undefined,
): FormLayout => {
  const { t, tk } = useTranslation('inventory');
  return useMemo(
    () => [
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'city',
            label: t(tk.fieldLabels.city),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'zipCode',
            label: t(tk.fieldLabels.zipCode),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'street',
            label: t(tk.fieldLabels.street),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'streetNumber',
            label: t(tk.fieldLabels.streetNumber),
          },
          {
            gridItemProps: gridItemTwoColumn,
            name: 'officeFloors',
            Component: ComponentOfficeFloors,
            label: t(tk.fieldLabels.officeFloorList),
          },
        ],
      },
    ],
    [t, tk, ComponentOfficeFloors],
  );
};
