import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityFilterGroup,
  FunctionalityFilterRow,
  FunctionalityFilters,
} from '../types';

import { getFields, ChairFields } from './fields';

// eslint-disable-next-line max-lines-per-function
const groups = (
  {
    chairAdjustableHeight,
    chairBack,
    chairBackLength,
    chairBackFixed,
    chairBackAdjustableHeight,
    chairBackAdjustableAngle,
    chairArmrests,
    chairArmrestsFixed,
    chairArmrestsAdjustablePosition,
    chairArmrestsAdjustableHeight,
    chairArmrestsAdjustableWidth,
    chairArmrestsAdjustableLength,
    chairFeetCount,
    chairFeetType,
    chairNeckrest,
    chairFootring,
    chairStackable,
    chairFoldable,
    chairLeaningBack,
  }: ChairFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityFilterGroup[] => [
  {
    label: t(tk.chairs.filters.height),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: chairAdjustableHeight,
      },
    ],
  },
  {
    label: t(tk.chairs.filters.backLength),
    singleSelect: true,
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairBack,
        inverse: true,
        label: t(tk.common.filters.none),
      },
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: chairBackLength,
      },
    ],
  },
  {
    label: t(tk.chairs.filters.back),
    singleSelect: true,
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairLeaningBack,
      },
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairBackFixed,
      },
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairBackAdjustableHeight,
      },
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairBackAdjustableAngle,
      },
    ],
  },
  {
    label: t(tk.chairs.filters.armrests),
    singleSelect: true,
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairArmrests,
        inverse: true,
        label: t(tk.common.filters.none),
      },
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairArmrestsFixed,
      },
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairArmrestsAdjustablePosition,
      },
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairArmrestsAdjustableHeight,
      },
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairArmrestsAdjustableWidth,
      },
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairArmrestsAdjustableLength,
      },
    ],
  },
  {
    label: t(tk.chairs.filters.neckrest),
    singleSelect: true,
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: chairNeckrest,
      },
    ],
  },
  {
    label: t(tk.chairs.filters.feetType),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: chairFeetType,
      },
    ],
  },
  {
    label: t(tk.chairs.filters.feetCount),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'select',
        field: chairFeetCount,
      },
    ],
  },
  {
    label: t(tk.chairs.filters.footring),
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairFootring,
        label: t(tk.common.filters.yes),
      },
    ],
  },
  {
    label: t(tk.chairs.filters.stackable),
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairStackable,
        label: t(tk.common.filters.yes),
      },
    ],
  },
  {
    label: t(tk.chairs.filters.foldable),
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: chairFoldable,
        label: t(tk.common.filters.yes),
      },
    ],
  },
];

export const chairs = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityFilters => ({
  groups: groups(getFields(t, tk), t, tk),
  title: t(tk.common.filters.attributes),
});
