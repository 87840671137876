import React from 'react';

import { DateInput } from './DateInput';
import { NumberInput } from './NumberInput';

type FilterInputProps = {
  name: string;
  type: string;
  label: string;
  selected?: Record<string, string[]>;
  handleChange: (val: any) => void;
  value: any;
};

export const FilterInput: React.FC<FilterInputProps> = props => (
  <>
    {props.type === 'date' ? (
      <DateInput
        value={props.value}
        label={props.label}
        name={props.name}
        handleChange={props.handleChange}
      />
    ) : (
      <NumberInput
        value={props.value}
        name={props.name}
        label={props.label}
        handleChange={props.handleChange}
        adornment={'kr'}
      />
    )}
  </>
);
