import { useMemo } from 'react';

import { useTranslation } from '../../../translations';
import { Config, SelectedFilters, useFilters } from '../../utils/useFilters';
import { useAdSkuTemplate } from '../../context/useAdSkuTemplate';
import { useCategories } from '../../context/useCategories';

export const useAdSkusAddInstanceFilters = (
  selectedFilters: SelectedFilters,
) => {
  const adSkuTemplate = useAdSkuTemplate();
  const adSkuInstanceTemplate = useAdSkuTemplate();
  const templates = useMemo(() => [adSkuTemplate, adSkuInstanceTemplate], [
    adSkuTemplate,
    adSkuInstanceTemplate,
  ]);
  const { t, tk } = useTranslation('inventory');
  const categories = useCategories();

  const fullConfig: Config = useMemo(
    () => [
      {
        type: 'custom',
        getFilter: () => {
          return {
            nodes: categories.map(category => ({
              name: category.name,
              items: category.subcategories.map(subcategory => ({
                label: subcategory.name,
                value: subcategory.id,
              })),
            })),
            type: 'nodes',
            name: 'subcategoryId',
            title: t(tk.skuListView.filterLabels.category) || 'subcategoryId',
            singleSelect: true,
          };
        },
      },
      {
        field: 'manufacturer',
        template: 'adSku',
        singleSelect: false,
        type: 'items',
      },
      {
        field: 'primaryColor',
        template: 'adSku',
        singleSelect: false,
        type: 'colors',
        title: t(tk.skuListView.filterLabels.primaryColor),
      },
    ],
    [categories, t, tk],
  );

  return {
    ...useFilters(fullConfig, templates, adSkuTemplate, selectedFilters),
  };
};
