import { TFunction } from 'i18next';
import { useMemo } from 'react';

import { FilterDefinitionWithText } from '../../common/components/CheckboxList/types';
import { logError } from '../../common/utils/logError';
import { useTranslation } from '../../translations';
import { ColorHelper, useColorHelper } from '../context/useColorMapping';
import { SkuTemplatePayload } from '../graphql/types';

type ConfigItem = {
  field: string;
  template: string;
  singleSelect: boolean;
  type: 'items' | 'colors';
  title?: string;
};

export type CustomFilter = {
  type: 'custom';
  getFilter: () => FilterDefinitionWithText;
};

export type Config = (ConfigItem | CustomFilter)[];

export type SelectedFilters = {
  [key: string]: string[];
};

export type SelectedRangeFilters = {
  [key: string]: { from: string; to: string };
};

const getFilter = (
  field: SkuTemplatePayload['fields'][number],
  fieldConfig: ConfigItem,
  t: TFunction,
  colorHelper: ColorHelper,
): FilterDefinitionWithText | undefined => {
  const { singleSelect, title, type } = fieldConfig;
  const name = field.name;
  if (field.__typename !== 'OptionSetFieldDefinition') {
    logError({
      key: 'filters-field-not-optionset',
      message: `Field for filter ${name} is not an option set`,
      fieldName: name,
    });
    return;
  }
  if (!field.optionSet) {
    logError({
      key: 'filters-field-null-optionset',
      message: `Unable to fetch option set for field ${name}`,
      fieldName: name,
    });
    return;
  }

  return {
    items: field.optionSet.values.map(value => ({
      label: value,
      value,
    })),
    type,
    name,
    title: title || t(`fieldLabels.${name}`) || name,
    singleSelect,
    colorHelper,
  };
};

const useFilterOptions = (config: Config, templates: SkuTemplatePayload[]) => {
  const { t } = useTranslation('inventory');
  const colorHelper = useColorHelper();

  return useMemo(() => {
    const filters: FilterDefinitionWithText[] = [];

    config.forEach(fieldConfig => {
      if (fieldConfig.type === 'custom') {
        filters.push(fieldConfig.getFilter());
        return;
      }
      const name = fieldConfig.field;
      const field = templates
        .find(t => t.name === fieldConfig.template)
        ?.fields.find(f => f.name === name);
      if (!field) {
        logError({
          key: 'filters-missing-field',
          message: `Missing field for filter ${name}`,
          fieldName: name,
        });
        return;
      }

      const filter = getFilter(field, fieldConfig, t, colorHelper);
      if (!filter) {
        return;
      }
      filters.push(filter);
    });

    return filters;
  }, [config, templates, t, colorHelper]);
};
export const useFilters = (
  config: Config,
  templates: SkuTemplatePayload[],
  mainTemplate: SkuTemplatePayload,
  selectedFilters: SelectedFilters,
) => {
  const filterOptions = useFilterOptions(config, templates);
  const filters = useMemo(
    () =>
      filterOptions.map(option => ({
        ...option,
        selected: selectedFilters[option.name] || [],
      })),
    [filterOptions, selectedFilters],
  );

  const includeRelated = (mainTemplate: SkuTemplatePayload, field: string) => {
    if (mainTemplate.name === 'adSkuInstance') {
      return false;
    }

    return !mainTemplate.fields.find(f => f.name === field);
  };

  const queryFilters = useMemo(
    () =>
      Object.entries(selectedFilters)
        .map(([field, values]) => ({
          field,
          values,
          includeRelated: includeRelated(mainTemplate, field),
        }))
        .filter(f => f.values.length),
    [selectedFilters, mainTemplate],
  );

  return {
    filters,
    queryFilters,
    selectedFilters,
  };
};
