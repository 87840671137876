import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Trans } from 'react-i18next';

import { ListMetadataMatch } from '../../../graphql';
import { skuTemplateName, skuInstanceTemplateName } from '../../../consts';
import { useTranslation } from '../../../../translations';
import { Row } from '../../../../common/components/Row';
import { OmitTypename } from '../../../../common/types';

type Props = {
  loading?: boolean;
  matches?: OmitTypename<ListMetadataMatch>[];
};

export const SkuListMetadata: FC<Props> = ({ loading, matches }) => {
  const { t, tk } = useTranslation('inventory');

  const skuCount = matches?.find(m => m.template === skuTemplateName)?.count;
  const skuInstanceCount = matches?.find(
    m => m.template === skuInstanceTemplateName,
  )?.count;

  const showData =
    !loading && skuCount !== undefined && skuInstanceCount !== undefined;
  return showData ? (
    <Row>
      <Typography variant="subtitle1">
        {showData ? (
          <Trans
            i18nKey={tk.skuListView.metadata.counts}
            t={t}
            values={{ skuCount, skuInstanceCount }}
          />
        ) : (
          '\u00A0' // returning space to keep layout stable when loading
        )}
      </Typography>
    </Row>
  ) : (
    <></>
  );
};
