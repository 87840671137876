import { useMemo } from 'react';

import { useResolvedSkusQuery } from './sku';
import { usePersistedState } from './usePersistedState';
import { useResolvedAdSkusQuery } from './adSku';

const LIMIT = 20;

export enum LastRegisteredTypes {
  sku = 'last-registered-sku',
  adSku = 'last-registered-ad-sku',
}

export const useLastRegistered = (type: LastRegisteredTypes) => {
  const useLastRegisteredState = usePersistedState(type);
  const [skuIds, setSkuIds] = useLastRegisteredState<string[]>([]);

  const add = (skuId: string) => {
    setSkuIds([skuId, ...skuIds.filter(id => id !== skuId)].slice(0, LIMIT));
  };

  return {
    skuIds,
    add,
  };
};

export const useResolvedLastRegisteredSkus = () => {
  const { skuIds } = useLastRegistered(LastRegisteredTypes.sku);

  const filters = useMemo(
    () => [
      {
        field: 'id',
        values: skuIds,
      },
    ],
    [skuIds],
  );

  const { data } = useResolvedSkusQuery({
    variables: {
      filters,
      slice: {
        offset: 0,
        limit: LIMIT,
      },
    },
  });
  return data?.skus || [];
};

export const useResolvedLastRegisteredAdSkus = () => {
  const { skuIds: adSkuIds } = useLastRegistered(LastRegisteredTypes.adSku);

  const filters = useMemo(
    () => [
      {
        field: 'id',
        values: adSkuIds,
      },
    ],
    [adSkuIds],
  );

  const { data } = useResolvedAdSkusQuery({
    variables: {
      filters,
      slice: {
        offset: 0,
        limit: LIMIT,
      },
    },
  });
  return data?.adSkus || [];
};
