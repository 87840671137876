import { Row, Workbook, Worksheet } from 'exceljs';
import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import { ResolvedSku, ResolvedSkuWithMetadata } from '../../types';
import { SkuInstance } from '../../graphql';
import { formatOfficeAddress } from '../../utils/format';
import { fetchBase64Image } from '../../../common/utils/fetchBase64Image';
import { Maybe } from '../../../common/types';

export const splitSkusArrHelper = (
  skusIdsArr: string[],
  skus: ResolvedSkuWithMetadata[],
) => {
  const splitSkusArr: string[][] = [];
  let splitChunkSkus: string[] = [];
  let splitChunkSkusMatches = 0;

  skusIdsArr.forEach(skuId => {
    const findMetaData = skus.find(({ sku }) => sku.id === skuId);

    const matches = findMetaData?.metadata?.matches || 1;

    if (matches >= 100) {
      return splitSkusArr.push([skuId]);
    }

    if (splitChunkSkusMatches + matches < 125) {
      splitChunkSkusMatches += matches;
      return splitChunkSkus.push(skuId);
    } else {
      splitChunkSkus.push(skuId);
      splitSkusArr.push(splitChunkSkus);

      splitChunkSkus = [];
      splitChunkSkusMatches = 0;
    }
  });

  if (splitChunkSkus.length) {
    splitSkusArr.push(splitChunkSkus);
    splitChunkSkus = [];
    splitChunkSkusMatches = 0;
  }

  return splitSkusArr;
};

const addFieldValue = (value: string | undefined | null): string => {
  return value || '';
};

const getImageHeight = async (
  base64Image: string,
  givenWidth: number,
): Promise<number> => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onerror = () => {
      reject(0);
    };

    image.onload = () => {
      const { height, width } = image;
      const aspectRation = height / width;
      resolve(givenWidth * aspectRation);
    };

    image.src = base64Image;
  });
};

const convertToString = (value: Maybe<number>): string => {
  return value ? value.toString() : '';
};

/* eslint-disable max-lines-per-function */
export function createSpreadsheetGenerator(
  workbook: Workbook,
  worksheet: Worksheet,
) {
  const addSkuInstanceRow = (
    sku: ResolvedSku,
    skuInstance?: SkuInstance,
  ): Row => {
    const { primaryColor, secondaryColor } = sku;

    const row = [
      '',
      addFieldValue(sku.manufacturer),
      addFieldValue(sku.model),
      addFieldValue(sku.serialNumber),
      addFieldValue(primaryColor?.label),
      addFieldValue(secondaryColor?.label),
      addFieldValue(sku.description),
      addFieldValue(convertToString(sku.minWidth)),
      addFieldValue(convertToString(sku.maxWidth)),
      addFieldValue(convertToString(sku.minHeight)),
      addFieldValue(convertToString(sku.maxHeight)),
      addFieldValue(convertToString(sku.minDepth)),
      addFieldValue(convertToString(sku.maxDepth)),
      addFieldValue(convertToString(sku.minLength)),
      addFieldValue(convertToString(sku.maxLength)),
    ];

    if (skuInstance) {
      const skuInstanceRow = [
        addFieldValue(skuInstance.id),
        skuInstance.office?.__typename === 'Office'
          ? formatOfficeAddress(skuInstance.office)
          : '',
        skuInstance.officeFloor?.__typename === 'OfficeFloor'
          ? skuInstance.officeFloor.floorLabel
          : '',
        addFieldValue(skuInstance.room),
        addFieldValue(skuInstance.status),
        addFieldValue(skuInstance.condition),
        addFieldValue(skuInstance.processingStage),
        addFieldValue(skuInstance.processingStageDateFrom),
        addFieldValue(skuInstance.processingStageDateTo),
        addFieldValue(skuInstance.note),
      ];

      row.push(...skuInstanceRow);
    }

    return worksheet.addRow(row);
  };

  const addSkuInstanceLabelsRow = (
    t: TFunction,
    tk: TKeys<'inventory'>,
  ): void => {
    worksheet.columns = [
      { header: '', width: 40 },
      {
        header: t(tk.fieldLabels.manufacturer),
        width: 20,
      },
      {
        header: t(tk.fieldLabels.model),
      },
      {
        header: t(tk.fieldLabels.serialNumber),
        width: 40,
      },
      {
        header: t(tk.fieldLabels.primaryColor.default),
        width: 15,
      },
      {
        header: t(tk.fieldLabels.secondaryColor.default),
        width: 15,
      },
      {
        header: t(tk.fieldLabels.description),
        width: 40,
      },
      {
        header: t(tk.fieldLabels.minWidth),
      },
      {
        header: t(tk.fieldLabels.maxWidth),
      },
      {
        header: t(tk.fieldLabels.minHeight),
      },
      {
        header: t(tk.fieldLabels.maxHeight),
      },
      {
        header: t(tk.fieldLabels.minDepth),
      },
      {
        header: t(tk.fieldLabels.maxDepth),
      },
      {
        header: t(tk.fieldLabels.minLength),
      },
      {
        header: t(tk.fieldLabels.maxLength),
      },
      { header: t(tk.skuInstanceView.topMenu.currentItem), width: 40 },
      {
        header: t(tk.fieldLabels.officeId),
        width: 25,
      },
      {
        header: t(tk.fieldLabels.officeFloorId),
        width: 5,
      },
      {
        header: t(tk.fieldLabels.room),
      },
      {
        header: t(tk.skuInstanceListView.item.status),
        width: 15,
      },
      {
        header: t(tk.skuInstanceListView.item.condition),
        width: 15,
      },
      {
        header: t(tk.fieldLabels.processingStage),
        width: 20,
      },
      {
        header: t(tk.skuReportView.from),
      },
      {
        header: t(tk.skuReportView.to),
      },
      {
        header: t(tk.fieldLabels.note),
      },
    ];

    const row = worksheet.getRow(1);
    row.font = { bold: true, size: 14, name: 'Default' };
  };
  /* eslint-enable max-lines-per-function */

  async function addImage(sku: ResolvedSku, rows: Row[]) {
    try {
      const image =
        sku.images[0] &&
        (sku.images[0].optimizedImages?.find(img => img.size === 400) ||
          sku.images[0]);

      if (image) {
        const imageWidth = 270;
        const base64Image = await fetchBase64Image(image.url);
        const imageHeight = await getImageHeight(base64Image, imageWidth);

        const imageId = workbook.addImage({
          base64: base64Image,
          extension: 'jpeg',
        });

        rows[0].height = (imageHeight * 80) / 100;
        worksheet.addImage(imageId, {
          tl: { col: 0, row: rows[0].number - 1 },
          ext: { width: imageWidth, height: imageHeight },
          editAs: 'oneCell',
        });
      }
    } catch (error) {
      return undefined;
    }
  }

  return {
    addImage,
    addSkuInstanceRow,
    addSkuInstanceLabelsRow,
  };
}
