import {
  FormHelperTextClassKey,
  InputBaseClassKey,
  InputLabelClassKey,
  OutlinedInputClassKey,
  StyleRules,
  SelectClassKey,
  MenuClassKey,
} from '@material-ui/core';

import { DsBlack } from '../colors';
import { typography } from '../typography';
import { breakpoints } from '../breakpoints';

export const MuiInputBase: Partial<StyleRules<InputBaseClassKey>> = {
  root: {
    color: DsBlack.main,
    'label + &': {
      marginTop: 8,
    },
  },
};

export const MuiInputLabel: Partial<StyleRules<InputLabelClassKey>> = {
  root: {
    ...typography.overline,
    color: DsBlack.main,
    marginLeft: -8,
  },
  outlined: {
    '&$shrink': {
      transform: 'translate(14px, -14px)',
    },
  },
};

export const MuiOutlinedInput: Partial<StyleRules<OutlinedInputClassKey>> = {
  root: {
    borderRadius: 8,
    '&$disabled': {
      color: DsBlack.light,
      backgroundColor: DsBlack.veryLight,
    },
  },
  input: {
    borderRadius: 8,
    padding: `12px 16px`,
    '&:focus': {
      backgroundColor: '#ffffff',
    },
    '&$disabled': {
      backgroundColor: DsBlack.ultraLight,
    },
  },
  multiline: {
    ...typography.body2,
    padding: 0,
  },
  inputMultiline: {
    padding: 16,
  },
};

export const MuiFormHelperText: Partial<StyleRules<FormHelperTextClassKey>> = {
  root: {
    '&$error': {
      opacity: 1,
    },
  },
};

export const MuiSelect: Partial<StyleRules<SelectClassKey>> = {
  root: {
    width: '100%',
  },
  icon: {
    top: undefined,
  },
};

export const MuiMenu: Partial<StyleRules<MenuClassKey>> = {
  paper: {
    border: `1px solid ${DsBlack.main}`,
    marginTop: 8,
    [breakpoints.down('xs')]: {
      marginLeft: -8,
    },
  },
};
