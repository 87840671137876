/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type Query = {
  __typename: 'Query';
  appReady?: Maybe<AppStatus>;
  appHealth?: Maybe<AppStatus>;
  adSkuInstances: AdSkuInstancesWithMetadataResponse;
  adSkuInstance: AdSkuInstanceByIdResponse;
  adSkuInstanceTemplate: TemplateResponse;
  adSkus: AdSkusWithMetadataResponse;
  adSku: AdSkuByIdResponse;
  adSkuTemplate: TemplateResponse;
  mainCategories: MainCategoriesResponse;
  mainCategory: MainCategoryByIdResponse;
  mappings: MappingsResponse;
  offices: OfficesResponse;
  office: OfficeByIdResponse;
  optionSets: OptionSetsResponse;
  optionSet: OptionSetByIdResponse;
  skuInstances: SkuInstancesWithMetadataResponse;
  skuInstance: SkuInstanceByIdResponse;
  skuInstancesCrossTenantsByGtinSerial: SkuInstancesCrossTenantsByGtinSerialResponse;
  skuInstanceTemplate: TemplateResponse;
  skus: SkusWithMetadataResponse;
  sku: SkuByIdResponse;
  skuTemplate: TemplateResponse;
  user: UserResponse;
  partner: Partner;
  tenants?: Maybe<TenantsResponse>;
  tenant: TenantResponse;
};

export type QueryAdSkuInstancesArgs = {
  filters?: Maybe<Array<Filter>>;
  rangeFilters?: Maybe<Array<RangeFilter>>;
  unionFilters?: Maybe<Array<UnionFilter>>;
  slice?: Maybe<Slice>;
};

export type QueryAdSkuInstanceArgs = {
  id: Scalars['ID'];
};

export type QueryAdSkusArgs = {
  filters?: Maybe<Array<Filter>>;
  rangeFilters?: Maybe<Array<RangeFilter>>;
  unionFilters?: Maybe<Array<UnionFilter>>;
  criteria?: Maybe<Criteria>;
  slice?: Maybe<Slice>;
};

export type QueryAdSkuArgs = {
  id: Scalars['ID'];
};

export type QueryMainCategoriesArgs = {
  filters?: Maybe<Array<Filter>>;
};

export type QueryMainCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryMappingsArgs = {
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryOfficesArgs = {
  filters?: Maybe<Array<Filter>>;
};

export type QueryOfficeArgs = {
  id: Scalars['ID'];
};

export type QueryOptionSetArgs = {
  id: Scalars['ID'];
};

export type QuerySkuInstancesArgs = {
  filters?: Maybe<Array<Filter>>;
  slice?: Maybe<Slice>;
};

export type QuerySkuInstanceArgs = {
  id: Scalars['ID'];
};

export type QuerySkuInstancesCrossTenantsByGtinSerialArgs = {
  gtin: Scalars['String'];
  serialNumber: Scalars['String'];
};

export type QuerySkusArgs = {
  filters?: Maybe<Array<Filter>>;
  slice?: Maybe<Slice>;
};

export type QuerySkuArgs = {
  id: Scalars['ID'];
};

export type QueryTenantArgs = {
  id: Scalars['ID'];
};

export enum AppStatus {
  Ok = 'Ok',
  Warning = 'Warning',
  Error = 'Error',
}

export type AdSkuInstancesWithMetadataResponse =
  | AdSkuInstanceListWithMetadata
  | UnexpectedError
  | ForbiddenError;

export type AdSkuInstanceByIdResponse =
  | AdSkuInstance
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type Filter = {
  field: Scalars['String'];
  includeRelated?: Maybe<Scalars['Boolean']>;
  values: Array<Scalars['String']>;
};

export type RangeFilter = {
  field: Scalars['String'];
  fromValue: Scalars['String'];
  toValue: Scalars['String'];
  includeRelated?: Maybe<Scalars['Boolean']>;
};

export type UnionFilter = {
  fromField: Scalars['String'];
  fromValue: Scalars['String'];
  toField: Scalars['String'];
  toValue: Scalars['String'];
  includeRelated?: Maybe<Scalars['Boolean']>;
};

export type Slice = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type TemplateResponse =
  | Template
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type Template = {
  __typename: 'Template';
  id: Scalars['ID'];
  name: Scalars['String'];
  fields: Array<FieldDefinition>;
};

export type NotFoundError = Error & {
  __typename: 'NotFoundError';
  message: Scalars['String'];
};

export type Error = {
  message: Scalars['String'];
};

export type UnexpectedError = Error & {
  __typename: 'UnexpectedError';
  message: Scalars['String'];
};

export type ForbiddenError = Error & {
  __typename: 'ForbiddenError';
  message: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  createAdSkuInstance?: Maybe<CreateAdSkuInstanceResponse>;
  updateAdSkuInstance?: Maybe<UpdateAdSkuInstanceResponse>;
  deleteAdSkuInstance?: Maybe<DeleteAdSkuInstanceResponse>;
  createAdSkuInstances?: Maybe<CreateAdSkuInstancesResponse>;
  createAdSku?: Maybe<CreateAdSkuResponse>;
  updateAdSku?: Maybe<UpdateAdSkuResponse>;
  createSubcategory?: Maybe<CreateSubcategoryResponse>;
  createOffice?: Maybe<CreateOfficeResponse>;
  updateOffice?: Maybe<UpdateOfficeResponse>;
  createSkuInstance?: Maybe<CreateSkuInstanceResponse>;
  updateSkuInstance?: Maybe<UpdateSkuInstanceResponse>;
  deleteSkuInstance?: Maybe<DeleteSkuInstanceResponse>;
  createSkuInstances?: Maybe<CreateSkuInstancesResponse>;
  createSku?: Maybe<CreateSkuResponse>;
  updateSku?: Maybe<UpdateSkuResponse>;
  updateSkuFunctionalities?: Maybe<UpdateSkuResponse>;
  uploadImage?: Maybe<UploadImageResponse>;
  createUser?: Maybe<CreateUserResponse>;
  createPartner?: Maybe<CreatePartnerResponse>;
  updatePartner?: Maybe<UpdatePartnerResponse>;
  createTenant?: Maybe<CreateTenantResponse>;
  updateTenant?: Maybe<UpdateTenantResponse>;
};

export type MutationCreateAdSkuInstanceArgs = {
  input: CreateAdSkuInstanceInput;
};

export type MutationUpdateAdSkuInstanceArgs = {
  input: UpdateAdSkuInstanceInput;
};

export type MutationDeleteAdSkuInstanceArgs = {
  input: DeleteAdSkuInstanceInput;
};

export type MutationCreateAdSkuInstancesArgs = {
  input: CreateAdSkuInstancesInput;
};

export type MutationCreateAdSkuArgs = {
  input: CreateAdSkuInput;
};

export type MutationUpdateAdSkuArgs = {
  input: UpdateAdSkuInput;
};

export type MutationCreateSubcategoryArgs = {
  input: CreateSubcategoryInput;
};

export type MutationCreateOfficeArgs = {
  input: CreateOfficeInput;
};

export type MutationUpdateOfficeArgs = {
  input: UpdateOfficeInput;
};

export type MutationCreateSkuInstanceArgs = {
  input: CreateSkuInstanceInput;
};

export type MutationUpdateSkuInstanceArgs = {
  input: UpdateSkuInstanceInput;
};

export type MutationDeleteSkuInstanceArgs = {
  input: DeleteSkuInstanceInput;
};

export type MutationCreateSkuInstancesArgs = {
  input: CreateSkuInstancesInput;
};

export type MutationCreateSkuArgs = {
  input: CreateSkuInput;
};

export type MutationUpdateSkuArgs = {
  input: UpdateSkuInput;
};

export type MutationUpdateSkuFunctionalitiesArgs = {
  input: UpdateSkuFunctionalitiesInput;
};

export type MutationUploadImageArgs = {
  file: Scalars['Upload'];
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreatePartnerArgs = {
  input: CreatePartnerInput;
};

export type MutationUpdatePartnerArgs = {
  input: UpdatePartnerInput;
};

export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};

export type MutationUpdateTenantArgs = {
  input: UpdateTenantInput;
};

export type CreateAdSkuInstanceInput = {
  adSkuInstance: AdSkuInstanceInput;
};

export type CreateAdSkuInstanceResponse =
  | CreateAdSkuInstancePayload
  | NotFoundError
  | BadRequestError
  | UnexpectedError
  | ForbiddenError
  | ConflictError;

export type UpdateAdSkuInstanceInput = {
  id: Scalars['ID'];
  adSkuInstance: AdSkuInstanceInput;
};

export type UpdateAdSkuInstanceResponse =
  | UpdateAdSkuInstancePayload
  | NotFoundError
  | BadRequestError
  | UnexpectedError
  | ForbiddenError
  | ConflictError;

export type DeleteAdSkuInstanceInput = {
  id: Scalars['ID'];
};

export type DeleteAdSkuInstanceResponse =
  | DeleteAdSkuInstancePayload
  | NotFoundError
  | BadRequestError
  | UnexpectedError
  | ForbiddenError
  | ConflictError;

export type CreateAdSkuInstancesInput = {
  adSkuInstance: AdSkuInstanceInput;
  count: Scalars['Int'];
};

export type CreateAdSkuInstancesResponse =
  | CreateAdSkuInstancesPayload
  | NotFoundError
  | BadRequestError
  | UnexpectedError
  | ForbiddenError
  | ConflictError;

export type AdSkuInstanceList = {
  __typename: 'AdSkuInstanceList';
  items: Array<AdSkuInstance>;
};

export type AdSkuInstance = {
  __typename: 'AdSkuInstance';
  id: Scalars['ID'];
  adSku: AdSkuByIdResponse;
  status: Scalars['String'];
  condition?: Maybe<Scalars['String']>;
  processingStage?: Maybe<Scalars['String']>;
  salesPeriodDateFrom?: Maybe<Scalars['String']>;
  salesPeriodDateTo?: Maybe<Scalars['String']>;
  salesPeriodTimestampFrom?: Maybe<Scalars['String']>;
  salesPeriodTimestampTo?: Maybe<Scalars['String']>;
  locationNumber?: Maybe<Scalars['String']>;
  templateName: Scalars['String'];
  template: Template;
  salesPrice?: Maybe<Scalars['String']>;
  tenant?: Maybe<Tenant>;
};

export type AdSkuInstanceListWithMetadata = {
  __typename: 'AdSkuInstanceListWithMetadata';
  metadata: ListMetadata;
  items: Array<AdSkuInstance>;
};

export type ListMetadata = {
  __typename: 'ListMetadata';
  matches: Array<ListMetadataMatch>;
};

export type ListMetadataMatch = {
  __typename: 'ListMetadataMatch';
  template: Scalars['String'];
  count: Scalars['Int'];
};

export type AdSkuByIdResponse =
  | AdSku
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type AdSku = {
  __typename: 'AdSku';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  mainCategory?: Maybe<MainCategoryByIdResponse>;
  manufacturer: Scalars['String'];
  maxHeight?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Float']>;
  maxWidth?: Maybe<Scalars['Float']>;
  minLength?: Maybe<Scalars['Float']>;
  minWidth?: Maybe<Scalars['Float']>;
  minHeight?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  adSkuInstances: AdSkuInstancesResponse;
  subcategory?: Maybe<SubcategoryByIdResponse>;
  templateName: Scalars['String'];
  template: Template;
  images: Array<ImageData>;
  tenant?: Maybe<Tenant>;
};

export type FieldDefinition = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  readonly: Scalars['Boolean'];
};

export type Tenant = {
  __typename: 'Tenant';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  offices?: Maybe<OfficesResponse>;
};

export type AdSkuInstanceTenantId = {
  __typename: 'AdSkuInstanceTenantId';
  adSkuInstance: AdSkuInstance;
  tenantId: Scalars['String'];
};

export type AdSkuInstanceInput = {
  adSkuId: Scalars['ID'];
  status: Scalars['String'];
  condition?: Maybe<Scalars['String']>;
  processingStage?: Maybe<Scalars['String']>;
  salesPeriodDateFrom?: Maybe<Scalars['String']>;
  salesPeriodDateTo?: Maybe<Scalars['String']>;
  salesPeriodTimestampFrom?: Maybe<Scalars['String']>;
  salesPeriodTimestampTo?: Maybe<Scalars['String']>;
  officeId?: Maybe<Scalars['ID']>;
  salesPrice?: Maybe<Scalars['String']>;
  locationNumber?: Maybe<Scalars['String']>;
};

export type CreateAdSkuInstancePayload = {
  __typename: 'CreateAdSkuInstancePayload';
  adSkuInstance?: Maybe<AdSkuInstance>;
};

export type UpdateAdSkuInstancePayload = {
  __typename: 'UpdateAdSkuInstancePayload';
  adSkuInstance?: Maybe<AdSkuInstance>;
};

export type CreateAdSkuInstancesPayload = {
  __typename: 'CreateAdSkuInstancesPayload';
  items: Array<AdSkuInstance>;
};

export type DeleteAdSkuInstancePayload = {
  __typename: 'DeleteAdSkuInstancePayload';
  removed: Scalars['Boolean'];
};

export type AdSkuInstancesResponse =
  | AdSkuInstanceList
  | UnexpectedError
  | ForbiddenError;

export type BadRequestError = Error & {
  __typename: 'BadRequestError';
  message: Scalars['String'];
  field?: Maybe<Scalars['String']>;
};

export type ConflictError = Error & {
  __typename: 'ConflictError';
  message: Scalars['String'];
  field?: Maybe<Scalars['String']>;
};

export type AdSkusWithMetadataResponse =
  | AdSkuListWithMetadata
  | UnexpectedError
  | ForbiddenError;

export type Criteria = {
  minRelatedCount?: Maybe<Scalars['String']>;
  maxRelatedCount?: Maybe<Scalars['String']>;
};

export type CreateAdSkuInput = {
  adSku: AdSkuInput;
};

export type CreateAdSkuResponse =
  | CreateAdSkuPayload
  | UnexpectedError
  | ForbiddenError;

export type UpdateAdSkuInput = {
  id: Scalars['ID'];
  adSku: AdSkuInput;
};

export type UpdateAdSkuResponse =
  | UpdateAdSkuPayload
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type AdSkuListWithMetadata = {
  __typename: 'AdSkuListWithMetadata';
  metadata: ListMetadata;
  items: Array<AdSkuListWithMetadataItem>;
};

export type AdSkuListWithMetadataItem = {
  __typename: 'AdSkuListWithMetadataItem';
  adSku: AdSku;
  metadata?: Maybe<ListItemMetadata>;
};

export type ListItemMetadata = {
  __typename: 'ListItemMetadata';
  related?: Maybe<Scalars['Int']>;
  matches?: Maybe<Scalars['Int']>;
};

export type ImageData = {
  __typename: 'ImageData';
  filename: Scalars['String'];
  optimizedImages?: Maybe<Array<OptimizedImage>>;
};

export type MainCategoryByIdResponse =
  | MainCategory
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type MainCategory = {
  __typename: 'MainCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  key: Scalars['String'];
  subcategories?: Maybe<SubcategoriesResponse>;
};

export type SubcategoryByIdResponse =
  | Subcategory
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type Subcategory = {
  __typename: 'Subcategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  key: Scalars['String'];
};

export type AdSkuInput = {
  manufacturer: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxHeight?: Maybe<Scalars['Float']>;
  maxWidth?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Float']>;
  minLength?: Maybe<Scalars['Float']>;
  minWidth?: Maybe<Scalars['Float']>;
  minHeight?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  mainCategoryId: Scalars['ID'];
  subcategoryId: Scalars['ID'];
  images?: Maybe<Array<ImageDataInput>>;
};

export type ImageDataInput = {
  filename: Scalars['String'];
};

export type OptimizedImage = {
  __typename: 'OptimizedImage';
  filename: Scalars['String'];
  type: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
};

export type CreateAdSkuPayload = {
  __typename: 'CreateAdSkuPayload';
  adSku?: Maybe<AdSku>;
};

export type UpdateAdSkuPayload = {
  __typename: 'UpdateAdSkuPayload';
  adSku?: Maybe<AdSku>;
};

export type MainCategoriesResponse =
  | MainCategoryList
  | UnexpectedError
  | ForbiddenError;

export type CreateSubcategoryInput = {
  subcategory: SubcategoryInput;
};

export type CreateSubcategoryResponse =
  | CreateSubcategoryPayload
  | NotFoundError
  | UnexpectedError
  | ForbiddenError
  | BadRequestError;

export type SubcategoryInput = {
  mainCategoryId: Scalars['ID'];
  name: Scalars['String'];
};

export type MainCategoryList = {
  __typename: 'MainCategoryList';
  mainCategories: Array<MainCategory>;
};

export type SubcategoryList = {
  __typename: 'SubcategoryList';
  subcategories: Array<Subcategory>;
};

export type SubcategoriesResponse =
  | SubcategoryList
  | UnexpectedError
  | ForbiddenError;

export type CreateSubcategoryPayload = {
  __typename: 'CreateSubcategoryPayload';
  subcategory?: Maybe<Subcategory>;
};

export type Image = {
  __typename: 'Image';
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
  optimizedImages: Array<OptimizedImage>;
};

export type MappingsResponse = MappingList | UnexpectedError | ForbiddenError;

export type MappingList = {
  __typename: 'MappingList';
  mappings: Array<Mapping>;
};

export type Mapping = {
  __typename: 'Mapping';
  id: Scalars['String'];
  name: Scalars['String'];
  mappings: Scalars['String'];
};

export type OfficesResponse = OfficeList | UnexpectedError | ForbiddenError;

export type OfficeByIdResponse =
  | Office
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type CreateOfficeInput = {
  office: OfficeCreateInput;
};

export type CreateOfficeResponse =
  | CreateOfficePayload
  | UnexpectedError
  | ForbiddenError;

export type UpdateOfficeInput = {
  id: Scalars['ID'];
  office: OfficeUpdateInput;
};

export type UpdateOfficeResponse =
  | UpdateOfficePayload
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type OfficeCreateInput = {
  city: Scalars['String'];
  zipCode: Scalars['String'];
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  building?: Maybe<Scalars['String']>;
  locationNumber: Scalars['String'];
  officeFloors?: Maybe<Array<Scalars['String']>>;
};

export type OfficeUpdateInput = {
  city: Scalars['String'];
  zipCode: Scalars['String'];
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  building?: Maybe<Scalars['String']>;
  locationNumber: Scalars['String'];
  officeFloors?: Maybe<Array<Scalars['String']>>;
};

export type RemoveOfficeInput = {
  id: Scalars['ID'];
};

export type CreateOfficePayload = {
  __typename: 'CreateOfficePayload';
  office: Office;
};

export type Office = {
  __typename: 'Office';
  id: Scalars['String'];
  city: Scalars['String'];
  zipCode: Scalars['String'];
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  building?: Maybe<Scalars['String']>;
  locationNumber: Scalars['String'];
  officeFloors: OfficeFloorsResponse;
};

export type UpdateOfficePayload = {
  __typename: 'UpdateOfficePayload';
  office: Office;
};

export type OfficeList = {
  __typename: 'OfficeList';
  items: Array<Office>;
};

export type OfficeFloorsResponse =
  | OfficeFloorList
  | UnexpectedError
  | ForbiddenError;

export type OfficeFloorList = {
  __typename: 'OfficeFloorList';
  items: Array<OfficeFloor>;
};

export type OfficeFloor = {
  __typename: 'OfficeFloor';
  id: Scalars['String'];
  floorLabel: Scalars['String'];
};

export type OfficeFloorByIdResponse =
  | OfficeFloor
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type OptionSetsResponse =
  | OptionSetList
  | UnexpectedError
  | ForbiddenError;

export type OptionSetByIdResponse =
  | OptionSet
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type OptionSetList = {
  __typename: 'OptionSetList';
  optionSets: Array<OptionSet>;
};

export type OptionSet = {
  __typename: 'OptionSet';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  values: Array<Scalars['String']>;
};

export type SkuInstancesWithMetadataResponse =
  | SkuInstanceListWithMetadata
  | UnexpectedError
  | ForbiddenError;

export type SkuInstanceByIdResponse =
  | SkuInstance
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type SkuInstancesCrossTenantsByGtinSerialResponse =
  | SkuInstanceTenantId
  | NotFoundError
  | UnexpectedError
  | ForbiddenError
  | ConflictError;

export type CreateSkuInstanceInput = {
  skuInstance: SkuInstanceInput;
};

export type CreateSkuInstanceResponse =
  | CreateSkuInstancePayload
  | NotFoundError
  | BadRequestError
  | UnexpectedError
  | ForbiddenError
  | ConflictError;

export type UpdateSkuInstanceInput = {
  id: Scalars['ID'];
  skuInstance: SkuInstanceInput;
};

export type UpdateSkuInstanceResponse =
  | UpdateSkuInstancePayload
  | NotFoundError
  | BadRequestError
  | UnexpectedError
  | ForbiddenError
  | ConflictError;

export type DeleteSkuInstanceInput = {
  id: Scalars['ID'];
};

export type DeleteSkuInstanceResponse =
  | DeleteSkuInstancePayload
  | NotFoundError
  | BadRequestError
  | UnexpectedError
  | ForbiddenError
  | ConflictError;

export type CreateSkuInstancesInput = {
  skuInstance: SkuInstanceInput;
  count: Scalars['Int'];
};

export type CreateSkuInstancesResponse =
  | CreateSkuInstancesPayload
  | NotFoundError
  | BadRequestError
  | UnexpectedError
  | ForbiddenError
  | ConflictError;

export type SkuInstanceList = {
  __typename: 'SkuInstanceList';
  /** @deprecated Use `items`. */
  skuInstances: Array<SkuInstance>;
  items: Array<SkuInstance>;
};

export type SkuInstance = {
  __typename: 'SkuInstance';
  id: Scalars['ID'];
  sku: SkuByIdResponse;
  status: Scalars['String'];
  condition?: Maybe<Scalars['String']>;
  processingStage?: Maybe<Scalars['String']>;
  processingStageDateFrom?: Maybe<Scalars['String']>;
  processingStageDateTo?: Maybe<Scalars['String']>;
  office: OfficeByIdResponse;
  officeFloor?: Maybe<OfficeFloorByIdResponse>;
  room?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  gtin?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  costCenter?: Maybe<Scalars['String']>;
  procurementDate?: Maybe<Scalars['String']>;
  procurementNumber?: Maybe<Scalars['String']>;
  procurementCost?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  templateName: Scalars['String'];
  template: Template;
};

export type SkuInstanceListWithMetadata = {
  __typename: 'SkuInstanceListWithMetadata';
  metadata: ListMetadata;
  /** @deprecated Use `items`. */
  skuInstances: Array<SkuInstance>;
  items: Array<SkuInstance>;
};

export type SkuByIdResponse =
  | Sku
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type Sku = {
  __typename: 'Sku';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  mainCategory?: Maybe<MainCategoryByIdResponse>;
  manufacturer: Scalars['String'];
  maxHeight?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Float']>;
  maxWidth?: Maybe<Scalars['Float']>;
  minLength?: Maybe<Scalars['Float']>;
  minWidth?: Maybe<Scalars['Float']>;
  minHeight?: Maybe<Scalars['Float']>;
  maxSeatHeight?: Maybe<Scalars['Float']>;
  minSeatHeight?: Maybe<Scalars['Float']>;
  maxDepth?: Maybe<Scalars['Float']>;
  minDepth?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  skuInstances: SkuInstancesResponse;
  subcategory?: Maybe<SubcategoryByIdResponse>;
  templateName: Scalars['String'];
  template: Template;
  functionalities: FunctionalityResponse;
  images: Array<ImageData>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type SkuInstanceTenantId = {
  __typename: 'SkuInstanceTenantId';
  skuInstance: SkuInstance;
  tenantId: Scalars['String'];
};

export type SkuInstanceInput = {
  skuId: Scalars['ID'];
  status: Scalars['String'];
  condition?: Maybe<Scalars['String']>;
  processingStage?: Maybe<Scalars['String']>;
  processingStageDateFrom?: Maybe<Scalars['String']>;
  processingStageDateTo?: Maybe<Scalars['String']>;
  officeId?: Maybe<Scalars['ID']>;
  officeFloorId?: Maybe<Scalars['ID']>;
  room?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
  gtin?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  costCenter?: Maybe<Scalars['String']>;
  procurementDate?: Maybe<Scalars['String']>;
  procurementNumber?: Maybe<Scalars['String']>;
  procurementCost?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type CreateSkuInstancePayload = {
  __typename: 'CreateSkuInstancePayload';
  skuInstance?: Maybe<SkuInstance>;
};

export type UpdateSkuInstancePayload = {
  __typename: 'UpdateSkuInstancePayload';
  skuInstance?: Maybe<SkuInstance>;
};

export type CreateSkuInstancesPayload = {
  __typename: 'CreateSkuInstancesPayload';
  items: Array<SkuInstance>;
};

export type DeleteSkuInstancePayload = {
  __typename: 'DeleteSkuInstancePayload';
  removed: Scalars['Boolean'];
};

export type SkuInstancesResponse =
  | SkuInstanceList
  | UnexpectedError
  | ForbiddenError;

export type SkusWithMetadataResponse =
  | SkuListWithMetadata
  | UnexpectedError
  | ForbiddenError;

export type CreateSkuInput = {
  sku: SkuInput;
};

export type CreateSkuResponse =
  | CreateSkuPayload
  | UnexpectedError
  | ForbiddenError;

export type UpdateSkuInput = {
  id: Scalars['ID'];
  sku: SkuInput;
};

export type UpdateSkuResponse =
  | UpdateSkuPayload
  | NotFoundError
  | UnexpectedError
  | ForbiddenError;

export type UpdateSkuFunctionalitiesInput = {
  id: Scalars['ID'];
  functionalities?: Maybe<Array<FunctionalityInput>>;
};

export type SkuListWithMetadata = {
  __typename: 'SkuListWithMetadata';
  metadata: ListMetadata;
  /** @deprecated Use `items`. */
  skus: Array<SkuListWithMetadataItem>;
  items: Array<SkuListWithMetadataItem>;
};

export type SkuListWithMetadataItem = {
  __typename: 'SkuListWithMetadataItem';
  sku: Sku;
  metadata?: Maybe<ListItemMetadata>;
};

export type FunctionalityResponse =
  | AvailableFunctionalityList
  | UnexpectedError
  | NotFoundError
  | ForbiddenError;

export type AvailableFunctionality =
  | StringValueFunctionality
  | BooleanValueFunctionality;

export type StringValueFunctionality = {
  __typename: 'StringValueFunctionality';
  field: Scalars['String'];
  type: FunctionalityFieldType;
  value: Scalars['String'];
};

export type BooleanValueFunctionality = {
  __typename: 'BooleanValueFunctionality';
  field: Scalars['String'];
  type: FunctionalityFieldType;
  value: Scalars['Boolean'];
};

export enum FunctionalityFieldType {
  Bool = 'BOOL',
  String = 'STRING',
}

export type SkuInput = {
  manufacturer: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxHeight?: Maybe<Scalars['Float']>;
  maxWidth?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Float']>;
  minLength?: Maybe<Scalars['Float']>;
  minWidth?: Maybe<Scalars['Float']>;
  minHeight?: Maybe<Scalars['Float']>;
  maxSeatHeight?: Maybe<Scalars['Float']>;
  minSeatHeight?: Maybe<Scalars['Float']>;
  maxDepth?: Maybe<Scalars['Float']>;
  minDepth?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  mainCategoryId: Scalars['ID'];
  subcategoryId: Scalars['ID'];
  images?: Maybe<Array<ImageDataInput>>;
  functionalities?: Maybe<Array<FunctionalityInput>>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type FunctionalityInput = {
  field: Scalars['String'];
  type: FunctionalityFieldType;
  value?: Maybe<Scalars['String']>;
  boolValue?: Maybe<Scalars['Boolean']>;
};

export type CreateSkuPayload = {
  __typename: 'CreateSkuPayload';
  sku?: Maybe<Sku>;
};

export type UpdateSkuPayload = {
  __typename: 'UpdateSkuPayload';
  sku?: Maybe<Sku>;
};

export type AvailableFunctionalityList = {
  __typename: 'AvailableFunctionalityList';
  items: Array<AvailableFunctionality>;
};

export type TextFieldDefinition = FieldDefinition & {
  __typename: 'TextFieldDefinition';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  readonly: Scalars['Boolean'];
};

export type BooleanFieldDefinition = FieldDefinition & {
  __typename: 'BooleanFieldDefinition';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  readonly: Scalars['Boolean'];
};

export type NumberFieldDefinition = FieldDefinition & {
  __typename: 'NumberFieldDefinition';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['Float']>;
  required: Scalars['Boolean'];
  readonly: Scalars['Boolean'];
};

export type OptionSetFieldDefinition = FieldDefinition & {
  __typename: 'OptionSetFieldDefinition';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
  optionSet?: Maybe<OptionSet>;
  required: Scalars['Boolean'];
  readonly: Scalars['Boolean'];
};

export type UploadImageResponse =
  | Image
  | UnexpectedError
  | ForbiddenError
  | FileTypeNotSupportedError;

export type FileTypeNotSupportedError = Error & {
  __typename: 'FileTypeNotSupportedError';
  message: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  roles: Array<Scalars['String']>;
};

export type CreateUserResponse =
  | CreateUserPayload
  | UnexpectedError
  | ForbiddenError
  | ConflictError
  | NotFoundError;

export type UserResponse = User | UnexpectedError | ForbiddenError;

export enum GlobalPermissions {
  Admin = 'ADMIN',
  Read = 'READ',
  Write = 'WRITE',
}

export type User = {
  __typename: 'User';
  tenants: Array<Tenant>;
  globalPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  email: Scalars['String'];
  isPartnerAdmin?: Maybe<Scalars['Boolean']>;
};

export type CreateUserPayload = {
  __typename: 'CreateUserPayload';
  user?: Maybe<User>;
};

export type Partner = {
  __typename: 'Partner';
  id: Scalars['ID'];
  name: Scalars['String'];
  admins?: Maybe<Array<Maybe<User>>>;
};

export type CreatePartnerInput = {
  partner: PartnerInput;
};

export type CreatePartnerResponse =
  | CreatePartnerPayload
  | UnexpectedError
  | ForbiddenError
  | ConflictError
  | NotFoundError;

export type UpdatePartnerInput = {
  partner: PartnerInput;
  id: Scalars['ID'];
};

export type UpdatePartnerResponse =
  | CreatePartnerPayload
  | NotFoundError
  | UnexpectedError
  | ForbiddenError
  | ConflictError;

export type UserData = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type PartnerInput = {
  name: Scalars['String'];
  admins: Array<UserData>;
};

export type CreatePartnerPayload = {
  __typename: 'CreatePartnerPayload';
  partner?: Maybe<Partner>;
};

export type ImportedOffice = {
  __typename: 'ImportedOffice';
  office: Office;
  officeFloorList: OfficeFloorList;
};

export type TenantsResponse = Tenants | UnexpectedError | ForbiddenError;

export type TenantResponse =
  | Tenant
  | UnexpectedError
  | ForbiddenError
  | NotFoundError;

export type CreateTenantInput = {
  tenant: TenantInput;
};

export type CreateTenantResponse =
  | CreateTenantPayload
  | UnexpectedError
  | ForbiddenError
  | ConflictError
  | NotFoundError;

export type UpdateTenantInput = {
  id: Scalars['ID'];
  tenant: TenantInputUpdate;
};

export type UpdateTenantResponse =
  | CreateTenantPayload
  | UnexpectedError
  | ForbiddenError
  | ConflictError
  | NotFoundError;

export type Tenants = {
  __typename: 'Tenants';
  items: Array<Maybe<Tenant>>;
};

export type TenantInput = {
  name: Scalars['String'];
  displayName: Scalars['String'];
  officeId?: Maybe<Scalars['ID']>;
  users: Array<UserData>;
};

export type TenantInputUpdate = {
  name: Scalars['String'];
  displayName: Scalars['String'];
  users: Array<UserData>;
};

export type CreateTenantPayload = {
  __typename: 'CreateTenantPayload';
  tenant: Tenant;
};

export type AdSkuQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AdSkuQuery = { __typename: 'Query' } & {
  adSku:
    | ({ __typename: 'AdSku' } & AdSkuFieldsFragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type AdSkuInstanceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AdSkuInstanceQuery = { __typename: 'Query' } & {
  adSkuInstance:
    | ({ __typename: 'AdSkuInstance' } & {
        adSku:
          | ({ __typename: 'AdSku' } & AdSkuFieldsFragment)
          | ({
              __typename: 'NotFoundError';
            } & ErrorFragment_NotFoundError_Fragment)
          | ({
              __typename: 'UnexpectedError';
            } & ErrorFragment_UnexpectedError_Fragment)
          | ({
              __typename: 'ForbiddenError';
            } & ErrorFragment_ForbiddenError_Fragment);
      } & AdSkuInstanceFieldsFragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type AdSkuInstancesQueryVariables = Exact<{
  filters?: Maybe<Array<Filter>>;
  rangeFilters?: Maybe<Array<RangeFilter>>;
  unionFilters?: Maybe<Array<UnionFilter>>;
  slice?: Maybe<Slice>;
}>;

export type AdSkuInstancesQuery = { __typename: 'Query' } & {
  adSkuInstances:
    | ({ __typename: 'AdSkuInstanceListWithMetadata' } & {
        metadata: { __typename: 'ListMetadata' } & {
          matches: Array<
            { __typename: 'ListMetadataMatch' } & Pick<
              ListMetadataMatch,
              'template' | 'count'
            >
          >;
        };
        items: Array<
          { __typename: 'AdSkuInstance' } & AdSkuInstanceFieldsFragment
        >;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type AdSkuWithInstancesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AdSkuWithInstancesQuery = { __typename: 'Query' } & {
  adSku:
    | ({ __typename: 'AdSku' } & {
        adSkuInstances:
          | ({ __typename: 'AdSkuInstanceList' } & {
              items: Array<
                { __typename: 'AdSkuInstance' } & AdSkuInstanceFieldsFragment
              >;
            })
          | { __typename: 'UnexpectedError' }
          | { __typename: 'ForbiddenError' };
      } & AdSkuFieldsFragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type AdSkusQueryVariables = Exact<{
  filters?: Maybe<Array<Filter>>;
  rangeFilters?: Maybe<Array<RangeFilter>>;
  unionFilters?: Maybe<Array<UnionFilter>>;
  criteria?: Maybe<Criteria>;
  slice?: Maybe<Slice>;
}>;

export type AdSkusQuery = { __typename: 'Query' } & {
  adSkus:
    | ({ __typename: 'AdSkuListWithMetadata' } & {
        metadata: { __typename: 'ListMetadata' } & {
          matches: Array<
            { __typename: 'ListMetadataMatch' } & Pick<
              ListMetadataMatch,
              'template' | 'count'
            >
          >;
        };
        items: Array<
          { __typename: 'AdSkuListWithMetadataItem' } & {
            adSku: { __typename: 'AdSku' } & AdSkuFieldsFragment;
            metadata?: Maybe<
              { __typename: 'ListItemMetadata' } & Pick<
                ListItemMetadata,
                'matches' | 'related'
              >
            >;
          }
        >;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type AdSkusWithInstancesQueryVariables = Exact<{
  filters?: Maybe<Array<Filter>>;
  rangeFilters?: Maybe<Array<RangeFilter>>;
  unionFilters?: Maybe<Array<UnionFilter>>;
  criteria?: Maybe<Criteria>;
  slice?: Maybe<Slice>;
}>;

export type AdSkusWithInstancesQuery = { __typename: 'Query' } & {
  adSkus:
    | ({ __typename: 'AdSkuListWithMetadata' } & {
        metadata: { __typename: 'ListMetadata' } & {
          matches: Array<
            { __typename: 'ListMetadataMatch' } & Pick<
              ListMetadataMatch,
              'template' | 'count'
            >
          >;
        };
        items: Array<
          { __typename: 'AdSkuListWithMetadataItem' } & {
            adSku: { __typename: 'AdSku' } & {
              adSkuInstances:
                | ({ __typename: 'AdSkuInstanceList' } & {
                    items: Array<
                      {
                        __typename: 'AdSkuInstance';
                      } & AdSkuInstanceFieldsFragment
                    >;
                  })
                | { __typename: 'UnexpectedError' }
                | { __typename: 'ForbiddenError' };
            } & AdSkuFieldsFragment;
            metadata?: Maybe<
              { __typename: 'ListItemMetadata' } & Pick<
                ListItemMetadata,
                'matches' | 'related'
              >
            >;
          }
        >;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type ContextQueryVariables = Exact<{
  mappings: Array<Scalars['String']>;
}>;

export type ContextQuery = { __typename: 'Query' } & {
  mainCategories:
    | ({ __typename: 'MainCategoryList' } & {
        mainCategories: Array<
          { __typename: 'MainCategory' } & Pick<
            MainCategory,
            'id' | 'name' | 'key'
          > & {
              subcategories?: Maybe<
                | ({ __typename: 'SubcategoryList' } & {
                    subcategories: Array<
                      { __typename: 'Subcategory' } & Pick<
                        Subcategory,
                        'id' | 'name' | 'key'
                      >
                    >;
                  })
                | ({
                    __typename: 'UnexpectedError';
                  } & ErrorFragment_UnexpectedError_Fragment)
                | ({
                    __typename: 'ForbiddenError';
                  } & ErrorFragment_ForbiddenError_Fragment)
              >;
            }
        >;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
  skuTemplate:
    | ({ __typename: 'Template' } & TemplateFieldsFragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
  adSkuTemplate:
    | ({ __typename: 'Template' } & TemplateFieldsFragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
  skuInstanceTemplate:
    | ({ __typename: 'Template' } & TemplateFieldsFragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
  adSkuInstanceTemplate:
    | ({ __typename: 'Template' } & TemplateFieldsFragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
  mappings:
    | ({ __typename: 'MappingList' } & {
        mappings: Array<
          { __typename: 'Mapping' } & Pick<Mapping, 'id' | 'name' | 'mappings'>
        >;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
  offices:
    | ({ __typename: 'OfficeList' } & {
        items: Array<
          { __typename: 'Office' } & Pick<
            Office,
            | 'id'
            | 'city'
            | 'zipCode'
            | 'street'
            | 'streetNumber'
            | 'building'
            | 'locationNumber'
          > & {
              officeFloors:
                | ({ __typename: 'OfficeFloorList' } & {
                    items: Array<
                      { __typename: 'OfficeFloor' } & Pick<
                        OfficeFloor,
                        'id' | 'floorLabel'
                      >
                    >;
                  })
                | ({ __typename: 'UnexpectedError' } & Pick<
                    UnexpectedError,
                    'message'
                  >)
                | ({ __typename: 'ForbiddenError' } & Pick<
                    ForbiddenError,
                    'message'
                  >);
            }
        >;
      })
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
    | ({ __typename: 'ForbiddenError' } & Pick<ForbiddenError, 'message'>);
};

export type CreateAdSkuMutationVariables = Exact<{
  input: CreateAdSkuInput;
}>;

export type CreateAdSkuMutation = { __typename: 'Mutation' } & {
  createAdSku?: Maybe<
    | ({ __typename: 'CreateAdSkuPayload' } & {
        adSku?: Maybe<{ __typename: 'AdSku' } & AdSkuFieldsFragment>;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
  >;
};

export type CreateAdSkuInstanceMutationVariables = Exact<{
  input: CreateAdSkuInstanceInput;
}>;

export type CreateAdSkuInstanceMutation = { __typename: 'Mutation' } & {
  createAdSkuInstance?: Maybe<
    | ({ __typename: 'CreateAdSkuInstancePayload' } & {
        adSkuInstance?: Maybe<
          { __typename: 'AdSkuInstance' } & AdSkuInstanceFieldsFragment
        >;
      })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'BadRequestError';
      } & ErrorFragment_BadRequestError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'ConflictError' } & ErrorFragment_ConflictError_Fragment)
  >;
};

export type CreateAdSkuInstancesMutationVariables = Exact<{
  input: CreateAdSkuInstancesInput;
}>;

export type CreateAdSkuInstancesMutation = { __typename: 'Mutation' } & {
  createAdSkuInstances?: Maybe<
    | ({ __typename: 'CreateAdSkuInstancesPayload' } & {
        items: Array<
          { __typename: 'AdSkuInstance' } & AdSkuInstanceFieldsFragment
        >;
      })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'BadRequestError';
      } & ErrorFragment_BadRequestError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'ConflictError' } & ErrorFragment_ConflictError_Fragment)
  >;
};

export type CreateOfficeMutationVariables = Exact<{
  input: CreateOfficeInput;
}>;

export type CreateOfficeMutation = { __typename: 'Mutation' } & {
  createOffice?: Maybe<
    | ({ __typename: 'CreateOfficePayload' } & {
        office: { __typename: 'Office' } & OfficeFieldsFragment;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
  >;
};

export type CreateSkuMutationVariables = Exact<{
  input: CreateSkuInput;
}>;

export type CreateSkuMutation = { __typename: 'Mutation' } & {
  createSku?: Maybe<
    | ({ __typename: 'CreateSkuPayload' } & {
        sku?: Maybe<{ __typename: 'Sku' } & SkuFieldsFragment>;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
  >;
};

export type CreateSkuInstanceMutationVariables = Exact<{
  input: CreateSkuInstanceInput;
}>;

export type CreateSkuInstanceMutation = { __typename: 'Mutation' } & {
  createSkuInstance?: Maybe<
    | ({ __typename: 'CreateSkuInstancePayload' } & {
        skuInstance?: Maybe<
          { __typename: 'SkuInstance' } & SkuInstanceFieldsFragment
        >;
      })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'BadRequestError';
      } & ErrorFragment_BadRequestError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'ConflictError' } & ErrorFragment_ConflictError_Fragment)
  >;
};

export type CreateSkuInstancesMutationVariables = Exact<{
  input: CreateSkuInstancesInput;
}>;

export type CreateSkuInstancesMutation = { __typename: 'Mutation' } & {
  createSkuInstances?: Maybe<
    | ({ __typename: 'CreateSkuInstancesPayload' } & {
        items: Array<{ __typename: 'SkuInstance' } & SkuInstanceFieldsFragment>;
      })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'BadRequestError';
      } & ErrorFragment_BadRequestError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'ConflictError' } & ErrorFragment_ConflictError_Fragment)
  >;
};

export type CreateSubcategoryMutationVariables = Exact<{
  input: CreateSubcategoryInput;
}>;

export type CreateSubcategoryMutation = { __typename: 'Mutation' } & {
  createSubcategory?: Maybe<
    | ({ __typename: 'CreateSubcategoryPayload' } & {
        subcategory?: Maybe<
          { __typename: 'Subcategory' } & Pick<
            Subcategory,
            'id' | 'name' | 'key'
          >
        >;
      })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({
        __typename: 'BadRequestError';
      } & ErrorFragment_BadRequestError_Fragment)
  >;
};

export type CreateTenantMutationVariables = Exact<{
  input: CreateTenantInput;
}>;

export type CreateTenantMutation = { __typename: 'Mutation' } & {
  createTenant?: Maybe<
    | ({ __typename: 'CreateTenantPayload' } & {
        tenant: { __typename: 'Tenant' } & Pick<
          Tenant,
          'name' | 'displayName' | 'id'
        >;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'ConflictError' } & ErrorFragment_ConflictError_Fragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
  >;
};

export type DeleteAdSkuInstanceMutationVariables = Exact<{
  input: DeleteAdSkuInstanceInput;
}>;

export type DeleteAdSkuInstanceMutation = { __typename: 'Mutation' } & {
  deleteAdSkuInstance?: Maybe<
    | ({ __typename: 'DeleteAdSkuInstancePayload' } & Pick<
        DeleteAdSkuInstancePayload,
        'removed'
      >)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'BadRequestError';
      } & ErrorFragment_BadRequestError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'ConflictError' } & ErrorFragment_ConflictError_Fragment)
  >;
};

export type DeleteSkuInstanceMutationVariables = Exact<{
  input: DeleteSkuInstanceInput;
}>;

export type DeleteSkuInstanceMutation = { __typename: 'Mutation' } & {
  deleteSkuInstance?: Maybe<
    | ({ __typename: 'DeleteSkuInstancePayload' } & Pick<
        DeleteSkuInstancePayload,
        'removed'
      >)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'BadRequestError';
      } & ErrorFragment_BadRequestError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'ConflictError' } & ErrorFragment_ConflictError_Fragment)
  >;
};

export type GetOfficeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOfficeQuery = { __typename: 'Query' } & {
  office:
    | ({ __typename: 'Office' } & OfficeFieldsFragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type SkuQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SkuQuery = { __typename: 'Query' } & {
  sku:
    | ({ __typename: 'Sku' } & SkuFieldsFragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type SkuInstanceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SkuInstanceQuery = { __typename: 'Query' } & {
  skuInstance:
    | ({ __typename: 'SkuInstance' } & {
        sku:
          | ({ __typename: 'Sku' } & SkuFieldsFragment)
          | ({
              __typename: 'NotFoundError';
            } & ErrorFragment_NotFoundError_Fragment)
          | ({
              __typename: 'UnexpectedError';
            } & ErrorFragment_UnexpectedError_Fragment)
          | ({
              __typename: 'ForbiddenError';
            } & ErrorFragment_ForbiddenError_Fragment);
      } & SkuInstanceFieldsFragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type SkuInstanceByGtinSerialQueryVariables = Exact<{
  gtin: Scalars['String'];
  serialNumber: Scalars['String'];
}>;

export type SkuInstanceByGtinSerialQuery = { __typename: 'Query' } & {
  skuInstancesCrossTenantsByGtinSerial:
    | ({ __typename: 'SkuInstanceTenantId' } & Pick<
        SkuInstanceTenantId,
        'tenantId'
      > & {
          skuInstance: { __typename: 'SkuInstance' } & Pick<SkuInstance, 'id'>;
        })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'ConflictError' } & ErrorFragment_ConflictError_Fragment);
};

export type SkuInstancesQueryVariables = Exact<{
  filters?: Maybe<Array<Filter>>;
  slice?: Maybe<Slice>;
}>;

export type SkuInstancesQuery = { __typename: 'Query' } & {
  skuInstances:
    | ({ __typename: 'SkuInstanceListWithMetadata' } & {
        metadata: { __typename: 'ListMetadata' } & {
          matches: Array<
            { __typename: 'ListMetadataMatch' } & Pick<
              ListMetadataMatch,
              'template' | 'count'
            >
          >;
        };
        skuInstances: Array<
          { __typename: 'SkuInstance' } & SkuInstanceFieldsFragment
        >;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type SkusQueryVariables = Exact<{
  filters?: Maybe<Array<Filter>>;
  slice?: Maybe<Slice>;
}>;

export type SkusQuery = { __typename: 'Query' } & {
  skus:
    | ({ __typename: 'SkuListWithMetadata' } & {
        metadata: { __typename: 'ListMetadata' } & {
          matches: Array<
            { __typename: 'ListMetadataMatch' } & Pick<
              ListMetadataMatch,
              'template' | 'count'
            >
          >;
        };
        skus: Array<
          { __typename: 'SkuListWithMetadataItem' } & {
            sku: { __typename: 'Sku' } & SkuFieldsFragment;
            metadata?: Maybe<
              { __typename: 'ListItemMetadata' } & Pick<
                ListItemMetadata,
                'matches' | 'related'
              >
            >;
          }
        >;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type GetTenantQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTenantQuery = { __typename: 'Query' } & {
  tenant:
    | ({ __typename: 'Tenant' } & TenantFieldsFragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment);
};

export type UpdateAdSkuMutationVariables = Exact<{
  input: UpdateAdSkuInput;
}>;

export type UpdateAdSkuMutation = { __typename: 'Mutation' } & {
  updateAdSku?: Maybe<
    | ({ __typename: 'UpdateAdSkuPayload' } & {
        adSku?: Maybe<{ __typename: 'AdSku' } & AdSkuFieldsFragment>;
      })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
  >;
};

export type UpdateAdSkuInstanceMutationVariables = Exact<{
  input: UpdateAdSkuInstanceInput;
}>;

export type UpdateAdSkuInstanceMutation = { __typename: 'Mutation' } & {
  updateAdSkuInstance?: Maybe<
    | ({ __typename: 'UpdateAdSkuInstancePayload' } & {
        adSkuInstance?: Maybe<
          { __typename: 'AdSkuInstance' } & AdSkuInstanceFieldsFragment
        >;
      })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'BadRequestError';
      } & ErrorFragment_BadRequestError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'ConflictError' } & ErrorFragment_ConflictError_Fragment)
  >;
};

export type UpdateOfficeMutationVariables = Exact<{
  input: UpdateOfficeInput;
}>;

export type UpdateOfficeMutation = { __typename: 'Mutation' } & {
  updateOffice?: Maybe<
    | ({ __typename: 'UpdateOfficePayload' } & {
        office: { __typename: 'Office' } & OfficeFieldsFragment;
      })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
  >;
};

export type UpdateSkuMutationVariables = Exact<{
  input: UpdateSkuInput;
}>;

export type UpdateSkuMutation = { __typename: 'Mutation' } & {
  updateSku?: Maybe<
    | ({ __typename: 'UpdateSkuPayload' } & {
        sku?: Maybe<{ __typename: 'Sku' } & SkuFieldsFragment>;
      })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
  >;
};

export type UpdateSkuFunctionalitiesMutationVariables = Exact<{
  input: UpdateSkuFunctionalitiesInput;
}>;

export type UpdateSkuFunctionalitiesMutation = { __typename: 'Mutation' } & {
  updateSkuFunctionalities?: Maybe<
    | ({ __typename: 'UpdateSkuPayload' } & {
        sku?: Maybe<{ __typename: 'Sku' } & SkuFieldsFragment>;
      })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
  >;
};

export type UpdateSkuInstanceMutationVariables = Exact<{
  input: UpdateSkuInstanceInput;
}>;

export type UpdateSkuInstanceMutation = { __typename: 'Mutation' } & {
  updateSkuInstance?: Maybe<
    | ({ __typename: 'UpdateSkuInstancePayload' } & {
        skuInstance?: Maybe<
          { __typename: 'SkuInstance' } & SkuInstanceFieldsFragment
        >;
      })
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
    | ({
        __typename: 'BadRequestError';
      } & ErrorFragment_BadRequestError_Fragment)
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'ConflictError' } & ErrorFragment_ConflictError_Fragment)
  >;
};

export type UpdateTenantMutationVariables = Exact<{
  input: UpdateTenantInput;
}>;

export type UpdateTenantMutation = { __typename: 'Mutation' } & {
  updateTenant?: Maybe<
    | ({ __typename: 'CreateTenantPayload' } & {
        tenant: { __typename: 'Tenant' } & TenantFieldsFragment;
      })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({ __typename: 'ForbiddenError' } & ErrorFragment_ForbiddenError_Fragment)
    | ({ __typename: 'ConflictError' } & ErrorFragment_ConflictError_Fragment)
    | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
  >;
};

export type UploadImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type UploadImageMutation = { __typename: 'Mutation' } & {
  uploadImage?: Maybe<
    | ({ __typename: 'Image' } & Pick<
        Image,
        'filename' | 'mimetype' | 'encoding'
      > & {
          optimizedImages: Array<
            { __typename: 'OptimizedImage' } & Pick<
              OptimizedImage,
              'filename' | 'type' | 'size'
            >
          >;
        })
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
    | ({ __typename: 'ForbiddenError' } & Pick<ForbiddenError, 'message'>)
    | ({ __typename: 'FileTypeNotSupportedError' } & Pick<
        FileTypeNotSupportedError,
        'message'
      >)
  >;
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = { __typename: 'Query' } & {
  user:
    | ({ __typename: 'User' } & Pick<User, 'isPartnerAdmin'> & {
          tenants: Array<
            { __typename: 'Tenant' } & Pick<
              Tenant,
              'id' | 'name' | 'displayName'
            >
          >;
        })
    | ({
        __typename: 'UnexpectedError';
      } & ErrorFragment_UnexpectedError_Fragment)
    | ({
        __typename: 'ForbiddenError';
      } & ErrorFragment_ForbiddenError_Fragment);
};

export type AdSkuFieldsFragment = { __typename: 'AdSku' } & Pick<
  AdSku,
  | 'id'
  | 'manufacturer'
  | 'model'
  | 'description'
  | 'primaryColor'
  | 'secondaryColor'
  | 'minHeight'
  | 'minWidth'
  | 'minLength'
  | 'maxHeight'
  | 'maxWidth'
  | 'maxLength'
> & {
    tenant?: Maybe<{ __typename: 'Tenant' } & Pick<Tenant, 'id' | 'name'>>;
    mainCategory?: Maybe<
      | ({ __typename: 'MainCategory' } & Pick<
          MainCategory,
          'id' | 'name' | 'key'
        >)
      | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
      | ({
          __typename: 'UnexpectedError';
        } & ErrorFragment_UnexpectedError_Fragment)
      | ({
          __typename: 'ForbiddenError';
        } & ErrorFragment_ForbiddenError_Fragment)
    >;
    subcategory?: Maybe<
      | ({ __typename: 'Subcategory' } & Pick<
          Subcategory,
          'id' | 'name' | 'key'
        >)
      | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
      | ({
          __typename: 'UnexpectedError';
        } & ErrorFragment_UnexpectedError_Fragment)
      | ({
          __typename: 'ForbiddenError';
        } & ErrorFragment_ForbiddenError_Fragment)
    >;
    images: Array<
      { __typename: 'ImageData' } & Pick<ImageData, 'filename'> & {
          optimizedImages?: Maybe<
            Array<
              { __typename: 'OptimizedImage' } & Pick<
                OptimizedImage,
                'filename' | 'type' | 'size'
              >
            >
          >;
        }
    >;
  };

export type AdSkuInstanceFieldsFragment = {
  __typename: 'AdSkuInstance';
} & Pick<
  AdSkuInstance,
  | 'id'
  | 'status'
  | 'condition'
  | 'processingStage'
  | 'locationNumber'
  | 'salesPrice'
  | 'salesPeriodDateFrom'
  | 'salesPeriodDateTo'
  | 'salesPeriodTimestampFrom'
  | 'salesPeriodTimestampTo'
> & { tenant?: Maybe<{ __typename: 'Tenant' } & Pick<Tenant, 'id' | 'name'>> };

type ErrorFragment_NotFoundError_Fragment = {
  __typename: 'NotFoundError';
} & Pick<NotFoundError, 'message'>;

type ErrorFragment_UnexpectedError_Fragment = {
  __typename: 'UnexpectedError';
} & Pick<UnexpectedError, 'message'>;

type ErrorFragment_ForbiddenError_Fragment = {
  __typename: 'ForbiddenError';
} & Pick<ForbiddenError, 'message'>;

type ErrorFragment_BadRequestError_Fragment = {
  __typename: 'BadRequestError';
} & Pick<BadRequestError, 'message'>;

type ErrorFragment_ConflictError_Fragment = {
  __typename: 'ConflictError';
} & Pick<ConflictError, 'message'>;

type ErrorFragment_FileTypeNotSupportedError_Fragment = {
  __typename: 'FileTypeNotSupportedError';
} & Pick<FileTypeNotSupportedError, 'message'>;

export type ErrorFragmentFragment =
  | ErrorFragment_NotFoundError_Fragment
  | ErrorFragment_UnexpectedError_Fragment
  | ErrorFragment_ForbiddenError_Fragment
  | ErrorFragment_BadRequestError_Fragment
  | ErrorFragment_ConflictError_Fragment
  | ErrorFragment_FileTypeNotSupportedError_Fragment;

export type OfficeFieldsFragment = { __typename: 'Office' } & Pick<
  Office,
  'id' | 'city' | 'zipCode' | 'street' | 'streetNumber' | 'locationNumber'
>;

export type SkuFieldsFragment = { __typename: 'Sku' } & Pick<
  Sku,
  | 'id'
  | 'manufacturer'
  | 'model'
  | 'description'
  | 'primaryColor'
  | 'secondaryColor'
  | 'minHeight'
  | 'minWidth'
  | 'minLength'
  | 'maxHeight'
  | 'maxWidth'
  | 'maxLength'
  | 'serialNumber'
  | 'maxSeatHeight'
  | 'minSeatHeight'
  | 'maxDepth'
  | 'minDepth'
> & {
    functionalities:
      | ({ __typename: 'AvailableFunctionalityList' } & {
          items: Array<
            | ({ __typename: 'StringValueFunctionality' } & Pick<
                StringValueFunctionality,
                'field' | 'value'
              >)
            | ({ __typename: 'BooleanValueFunctionality' } & Pick<
                BooleanValueFunctionality,
                'field'
              > & { boolValue: BooleanValueFunctionality['value'] })
          >;
        })
      | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
      | ({ __typename: 'NotFoundError' } & Pick<NotFoundError, 'message'>)
      | ({ __typename: 'ForbiddenError' } & Pick<ForbiddenError, 'message'>);
    mainCategory?: Maybe<
      | ({ __typename: 'MainCategory' } & Pick<
          MainCategory,
          'id' | 'name' | 'key'
        >)
      | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
      | ({
          __typename: 'UnexpectedError';
        } & ErrorFragment_UnexpectedError_Fragment)
      | ({
          __typename: 'ForbiddenError';
        } & ErrorFragment_ForbiddenError_Fragment)
    >;
    subcategory?: Maybe<
      | ({ __typename: 'Subcategory' } & Pick<
          Subcategory,
          'id' | 'name' | 'key'
        >)
      | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
      | ({
          __typename: 'UnexpectedError';
        } & ErrorFragment_UnexpectedError_Fragment)
      | ({
          __typename: 'ForbiddenError';
        } & ErrorFragment_ForbiddenError_Fragment)
    >;
    images: Array<
      { __typename: 'ImageData' } & Pick<ImageData, 'filename'> & {
          optimizedImages?: Maybe<
            Array<
              { __typename: 'OptimizedImage' } & Pick<
                OptimizedImage,
                'filename' | 'type' | 'size'
              >
            >
          >;
        }
    >;
  };

export type SkuInstanceFieldsFragment = { __typename: 'SkuInstance' } & Pick<
  SkuInstance,
  | 'id'
  | 'status'
  | 'condition'
  | 'room'
  | 'qrCode'
  | 'gtin'
  | 'serialNumber'
  | 'costCenter'
  | 'procurementDate'
  | 'procurementCost'
  | 'procurementNumber'
  | 'note'
  | 'processingStage'
  | 'processingStageDateFrom'
  | 'processingStageDateTo'
> & {
    sku:
      | ({ __typename: 'Sku' } & Pick<Sku, 'id'>)
      | { __typename: 'NotFoundError' }
      | { __typename: 'UnexpectedError' }
      | { __typename: 'ForbiddenError' };
    office:
      | ({ __typename: 'Office' } & Pick<
          Office,
          | 'id'
          | 'city'
          | 'street'
          | 'streetNumber'
          | 'zipCode'
          | 'building'
          | 'locationNumber'
        >)
      | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
      | ({
          __typename: 'UnexpectedError';
        } & ErrorFragment_UnexpectedError_Fragment)
      | ({
          __typename: 'ForbiddenError';
        } & ErrorFragment_ForbiddenError_Fragment);
    officeFloor?: Maybe<
      | ({ __typename: 'OfficeFloor' } & Pick<OfficeFloor, 'id' | 'floorLabel'>)
      | ({ __typename: 'NotFoundError' } & ErrorFragment_NotFoundError_Fragment)
      | ({
          __typename: 'UnexpectedError';
        } & ErrorFragment_UnexpectedError_Fragment)
      | ({
          __typename: 'ForbiddenError';
        } & ErrorFragment_ForbiddenError_Fragment)
    >;
  };

export type TemplateFieldsFragment = { __typename: 'Template' } & Pick<
  Template,
  'id' | 'name'
> & {
    fields: Array<
      | ({ __typename: 'TextFieldDefinition' } & Pick<
          TextFieldDefinition,
          'defaultValue' | 'id' | 'name' | 'description' | 'required'
        > & { type: 'TextFieldDefinition' })
      | ({ __typename: 'BooleanFieldDefinition' } & Pick<
          BooleanFieldDefinition,
          'id' | 'name' | 'description' | 'required'
        > & {
            boolDefaultValue: BooleanFieldDefinition['defaultValue'];
            type: 'BooleanFieldDefinition';
          })
      | ({ __typename: 'NumberFieldDefinition' } & Pick<
          NumberFieldDefinition,
          'id' | 'name' | 'description' | 'required'
        > & {
            numberDefaultValue: NumberFieldDefinition['defaultValue'];
            type: 'NumberFieldDefinition';
          })
      | ({ __typename: 'OptionSetFieldDefinition' } & Pick<
          OptionSetFieldDefinition,
          'defaultValue' | 'id' | 'name' | 'description' | 'required'
        > & { type: 'OptionSetFieldDefinition' } & {
            optionSet?: Maybe<
              { __typename: 'OptionSet' } & Pick<
                OptionSet,
                'id' | 'name' | 'description' | 'values'
              >
            >;
          })
    >;
  };

export type TenantFieldsFragment = { __typename: 'Tenant' } & Pick<
  Tenant,
  'name' | 'displayName' | 'id'
> & {
    offices?: Maybe<
      | ({ __typename: 'OfficeList' } & {
          items: Array<
            { __typename: 'Office' } & Pick<
              Office,
              | 'id'
              | 'locationNumber'
              | 'street'
              | 'streetNumber'
              | 'city'
              | 'zipCode'
            >
          >;
        })
      | { __typename: 'UnexpectedError' }
      | { __typename: 'ForbiddenError' }
    >;
  };

export const ErrorFragmentFragmentDoc = gql`
  fragment errorFragment on Error {
    message
  }
`;
export const AdSkuFieldsFragmentDoc = gql`
  fragment adSkuFields on AdSku {
    id
    tenant {
      id
      name
    }
    manufacturer
    model
    description
    primaryColor
    secondaryColor
    minHeight
    minWidth
    minLength
    maxHeight
    maxWidth
    maxLength
    mainCategory {
      ... on MainCategory {
        id
        name
        key
      }
      ...errorFragment
    }
    subcategory {
      ... on Subcategory {
        id
        name
        key
      }
      ...errorFragment
    }
    images {
      filename
      optimizedImages {
        filename
        type
        size
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`;
export const AdSkuInstanceFieldsFragmentDoc = gql`
  fragment adSkuInstanceFields on AdSkuInstance {
    id
    tenant {
      id
      name
    }
    status
    condition
    processingStage
    locationNumber
    salesPrice
    salesPeriodDateFrom
    salesPeriodDateTo
    salesPeriodTimestampFrom
    salesPeriodTimestampTo
  }
`;
export const OfficeFieldsFragmentDoc = gql`
  fragment officeFields on Office {
    id
    city
    zipCode
    street
    streetNumber
    locationNumber
  }
`;
export const SkuFieldsFragmentDoc = gql`
  fragment skuFields on Sku {
    id
    manufacturer
    model
    description
    primaryColor
    secondaryColor
    minHeight
    minWidth
    minLength
    maxHeight
    maxWidth
    maxLength
    serialNumber
    maxSeatHeight
    minSeatHeight
    maxDepth
    minDepth
    functionalities {
      ... on AvailableFunctionalityList {
        items {
          ... on StringValueFunctionality {
            field
            value
          }
          ... on BooleanValueFunctionality {
            field
            boolValue: value
          }
        }
      }
      ... on Error {
        message
      }
    }
    mainCategory {
      ... on MainCategory {
        id
        name
        key
      }
      ...errorFragment
    }
    subcategory {
      ... on Subcategory {
        id
        name
        key
      }
      ...errorFragment
    }
    images {
      filename
      optimizedImages {
        filename
        type
        size
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`;
export const SkuInstanceFieldsFragmentDoc = gql`
  fragment skuInstanceFields on SkuInstance {
    id
    status
    condition
    room
    sku {
      ... on Sku {
        id
      }
    }
    qrCode
    gtin
    serialNumber
    costCenter
    procurementDate
    procurementCost
    procurementNumber
    note
    processingStage
    processingStageDateFrom
    processingStageDateTo
    office {
      ... on Office {
        id
        city
        street
        streetNumber
        zipCode
        building
        locationNumber
      }
      ...errorFragment
    }
    officeFloor {
      ... on OfficeFloor {
        id
        floorLabel
      }
      ...errorFragment
    }
  }
  ${ErrorFragmentFragmentDoc}
`;
export const TemplateFieldsFragmentDoc = gql`
  fragment templateFields on Template {
    id
    name
    fields {
      id
      name
      type: __typename
      description
      required
      ... on TextFieldDefinition {
        defaultValue
      }
      ... on BooleanFieldDefinition {
        boolDefaultValue: defaultValue
      }
      ... on NumberFieldDefinition {
        numberDefaultValue: defaultValue
      }
      ... on OptionSetFieldDefinition {
        defaultValue
        optionSet {
          id
          name
          description
          values
        }
      }
    }
  }
`;
export const TenantFieldsFragmentDoc = gql`
  fragment tenantFields on Tenant {
    name
    displayName
    id
    offices {
      ... on OfficeList {
        items {
          id
          locationNumber
          street
          streetNumber
          city
          zipCode
        }
      }
    }
  }
`;
export const AdSkuDocument = gql`
  query AdSku($id: ID!) {
    adSku(id: $id) {
      ...adSkuFields
      ...errorFragment
    }
  }
  ${AdSkuFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useAdSkuQuery__
 *
 * To run a query within a React component, call `useAdSkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdSkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdSkuQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdSkuQuery(
  baseOptions?: Apollo.QueryHookOptions<AdSkuQuery, AdSkuQueryVariables>,
) {
  return Apollo.useQuery<AdSkuQuery, AdSkuQueryVariables>(
    AdSkuDocument,
    baseOptions,
  );
}
export function useAdSkuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdSkuQuery, AdSkuQueryVariables>,
) {
  return Apollo.useLazyQuery<AdSkuQuery, AdSkuQueryVariables>(
    AdSkuDocument,
    baseOptions,
  );
}
export type AdSkuQueryHookResult = ReturnType<typeof useAdSkuQuery>;
export type AdSkuLazyQueryHookResult = ReturnType<typeof useAdSkuLazyQuery>;
export type AdSkuQueryResult = Apollo.QueryResult<
  AdSkuQuery,
  AdSkuQueryVariables
>;
export const AdSkuInstanceDocument = gql`
  query AdSkuInstance($id: ID!) {
    adSkuInstance(id: $id) {
      ... on AdSkuInstance {
        ...adSkuInstanceFields
        adSku {
          ...adSkuFields
          ...errorFragment
        }
      }
      ...errorFragment
    }
  }
  ${AdSkuInstanceFieldsFragmentDoc}
  ${AdSkuFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useAdSkuInstanceQuery__
 *
 * To run a query within a React component, call `useAdSkuInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdSkuInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdSkuInstanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdSkuInstanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdSkuInstanceQuery,
    AdSkuInstanceQueryVariables
  >,
) {
  return Apollo.useQuery<AdSkuInstanceQuery, AdSkuInstanceQueryVariables>(
    AdSkuInstanceDocument,
    baseOptions,
  );
}
export function useAdSkuInstanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdSkuInstanceQuery,
    AdSkuInstanceQueryVariables
  >,
) {
  return Apollo.useLazyQuery<AdSkuInstanceQuery, AdSkuInstanceQueryVariables>(
    AdSkuInstanceDocument,
    baseOptions,
  );
}
export type AdSkuInstanceQueryHookResult = ReturnType<
  typeof useAdSkuInstanceQuery
>;
export type AdSkuInstanceLazyQueryHookResult = ReturnType<
  typeof useAdSkuInstanceLazyQuery
>;
export type AdSkuInstanceQueryResult = Apollo.QueryResult<
  AdSkuInstanceQuery,
  AdSkuInstanceQueryVariables
>;
export const AdSkuInstancesDocument = gql`
  query AdSkuInstances(
    $filters: [Filter!]
    $rangeFilters: [RangeFilter!]
    $unionFilters: [UnionFilter!]
    $slice: Slice
  ) {
    adSkuInstances(
      filters: $filters
      rangeFilters: $rangeFilters
      unionFilters: $unionFilters
      slice: $slice
    ) {
      ... on AdSkuInstanceListWithMetadata {
        metadata {
          matches {
            template
            count
          }
        }
        items {
          ...adSkuInstanceFields
        }
      }
      ...errorFragment
    }
  }
  ${AdSkuInstanceFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useAdSkuInstancesQuery__
 *
 * To run a query within a React component, call `useAdSkuInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdSkuInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdSkuInstancesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      rangeFilters: // value for 'rangeFilters'
 *      unionFilters: // value for 'unionFilters'
 *      slice: // value for 'slice'
 *   },
 * });
 */
export function useAdSkuInstancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdSkuInstancesQuery,
    AdSkuInstancesQueryVariables
  >,
) {
  return Apollo.useQuery<AdSkuInstancesQuery, AdSkuInstancesQueryVariables>(
    AdSkuInstancesDocument,
    baseOptions,
  );
}
export function useAdSkuInstancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdSkuInstancesQuery,
    AdSkuInstancesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<AdSkuInstancesQuery, AdSkuInstancesQueryVariables>(
    AdSkuInstancesDocument,
    baseOptions,
  );
}
export type AdSkuInstancesQueryHookResult = ReturnType<
  typeof useAdSkuInstancesQuery
>;
export type AdSkuInstancesLazyQueryHookResult = ReturnType<
  typeof useAdSkuInstancesLazyQuery
>;
export type AdSkuInstancesQueryResult = Apollo.QueryResult<
  AdSkuInstancesQuery,
  AdSkuInstancesQueryVariables
>;
export const AdSkuWithInstancesDocument = gql`
  query AdSkuWithInstances($id: ID!) {
    adSku(id: $id) {
      ... on AdSku {
        ...adSkuFields
        adSkuInstances {
          ... on AdSkuInstanceList {
            items {
              ...adSkuInstanceFields
            }
          }
        }
      }
      ...errorFragment
    }
  }
  ${AdSkuFieldsFragmentDoc}
  ${AdSkuInstanceFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useAdSkuWithInstancesQuery__
 *
 * To run a query within a React component, call `useAdSkuWithInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdSkuWithInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdSkuWithInstancesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdSkuWithInstancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdSkuWithInstancesQuery,
    AdSkuWithInstancesQueryVariables
  >,
) {
  return Apollo.useQuery<
    AdSkuWithInstancesQuery,
    AdSkuWithInstancesQueryVariables
  >(AdSkuWithInstancesDocument, baseOptions);
}
export function useAdSkuWithInstancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdSkuWithInstancesQuery,
    AdSkuWithInstancesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AdSkuWithInstancesQuery,
    AdSkuWithInstancesQueryVariables
  >(AdSkuWithInstancesDocument, baseOptions);
}
export type AdSkuWithInstancesQueryHookResult = ReturnType<
  typeof useAdSkuWithInstancesQuery
>;
export type AdSkuWithInstancesLazyQueryHookResult = ReturnType<
  typeof useAdSkuWithInstancesLazyQuery
>;
export type AdSkuWithInstancesQueryResult = Apollo.QueryResult<
  AdSkuWithInstancesQuery,
  AdSkuWithInstancesQueryVariables
>;
export const AdSkusDocument = gql`
  query AdSkus(
    $filters: [Filter!]
    $rangeFilters: [RangeFilter!]
    $unionFilters: [UnionFilter!]
    $criteria: Criteria
    $slice: Slice
  ) {
    adSkus(
      filters: $filters
      rangeFilters: $rangeFilters
      unionFilters: $unionFilters
      criteria: $criteria
      slice: $slice
    ) {
      ... on AdSkuListWithMetadata {
        metadata {
          matches {
            template
            count
          }
        }
        items {
          adSku {
            ...adSkuFields
          }
          metadata {
            matches
            related
          }
        }
      }
      ...errorFragment
    }
  }
  ${AdSkuFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useAdSkusQuery__
 *
 * To run a query within a React component, call `useAdSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdSkusQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      rangeFilters: // value for 'rangeFilters'
 *      unionFilters: // value for 'unionFilters'
 *      criteria: // value for 'criteria'
 *      slice: // value for 'slice'
 *   },
 * });
 */
export function useAdSkusQuery(
  baseOptions?: Apollo.QueryHookOptions<AdSkusQuery, AdSkusQueryVariables>,
) {
  return Apollo.useQuery<AdSkusQuery, AdSkusQueryVariables>(
    AdSkusDocument,
    baseOptions,
  );
}
export function useAdSkusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdSkusQuery, AdSkusQueryVariables>,
) {
  return Apollo.useLazyQuery<AdSkusQuery, AdSkusQueryVariables>(
    AdSkusDocument,
    baseOptions,
  );
}
export type AdSkusQueryHookResult = ReturnType<typeof useAdSkusQuery>;
export type AdSkusLazyQueryHookResult = ReturnType<typeof useAdSkusLazyQuery>;
export type AdSkusQueryResult = Apollo.QueryResult<
  AdSkusQuery,
  AdSkusQueryVariables
>;
export const AdSkusWithInstancesDocument = gql`
  query AdSkusWithInstances(
    $filters: [Filter!]
    $rangeFilters: [RangeFilter!]
    $unionFilters: [UnionFilter!]
    $criteria: Criteria
    $slice: Slice
  ) {
    adSkus(
      filters: $filters
      rangeFilters: $rangeFilters
      unionFilters: $unionFilters
      criteria: $criteria
      slice: $slice
    ) {
      ... on AdSkuListWithMetadata {
        metadata {
          matches {
            template
            count
          }
        }
        items {
          adSku {
            ...adSkuFields
            adSkuInstances {
              ... on AdSkuInstanceList {
                items {
                  ...adSkuInstanceFields
                }
              }
            }
          }
          metadata {
            matches
            related
          }
        }
      }
      ...errorFragment
    }
  }
  ${AdSkuFieldsFragmentDoc}
  ${AdSkuInstanceFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useAdSkusWithInstancesQuery__
 *
 * To run a query within a React component, call `useAdSkusWithInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdSkusWithInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdSkusWithInstancesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      rangeFilters: // value for 'rangeFilters'
 *      unionFilters: // value for 'unionFilters'
 *      criteria: // value for 'criteria'
 *      slice: // value for 'slice'
 *   },
 * });
 */
export function useAdSkusWithInstancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdSkusWithInstancesQuery,
    AdSkusWithInstancesQueryVariables
  >,
) {
  return Apollo.useQuery<
    AdSkusWithInstancesQuery,
    AdSkusWithInstancesQueryVariables
  >(AdSkusWithInstancesDocument, baseOptions);
}
export function useAdSkusWithInstancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdSkusWithInstancesQuery,
    AdSkusWithInstancesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AdSkusWithInstancesQuery,
    AdSkusWithInstancesQueryVariables
  >(AdSkusWithInstancesDocument, baseOptions);
}
export type AdSkusWithInstancesQueryHookResult = ReturnType<
  typeof useAdSkusWithInstancesQuery
>;
export type AdSkusWithInstancesLazyQueryHookResult = ReturnType<
  typeof useAdSkusWithInstancesLazyQuery
>;
export type AdSkusWithInstancesQueryResult = Apollo.QueryResult<
  AdSkusWithInstancesQuery,
  AdSkusWithInstancesQueryVariables
>;
export const ContextDocument = gql`
  query Context($mappings: [String!]!) {
    mainCategories {
      ... on MainCategoryList {
        mainCategories {
          id
          name
          key
          subcategories {
            ... on SubcategoryList {
              subcategories {
                id
                name
                key
              }
            }
            ...errorFragment
          }
        }
      }
      ...errorFragment
    }
    skuTemplate {
      ... on Template {
        ...templateFields
      }
      ...errorFragment
    }
    adSkuTemplate {
      ... on Template {
        ...templateFields
      }
      ...errorFragment
    }
    skuInstanceTemplate {
      ... on Template {
        ...templateFields
      }
      ...errorFragment
    }
    adSkuInstanceTemplate {
      ... on Template {
        ...templateFields
      }
      ...errorFragment
    }
    mappings(names: $mappings) {
      ... on MappingList {
        mappings {
          id
          name
          mappings
        }
      }
      ...errorFragment
    }
    offices {
      ... on OfficeList {
        items {
          id
          city
          zipCode
          street
          streetNumber
          building
          locationNumber
          officeFloors {
            ... on OfficeFloorList {
              items {
                id
                floorLabel
              }
            }
            ... on Error {
              message
            }
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
  ${TemplateFieldsFragmentDoc}
`;

/**
 * __useContextQuery__
 *
 * To run a query within a React component, call `useContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContextQuery({
 *   variables: {
 *      mappings: // value for 'mappings'
 *   },
 * });
 */
export function useContextQuery(
  baseOptions?: Apollo.QueryHookOptions<ContextQuery, ContextQueryVariables>,
) {
  return Apollo.useQuery<ContextQuery, ContextQueryVariables>(
    ContextDocument,
    baseOptions,
  );
}
export function useContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContextQuery,
    ContextQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ContextQuery, ContextQueryVariables>(
    ContextDocument,
    baseOptions,
  );
}
export type ContextQueryHookResult = ReturnType<typeof useContextQuery>;
export type ContextLazyQueryHookResult = ReturnType<typeof useContextLazyQuery>;
export type ContextQueryResult = Apollo.QueryResult<
  ContextQuery,
  ContextQueryVariables
>;
export const CreateAdSkuDocument = gql`
  mutation CreateAdSku($input: CreateAdSkuInput!) {
    createAdSku(input: $input) {
      ... on CreateAdSkuPayload {
        adSku {
          ...adSkuFields
        }
      }
      ...errorFragment
    }
  }
  ${AdSkuFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type CreateAdSkuMutationFn = Apollo.MutationFunction<
  CreateAdSkuMutation,
  CreateAdSkuMutationVariables
>;

/**
 * __useCreateAdSkuMutation__
 *
 * To run a mutation, you first call `useCreateAdSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdSkuMutation, { data, loading, error }] = useCreateAdSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdSkuMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAdSkuMutation,
    CreateAdSkuMutationVariables
  >,
) {
  return Apollo.useMutation<CreateAdSkuMutation, CreateAdSkuMutationVariables>(
    CreateAdSkuDocument,
    baseOptions,
  );
}
export type CreateAdSkuMutationHookResult = ReturnType<
  typeof useCreateAdSkuMutation
>;
export type CreateAdSkuMutationResult = Apollo.MutationResult<
  CreateAdSkuMutation
>;
export type CreateAdSkuMutationOptions = Apollo.BaseMutationOptions<
  CreateAdSkuMutation,
  CreateAdSkuMutationVariables
>;
export const CreateAdSkuInstanceDocument = gql`
  mutation CreateAdSkuInstance($input: CreateAdSkuInstanceInput!) {
    createAdSkuInstance(input: $input) {
      ... on CreateAdSkuInstancePayload {
        adSkuInstance {
          ...adSkuInstanceFields
        }
      }
      ...errorFragment
    }
  }
  ${AdSkuInstanceFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type CreateAdSkuInstanceMutationFn = Apollo.MutationFunction<
  CreateAdSkuInstanceMutation,
  CreateAdSkuInstanceMutationVariables
>;

/**
 * __useCreateAdSkuInstanceMutation__
 *
 * To run a mutation, you first call `useCreateAdSkuInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdSkuInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdSkuInstanceMutation, { data, loading, error }] = useCreateAdSkuInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdSkuInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAdSkuInstanceMutation,
    CreateAdSkuInstanceMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateAdSkuInstanceMutation,
    CreateAdSkuInstanceMutationVariables
  >(CreateAdSkuInstanceDocument, baseOptions);
}
export type CreateAdSkuInstanceMutationHookResult = ReturnType<
  typeof useCreateAdSkuInstanceMutation
>;
export type CreateAdSkuInstanceMutationResult = Apollo.MutationResult<
  CreateAdSkuInstanceMutation
>;
export type CreateAdSkuInstanceMutationOptions = Apollo.BaseMutationOptions<
  CreateAdSkuInstanceMutation,
  CreateAdSkuInstanceMutationVariables
>;
export const CreateAdSkuInstancesDocument = gql`
  mutation CreateAdSkuInstances($input: CreateAdSkuInstancesInput!) {
    createAdSkuInstances(input: $input) {
      ... on CreateAdSkuInstancesPayload {
        items {
          ...adSkuInstanceFields
        }
      }
      ...errorFragment
    }
  }
  ${AdSkuInstanceFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type CreateAdSkuInstancesMutationFn = Apollo.MutationFunction<
  CreateAdSkuInstancesMutation,
  CreateAdSkuInstancesMutationVariables
>;

/**
 * __useCreateAdSkuInstancesMutation__
 *
 * To run a mutation, you first call `useCreateAdSkuInstancesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdSkuInstancesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdSkuInstancesMutation, { data, loading, error }] = useCreateAdSkuInstancesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdSkuInstancesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAdSkuInstancesMutation,
    CreateAdSkuInstancesMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateAdSkuInstancesMutation,
    CreateAdSkuInstancesMutationVariables
  >(CreateAdSkuInstancesDocument, baseOptions);
}
export type CreateAdSkuInstancesMutationHookResult = ReturnType<
  typeof useCreateAdSkuInstancesMutation
>;
export type CreateAdSkuInstancesMutationResult = Apollo.MutationResult<
  CreateAdSkuInstancesMutation
>;
export type CreateAdSkuInstancesMutationOptions = Apollo.BaseMutationOptions<
  CreateAdSkuInstancesMutation,
  CreateAdSkuInstancesMutationVariables
>;
export const CreateOfficeDocument = gql`
  mutation CreateOffice($input: CreateOfficeInput!) {
    createOffice(input: $input) {
      ... on CreateOfficePayload {
        office {
          ...officeFields
        }
      }
      ...errorFragment
    }
  }
  ${OfficeFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type CreateOfficeMutationFn = Apollo.MutationFunction<
  CreateOfficeMutation,
  CreateOfficeMutationVariables
>;

/**
 * __useCreateOfficeMutation__
 *
 * To run a mutation, you first call `useCreateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfficeMutation, { data, loading, error }] = useCreateOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOfficeMutation,
    CreateOfficeMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateOfficeMutation,
    CreateOfficeMutationVariables
  >(CreateOfficeDocument, baseOptions);
}
export type CreateOfficeMutationHookResult = ReturnType<
  typeof useCreateOfficeMutation
>;
export type CreateOfficeMutationResult = Apollo.MutationResult<
  CreateOfficeMutation
>;
export type CreateOfficeMutationOptions = Apollo.BaseMutationOptions<
  CreateOfficeMutation,
  CreateOfficeMutationVariables
>;
export const CreateSkuDocument = gql`
  mutation CreateSku($input: CreateSkuInput!) {
    createSku(input: $input) {
      ... on CreateSkuPayload {
        sku {
          ...skuFields
        }
      }
      ...errorFragment
    }
  }
  ${SkuFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type CreateSkuMutationFn = Apollo.MutationFunction<
  CreateSkuMutation,
  CreateSkuMutationVariables
>;

/**
 * __useCreateSkuMutation__
 *
 * To run a mutation, you first call `useCreateSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkuMutation, { data, loading, error }] = useCreateSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSkuMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSkuMutation,
    CreateSkuMutationVariables
  >,
) {
  return Apollo.useMutation<CreateSkuMutation, CreateSkuMutationVariables>(
    CreateSkuDocument,
    baseOptions,
  );
}
export type CreateSkuMutationHookResult = ReturnType<
  typeof useCreateSkuMutation
>;
export type CreateSkuMutationResult = Apollo.MutationResult<CreateSkuMutation>;
export type CreateSkuMutationOptions = Apollo.BaseMutationOptions<
  CreateSkuMutation,
  CreateSkuMutationVariables
>;
export const CreateSkuInstanceDocument = gql`
  mutation CreateSkuInstance($input: CreateSkuInstanceInput!) {
    createSkuInstance(input: $input) {
      ... on CreateSkuInstancePayload {
        skuInstance {
          ...skuInstanceFields
        }
      }
      ...errorFragment
    }
  }
  ${SkuInstanceFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type CreateSkuInstanceMutationFn = Apollo.MutationFunction<
  CreateSkuInstanceMutation,
  CreateSkuInstanceMutationVariables
>;

/**
 * __useCreateSkuInstanceMutation__
 *
 * To run a mutation, you first call `useCreateSkuInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkuInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkuInstanceMutation, { data, loading, error }] = useCreateSkuInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSkuInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSkuInstanceMutation,
    CreateSkuInstanceMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateSkuInstanceMutation,
    CreateSkuInstanceMutationVariables
  >(CreateSkuInstanceDocument, baseOptions);
}
export type CreateSkuInstanceMutationHookResult = ReturnType<
  typeof useCreateSkuInstanceMutation
>;
export type CreateSkuInstanceMutationResult = Apollo.MutationResult<
  CreateSkuInstanceMutation
>;
export type CreateSkuInstanceMutationOptions = Apollo.BaseMutationOptions<
  CreateSkuInstanceMutation,
  CreateSkuInstanceMutationVariables
>;
export const CreateSkuInstancesDocument = gql`
  mutation CreateSkuInstances($input: CreateSkuInstancesInput!) {
    createSkuInstances(input: $input) {
      ... on CreateSkuInstancesPayload {
        items {
          ...skuInstanceFields
        }
      }
      ...errorFragment
    }
  }
  ${SkuInstanceFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type CreateSkuInstancesMutationFn = Apollo.MutationFunction<
  CreateSkuInstancesMutation,
  CreateSkuInstancesMutationVariables
>;

/**
 * __useCreateSkuInstancesMutation__
 *
 * To run a mutation, you first call `useCreateSkuInstancesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkuInstancesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkuInstancesMutation, { data, loading, error }] = useCreateSkuInstancesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSkuInstancesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSkuInstancesMutation,
    CreateSkuInstancesMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateSkuInstancesMutation,
    CreateSkuInstancesMutationVariables
  >(CreateSkuInstancesDocument, baseOptions);
}
export type CreateSkuInstancesMutationHookResult = ReturnType<
  typeof useCreateSkuInstancesMutation
>;
export type CreateSkuInstancesMutationResult = Apollo.MutationResult<
  CreateSkuInstancesMutation
>;
export type CreateSkuInstancesMutationOptions = Apollo.BaseMutationOptions<
  CreateSkuInstancesMutation,
  CreateSkuInstancesMutationVariables
>;
export const CreateSubcategoryDocument = gql`
  mutation CreateSubcategory($input: CreateSubcategoryInput!) {
    createSubcategory(input: $input) {
      ... on CreateSubcategoryPayload {
        subcategory {
          id
          name
          key
        }
      }
      ...errorFragment
    }
  }
  ${ErrorFragmentFragmentDoc}
`;
export type CreateSubcategoryMutationFn = Apollo.MutationFunction<
  CreateSubcategoryMutation,
  CreateSubcategoryMutationVariables
>;

/**
 * __useCreateSubcategoryMutation__
 *
 * To run a mutation, you first call `useCreateSubcategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcategoryMutation, { data, loading, error }] = useCreateSubcategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubcategoryMutation,
    CreateSubcategoryMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateSubcategoryMutation,
    CreateSubcategoryMutationVariables
  >(CreateSubcategoryDocument, baseOptions);
}
export type CreateSubcategoryMutationHookResult = ReturnType<
  typeof useCreateSubcategoryMutation
>;
export type CreateSubcategoryMutationResult = Apollo.MutationResult<
  CreateSubcategoryMutation
>;
export type CreateSubcategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateSubcategoryMutation,
  CreateSubcategoryMutationVariables
>;
export const CreateTenantDocument = gql`
  mutation CreateTenant($input: CreateTenantInput!) {
    createTenant(input: $input) {
      ... on CreateTenantPayload {
        __typename
        tenant {
          name
          displayName
          id
        }
      }
      ...errorFragment
    }
  }
  ${ErrorFragmentFragmentDoc}
`;
export type CreateTenantMutationFn = Apollo.MutationFunction<
  CreateTenantMutation,
  CreateTenantMutationVariables
>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTenantMutation,
    CreateTenantMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateTenantMutation,
    CreateTenantMutationVariables
  >(CreateTenantDocument, baseOptions);
}
export type CreateTenantMutationHookResult = ReturnType<
  typeof useCreateTenantMutation
>;
export type CreateTenantMutationResult = Apollo.MutationResult<
  CreateTenantMutation
>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<
  CreateTenantMutation,
  CreateTenantMutationVariables
>;
export const DeleteAdSkuInstanceDocument = gql`
  mutation DeleteAdSkuInstance($input: DeleteAdSkuInstanceInput!) {
    deleteAdSkuInstance(input: $input) {
      ... on DeleteAdSkuInstancePayload {
        removed
      }
      ...errorFragment
    }
  }
  ${ErrorFragmentFragmentDoc}
`;
export type DeleteAdSkuInstanceMutationFn = Apollo.MutationFunction<
  DeleteAdSkuInstanceMutation,
  DeleteAdSkuInstanceMutationVariables
>;

/**
 * __useDeleteAdSkuInstanceMutation__
 *
 * To run a mutation, you first call `useDeleteAdSkuInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdSkuInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdSkuInstanceMutation, { data, loading, error }] = useDeleteAdSkuInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAdSkuInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAdSkuInstanceMutation,
    DeleteAdSkuInstanceMutationVariables
  >,
) {
  return Apollo.useMutation<
    DeleteAdSkuInstanceMutation,
    DeleteAdSkuInstanceMutationVariables
  >(DeleteAdSkuInstanceDocument, baseOptions);
}
export type DeleteAdSkuInstanceMutationHookResult = ReturnType<
  typeof useDeleteAdSkuInstanceMutation
>;
export type DeleteAdSkuInstanceMutationResult = Apollo.MutationResult<
  DeleteAdSkuInstanceMutation
>;
export type DeleteAdSkuInstanceMutationOptions = Apollo.BaseMutationOptions<
  DeleteAdSkuInstanceMutation,
  DeleteAdSkuInstanceMutationVariables
>;
export const DeleteSkuInstanceDocument = gql`
  mutation DeleteSkuInstance($input: DeleteSkuInstanceInput!) {
    deleteSkuInstance(input: $input) {
      ... on DeleteSkuInstancePayload {
        removed
      }
      ...errorFragment
    }
  }
  ${ErrorFragmentFragmentDoc}
`;
export type DeleteSkuInstanceMutationFn = Apollo.MutationFunction<
  DeleteSkuInstanceMutation,
  DeleteSkuInstanceMutationVariables
>;

/**
 * __useDeleteSkuInstanceMutation__
 *
 * To run a mutation, you first call `useDeleteSkuInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkuInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkuInstanceMutation, { data, loading, error }] = useDeleteSkuInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSkuInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSkuInstanceMutation,
    DeleteSkuInstanceMutationVariables
  >,
) {
  return Apollo.useMutation<
    DeleteSkuInstanceMutation,
    DeleteSkuInstanceMutationVariables
  >(DeleteSkuInstanceDocument, baseOptions);
}
export type DeleteSkuInstanceMutationHookResult = ReturnType<
  typeof useDeleteSkuInstanceMutation
>;
export type DeleteSkuInstanceMutationResult = Apollo.MutationResult<
  DeleteSkuInstanceMutation
>;
export type DeleteSkuInstanceMutationOptions = Apollo.BaseMutationOptions<
  DeleteSkuInstanceMutation,
  DeleteSkuInstanceMutationVariables
>;
export const GetOfficeDocument = gql`
  query GetOffice($id: ID!) {
    office(id: $id) {
      ...officeFields
      ...errorFragment
    }
  }
  ${OfficeFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useGetOfficeQuery__
 *
 * To run a query within a React component, call `useGetOfficeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfficeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOfficeQuery,
    GetOfficeQueryVariables
  >,
) {
  return Apollo.useQuery<GetOfficeQuery, GetOfficeQueryVariables>(
    GetOfficeDocument,
    baseOptions,
  );
}
export function useGetOfficeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfficeQuery,
    GetOfficeQueryVariables
  >,
) {
  return Apollo.useLazyQuery<GetOfficeQuery, GetOfficeQueryVariables>(
    GetOfficeDocument,
    baseOptions,
  );
}
export type GetOfficeQueryHookResult = ReturnType<typeof useGetOfficeQuery>;
export type GetOfficeLazyQueryHookResult = ReturnType<
  typeof useGetOfficeLazyQuery
>;
export type GetOfficeQueryResult = Apollo.QueryResult<
  GetOfficeQuery,
  GetOfficeQueryVariables
>;
export const SkuDocument = gql`
  query Sku($id: ID!) {
    sku(id: $id) {
      ...skuFields
      ...errorFragment
    }
  }
  ${SkuFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useSkuQuery__
 *
 * To run a query within a React component, call `useSkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSkuQuery(
  baseOptions?: Apollo.QueryHookOptions<SkuQuery, SkuQueryVariables>,
) {
  return Apollo.useQuery<SkuQuery, SkuQueryVariables>(SkuDocument, baseOptions);
}
export function useSkuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SkuQuery, SkuQueryVariables>,
) {
  return Apollo.useLazyQuery<SkuQuery, SkuQueryVariables>(
    SkuDocument,
    baseOptions,
  );
}
export type SkuQueryHookResult = ReturnType<typeof useSkuQuery>;
export type SkuLazyQueryHookResult = ReturnType<typeof useSkuLazyQuery>;
export type SkuQueryResult = Apollo.QueryResult<SkuQuery, SkuQueryVariables>;
export const SkuInstanceDocument = gql`
  query SkuInstance($id: ID!) {
    skuInstance(id: $id) {
      ... on SkuInstance {
        ...skuInstanceFields
        sku {
          ...skuFields
          ...errorFragment
        }
      }
      ...errorFragment
    }
  }
  ${SkuInstanceFieldsFragmentDoc}
  ${SkuFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useSkuInstanceQuery__
 *
 * To run a query within a React component, call `useSkuInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkuInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuInstanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSkuInstanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SkuInstanceQuery,
    SkuInstanceQueryVariables
  >,
) {
  return Apollo.useQuery<SkuInstanceQuery, SkuInstanceQueryVariables>(
    SkuInstanceDocument,
    baseOptions,
  );
}
export function useSkuInstanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SkuInstanceQuery,
    SkuInstanceQueryVariables
  >,
) {
  return Apollo.useLazyQuery<SkuInstanceQuery, SkuInstanceQueryVariables>(
    SkuInstanceDocument,
    baseOptions,
  );
}
export type SkuInstanceQueryHookResult = ReturnType<typeof useSkuInstanceQuery>;
export type SkuInstanceLazyQueryHookResult = ReturnType<
  typeof useSkuInstanceLazyQuery
>;
export type SkuInstanceQueryResult = Apollo.QueryResult<
  SkuInstanceQuery,
  SkuInstanceQueryVariables
>;
export const SkuInstanceByGtinSerialDocument = gql`
  query SkuInstanceByGtinSerial($gtin: String!, $serialNumber: String!) {
    skuInstancesCrossTenantsByGtinSerial(
      gtin: $gtin
      serialNumber: $serialNumber
    ) {
      ... on SkuInstanceTenantId {
        skuInstance {
          id
        }
        tenantId
      }
      ...errorFragment
    }
  }
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useSkuInstanceByGtinSerialQuery__
 *
 * To run a query within a React component, call `useSkuInstanceByGtinSerialQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkuInstanceByGtinSerialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuInstanceByGtinSerialQuery({
 *   variables: {
 *      gtin: // value for 'gtin'
 *      serialNumber: // value for 'serialNumber'
 *   },
 * });
 */
export function useSkuInstanceByGtinSerialQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SkuInstanceByGtinSerialQuery,
    SkuInstanceByGtinSerialQueryVariables
  >,
) {
  return Apollo.useQuery<
    SkuInstanceByGtinSerialQuery,
    SkuInstanceByGtinSerialQueryVariables
  >(SkuInstanceByGtinSerialDocument, baseOptions);
}
export function useSkuInstanceByGtinSerialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SkuInstanceByGtinSerialQuery,
    SkuInstanceByGtinSerialQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    SkuInstanceByGtinSerialQuery,
    SkuInstanceByGtinSerialQueryVariables
  >(SkuInstanceByGtinSerialDocument, baseOptions);
}
export type SkuInstanceByGtinSerialQueryHookResult = ReturnType<
  typeof useSkuInstanceByGtinSerialQuery
>;
export type SkuInstanceByGtinSerialLazyQueryHookResult = ReturnType<
  typeof useSkuInstanceByGtinSerialLazyQuery
>;
export type SkuInstanceByGtinSerialQueryResult = Apollo.QueryResult<
  SkuInstanceByGtinSerialQuery,
  SkuInstanceByGtinSerialQueryVariables
>;
export const SkuInstancesDocument = gql`
  query SkuInstances($filters: [Filter!], $slice: Slice) {
    skuInstances(filters: $filters, slice: $slice) {
      ... on SkuInstanceListWithMetadata {
        metadata {
          matches {
            template
            count
          }
        }
        skuInstances {
          ...skuInstanceFields
        }
      }
      ...errorFragment
    }
  }
  ${SkuInstanceFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useSkuInstancesQuery__
 *
 * To run a query within a React component, call `useSkuInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkuInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuInstancesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      slice: // value for 'slice'
 *   },
 * });
 */
export function useSkuInstancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SkuInstancesQuery,
    SkuInstancesQueryVariables
  >,
) {
  return Apollo.useQuery<SkuInstancesQuery, SkuInstancesQueryVariables>(
    SkuInstancesDocument,
    baseOptions,
  );
}
export function useSkuInstancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SkuInstancesQuery,
    SkuInstancesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<SkuInstancesQuery, SkuInstancesQueryVariables>(
    SkuInstancesDocument,
    baseOptions,
  );
}
export type SkuInstancesQueryHookResult = ReturnType<
  typeof useSkuInstancesQuery
>;
export type SkuInstancesLazyQueryHookResult = ReturnType<
  typeof useSkuInstancesLazyQuery
>;
export type SkuInstancesQueryResult = Apollo.QueryResult<
  SkuInstancesQuery,
  SkuInstancesQueryVariables
>;
export const SkusDocument = gql`
  query Skus($filters: [Filter!], $slice: Slice) {
    skus(filters: $filters, slice: $slice) {
      ... on SkuListWithMetadata {
        metadata {
          matches {
            template
            count
          }
        }
        skus {
          sku {
            ...skuFields
          }
          metadata {
            matches
            related
          }
        }
      }
      ...errorFragment
    }
  }
  ${SkuFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useSkusQuery__
 *
 * To run a query within a React component, call `useSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkusQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      slice: // value for 'slice'
 *   },
 * });
 */
export function useSkusQuery(
  baseOptions?: Apollo.QueryHookOptions<SkusQuery, SkusQueryVariables>,
) {
  return Apollo.useQuery<SkusQuery, SkusQueryVariables>(
    SkusDocument,
    baseOptions,
  );
}
export function useSkusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SkusQuery, SkusQueryVariables>,
) {
  return Apollo.useLazyQuery<SkusQuery, SkusQueryVariables>(
    SkusDocument,
    baseOptions,
  );
}
export type SkusQueryHookResult = ReturnType<typeof useSkusQuery>;
export type SkusLazyQueryHookResult = ReturnType<typeof useSkusLazyQuery>;
export type SkusQueryResult = Apollo.QueryResult<SkusQuery, SkusQueryVariables>;
export const GetTenantDocument = gql`
  query GetTenant($id: ID!) {
    tenant(id: $id) {
      ...tenantFields
      ...errorFragment
    }
  }
  ${TenantFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useGetTenantQuery__
 *
 * To run a query within a React component, call `useGetTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTenantQuery,
    GetTenantQueryVariables
  >,
) {
  return Apollo.useQuery<GetTenantQuery, GetTenantQueryVariables>(
    GetTenantDocument,
    baseOptions,
  );
}
export function useGetTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantQuery,
    GetTenantQueryVariables
  >,
) {
  return Apollo.useLazyQuery<GetTenantQuery, GetTenantQueryVariables>(
    GetTenantDocument,
    baseOptions,
  );
}
export type GetTenantQueryHookResult = ReturnType<typeof useGetTenantQuery>;
export type GetTenantLazyQueryHookResult = ReturnType<
  typeof useGetTenantLazyQuery
>;
export type GetTenantQueryResult = Apollo.QueryResult<
  GetTenantQuery,
  GetTenantQueryVariables
>;
export const UpdateAdSkuDocument = gql`
  mutation UpdateAdSku($input: UpdateAdSkuInput!) {
    updateAdSku(input: $input) {
      ... on UpdateAdSkuPayload {
        adSku {
          ...adSkuFields
        }
      }
      ...errorFragment
    }
  }
  ${AdSkuFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type UpdateAdSkuMutationFn = Apollo.MutationFunction<
  UpdateAdSkuMutation,
  UpdateAdSkuMutationVariables
>;

/**
 * __useUpdateAdSkuMutation__
 *
 * To run a mutation, you first call `useUpdateAdSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdSkuMutation, { data, loading, error }] = useUpdateAdSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdSkuMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdSkuMutation,
    UpdateAdSkuMutationVariables
  >,
) {
  return Apollo.useMutation<UpdateAdSkuMutation, UpdateAdSkuMutationVariables>(
    UpdateAdSkuDocument,
    baseOptions,
  );
}
export type UpdateAdSkuMutationHookResult = ReturnType<
  typeof useUpdateAdSkuMutation
>;
export type UpdateAdSkuMutationResult = Apollo.MutationResult<
  UpdateAdSkuMutation
>;
export type UpdateAdSkuMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdSkuMutation,
  UpdateAdSkuMutationVariables
>;
export const UpdateAdSkuInstanceDocument = gql`
  mutation UpdateAdSkuInstance($input: UpdateAdSkuInstanceInput!) {
    updateAdSkuInstance(input: $input) {
      ... on UpdateAdSkuInstancePayload {
        adSkuInstance {
          ...adSkuInstanceFields
        }
      }
      ...errorFragment
    }
  }
  ${AdSkuInstanceFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type UpdateAdSkuInstanceMutationFn = Apollo.MutationFunction<
  UpdateAdSkuInstanceMutation,
  UpdateAdSkuInstanceMutationVariables
>;

/**
 * __useUpdateAdSkuInstanceMutation__
 *
 * To run a mutation, you first call `useUpdateAdSkuInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdSkuInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdSkuInstanceMutation, { data, loading, error }] = useUpdateAdSkuInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdSkuInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdSkuInstanceMutation,
    UpdateAdSkuInstanceMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateAdSkuInstanceMutation,
    UpdateAdSkuInstanceMutationVariables
  >(UpdateAdSkuInstanceDocument, baseOptions);
}
export type UpdateAdSkuInstanceMutationHookResult = ReturnType<
  typeof useUpdateAdSkuInstanceMutation
>;
export type UpdateAdSkuInstanceMutationResult = Apollo.MutationResult<
  UpdateAdSkuInstanceMutation
>;
export type UpdateAdSkuInstanceMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdSkuInstanceMutation,
  UpdateAdSkuInstanceMutationVariables
>;
export const UpdateOfficeDocument = gql`
  mutation UpdateOffice($input: UpdateOfficeInput!) {
    updateOffice(input: $input) {
      ... on UpdateOfficePayload {
        office {
          ...officeFields
        }
      }
      ...errorFragment
    }
  }
  ${OfficeFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type UpdateOfficeMutationFn = Apollo.MutationFunction<
  UpdateOfficeMutation,
  UpdateOfficeMutationVariables
>;

/**
 * __useUpdateOfficeMutation__
 *
 * To run a mutation, you first call `useUpdateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfficeMutation, { data, loading, error }] = useUpdateOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOfficeMutation,
    UpdateOfficeMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateOfficeMutation,
    UpdateOfficeMutationVariables
  >(UpdateOfficeDocument, baseOptions);
}
export type UpdateOfficeMutationHookResult = ReturnType<
  typeof useUpdateOfficeMutation
>;
export type UpdateOfficeMutationResult = Apollo.MutationResult<
  UpdateOfficeMutation
>;
export type UpdateOfficeMutationOptions = Apollo.BaseMutationOptions<
  UpdateOfficeMutation,
  UpdateOfficeMutationVariables
>;
export const UpdateSkuDocument = gql`
  mutation UpdateSku($input: UpdateSkuInput!) {
    updateSku(input: $input) {
      ... on UpdateSkuPayload {
        sku {
          ...skuFields
        }
      }
      ...errorFragment
    }
  }
  ${SkuFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type UpdateSkuMutationFn = Apollo.MutationFunction<
  UpdateSkuMutation,
  UpdateSkuMutationVariables
>;

/**
 * __useUpdateSkuMutation__
 *
 * To run a mutation, you first call `useUpdateSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkuMutation, { data, loading, error }] = useUpdateSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSkuMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSkuMutation,
    UpdateSkuMutationVariables
  >,
) {
  return Apollo.useMutation<UpdateSkuMutation, UpdateSkuMutationVariables>(
    UpdateSkuDocument,
    baseOptions,
  );
}
export type UpdateSkuMutationHookResult = ReturnType<
  typeof useUpdateSkuMutation
>;
export type UpdateSkuMutationResult = Apollo.MutationResult<UpdateSkuMutation>;
export type UpdateSkuMutationOptions = Apollo.BaseMutationOptions<
  UpdateSkuMutation,
  UpdateSkuMutationVariables
>;
export const UpdateSkuFunctionalitiesDocument = gql`
  mutation UpdateSkuFunctionalities($input: UpdateSkuFunctionalitiesInput!) {
    updateSkuFunctionalities(input: $input) {
      ... on UpdateSkuPayload {
        sku {
          ...skuFields
        }
      }
      ...errorFragment
    }
  }
  ${SkuFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type UpdateSkuFunctionalitiesMutationFn = Apollo.MutationFunction<
  UpdateSkuFunctionalitiesMutation,
  UpdateSkuFunctionalitiesMutationVariables
>;

/**
 * __useUpdateSkuFunctionalitiesMutation__
 *
 * To run a mutation, you first call `useUpdateSkuFunctionalitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkuFunctionalitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkuFunctionalitiesMutation, { data, loading, error }] = useUpdateSkuFunctionalitiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSkuFunctionalitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSkuFunctionalitiesMutation,
    UpdateSkuFunctionalitiesMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateSkuFunctionalitiesMutation,
    UpdateSkuFunctionalitiesMutationVariables
  >(UpdateSkuFunctionalitiesDocument, baseOptions);
}
export type UpdateSkuFunctionalitiesMutationHookResult = ReturnType<
  typeof useUpdateSkuFunctionalitiesMutation
>;
export type UpdateSkuFunctionalitiesMutationResult = Apollo.MutationResult<
  UpdateSkuFunctionalitiesMutation
>;
export type UpdateSkuFunctionalitiesMutationOptions = Apollo.BaseMutationOptions<
  UpdateSkuFunctionalitiesMutation,
  UpdateSkuFunctionalitiesMutationVariables
>;
export const UpdateSkuInstanceDocument = gql`
  mutation UpdateSkuInstance($input: UpdateSkuInstanceInput!) {
    updateSkuInstance(input: $input) {
      ... on UpdateSkuInstancePayload {
        skuInstance {
          ...skuInstanceFields
        }
      }
      ...errorFragment
    }
  }
  ${SkuInstanceFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type UpdateSkuInstanceMutationFn = Apollo.MutationFunction<
  UpdateSkuInstanceMutation,
  UpdateSkuInstanceMutationVariables
>;

/**
 * __useUpdateSkuInstanceMutation__
 *
 * To run a mutation, you first call `useUpdateSkuInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkuInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkuInstanceMutation, { data, loading, error }] = useUpdateSkuInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSkuInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSkuInstanceMutation,
    UpdateSkuInstanceMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateSkuInstanceMutation,
    UpdateSkuInstanceMutationVariables
  >(UpdateSkuInstanceDocument, baseOptions);
}
export type UpdateSkuInstanceMutationHookResult = ReturnType<
  typeof useUpdateSkuInstanceMutation
>;
export type UpdateSkuInstanceMutationResult = Apollo.MutationResult<
  UpdateSkuInstanceMutation
>;
export type UpdateSkuInstanceMutationOptions = Apollo.BaseMutationOptions<
  UpdateSkuInstanceMutation,
  UpdateSkuInstanceMutationVariables
>;
export const UpdateTenantDocument = gql`
  mutation UpdateTenant($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      ... on CreateTenantPayload {
        __typename
        tenant {
          ...tenantFields
        }
      }
      ...errorFragment
    }
  }
  ${TenantFieldsFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export type UpdateTenantMutationFn = Apollo.MutationFunction<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
  >(UpdateTenantDocument, baseOptions);
}
export type UpdateTenantMutationHookResult = ReturnType<
  typeof useUpdateTenantMutation
>;
export type UpdateTenantMutationResult = Apollo.MutationResult<
  UpdateTenantMutation
>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>;
export const UploadImageDocument = gql`
  mutation UploadImage($file: Upload!) {
    uploadImage(file: $file) {
      ... on Image {
        filename
        mimetype
        encoding
        optimizedImages {
          filename
          type
          size
        }
      }
      ... on Error {
        message
      }
    }
  }
`;
export type UploadImageMutationFn = Apollo.MutationFunction<
  UploadImageMutation,
  UploadImageMutationVariables
>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadImageMutation,
    UploadImageMutationVariables
  >,
) {
  return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(
    UploadImageDocument,
    baseOptions,
  );
}
export type UploadImageMutationHookResult = ReturnType<
  typeof useUploadImageMutation
>;
export type UploadImageMutationResult = Apollo.MutationResult<
  UploadImageMutation
>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<
  UploadImageMutation,
  UploadImageMutationVariables
>;
export const UserDocument = gql`
  query User {
    user {
      ... on User {
        tenants {
          id
          name
          displayName
        }
        isPartnerAdmin
      }
      ...errorFragment
    }
  }
  ${ErrorFragmentFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(
  baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>,
) {
  return Apollo.useQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    baseOptions,
  );
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    baseOptions,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
