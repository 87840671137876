import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { useTranslation } from '../../../../translations';
import { ResolvedSkuWithMetadata } from '../../../types';
import { SkuPreview } from '../../NewSkuView/SkuPreview';
import { getFunctionalityPreviewProps } from '../../../utils/sku';

type Props = {
  lastSkus: ResolvedSkuWithMetadata[];
  onRegisterInstance: (skuId: string) => void;
};

export const LastSkus: FC<Props> = ({ lastSkus, onRegisterInstance }) => {
  const { t, tk } = useTranslation('inventory');

  if (!lastSkus.length) {
    return null;
  }
  return (
    <>
      <Typography variant="subtitle1">
        {t(tk.newSkuView.welcomeStep.lastSkus.title)}
      </Typography>
      {lastSkus.map(({ sku }) => (
        <Box key={sku.id} mt={2}>
          <SkuPreview
            sku={sku}
            images={sku.images}
            functionality={getFunctionalityPreviewProps(sku)}
            actions={
              <Button
                color="secondary"
                onClick={() => onRegisterInstance(sku.id)}
              >
                {t(tk.newSkuView.welcomeStep.lastSkus.action)}
              </Button>
            }
          />
        </Box>
      ))}
    </>
  );
};
