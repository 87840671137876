import React, { FC } from 'react';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Link } from '../../../../../common/components/Link';
import {
  AdSkuInstancesPayload,
  SkuInstancesPayload,
} from '../../../../graphql/types';
import { useTranslation } from '../../../../../translations';
import { GoOptions } from '../../../../../common/navigation';
import { StatusChip } from '../StatusChip';
import { ConditionChip } from '../ConditionChip';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(4, 3, 3),
    },
    chip: {
      cursor: 'inherit',
      marginRight: theme.spacing(1),
    },
    divider: {
      backgroundColor: theme.palette.primary.veryLight,
      '&:last-child': {
        display: 'none',
      },
    },
    button: {
      alignSelf: 'flex-end',
    },
  }),
);

type Props = {
  skuInstance:
    | SkuInstancesPayload['skuInstances'][number]
    | AdSkuInstancesPayload['items'][number];
  route: { url: string; go(options?: GoOptions): void };
};

export const AdSkuInstanceListItem: FC<Props> = ({ skuInstance, route }) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  return (
    <ListItem
      className={classes.root}
      data-testid={`SkuInstanceListItem-${skuInstance.id}`}
    >
      <div>
        <Typography>
          <strong>
            {t(tk.skuInstanceListView.item.primary, {
              id: skuInstance.id,
            })}
          </strong>
        </Typography>
        <Box mt={2}>
          <StatusChip
            size="small"
            className={classes.chip}
            status={skuInstance.status}
          />
          {skuInstance.condition && (
            <ConditionChip
              size="small"
              className={classes.chip}
              condition={skuInstance.condition}
            />
          )}
        </Box>
      </div>
      <Button
        component={Link}
        route={route}
        variant="outlined"
        className={classes.button}
      >
        {t(tk.skuInstanceListView.item.callToAction)}
      </Button>
    </ListItem>
  );
};
