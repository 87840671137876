import React, { FC } from 'react';

import {
  IndicatorChip,
  IndicatorChipProps,
} from '../../../../common/components/IndicatorChip/IndicatorChip';
import { useTranslation } from '../../../../translations';

type Props = Omit<IndicatorChipProps, 'color'> & {
  condition: string;
};

const colorMapping: Record<string, 'error' | 'secondary'> = {
  broken: 'error',
  worn: 'secondary',
};

const getColor = (condition: string) => {
  if (colorMapping[condition]) {
    return colorMapping[condition];
  }
  return 'success';
};

export const ConditionChip: FC<Props> = ({ condition, label, ...props }) => {
  const { t, tk } = useTranslation('inventory');

  return (
    <IndicatorChip
      color={getColor(condition)}
      label={label || t(tk.skuInstanceListView.item.condition, { condition })}
      {...props}
    />
  );
};
