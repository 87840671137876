import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import { useSkuInstancesQuery, SkuInstancesQuery } from '../../graphql';
import { ErrorView } from '../../../common/components/ErrorView';
import { useNavigation, useSelectedFilters } from '../../../common/navigation';
import { extractQuery } from '../../../common/utils/extractQuery';
import { SkuInstancePayload, SkuInstancesPayload } from '../../graphql/types';
import { getPageCount } from '../../utils/getPageCount';
import { skuInstanceTemplateName } from '../../consts';
import { SkuInstanceListView as SkuInstanceListViewComponent } from '../../components/SkuInstanceListView';
import { SelectedFilters } from '../../utils/useFilters';
import { ResolvedSku } from '../../types';
import { SkuItems } from '../../components/SkuInstanceListView/SkuInstanceList/SkuItemsVariant/SkuItems';

import { useSkuInstanceFilters } from './useSkuInstanceFilters';

type Props = {
  sku: ResolvedSku;
};

const PAGE_LIMIT = 16;

export const SkuInstanceListView: FC<Props> = ({ sku }) => {
  const nav = useNavigation();

  const [selectedSkuItems, setSelectedSkuItems] = useState<
    SkuInstancePayload['id'][]
  >([]);

  const [isSelectedAll, setSelectedAll] = useState<boolean>(true);

  const selectedFilters = useSelectedFilters();
  const setSelectedFilters = (newFilters: SelectedFilters) => {
    return nav.sku({ id: sku.id, filters: newFilters }).go();
  };

  const { filters, queryFilters } = useSkuInstanceFilters(selectedFilters);
  const [page, setPage] = useState(1);

  const result = extractQuery<SkuInstancesQuery, SkuInstancesPayload>(
    useSkuInstancesQuery({
      variables: {
        filters: [
          ...queryFilters,
          { field: 'skuId', values: [sku.id], includeRelated: false },
        ],
        slice: {
          offset: (page - 1) * PAGE_LIMIT,
          limit: PAGE_LIMIT,
        },
      },
    }),
    'SkuInstanceListWithMetadata',
    data => data.skuInstances,
  );

  const allSkuInstancesId = useMemo(() => {
    const arryAllItemsIds: string[] = [];

    result.data?.skuInstances.forEach(item => {
      if (item.status !== 'pre-registered') {
        arryAllItemsIds.push(item.id);
      }
    });

    return arryAllItemsIds;
  }, [result.data]);

  useEffect(() => {
    if (result.loading) {
      return;
    }

    setSelectedAll(false);

    setSelectedSkuItems(prev =>
      prev.filter(itemId => allSkuInstancesId.includes(itemId)),
    );
  }, [allSkuInstancesId, result.loading]);

  if (result.error) {
    return <ErrorView error={result.error} />;
  }

  const handleFilterChange = (field: string, values: string[]) => {
    setPage(1);

    setSelectedAll(false);

    setSelectedFilters({
      ...selectedFilters,
      [field]: values,
    });
  };

  const handleSetPage = (page: number) => {
    setPage(page);
  };

  const handleSelectAllItemsInPage = () => {
    setSelectedSkuItems(allSkuInstancesId);
  };

  const handleUnSelectAllItemsInPage = () => setSelectedSkuItems([]);

  const handleSelectedSkuItems = (
    e: ChangeEvent<HTMLInputElement>,
    chekced: boolean,
  ) =>
    setSelectedSkuItems(prevSelectedSkuItems =>
      chekced
        ? [...prevSelectedSkuItems, e.target.value]
        : prevSelectedSkuItems.filter(val => val !== e.target.value),
    );

  const pageCount = getPageCount(
    result.data?.metadata.matches,
    skuInstanceTemplateName,
    PAGE_LIMIT,
  );

  const findSkuInstanceBaseOnId = selectedSkuItems.map(skuItemId =>
    result.data?.skuInstances.find(item => item.id === skuItemId),
  ) as SkuInstancePayload[] | undefined;

  return (
    <SkuInstanceListViewComponent
      isSelectedAll={isSelectedAll}
      setSelectedAll={setSelectedAll}
      filters={filters}
      loading={result.loading}
      selectedFilters={selectedFilters}
      onFilterChange={handleFilterChange}
      handleSelectAllItemsInPage={handleSelectAllItemsInPage}
      handleUnSelectAllItemsInPage={handleUnSelectAllItemsInPage}
      onPageChange={handleSetPage}
      page={page}
      selectedSkuItems={findSkuInstanceBaseOnId}
      pageCount={pageCount}
      matches={result.data?.metadata.matches}
      sku={sku}
      onRegister={() => nav.newSkuInstance({ skuId: sku.id }).go()}
      items={
        <SkuItems
          handleSelectedSkuItems={handleSelectedSkuItems}
          selectedSkuItems={selectedSkuItems}
          skuInstances={result.data?.skuInstances || []}
          nav={nav}
        />
      }
    />
  );
};
