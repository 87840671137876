import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import pick from 'lodash/pick';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { useNewSkuActions } from '../../../redux/newSku';
import { ColorStep as ColorStepComponent } from '../../../components/NewSkuView/ColorStep';
import { useColorMapping } from '../../../context/useColorMapping';
import * as selectors from '../../../redux/newSku/selectors';
import { useAssertCategory } from '../useAssertCategory';
import { Maybe } from '../../../graphql';

export const ColorStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onGoBack,
  onGoToStep,
  onStepCompleted,
}) => {
  const colorMapping = useColorMapping();
  const { setColors } = useNewSkuActions();
  const backLabel = useSelector(selectors.label);
  const skuInput = useSelector(selectors.skuInput);
  const subcategoryKey = useSelector(selectors.subcategoryKey);

  const initialValues = pick(skuInput, ['primaryColor', 'secondaryColor']);

  useAssertCategory(onGoToStep);

  if (!subcategoryKey) {
    return null;
  }

  const handleSubmit = (
    primaryColor: string,
    secondaryColor: Maybe<string>,
  ) => {
    setColors(primaryColor, secondaryColor);
    onStepCompleted();
  };

  return (
    <ColorStepComponent
      backLabel={backLabel}
      colorMapping={colorMapping}
      initialValues={initialValues}
      onGoBack={onGoBack}
      onSubmit={handleSubmit}
      number={number}
      totalCount={totalCount}
      subcategoryKey={subcategoryKey}
    />
  );
};
