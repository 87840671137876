import React, { FC, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { GraphQlError } from '../../../../common/types';
import { FormFeedback } from '../../../../common/components/FormFeedback';
import { ResolvedSku } from '../../../types';
import { SkuInstanceInput } from '../../../graphql';
import { ResolvedOffice } from '../../../context/types';
import { clientError } from '../../../../common/utils/graphQlErrors';
import { LoadingIndicator } from '../../../../common/components/LoadingIndicator';
import { Navigation } from '../../../../common/navigation';
import { RegistrationCompleteDialog } from '../../common/RegistrationCompleteDialog';
import {
  QrCodeData,
  SkuInstanceScanner,
} from '../../common/SkuInstanceScanner';

import { SkuInstanceSummary } from './SkuInstanceSummary';

const useStyles = makeStyles(theme =>
  createStyles({
    completedCard: {
      backgroundColor: theme.palette.success.veryLight,
      border: `1px solid ${theme.palette.success.main}`,
    },
  }),
);

type Props = {
  sku: Pick<
    ResolvedSku,
    'manufacturer' | 'model' | 'primaryColor' | 'secondaryColor' | 'images'
  >;
  inputs: SkuInstanceInput[];
  office: ResolvedOffice;
  imageUrl?: string;
  number: number;
  totalCount: number;
  loading: number[];
  completed: number[];
  error?: GraphQlError;
  onSubmit: (idx: number, data: QrCodeData) => void;
  onGoBack?: () => void;
  nav: Navigation;
};

export const SummaryStep: FC<Props> = ({
  error,
  inputs,
  office,
  sku,
  loading,
  completed,
  number,
  totalCount,
  onGoBack,
  onSubmit,
  nav,
}) => {
  const { t, tk } = useTranslation('inventory');
  const [scannerIndex, setScannerIndex] = useState<number | null>(null);
  const [scannerError, setScannerError] = useState<GraphQlError | null>(null);
  const classes = useStyles();

  const handleOpenScanner = (idx: number) => () => {
    setScannerIndex(idx);
  };

  const handleScannerSubmit = (data: QrCodeData) => {
    if (scannerIndex !== null && data) {
      onSubmit(scannerIndex, data);
    }
    setScannerIndex(null);
  };

  const handleScannerError = (message: string) => {
    setScannerIndex(null);
    setScannerError(clientError(message));
  };

  const showScanner = scannerIndex !== null;
  const allCompleted = completed.length === inputs.length;

  const handleRegisterNew = () => {
    nav.registration().go();
  };

  const handleViewInventory = () => {
    nav.skus().go();
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [allCompleted]);

  return (
    <WizardStep
      backLabel={`${sku.manufacturer} ${sku.model}`}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      showProgress={false}
      title={t(
        allCompleted
          ? tk.newSkuInstanceView.summaryStep.titleCompleted
          : tk.newSkuInstanceView.summaryStep.title,
      )}
      data-testid="SummaryStep"
    >
      {showScanner && (
        <SkuInstanceScanner
          sku={sku}
          open={showScanner}
          onChange={handleScannerSubmit}
          onError={handleScannerError}
        />
      )}
      <Box mb={3}>
        <Typography variant="subtitle1">
          {t(
            allCompleted
              ? tk.newSkuInstanceView.summaryStep.subtitleCompleted
              : tk.newSkuInstanceView.summaryStep.subtitle,
          )}
        </Typography>
      </Box>
      <FormFeedback error={error || scannerError} />
      {inputs.map((input, idx) => (
        <Box key={idx} mb={1}>
          <SkuInstanceSummary
            className={
              completed.includes(idx) ? classes.completedCard : undefined
            }
            data-testid={`SkuInstanceSummary-${idx}`}
            skuInstanceInput={input}
            office={office}
            sku={sku}
          >
            <Box mt={3}>
              <Button
                disabled={loading.includes(idx) || completed.includes(idx)}
                fullWidth
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleOpenScanner(idx)}
              >
                {loading.includes(idx) ? (
                  <LoadingIndicator size="small" />
                ) : (
                  t(
                    completed.includes(idx)
                      ? tk.newSkuInstanceView.summaryStep.completed
                      : tk.newSkuInstanceView.summaryStep.confirm,
                  )
                )}
              </Button>
            </Box>
          </SkuInstanceSummary>
        </Box>
      ))}
      {completed.length === 0 && (
        <Box mt={3} textAlign="center">
          <Link href="#" onClick={onGoBack}>
            {t(tk.newSkuInstanceView.summaryStep.goBackAndEdit)}
          </Link>
        </Box>
      )}
      {allCompleted && (
        <RegistrationCompleteDialog
          handleRegisterNew={handleRegisterNew}
          handleViewInventory={handleViewInventory}
        />
      )}
    </WizardStep>
  );
};
