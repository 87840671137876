import React, { FC } from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import cn from 'classnames';

const useStyles = makeStyles(theme =>
  createStyles({
    root: ({ color, size, fullWidth }: IndicatorChipProps) => ({
      backgroundColor:
        color === 'default'
          ? theme.palette.primary.veryLight
          : theme.palette[color].veryLight,
      fontSize: size === 'small' ? 12 : 14,
      width: fullWidth ? '100%' : undefined,
      padding: size === 'medium' ? theme.spacing(1) : undefined,
    }),
    outlined: ({ color }: IndicatorChipProps) => ({
      backgroundColor: '#fff',
      borderColor:
        color === 'default'
          ? theme.palette.primary.veryLight
          : theme.palette[color].light,
    }),
  }),
);

export type IndicatorChipProps = Omit<ChipProps, 'color'> & {
  className?: string;
  color:
    | 'default'
    | 'primary'
    | 'success'
    | 'secondary'
    | 'error'
    | 'quaternary';
  variant?: 'default' | 'outlined';
  fullWidth?: boolean;
};

export const IndicatorChip: FC<IndicatorChipProps> = props => {
  const {
    className,
    label,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    color,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fullWidth,
    variant = 'default',
    ...chipProps
  } = props;
  const classes = useStyles(props);

  return (
    <Chip
      className={cn(
        classes.root,
        { [classes.outlined]: variant === 'outlined' },
        className,
      )}
      label={label}
      variant={variant}
      {...chipProps}
    />
  );
};
