import React from 'react';
import Grid from '@material-ui/core/Grid';

import { ResolvedSkuWithMetadata } from '../../types';
import { Navigation } from '../../../common/navigation';

import { SkuListItem } from './SkuListItem';
import { SkuItemMetadata } from './SkuListItem/SkuItemMetadata';

type Props = {
  skus: ResolvedSkuWithMetadata[];
  nav: Pick<Navigation, 'sku'>;
};

export const SkuList: React.FC<Props> = ({ skus, nav }) => (
  <>
    {skus.map(({ sku, metadata }) => (
      <Grid key={sku.id} item xs={6} md={4} lg={3}>
        <SkuListItem
          sku={sku}
          route={nav.sku({ id: sku.id })}
          metadataView={<SkuItemMetadata metadata={metadata} />}
        />
      </Grid>
    ))}
  </>
);
