import React, { ReactNode } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: ({ color }: ExpansionCardProps) => ({
      backgroundColor: theme.palette[color].veryLight,
      border: 0,
      borderRadius: 16,
      '&:before': {
        display: 'none',
      },
    }),
    summary: {
      '&&&': {
        minHeight: 48,
      },
    },
    summaryContent: {
      '&&&': {
        margin: theme.spacing(1.5, 0),
      },
    },
    details: {},
  });

export const useStyles = makeStyles(styles);

export type ExpansionCardProps = {
  color: 'primary' | 'secondary' | 'info' | 'success' | 'error';
  title: ReactNode;
  classes?: Partial<WithStyles<typeof styles>['classes']>;
};

export const ExpansionCard: React.FC<ExpansionCardProps> = props => {
  const { children, title } = props;
  const classes = useStyles(props);
  return (
    <Accordion variant="outlined" className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{ root: classes.summary, content: classes.summaryContent }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
