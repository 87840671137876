import React from 'react';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { ListItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';

import { Office } from '../../../inventory/graphql';
import { useTranslation } from '../../../translations';

const useStyles = makeStyles(theme =>
  createStyles({
    add: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    tenantButtons: {
      '& button': {
        marginLeft: 2,
        marginRight: 2,
      },
    },
  }),
);

type Props = {
  offices: Array<Omit<Office, 'officeFloors'>>;
  handleEdit: (tenantId: string) => void;
};

export const OfficeList: React.FC<Props> = ({ offices, handleEdit }) => {
  const { t, tk } = useTranslation('admin');
  const classes = useStyles();

  return (
    <>
      <List
        subheader={<ListSubheader>{t(tk.adminPanel.offices)} </ListSubheader>}
      >
        {offices.map(office => (
          <ListItem key={office.id} divider>
            <ListItemText primary={office.locationNumber} />
            <Box className={classes.tenantButtons}>
              <Button
                color="secondary"
                size="small"
                onClick={() => handleEdit(office.id)}
              >
                <EditIcon fontSize="small" /> {t(tk.adminPanel.edit)}
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>
    </>
  );
};
