import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import { useState } from 'react';

import { useTranslation } from '../../../../translations';
import { ResolvedOffice } from '../../../context/types';
import { RegistrationDialog } from '../../common/RegistrationDialog';
import { LocationSelect } from '../../RegistrationView/LocationStep/LocationSelect';

type Props = {
  offices: ResolvedOffice[];
  initialValue?: string;
  open?: boolean;
  onClose: () => void;
  onSubmit: (officeId: string) => void;
};

export const LocationDialog: FC<Props> = ({
  offices,
  initialValue,
  open = false,
  onSubmit,
  onClose,
}) => {
  const { t, tk } = useTranslation('inventory');

  const [selected, setSelected] = useState<string>(initialValue || '');

  const handleSubmit = () => {
    if (selected) {
      onSubmit(selected);
    }
  };

  return (
    <RegistrationDialog
      title={t(tk.newSkuView.welcomeStep.changeLocation)}
      open={open}
      titleId="location-change-dialog"
      onClose={onClose}
      actions={
        <Button
          disabled={!selected}
          onClick={handleSubmit}
          fullWidth
          size="large"
          color="secondary"
        >
          {t(tk.newSkuView.locationDialog.confirm)}
        </Button>
      }
      withPadding
    >
      <LocationSelect
        offices={offices}
        onChange={setSelected}
        selected={selected}
      />
    </RegistrationDialog>
  );
};
