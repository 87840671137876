import { useMemo } from 'react';

import { useTranslation } from '../../../translations';
import { useOffices } from '../../context/useOffices';
import { useSkuInstanceTemplate } from '../../context/useSkuInstanceTemplate';
import { Config, SelectedFilters, useFilters } from '../../utils/useFilters';
import { getLocationFilter } from '../common/customFilters';

const config: Config = [
  {
    field: 'status',
    template: 'skuInstance',
    singleSelect: false,
    type: 'items',
  },
  {
    field: 'condition',
    template: 'skuInstance',
    singleSelect: false,
    type: 'items',
  },
  {
    field: 'processingStage',
    template: 'skuInstance',
    singleSelect: false,
    type: 'items',
  },
];

export const useSkuInstanceFilters = (selectedFilters: SelectedFilters) => {
  const skuInstanceTemplate = useSkuInstanceTemplate();
  const templates = useMemo(() => [skuInstanceTemplate], [skuInstanceTemplate]);
  const offices = useOffices();
  const { t, tk } = useTranslation('inventory');

  const fullConfig: Config = useMemo(
    () => [
      getLocationFilter(offices, t(tk.skuListView.filterLabels.location)),
      ...config,
    ],
    [offices, t, tk],
  );

  return useFilters(
    fullConfig,
    templates,
    skuInstanceTemplate,
    selectedFilters,
  );
};
