export const actionTypes = {
  INITIALIZE_FROM_EXISTING_AD_SKU:
    'inventory/newAdSku/INITIALIZE_FROM_EXISTING_AD_SKU',
  SET_COLORS: 'inventory/newAdSku/SET_COLORS',
  SET_DESCRIPTION: 'inventory/newAdSku/SET_DESCRIPTION',
  SET_DIMENSIONS: 'inventory/newAdSku/SET_DIMENSIONS',
  SET_IMAGES: 'inventory/newAdSku/SET_IMAGES',
  SET_MAIN_CATEGORY: 'inventory/newAdSku/SET_MAIN_CATEGORY',
  SET_MODEL_DATA: 'inventory/newAdSku/SET_MODEL_DATA',
  SET_SUBCATEGORY: 'inventory/newAdSku/SET_SUBCATEGORY',
  RESET: 'inventory/newAdSku/RESET',
} as const;

export type ActionTypes = typeof actionTypes;
