import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { StepComponentProps } from '../../../../common/components/Wizard/types';
import { useCategories } from '../../../context/useCategories';
import { SubcategoryStep as SubcategoryStepComponent } from '../../../components/NewSkuView/SubcategoryStep';
import * as selectors from '../../../redux/newAdSku/selectors';
import { StepId } from '../types';
import ensure from '../../../../common/utils';
import { ErrorView } from '../../../../common/components/ErrorView';
import { useNewAdSkuActions } from '../../../redux/newAdSku';
import { useSubcategoryCreate } from '../../common/useSubcategoryCreate';

export const SubcategoryStep: FC<StepComponentProps> = ({
  number,
  totalCount,
  onGoBack,
  onGoToStep,
  onStepCompleted,
}) => {
  const categories = useCategories();
  const mainCategoryId = useSelector(selectors.mainCategoryId);
  const { setSubcategory } = useNewAdSkuActions();

  const mainCategory = mainCategoryId
    ? categories.find(c => c.id === mainCategoryId)
    : null;

  const handleSubmit = (subcategoryId: string) => {
    const subcategory = ensure(
      mainCategory?.subcategories.find(s => s.id === subcategoryId),
    );
    setSubcategory(subcategory);
    onStepCompleted();
  };
  const { error, handleSubcategorySubmit } = useSubcategoryCreate(
    handleSubmit,
    mainCategoryId,
    mainCategory,
  );

  useEffect(() => {
    if (!mainCategory) {
      onGoToStep(StepId.MainCategory);
    }
  }, [onGoToStep, mainCategory]);

  if (!mainCategory) {
    return null;
  }

  if (error) {
    return <ErrorView error={error} />;
  }

  return (
    <SubcategoryStepComponent
      mainCategory={mainCategory}
      onChange={handleSubmit}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      onSubcategorySubmit={handleSubcategorySubmit}
    />
  );
};
