import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { materialUiKit } from '../../../common/components/Form/uiKit';

export const makeUiKit = (submitLabel: string) => ({
  ...materialUiKit,
  Submit: (disabled: boolean) => {
    return (
      <Box mt={8}>
        <Button
          disabled={disabled}
          fullWidth
          size="large"
          type="submit"
          color="secondary"
          variant="contained"
        >
          {submitLabel}
        </Button>
      </Box>
    );
  },
});
