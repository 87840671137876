import React from 'react';
import { Controller } from 'react-hook-form';
import type { Control, FieldError } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format, isValid } from 'date-fns';

import { FormFieldProps } from '../types';
import { dateFormat } from '../../../../inventory/utils/format';

type Options = readonly { label: string; value: string }[];

type Props = FormFieldProps & {
  control: Control;
  name: string;
  error?: FieldError;
  options?: Options;
};

export const ControlledDateField: React.FC<Props> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { control, error, name, endAdornment, ...dateFieldProps } = props;

  const formatDate = (date: MaterialUiPickersDate) => {
    if (!date || !isValid(date)) {
      return date;
    }
    return format(date, dateFormat);
  };

  return (
    <Controller
      render={({ onChange, value }) => (
        <KeyboardDatePicker
          autoOk
          clearable
          name={name}
          error={!!error}
          helperText={error?.message}
          margin="normal"
          value={value || null}
          onChange={date => onChange(formatDate(date))}
          {...dateFieldProps}
          inputVariant="outlined"
          format={'dd.MM.yyyy'}
          placeholder="dd.mm.yyyy"
          InputProps={{ notched: false }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      name={name}
      control={control}
    />
  );
};
