import React, { ComponentType, FC, ReactNode, useEffect, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TFunction } from 'i18next';
import { rgba } from 'polished';
import { Trans } from 'react-i18next';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { useForm } from '../../../../common/components/Form';
import {
  FormFields,
  FieldConfig,
} from '../../../../common/components/Form/types';
import { FormFeedback } from '../../../../common/components/FormFeedback';
import { GraphQlError } from '../../../../common/types';
import { TKeys } from '../../../../translations/keys';
import { materialUiKit } from '../../../../common/components/Form/uiKit';
import { WhiteCard } from '../../../../common/components/WhiteCard';
import { SummaryHeader } from '../../NewSkuView/SkuPreview/SummaryHeader';
import { ResolvedSku } from '../../../types';
import ensure from '../../../../common/utils';

import {
  FormStepData,
  FormValues,
  ProcurementFormValues,
} from './useNewSkuInstanceForm';
import { ProcurementFields } from './ProcurementFields';
import { CommonFields } from './CommonFields';

const useStyles = makeStyles(theme =>
  createStyles({
    greenCard: {
      backgroundColor: rgba(theme.palette.success.veryLight, 0.4),
      borderRadius: 16,
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3, 2),
    },
  }),
);

type Props = {
  error?: GraphQlError;
  fields: FormFields;
  initialValues?: Partial<FormStepData>;
  backLabel?: string;
  sku: Pick<
    ResolvedSku,
    'manufacturer' | 'model' | 'primaryColor' | 'secondaryColor' | 'images'
  >;
  number: number;
  totalCount: number;
  onSubmit: (data: FormStepData) => void;
  onPreregistration: (count: number, data: ProcurementFormValues) => void;
  onGoBack?: () => void;
};

const makeUiKit = (t: TFunction, tk: TKeys<'inventory'>) => ({
  ...materialUiKit,
  Submit: (disabled: boolean, values: FormValues) => {
    return (
      <Box mt={8}>
        <Button
          disabled={disabled}
          fullWidth
          size="large"
          type="submit"
          color="secondary"
          variant="contained"
        >
          {values.isPreregistration
            ? t(tk.newSkuInstanceView.formStep.confirmAndSave)
            : t(tk.newSkuInstanceView.formStep.confirm)}
        </Button>
      </Box>
    );
  },
});

function createArrayWithNumbers(length: number) {
  return Array.from({ length }, (_, k) => k);
}

export const FormStep: FC<Props> = ({
  backLabel,
  error,
  fields,
  sku,
  initialValues,
  number,
  totalCount,
  onGoBack,
  onSubmit,
  onPreregistration,
}) => {
  const { t, tk } = useTranslation('inventory');
  const uiKit = useMemo(() => makeUiKit(t, tk), [t, tk]);
  const classes = useStyles();
  const { Row } = uiKit;
  const { handleSubmit, renderField, renderSubmit, watch, setValue } = useForm<
    FormValues
  >({
    fields,
    defaultValues: {
      count: initialValues?.count || 1,
      isPreregistration: false,
      isSameStatus: true,
      isSameLocation: true,
      inputs: [],
    },
    uiKit,
  });
  const values: FormValues = watch() as FormValues;
  const count = Number.isNaN(Number(values?.count)) ? 0 : Number(values?.count);
  useEffect(() => {
    if (values.isPreregistration) {
      setValue('isSameStatus', false);
      setValue('isSameLocation', false);
    }
  }, [values.isPreregistration, setValue]);

  const handleSubmitCallback = (values: FormValues) => {
    const {
      count,
      isPreregistration,
      isSameStatus,
      isSameLocation,
      costCenter,
      procurementCost,
      procurementDate,
      procurementNumber,
      status,
      condition,
      officeFloorId,
      inputs: formInputs,
    } = values;
    if (isPreregistration) {
      return onPreregistration(count, {
        costCenter,
        procurementCost,
        procurementDate,
        procurementNumber,
      });
    }
    const inputs = createArrayWithNumbers(count).map(idx => {
      const item = formInputs[idx] || {};
      return {
        status: ensure(isSameStatus ? status : item.status),
        condition: ensure(isSameStatus ? condition : item.condition),
        officeFloorId: ensure(
          isSameLocation ? officeFloorId : item.officeFloorId,
        ),
        costCenter,
        procurementCost,
        procurementDate,
        procurementNumber,
      };
    });
    return onSubmit({
      count,
      inputs,
    });
  };

  return (
    <WizardStep
      backLabel={backLabel}
      onGoBack={onGoBack}
      showProgress={false}
      number={number}
      totalCount={totalCount}
      title={t(tk.newSkuInstanceView.formStep.title)}
      data-testid="FormStep"
    >
      <Box mb={3}>
        <Typography variant="subtitle1">
          {t(tk.newSkuInstanceView.formStep.subtitle)}
        </Typography>
      </Box>
      <FormFeedback error={error} />
      <form onSubmit={handleSubmit(handleSubmitCallback)}>
        <Row>
          {renderField({
            name: 'count',
            gridItemProps: { xs: 12 },
            label: t(tk.newSkuInstanceView.formStep.countFieldLabel),
          })}
        </Row>
        <div className={classes.greenCard}>
          <Row>
            {renderField({
              name: 'isPreregistration',
              gridItemProps: { xs: 12 },
              label: t(tk.newSkuInstanceView.formStep.isPreregistration),
            })}
          </Row>
          <Row>
            {renderField({
              name: 'isSameLocation',
              gridItemProps: { xs: 12 },
              label: t(tk.newSkuInstanceView.formStep.isSameLocation),
            })}
          </Row>
          <Row>
            {renderField({
              name: 'isSameStatus',
              gridItemProps: { xs: 12 },
              label: t(tk.newSkuInstanceView.formStep.isSameStatus),
            })}
          </Row>
        </div>
        <ProcurementFields Row={Row} renderField={renderField} />
        <CommonFields
          Row={Row}
          renderField={renderField}
          count={count}
          values={values}
        />
        <InstanceList
          Row={Row}
          sku={sku}
          renderField={renderField}
          count={count}
          values={values}
        />
        {renderSubmit()}
      </form>
    </WizardStep>
  );
};

type InstanceListProps = Pick<Props, 'sku'> & {
  Row: ComponentType<{}>;
  renderField: (config: FieldConfig) => ReactNode;
  count: number;
  values: FormValues;
};

const InstanceList: FC<InstanceListProps> = ({
  Row,
  count,
  values,
  renderField,
  sku,
}) => {
  const { t, tk } = useTranslation('inventory');
  if (
    values.isPreregistration ||
    (values.isSameLocation && values.isSameStatus)
  ) {
    return null;
  }
  return (
    <Box mt={1}>
      <Typography variant="subtitle1">
        <Trans
          i18nKey={tk.newSkuInstanceView.formStep.instancesHeader}
          values={{
            count,
            context: `${!values.isSameStatus ? 'status' : ''}${
              !values.isSameLocation ? 'location' : ''
            }`,
          }}
          ns="inventory"
        />
      </Typography>
      {createArrayWithNumbers(count).map(index => (
        <Box mt={2} key={index}>
          <InstanceCard sku={sku}>
            {!values.isSameStatus && (
              <>
                <Row>
                  {renderField({
                    name: 'status',
                    inputName: `inputs[${index}][status]`,
                    gridItemProps: { xs: 12 },
                    label: t(tk.fieldLabels.status),
                  })}
                </Row>
                <Row>
                  {renderField({
                    name: 'condition',
                    inputName: `inputs[${index}][condition]`,
                    gridItemProps: { xs: 12 },
                    label: t(tk.fieldLabels.condition),
                  })}
                </Row>
              </>
            )}
            {!values.isPreregistration && !values.isSameLocation && (
              <Row>
                {renderField({
                  name: 'officeFloorId',
                  inputName: `inputs[${index}][officeFloorId]`,
                  gridItemProps: { xs: 12 },
                  label: t(tk.fieldLabels.officeFloorId),
                })}
              </Row>
            )}
          </InstanceCard>
        </Box>
      ))}
    </Box>
  );
};

const InstanceCard: FC<Pick<Props, 'sku'>> = ({ children, sku }) => (
  <WhiteCard variant="outlined">
    <Box p={2} px={2} pb={0}>
      <Box mb={2}>
        <SummaryHeader sku={sku} image={sku.images[0]} />
      </Box>
      {children}
    </Box>
  </WhiteCard>
);
