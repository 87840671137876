import React, { FC, MouseEvent, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useTranslation } from '../../../../translations';
import { FieldProps } from '../../../../common/components/Form/types';
import { ControlledTextField } from '../../../../common/components/Form/uiKit/ControlledTextField';
import { TextField } from '../../../../common/components/TextField';

const useStyles = makeStyles(theme =>
  createStyles({
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cancelLink: {
      color: theme.palette.error.light,
    },
  }),
);

export const ManufacturerField: FC<FieldProps> = ({
  field,
  gridItemProps,
  control,
  error,
  name,
  label,
  noValueLabel,
}: FieldProps) => {
  const [createMode, setCreateMode] = useState(false);
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');

  if (field.type !== 'SelectFieldDefinition') {
    return null;
  }

  const fieldProps = {
    fullWidth: true,
    id: `form-input-${name}`,
    name,
    required: field.required,
    control,
    disabled: field.disabled,
    error,
  };

  const options = [
    {
      label: noValueLabel || '-',
      value: '',
    },
  ].concat(field.options || []);

  const handleCreate = (event: MouseEvent) => {
    setCreateMode(true);
    event.preventDefault();
  };

  const handleCancelCreate = (event: MouseEvent) => {
    setCreateMode(false);
    event.preventDefault();
  };

  if (!createMode) {
    return (
      <Grid item {...gridItemProps}>
        <Box mb={1}>
          <ControlledTextField
            {...fieldProps}
            select={true}
            label={label}
            options={options}
            SelectProps={{
              displayEmpty: true,
            }}
          />
          <Box pl={1}>
            <Link href="#" onClick={handleCreate} variant="caption">
              {t(tk.newSkuView.modelStep.createBrand.link)}
            </Link>
          </Box>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item {...gridItemProps}>
      <Box mb={1}>
        <TextField
          label={label}
          disabled
          fullWidth
          select={true}
          SelectProps={{
            displayEmpty: true,
          }}
          options={options}
          value=""
        />
        <Box mt={3}>
          <div className={classes.titleContainer}>
            <Typography variant="body1">
              <strong>{t(tk.newSkuView.modelStep.createBrand.title)}</strong>
            </Typography>
            <Link
              className={classes.cancelLink}
              href="#"
              onClick={handleCancelCreate}
              variant="caption"
            >
              {t(tk.actions.cancel)}
            </Link>
          </div>
          <ControlledTextField {...fieldProps} />
        </Box>
      </Box>
    </Grid>
  );
};
