import { Dictionary } from 'lodash';
import { useMemo } from 'react';
import { TFunction } from 'i18next';

import { Maybe } from '../../common/types';
import { SkuTemplatePayload } from '../graphql/types';
import { TKeys, useTranslation } from '../../translations';

import { chairs } from './chairs';
import { sofas } from './sofas';
import { storage } from './storage';
import { tables } from './tables';
import { whiteboards } from './whiteboards';
import {
  FunctionalitySubcategoryConfig,
  ResolvedFunctionalitySubcategoryConfig,
} from './types';
import { resolveFunctionalityGroups } from './utils';

export const getFunctionalityConfiguration = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) => {
  return {
    ...chairs(t, tk),
    ...storage(t, tk),
    ...sofas(t, tk),
    ...tables(t, tk),
    ...whiteboards(t, tk),
  };
};

export const useFunctionalityConfiguration = (): Dictionary<
  FunctionalitySubcategoryConfig
> => {
  const { t, tk } = useTranslation('functionality');
  return useMemo(() => getFunctionalityConfiguration(t, tk), [t, tk]);
};

export const getFunctionalityConfig = (
  configurations: Dictionary<FunctionalitySubcategoryConfig>,
  subcategoryKey: Maybe<string>,
): FunctionalitySubcategoryConfig | null => {
  if (!subcategoryKey) {
    return null;
  }
  const config = configurations[subcategoryKey];
  if (!config) {
    return null;
  }
  return config;
};

export const useFunctionalityConfig = (subcategoryKey: Maybe<string>) => {
  const configuration = useFunctionalityConfiguration();
  return getFunctionalityConfig(configuration, subcategoryKey);
};

export const getResolvedFunctionalityConfig = (
  configurations: Dictionary<FunctionalitySubcategoryConfig>,
  subcategoryKey: string,
  templateFields: SkuTemplatePayload['fields'],
): Maybe<ResolvedFunctionalitySubcategoryConfig> => {
  const config = configurations[subcategoryKey];
  if (!config) {
    return null;
  }
  return {
    ...config,
    defaultValues: config.defaultValues || {},
    groups: resolveFunctionalityGroups(config.groups, templateFields),
  };
};

export const useResolvedFunctionalityConfig = (
  subcategoryKey: string,
  templateFields: SkuTemplatePayload['fields'],
) => {
  const configuration = useFunctionalityConfiguration();
  return useMemo(
    () =>
      getResolvedFunctionalityConfig(
        configuration,
        subcategoryKey,
        templateFields,
      ),
    [configuration, subcategoryKey, templateFields],
  );
};

export { getGroupLabel } from './getGroupLabel';

export * from './types';
