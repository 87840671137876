import React, { FC, useMemo } from 'react';
import { useUnmount } from 'react-use';

import { Wizard } from '../../../common/components/Wizard';
import { useNavigation } from '../../../common/navigation';
import { makeUseStepState } from '../../utils/wizard';
import { useNewAdSkuActions } from '../../redux/newAdSku';

import { SearchStep } from './steps/SearchStep';
import { SummaryStep } from './steps/SummaryStep';
import { MainCategoryStep } from './steps/MainCategoryStep';
import { StepId } from './types';
import { SubcategoryStep } from './steps/SubcategoryStep';
import { ImageStep } from './steps/ImageStep';
import { ModelStep } from './steps/ModelStep';
import { ColorStep } from './steps/ColorStep';
import { DimensionsStep } from './steps/DimensionsStep';

type Props = {};

export const steps = [
  {
    id: StepId.MainCategory,
    component: MainCategoryStep,
  },
  {
    id: StepId.Subcategory,
    component: SubcategoryStep,
  },
  {
    id: StepId.Model,
    component: ModelStep,
  },
  {
    id: StepId.Search,
    component: SearchStep,
  },
  {
    id: StepId.Image,
    component: ImageStep,
  },
  {
    id: StepId.Color,
    component: ColorStep,
  },
  {
    id: StepId.Dimensions,
    component: DimensionsStep,
  },
  {
    id: StepId.Summary,
    component: SummaryStep,
  },
];

export const NewAdSkuView: FC<Props> = () => {
  const nav = useNavigation();
  const { reset } = useNewAdSkuActions();
  const useStepState = useMemo(() => makeUseStepState(nav.adSkuRegistration), [
    nav,
  ]);

  useUnmount(() => reset());

  return <Wizard steps={steps} extraProps={{}} useStepState={useStepState} />;
};
