import * as yup from 'yup';

import { FormFields } from '../../../common/components/Form/types';

export const tenantFormFields: FormFields = {
  displayName: {
    type: 'TextFieldDefinition',
    validation: yup.string().required(),
  },
  user: {
    type: 'TextFieldDefinition',
    validation: yup.string().email(),
  },
  city: {
    type: 'TextFieldDefinition',
    validation: yup.string().required(),
  },
  zipCode: {
    type: 'TextFieldDefinition',
    validation: yup.string().required(),
  },
  street: {
    type: 'TextFieldDefinition',
    validation: yup.string().required(),
  },
  streetNumber: {
    type: 'TextFieldDefinition',
    validation: yup.string().required(),
  },
};

export const updateTenantFormFields: FormFields = {
  displayName: {
    type: 'TextFieldDefinition',
    validation: yup.string().required(),
  },
};
