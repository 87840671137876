import React, { useState } from 'react';

import {
  AddTenantView as AddTenantViewComponent,
  TenantWithOfficeFormFields,
} from '../../components/AddTenantView/AddTenantView';
import { tenantFormFields } from '../../components/AddTenantView/tenantFormFields';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { ErrorView } from '../../../common/components/ErrorView';
import { useAddTenantFormLayout } from '../../forms/useTenantForm';
import { useTranslation } from '../../../translations';
import { useAddOffice } from '../AddOfficeView/useAddOffice';
import { OfficeInput } from '../../components/OfficeView/OfficeView';
import { useUserContext } from '../../../inventory/context/useUserContext';
import { useNavigation } from '../../../common/navigation';

import { slugifyTenantName } from './slugifyTenantName';
import { useCreateTenant } from './useCreateTenant';

export const AddTenantView: React.FC = () => {
  const { t, tk } = useTranslation('admin');
  const [office, setOffice] = useState<OfficeInput>();
  const nav = useNavigation();
  const { fetchUserData } = useUserContext();

  const onCreateOfficeCompleted = () => {
    fetchUserData();
    nav.adminPanel().go();
  };

  const {
    addOffice,
    isProcessing: isOfficeProcessing,
    mutationResult: addOfficeMutationResult,
  } = useAddOffice(onCreateOfficeCompleted);

  const onCreateTenantCompleted = (tenantId: string) => {
    if (office) {
      addOffice(office, tenantId);
    }
  };

  const { createTenant, isProcessing, mutationResult } = useCreateTenant(
    onCreateTenantCompleted,
  );

  const layout = useAddTenantFormLayout();

  const handleSubmit = (tenantData: TenantWithOfficeFormFields) => {
    const tenantName = slugifyTenantName(tenantData.displayName);
    setOffice({
      street: tenantData.street,
      city: tenantData.city,
      zipCode: tenantData.zipCode,
      streetNumber: tenantData.streetNumber,
    });

    createTenant({
      displayName: tenantData.displayName,
      name: tenantName,
      users: tenantData.user ? [{ email: tenantData.user }] : [],
    });
  };

  if (isProcessing || isOfficeProcessing) {
    return <LoadingIndicator />;
  }

  const error = mutationResult.error || addOfficeMutationResult.error;

  if (error) {
    return <ErrorView error={error} />;
  }

  return (
    <AddTenantViewComponent
      fields={tenantFormFields}
      onSubmit={handleSubmit}
      layout={layout}
      headingText={t(tk.adminPanel.addTenant)}
    />
  );
};
