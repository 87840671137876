import { StyleRules } from '@material-ui/core/styles';
import { StepLabelClasskey } from '@material-ui/core/StepLabel';
import { StepIconClasskey } from '@material-ui/core/StepIcon';
import { StepConnectorClasskey } from '@material-ui/core/StepConnector';

import { DsBlack, DsYellow } from '../colors';

export const MuiStepLabel: Partial<StyleRules<StepLabelClasskey>> = {
  label: {
    '&$active': {
      fontWeight: 400,
    },
    '&$completed': {
      fontWeight: 400,
    },
    '&$alternativeLabel': {
      marginTop: 12,
    },
  },
};

export const MuiStepIcon: Partial<StyleRules<StepIconClasskey>> = {
  root: {
    color: DsBlack.ultraLight,
    '&$active': {
      color: DsYellow.main,
    },
    '&$completed': {
      color: DsYellow.main,
    },
  },
  text: {
    fill: DsBlack.main,
  },
};

export const MuiStepConnector: Partial<StyleRules<StepConnectorClasskey>> = {
  line: {
    borderColor: DsBlack.ultraLight,
  },
};
