/**
 * LinkAnchor based on https://github.com/ReactTraining/react-router/blob/v5.2.0/packages/react-router-dom/modules/Link.js
 */

import { makeStyles, createStyles } from '@material-ui/core/styles';
import React, { forwardRef } from 'react';
import cn from 'classnames';

import { RouteActions } from '../navigation';

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<any>;
  route: RouteActions;
};

function isModifiedEvent(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export type LinkAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  navigate: () => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: 'inherit',
      'a:visited': {
        color: 'inherit',
      },
      'a:hover': {
        color: 'inherit',
      },
      'a:active': {
        color: 'inherit',
      },
    },
  }),
);

const LinkAnchor = forwardRef<HTMLAnchorElement, LinkAnchorProps>(
  ({ navigate, onClick, ...rest }, ref) => {
    const classes = useStyles();

    const { target, className } = rest;

    const props: Partial<React.AnchorHTMLAttributes<HTMLAnchorElement>> = {
      ...rest,
      onClick: event => {
        try {
          if (onClick) {
            onClick(event);
          }
        } catch (ex) {
          event.preventDefault();
          throw ex;
        }

        if (
          !event.defaultPrevented && // onClick prevented default
          event.button === 0 && // ignore everything but left clicks
          (!target || target === '_self') && // let browser handle "target=_blank" etc.
          !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
          event.preventDefault();
          navigate();
        }
      },
      className,
    };

    return <a {...props} className={cn(className, classes.root)} ref={ref} />;
  },
);

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ component = LinkAnchor, route, ...rest }, ref) => {
    const props = {
      ...rest,
      href: route.url,
      navigate: route.go,
      ref,
    };
    return React.createElement(component, props);
  },
);
