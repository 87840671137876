import { TypographyOptions } from '@material-ui/core/styles/createTypography';

import { breakpoints } from './breakpoints';

export const typography: TypographyOptions = {
  fontFamily: ['GT Walsheim Pro', 'Arial', 'sans-serif'].join(','),
  fontSize: 16,
  body1: {
    fontSize: 16,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: 14,
    lineHeight: 1.43,
  },
  button: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.66,
    textTransform: 'none',
  },
  caption: {
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.33,
    letterSpacing: 0.4,
  },
  overline: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.33,
    opacity: 0.87,
  },
  h1: {
    fontSize: 56,
    fontWeight: 800,
    lineHeight: 1.14,
    letterSpacing: -1.5,
    [breakpoints.down('sm')]: {
      fontSize: 36,
      lineHeight: 1.12,
      letterSpacing: 0,
    },
  },
  h2: {
    fontSize: 48,
    fontWeight: 700,
    lineHeight: 1.21,
    [breakpoints.down('sm')]: {
      fontSize: 32,
      lineHeight: 1.12,
    },
  },
  h3: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: 1.28,
    [breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: 1.4,
    },
  },
  h4: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.31,
    letterSpacing: 0.25,
  },
  h5: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.33,
  },
  h6: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.66,
    letterSpacing: 0.15,
  },
};
