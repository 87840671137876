import { onError } from '@apollo/client/link/error';

import { logError } from '../../common/utils/logError';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error =>
      logError(error, `[GraphQL error] ${error.message}`),
    );
  }
  if (networkError) {
    logError(networkError, `[Network error] ${networkError.message}`);
  }
});
