import { useMemo } from 'react';

import { useTranslation } from '../../../../translations';
import {
  ComponentRow,
  FieldRow,
  FormLayout,
} from '../../../../common/components/Form/types';

import { ManufacturerField } from './ManufacturerField';

export const useModelStepFormLayout = (
  withSerialNumber?: boolean,
): FormLayout => {
  const { t, tk } = useTranslation('inventory');

  return useMemo(() => {
    const basicFields: (FieldRow | ComponentRow)[] = [
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 12 },
            name: 'manufacturer',
            label: t(tk.fieldLabels.manufacturer),
            noValueLabel: t(tk.newSkuView.modelStep.brandPlaceholder),
            Component: ManufacturerField,
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: { xs: 12 },
            name: 'model',
            label: t(tk.fieldLabels.model),
          },
        ],
      },
    ];
    const serialNumberField: FieldRow | ComponentRow = {
      type: 'fields',
      fields: [
        {
          gridItemProps: { xs: 12 },
          name: 'serialNumber',
          label: t(tk.fieldLabels.serialNumber_registration),
        },
      ],
    };
    return withSerialNumber
      ? [...basicFields, serialNumberField]
      : [...basicFields];
  }, [
    t,
    tk.fieldLabels.manufacturer,
    tk.fieldLabels.model,
    tk.fieldLabels.serialNumber_registration,
    tk.newSkuView.modelStep.brandPlaceholder,
    withSerialNumber,
  ]);
};
