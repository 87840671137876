import { useConfig } from './useConfig';

const directory = 'original';

export const useImageHelper = () => {
  const { mediaUrlBase, mediaTempUrlBase } = useConfig();

  return {
    getImageUrl: (filename: string, type?: string) => {
      return mediaUrlBase + [type || directory, filename].join('/');
    },
    getTempImageUrl: (filename: string, type?: string) => {
      return mediaTempUrlBase + [type || directory, filename].join('/');
    },
  };
};

export type ImageHelper = ReturnType<typeof useImageHelper>;
