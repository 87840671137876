import { useMemo } from 'react';

import { useTranslation } from '../../../translations';
import { useSkuTemplate } from '../../context/useSkuTemplate';
import { useSkuInstanceTemplate } from '../../context/useSkuInstanceTemplate';
import { useCategories } from '../../context/useCategories';
import { Config, SelectedFilters, useFilters } from '../../utils/useFilters';
import { getLocationFilter } from '../common/customFilters';
import { useOffices } from '../../context/useOffices';
import { useResolvedFilterConfig } from '../../models/filters';

export const useSkuFilters = (selectedFilters: SelectedFilters) => {
  const skuTemplate = useSkuTemplate();
  const skuInstanceTemplate = useSkuInstanceTemplate();
  const templates = useMemo(() => [skuTemplate, skuInstanceTemplate], [
    skuTemplate,
    skuInstanceTemplate,
  ]);
  const { t, tk } = useTranslation('inventory');
  const categories = useCategories();
  const offices = useOffices();
  const filterConfig = useResolvedFilterConfig(skuTemplate.fields);

  const functionalityFilters = useMemo(() => {
    const categoryKey =
      selectedFilters.subcategoryId && selectedFilters.subcategoryId.length
        ? categories.find(c =>
            c.subcategories.find(
              s => s.id === selectedFilters.subcategoryId[0],
            ),
          )?.key
        : undefined;
    return categoryKey ? filterConfig[categoryKey] : null;
  }, [categories, filterConfig, selectedFilters.subcategoryId]);

  const fullConfig: Config = useMemo(
    () => [
      {
        type: 'custom',
        getFilter: () => {
          return {
            nodes: categories.map(category => ({
              name: category.name,
              items: category.subcategories.map(subcategory => ({
                label: subcategory.name,
                value: subcategory.id,
              })),
            })),
            type: 'nodes',
            name: 'subcategoryId',
            title: t(tk.skuListView.filterLabels.category) || 'subcategoryId',
            singleSelect: true,
          };
        },
      },
      {
        field: 'manufacturer',
        template: 'sku',
        singleSelect: false,
        type: 'items',
      },
      {
        field: 'status',
        template: 'skuInstance',
        singleSelect: false,
        type: 'items',
      },
      {
        field: 'condition',
        template: 'skuInstance',
        singleSelect: false,
        type: 'items',
      },
      getLocationFilter(offices, t(tk.skuListView.filterLabels.location)),
      {
        field: 'primaryColor',
        template: 'sku',
        singleSelect: false,
        type: 'colors',
        title: t(tk.skuListView.filterLabels.primaryColor),
      },
      {
        field: 'processingStage',
        template: 'skuInstance',
        singleSelect: false,
        type: 'items',
      },
    ],
    [categories, offices, t, tk],
  );

  return {
    ...useFilters(fullConfig, templates, skuTemplate, selectedFilters),
    functionalityFilters,
  };
};
