import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { SwatchSelect } from '../../../../common/components/SwatchSelect';
import { SkuInput } from '../../../graphql';
import { Maybe } from '../../../../common/types';
import { colorsStepConfiguration } from '../../../models/colors';
import { ColorMapping } from '../../../context/useColorMapping';

import {
  resolveColorHidden,
  resolveColorLabel,
  validateSecondaryColor,
} from './util';

export type ColorStepProps = {
  backLabel?: string;
  colorMapping: ColorMapping;
  initialValues?: Partial<Pick<SkuInput, 'primaryColor' | 'secondaryColor'>>;
  number: number;
  totalCount: number;
  subcategoryKey: string;
  onSubmit: (primaryColor: string, secondaryColor: Maybe<string>) => void;
  onGoBack?: () => void;
};

export const ColorStep: FC<ColorStepProps> = ({
  backLabel,
  colorMapping,
  initialValues,
  number,
  totalCount,
  subcategoryKey,
  onSubmit,
  onGoBack,
}) => {
  const { t, tk } = useTranslation('inventory');
  const [primaryColor, setPrimaryColor] = useState<Maybe<string>>(
    initialValues?.primaryColor,
  );
  const [secondaryColor, setSecondaryColor] = useState<Maybe<string>>(
    initialValues?.secondaryColor,
  );

  if (!subcategoryKey) {
    return null;
  }

  const handleSubmit = () => {
    if (
      primaryColor &&
      validateSecondaryColor(
        colorsStepConfiguration,
        subcategoryKey,
        secondaryColor,
      )
    ) {
      onSubmit(primaryColor, secondaryColor);
    }
  };

  const submitDisabled = !(
    primaryColor &&
    validateSecondaryColor(
      colorsStepConfiguration,
      subcategoryKey,
      secondaryColor,
    )
  );

  return (
    <WizardStep
      backLabel={backLabel}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      title={t(tk.newSkuView.colorStep.title)}
      data-testid="ColorStep"
    >
      <Typography
        variant="subtitle1"
        gutterBottom
        data-testid="PrimaryColorLabel"
      >
        {t(resolveColorLabel(tk.fieldLabels.primaryColor, subcategoryKey))}
      </Typography>
      <Box mb={4}>
        <SwatchSelect
          selected={primaryColor}
          colorMapping={colorMapping}
          onChange={setPrimaryColor}
          showMoreLabel={t(tk.newSkuView.colorStep.showMore)}
          data-testid="PrimaryColorSwatchSelect"
        />
      </Box>
      {resolveColorHidden(subcategoryKey) ? null : (
        <>
          <Typography
            variant="subtitle1"
            gutterBottom
            data-testid="SecondaryColorLabel"
          >
            {t(
              resolveColorLabel(tk.fieldLabels.secondaryColor, subcategoryKey),
            )}
          </Typography>
          <SwatchSelect
            selected={secondaryColor}
            colorMapping={colorMapping}
            onChange={setSecondaryColor}
            showMoreLabel={t(tk.newSkuView.colorStep.showMore)}
            data-testid="SecondaryColorSwatchSelect"
          />
        </>
      )}
      <Box mt={8}>
        <Button
          disabled={submitDisabled}
          onClick={handleSubmit}
          fullWidth
          size="large"
          color="secondary"
          data-testid="ColorStepSubmit"
        >
          {t(tk.newSkuView.colorStep.confirm)}
        </Button>
      </Box>
    </WizardStep>
  );
};
