import Big from 'big.js';

const BIG_ROUND_DOWN = 0;

export enum PriceFormat {
  Default,
  Checkout,
}

/*
 * F O R M A T S
 *
 * Default:
 * - returns the price as a decimal string (2)
 * - if .00 then it's replaced by ,- (e.g. 150.00 => 150,-)
 *
 * Checkout:
 * - returns the price as a decimal string (2)
 *
 */

export const formatPrice = (
  price: string,
  format: PriceFormat = PriceFormat.Default,
) => {
  const value = Big(price);

  if (value.mod(1).eq(Big(0)) && format === PriceFormat.Default) {
    return `${value.round(0, BIG_ROUND_DOWN).toFixed()},-`;
  }

  return value.toFixed(2).replace('.', ',');
};
