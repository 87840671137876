import { useState } from 'react';

import { UpdateAdSkuMutation, useUpdateAdSkuMutation } from '../../graphql';
import { extractMutation } from '../../../common/utils/extractQuery';
import { UpdateAdSkuMutationPayload } from '../../graphql/types';
import { ResolvedAdSku } from '../../types';
import { AdSkuFormInput } from '../../forms/useAdSkuForm';

export const useUpdateAdSku = () => {
  const [updateCompleted, setUpdateCompleted] = useState<boolean>(false);

  const [_updateAdSku, rawMutationResult] = useUpdateAdSkuMutation({
    onCompleted: () => {
      setUpdateCompleted(true);
    },
  });
  const mutationResult = extractMutation<
    UpdateAdSkuMutation,
    UpdateAdSkuMutationPayload
  >(rawMutationResult, 'UpdateAdSkuPayload', data => data.updateAdSku);

  const updateAdSku = (adSku: ResolvedAdSku, adSkuInput: AdSkuFormInput) => {
    setUpdateCompleted(false);
    _updateAdSku({
      variables: {
        input: {
          id: adSku.id,
          adSku: {
            ...adSkuInput,
            images: adSku.images.map(({ filename }) => ({ filename })), // form makes no changes to images
          },
        },
      },
    });
  };
  return {
    updateAdSku,
    mutationResult,
    updateCompleted,
  };
};
