import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import ListSubheader from '@material-ui/core/ListSubheader';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { FilterInput } from './FilterInput';

const useStyles = makeStyles(theme =>
  createStyles({
    filterCard: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1, 2, 1),
      borderColor: 'white',
    },
  }),
);

const formatRangeSelected = (filterString: string) => {
  if (filterString.search(/^from:/) > -1) {
    return filterString.replace('from:', '');
  } else if (filterString.search(/^to:/) > -1) {
    return filterString.replace('to:', '');
  }

  return '';
};

const currentSelectedFilter = (
  name: string,
  selected?: Record<string, string[]>,
) =>
  selected && selected[name]
    ? selected[name].map((fs: string) => formatRangeSelected(fs))
    : ['', ''];

export type Props = {
  adornment: string;
  title: string;
  name: string;
  labelFrom: string;
  labelTo: string;
  type: string;
  selected?: Record<string, string[]>;
  handleChange?: (
    field: string,
    from: number | string | undefined,
    to: number | string | undefined,
  ) => void | undefined;
};

export const RangeFilter: React.FC<Props> = props => {
  const {
    name,
    labelFrom,
    labelTo,
    type,
    selected,
    title,
    handleChange,
  } = props;
  const classes = useStyles();
  const [selectedFrom, selectedTo] = currentSelectedFilter(name, selected);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [timer, setTimer] = useState<null | ReturnType<typeof setTimeout>>(
    null,
  );
  useEffect(() => {
    if (selectedFrom === '') {
      setFrom(null);
    }
    if (selectedTo === '') {
      setTo(null);
    }
  }, [setFrom, setTo, selectedFrom, selectedTo]);

  const onChange = (name: string, val: any, type: string) => {
    if (type === 'number' && val.search(/[a-zA-Z]/) > -1) {
      return;
    } else if (type === 'date' && val === 'Invalid Date') {
      return;
    }
    let newFrom = selectedFrom;
    let newTo = selectedTo;
    if (name === 'from') {
      setFrom(val);
      newFrom = val;
    }
    if (name === 'to') {
      setTo(val);
      newTo = val;
    }

    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        return handleChange ? handleChange(props.name, newFrom, newTo) : null;
      }, 500),
    );
  };

  const onClear = () => {
    setFrom(null);
    setTo(null);
    return handleChange ? handleChange(props.name, '', '') : null;
  };

  const validateChange = (type: string, name: string, v: any) => {
    if (type === 'date' && v instanceof Date && !isNaN(v.getTime())) {
      const timestamp = v.getTime();
      if (timestamp < 946714748000 || timestamp > 2147483647000) {
        return;
      }
      return onChange(name, v, type);
    } else if (type === 'number') {
      return onChange(name, v, type);
    }
  };

  return (
    <Card key={name} className={classes.filterCard}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <ListSubheader disableGutters disableSticky>
            {title}
          </ListSubheader>
        </Grid>
        <Grid item xs={4}>
          <Link onClick={onClear} style={{ cursor: 'pointer' }}>
            Clear Selections
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <FilterInput
          value={from ? from : selectedFrom}
          name={'from'}
          label={labelFrom}
          type={type}
          selected={selected}
          handleChange={v => validateChange(type, 'from', v)}
        />
        <FilterInput
          value={to ? to : selectedTo}
          name={'to'}
          label={labelTo}
          type={type}
          selected={selected}
          handleChange={v => validateChange(type, 'to', v)}
        />
      </Grid>
    </Card>
  );
};
