import { useMemo } from 'react';

import { useNavigation } from '../../common/navigation';
import { useSkuInstanceQuery, SkuInstanceQuery, Maybe } from '../graphql';
import { extractQuery } from '../../common/utils/extractQuery';
import { SkuInstancePayload } from '../graphql/types';
import { useCategories } from '../context/useCategories';
import { useTranslation } from '../../translations';
import { getBreadcrumbs } from '../containers/SkuView/breadcrumbs';
import { ResolvedSku } from '../types';

import { useResolvedSku } from './sku';

export const useResolvedSkuInstance = (id: string) => {
  const skuInstanceQuery = extractQuery<SkuInstanceQuery, SkuInstancePayload>(
    useSkuInstanceQuery({
      variables: { id },
    }),
    'SkuInstance',
    data => data.skuInstance,
  );

  const checkSku =
    skuInstanceQuery.data?.sku.__typename === 'Sku'
      ? skuInstanceQuery.data.sku
      : null;
  const sku = useResolvedSku(checkSku);

  const skuInstance = skuInstanceQuery.data;

  const error =
    skuInstanceQuery.error ||
    (skuInstance?.sku.__typename !== 'Sku' ? skuInstance?.sku : null);

  return {
    skuInstance,
    sku,
    loading: skuInstanceQuery.loading,
    error,
  };
};

export const useSkuInstanceBreadcrumbs = (
  sku: Maybe<ResolvedSku>,
  id: string,
) => {
  const nav = useNavigation();
  const categories = useCategories();
  const { t, tk } = useTranslation('inventory');

  return useMemo(() => {
    if (!sku) {
      return [];
    }

    let breadcrumbs = getBreadcrumbs(
      categories,
      t(tk.skuListView.title),
      sku,
      nav.skus,
    );
    if (breadcrumbs.length > 0) {
      breadcrumbs = breadcrumbs.slice(0, -1);
    }
    if (sku) {
      breadcrumbs.push({
        label: `${sku.manufacturer} ${sku.model}`,
        route: nav.sku({ id: sku.id }),
      });
    }
    breadcrumbs.push({
      label: t(tk.skuInstanceView.topMenu.currentItem, {
        id: id.substring(0, 8),
      }),
    });
    return breadcrumbs;
  }, [
    nav,
    categories,
    t,
    tk.skuListView.title,
    tk.skuInstanceView.topMenu.currentItem,
    sku,
    id,
  ]);
};
