import React from 'react';

import {
  CreateOfficeFields,
  OfficeView,
} from '../../components/OfficeView/OfficeView';
import { officeFormFields } from '../../components/OfficeView/officeFormFields';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { ErrorView } from '../../../common/components/ErrorView';
import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../translations';

import { useAddOffice } from './useAddOffice';

type Props = {
  tenantId: string;
};

export const AddOfficeView: React.FC<Props> = ({ tenantId }) => {
  const { t, tk } = useTranslation('admin');
  const nav = useNavigation();

  const { addOffice, isProcessing, mutationResult } = useAddOffice();

  const handleSubmit = ({
    street,
    streetNumber,
    zipCode,
    city,
    officeFloors,
  }: CreateOfficeFields) => {
    addOffice(
      {
        street,
        city,
        zipCode,
        streetNumber,
        officeFloors,
      },
      tenantId,
    );
  };

  if (isProcessing) {
    return <LoadingIndicator />;
  }

  if (mutationResult.error) {
    return <ErrorView error={mutationResult.error} />;
  }

  return (
    <OfficeView
      isOfficeFloorField
      tenantId={tenantId}
      fields={officeFormFields}
      onSubmit={data => handleSubmit(data)}
      onCancel={() => nav.adminPanel().go()}
      heading={t(tk.adminPanel.addOfficeForTenant, { tenant: tenantId })}
    />
  );
};
