import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityFilterGroup,
  FunctionalityFilterRow,
  FunctionalityFilters,
} from '../types';

import { getFields, SofaFields } from './fields';

const groups = (
  {
    sofaChaiseLounge,
    sofaFeet,
    sofaSeats,
    sofaShape,
    sofaType,
    sofaWidth,
  }: SofaFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityFilterGroup[] => [
  {
    label: t(tk.sofas.filters.seats),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'select',
        field: sofaSeats,
      },
    ],
  },
  {
    label: t(tk.sofas.filters.size),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: sofaWidth,
      },
    ],
  },
  {
    label: t(tk.sofas.filters.shape),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: sofaShape,
      },
    ],
  },
  {
    label: t(tk.sofas.filters.chaiseLounge),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: sofaChaiseLounge,
      },
    ],
  },
  {
    label: t(tk.sofas.filters.feet),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: sofaFeet,
      },
    ],
  },
  {
    label: t(tk.sofas.filters.style),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: sofaType,
      },
    ],
  },
];

export const sofas = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityFilters => ({
  groups: groups(getFields(t, tk), t, tk),
  title: t(tk.common.filters.attributes),
});
