import React, { FC, useState } from 'react';

import { useNavigation } from '../../../common/navigation';
import {
  SkuInstanceInput,
  useUpdateSkuInstanceMutation,
  UpdateSkuInstanceMutation,
} from '../../graphql';
import { ScanSkuInstanceView as ScanSkuInstanceViewComponent } from '../../components/ScanSkuInstanceView';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { ErrorView } from '../../../common/components/ErrorView';
import { extractMutation } from '../../../common/utils/extractQuery';
import { UpdateSkuInstanceMutationPayload } from '../../graphql/types';
import { useSkuInstanceFormFields } from '../../forms/useSkuInstanceForm';
import { useDeleteSkuInstance } from '../SkuInstanceView/useDeleteSkuInstance';
import {
  useResolvedSkuInstance,
  useSkuInstanceBreadcrumbs,
} from '../../utils/skuInstance';
import { getInitialValues } from '../../components/SkuInstanceView/getInitialValues';

type Props = {
  id: string;
};

export const ScanSkuInstanceView: FC<Props> = ({ id }) => {
  const nav = useNavigation();

  const [updateCompleted, setUpdateCompleted] = useState<boolean>(false);
  const fields = useSkuInstanceFormFields();

  const { skuInstance, sku, loading, error } = useResolvedSkuInstance(id);

  const [updateSkuInstance, rawMutationResult] = useUpdateSkuInstanceMutation({
    onCompleted: ({ updateSkuInstance }) => {
      if (updateSkuInstance?.__typename === 'UpdateSkuInstancePayload') {
        setUpdateCompleted(true);
        setTimeout(() => nav.skuInstance({ id }).go(), 3000);
      }
    },
  });

  const mutationResult = extractMutation<
    UpdateSkuInstanceMutation,
    UpdateSkuInstanceMutationPayload
  >(
    rawMutationResult,
    'UpdateSkuInstancePayload',
    data => data.updateSkuInstance,
  );

  const deleteMutation = useDeleteSkuInstance(id, sku?.id);
  const breadcrumbs = useSkuInstanceBreadcrumbs(sku, id);

  if (!skuInstance || !sku) {
    if (loading) {
      return <LoadingIndicator />;
    }

    return <ErrorView error={error} />;
  }

  const handleUpdate = (input: Omit<SkuInstanceInput, 'skuId'>) => {
    setUpdateCompleted(false);
    updateSkuInstance({
      variables: {
        input: {
          id,
          skuInstance: {
            ...getInitialValues(skuInstance),
            ...input,
            skuId: sku.id,
          },
        },
      },
    });
  };

  if (!updateCompleted && skuInstance.qrCode) {
    return <ErrorView error={{ message: 'Instance already registered' }} />;
  }

  return (
    <ScanSkuInstanceViewComponent
      error={mutationResult.error}
      sku={sku}
      skuInstance={skuInstance}
      breadcrumbs={breadcrumbs}
      fields={fields}
      deleteMutation={deleteMutation}
      onUpdate={handleUpdate}
      onViewSku={() => {
        nav.sku({ id: sku.id }).go();
      }}
      updateCompleted={updateCompleted}
    />
  );
};
