import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import { useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from '../../../translations';
import {
  FunctionalityValueMap,
  ResolvedFunctionalitySubcategoryConfig,
} from '../../models';
import { SkuFunctionalityInput } from '../common/SkuFunctionalityInput/SkuFunctionalityInput';
import { useFunctionalityState } from '../common/SkuFunctionalityInput/useFunctionalityState';
import { RegistrationDialog } from '../common/RegistrationDialog';
import { ResolvedSku } from '../../types';
import { getFunctionalityValues } from '../../models/utils';
import { UpdateSkuFunctionalitiesMutationPayload } from '../../graphql/types';
import { MutationReturnType } from '../../../common/utils/extractQuery';
import { FormFeedback } from '../../../common/components/FormFeedback';

export type EditFunctionalityDialogProps = {
  sku: ResolvedSku;
  config: ResolvedFunctionalitySubcategoryConfig;
  open?: boolean;
  onUpdate: (data: FunctionalityValueMap) => void;
  onClose: () => void;
  mutationResult: MutationReturnType<UpdateSkuFunctionalitiesMutationPayload>;
  updateCompleted: boolean;
  onDismissCompleted: () => void;
};

export const EditFunctionalityDialog: FC<EditFunctionalityDialogProps> = props => {
  const {
    config,
    sku,
    open = false,
    onUpdate,
    onClose,
    mutationResult,
    updateCompleted,
    onDismissCompleted,
  } = props;
  const initialValues = useMemo(() => getFunctionalityValues(sku), [sku]);
  const { values, setValues, isReady } = useFunctionalityState(
    config,
    initialValues,
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, tk } = useTranslation('inventory');
  const handleSubmit = () => {
    onUpdate(values);
  };
  const handleDismiss = () => {
    onClose();
    onDismissCompleted();
  };

  if (updateCompleted) {
    return (
      <RegistrationDialog
        DialogProps={{ fullScreen }}
        title={t(tk.skuView.editFunctionality.successTitle)}
        open={open}
        titleId="edit-functionality-dialog-success"
        onClose={handleDismiss}
        loading={mutationResult.loading}
        actions={
          <Button
            onClick={handleDismiss}
            fullWidth
            size="large"
            color="secondary"
          >
            {t(tk.skuView.editFunctionality.returnAction)}
          </Button>
        }
        withPadding
      >
        <Typography variant="body2" align="center">
          {t(tk.skuView.editFunctionality.successDescription)}
        </Typography>
      </RegistrationDialog>
    );
  }
  return (
    <RegistrationDialog
      DialogProps={{ fullScreen }}
      title={t(tk.newSkuView.functionalityStep.title)}
      open={open}
      titleId="edit-functionality-dialog"
      onClose={onClose}
      loading={mutationResult.loading}
      actions={
        <Button
          disabled={!isReady}
          onClick={handleSubmit}
          fullWidth
          size="large"
          color="secondary"
        >
          {t(tk.skuView.editFunctionality.saveAction)}
        </Button>
      }
      withPadding
    >
      <FormFeedback error={mutationResult.error} />
      <SkuFunctionalityInput
        config={config}
        values={values}
        initialValues={initialValues}
        onChange={setValues}
      />
    </RegistrationDialog>
  );
};
