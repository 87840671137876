import { useCallback, useState } from 'react';

import {
  UpdateSkuFunctionalitiesMutation,
  useUpdateSkuFunctionalitiesMutation,
} from '../../graphql';
import { extractMutation } from '../../../common/utils/extractQuery';
import { UpdateSkuFunctionalitiesMutationPayload } from '../../graphql/types';
import { ResolvedSku } from '../../types';
import { getFunctionalityInput } from '../../models/utils';
import {
  FunctionalityValueMap,
  ResolvedFunctionalitySubcategoryConfig,
} from '../../models';

export const useUpdateSkuFunctionalities = () => {
  const [updateCompleted, setUpdateCompleted] = useState<boolean>(false);

  const [
    _updateSkuFunctionalities,
    rawMutationResult,
  ] = useUpdateSkuFunctionalitiesMutation({
    onCompleted: () => {
      setUpdateCompleted(true);
    },
  });
  const mutationResult = extractMutation<
    UpdateSkuFunctionalitiesMutation,
    UpdateSkuFunctionalitiesMutationPayload
  >(
    rawMutationResult,
    'UpdateSkuPayload',
    data => data.updateSkuFunctionalities,
  );
  const updateSkuFunctionalities = useCallback(
    (
      config: ResolvedFunctionalitySubcategoryConfig,
      sku: ResolvedSku,
      values: FunctionalityValueMap,
    ) => {
      const functionalities = getFunctionalityInput(config.fields, values);
      setUpdateCompleted(false);
      _updateSkuFunctionalities({
        variables: {
          input: {
            id: sku.id,
            functionalities,
          },
        },
      });
    },
    [_updateSkuFunctionalities],
  );
  const onDismissCompleted = useCallback(() => setUpdateCompleted(false), [
    setUpdateCompleted,
  ]);

  return {
    updateSkuFunctionalities,
    mutationResult,
    updateCompleted,
    onDismissCompleted,
  };
};
