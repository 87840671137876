import { useState } from 'react';

import {
  UpdateOfficeMutation,
  useUpdateOfficeMutation,
} from '../../../inventory/graphql';
import { extractMutation } from '../../../common/utils/extractQuery';
import { UpdateOfficeMutationPayload } from '../../../inventory/graphql/types';
import { OfficeInput } from '../../components/OfficeView/OfficeView';

export const useEditOffice = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [updateOfficeMutation, rawMutationResult] = useUpdateOfficeMutation({
    onCompleted: () => {
      setIsProcessing(false);
    },
  });

  const mutationResult = extractMutation<
    UpdateOfficeMutation,
    UpdateOfficeMutationPayload
  >(rawMutationResult, 'UpdateOfficePayload', data => data.updateOffice);

  const updateOffice = (
    { street, streetNumber, zipCode, city }: OfficeInput,
    officeId: string,
    tenantId: string,
  ) => {
    const locationNumber = `${street} ${streetNumber}`;
    setIsProcessing(true);
    updateOfficeMutation({
      context: {
        headers: {
          'x-tenant-id': tenantId,
        },
      },
      variables: {
        input: {
          id: officeId,
          office: {
            locationNumber,
            street,
            city,
            zipCode,
            streetNumber,
          },
        },
      },
    });
  };

  return { isProcessing, updateOffice, mutationResult };
};
