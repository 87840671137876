import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useTranslation } from '../../../translations';

const useStyles = makeStyles(theme =>
  createStyles({
    label: {
      color: theme.palette.primary.veryLight,
    },
  }),
);

type Props = {
  onGoBack: () => void;
  label?: string;
};

export const BackLink: FC<Props> = ({ onGoBack, label }) => {
  const { t, tk } = useTranslation('common');
  const classes = useStyles();

  return (
    <Box>
      <Link href="#" onClick={onGoBack}>
        {t(tk.actions.back)}
      </Link>
      {label && (
        <>
          {' / '}
          <span className={classes.label}>{label}</span>
        </>
      )}
    </Box>
  );
};
