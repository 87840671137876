import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { TwoLineButton } from '../../../../common/components/TwoLineButton';
import { WizardStep } from '../../../../common/components/WizardStep';
import { useTranslation } from '../../../../translations';

const useStyles = makeStyles(theme =>
  createStyles({
    buttonList: {
      '& button': {
        marginBottom: theme.spacing(2),
      },
    },
  }),
);

type Props = {
  onAdSkuSelect: () => void;
  onAdSkuInstanceSelect: () => void;
  onGoBack?: () => void;
};

export const AdPickStep: React.FC<Props> = ({
  onAdSkuSelect,
  onGoBack,
  onAdSkuInstanceSelect,
}) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  return (
    <WizardStep
      backLabel={t(tk.newSkuView.mainCategoryStep.backLabel)}
      onGoBack={onGoBack}
      showProgress={false}
      data-testid="AdPickStep"
    >
      <Box mt={4} mb={3}>
        <Typography variant="h3">
          {t(tk.registrationView.adPickStep.title)}
        </Typography>
      </Box>
      <Box className={classes.buttonList}>
        <TwoLineButton
          onClick={onAdSkuSelect}
          color="secondary"
          primaryText={t(tk.registrationView.adPickStep.newAdSku.title)}
          secondaryText={t(tk.registrationView.adPickStep.newAdSku.subtitle)}
        />
        <TwoLineButton
          onClick={onAdSkuInstanceSelect}
          color="secondary"
          primaryText={t(tk.registrationView.adPickStep.newAdSkuInstance.title)}
          secondaryText={t(
            tk.registrationView.adPickStep.newAdSkuInstance.subtitle,
          )}
        />
      </Box>
    </WizardStep>
  );
};
