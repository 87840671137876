import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.light,
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
  }),
);

export const PromptLabel: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} variant="body2">
      {children}
    </Typography>
  );
};
