import React, { FC, useState } from 'react';

import { useNavigation } from '../../../common/navigation';
import {
  useUpdateAdSkuInstanceMutation,
  UpdateAdSkuInstanceMutation,
  AdSkuInstanceInput,
} from '../../graphql';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { ErrorView } from '../../../common/components/ErrorView';
import { extractMutation } from '../../../common/utils/extractQuery';
import { UpdateAdSkuInstanceMutationPayload } from '../../graphql/types';
import { useAdSkuInstanceFormFields } from '../../forms/useAdSkuInstanceForm';
import {
  useAdSkuInstanceBreadcrumbs,
  useResolvedAdSkuInstance,
} from '../../utils/adSkuInstance';
import { getInitialValues } from '../../components/AdSkuInstanceView/getInitialValues';
import { AdSkuInstanceView as AdSkuInstanceViewComponent } from '../../components/AdSkuInstanceView/AdSkuInstanceView';
import { useCurrentTenantId } from '../../context/TenantContext';

import { useDeleteAdSkuInstance } from './useDeleteAdSkuInstance';

type Props = {
  id: string;
};

export const AdSkuInstanceView: FC<Props> = ({ id }) => {
  const nav = useNavigation();

  const [updateCompleted, setUpdateCompleted] = useState<boolean>(false);

  const { adSkuInstance, adSku, loading, error } = useResolvedAdSkuInstance(id);

  const contextTenantId = useCurrentTenantId();
  const isTenantFromContext = adSkuInstance?.tenant?.name === contextTenantId;

  const fields = useAdSkuInstanceFormFields(undefined, !isTenantFromContext);

  const [
    updateAdSkuInstance,
    rawMutationResult,
  ] = useUpdateAdSkuInstanceMutation({
    onCompleted: () => {
      setUpdateCompleted(true);
    },
  });

  const mutationResult = extractMutation<
    UpdateAdSkuInstanceMutation,
    UpdateAdSkuInstanceMutationPayload
  >(
    rawMutationResult,
    'UpdateAdSkuInstancePayload',
    data => data.updateAdSkuInstance,
  );

  const deleteMutation = useDeleteAdSkuInstance(id, adSku?.id);
  const breadcrumbs = useAdSkuInstanceBreadcrumbs(adSku, id);

  if (!adSkuInstance || !adSku) {
    if (loading) {
      return <LoadingIndicator />;
    }

    return <ErrorView error={error} />;
  }

  const handleUpdate = (input: Omit<AdSkuInstanceInput, 'adSkuId'>) => {
    setUpdateCompleted(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tenant, ...adSkuInstanceValues } = {
      ...getInitialValues(adSkuInstance),
      ...input,
      adSkuId: adSku.id,
    };

    updateAdSkuInstance({
      variables: {
        input: {
          id,
          adSkuInstance: adSkuInstanceValues,
        },
      },
    });
  };

  return (
    <AdSkuInstanceViewComponent
      error={mutationResult.error}
      adSku={adSku}
      adSkuInstance={adSkuInstance}
      breadcrumbs={breadcrumbs}
      fields={fields}
      deleteMutation={deleteMutation}
      onUpdate={isTenantFromContext ? handleUpdate : undefined}
      onViewSku={() => {
        nav.adSku({ id: adSku.id }).go();
      }}
      updateCompleted={updateCompleted}
    />
  );
};
