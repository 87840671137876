import Button from '@material-ui/core/Button';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { useTranslation } from '../../../../translations';

const useStyles = makeStyles(theme =>
  createStyles({
    counts: {
      marginTop: theme.spacing(3),
    },
  }),
);

type Props = {
  adSkuId: string;
  onClick?: (id: string) => void;
};

export const AdSkuItemAddInstance: React.FC<Props> = ({ adSkuId, onClick }) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  return (
    <Box className={classes.counts}>
      <Button
        color="secondary"
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          onClick && onClick(adSkuId);
        }}
      >
        {t(tk.skuListView.registerNewAdInstance)}
      </Button>
    </Box>
  );
};
