import React, { FC, ComponentType, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useTranslation } from '../../../../translations';
import { WizardStep } from '../../../../common/components/WizardStep';
import { TwoLineButton } from '../../../../common/components/TwoLineButton';
import { ResolvedSkuWithMetadata } from '../../../types';

import { LastSkus } from './LastSkus';

const useStyles = makeStyles(theme =>
  createStyles({
    buttonList: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  }),
);

export type LocationDialogProps = {
  onClose: () => void;
  open: boolean;
};

type Props = {
  number: number;
  totalCount: number;
  lastSkus: ResolvedSkuWithMetadata[];
  LocationDialog?: ComponentType<LocationDialogProps>;
  onGoBack?: () => void;
  onNewSku: () => void;
  onNewAdSku: () => void;
  onRegisterInstance: (skuId: string) => void;
};

export const WelcomeStep: FC<Props> = ({
  number,
  totalCount,
  lastSkus,
  LocationDialog,
  onNewSku,
  // onNewAdSku,
  onGoBack,
  onRegisterInstance,
}) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();
  const [showLocationDialog, setLocationDialog] = useState(false);

  const handleChangeLocation = () => {
    setLocationDialog(true);
  };

  const handleChangeLocationClose = () => {
    setLocationDialog(false);
  };

  return (
    <WizardStep
      backLabel={t(tk.newSkuView.mainCategoryStep.backLabel)}
      onGoBack={onGoBack}
      number={number}
      totalCount={totalCount}
      showProgress={false}
      data-testid="WelcomeStep"
    >
      {showLocationDialog && LocationDialog && (
        <LocationDialog
          onClose={handleChangeLocationClose}
          open={showLocationDialog}
        />
      )}
      <Box mt={3}>
        <Button
          onClick={handleChangeLocation}
          variant="outlined"
          size="small"
          startIcon={<LocationOnIcon />}
        >
          {t(tk.newSkuView.welcomeStep.changeLocation)}
        </Button>
      </Box>
      <Box mt={4} mb={3}>
        <Typography variant="h3">
          {t(tk.newSkuView.welcomeStep.title)}
        </Typography>
      </Box>
      <Box className={classes.buttonList}>
        <TwoLineButton
          onClick={onNewSku}
          color="secondary"
          primaryText={t(tk.newSkuView.welcomeStep.newSku.title)}
          secondaryText={t(tk.newSkuView.welcomeStep.newSku.subtitle)}
        />
        {/* FF - Hiden Market Place
         <TwoLineButton
          onClick={onNewAdSku}
          color="secondary"
          primaryText={t(tk.newSkuView.welcomeStep.newAdSku.title)}
          secondaryText={t(tk.newSkuView.welcomeStep.newAdSku.subtitle)}
        /> */}
      </Box>
      <Box mt={8}>
        <LastSkus lastSkus={lastSkus} onRegisterInstance={onRegisterInstance} />
      </Box>
    </WizardStep>
  );
};
