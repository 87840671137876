import { combineReducers } from 'redux';

import newSku from './newSku';
import newAdSku from './newAdSku';
import newSkuInstance from './newSkuInstance';
import newAdSkuInstance from './newAdSkuInstance';
import office from './office';

export const reducer = combineReducers({
  newSku,
  newAdSku,
  newSkuInstance,
  newAdSkuInstance,
  office,
});

export type State = ReturnType<typeof reducer>;

export default reducer;
