import React, { ChangeEvent } from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { CheckboxSelected } from './icons/24px/CheckboxSelected';
import { CheckboxUnselected } from './icons/24px/CheckboxUnselected';

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ fullWidth }: CheckboxFieldProps) => ({
    display: fullWidth ? 'flex' : undefined,
  }),
  checkbox: {
    padding: 0,
  },
  selectedIcon: {
    fontSize: 18,
    marginLeft: 12,
    //no design and usage for required unselected icon +  icon does not support changing colors
    // '& *': {
    //   stroke: error ? theme.palette.error.main : theme.palette.primary.main,
    // },
  },
  unselectedIcon: ({ error }: CheckboxFieldProps) => ({
    fontSize: 18,
    marginLeft: 12,
    '& *': {
      stroke: error ? theme.palette.error.main : theme.palette.primary.main,
    },
  }),
  label: {
    margin: 0,
  },
  error: {},
}));

export type CheckboxFieldProps = {
  disabled?: boolean;
  name: string;
  value: boolean;
  label: string | React.ReactNode;
  error?: {
    message?: string;
  };
  onChange?: (value: boolean) => void;
  fullWidth?: boolean;
};

export const CheckboxField: React.FC<CheckboxFieldProps> = props => {
  const { disabled, error, name, label, value, onChange } = props;
  const classes = useStyles(props);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(event.target.checked);
  return (
    <FormControl className={classes.root} component="fieldset" error={!!error}>
      <FormControlLabel
        control={
          <MuiCheckbox
            checked={value}
            disabled={disabled}
            className={classes.checkbox}
            checkedIcon={<CheckboxSelected className={classes.selectedIcon} />}
            icon={<CheckboxUnselected className={classes.unselectedIcon} />}
            indeterminateIcon={
              <CheckboxUnselected className={classes.unselectedIcon} />
            }
            onChange={handleChange}
          />
        }
        name={name}
        label={
          <Box ml={1}>
            <Typography variant="body2" className={classes.label}>
              {label}
            </Typography>
            {error && (
              <FormHelperText className={classes.error}>
                {error?.message}
              </FormHelperText>
            )}
          </Box>
        }
      />
    </FormControl>
  );
};
