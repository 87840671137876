import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
} from '../types';
import { mapFieldKeys } from '../utils';

import { getFields, TableFields } from './fields';
import { TableFunctionalities } from './types';

export const DINING_TABLES_SUBCATEGORY_KEY = 'diningTables';
export const DINING_TABLES_SUBCATEGORY_LABEL = 'Dining Tables';

const defaultValues: TableFunctionalities = {
  tableAdjustableWidth: false,
  tableStackable: false,
  tableFoldable: false,
  tableDrawer: false,
};

const groups = (
  { tableAdjustableWidth, tableFeet, tableTopShape }: TableFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'feet',
    label: tableFeet.label,
    attributes: [tableFeet],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [tableFeet.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tableFeet,
        },
      ],
    },
  },
  {
    name: 'adjustableWidth',
    label: tableAdjustableWidth.label,
    attributes: [tableAdjustableWidth],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.tables.groups.adjustableWidth.noValueLabel),
      field: tableAdjustableWidth,
    },
  },
  {
    name: 'tableTopShape',
    label: tableTopShape.label,
    attributes: [tableTopShape],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [tableTopShape.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: tableTopShape,
        },
      ],
    },
  },
];

export const diningTables = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields, t, tk),
  };
};
