import {
  ColorsStepConfiguration,
  colorsStepConfiguration,
} from '../../../models/colors';

export const validateSecondaryColor = (
  colorsStepConfiguration: ColorsStepConfiguration,
  subcategoryKey: string | undefined,
  secondaryColor: string | null | undefined,
) =>
  subcategoryKey &&
  colorsStepConfiguration[subcategoryKey] &&
  colorsStepConfiguration[subcategoryKey].secondaryColorRequired
    ? secondaryColor
    : true;

export const resolveColorLabel = (
  labels: Record<string, string>,
  subcategoryKey: string | undefined,
) => {
  return (subcategoryKey && labels[subcategoryKey]) || labels.default;
};

export const resolveColorHidden = (subcategoryKey: string | undefined) => {
  return Boolean(
    subcategoryKey &&
      colorsStepConfiguration[subcategoryKey] &&
      colorsStepConfiguration[subcategoryKey].secondaryColorHidden,
  );
};
