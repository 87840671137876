import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import ListSubheader from '@material-ui/core/ListSubheader';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { TextField } from '../TextField';

const useStyles = makeStyles(theme =>
  createStyles({
    filterCard: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1, 2, 1),
      borderColor: 'white',
    },
  }),
);

export type Props = {
  handleChange?: (fields: string, value: string) => void;
  name: string;
  title: string;
  value?: unknown;
};

export const TextInputFilter: React.FC<Props> = ({
  title,
  name,
  value,
  handleChange,
}) => {
  const classes = useStyles();
  const [filterValue, setFilterValue] = useState(value);
  const [timer, setTimer] = useState<null | ReturnType<typeof setTimeout>>(
    null,
  );

  const onChange = (value: string) => {
    setFilterValue(value);

    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        return handleChange ? handleChange(name, value) : null;
      }, 500),
    );
  };

  return (
    <Card className={classes.filterCard}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ListSubheader disableGutters disableSticky>
            {title}
          </ListSubheader>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            value={filterValue || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e.target.value)
            }
          />
        </Grid>
      </Grid>
    </Card>
  );
};
