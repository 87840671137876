import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import cn from 'classnames';

import { Container, ContainerProps } from '../Container';

const backgroundColor = (color: BackgroundContainerColor, theme: Theme) => {
  switch (color) {
    case 'default':
      return theme.palette.background.alternative;
    case 'primary':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.light;
    case 'success':
      return theme.palette.success.veryLight;
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      color: (props: BackgroundContainerProps) =>
        props.color !== 'primary'
          ? 'inherit'
          : theme.palette.primary.contrastText,
      backgroundColor: (props: BackgroundContainerProps) =>
        backgroundColor(props.color, theme),
    },
  }),
);

export type BackgroundContainerColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success';

export type BackgroundContainerProps = ContainerProps & {
  component?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  color: BackgroundContainerColor;
};

export const BackgroundContainer = (props: BackgroundContainerProps) => {
  const { className, children, ...containerProps } = props;
  const classes = useStyles(props);
  return (
    <div className={cn(classes.root, className)}>
      <Container {...containerProps}>{children}</Container>
    </div>
  );
};
