import omit from 'lodash/omit';
import { useMemo } from 'react';
import { QueryHookOptions } from '@apollo/client';

import { ImageHelper, useImageHelper } from '../../common/config';
import { Maybe, OmitTypename } from '../../common/types';
import { SkuPayload, SkusPayload } from '../graphql/types';
import { ResolvedAdSku, ResolvedSku } from '../types';
import placeholder from '../images/placeholdersquare.png';
import { extractQuery } from '../../common/utils/extractQuery';
import {
  SkuQuery,
  SkuQueryVariables,
  SkusQuery,
  SkusQueryVariables,
  useSkuQuery,
  useSkusQuery,
} from '../graphql';
import { getFunctionalityValues } from '../models/utils';
import { defaultImageSize, imageSizes } from '../consts';
import { ColorHelper, useColorHelper } from '../context/useColorMapping';

export const getSrcSet = (
  getImageUrl: ImageHelper['getImageUrl'],
  images: SkuPayload['images'][number]['optimizedImages'],
) => {
  return imageSizes
    .map(size => {
      const image = images?.find(i => i.size === size);
      if (!image) {
        return null;
      }
      const url = getImageUrl(image.filename, image.type);
      return `${url} ${size}w`;
    })
    .filter(Boolean)
    .join(', ');
};

export const getDefaultImageUrl = (
  getImageUrl: ImageHelper['getImageUrl'],
  image: OmitTypename<SkuPayload['images'][number]>,
) => {
  const optimizedImage = image.optimizedImages?.find(
    i => i.size === defaultImageSize,
  );
  if (optimizedImage) {
    return getImageUrl(optimizedImage.filename, optimizedImage.type);
  }
  return getImageUrl(image.filename);
};

export const resolveImage = (
  getImageUrl: ImageHelper['getImageUrl'],
  image: OmitTypename<SkuPayload['images'][number]>,
) => {
  return {
    ...image,
    url: getDefaultImageUrl(getImageUrl, image),
    optimizedImages: image.optimizedImages?.map(optImage => ({
      ...optImage,
      url: getImageUrl(optImage.filename, optImage.type),
    })),
    srcSet: getSrcSet(getImageUrl, image.optimizedImages),
  };
};

export const resolveSku = (
  sku: SkuPayload,
  { getImageUrl }: ImageHelper,
  colorHelper: ColorHelper,
): ResolvedSku => ({
  ...omit(sku, '__typename', 'functionalities'),
  images: sku.images.map(image => resolveImage(getImageUrl, image)),
  functionalities:
    sku.functionalities.__typename === 'AvailableFunctionalityList'
      ? sku.functionalities.items
      : [],
  primaryColor: sku.primaryColor ? colorHelper(sku.primaryColor) : null,
  secondaryColor: sku.secondaryColor ? colorHelper(sku.secondaryColor) : null,
});

export const useResolvedSku = (sku: Maybe<SkuPayload>) => {
  const imageHelper = useImageHelper();
  const colorHelper = useColorHelper();
  return useMemo(
    () => (sku ? resolveSku(sku, imageHelper, colorHelper) : null),
    [colorHelper, imageHelper, sku],
  );
};

export const useResolvedSkuQuery = (
  options?: QueryHookOptions<SkuQuery, SkuQueryVariables>,
) => {
  const rawSkuQuery = useSkuQuery(options);
  const skuQuery = extractQuery<SkuQuery, SkuPayload>(
    rawSkuQuery,
    'Sku',
    data => data.sku,
  );
  const data = useResolvedSku(skuQuery.data);
  return {
    ...skuQuery,
    data,
  };
};

export const getMainImageUrl = (sku: ResolvedSku | ResolvedAdSku) => {
  if (!sku.images.length) {
    return placeholder;
  }
  return sku.images[0].url;
};

export const getMainImageSrcSet = (sku: ResolvedSku | ResolvedAdSku) => {
  if (!sku.images.length) {
    return undefined;
  }
  return sku.images[0].srcSet;
};

export const useResolvedSkusQuery = (
  options?: QueryHookOptions<SkusQuery, SkusQueryVariables>,
) => {
  const imageHelper = useImageHelper();
  const colorHelper = useColorHelper();

  const result = extractQuery<SkusQuery, SkusPayload>(
    useSkusQuery(options),
    'SkuListWithMetadata',
    data => data.skus,
  );

  const skus = useMemo(() => {
    if (!result.data?.skus) {
      return [];
    }
    return result.data?.skus.map(({ sku, metadata }) => ({
      sku: resolveSku(sku, imageHelper, colorHelper),
      metadata,
    }));
  }, [colorHelper, imageHelper, result.data]);

  if (!result.data) {
    return {
      ...result,
      data: undefined,
    };
  }

  return {
    ...result,
    data: {
      ...result.data,
      skus,
    },
  };
};

export const getFunctionalityPreviewProps = (
  sku: ResolvedSku,
  expandable = true,
) => {
  const subcategory = sku.subcategory;
  if (!subcategory || subcategory.__typename !== 'Subcategory') {
    return null;
  }
  return {
    expandable,
    subcategoryKey: subcategory.key,
    values: getFunctionalityValues(sku),
  };
};
