import React from 'react';

import { Props } from '../types';

import { useStyles } from './styles';

export const CheckboxUnselected = (props: Props) => {
  const classes = useStyles(props);
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      className={classes.root}
      {...props}
    >
      <rect
        x={1}
        y={1}
        width={22}
        height={22}
        rx={3}
        stroke="#3A352D"
        strokeWidth={2}
      />
    </svg>
  );
};
