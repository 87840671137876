import { createMuiTheme } from '@material-ui/core/styles';

import { breakpoints } from './breakpoints';
import { palette } from './colors';
import { props } from './props';
import { typography } from './typography';
import { MuiAppBar } from './overrides/appBar';
import { MuiButton } from './overrides/button';
import { MuiCheckbox } from './overrides/checkbox';
import { MuiChip } from './overrides/data';
import {
  MuiFormHelperText,
  MuiInputBase,
  MuiInputLabel,
  MuiOutlinedInput,
} from './overrides/input';
import {
  MuiExpansionPanel,
  MuiExpansionPanelDetails,
  MuiExpansionPanelSummary,
} from './overrides/expansionPanel';
import { MuiLink } from './overrides/link';
import { MuiListSubheader } from './overrides/list';
import {
  MuiBackdrop,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
} from './overrides/modal';
import { MuiSlider } from './overrides/slider';
import { MuiTab, MuiTabs } from './overrides/tabs';
import { MuiSelect, MuiMenu } from './overrides/input';
import {
  MuiStepConnector,
  MuiStepIcon,
  MuiStepLabel,
} from './overrides/navigation';
import { zIndex } from './zIndex';

export const theme = createMuiTheme({
  breakpoints,
  overrides: {
    MuiBackdrop,
    MuiButton,
    MuiCheckbox,
    MuiChip,
    MuiDialog,
    MuiDialogActions,
    MuiDialogContent,
    MuiExpansionPanel,
    MuiExpansionPanelDetails,
    MuiExpansionPanelSummary,
    MuiFormHelperText,
    MuiInputBase,
    MuiInputLabel,
    MuiLink,
    MuiListSubheader,
    MuiMenu,
    MuiOutlinedInput,
    MuiSelect,
    MuiSlider,
    MuiStepConnector,
    MuiStepIcon,
    MuiStepLabel,
    MuiTab,
    MuiTabs,
    MuiAppBar,
  },
  palette,
  props,
  typography,
  zIndex,
} as const);
