import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import { hideRuleByFieldValue } from '../rules';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
  FunctionalityValue,
} from '../types';
import { mapFieldKeys } from '../utils';

import { getFields, ChairFields } from './fields';
import { ChairFunctionalities } from './types';

const defaultValues: ChairFunctionalities = {
  chairAdjustableHeight: 'fixed',
  chairBack: true,
  chairBackFixed: true,
  chairBackAdjustableHeight: false,
  chairBackAdjustableAngle: false,
  chairLeaningBack: false,
  chairSeat: true,
  chairSeatFixed: true,
  chairSeatAdjustablePosition: false,
  chairSeatAdjustableAngle: false,
  chairArmrests: false,
  chairArmrestsFixed: false,
  chairArmrestsAdjustablePosition: false,
  chairArmrestsAdjustableHeight: false,
  chairArmrestsAdjustableWidth: false,
  chairArmrestsAdjustableLength: false,
  chairNeckrest: 'none',
  chairFootring: false,
  chairStackable: false,
  chairFoldable: false,
};

const processValues = (values: Record<string, FunctionalityValue>) => {
  return {
    ...values,
    chairArmrestsFixed: values.chairArmrests,
  };
};

const groups = (
  {
    chairBack,
    chairBackLength,
    chairArmrests,
    chairArmrestsFixed,
    chairFeetCount,
    chairFeetType,
    chairNeckrest,
    chairLeaningBack,
  }: ChairFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'back',
    label: t(tk.chairs.groups.back.label),
    attributes: [chairBackLength],
    functionalities: [chairLeaningBack],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [chairBack.key, chairBackLength.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairBackLength,
        },
        {
          type: FunctionalityEditRow.BooleanGroup,
          promptLabel: t(tk.chairs.groups.back.promptLabel),
          fields: [chairLeaningBack],
        },
      ],
    },
  },
  {
    name: 'armrests',
    label: t(tk.chairs.groups.armrests.label),
    attributes: [chairArmrestsFixed],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      field: chairArmrests,
    },
  },
  {
    name: 'feet',
    label: t(tk.chairs.groups.feet.label),
    attributes: [chairFeetCount, chairFeetType],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [chairFeetCount.key, chairFeetType.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairFeetCount,
        },
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairFeetType,
        },
      ],
    },
  },
  {
    name: 'neckrest',
    label: t(tk.chairs.groups.neckrest.label),
    attributes: [chairNeckrest],
    functionalities: [],
    hideRule: hideRuleByFieldValue(chairNeckrest.key, 'none'),
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [chairNeckrest.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairNeckrest,
          limitedTo: ['none', 'fixed'],
        },
      ],
    },
  },
];

export const armchairs = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields, t, tk),
    processValues,
  };
};
