import React, { useMemo } from 'react';

import { FormLayout } from '../../common/components/Form/types';
import { useTranslation } from '../../translations';
import { FormHeading } from '../components/AddTenantView/FormHeading';
import { gridItemStandard } from '../../inventory/forms/common';

export const useUpdateTenantFormLayout = (): FormLayout => {
  const { t, tk } = useTranslation('inventory');
  return useMemo(
    () => [
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'displayName',
            label: t(tk.fieldLabels.name),
          },
        ],
      },
    ],
    [t, tk],
  );
};

export const useAddTenantFormLayout = (): FormLayout => {
  const { t, tk } = useTranslation('inventory');
  return useMemo(
    () => [
      {
        type: 'component',
        component: (
          <FormHeading text={t(tk.addTenantView.tenantInfo)} variant="h6" />
        ),
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'displayName',
            label: t(tk.fieldLabels.companyName),
          },
        ],
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'user',
            label: t(tk.fieldLabels.companyUserEmail),
          },
        ],
      },
      {
        type: 'component',
        component: (
          <FormHeading text={t(tk.addTenantView.newOfficeInfo)} variant="h6" />
        ),
      },
      {
        type: 'fields',
        fields: [
          {
            gridItemProps: gridItemStandard,
            name: 'city',
            label: t(tk.fieldLabels.city),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'zipCode',
            label: t(tk.fieldLabels.zipCode),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'street',
            label: t(tk.fieldLabels.street),
          },
          {
            gridItemProps: gridItemStandard,
            name: 'streetNumber',
            label: t(tk.fieldLabels.streetNumber),
          },
        ],
      },
    ],
    [t, tk],
  );
};
