import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';
import { genBooleanField, genOptionSetField } from '../../configUtils';
import { FunctionalityFieldDisplayType } from '../../types';

export const chairBack = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'chairBack',
    t,
    tk: tk.chairs.fields.chairBack,
  });

export const chairBackAdjustableHeight = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genBooleanField({
    key: 'chairBackAdjustableHeight',
    t,
    tk: tk.chairs.fields.chairBackAdjustableHeight,
  });

export const chairBackAdjustableAngle = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genBooleanField({
    key: 'chairBackAdjustableAngle',
    t,
    tk: tk.chairs.fields.chairBackAdjustableAngle,
  });

export const chairLeaningBack = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'chairLeaningBack',
    t,
    tk: tk.chairs.fields.chairLeaningBack,
  });

export const chairSeatAdjustablePosition = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genBooleanField({
    key: 'chairSeatAdjustablePosition',
    t,
    tk: tk.chairs.fields.chairSeatAdjustablePosition,
  });

export const chairSeatAdjustableAngle = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genBooleanField({
    key: 'chairSeatAdjustableAngle',
    t,
    tk: tk.chairs.fields.chairSeatAdjustableAngle,
  });

export const chairArmrestsAdjustablePosition = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genBooleanField({
    key: 'chairArmrestsAdjustablePosition',
    t,
    tk: tk.chairs.fields.chairArmrestsAdjustablePosition,
  });

export const chairArmrestsAdjustableHeight = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genBooleanField({
    key: 'chairArmrestsAdjustableHeight',
    t,
    tk: tk.chairs.fields.chairArmrestsAdjustableHeight,
  });

export const chairArmrestsAdjustableWidth = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genBooleanField({
    key: 'chairArmrestsAdjustableWidth',
    t,
    tk: tk.chairs.fields.chairArmrestsAdjustableWidth,
  });

export const chairArmrestsAdjustableLength = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genBooleanField({
    key: 'chairArmrestsAdjustableLength',
    t,
    tk: tk.chairs.fields.chairArmrestsAdjustableLength,
  });

export const chairAdjustableHeight = (
  t: TFunction,
  tk: TKeys<'functionality'>,
) =>
  genOptionSetField({
    key: 'chairAdjustableHeight',
    t,
    tk: tk.chairs.fields.chairAdjustableHeight,
    type: FunctionalityFieldDisplayType.String,
  });

export const chairBackFixed = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'chairBackFixed',
    t,
    tk: tk.chairs.fields.chairBackFixed,
  });

export const chairBackLength = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'chairBackLength',
    t,
    tk: tk.chairs.fields.chairBackLength,
    type: FunctionalityFieldDisplayType.String,
  });

export const chairSeat = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'chairSeat',
    t,
    tk: tk.chairs.fields.chairSeat,
  });

export const chairSeatFixed = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'chairSeatFixed',
    t,
    tk: tk.chairs.fields.chairSeatFixed,
  });

export const chairArmrests = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'chairArmrests',
    t,
    tk: tk.chairs.fields.chairArmrests,
  });

export const chairArmrestsFixed = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'chairArmrestsFixed',
    t,
    tk: tk.chairs.fields.chairArmrestsFixed,
  });

export const chairFeetCount = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'chairFeetCount',
    t,
    tk: tk.chairs.fields.chairFeetCount,
    type: FunctionalityFieldDisplayType.Number,
  });

export const chairFeetType = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'chairFeetType',
    t,
    tk: tk.chairs.fields.chairFeetType,
    type: FunctionalityFieldDisplayType.String,
  });

export const chairNeckrest = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'chairNeckrest',
    t,
    tk: tk.chairs.fields.chairNeckrest,
    type: FunctionalityFieldDisplayType.String,
  });

export const chairFootring = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'chairFootring',
    t,
    tk: tk.chairs.fields.chairFootring,
  });

export const chairStackable = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'chairStackable',
    t,
    tk: tk.chairs.fields.chairStackable,
  });

export const chairFoldable = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'chairFoldable',
    t,
    tk: tk.chairs.fields.chairFoldable,
  });

export const chairSeats = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'chairSeats',
    t,
    tk: tk.chairs.fields.chairSeats,
    type: FunctionalityFieldDisplayType.String,
  });
