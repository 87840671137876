import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent/CardContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useTranslation } from '../../../../translations';
import { WhiteCard } from '../../../../common/components/WhiteCard';
import { SummaryHeader } from '../../NewSkuView/SkuPreview/SummaryHeader';
import { ResolvedSku } from '../../../types';
import { SkuInstanceInput } from '../../../graphql';
import { ResolvedOffice } from '../../../context/types';
import { IndicatorChip } from '../../../../common/components/IndicatorChip/IndicatorChip';
import { StatusChip } from '../../SkuInstanceListView/SkuInstanceList/StatusChip';
import { ConditionChip } from '../../SkuInstanceListView/SkuInstanceList/ConditionChip';

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      justifyContent: 'flex-start',
    },
  }),
);

type Props = {
  className?: string;
  sku: Pick<
    ResolvedSku,
    'manufacturer' | 'model' | 'primaryColor' | 'secondaryColor' | 'images'
  >;
  skuInstanceInput: SkuInstanceInput;
  office: ResolvedOffice;
  imageUrl?: string;
};

export const SkuInstanceSummary: FC<Props> = ({
  children,
  className,
  skuInstanceInput,
  office,
  sku,
  ...props
}) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  const location = office.officeFloors.find(
    f => f.id === skuInstanceInput.officeFloorId,
  )?.floorLabel;

  return (
    <WhiteCard className={className} variant="outlined" {...props}>
      <CardContent>
        <SummaryHeader sku={sku} image={sku.images[0]} />
        <Box mt={2}>
          <IndicatorChip
            className={classes.chip}
            color="default"
            variant="outlined"
            fullWidth
            label={
              <span>
                <strong>{t(tk.newSkuInstanceView.summaryStep.location)}</strong>{' '}
                {location}
              </span>
            }
          />
        </Box>
        <Box display="flex" mt={2}>
          <Box flex={1} mr={1}>
            <StatusChip
              className={classes.chip}
              variant="outlined"
              status={skuInstanceInput.status}
              fullWidth
              label={
                <span>
                  <strong>{t(tk.fieldLabels.status)}</strong>{' '}
                  {skuInstanceInput.status}
                </span>
              }
            />
          </Box>
          {skuInstanceInput.condition && (
            <Box flex={1} ml={1}>
              <ConditionChip
                className={classes.chip}
                variant="outlined"
                condition={skuInstanceInput.condition}
                fullWidth
                label={
                  <span>
                    <strong>{t(tk.fieldLabels.condition)}</strong>{' '}
                    {skuInstanceInput.condition}
                  </span>
                }
              />
            </Box>
          )}
        </Box>
        {children}
      </CardContent>
    </WhiteCard>
  );
};
