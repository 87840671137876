import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { SwatchProps, StyleProps } from './common';

const getActiveStyles = (props: StyleProps, theme: Theme) => {
  if (!props.onClick) {
    return {};
  }
  return {
    border: `2px solid ${
      props.contrast
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main
    }`,
    transition: theme.transitions.create(['border']),
  };
};

const diameter: { [size: string]: number } = {
  small: 24,
  medium: 40,
  large: 80,
  xsmall: 16,
} as const;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: StyleProps) => ({
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: props.onClick ? 'pointer' : undefined,
      margin: theme.spacing() * (props.size === 'large' ? 1.5 : 0.5),
    }),
    iconContainer: (props: StyleProps) => ({
      border: `2px solid transparent`,
      borderRadius: '50%',
      display: 'inline-block',
      ...(props.selected ? getActiveStyles(props, theme) : {}),
      '&:hover': getActiveStyles(props, theme),
      '&:focus': getActiveStyles(props, theme),
      '&:active': getActiveStyles(props, theme),
    }),
    icon: (props: StyleProps) => ({
      boxSizing: 'border-box',
      backgroundColor: props.color,
      border: props.withBorder
        ? `1px solid ${theme.palette.primary.main}`
        : 'none',
      borderRadius: '50%',
      margin: 2,
      height: diameter[props.size],
      width: diameter[props.size],
    }),
    label: (props: StyleProps) => ({
      marginTop: theme.spacing() * (props.size === 'large' ? 1.5 : 0.5),
      fontWeight: props.selected ? 700 : undefined,
    }),
  }),
);

export const Swatch: React.FC<SwatchProps> = ({
  color,
  label,
  selected = false,
  size = 'medium',
  withBorder = false,
  contrast = false,
  onClick,
}) => {
  const classes = useStyles({
    color,
    size,
    selected,
    withBorder,
    contrast,
    onClick,
  });
  return (
    <div className={classes.container}>
      <div
        className={classes.iconContainer}
        onClick={onClick}
        aria-label={label}
      >
        <div className={classes.icon}></div>
      </div>
      {label && (
        <Typography
          className={classes.label}
          variant="caption"
          onClick={onClick}
        >
          {label}
        </Typography>
      )}
    </div>
  );
};
