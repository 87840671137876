import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Swatch } from '../../../../common/components/Swatch';
import { SkuImage } from '../../common/SkuImage';
import { ResolvedImage, ResolvedSku } from '../../../types';
import { fallbackColor } from '../../../consts';

const useStyles = makeStyles(theme =>
  createStyles({
    mediaContainer: {
      paddingRight: theme.spacing(2),
    },
    media: {
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: 8,
    },
  }),
);

type Props = {
  sku: Pick<
    ResolvedSku,
    'manufacturer' | 'model' | 'primaryColor' | 'secondaryColor'
  >;
  image?: ResolvedImage;
};

export const SummaryHeader: FC<Props> = ({ sku, image }) => {
  const classes = useStyles();
  const { manufacturer, model, primaryColor, secondaryColor } = sku;

  return (
    <Grid container>
      {image && (
        <Grid item xs={4} className={classes.mediaContainer}>
          <SkuImage image={image} className={classes.media} sizes="50%" />
        </Grid>
      )}
      <Grid item xs={8}>
        <Typography variant="body1">{manufacturer}</Typography>
        <Box mb={1}>
          <Typography variant="body1">
            <strong>{model}</strong>
          </Typography>
        </Box>
        {primaryColor && (
          <Box display="flex" flexDirection="row" alignItems="center" ml={-1}>
            <Swatch
              size="xsmall"
              withBorder
              color={sku.primaryColor?.hex || fallbackColor}
            />
            <Typography variant="body2">{primaryColor.label}</Typography>
          </Box>
        )}
        {secondaryColor && (
          <Box display="flex" flexDirection="row" alignItems="center" ml={-1}>
            <Swatch
              size="xsmall"
              withBorder
              color={sku.secondaryColor?.hex || fallbackColor}
            />
            <Typography variant="body2">{secondaryColor.label}</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
