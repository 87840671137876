import { TFunction } from 'i18next';
import { mapValues } from 'lodash';
import { useMemo } from 'react';

import { useTranslation, TKeys } from '../../translations';
import { SkuTemplatePayload } from '../graphql/types';

import { chairs } from './chairs/filters';
import { sofas } from './sofas/filters';
import { storage } from './storage/filters';
import { tables } from './tables/filters';
import { whiteboards } from './whiteboards/filters';
import {
  FunctionalityFilterGroup,
  FunctionalityFilterRow,
  FunctionalityFilters,
  ResolvedFunctionalityFilterGroup,
  ResolvedFunctionalityFilters,
} from './types';
import { getOptions } from './utils';

const filters = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): Record<string, FunctionalityFilters> => ({
  chairs: chairs(t, tk),
  sofas: sofas(t, tk),
  storage: storage(t, tk),
  tables: tables(t, tk),
  whiteboards: whiteboards(t, tk),
});

const resolveFilterGroup = (
  group: FunctionalityFilterGroup,
  templateFields: SkuTemplatePayload['fields'],
): ResolvedFunctionalityFilterGroup => {
  return {
    ...group,
    rows: group.rows.map(row => {
      if (row.type !== FunctionalityFilterRow.OptionSet) {
        return row;
      }
      return {
        ...row,
        field: {
          ...row.field,
          options: getOptions(row.field, templateFields, false),
        },
      };
    }),
  };
};

export const useResolvedFilterConfig = (
  templateFields: SkuTemplatePayload['fields'],
): Record<string, ResolvedFunctionalityFilters> => {
  const { t, tk } = useTranslation('functionality');
  return useMemo(() => {
    const config = filters(t, tk);
    return mapValues(config, catConfig => ({
      ...catConfig,
      groups: catConfig.groups.map(group =>
        resolveFilterGroup(group, templateFields),
      ),
    }));
  }, [t, templateFields, tk]);
};
