import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import MuiLink from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { ExpandMore } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import React, { FC, useContext, useEffect, useState } from 'react';
import * as History from 'history';

import { Divider } from '../../../common/components/Divider';
import { useTranslation } from '../../../translations';
import { TenantLogo } from '../Logo/TenantLogo';
import { LogoutContext } from '../../containers/LogoutProvider';

import {
  HeaderContent,
  HeaderContentProps,
} from './HeaderContent/HeaderContent';
import { TenantSwitch, TenantSwitchProps } from './TenantSwitch';

const useStyles = makeStyles(theme =>
  createStyles({
    accordion: {
      display: 'flex',
      flexDirection: 'column',
      background: 'none',
      '&.Mui-focusVisible': {
        background: 'none',
      },
    },
    accordionDetails: {
      width: '100%',
    },
    accordionSummary: {
      order: 2,
      background: 'none',
      '&.Mui-focusVisible': {
        background: 'none',
      },
    },
    appBar: {
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(4),
      },
      ['@media print']: {
        display: 'none',
      },
    },
    divider: {
      backgroundColor: theme.palette.primary.veryLight,
    },
    grow: {
      flexGrow: 1,
    },
    logo: {
      width: 80,
      margin: theme.spacing(2, 0),
    },
    toolbar: {
      width: '100%',
      padding: 0,
    },
  }),
);

type Props = HeaderContentProps & {
  location?: History.Location;
  tenantId?: string;
  tenants?: TenantSwitchProps['tenants'];
  onTenantChange: (tenantId: string) => void;
};

export const Header: FC<Props> = ({
  location,
  tenantId,
  tenants,
  onTenantChange,
  ...contentProps
}) => {
  const classes = useStyles();

  const logout = useContext(LogoutContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const { t: tc, tk: tkc } = useTranslation('common');

  useEffect(() => logout.setShowLogout(false));

  useEffect(() => setMenuOpen(false), [location]);

  return (
    <AppBar
      elevation={0}
      className={classes.appBar}
      color="transparent"
      position="static"
    >
      <Container>
        <Accordion
          expanded={menuOpen}
          elevation={0}
          className={classes.accordion}
          onChange={() => setMenuOpen(!menuOpen)}
        >
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={!menuOpen ? <MenuIcon /> : <ExpandMore />}
          >
            <Toolbar className={classes.toolbar}>
              <TenantLogo menuOpen={menuOpen} tenantId={tenantId} />
              <TenantSwitch
                current={tenantId}
                tenants={tenants}
                onChange={onTenantChange}
              />
              <div className={classes.grow} />
              {menuOpen && (
                <MuiLink href="#" onClick={() => setMenuOpen(!menuOpen)}>
                  {tc(tkc.header.closeMenuButton)}
                </MuiLink>
              )}
            </Toolbar>
          </AccordionSummary>
          <AccordionDetails className={classes.toolbar}>
            <HeaderContent {...contentProps} />
          </AccordionDetails>
        </Accordion>
        <Divider thickness={2} className={classes.divider} />
      </Container>
    </AppBar>
  );
};
