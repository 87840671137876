import Grid from '@material-ui/core/Grid';
import React, { useMemo } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Controller, useFieldArray } from 'react-hook-form';

import { FieldProps } from '../../../common/components/Form/types';
import { useTranslation } from '../../../translations';

const useStyles = makeStyles(theme =>
  createStyles({
    buttonRemove: {
      borderColor: theme.palette.error.dark,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  }),
);

export const useOfficeFloorsFieldComponent = () => {
  const styless = useStyles();
  const { t, tk } = useTranslation('admin');

  return useMemo(
    () => ({ gridItemProps, control, name, label, ...props }: FieldProps) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { remove, fields, append } = useFieldArray<string[]>({
        name,
        control,
      });
      return (
        <Grid item {...gridItemProps}>
          <Typography
            style={{
              fontWeight: 500,
              minWidth: 100,
              letterSpacing: 0,
              fontSize: 12,
            }}
            variant="body2"
          >
            {label}
          </Typography>
          {fields.length > 0 && (
            <Box
              display={'flex'}
              mt={'8px'}
              flexDirection={'column'}
              gridRowGap={'8px'}
            >
              {fields.map((_item, index) => {
                return (
                  <Box
                    display={'flex'}
                    gridColumnGap={'8px'}
                    alignItems={'center'}
                    key={index}
                  >
                    <Controller
                      {...props}
                      control={control}
                      name={`${name}.${index}`}
                      render={props => {
                        return <TextField {...props} />;
                      }}
                    />

                    <Button
                      className={styless.buttonRemove}
                      size="small"
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      {t(tk.adminPanel.removeOfficeFloor)}
                    </Button>
                  </Box>
                );
              })}
            </Box>
          )}

          {fields.length < 10 && (
            <Button
              style={{ marginTop: 8 }}
              variant="contained"
              color="primary"
              type="button"
              onClick={() => append([''])}
            >
              {t(tk.adminPanel.addOfficeFloor)}
            </Button>
          )}
        </Grid>
      );
    },
    [styless.buttonRemove, t, tk],
  );
};
