import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';
import React from 'react';

import { BackgroundContainer } from '../../../common/components/BackgroundContainer';
import { useTranslation } from '../../../translations';
import { GoodWorkLogo } from '../Logo/GoodWorkLogo/GoodWorkLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ['@media print']: {
        display: 'none',
      },
    },
    logo: {
      padding: theme.spacing(2, 0, 1),
    },
    subtitle: {
      paddingBottom: theme.spacing(8),
    },
    link: {
      padding: theme.spacing(1, 4, 1, 0),
      width: '100%',
      textDecoration: 'underline',
      textTransform: 'capitalize',
    },
    linkLabel: {
      width: '100%',
    },
    linkLabelSecondary: {
      textDecorationColor: theme.palette.text.secondary,
    },
    content: {
      padding: theme.spacing(3, 0),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4, 4),
      },
    },
    social: {
      paddingTop: theme.spacing(4),
    },
  }),
);

type FooterLink = {
  label: string;
  to: string;
};

type Props = {};

export const Footer: React.FC<Props> = () => {
  const classes = useStyles();

  const { t, tk } = useTranslation('common');

  const handleScrollToTop = () =>
    window.scrollTo({ top: 0, behavior: 'smooth' });

  const links = [
    {
      label: t(tk.footer.linkLabels.aboutUs),
      to: t(tk.footer.linkHrefs.aboutUs),
    },
    {
      label: t(tk.footer.linkLabels.contactUs),
      to: t(tk.footer.linkHrefs.contactUs),
    },
    {
      label: t(tk.footer.linkLabels.privacyPolicy),
      to: t(tk.footer.linkHrefs.privacyPolicy),
    },
    {
      label: t(tk.footer.linkLabels.cookiePolicy),
      to: t(tk.footer.linkHrefs.cookiePolicy),
    },
  ];

  const getLink = (link: FooterLink, key: string | number) => (
    <Grid item key={key}>
      <Button
        className={classes.link}
        color="inherit"
        variant="text"
        href={link.to}
      >
        <Typography align="left" variant="body1" className={classes.linkLabel}>
          {link.label}
        </Typography>
      </Button>
    </Grid>
  );

  return (
    <BackgroundContainer
      component="footer"
      color="primary"
      className={classes.root}
    >
      <Grid
        container
        className={classes.content}
        direction="row"
        justify="space-between"
      >
        <Grid item sm={4} xs={12}>
          <GoodWorkLogo withText mode="dark" />
        </Grid>
        <Grid item sm={3} xs="auto">
          <Grid container direction="column" justify="flex-start">
            {links.map(getLink)}
            <Grid container className={classes.social}>
              <Button
                className={cn(classes.link, classes.linkLabelSecondary)}
                color="inherit"
                variant="text"
                onClick={handleScrollToTop}
              >
                <Typography
                  align="left"
                  variant="body1"
                  color="textSecondary"
                  className={classes.linkLabel}
                >
                  {t(tk.footer.linkLabels.scrollTop)}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BackgroundContainer>
  );
};
