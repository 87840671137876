import React, { forwardRef, ReactNode, MouseEvent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderWidth: 1,
      borderRadius: 8,
    },
  }),
);

type Props = {
  children: ReactNode;
  onClick: (event: MouseEvent) => void;
};

export const ContextMenuButton = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { children, onClick } = props;
    const classes = useStyles(props);

    return (
      <Paper
        elevation={0}
        variant="outlined"
        ref={ref}
        onClick={onClick}
        className={classes.root}
      >
        <CardActionArea>{children}</CardActionArea>
      </Paper>
    );
  },
);
