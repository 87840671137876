import React from 'react';
import {
  useTranslation as _useTranslation,
  // eslint-disable-next-line import/named
  UseTranslationOptions,
  Trans as BaseTrans,
} from 'react-i18next';

import { getKeys, keys } from './keys';
import { TranslationSchema } from './resources';

export type TKeys<NS extends keyof TranslationSchema> = typeof keys[NS];

export const useTranslation = <T extends keyof TranslationSchema>(
  ns: T,
  options?: UseTranslationOptions,
) => {
  const response = _useTranslation(ns, options);
  const { i18n, t } = response;
  const Trans: typeof BaseTrans = props => (
    <BaseTrans i18n={i18n} t={t} {...props} />
  );
  return {
    ...response,
    Trans,
    tk: getKeys(ns),
  };
};
