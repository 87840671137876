import React, { FC, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Form } from '../../../common/components/Form';
import { useTranslation } from '../../../translations';
import { Row } from '../../../common/components/Row';
import { FormFeedback } from '../../../common/components/FormFeedback';
import { GraphQlError } from '../../../common/types';
import { SkuFormInput } from '../../forms';
import { BreadcrumbItem } from '../../../common/components/Breadcrumbs';
import { TwoColumnLayout } from '../../../common/components/TwoColumnLayout';
import { Container } from '../../../common/components/Container';
import { ResolvedAdSku, ResolvedSku } from '../../types';
import { FormFields } from '../../../common/components/Form/types';
import { ResolvedMainCategory } from '../../context/types';
import { ColorMapping } from '../../context/useColorMapping';
import { PhotoGallery } from '../SkuView/PhotoGallery';
import { SkuTopMenu } from '../SkuView/SkuTopMenu';
import { useColorFieldComponent } from '../SkuView/useColorFieldComponent';
import { useAdSkuFormLayout } from '../../forms/useAdSkuForm';
import { useNavigation } from '../../../common/navigation';

import { getInitialValues } from './getInitialValues';

type Props = {
  breadcrumbs: BreadcrumbItem[];
  categories: ResolvedMainCategory[];
  colorMapping: ColorMapping;
  error?: GraphQlError;
  adSku: ResolvedAdSku;
  fields: FormFields;
  onUpdate?: (data: SkuFormInput) => void;
  updateCompleted: boolean;
};

const useStyles = makeStyles(theme =>
  createStyles({
    media: {
      borderRadius: 8,
      maxWidth: '100%',
    },
    root: {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(12),
      },
    },
    rightColumn: {
      maxWidth: 650,
    },
    idField: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '66%',
      },
      padding: theme.spacing(3, 0, 1),
    },
  }),
);

export const AdSkuView: FC<Props> = ({
  breadcrumbs,
  categories,
  colorMapping,
  error,
  fields,
  adSku,
  onUpdate,
  updateCompleted,
}) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');
  const ColorFieldComponent = useColorFieldComponent(colorMapping);
  const formLayout = useAdSkuFormLayout(categories, ColorFieldComponent);
  const initialValues = useMemo(() => getInitialValues(adSku), [adSku]);
  const nav = useNavigation();

  const handleRegisterNewItem = () =>
    nav.newAdSkuInstance({ adSkuId: adSku.id }).go();

  return (
    <Container className={classes.root} data-testid="SkuView">
      <SkuTopMenu
        breadcrumbs={breadcrumbs}
        onRegisterNewInstance={handleRegisterNewItem}
      />
      <TwoColumnLayout>
        <PhotoGallery sku={adSku as ResolvedSku} />
        <div className={classes.rightColumn}>
          <FormFeedback
            error={error}
            success={updateCompleted}
            sucessMessage={t(tk.skuView.updateSuccess)}
          />
          <Row>
            <div className={classes.idField}>
              <TextField
                disabled
                label={t(tk.fieldLabels.skuId)}
                name="id"
                InputProps={{ notched: false } as Partial<OutlinedInputProps>}
                InputLabelProps={{
                  shrink: true,
                }}
                value={adSku.id}
                fullWidth
                variant="outlined"
              />
            </div>
          </Row>
          <Form
            fields={fields}
            layout={formLayout}
            defaultValues={initialValues}
            onSubmit={onUpdate}
          />
        </div>
      </TwoColumnLayout>
    </Container>
  );
};
