import React, { FC, useMemo, useState } from 'react';

import { ErrorView } from '../../../common/components/ErrorView';
import { useNavigation, useSelectedFilters } from '../../../common/navigation';
import { useCategories } from '../../context/useCategories';
import { useTranslation } from '../../../translations';
import { getPageCount } from '../../utils/getPageCount';
import { adSkuTemplateName } from '../../consts';
import { SelectedFilters } from '../../utils/useFilters';
import {
  filterRangeValueQueryFilters,
  filterSingleValueQueryFilters,
  getRangeFiltersQuery,
  getUnionFiltersQuery,
  useResolvedAdSkusWithInstancesQuery,
} from '../../utils/adSku';
import { AdSkuList } from '../../components/SkuListView/AdSkuList';
import { getHeaderData, SkuType } from '../SkuListView/utils';
import { SkuListView } from '../../components/SkuListView';
import { RangeFilter, UnionFilter } from '../../graphql';

import { useAdSkuFilters } from './useAdSkuFilters';

const PAGE_LIMIT = 16;

export const AdSkuListView: FC = () => {
  const nav = useNavigation();
  const selectedFilters = useSelectedFilters();

  const setSelectedFilters = (newFilters: SelectedFilters) => {
    nav.adSkus({ filters: newFilters }).go();
  };

  const { filters, queryFilters } = useAdSkuFilters(selectedFilters);

  const [page, setPage] = useState(1);
  const categories = useCategories();
  const { t, tk } = useTranslation('inventory');
  const { breadcrumbs, title } = useMemo(
    () =>
      getHeaderData(
        queryFilters,
        categories,
        nav,
        t(tk.skuListView.title),
        SkuType.adSkus,
      ),
    [categories, queryFilters, t, nav, tk.skuListView.title],
  );

  const singleValueFilters = queryFilters.filter(filterSingleValueQueryFilters);
  const rangeFilters = queryFilters.filter(filterRangeValueQueryFilters);
  const criteriaFilters = queryFilters.filter(
    ({ field }) => field === 'minRelatedCount',
  );

  const criteria: Record<string, unknown> = {};
  let rangeFiltersQuery: RangeFilter[] = [];
  let unionFiltersQuery: UnionFilter[] = [];
  if (Array.isArray(rangeFilters) && rangeFilters.length > 0) {
    rangeFiltersQuery = getRangeFiltersQuery({
      filters: rangeFilters,
      name: 'salesPrice',
      min: '0',
      max: '9999999999',
    });

    unionFiltersQuery = getUnionFiltersQuery({
      filters: rangeFilters,
      name: 'salesPeriod',
      fromField: 'salesPeriodTimestampFrom',
      toField: 'salesPeriodTimestampTo',
      defaultFromValue: '946714748',
      defaultToValue: '2147483647',
    });
  }

  if (criteriaFilters && criteriaFilters.length > 0) {
    criteriaFilters.forEach(crit => (criteria[crit.field] = crit.values[0]));
  }

  const result = useResolvedAdSkusWithInstancesQuery({
    variables: {
      filters: singleValueFilters,
      rangeFilters: rangeFiltersQuery,
      unionFilters: unionFiltersQuery,
      criteria,
      slice: {
        offset: (page - 1) * PAGE_LIMIT,
        limit: PAGE_LIMIT,
      },
    },
  });

  const adSkus = result.data?.adSkus || [];

  if (result.error) {
    return <ErrorView error={result.error} />;
  }

  const handleFilterChange = (field: string, values: string[]) => {
    setPage(1);
    if (field === 'subcategoryId') {
      setSelectedFilters({
        [field]: values,
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        [field]: values,
      });
    }
  };

  const handleRangeFilterChange = (
    field: string,
    from: number | string | undefined,
    to: number | string | undefined,
  ) => {
    if (from === '' && to === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [field]: removed, ...selectedFiltersRest } = selectedFilters;
      return setSelectedFilters(selectedFiltersRest);
    }

    return setSelectedFilters({
      ...selectedFilters,
      [field]: [`from:${from}`, `to:${to}`],
    });
  };

  const handleCriteriaChange = (field: string, value: unknown) => {
    return setSelectedFilters({
      ...selectedFilters,
      [field]: value ? [value] : undefined,
    });
  };

  const pageCount = getPageCount(
    result.data?.metadata.matches,
    adSkuTemplateName,
    PAGE_LIMIT,
  );

  const onAddInstanceClick = (id: string) =>
    nav.newAdSkuInstance({ adSkuId: id }).go();

  return (
    <SkuListView
      breadcrumbs={breadcrumbs}
      filters={filters}
      loading={result.loading}
      onFilterChange={handleFilterChange}
      onRangeFilterChange={handleRangeFilterChange}
      onTextInputChange={handleCriteriaChange}
      onPageChange={setPage}
      page={page}
      pageCount={pageCount}
      matches={result.data?.metadata.matches}
      title={title}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      skuList={
        <AdSkuList
          adSkus={adSkus}
          nav={nav}
          onAddInstanceClick={onAddInstanceClick}
          isAddInstanceView={false}
          createRoute={id => nav.adSku({ id })}
        />
      }
    />
  );
};
