import { Office } from '../graphql';

export const dateFormat = 'yyyy-MM-dd';

export const formatOfficeAddress = (
  office: Pick<Office, 'street' | 'streetNumber' | 'building'>,
) =>
  `${office.street} ${office.streetNumber}${
    office.building ? office.building : ''
  }`;
