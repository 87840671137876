import { produce } from 'immer';

import { AdSkuInput, MainCategory, Subcategory } from '../../graphql';
import { ResolvedImage } from '../../types';

import { Actions } from './actions';
import { actionTypes } from './actionTypes';

export type DimensionsData = Pick<
  AdSkuInput,
  | 'minHeight'
  | 'maxHeight'
  | 'minWidth'
  | 'maxWidth'
  | 'minLength'
  | 'maxLength'
>;

export type State = {
  adSkuInput: Partial<
    DimensionsData &
      Pick<
        AdSkuInput,
        | 'mainCategoryId'
        | 'subcategoryId'
        | 'manufacturer'
        | 'model'
        | 'description'
        | 'primaryColor'
        | 'secondaryColor'
        | 'images'
      >
  >;
  images: ResolvedImage[];
  mainCategory?: Pick<MainCategory, 'id' | 'key' | 'name'>;
  subcategory?: Pick<Subcategory, 'id' | 'key' | 'name'>;
  isNewManufacturer?: boolean;
};

export const initialData: State = {
  adSkuInput: {},
  images: [],
};

// eslint-disable-next-line complexity
export const reducer = produce((state: State, action: Actions) => {
  switch (action.type) {
    case actionTypes.RESET: {
      state.adSkuInput = {};
      state.images = [];
      state.isNewManufacturer = false;
      delete state.mainCategory;
      delete state.subcategory;
      return;
    }

    case actionTypes.INITIALIZE_FROM_EXISTING_AD_SKU: {
      state.adSkuInput = {
        ...action.adSkuInput,
        // keep values from previous steps
        mainCategoryId: state.adSkuInput.mainCategoryId,
        subcategoryId: state.adSkuInput.subcategoryId,
        manufacturer: state.adSkuInput.manufacturer,
        model: state.adSkuInput.model,
      };
      return;
    }

    case actionTypes.SET_COLORS: {
      state.adSkuInput.primaryColor = action.primaryColor;
      state.adSkuInput.secondaryColor = action.secondaryColor;
      return;
    }

    case actionTypes.SET_DESCRIPTION: {
      state.adSkuInput.description = action.description;
      return;
    }

    case actionTypes.SET_DIMENSIONS: {
      state.adSkuInput.minHeight = action.minHeight;
      state.adSkuInput.maxHeight = action.maxHeight
        ? action.maxHeight
        : action.minHeight;
      state.adSkuInput.minWidth = action.minWidth;
      state.adSkuInput.maxWidth = action.maxWidth
        ? action.maxWidth
        : action.minWidth;
      state.adSkuInput.minLength = action.minLength;
      state.adSkuInput.maxLength = action.maxLength
        ? action.maxLength
        : action.minLength;
      return;
    }

    case actionTypes.SET_IMAGES: {
      state.images = action.images;
      state.adSkuInput.images = action.images.map(({ filename }) => ({
        filename,
      }));
      return;
    }

    case actionTypes.SET_MAIN_CATEGORY: {
      if (state.adSkuInput.mainCategoryId !== action.mainCategory.id) {
        state.adSkuInput.mainCategoryId = action.mainCategory.id;
        state.subcategory = undefined;
        state.mainCategory = action.mainCategory;
      }
      return;
    }
    case actionTypes.SET_MODEL_DATA: {
      state.adSkuInput.manufacturer = action.manufacturer;
      state.adSkuInput.model = action.model;
      state.isNewManufacturer = action.isNewManufacturer;
      return;
    }
    case actionTypes.SET_SUBCATEGORY: {
      state.adSkuInput.subcategoryId = action.subcategory.id;
      state.subcategory = action.subcategory;
      return;
    }
  }
}, initialData);
