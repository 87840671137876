import React, { useCallback } from 'react';
import { Switch, Route } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import { useNavigation } from '../../../common/navigation';
import { TenantProvider } from '../../../inventory/context/TenantContext';

const TenantRoute: React.FC = ({ children }) => {
  const match = useRouteMatch<{ tenantId: string }>();
  const tenantId = match.params.tenantId;
  const nav = useNavigation();

  const handleTenantChange = useCallback(
    (tenantId: string) => {
      nav.skus({}, { tenantId }).go();
    },
    [nav],
  );

  return (
    <TenantProvider tenantId={tenantId} onTenantChange={handleTenantChange}>
      {children}
    </TenantProvider>
  );
};

export const TenantRouter: React.FC = ({ children }) => {
  return (
    <Switch>
      <Route
        path="/:lang/:tenantId"
        component={() => <TenantRoute>{children}</TenantRoute>}
      />
      <Route component={() => <TenantRoute>{children}</TenantRoute>} />
    </Switch>
  );
};
