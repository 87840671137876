import { useMemo } from 'react';
import createPersistedState from 'use-persisted-state';

import { useCurrentTenantId } from '../context/TenantContext';

const getKey = (tenantId: string, key: string) => `reuse:${tenantId}:${key}`;

export const usePersistedState = (key: string) => {
  const tenantId = useCurrentTenantId();
  return useMemo(() => createPersistedState(getKey(tenantId || '', key)), [
    tenantId,
    key,
  ]);
};
