import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
} from '../../types';
import { hideRuleByFieldValue } from '../rules';
import { getFields, WhiteboardFields } from '../fields';
import { WhiteBoardsFunctionalities } from '../types';

const defaultValues: WhiteBoardsFunctionalities = {
  wBoardFrame: false,
  wBoardFoldable: false,
  wBoardFlippable: false,
};

const groups = (
  {
    wBoardSize,
    wBoardMaterial,
    wBoardFrame,
    wBoardColor,
    wBoardFeet,
    wBoardSided,
    wBoardFoldable,
    wBoardFlippable,
    wBoardHanger,
  }: WhiteboardFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'sizes',
    label: t(tk.whiteboards.groups.sizes.label),
    attributes: [wBoardSize],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [wBoardSize.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: wBoardSize,
        },
      ],
    },
  },
  {
    name: 'materials',
    label: t(tk.whiteboards.groups.materials.label),
    attributes: [wBoardMaterial],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [wBoardMaterial.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: wBoardMaterial,
        },
      ],
    },
  },
  {
    name: 'colors',
    label: t(tk.whiteboards.groups.colors.label),
    attributes: [wBoardColor],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [wBoardColor.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: wBoardColor,
        },
      ],
    },
  },
  {
    name: 'feet',
    label: t(tk.whiteboards.groups.feet.label),
    attributes: [wBoardFeet],
    functionalities: [],
    hideRule: hideRuleByFieldValue(wBoardFeet.key, 'none'),
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [wBoardFeet.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: wBoardFeet,
        },
      ],
    },
  },
  {
    name: 'sided',
    label: t(tk.whiteboards.groups.sided.label),
    attributes: [wBoardSided],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [wBoardSided.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: wBoardSided,
        },
      ],
    },
  },
  {
    name: 'hangers',
    label: t(tk.whiteboards.groups.hangers.label),
    attributes: [wBoardHanger],
    functionalities: [],
    hideRule: hideRuleByFieldValue(wBoardHanger.key, 'none'),
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [wBoardHanger.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: wBoardHanger,
        },
      ],
    },
  },

  {
    name: 'frames',
    label: t(tk.whiteboards.groups.frames.label),
    attributes: [wBoardFrame],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      field: wBoardFrame,
    },
  },
  {
    name: 'foldability',
    label: t(tk.whiteboards.groups.foldability.label),
    attributes: [wBoardFoldable],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.whiteboards.groups.foldability.noValueLabel),
      field: wBoardFoldable,
    },
  },
  {
    name: 'fippability',
    label: t(tk.whiteboards.groups.fippability.label),
    attributes: [wBoardFlippable],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.whiteboards.groups.fippability.noValueLabel),
      field: wBoardFlippable,
    },
  },
];

export const whiteboards = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields,
    groups: groups(fields, t, tk),
  };
};
