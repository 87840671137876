import React, { FC } from 'react';

import { useNavigation } from '../../../../common/navigation';
import { AdPickStep as AdPickStepComponent } from '../../../components/RegistrationView/AdPickView';
import { StepComponentProps } from '../../../../common/components/Wizard/types';

export const AdPickStep: FC<StepComponentProps> = ({ onGoBack }) => {
  const nav = useNavigation();
  const handleAdSkuSelect = () => {
    nav.adSkuRegistration().go();
  };
  const handleAdSkuInstanceSelect = () => {
    nav.adSkusAddInstance().go();
  };

  return (
    <AdPickStepComponent
      onAdSkuSelect={handleAdSkuSelect}
      onAdSkuInstanceSelect={handleAdSkuInstanceSelect}
      onGoBack={onGoBack}
    />
  );
};
