import React, { Suspense, FC } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { ConfigProvider } from '../common/config';
import { useFlexRoot } from '../common/utils/useFlexRoot';
import { LoadingIndicator } from '../common/components/LoadingIndicator';
import { InventoryProvider } from '../inventory/context/InventoryContext';
import { theme } from '../theme';
import { configureStore } from '../common/utils/configureStore';

import { ApolloProvider } from './containers/ApolloProvider';
import { AppUserProvider } from './containers/AppUserProvider';
import { AuthProvider } from './containers/AuthProvider';
import { Routes } from './containers/Routes';
import { Layout } from './containers/Layout';
import { RootRouter } from './containers/RootRouter';
import { Head } from './components/Head';
import { createReducer } from './setup/reducer';
import { TenantRouter } from './setup/tenant/TenantRouter';
import { LogoutProvider } from './containers/LogoutProvider';

import '../theme/fonts/fonts.css';

const configUrl = '/config.json';

const history = createBrowserHistory();
const reducer = createReducer(history);
const store = configureStore(reducer, history);

const ProtectedAppUserProvider = withAuthenticationRequired(AppUserProvider, {
  onRedirecting: () => <LoadingIndicator />,
});

export const App: FC = () => {
  useFlexRoot();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<LoadingIndicator />}>
        <ReduxProvider store={store}>
          <ConnectedRouter history={history}>
            <ConfigProvider configUrl={configUrl}>
              <AuthProvider>
                <LogoutProvider>
                  <ProtectedAppUserProvider>
                    <RootRouter>
                      <TenantRouter>
                        <ApolloProvider>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <InventoryProvider>
                              <Head />
                              <Layout>
                                <Routes />
                              </Layout>
                            </InventoryProvider>
                          </MuiPickersUtilsProvider>
                        </ApolloProvider>
                      </TenantRouter>
                    </RootRouter>
                  </ProtectedAppUserProvider>
                </LogoutProvider>
              </AuthProvider>
            </ConfigProvider>
          </ConnectedRouter>
        </ReduxProvider>
      </Suspense>
    </ThemeProvider>
  );
};
