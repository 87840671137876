import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { CheckboxSwatch } from '../CheckboxSwatch';
import { ColorHelper } from '../../../inventory/context/useColorMapping';

type StyleProps = {
  color: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      padding: 0,
    },
    selectedCheckboxLabel: {
      fontWeight: 700,
    },
    listItemIcon: {
      minWidth: 24,
    },
    root: {
      display: 'inline-flex',
      width: '50%',
    },
    checkboxIcon: ({ color }: StyleProps) => ({
      border: '2px solid #000',
      color,
    }),
  }),
);

export type CheckboxListColorItemProps = {
  selected: boolean;
  colorHelper: ColorHelper;
  item: {
    label: string;
    value: string;
  };
  name: string;
  singleSelect?: boolean;
  handleToggle: (value: string) => () => void;
};

export const CheckboxListColorItem: React.FC<CheckboxListColorItemProps> = ({
  item,
  name,
  colorHelper,
  selected,
  singleSelect = false,
  handleToggle,
}) => {
  const color = colorHelper(item.value);
  const classes = useStyles({ color: color.hex });
  const labelId = `checkbox-list-${name}-label-${item.value}`;
  return (
    <ListItem
      className={classes.root}
      key={item.value}
      role={undefined}
      dense
      button
      onClick={handleToggle(item.value)}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <Checkbox
          className={classes.checkbox}
          size="small"
          edge="start"
          checked={selected}
          checkedIcon={
            <CheckboxSwatch color={color.hex} size="small" selected={true} />
          }
          icon={<CheckboxSwatch color={color.hex} size="small" />}
          tabIndex={-1}
          disableRipple
          color="primary"
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={color.label}
        primaryTypographyProps={{
          className:
            singleSelect && selected
              ? classes.selectedCheckboxLabel
              : undefined,
          variant: 'body2',
        }}
      />
    </ListItem>
  );
};
