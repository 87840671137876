import React from 'react';
import Grid from '@material-ui/core/Grid';

import {
  ResolvedAdSkuWithInstances,
  ResolvedAdSkuWithMetadataAndInstances,
} from '../../types';
import { GoOptions, Navigation } from '../../../common/navigation';

import { SkuListItem } from './SkuListItem';
import { AdSkuItemMetadata } from './SkuListItem/AdSkuItemMetadata';
import { AdSkuItemAddInstance } from './SkuListItem/AdSkuItemAddInstance';

type Props = {
  adSkus: ResolvedAdSkuWithMetadataAndInstances[];
  nav: Pick<Navigation, 'adSku'>;
  isAddInstanceView?: boolean;
  onAddInstanceClick?: (id: string) => void;
  createRoute: (id: string) => { url: string; go(options?: GoOptions): void };
};

export const AdSkuList: React.FC<Props> = ({
  adSkus,
  isAddInstanceView,
  onAddInstanceClick,
  createRoute,
}) => {
  return (
    <>
      {adSkus.map(({ adSku, metadata }) => (
        <Grid key={adSku.id} item xs={6} md={4} lg={3}>
          <SkuListItem
            sku={adSku}
            route={createRoute(adSku.id)}
            metadataView={
              isAddInstanceView ? (
                <AdSkuItemAddInstance
                  adSkuId={adSku.id}
                  onClick={onAddInstanceClick}
                />
              ) : (
                <AdSkuItemMetadata
                  adSku={adSku as ResolvedAdSkuWithInstances}
                  metadata={metadata}
                />
              )
            }
          />
        </Grid>
      ))}
    </>
  );
};
