import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';

import { armchairs } from './armchairs';
import { benches } from './benches';
import { conferenceChairs } from './conferenceChairs';
import { foldingChairs } from './foldingChairs';
import { officeChairs } from './officeChairs';
import { poufs } from './poufs';
import { stools } from './stools';

export const chairs = (t: TFunction, tk: TKeys<'functionality'>) => ({
  armchairs: armchairs(t, tk),
  benches: benches(t, tk),
  conferenceChairs: conferenceChairs(t, tk),
  foldingChairs: foldingChairs(t, tk),
  officeChairs: officeChairs(t, tk),
  poufs: poufs(t, tk),
  stools: stools(t, tk),
});
