import { useParams } from 'react-router-dom';

import { RegistrationParams, RouteActions } from '../../common/navigation';

export const makeUseStepState = (
  route: (params: RegistrationParams) => RouteActions,
  extraParams = {},
) => {
  const useStepState = (
    initialStep: string,
  ): [string, (step: string) => void] => {
    const { step } = useParams<{ step?: string }>();
    const setStep = (stepId: string) => {
      route({ ...extraParams, stepId }).go();
    };

    return [step || initialStep, setStep];
  };
  return useStepState;
};
