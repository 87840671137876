import { ResolvedImage } from '../../types';
import { useImageHelper } from '../../../common/config';
import { UploadImageMutation, useUploadImageMutation } from '../../graphql';
import { resolveImage } from '../../utils/sku';
import { extractMutation } from '../../../common/utils/extractQuery';
import { ImagePayload } from '../../graphql/types';

export function useUploadImages(setImages: (images: ResolvedImage[]) => void) {
  const { getTempImageUrl } = useImageHelper();
  const [uploadImage, rawMutationResult] = useUploadImageMutation({
    onCompleted: data => {
      if (!data.uploadImage || data.uploadImage.__typename !== 'Image') {
        setImages([]);
        return;
      }
      const resolved = resolveImage(getTempImageUrl, data.uploadImage);
      setImages([resolved]);
    },
  });
  const mutationResult = extractMutation<UploadImageMutation, ImagePayload>(
    rawMutationResult,
    'Image',
    data => data.uploadImage,
  );
  return { uploadImage, mutationResult };
}
