import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import { Link } from '../../../../common/components/Link';
import { RouteActions } from '../../../../common/navigation';
import { useTranslation } from '../../../../translations';
import { GoodWorkLogo } from '../../Logo/GoodWorkLogo/GoodWorkLogo';

const useStyles = makeStyles(theme =>
  createStyles({
    activeMenuItem: {
      color: theme.palette.primary.veryLight,
      textDecoration: 'none',
    },
    menuLink: {
      paddingBottom: theme.spacing(1),
    },
  }),
);

export type HeaderContentProps = {
  links: {
    route: RouteActions;
    label: string;
    active?: boolean;
  }[];
  onLogout?: () => void;
};

export const HeaderContent: FC<HeaderContentProps> = ({ links, onLogout }) => {
  const classes = useStyles();

  const { t: tc, tk: tkc } = useTranslation('common');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const Menu = (
    <Box display="flex" flexDirection="column">
      {links.map(({ route, label, active }) => (
        <Link
          key={label}
          route={route}
          className={cn([
            classes.menuLink,
            { [classes.activeMenuItem]: active },
          ])}
        >
          <Typography variant={isMobile ? 'h3' : 'h2'}>{label}</Typography>
        </Link>
      ))}
    </Box>
  );

  return (
    <Box display="flex" width="100%" justifyContent="space-between" mt={2}>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
        <GoodWorkLogo withText />
        {isMobile && Menu}
      </Box>
      {!isMobile && Menu}
      {onLogout && (
        <MuiLink href="#" onClick={onLogout}>
          {tc(tkc.header.logout)}
        </MuiLink>
      )}
    </Box>
  );
};
