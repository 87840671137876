import React, { FC, useMemo, useCallback, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Form } from '../../../common/components/Form';
import { useTranslation } from '../../../translations';
import { Row } from '../../../common/components/Row';
import { FormFeedback } from '../../../common/components/FormFeedback';
import { GraphQlError } from '../../../common/types';
import { SkuFormInput, useSkuFormLayout } from '../../forms';
import { BreadcrumbItem } from '../../../common/components/Breadcrumbs';
import { TwoColumnLayout } from '../../../common/components/TwoColumnLayout';
import { Container } from '../../../common/components/Container';
import { ResolvedSku } from '../../types';
import { FormFields } from '../../../common/components/Form/types';
import { ResolvedMainCategory } from '../../context/types';
import { ColorMapping } from '../../context/useColorMapping';
import { useNavigation } from '../../../common/navigation';

import { PhotoGallery } from './PhotoGallery';
import { getInitialValues } from './getInitialValues';
import { Functionalities } from './Functionalities';
import { SkuTopMenu } from './SkuTopMenu';
import {
  EditFunctionalityDialog,
  EditFunctionalityDialogProps,
} from './EditFunctionalityDialog';
import { useColorFieldComponent } from './useColorFieldComponent';

type Props = {
  breadcrumbs: BreadcrumbItem[];
  categories: ResolvedMainCategory[];
  colorMapping: ColorMapping;
  error?: GraphQlError;
  sku: ResolvedSku;
  fields: FormFields;
  onUpdate: (data: SkuFormInput) => void;
  updateCompleted: boolean;
  editFunctionality?: Pick<
    EditFunctionalityDialogProps,
    | 'config'
    | 'onUpdate'
    | 'mutationResult'
    | 'updateCompleted'
    | 'onDismissCompleted'
  >;
};

const useStyles = makeStyles(theme =>
  createStyles({
    media: {
      borderRadius: 8,
      maxWidth: '100%',
    },
    root: {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(12),
      },
    },
    rightColumn: {
      maxWidth: 650,
    },
    idField: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '66%',
      },
      padding: theme.spacing(3, 0, 1),
    },
  }),
);

export const SkuView: FC<Props> = ({
  breadcrumbs,
  categories,
  colorMapping,
  error,
  fields,
  editFunctionality,
  sku,
  onUpdate,
  updateCompleted,
}) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');
  const ColorFieldComponent = useColorFieldComponent(colorMapping);
  const formLayout = useSkuFormLayout(
    categories,
    ColorFieldComponent,
    <Functionalities sku={sku} />,
  );
  const initialValues = useMemo(() => getInitialValues(sku), [sku]);
  const [showFuncEdit, setShowFuncEdit] = useState(false);
  const nav = useNavigation();

  const handleRegisterNewItem = () =>
    nav.newSkuInstance({ skuId: sku.id }).go();
  const handleEditFunctionality = useCallback(() => {
    setShowFuncEdit(true);
  }, []);

  const handleCloseEditFunctionality = useCallback(() => {
    setShowFuncEdit(false);
  }, []);

  return (
    <Container className={classes.root} data-testid="SkuView">
      <SkuTopMenu
        breadcrumbs={breadcrumbs}
        onEditFunctionality={
          editFunctionality ? handleEditFunctionality : undefined
        }
        onRegisterNewInstance={handleRegisterNewItem}
      />
      {showFuncEdit && editFunctionality && (
        <EditFunctionalityDialog
          open={true}
          sku={sku}
          onClose={handleCloseEditFunctionality}
          {...editFunctionality}
        />
      )}
      <TwoColumnLayout>
        <PhotoGallery sku={sku} />
        <div className={classes.rightColumn}>
          <FormFeedback
            error={error}
            success={updateCompleted}
            sucessMessage={t(tk.skuView.updateSuccess)}
          />
          <Row>
            <div className={classes.idField}>
              <TextField
                disabled
                label={t(tk.fieldLabels.skuId)}
                name="id"
                InputProps={{ notched: false } as Partial<OutlinedInputProps>}
                InputLabelProps={{
                  shrink: true,
                }}
                value={sku.id}
                fullWidth
                variant="outlined"
              />
            </div>
          </Row>
          <Form
            fields={fields}
            layout={formLayout}
            defaultValues={initialValues}
            onSubmit={onUpdate}
          />
        </div>
      </TwoColumnLayout>
    </Container>
  );
};
