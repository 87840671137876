import {
  FunctionalityEditGroup,
  FunctionalityValue,
  ResolvedFunctionalityGroup,
} from '../../../models';

export const needsConfiguration = (
  group: ResolvedFunctionalityGroup,
  values: Record<string, FunctionalityValue>,
) => {
  const { editConfig } = group;
  if (!editConfig || editConfig.type !== FunctionalityEditGroup.Group) {
    return false;
  }
  return editConfig.requiredFieldKeys.some(key => !values[key]);
};
