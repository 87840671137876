import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';
import { genBooleanField, genOptionSetField } from '../../configUtils';
import { FunctionalityFieldDisplayType } from '../../types';

export const wBoardSize = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'wBoardSize',
    t,
    tk: tk.whiteboards.fields.wBoardSize,
    type: FunctionalityFieldDisplayType.String,
  });

export const wBoardMaterial = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'wBoardMaterial',
    t,
    tk: tk.whiteboards.fields.wBoardMaterial,
    type: FunctionalityFieldDisplayType.String,
  });

export const wBoardFrame = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'wBoardFrame',
    t,
    tk: tk.whiteboards.fields.wBoardFrame,
  });

export const wBoardColor = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'wBoardColor',
    t,
    tk: tk.whiteboards.fields.wBoardColor,
    type: FunctionalityFieldDisplayType.String,
  });

export const wBoardFeet = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'wBoardFeet',
    t,
    tk: tk.whiteboards.fields.wBoardFeet,
    type: FunctionalityFieldDisplayType.String,
  });

export const wBoardSided = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'wBoardSided',
    t,
    tk: tk.whiteboards.fields.wBoardSided,
    type: FunctionalityFieldDisplayType.String,
  });

export const wBoardFoldable = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'wBoardFoldable',
    t,
    tk: tk.whiteboards.fields.wBoardFoldable,
  });

export const wBoardFlippable = (t: TFunction, tk: TKeys<'functionality'>) =>
  genBooleanField({
    key: 'wBoardFlippable',
    t,
    tk: tk.whiteboards.fields.wBoardFlippable,
  });

export const wBoardHanger = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'wBoardHanger',
    t,
    tk: tk.whiteboards.fields.wBoardHanger,
    type: FunctionalityFieldDisplayType.String,
  });
