import React, { FC } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import { CommonAttributes } from '../types';

import { BackLink } from './BackLink';

const BorderLinearProgress = withStyles(theme =>
  createStyles({
    root: {
      height: 8,
      borderRadius: 4,
      backgroundColor: theme.palette.background.alternative,
    },
    bar: {
      borderRadius: 4,
    },
  }),
)(LinearProgress);

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(12),
      },
    },
    content: {
      minHeight: '50vh',
    },
  }),
);

type Props = CommonAttributes & {
  title?: string;
  number?: number;
  totalCount?: number;
  showProgress?: boolean;
  onGoBack?: () => void;
  backLabel?: string;
};

export const WizardStep: FC<Props> = ({
  className,
  backLabel,
  onGoBack,
  children,
  title,
  number,
  totalCount,
  showProgress = true,
  ...commonAttributes
}) => {
  const classes = useStyles();
  return (
    <Container
      className={cn(classes.root, className)}
      maxWidth="sm"
      {...commonAttributes}
    >
      {onGoBack && <BackLink onGoBack={onGoBack} label={backLabel} />}
      {title && (
        <Box mt={4} mb={3}>
          <Typography variant="h3">{title}</Typography>
        </Box>
      )}
      {showProgress && totalCount && number && (
        <Box mb={3}>
          <BorderLinearProgress
            color="secondary"
            variant="determinate"
            value={(100 * number) / totalCount}
          />
        </Box>
      )}
      <div className={classes.content}>{children}</div>
    </Container>
  );
};
