import React from 'react';

import {
  OfficeView,
  OfficeInput,
} from '../../components/OfficeView/OfficeView';
import { LoadingIndicator } from '../../../common/components/LoadingIndicator';
import { ErrorView } from '../../../common/components/ErrorView';
import { officeFormFields } from '../../components/OfficeView/officeFormFields';
import { useNavigation } from '../../../common/navigation';
import { useUserContext } from '../../../inventory/context/useUserContext';
import { useTranslation } from '../../../translations';

import { useGetOffice } from './useGetOffice';
import { useEditOffice } from './useEditOffice';

type Props = {
  id: string;
  tenantKey: string;
};

export const EditOfficeView: React.FC<Props> = ({ id, tenantKey }) => {
  const { t, tk } = useTranslation('admin');

  const { loading, error, data } = useGetOffice(id, tenantKey);
  const { updateOffice, mutationResult, isProcessing } = useEditOffice();
  const {
    data: { tenants },
  } = useUserContext();
  const tenant = tenants.find(tenant => tenant.name === tenantKey);

  const nav = useNavigation();

  const handleSubmit = (office: OfficeInput) => {
    updateOffice(office, id, tenantKey);
  };

  if (loading || isProcessing) {
    return <LoadingIndicator />;
  }

  if (error || mutationResult.error) {
    return <ErrorView error={error || mutationResult.error} />;
  }

  return (
    <OfficeView
      tenantId={tenantKey}
      defaultValues={data}
      fields={officeFormFields}
      onSubmit={handleSubmit}
      onCancel={() => nav.adminPanelTenant({ id: tenant!.id }).go()}
      heading={t(tk.adminPanel.editOffice)}
    />
  );
};
