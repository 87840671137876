import { useMemo } from 'react';

import { useOffices } from '../context/useOffices';
import { useSkuInstanceTemplate } from '../context/useSkuInstanceTemplate';
import {
  FieldDefinition,
  FormLayout,
} from '../../common/components/Form/types';
import { ResolvedOffice } from '../context/types';
import { SkuTemplatePayload } from '../graphql/types';
import { useTranslation } from '../../translations';
import { useStatus } from '../context/useStatus';
import { InitialData } from '../context/InventoryContext';

import { FieldOverrides, resolveFields } from './utils';
import { SupportedSkuInstanceField } from './useSkuInstanceForm';
import { gridItemTwoColumn } from './common';

const getOverrides = (
  offices: ResolvedOffice[],
  status: InitialData['status'],
): FieldOverrides => ({
  officeId: {
    type: 'SelectFieldDefinition',
    options: offices.map(office => ({
      label: `${office.street} ${office.streetNumber}${
        office.building ? office.building : ''
      } [${office.zipCode} ${office.city}]`,
      value: office.id,
    })),
  },
  status: {
    required: false,
    options: status.values
      .map(val => ({
        label: val,
        value: val,
      }))
      .filter(({ value }) => value !== 'pre-registered'),
  },
});

export const skuInstanceFormUpdateDialogFields = [
  'officeId',
  'processingStage',
  'status',
  'condition',
] as const;

export const getSkuInstanceFormManyFields = (
  templateFields: SkuTemplatePayload['fields'],
  offices: ResolvedOffice[],
  status: InitialData['status'],
  keys: readonly SupportedSkuInstanceField[] = skuInstanceFormUpdateDialogFields,
) => {
  return resolveFields(templateFields, getOverrides(offices, status), keys);
};

export const useSkuInstanceFormUpdateDialogFields = (
  keys = skuInstanceFormUpdateDialogFields,
): Record<string, FieldDefinition> => {
  const offices = useOffices();
  const status = useStatus();
  const { fields } = useSkuInstanceTemplate();

  return useMemo(
    () => getSkuInstanceFormManyFields(fields, offices, status, keys),
    [fields, offices, keys, status],
  );
};

export const useSkuInstanceFormUpdateDialogLayout = (): FormLayout => {
  const { t, tk } = useTranslation('inventory');

  return [
    {
      type: 'fields',
      fields: [
        {
          gridItemProps: { xs: 12, md: 12 },
          name: 'officeId',
          label: t(tk.fieldLabels.officeId),
        },
      ],
    },

    {
      type: 'fields',
      fields: [
        {
          gridItemProps: { xs: 12, md: 12 },
          name: 'processingStage',
          label: t(tk.fieldLabels.processingStage),
        },
      ],
    },
    {
      type: 'fields',
      fields: [
        {
          gridItemProps: gridItemTwoColumn,
          name: 'status',
          label: t(tk.fieldLabels.status),
        },
        {
          gridItemProps: gridItemTwoColumn,
          name: 'condition',
          label: t(tk.fieldLabels.condition),
        },
      ],
    },
  ];
};
