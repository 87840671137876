import React, { FC, useMemo, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TFunction } from 'i18next';
import Button from '@material-ui/core/Button';

import { SkuInstanceInput } from '../../graphql';
import { Form } from '../../../common/components/Form';
import { Link } from '../../../common/components/Link';
import { TKeys, useTranslation } from '../../../translations';
import { FormFeedback } from '../../../common/components/FormFeedback';
import { SkuInstancePayload } from '../../graphql/types';
import { GraphQlError } from '../../../common/types';
import { useScanSkuInstanceFormLayout } from '../../forms/useScanSkuInstanceForm';
import { ResolvedSku } from '../../types';
import { FormFields } from '../../../common/components/Form/types';
import { BreadcrumbItem } from '../../../common/components/Breadcrumbs';
import { DeleteSkuInstanceDialogProps } from '../SkuInstanceView/DeleteSkuInstanceDialog';
import { SkuInstanceTopMenu } from '../SkuInstanceView/SkuInstanceTopMenu';
import { materialUiKit } from '../../../common/components/Form/uiKit';
import { clientError } from '../../../common/utils/graphQlErrors';
import { SkuPreview } from '../NewSkuView/SkuPreview';
import { useNavigation } from '../../../common/navigation';
import { QrCodeData, SkuInstanceScanner } from '../common/SkuInstanceScanner';

import { getInitialValues } from './getInitialValues';

const useStyles = makeStyles(theme =>
  createStyles({
    cardSection: {
      width: '100%',
      display: 'flex',
      height: 'min-content',
      paddingRight: theme.spacing(3),
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        paddingBottom: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    formSection: {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(12),
      },
    },
    menuSection: {
      width: '100%',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.only('xs')]: {
        marginBottom: theme.spacing(3),
      },
    },
  }),
);

const makeUiKit = (t: TFunction, tk: TKeys<'inventory'>) => ({
  ...materialUiKit,
  Submit: (disabled: boolean) => {
    return (
      <Box mt={8}>
        <Button
          disabled={disabled}
          fullWidth
          size="large"
          type="submit"
          color="secondary"
          variant="contained"
        >
          {t(tk.scanSkuInstanceView.confirm)}
        </Button>
      </Box>
    );
  },
});

type Props = {
  error?: GraphQlError;
  sku: ResolvedSku;
  skuInstance: SkuInstancePayload;
  fields: FormFields;
  breadcrumbs: BreadcrumbItem[];
  onUpdate: (data: Omit<SkuInstanceInput, 'skuId'>) => void;
  onViewSku: () => void;
  updateCompleted: boolean;
  deleteMutation: Pick<
    DeleteSkuInstanceDialogProps,
    'onDelete' | 'mutationResult'
  >;
};

export const ScanSkuInstanceView: FC<Props> = ({
  sku,
  error,
  fields,
  skuInstance,
  breadcrumbs,
  deleteMutation,
  onUpdate,
  updateCompleted,
}) => {
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');
  const [showScanner, setShowScanner] = useState(false);
  const [scannerError, setScannerError] = useState<GraphQlError | null>(null);
  const uiKit = useMemo(() => makeUiKit(t, tk), [t, tk]);
  const nav = useNavigation();
  const layout = useScanSkuInstanceFormLayout();
  const initialValues = useMemo(() => getInitialValues(skuInstance), [
    skuInstance,
  ]);
  const [confirmedFormData, setConfirmedFormData] = useState<Omit<
    SkuInstanceInput,
    'skuId'
  > | null>(null);

  if (skuInstance.sku.__typename !== 'Sku') {
    return null;
  }

  const handleFormSubmit = (data: Omit<SkuInstanceInput, 'skuId'>) => {
    setConfirmedFormData(data);
    setShowScanner(true);
  };

  const handleScannerSubmit = (qrCodeData: QrCodeData) => {
    setShowScanner(false);
    if (confirmedFormData) {
      onUpdate({
        ...confirmedFormData,
        ...qrCodeData,
      });
    }
  };

  const handleScannerError = (message: string) => {
    setShowScanner(false);
    setScannerError(clientError(message));
  };

  if (updateCompleted) {
    return (
      <Container maxWidth="sm" data-testid="ScanSkuInstanceView">
        <Alert severity="success" data-testid="success">
          {t(tk.scanSkuInstanceView.successMessage)}
        </Alert>
        <Box my={8} textAlign="center">
          <Button
            component={Link}
            route={nav.skuInstance({ id: skuInstance.id })}
            variant="outlined"
          >
            {t(tk.scanSkuInstanceView.viewInstance)}
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <section className={classes.menuSection}>
        <SkuInstanceTopMenu
          breadcrumbs={breadcrumbs}
          deleteMutation={deleteMutation}
        />
      </section>
      {showScanner && (
        <SkuInstanceScanner
          sku={sku}
          open={showScanner}
          onChange={handleScannerSubmit}
          onError={handleScannerError}
        />
      )}
      <section data-testid="ScanSkuInstanceView">
        <Container maxWidth="sm">
          <Box mt={4} mb={3}>
            <Typography variant="h3">
              {t(tk.scanSkuInstanceView.title)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="subtitle1">
              {t(tk.scanSkuInstanceView.subtitle)}
            </Typography>
          </Box>
          <Box mb={3}>
            <SkuPreview sku={sku} images={sku.images} />
          </Box>
          <FormFeedback
            error={error || scannerError}
            success={updateCompleted}
            sucessMessage={t(tk.skuInstanceView.updateSuccess)}
          />
          <Form
            fields={fields}
            layout={layout}
            defaultValues={initialValues}
            onSubmit={handleFormSubmit}
            uiKit={uiKit}
          />
          <Box pb={8} />
        </Container>
      </section>
    </Container>
  );
};
