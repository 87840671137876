import { useAuth0 } from '@auth0/auth0-react';
import React, { FC, useCallback, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { FillSpace } from '../../common/components/FillSpace';
import { useNavigation } from '../../common/navigation';
import { useCurrentTenantId } from '../../inventory/context/TenantContext';
import { useUserContext } from '../../inventory/context/useUserContext';
import { useTranslation } from '../../translations';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

type Props = {};

const useHeaderLinks = (isPartnerAdmin = true) => {
  const nav = useNavigation();
  const location = useLocation();
  const { t, tk } = useTranslation('common');

  return useMemo(() => {
    const links = [
      {
        route: nav.registration(),
        label: t(tk.header.menu.createSku),
        active: !!matchPath(nav.registration().url, {
          path: location.pathname,
          exact: true,
        }),
      },
      {
        route: nav.skus(),
        label: t(tk.header.menu.skus),
        active: !!matchPath(nav.skus().url, {
          path: location.pathname,
          exact: true,
        }),
      },
      // FF - Hiden Market Place
      // {
      //   route: nav.adSkus(),
      //   label: t(tk.header.menu.adSkus),
      //   active: !!matchPath(nav.adSkus().url, {
      //     path: location.pathname,
      //     exact: true,
      //   }),
      // },
    ];

    if (isPartnerAdmin) {
      links.push({
        route: nav.adminPanel(),
        label: t(tk.header.menu.adminPanel),
        active: !!matchPath(nav.adminPanel().url, {
          path: location.pathname,
          exact: true,
        }),
      });
    }

    return links;
  }, [isPartnerAdmin, location.pathname, nav, t, tk]);
};

export const Layout: FC<Props> = ({ children }) => {
  const { logout } = useAuth0();
  const {
    data: { tenants, isPartnerAdmin },
  } = useUserContext();
  const links = useHeaderLinks(isPartnerAdmin || false);
  const location = useLocation();
  const tenantId = useCurrentTenantId();

  const nav = useNavigation();

  const handleLogout = useCallback(() => {
    window.localStorage.clear();
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const handleTenantChange = useCallback(
    (tenantId: string) => {
      nav.skus({}, { tenantId }).go();
    },
    [nav],
  );

  return (
    <>
      <Header
        location={location}
        tenantId={tenantId}
        links={links}
        tenants={tenants}
        onTenantChange={handleTenantChange}
        onLogout={handleLogout}
      />
      {children}
      <FillSpace />
      <Footer />
    </>
  );
};
