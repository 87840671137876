import Typography from '@material-ui/core/Typography';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useTranslation } from '../../../../translations';
import { ResolvedAdSkuWithInstances } from '../../../types';
import { ListItemMetadata, Maybe } from '../../../graphql';
import { OmitTypename } from '../../../../common/types';

import { PriceRange } from './PriceRange';
import { SalesPeriod } from './SalesPeriod';

const useStyles = makeStyles(theme =>
  createStyles({
    counts: {
      marginTop: theme.spacing(3),
    },
  }),
);

type Props = {
  adSku: ResolvedAdSkuWithInstances;
  metadata?: Maybe<OmitTypename<ListItemMetadata>>;
};

export const AdSkuItemMetadata: React.FC<Props> = ({ adSku, metadata }) => {
  const { t, tk } = useTranslation('inventory');
  const classes = useStyles();

  return metadata ? (
    <>
      <Typography display="block" className={classes.counts} variant="caption">
        {t(tk.skuListView.instancesForSale, {
          matches: metadata.matches,
        })}
      </Typography>
      {adSku.adSkuInstances && (
        <>
          <PriceRange instances={adSku.adSkuInstances} />
          <SalesPeriod instances={adSku.adSkuInstances} />
        </>
      )}
    </>
  ) : (
    <></>
  );
};
