import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { useMemo } from 'react';

import { useTranslation } from '../../../translations';
import { ContextMenu, ContextMenuProps } from '../common/ContextMenu';
import { BreadcrumbItem } from '../../../common/components/Breadcrumbs';
import { ArrowLeft } from '../../../common/components/icons/12px/ArrowLeft';

type Props = {
  breadcrumbs: BreadcrumbItem[];
  onEditFunctionality?: () => void;
  onRegisterNewInstance?: () => void;
};

export const SkuTopMenu: FC<Props> = ({
  breadcrumbs,
  onEditFunctionality,
  onRegisterNewInstance,
}) => {
  const { t, tk } = useTranslation('inventory');

  const items = useMemo(() => {
    const items: ContextMenuProps['items'] = [
      {
        label: t(tk.skuView.topMenu.registerNewInstance),
        onSelect: onRegisterNewInstance,
      },
    ];
    if (onEditFunctionality) {
      items.unshift({
        label: t(tk.skuView.topMenu.editFunctionality),
        onSelect: onEditFunctionality,
        id: 'edit-functionality-option',
      });
    }
    return items;
  }, [
    onEditFunctionality,
    onRegisterNewInstance,
    t,
    tk.skuView.topMenu.editFunctionality,
    tk.skuView.topMenu.registerNewInstance,
  ]);

  return (
    <Box display="flex" flexDirection="row" data-testid="SkuTopMenu">
      <ContextMenu
        title={<ArrowLeft data-testid="BreadcrumbsButton" />}
        items={breadcrumbs.map(b => ({
          label: b.label,
          onSelect: b.route ? () => b.route?.go() : undefined,
        }))}
      />
      <Box ml={2} />
      <ContextMenu
        title={t(tk.skuView.topMenu.title)}
        items={items}
        showExpandIcon
      />
    </Box>
  );
};
