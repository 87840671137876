import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { readableColor } from 'polished';
import cn from 'classnames';

import { SwatchProps, StyleProps } from './common';

const diameter: { [size: string]: number } = {
  small: 16,
  medium: 24,
  large: 32,
} as const;

const checkSize: { [size: string]: number } = {
  small: 14,
  medium: 22,
  large: 30,
} as const;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: StyleProps) => ({
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyItems: 'space-between',
      margin: theme.spacing() * (props.size === 'large' ? 1.5 : 0.5),
    }),
    iconContainer: {
      cursor: 'pointer',
      display: 'inline-flex',
    },
    icon: (props: StyleProps) => ({
      boxSizing: 'border-box',
      backgroundColor: props.color,
      border: `1px solid ${theme.palette.primary.main}`,
      display: 'inline-flex',
      borderRadius: '50%',
      margin: 2,
      height: diameter[props.size],
      width: diameter[props.size],
      overflow: 'hidden',
    }),
    label: (props: StyleProps) => ({
      marginTop: theme.spacing() * (props.size === 'large' ? 1.5 : 0.5),
    }),
    check: (props: StyleProps) => ({
      position: 'absolute',
      color: readableColor(props.color),
      fontSize: checkSize[props.size],
    }),
  }),
);

export const CheckboxSwatch: React.FC<SwatchProps> = ({
  color,
  className,
  label,
  selected = false,
  size = 'medium',
  withBorder = false,
  contrast = false,
  onClick,
}) => {
  const classes = useStyles({
    color,
    size,
    selected,
    withBorder,
    contrast,
    onClick,
  });
  return (
    <div className={cn(classes.container, className)}>
      <div className={classes.iconContainer} onClick={onClick}>
        <div className={classes.icon}>
          {selected && <CheckIcon className={classes.check} />}
        </div>
      </div>
      {label && (
        <Typography className={classes.label} variant="button">
          {label}
        </Typography>
      )}
    </div>
  );
};
