import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import Card from '@material-ui/core/Card';
import UpdateIcon from '@material-ui/icons/Update';
import {
  makeStyles,
  createStyles,
  ThemeOptions,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel, Switch } from '@material-ui/core';

import { FormFeedback } from '../../../common/components/FormFeedback';
import { GraphQlError } from '../../../common/types';
import { ListProps, useList } from '../../../common/components/List/useList';
import { BackgroundContainer } from '../../../common/components/BackgroundContainer';
import { useTranslation } from '../../../translations';
import { Row } from '../../../common/components/Row';
import { TwoColumnLayout } from '../../../common/components/TwoColumnLayout';
import { ResolvedAdSku, ResolvedSku } from '../../types';
import { SelectedFilters } from '../../utils/useFilters';
import { SkuInstancePayload } from '../../graphql/types';

import { SkuInstanceList } from './SkuInstanceList/SkuInstanceList';
import { SkuInstanceListMetadata } from './SkuInstanceListMetadata';
import { DialogFormUpdateSkus } from './DialogFormUpdateSkus';

const useStyles = makeStyles(theme =>
  createStyles({
    chip: {
      cursor: 'inherit',
      marginRight: theme.spacing(1),
    },
    divider: {
      backgroundColor: theme.palette.primary.veryLight,
      '&:last-child': {
        display: 'none',
      },
    },
    filterCard: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 283,
      },
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    bootomSection: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '16px',
      margin: '16px 0',
    },
    registerButton: {
      display: 'flex',
      padding: 0,
      gap: '8px',
      justifyContent: 'end',
      [theme.breakpoints.up('md')]: {
        padding: 0,
        flexWrap: 'nowrap',
      },
    },

    checkAllButton: {
      width: '100%',
      background: theme.palette.info.light,
      '&:hover': {
        background: theme.palette.info.veryLight,
      },
      [theme.breakpoints.up('md')]: {
        width: 'max-content',
      },
    },
    unCheckAllButton: {
      width: '100%',
      background: theme.palette.error.light,
      '&:hover': {
        background: theme.palette.error.veryLight,
      },
      [theme.breakpoints.up('md')]: {
        width: 'max-content',
      },
    },
  }),
);

type Props = ListProps & {
  error?: GraphQlError;
  sku: ResolvedSku | ResolvedAdSku;
  onRegister: () => void;
  items: JSX.Element;
  selectedFilters: SelectedFilters;
  handleSelectAllItemsInPage?: () => void;
  handleUnSelectAllItemsInPage?: () => void;
  selectedSkuItems?: Array<SkuInstancePayload>;
  isSelectedAll?: boolean;
  setSelectedAll?: Dispatch<SetStateAction<boolean>>;
};

export const SkuInstanceListView: FC<Props> = ({
  error,
  sku,
  items,
  loading,
  filters,
  matches,
  page,
  pageCount,
  onPageChange,
  onFilterChange,
  selectedFilters,
  onRangeFilterChange,
  onRegister,
  selectedSkuItems,
  handleSelectAllItemsInPage,
  handleUnSelectAllItemsInPage,
  isSelectedAll,
  setSelectedAll,
}) => {
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);
  const classes = useStyles();
  const { t, tk } = useTranslation('inventory');

  const { Filters, CurrentFilters, Pagination } = useList({
    filters,
    page,
    pageCount,
    onPageChange,
    onFilterChange,
    onRangeFilterChange,
    selectedFilters,
  });

  return (
    <BackgroundContainer color="default" data-testid="SkuInstanceListView">
      <Box mt={5} mb={{ xs: 6, md: 12 }}>
        <TwoColumnLayout>
          <div />
          <>
            <FormFeedback error={error} />

            <Row>
              <Typography variant="h6">
                {t(tk.skuInstanceListView.title, {
                  manufacturer: sku.manufacturer,
                  model: sku.model,
                })}
              </Typography>
            </Row>

            {matches && <SkuInstanceListMetadata matches={matches} />}
            {!loading && <CurrentFilters />}

            {handleSelectAllItemsInPage &&
              handleUnSelectAllItemsInPage &&
              setSelectedAll && (
                <Box mb={2}>
                  <FormControlLabel
                    label={
                      <Typography variant="subtitle2">
                        {t(tk.skuInstanceListView.switchItemLabel)}
                      </Typography>
                    }
                    control={
                      <Switch
                        checked={isSelectedAll}
                        size="medium"
                        onChange={(_, checked) => {
                          checked
                            ? handleSelectAllItemsInPage()
                            : handleUnSelectAllItemsInPage();

                          setSelectedAll(checked);
                        }}
                      />
                    }
                  />
                </Box>
              )}
          </>
        </TwoColumnLayout>
        <TwoColumnLayout>
          <div className={classes.filterContainer}>
            <Card className={classes.filterCard} elevation={0}>
              <Box p={3}>
                <Filters data-testid="" />
              </Box>
            </Card>
          </div>
          <>
            <SkuInstanceList loading={loading} items={items} />
            <Box className={classes.bootomSection}>
              <Pagination />
              <Box className={classes.registerButton}>
                <RegisterButton color="primary" onClick={onRegister}>
                  {t(tk.skuInstanceListView.registerInstance)}
                </RegisterButton>
                {!!selectedSkuItems?.length && (
                  <Button
                    onClick={() => setUpdateDialogOpen(true)}
                    endIcon={<UpdateIcon />}
                    size="large"
                    color="secondary"
                  >
                    {t(tk.skuInstanceListView.changeStatusForInstancesButton)}
                  </Button>
                )}
              </Box>
            </Box>
          </>
        </TwoColumnLayout>
      </Box>
      <DialogFormUpdateSkus
        selectedSkuItems={selectedSkuItems}
        onClose={() => setUpdateDialogOpen(false)}
        open={isUpdateDialogOpen}
      />
    </BackgroundContainer>
  );
};

export const registerButtonTheme = (options: ThemeOptions) =>
  createMuiTheme({
    ...options,
    palette: {
      primary: options.palette?.ternary,
    },
  });

const RegisterButton: React.FC<ButtonProps> = props => (
  <ThemeProvider theme={registerButtonTheme}>
    <Button {...props} />
  </ThemeProvider>
);
