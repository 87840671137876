import { useState } from 'react';

import { extractMutation } from '../../../common/utils/extractQuery';
import { CreateOfficeMutationPayload } from '../../../inventory/graphql/types';
import {
  CreateOfficeInput,
  CreateOfficeMutation,
  useCreateOfficeMutation,
} from '../../../inventory/graphql';

export const useAddOffice = (onCompleted?: () => void) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const [createOfficeMutation, rawMutationResult] = useCreateOfficeMutation({
    onCompleted: () => {
      setIsProcessing(false);
      if (onCompleted) {
        onCompleted();
      }
    },
  });

  const mutationResult = extractMutation<
    CreateOfficeMutation,
    CreateOfficeMutationPayload
  >(rawMutationResult, 'CreateOfficePayload', data => data.createOffice);

  const addOffice = (
    {
      street,
      streetNumber,
      zipCode,
      city,
      officeFloors,
    }: Pick<
      CreateOfficeInput['office'],
      'street' | 'streetNumber' | 'zipCode' | 'officeFloors' | 'city'
    >,
    tenantId: string,
  ) => {
    const locationNumber = `${street} ${streetNumber}`;
    setIsProcessing(true);
    createOfficeMutation({
      context: {
        headers: {
          'x-tenant-id': tenantId,
        },
      },
      variables: {
        input: {
          office: {
            locationNumber,
            street,
            city,
            zipCode,
            streetNumber,
            officeFloors,
          },
        },
      },
    });
  };

  return {
    isProcessing,
    addOffice,
    mutationResult,
  };
};
