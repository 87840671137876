import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      padding: 0,
    },
    selectedCheckboxLabel: {
      fontWeight: 700,
    },
    listItemIcon: {
      minWidth: 24,
    },
    groupName: {
      fontWeight: 500,
    },
    item: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
);

export type CheckboxListNodeProps = {
  selected: string[];
  items: {
    label: string;
    value: string;
  }[];
  groupName: string;
  name: string;
  singleSelect?: boolean;
  handleToggle: (value: string) => () => void;
  onChange: (selected: string[]) => void;
};

export const CheckboxListNode: React.FC<CheckboxListNodeProps> = ({
  items,
  groupName,
  name,
  selected,
  singleSelect = false,
  onChange,
  handleToggle,
}) => {
  const classes = useStyles();

  const isSelectedNode = singleSelect
    ? !!items.length && !items.some(i => !selected.includes(i.value))
    : items.some(i => selected.includes(i.value));

  const handleNodeClick = () => {
    if (singleSelect) {
      return onChange(items.map(i => i.value));
    }
    const ids = items.map(i => i.value);
    if (isSelectedNode) {
      return onChange(selected.filter(s => !ids.includes(s)));
    }
    return onChange(selected.concat(ids));
  };

  const handleClick = (value: string) => {
    if (singleSelect) {
      return () => onChange([value]);
    }
    return handleToggle(value);
  };

  const expanded = items.some(i => selected.includes(i.value));

  const nodeId = `checkbox-list-${name}-group-${groupName}`;
  return (
    <>
      <ListItem
        className={classes.item}
        key={groupName}
        role={undefined}
        dense
        button
        onClick={handleNodeClick}
      >
        {!singleSelect && (
          <ListItemIcon className={classes.listItemIcon}>
            <Checkbox
              className={classes.checkbox}
              size="small"
              edge="start"
              checked={isSelectedNode}
              tabIndex={-1}
              disableRipple
              color="primary"
              inputProps={{ 'aria-labelledby': nodeId }}
            />
          </ListItemIcon>
        )}
        <ListItemText
          id={nodeId}
          primary={groupName}
          primaryTypographyProps={{
            className: isSelectedNode
              ? classes.selectedCheckboxLabel
              : undefined,
            variant: 'body1',
          }}
        />
      </ListItem>
      {expanded &&
        items.map(({ label, value }) => {
          const labelId = `checkbox-list-${name}-group-${groupName}-label-${value}`;
          const checked = selected.indexOf(value) !== -1;

          return (
            <ListItem
              className={classes.item}
              key={value}
              role={undefined}
              dense
              button
              onClick={handleClick(value)}
            >
              <Box ml={2} />
              {!singleSelect && (
                <ListItemIcon className={classes.listItemIcon}>
                  <Checkbox
                    className={classes.checkbox}
                    size="small"
                    edge="start"
                    checked={checked}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
              )}
              <ListItemText
                id={labelId}
                primary={label}
                primaryTypographyProps={{
                  className:
                    checked && !isSelectedNode && singleSelect
                      ? classes.selectedCheckboxLabel
                      : undefined,
                  variant: 'body1',
                }}
              />
            </ListItem>
          );
        })}
    </>
  );
};
