import { ListMetadataMatch } from '../graphql';

export const getPageCount = (
  matches: Readonly<
    Pick<ListMetadataMatch, 'template' | 'count'>[] | undefined
  >,
  template: string,
  pageLimit: number,
) => {
  if (!matches) {
    return null;
  }
  const count = matches.find(m => m.template === template)?.count;
  if (!count) {
    return null;
  }
  return Math.ceil(count / pageLimit);
};
