import React, { FC, useCallback } from 'react';
import { useState } from 'react';

import { ResolvedSku } from '../../../types';
import { Scanner } from '../Scanner';
import { defaultGtin } from '../../../consts';

import { ScannerBar } from './ScannerBar';
import { generateQrCode, parseQrCode } from './parseQrCode';
import { CodeFormDialog } from './CodeFormDialog';

type Props = {
  open: boolean;
  sku: Pick<
    ResolvedSku,
    'manufacturer' | 'model' | 'primaryColor' | 'secondaryColor' | 'images'
  >;
  onError?: (message: string) => void;
  onChange?: (code: QrCodeData) => void;
};

export type QrCodeData = {
  qrCode: string;
  gtin: string;
  serialNumber: string;
};

export const SkuInstanceScanner: FC<Props> = ({
  sku,
  onChange,
  open,
  onError,
}) => {
  const [data, setData] = useState<QrCodeData | null>(null);
  const [showCodeForm, setShowCodeForm] = useState(false);

  const handleScannerChange = useCallback((qrCode: string) => {
    const data = parseQrCode(qrCode);
    if (data) {
      setData(data);
    }
  }, []);

  const handleScannerSubmit = useCallback(() => {
    if (data && onChange) {
      onChange(data);
    }
    setData(null);
  }, [data, onChange]);

  const handleEnterCode = () => {
    setShowCodeForm(true);
  };

  const handleEnterCodeClose = () => {
    setShowCodeForm(false);
  };

  const handleCodeFormSubmit = (serialNumber: string) => {
    const data = {
      qrCode: generateQrCode(defaultGtin, serialNumber),
      gtin: defaultGtin,
      serialNumber,
    };
    handleEnterCodeClose();
    onChange && onChange(data);
  };

  return (
    <Scanner open={open} onChange={handleScannerChange} onError={onError}>
      {showCodeForm && (
        <CodeFormDialog
          open={showCodeForm}
          onSubmit={handleCodeFormSubmit}
          onClose={handleEnterCodeClose}
        />
      )}
      <ScannerBar
        sku={sku}
        isReady={!!data}
        onEnterCode={handleEnterCode}
        onConfirm={handleScannerSubmit}
      />
    </Scanner>
  );
};
