import { useState } from 'react';
import { Dictionary } from 'lodash';

import { useUpdateSkuMutation, UpdateSkuMutation } from '../../graphql';
import { extractMutation } from '../../../common/utils/extractQuery';
import { UpdateSkuMutationPayload } from '../../graphql/types';
import { SkuFormInput } from '../../forms';
import { ResolvedSku } from '../../types';
import {
  getFunctionalityInput,
  getFunctionalityValues,
} from '../../models/utils';
import {
  FunctionalitySubcategoryConfig,
  getFunctionalityConfig,
  useFunctionalityConfiguration,
} from '../../models';

export const mapFunctionalityToInput = (
  sku: ResolvedSku,
  skuInput: SkuFormInput,
  configuration: Dictionary<FunctionalitySubcategoryConfig>,
) => {
  if (
    !sku.subcategory ||
    sku.subcategory.__typename !== 'Subcategory' ||
    !sku.mainCategory ||
    sku.mainCategory.__typename !== 'MainCategory'
  ) {
    return [];
  }
  const config = getFunctionalityConfig(configuration, sku.subcategory.key);
  const values = getFunctionalityValues(sku);
  if (config === null || sku.mainCategory.id !== skuInput.mainCategoryId) {
    return [];
  }
  return getFunctionalityInput(config.fields, values);
};

export const useUpdateSku = () => {
  const [updateCompleted, setUpdateCompleted] = useState<boolean>(false);

  const [_updateSku, rawMutationResult] = useUpdateSkuMutation({
    onCompleted: () => {
      setUpdateCompleted(true);
    },
  });
  const mutationResult = extractMutation<
    UpdateSkuMutation,
    UpdateSkuMutationPayload
  >(rawMutationResult, 'UpdateSkuPayload', data => data.updateSku);
  const configuration = useFunctionalityConfiguration();

  const updateSku = (sku: ResolvedSku, skuInput: SkuFormInput) => {
    setUpdateCompleted(false);
    _updateSku({
      variables: {
        input: {
          id: sku.id,
          sku: {
            ...skuInput,
            images: sku.images.map(({ filename }) => ({ filename })), // form makes no changes to images
            functionalities: mapFunctionalityToInput(
              sku,
              skuInput,
              configuration,
            ),
          },
        },
      },
    });
  };
  return {
    updateSku,
    mutationResult,
    updateCompleted,
  };
};
