import React from 'react';
import BaseContainer, {
  ContainerProps as BaseContainerProps,
} from '@material-ui/core/Container';
import {
  useTheme,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cn from 'classnames';

export type ContainerProps = Omit<BaseContainerProps, 'disableGutters'> & {
  disableGutters?: 'xs' | 'sm' | 'md' | 'lg';
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gutters: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  }),
);

export const Container: React.FC<ContainerProps> = ({
  disableGutters,
  children,
  ...props
}) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const mediaDisable = useMediaQuery(breakpoints.down(disableGutters || 'xl'));

  const disable = disableGutters && mediaDisable;

  return (
    <BaseContainer
      className={cn({ [classes.gutters]: !disable })}
      disableGutters={disable}
      {...props}
    >
      {children}
    </BaseContainer>
  );
};
