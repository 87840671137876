import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export const breakpointsInput = {
  values: {
    xs: 0,
    sm: 600,
    md: 992,
    lg: 1280,
    xl: 1920,
  },
} as const;

export const breakpoints = createBreakpoints(breakpointsInput);
