import React, { ReactNode } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    right: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(4),
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(8),
      },
    },
  }),
);

type Props = {
  children: [ReactNode, ReactNode];
};

export const TwoColumnLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={4} xs={12}>
        {children[0]}
      </Grid>
      <Grid item md={8} xs={12} className={classes.right}>
        {children[1]}
      </Grid>
    </Grid>
  );
};
