import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityEditGroup,
  FunctionalityEditRow,
  FunctionalityGroup,
  FunctionalitySubcategoryConfig,
  FunctionalityValue,
} from '../types';
import { mapFieldKeys } from '../utils';

import { getFields, ChairFields } from './fields';
import { ChairFunctionalities } from './types';

const defaultValues: ChairFunctionalities = {
  chairAdjustableHeight: 'fixed',
  chairBack: false,
  chairBackLength: 'standard',
  chairBackFixed: true,
  chairBackAdjustableHeight: false,
  chairBackAdjustableAngle: false,
  chairLeaningBack: false,
  chairSeat: true,
  chairSeatFixed: true,
  chairSeatAdjustablePosition: false,
  chairSeatAdjustableAngle: false,
  chairArmrests: false,
  chairArmrestsFixed: false,
  chairArmrestsAdjustablePosition: false,
  chairArmrestsAdjustableHeight: false,
  chairArmrestsAdjustableWidth: false,
  chairArmrestsAdjustableLength: false,
  chairFeetCount: '4',
  chairFeetType: 'feet',
  chairNeckrest: 'none',
  chairFootring: false,
  chairStackable: false,
  chairFoldable: false,
};

const processValues = (values: Record<string, FunctionalityValue>) => {
  return {
    ...values,
    chairArmrestsFixed: values.chairArmrests,
  };
};

const groups = (
  {
    chairBack,
    chairArmrests,
    chairArmrestsFixed,
    chairStackable,
    chairFoldable,
    chairSeats,
  }: ChairFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityGroup[] => [
  {
    name: 'back',
    label: t(tk.chairs.groups.back.label),
    attributes: [chairBack],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      field: chairBack,
    },
  },
  {
    name: 'armrests',
    label: t(tk.chairs.groups.armrests.label),
    attributes: [chairArmrestsFixed],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      field: chairArmrests,
    },
  },
  {
    name: 'stackable',
    label: t(tk.chairs.groups.stackable.label),
    attributes: [chairStackable],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.chairs.groups.stackable.noValueLabel),
      field: chairStackable,
    },
  },
  {
    name: 'foldable',
    label: t(tk.chairs.groups.foldable.label),
    attributes: [chairFoldable],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Boolean,
      noValueLabel: t(tk.chairs.groups.foldable.noValueLabel),
      field: chairFoldable,
    },
  },
  {
    name: 'seats',
    label: t(tk.chairs.groups.seats.label),
    attributes: [chairSeats],
    functionalities: [],
    editConfig: {
      type: FunctionalityEditGroup.Group,
      requiredFieldKeys: [chairSeats.key],
      rows: [
        {
          type: FunctionalityEditRow.OptionSet,
          field: chairSeats,
        },
      ],
    },
  },
];

export const benches = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalitySubcategoryConfig => {
  const fields = getFields(t, tk);
  return {
    defaultValues,
    fields: mapFieldKeys(fields),
    groups: groups(fields, t, tk),
    processValues,
  };
};
