import { setContext } from '@apollo/link-context';

export const createTenantLink = (tenantId?: string) =>
  setContext(async (_, { headers }) => {
    return {
      headers: {
        'x-tenant-id': tenantId || '',
        ...headers,
      },
    };
  });
