import { QueryHookOptions } from '@apollo/client';
import { useMemo } from 'react';
import omit from 'lodash/omit';

import {
  AdSkuQuery,
  AdSkuQueryVariables,
  AdSkusQuery,
  AdSkusQueryVariables,
  AdSkusWithInstancesQuery,
  AdSkusWithInstancesQueryVariables,
  Filter,
  RangeFilter,
  UnionFilter,
  useAdSkuQuery,
  useAdSkusQuery,
  useAdSkusWithInstancesQuery,
} from '../graphql';
import { ImageHelper, useImageHelper } from '../../common/config';
import { ColorHelper, useColorHelper } from '../context/useColorMapping';
import { extractQuery } from '../../common/utils/extractQuery';
import {
  AdSkuPayload,
  AdSkusPayload,
  AdSkusWithInstancesPayload,
  AdSkuWithInstancesPayload,
} from '../graphql/types';
import { ResolvedAdSku, ResolvedAdSkuWithInstances } from '../types';
import { Maybe } from '../../common/types';

import { resolveImage } from './sku';

export const resolveAdSku = (
  adSku: AdSkuPayload,
  { getImageUrl }: ImageHelper,
  colorHelper: ColorHelper,
): ResolvedAdSku => ({
  ...omit(adSku, '__typename'),
  images: adSku.images.map(image => resolveImage(getImageUrl, image)),
  primaryColor: adSku.primaryColor ? colorHelper(adSku.primaryColor) : null,
  secondaryColor: adSku.secondaryColor
    ? colorHelper(adSku.secondaryColor)
    : null,
});

export const resolveAdSkuWithInstances = (
  adSku: AdSkuWithInstancesPayload,
  { getImageUrl }: ImageHelper,
  colorHelper: ColorHelper,
): ResolvedAdSkuWithInstances => ({
  ...omit(adSku, '__typename'),
  images: adSku.images.map(image => resolveImage(getImageUrl, image)),
  adSkuInstances:
    adSku.adSkuInstances.__typename === 'AdSkuInstanceList'
      ? adSku.adSkuInstances.items
      : [],
  primaryColor: adSku.primaryColor ? colorHelper(adSku.primaryColor) : null,
  secondaryColor: adSku.secondaryColor
    ? colorHelper(adSku.secondaryColor)
    : null,
});

export const useResolvedAdSku = (adSku: Maybe<AdSkuPayload>) => {
  const imageHelper = useImageHelper();
  const colorHelper = useColorHelper();
  return useMemo(
    () => (adSku ? resolveAdSku(adSku, imageHelper, colorHelper) : null),
    [colorHelper, imageHelper, adSku],
  );
};

export const useResolvedAdSkuQuery = (
  options?: QueryHookOptions<AdSkuQuery, AdSkuQueryVariables>,
) => {
  const rawAdSkuQuery = useAdSkuQuery(options);
  const adSkuQuery = extractQuery<AdSkuQuery, AdSkuPayload>(
    rawAdSkuQuery,
    'AdSku',
    data => data.adSku,
  );
  const data = useResolvedAdSku(adSkuQuery.data);
  return {
    ...adSkuQuery,
    data,
  };
};

export const useResolvedAdSkusQuery = (
  options?: QueryHookOptions<AdSkusQuery, AdSkusQueryVariables>,
) => {
  const imageHelper = useImageHelper();
  const colorHelper = useColorHelper();

  const result = extractQuery<AdSkusQuery, AdSkusPayload>(
    useAdSkusQuery(options),
    'AdSkuListWithMetadata',
    data => data.adSkus,
  );

  const adSkus = useMemo(() => {
    if (!result.data?.items) {
      return [];
    }
    return result.data?.items.map(({ adSku, metadata }) => ({
      adSku: resolveAdSku(adSku, imageHelper, colorHelper),
      metadata,
    }));
  }, [colorHelper, imageHelper, result.data]);

  if (!result.data) {
    return {
      ...result,
      data: undefined,
    };
  }

  return {
    ...result,
    data: {
      ...result.data,
      adSkus,
    },
  };
};

export const useResolvedAdSkusWithInstancesQuery = (
  options?: QueryHookOptions<
    AdSkusWithInstancesQuery,
    AdSkusWithInstancesQueryVariables
  >,
) => {
  const imageHelper = useImageHelper();
  const colorHelper = useColorHelper();

  const result = extractQuery<
    AdSkusWithInstancesQuery,
    AdSkusWithInstancesPayload
  >(
    useAdSkusWithInstancesQuery(options),
    'AdSkuListWithMetadata',
    data => data.adSkus,
  );

  const adSkus = useMemo(() => {
    if (!result.data?.items) {
      return [];
    }
    return result.data?.items.map(({ adSku, metadata }) => ({
      adSku: resolveAdSkuWithInstances(adSku, imageHelper, colorHelper),
      metadata,
    }));
  }, [colorHelper, imageHelper, result.data]);

  if (!result.data) {
    return {
      ...result,
      data: undefined,
    };
  }

  return {
    ...result,
    data: {
      ...result.data,
      adSkus,
    },
  };
};

export const filterSingleValueQueryFilters = ({ values, field }: Filter) => {
  return (
    field !== 'minRelatedCount' &&
    values.filter(x => {
      return x.search(/^from:|to:/) === -1;
    }).length > 0
  );
};

export const filterRangeValueQueryFilters = ({ values }: Filter) => {
  const g = values.filter(x => {
    return x.search(/^from:|^to:/) > -1;
  });

  return Array.isArray(g) && g.length === 2;
};

export const findRangeQueryFilter = (name: string, rangeFilters: Filter[]) =>
  rangeFilters.find(({ field }) => field === name);

export const extractFromToValues = (rangeFilters: Filter) =>
  rangeFilters.values.map(v => {
    if (v.search(/^from:/) > -1) {
      return { value: v.replace('from:', '') };
    } else if (v.search(/^to:/) > -1) {
      return { value: v.replace('to:', '') };
    }
  });

export const getRangeFiltersQuery = (conf: {
  filters: Filter[];
  name: string;
  min: string;
  max: string;
}): RangeFilter[] => {
  const { filters, name, min, max } = conf;
  const priceRangeFilters = findRangeQueryFilter(name, filters);
  if (!priceRangeFilters) {
    return [];
  }
  const [priceFromValue, priceToValue] = extractFromToValues(priceRangeFilters);

  if (!priceFromValue && !priceToValue) {
    return [];
  }

  return [
    {
      field: priceRangeFilters.field,
      includeRelated: priceRangeFilters.includeRelated,
      fromValue:
        priceFromValue && priceFromValue.value !== ''
          ? priceFromValue.value
          : min,
      toValue:
        priceToValue && priceToValue.value !== '' ? priceToValue.value : max,
    },
  ];
};

export const getUnionFiltersQuery = (conf: {
  filters: Filter[];
  name: string;
  defaultFromValue: string;
  defaultToValue: string;
  fromField: string;
  toField: string;
}): UnionFilter[] => {
  const {
    filters,
    name,
    defaultFromValue,
    defaultToValue,
    fromField,
    toField,
  } = conf;

  const dateRangeFilters = findRangeQueryFilter(name, filters);
  if (!dateRangeFilters) {
    return [];
  }
  const [dateFromValue, dateToValue] = extractFromToValues(dateRangeFilters);

  if (!dateFromValue && !dateToValue) {
    return [];
  }

  let fromValue = defaultFromValue;
  let toValue = defaultToValue;
  if (dateFromValue && dateFromValue.value !== '') {
    fromValue = `${new Date(dateFromValue.value).getTime() / 1000}`;
  }
  if (dateToValue && dateToValue.value !== '') {
    toValue = `${new Date(dateToValue.value).getTime() / 1000}`;
  }
  return [
    {
      fromField,
      toField,
      fromValue,
      includeRelated: dateRangeFilters.includeRelated,
      toValue,
    },
  ];
};
