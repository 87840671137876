import { TFunction } from 'i18next';

import { TKeys } from '../../../../translations';
import { FunctionalityFieldDisplayType } from '../../types';
import { genOptionSetField } from '../../configUtils';

export const sofaWidth = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'sofaWidth',
    t,
    tk: tk.sofas.fields.sofaWidth,
    type: FunctionalityFieldDisplayType.String,
  });

export const sofaFeet = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'sofaFeet',
    t,
    tk: tk.sofas.fields.sofaFeet,
    type: FunctionalityFieldDisplayType.String,
  });

export const sofaSeats = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'sofaSeats',
    t,
    tk: tk.sofas.fields.sofaSeats,
    type: FunctionalityFieldDisplayType.String,
  });

export const sofaChaiseLounge = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'sofaChaiseLounge',
    t,
    tk: tk.sofas.fields.sofaChaiseLounge,
    type: FunctionalityFieldDisplayType.String,
  });

export const sofaShape = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'sofaShape',
    t,
    tk: tk.sofas.fields.sofaShape,
    type: FunctionalityFieldDisplayType.String,
  });

export const sofaType = (t: TFunction, tk: TKeys<'functionality'>) =>
  genOptionSetField({
    key: 'sofaType',
    t,
    tk: tk.sofas.fields.sofaType,
    type: FunctionalityFieldDisplayType.String,
  });
