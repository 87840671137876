import { TFunction } from 'i18next';

import { TKeys } from '../../../translations';
import {
  FunctionalityFilterGroup,
  FunctionalityFilterRow,
  FunctionalityFilters,
} from '../types';

import { getFields, WhiteboardFields } from './fields';

const groups = (
  {
    wBoardSize,
    wBoardMaterial,
    wBoardFrame,
    wBoardFeet,
    wBoardSided,
    wBoardFoldable,
    wBoardFlippable,
    wBoardHanger,
  }: WhiteboardFields,
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityFilterGroup[] => [
  {
    label: t(tk.whiteboards.filters.size),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: wBoardSize,
      },
    ],
  },
  {
    label: t(tk.whiteboards.filters.material),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: wBoardMaterial,
      },
    ],
  },
  {
    label: t(tk.whiteboards.filters.sides),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: wBoardSided,
      },
    ],
  },
  {
    label: t(tk.whiteboards.filters.frame),
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: wBoardFrame,
        inverse: true,
        label: t(tk.common.filters.none),
      },
    ],
  },
  {
    label: t(tk.whiteboards.filters.feet),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: wBoardFeet,
      },
    ],
  },
  {
    label: t(tk.whiteboards.filters.hanger),
    rows: [
      {
        type: FunctionalityFilterRow.OptionSet,
        variant: 'inline',
        field: wBoardHanger,
      },
    ],
  },
  {
    label: t(tk.whiteboards.filters.other),
    rows: [
      {
        type: FunctionalityFilterRow.Boolean,
        field: wBoardFoldable,
      },
      {
        type: FunctionalityFilterRow.Boolean,
        field: wBoardFlippable,
      },
    ],
  },
];

export const whiteboards = (
  t: TFunction,
  tk: TKeys<'functionality'>,
): FunctionalityFilters => ({
  groups: groups(getFields(t, tk), t, tk),
  title: t(tk.common.filters.attributes),
});
