import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { Controller } from 'react-hook-form';
import type { Control, FieldError } from 'react-hook-form';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { ColorMenuItem } from '../../../common/components/ColorMenuItem';
import { ColorMapping } from '../../context/useColorMapping';
import { TextField } from '../../../common/components/TextField';
import { CheckboxSwatch } from '../../../common/components/CheckboxSwatch';

type Props = Omit<TextFieldProps, 'onChange' | 'error'> & {
  control: Control;
  name: string;
  error?: FieldError;
  colorMapping: ColorMapping;
};

const useStyles = makeStyles(theme =>
  createStyles({
    valueContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    swatch: {
      margin: theme.spacing(0, 1, 0, 0),
    },
  }),
);

export const ControlledColorField: React.FC<Props> = props => {
  const { control, error, name, colorMapping, ...textFieldProps } = props;
  const classes = useStyles();
  return (
    <Controller
      as={
        <TextField
          name={name}
          error={!!error}
          helperText={error?.message}
          select
          {...textFieldProps}
          InputProps={
            {
              notched: false,
            } as Partial<OutlinedInputProps>
          }
          SelectProps={{
            renderValue: (value: unknown) => {
              if (typeof value !== 'string') {
                return null;
              }
              const color = colorMapping[value];
              if (!color) {
                return value;
              }
              return (
                <div className={classes.valueContainer}>
                  <CheckboxSwatch
                    className={classes.swatch}
                    size="small"
                    color={color.hex}
                  />
                  {color.label}
                </div>
              );
            },
          }}
        >
          {Object.values(colorMapping).map(({ key, hex, label }) => (
            <ColorMenuItem color={hex} key={key} value={key}>
              {label}
            </ColorMenuItem>
          ))}
        </TextField>
      }
      name={name}
      control={control}
    />
  );
};
