import React, { ComponentType, FC, ReactNode } from 'react';

import { useTranslation } from '../../../../translations';
import { FieldConfig } from '../../../../common/components/Form/types';

import { FormValues } from './useNewSkuInstanceForm';

type Props = {
  Row: ComponentType<{}>;
  renderField: (config: FieldConfig) => ReactNode;
  count: number;
  values: FormValues;
};

export const CommonFields: FC<Props> = ({
  Row,
  count,
  values,
  renderField,
}) => {
  const { t, tk } = useTranslation('inventory');
  if (!count || values.isPreregistration) {
    return null;
  }
  return (
    <>
      {values.isSameStatus && (
        <>
          <Row>
            {renderField({
              name: 'status',
              gridItemProps: { xs: 12 },
              label:
                count > 1
                  ? t(tk.newSkuInstanceView.formStep.statusMultipleInstances, {
                      count,
                    })
                  : t(tk.fieldLabels.status),
            })}
          </Row>
          <Row>
            {renderField({
              name: 'condition',
              gridItemProps: { xs: 12 },
              label:
                count > 1
                  ? t(
                      tk.newSkuInstanceView.formStep.conditionMultipleInstances,
                      {
                        count,
                      },
                    )
                  : t(tk.fieldLabels.condition),
            })}
          </Row>
        </>
      )}
      {values.isSameLocation && (
        <Row>
          {renderField({
            name: 'officeFloorId',
            gridItemProps: { xs: 12 },
            label:
              count > 1
                ? t(
                    tk.newSkuInstanceView.formStep.officeFloorMultipleInstances,
                    {
                      count,
                    },
                  )
                : t(tk.fieldLabels.officeFloorId),
          })}
        </Row>
      )}
    </>
  );
};
