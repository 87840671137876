import { Maybe } from '../../common/types';

import {
  AttributeGroupField,
  BooleanFieldConfig,
  BooleanFunctionalityEditGroup,
  FunctionalityEditConfig,
  FunctionalityEditGroup,
  FunctionalityFieldDisplayType,
  FunctionalityGroup,
  FunctionalityGroupField,
  FunctionalityValue,
  OptionSetFieldConfig,
} from './types';

/**
 * based on the type of field
 * show label or value
 */
const getLabelByType = (
  fieldConfig: BooleanFieldConfig | OptionSetFieldConfig,
  value?: FunctionalityValue,
) => {
  if (!value) {
    return null;
  }
  if (fieldConfig.type === FunctionalityFieldDisplayType.Bool) {
    return fieldConfig.label;
  }
  if (
    fieldConfig.type === FunctionalityFieldDisplayType.String &&
    typeof value === 'string'
  ) {
    const valueLables = (fieldConfig as OptionSetFieldConfig).valueViewLabels;
    return (valueLables && valueLables[value]) || value;
  }
  return value;
};

/**
 * based on attributes form text and place comma
 * taking into account semantics
 *
 * example: [ '4', 'Hard Wheels' ] => "4 Hard Wheels"
 * example: [ 'Fixed', 'Standard Height' ] => "Fixed, Standard Height"
 */
const renderAttributesText = (
  attributes: AttributeGroupField[],
  values: Record<string, FunctionalityValue>,
): string | null => {
  if (Array.isArray(attributes) && attributes.length > 0) {
    const a = attributes
      .map(f => getLabelByType(f, values[f.key]))
      .filter(v => v !== null);

    let splitter = ', ';
    if (attributes.find(a => a.type === FunctionalityFieldDisplayType.Number)) {
      splitter = ' ';
    }
    return a.join(splitter);
  }

  return null;
};

const renderFunctionalitiesText = (
  functionalities: FunctionalityGroupField[],
  values: Record<string, FunctionalityValue>,
) => {
  return functionalities
    .filter(f => !f.groupBy)
    .map(({ key, label, type }) => {
      if (type === FunctionalityFieldDisplayType.Bool && values[key]) {
        return label;
      }
      return null;
    })
    .filter(v => v !== null)
    .join(', ');
};

const groupFunctionalities = (
  functionalities: FunctionalityGroupField[],
  values: Record<string, FunctionalityValue>,
) => {
  const groupedBy: Record<string, string[]> = {};
  functionalities
    .filter(f => f.groupBy)
    .forEach(({ key, label, groupBy, type }) => {
      if (
        groupBy &&
        type === FunctionalityFieldDisplayType.Bool &&
        values[key]
      ) {
        if (!Array.isArray(groupedBy[groupBy])) {
          groupedBy[groupBy] = [];
        }
        groupedBy[groupBy].push(label);
      }
    });

  return groupedBy;
};

const buildFunctions = (
  attributes: AttributeGroupField[],
  functionalities: FunctionalityGroupField[],
  values: Record<string, FunctionalityValue>,
) => {
  let value = '';

  const attributesText: string | null = renderAttributesText(
    attributes,
    values,
  );
  if (attributesText) {
    value = attributesText;
  }
  const functionalitiesText: string | null = renderFunctionalitiesText(
    functionalities,
    values,
  );

  if (functionalitiesText) {
    value = `${value}${
      functionalitiesText && value ? ', ' : ''
    }${functionalitiesText}`;
  }

  const groupedFunctionalities = groupFunctionalities(functionalities, values);
  for (const key in groupedFunctionalities) {
    value = `${value}${value ? ', ' : ''}${key} ${groupedFunctionalities[
      key
    ].join(' & ')}`;
  }

  return value;
};

const getNoValueLabel = (
  noneLabel: string,
  notConfiguredLabel: string,
  editConfig?: FunctionalityEditConfig,
) => {
  const label = editConfig?.noValueLabel || noneLabel;

  if (!editConfig) {
    return null;
  }
  if (editConfig.type === FunctionalityEditGroup.Group) {
    const requiredFieldsNo = editConfig.requiredFieldKeys?.length || 0;
    return requiredFieldsNo > 0 ? notConfiguredLabel : label;
  }

  return label || notConfiguredLabel;
};

export const getGroupLabel = (
  group: FunctionalityGroup,
  values: Record<string, FunctionalityValue>,
) => {
  const { attributes, functionalities } = group;
  const functions = buildFunctions(attributes, functionalities, values);
  if (functions.length === 0) {
    return null;
  }
  return functions;
};

export const getGroupEditLabel = (
  group: FunctionalityGroup,
  values: Record<string, FunctionalityValue>,
  readyGroup: boolean,
) => {
  const { attributes, functionalities, editConfig } = group;
  const noneLabel = 'None';
  const notConfiguredLabel = 'Not configured';

  if (!readyGroup) {
    return notConfiguredLabel;
  }

  const functions = buildFunctions(attributes, functionalities, values);
  if (functions.length === 0) {
    return getNoValueLabel(noneLabel, notConfiguredLabel, editConfig);
  }
  return functions;
};

export const getBooleanGroupLabel = (
  editConfig: BooleanFunctionalityEditGroup,
  value: Maybe<string | number | boolean>,
) => {
  const noneLabel = 'None';

  return value
    ? editConfig.field.editLabel
    : editConfig.noValueLabel || noneLabel;
};
