import React, { FC, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

import {
  getFunctionalityConfig,
  getGroupLabel,
  useFunctionalityConfiguration,
} from '../../models';
import { ResolvedSku } from '../../types';
import { getFunctionalityValues } from '../../models/utils';

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      backgroundColor: theme.palette.success.veryLight,
      borderRadius: 8,
      border: `1px solid ${theme.palette.success.light}`,
      width: '100%',
      padding: theme.spacing(1),
      marginBottom: 20,
      marginLeft: 9,
    },
    root: {
      padding: theme.spacing(0.2),
      borderRadius: 8,
      minWidth: 288,
      height: 52,
      border: `1px solid ${theme.palette.success.main}`,
      margin: 10,
    },
    content: {
      '&&': {
        paddingTop: theme.spacing(0.2),
        paddingLeft: theme.spacing(0.8),
      },
    },
    pos: {
      color: theme.palette.primary.light,
    },
    label: {
      fontSize: 14,
      color: theme.palette.success.main,
      fontWeight: 500,
    },
    componentLabel: {
      fontSize: 14,
      fontWeight: 500,
      paddingLeft: theme.spacing(1),
    },
  }),
);

type Props = {
  sku: ResolvedSku;
};

// eslint-disable-next-line max-lines-per-function
export const Functionalities: FC<Props> = ({ sku }) => {
  const classes = useStyles({ color: 'primary' });
  const values = useMemo(() => getFunctionalityValues(sku), [sku]);
  const configuration = useFunctionalityConfiguration();

  const { subcategory } = sku;
  if (!subcategory || subcategory.__typename !== 'Subcategory') {
    return null;
  }
  const config = getFunctionalityConfig(configuration, subcategory.key);

  if (!config) {
    return null;
  }

  return (
    <Card elevation={0} className={classes.card}>
      <Typography
        variant="h6"
        component="h2"
        className={classes.componentLabel}
      >
        Functionality
      </Typography>
      <Grid container spacing={0}>
        {config.groups.map(group => {
          const { label, name, hideRule } = group;
          if (hideRule && hideRule(values)) {
            return null;
          }
          const chipLabel = getGroupLabel(group, values);
          if (chipLabel && chipLabel.length > 0) {
            return (
              <Grid item md={6} lg={6} xl={6} xs={12} sm={6} key={label}>
                <Card
                  data-testid={`${name}-func-group`}
                  className={classes.root}
                  elevation={0}
                >
                  <CardContent className={classes.content}>
                    <Typography
                      variant="h6"
                      component="h2"
                      className={classes.label}
                    >
                      {label}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {chipLabel}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          } else {
            return null;
          }
        })}
      </Grid>
    </Card>
  );
};
