import flatten from 'lodash/flatten';

import { ListFilter } from './types';

const getLabel = (filter: ListFilter, value: string) => {
  switch (filter.type) {
    case 'nodes': {
      let label = null;
      for (const node of filter.nodes) {
        const item = node.items.find(i => i.value === value);
        if (item) {
          label = `${node.name}: ${item.label}`;
        }
      }
      return label || value;
    }
    case 'range': {
      return 'range-filter';
    }
    case 'text': {
      return `${filter.title}: ${value}`;
    }
    default:
      return filter.items.find(i => i.value === value)?.label || value;
  }
};

export const mapCurrentFilters = (filters: ListFilter[]) => {
  const formatRangeSelected = (v: string) => {
    if (v.search(/^from:/) > -1) {
      return v.replace('from:', '');
    } else if (v.search(/^to:/) > -1) {
      return v.replace('to:', '');
    }
  };

  const formatLabelType = (label: string, filterName: string) => {
    if (filterName === 'salesPrice') {
      return `${label}kr`;
    } else if (filterName === 'salesPeriod') {
      return new Date(label).toLocaleDateString('pl-PL');
    }
  };

  const getRangeLabel = (selected: string[], filterName: string) => {
    const [from, to] = selected.map(s => formatRangeSelected(s));

    if (!to && from) {
      return `${formatLabelType(from, filterName)} - ∞`;
    } else if (!from && to) {
      return `0 - ${formatLabelType(to, filterName)}`;
    } else if (from && to) {
      return `${formatLabelType(from, filterName)} - ${formatLabelType(
        to,
        filterName,
      )}`;
    }

    return '';
  };

  const rangeFilters = filters
    .filter(({ selected }) => selected.length > 0)
    .filter(({ type }) => type === 'range')
    .map(({ name, selected }) => {
      return {
        name,
        value: selected.join(','),
        selected,
        label: getRangeLabel(selected, name),
      };
    });

  const f = flatten(
    filters
      .filter(({ type }) => type !== 'range')
      .map(filter =>
        filter.selected.map(s => ({
          name: filter.name,
          value: s,
          label: getLabel(filter, s),
          selected: filter.selected,
        })),
      ),
  );

  return [...f, ...rangeFilters];
};
