import mapKeys from 'lodash/mapKeys';

import { SkuTemplatePayload } from '../graphql/types';
import { ResolvedSku } from '../types';
import { FunctionalityFieldType, FunctionalityInput } from '../graphql';
import { FieldConfig, FieldRow } from '../../common/components/Form/types';
import { logError } from '../../common/utils/logError';

import {
  Field,
  FunctionalityEditConfig,
  FunctionalityEditGroup,
  FunctionalityEditGroupRow,
  FunctionalityEditRow,
  FunctionalityFieldDisplayType,
  FunctionalityGroup,
  FunctionalityValue,
  OptionSetFieldConfig,
  ResolvedFunctionalityEditConfig,
  ResolvedFunctionalityGroup,
} from './types';
import { dimensionsConfiguration } from './dimensions';

export const someTrue = (
  values: Record<string, FunctionalityValue>,
  keys: string[],
) =>
  Boolean(
    Object.entries(values).filter(
      ([key, value]) => keys.includes(key) && !!value,
    ).length > 0,
  );

export const getOptions = (
  field: OptionSetFieldConfig,
  templateFields: SkuTemplatePayload['fields'],
  editMode = true,
) => {
  const templateField = templateFields.find(f => f.name === field.key);
  if (!templateField) {
    logError({
      key: 'func-missing-field',
      message: `Unable to resolve option set functionality field - field ${field.key} not found`,
    });
    return [];
  }

  if (templateField?.type !== 'OptionSetFieldDefinition') {
    logError({
      key: 'func-missing-field',
      message: `Unable to resolve option set functionality field - field ${field.key} not an option set field`,
    });
    return [];
  }

  if (!templateField.optionSet) {
    logError({
      key: 'func-missing-field',
      message: `Unable to resolve option set functionality field ${field.key}- option set undefined`,
    });
    return [];
  }

  return templateField.optionSet.values.map(value => ({
    value,
    label:
      (editMode && field.valueEditLabels && field.valueEditLabels[value]) ||
      (field.valueViewLabels && field.valueViewLabels[value]) ||
      value,
  }));
};

const getEditableFields = (rows: FunctionalityEditGroupRow[]) =>
  rows.reduce((result: string[], row) => {
    if (row.type === FunctionalityEditRow.OptionSet) {
      return [...result, row.field.key];
    }
    return [...result, ...row.fields.map(f => f.key)];
  }, []);

const resolveEditConfig = (
  config: FunctionalityEditConfig | undefined,
  templateFields: SkuTemplatePayload['fields'],
): ResolvedFunctionalityEditConfig | undefined => {
  if (!config || config.type !== FunctionalityEditGroup.Group) {
    return config;
  }
  return {
    ...config,
    editableFieldKeys: getEditableFields(config.rows),
    requiredFieldKeys: config.requiredFieldKeys || [],
    rows: config.rows.map(row => {
      if (row.type !== FunctionalityEditRow.OptionSet) {
        return row;
      }
      return {
        ...row,
        field: {
          ...row.field,
          options: getOptions(row.field, templateFields),
        },
      };
    }),
  };
};

export const resolveFunctionalityGroups = (
  groups: FunctionalityGroup[],
  templateFields: SkuTemplatePayload['fields'],
): ResolvedFunctionalityGroup[] =>
  groups.map(group => ({
    ...group,
    editConfig: resolveEditConfig(group.editConfig, templateFields),
  }));

export const getFunctionalityValues = (
  sku: Pick<ResolvedSku, 'functionalities'>,
): Record<string, FunctionalityValue> => {
  return sku.functionalities.reduce(
    (values: Record<string, FunctionalityValue>, fv) => {
      if (fv?.__typename === 'StringValueFunctionality') {
        values[fv.field] = fv.value;
      } else if (fv?.__typename === 'BooleanValueFunctionality') {
        values[fv.field] = fv.boolValue;
      }
      return values;
    },
    {},
  );
};

export const getFunctionalityInput = (
  fields: Record<string, Field>,
  values: Record<string, FunctionalityValue>,
): FunctionalityInput[] => {
  return Object.entries(values)
    .map(([key, value]) => {
      const fieldDefinition = fields[key];
      if (!fieldDefinition) {
        return (null as unknown) as FunctionalityInput; // handled by filter
      }
      return {
        field: key,
        type:
          fieldDefinition.type === FunctionalityFieldDisplayType.Bool
            ? FunctionalityFieldType.Bool
            : FunctionalityFieldType.String,
        boolValue:
          fieldDefinition.type === FunctionalityFieldDisplayType.Bool
            ? Boolean(value)
            : undefined,
        value:
          fieldDefinition.type !== FunctionalityFieldDisplayType.Bool
            ? String(value)
            : undefined,
      };
    })
    .filter(Boolean);
};

export const mapFieldKeys = (fields: Record<string, Field>) =>
  mapKeys(fields, value => value.key);

export const filterDimensionFieldNames = (
  subcategoryKey: string,
  fieldNames: readonly string[],
) =>
  fieldNames.filter(
    name => dimensionsConfiguration[subcategoryKey][name].hidden === false,
  );

export const isDimensionFieldHidden = (
  fieldName: string,
  subcategoryKey: string,
) => {
  if (!dimensionsConfiguration[subcategoryKey]) {
    return false;
  }

  return dimensionsConfiguration[subcategoryKey][fieldName].hidden;
};

export const processDimensionRows = (
  subcategoryKey: string,
  row: Pick<FieldRow, 'type'> & {
    fields: Array<Omit<FieldConfig, 'Component'> & { groupLabel: string }>;
  },
) => {
  const fields = row.fields.map(field => {
    const { name, groupLabel, label, gridItemProps } = field;

    const showGroupLabel =
      dimensionsConfiguration[subcategoryKey][name].showGroupLabel === true;

    return {
      name,
      gridItemProps,
      hidden: isDimensionFieldHidden(name, subcategoryKey),
      label: showGroupLabel && groupLabel ? groupLabel : label,
    };
  });

  return { ...row, fields };
};
